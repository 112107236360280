import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { Button, Grid, Loader } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import WeatherTable from 'components/data/WeatherTable';
import Section from 'components/layout/Section';
import { useExportWeathers, useInfiniteWeathers } from 'queries/weather';

export default function ProjectWeathersPage({ activities = [] }) {
  const { startDate, endDate } = getActivitiesInterval(activities);
  const filter = { startDate, endDate, orderBy: 'date', sort: 'asc' };

  const {
    weathers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteWeathers(filter);

  const {
    mutate: exportWeathers,
    isLoading: isExportingWeathers,
  } = useExportWeathers({
    onSuccess: () => {
      toast.success('Dados meteorológicos exportados!');
    },
  });

  return (
    <Grid>
      {isLoading && !weathers ? (
        <Grid.Column width={16}>
          <Loader active size="large" style={{ marginTop: '200px' }}>
            Carregando...
          </Loader>
        </Grid.Column>
      ) : (
        <>
          {activities.length && weathers?.length ? (
            <Grid.Column width={16} textAlign="right">
              <Button
                basic
                loading={isExportingWeathers}
                onClick={() => exportWeathers(filter)}
              >
                Exportar
              </Button>
            </Grid.Column>
          ) : null}

          <Grid.Column width={16}>
            {!activities.length ? (
              <Section>
                <Section.Content>
                  <EmptyState>
                    <EmptyState.Header>Estudo sem cronograma</EmptyState.Header>
                    <EmptyState.Description>
                      Adicione o cronograma de atividades para obter os dados
                      meteorológicos deste período
                    </EmptyState.Description>
                  </EmptyState>
                </Section.Content>
              </Section>
            ) : null}

            {activities.length && !weathers?.length ? (
              <Section>
                <Section.Content>
                  <EmptyState>
                    <EmptyState.Header>
                      Nenhum dado deste período
                    </EmptyState.Header>
                    <EmptyState.Description>
                      Importe dados meteorológicos no período do cronograma de
                      atividades para listá-los aqui
                    </EmptyState.Description>
                  </EmptyState>
                </Section.Content>
              </Section>
            ) : null}

            {activities.length && weathers?.length ? (
              <WeatherTable
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                isLoading={isLoading}
                weathers={weathers}
              />
            ) : null}
          </Grid.Column>
        </>
      )}
    </Grid>
  );
}

function getActivitiesInterval(activities) {
  const startDate = activities.length
    ? DateTime.fromISO(activities[0].date)
        .minus({ days: 10 })
        .toISO()
    : undefined;
  const endDate = activities.length
    ? DateTime.fromISO(activities[activities?.length - 1].date)
        .plus({ days: 10 })
        .toISO()
    : undefined;
  return { startDate, endDate };
}
