import * as React from 'react';

import { DateTime } from 'luxon';
import { Button, Dropdown, Header, Icon, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { useAuth } from 'hooks/auth';
import { downloadDocument } from 'hooks/document';

type Shipping = {
  address: string | null;
  id: number;
  items: {
    id: number;
    number: number;
  }[];
  invoiceDocumentId: number | null;
  shippingDate: string | null;
  shippingForecast: string | null;
};

export type ShipmentTable = {
  isLoading: boolean;
  items: Shipping[];
  onAddClick?: () => void;
  onChangeAddressClick: (shipmentId: number) => void;
  onChangeShippingForecastClick: (shipmentId: number) => void;
  onSendClick: (shipmentId: number) => void;
  onItemsClick: (shipmentId: number) => void;
};

function ShipmentTable({
  isLoading,
  items = [],
  onAddClick,
  onChangeAddressClick,
  onChangeShippingForecastClick,
  onSendClick,
  onItemsClick,
}: ShipmentTable) {
  const { user } = useAuth();

  const options = [
    {
      key: 'send-shipment',
      text: 'Enviar remessa',
      value: 'send-shipment',
      icon: 'shipping fast',
      onClick: onSendClick,
    },
    {
      key: 'change-shipping',
      text: 'Alterar envio',
      value: 'change-shipping',
      icon: 'edit',
      onClick: onChangeAddressClick,
    },
    {
      key: 'change-shipping-forecast',
      text: 'Alterar previsão de expedição',
      value: 'change-shipping-forecast',
      icon: 'calendar alternate outline',
      onClick: onChangeShippingForecastClick,
    },
  ] as const;

  if (!items.length) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Nenhuma remessa</EmptyState.Header>
            <EmptyState.Description>
              Clique no botão abaixo para adicionar uma remessa
            </EmptyState.Description>
            {onAddClick ? (
              <EmptyState.Actions>
                <Button basic type="button" onClick={onAddClick} primary>
                  <Icon name="add" />
                  Adicionar
                </Button>
              </EmptyState.Actions>
            ) : null}
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Header>
        <Header as="h3">Remessas</Header>
        {items.length ? (
          <Button basic onClick={onAddClick} primary>
            <Icon name="add" /> Novo item
          </Button>
        ) : null}
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell>Previsão</Table.HeaderCell>
              <Table.HeaderCell>Data de envio</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell width={5}>Endereço</Table.HeaderCell>
              <Table.HeaderCell>Itens</Table.HeaderCell>
              {user?.isAdmin ? <Table.HeaderCell>NF</Table.HeaderCell> : null}
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={7} rows={5} />}
            {!isLoading &&
              items?.map(
                (
                  {
                    address,
                    id,
                    items: shipmentItems,
                    invoiceDocumentId,
                    shippingDate,
                    shippingForecast,
                  },
                  index,
                ) => {
                  const itemsDescription = `${shipmentItems.length} ${
                    shipmentItems.length === 1 ? 'item' : 'itens'
                  }`;

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell width={1}>{index + 1}</Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {shippingForecast
                            ? DateTime.fromISO(shippingForecast).toFormat(
                                'dd/MM/yyyy',
                              )
                            : 'n/a'}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {shippingDate
                            ? DateTime.fromISO(shippingDate).toFormat(
                                'dd/MM/yyyy',
                              )
                            : 'n/a'}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        {shippingDate ? (
                          <Tag color="transparent">Concluída</Tag>
                        ) : (
                          <Tag color="yellow">Pendente</Tag>
                        )}
                      </Table.Cell>
                      <Table.Cell title={address || 'n/a'}>
                        <Text variant="primary">{address || 'n/a'}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <LinkButton onClick={() => onItemsClick(id)}>
                          {itemsDescription}
                        </LinkButton>
                      </Table.Cell>
                      {user?.isAdmin ? (
                        <Table.Cell>
                          <Text variant="primary">
                            {invoiceDocumentId ? (
                              <LinkButton
                                onClick={() =>
                                  downloadDocument(invoiceDocumentId)
                                }
                              >
                                Anexo
                              </LinkButton>
                            ) : null}
                          </Text>
                        </Table.Cell>
                      ) : null}
                      <Table.Cell
                        textAlign="center"
                        style={{ overflow: 'visible' }}
                      >
                        <Dropdown
                          direction="left"
                          floating
                          labeled
                          icon={null}
                          button
                          trigger={
                            <Button basic icon>
                              <Icon name="ellipsis horizontal" />
                            </Button>
                          }
                        >
                          <Dropdown.Menu>
                            <Dropdown.Menu scrolling style={{ zIndex: 999 }}>
                              {options.map((option) => (
                                <Dropdown.Item
                                  key={option.key}
                                  text={option.text}
                                  value={option.value}
                                  icon={option.icon}
                                  disabled={
                                    (option.key === 'send-shipment' &&
                                      !!shippingDate) ||
                                    (option.key === 'change-shipping' &&
                                      !shippingDate)
                                  }
                                  onClick={() => option.onClick(id)}
                                />
                              ))}
                            </Dropdown.Menu>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Table.Cell>
                    </Table.Row>
                  );
                },
              )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

export default ShipmentTable;
