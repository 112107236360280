import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import StateSelect from 'components/inputs/StateSelect';
import { useUpdateCompanyInfo } from 'data/company-info';
import { formatCpfOrCnpj } from 'util/mask';

export type UpdateCompanyInfoFormValues = {
  name: string;
  cnpj: string;
  stateRegistration: string;
  address: string;
  mailbox: string;
  city: string;
  state: string;
  cep: string;
  phone: string;
  websiteUrl: string;
};

export type UpdateCompanyInfoModalProps = {
  formValues?: UpdateCompanyInfoFormValues;
  isOpen: boolean;
  onClose: () => void;
  shipmentId: number;
};

const FORM_ID = 'form_update_company_info';
const MASK_CEP = '#####-###';
const MASK_PHONE = '(##) #####-####';

const UpdateCompanyInfoInput = yup.object({
  name: yup
    .string()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  cnpj: yup
    .string()
    .required('Obrigatório')
    .max(20, 'Máximo de 20 caracteres')
    .typeError('Obrigatório'),
  stateRegistration: yup
    .string()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  address: yup
    .string()
    .required('Obrigatório')
    .max(500, 'Máximo de 500 caracteres')
    .typeError('Obrigatório'),
  mailbox: yup
    .string()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  city: yup
    .string()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  state: yup
    .object()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  cep: yup
    .string()
    .required('Obrigatório')
    .max(20, 'Máximo de 20 caracteres')
    .typeError('Obrigatório'),
  phone: yup
    .string()
    .required('Obrigatório')
    .max(20, 'Máximo de 20 caracteres')
    .typeError('Obrigatório'),
  websiteUrl: yup
    .string()
    .required('Obrigatório')
    .typeError('Obrigatório'),
});

export default function UpdateCompanyInfoModal({
  formValues,
  isOpen,
  onClose,
}: UpdateCompanyInfoModalProps) {
  const defaultValues = formValues;
  const resolver = yupResolver(UpdateCompanyInfoInput);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const updateCompanyInfo = useUpdateCompanyInfo({
    onSuccess: () => handleSuccess('Informações alteradas!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: UpdateCompanyInfoFormValues) {
    updateCompanyInfo.mutate({
      name: values.name,
      cnpj: values.cnpj,
      stateRegistration: values.stateRegistration,
      address: values.address,
      mailbox: values.mailbox,
      city: values.city,
      state: values.state?.value,
      cep: values.cep,
      phone: values.phone,
      websiteUrl: values.websiteUrl,
    });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alteração de envio</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Nome</label>
            <Controller
              control={control}
              name="name"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={updateCompanyInfo.isLoading}
                  loading={updateCompanyInfo.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.name && <InputError>{errors?.name?.message}</InputError>}
          </Form.Field>
          <Form.Field>
            <label>CNPJ</label>
            <Controller
              control={control}
              name="cnpj"
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  allowEmptyFormatting
                  autoComplete="off"
                  disabled={updateCompanyInfo.isLoading}
                  format={formatCpfOrCnpj}
                  isNumericString
                  mask="_"
                  maxLength={18}
                  onBlur={onBlur}
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  value={value}
                />
              )}
            />
            {errors.cnpj && <InputError>{errors?.cnpj?.message}</InputError>}
          </Form.Field>
          <Form.Field>
            <label>Inscrição estadual</label>
            <Controller
              control={control}
              name="stateRegistration"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={updateCompanyInfo.isLoading}
                  loading={updateCompanyInfo.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.stateRegistration && (
              <InputError>{errors?.stateRegistration?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Telefone</label>
            <Controller
              control={control}
              name="phone"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={MASK_PHONE}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  disabled={updateCompanyInfo.isLoading}
                  autoComplete="off"
                />
              )}
            />
            {errors.phone && <InputError>{errors?.phone?.message}</InputError>}
          </Form.Field>
          <Form.Field>
            <label>Endereço</label>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <TextArea
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={updateCompanyInfo.isLoading}
                  loading={updateCompanyInfo.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.address && (
              <InputError>{errors?.address?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>CEP</label>
            <Controller
              control={control}
              name="cep"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={MASK_CEP}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  maxLength={18}
                  disabled={updateCompanyInfo.isLoading}
                  autoComplete="new-password"
                />
              )}
            />
            {errors.cep && <InputError>{errors?.cep?.message}</InputError>}
          </Form.Field>
          <Form.Group>
            <Form.Field width={10}>
              <label>Cidade</label>
              <Controller
                control={control}
                name="city"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={updateCompanyInfo.isLoading}
                    loading={updateCompanyInfo.isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.city && <InputError>{errors?.city?.message}</InputError>}
            </Form.Field>
            <Form.Field width={6}>
              <label>Estado</label>
              <Controller
                control={control}
                name="state"
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <StateSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={updateCompanyInfo.isLoading}
                  />
                )}
              />
              {errors.state && <InputError>Obrigatório</InputError>}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Caixa postal</label>
            <Controller
              control={control}
              name="mailbox"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={updateCompanyInfo.isLoading}
                  loading={updateCompanyInfo.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.mailbox && (
              <InputError>{errors?.mailbox?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Site</label>
            <Controller
              control={control}
              name="websiteUrl"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={updateCompanyInfo.isLoading}
                  loading={updateCompanyInfo.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.websiteUrl && (
              <InputError>{errors?.websiteUrl?.message}</InputError>
            )}
          </Form.Field>
        </Form>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={updateCompanyInfo.isLoading}
          form={FORM_ID}
          loading={updateCompanyInfo.isLoading}
          primary
          type="submit"
        >
          Alterar envio
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
