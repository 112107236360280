import React from 'react';

import { DateTime } from 'luxon';
import { Table, Icon, Button, Popup } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import Section from 'components/layout/Section';
import { PROJECT_STAGE } from 'data/project';
import { useAuth } from 'hooks/auth';
import { downloadDocument } from 'hooks/document';
import { formatCurrencyWithSymbol } from 'util/Currency';

export default function ProjectInstallmentsTable({
  contract,
  project,
  loading,
  onEditClick,
}) {
  const { user } = useAuth();
  console.log(project);

  return (
    <Section>
      <Section.Content>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center" width="1">
                Parcela
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Previsão faturamento
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Situação</Table.HeaderCell>
              {user.isAdmin ? <Table.HeaderCell textAlign="center" /> : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading && <LoadingRow columns={4} rows={1} />}
            {project?.installments?.map((installment) => (
              <Table.Row key={installment.id}>
                <Table.Cell textAlign="center">{installment.number}</Table.Cell>
                <Table.Cell textAlign="right">
                  {formatCurrencyWithSymbol(
                    installment.amount,
                    contract.currency,
                  )}
                </Table.Cell>
                <Table.Cell textAlign="right">
                  {installment.paymentForecast
                    ? DateTime.fromISO(installment.paymentForecast).toFormat(
                        'dd/MM/yyyy',
                      )
                    : null}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {project.projectStage === PROJECT_STAGE.canceled ? (
                    <Tag color="red">Cancelado</Tag>
                  ) : (
                    <Tag
                      color={installment.paymentId === null ? 'grey' : 'green'}
                    >
                      {installment.paymentId === null ? 'Em aberto' : 'Pago'}
                    </Tag>
                  )}
                </Table.Cell>
                {user.isAdmin ? (
                  <Table.Cell textAlign="center">
                    <Popup
                      trigger={
                        <Button
                          size="small"
                          onClick={() =>
                            downloadDocument(installment.invoiceDocumentId)
                          }
                          disabled={!installment.invoiceDocumentId}
                          circular
                          basic
                          icon
                        >
                          <Icon name="download" />
                        </Button>
                      }
                      content="Baixar anexo"
                      mouseEnterDelay={500}
                      inverted
                    />
                    <Popup
                      trigger={
                        <Button
                          size="small"
                          onClick={() => onEditClick(installment)}
                          circular
                          basic
                          icon
                        >
                          <Icon name="edit" />
                        </Button>
                      }
                      content="Editar"
                      mouseEnterDelay={500}
                      inverted
                    />
                  </Table.Cell>
                ) : null}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
