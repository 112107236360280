import React from 'react';

import { Header as HeaderSemantic, Icon } from 'semantic-ui-react';

import { Container, EmptyStateDescription, EmptyStateActions } from './styles';

function EmptyState({ icon = 'search', align = 'center', children }) {
  return (
    <Container align={align}>
      <Icon name={icon} color="grey" size="huge" style={{ opacity: '0.4' }} />
      {children}
    </Container>
  );
}

function Header({ children }) {
  return <HeaderSemantic>{children}</HeaderSemantic>;
}

function Description({ children }) {
  return <EmptyStateDescription>{children}</EmptyStateDescription>;
}

function Actions({ children }) {
  return <EmptyStateActions>{children}</EmptyStateActions>;
}

EmptyState.Header = Header;
EmptyState.Description = Description;
EmptyState.Actions = Actions;

export default EmptyState;
