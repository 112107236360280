import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Dimmer,
  Divider,
  Form,
  Header,
  Input,
  Loader,
  Modal,
} from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';

import TextInfo from 'components/data/TextInfo';
import Text from 'components/foundation/Text';
import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { usePerformActivity } from 'mutations/project';
import { useActivity } from 'queries/activity';
import { emptyStringToNull } from 'util/validation';

const schema = yup
  .object({
    bbchScale: yup
      .string()
      .optional()
      .transform(emptyStringToNull),
    executionDate: yup
      .date()
      .required('Obrigatório')
      .transform(emptyStringToNull),
  })
  .required(`Obrigatório`);

type FormValues = yup.InferType<typeof schema>;

const FORM_ID = 'perform_activity_form';

type ActivityPerformModalProps = {
  activityId: number;
  onClose: () => void;
  isOpen: boolean;
};

export default function ActivityPerformModal({
  activityId,
  onClose,
  isOpen,
}: ActivityPerformModalProps) {
  const history = useHistory();
  const formRef = React.useRef(null);

  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { data: activity, isLoading } = useActivity(activityId);
  const {
    mutate: performActivity,
    isLoading: isUpdating,
  } = usePerformActivity({ onSuccess });

  function onSuccess() {
    toast.success('Atividade executada!');
    onClose();
  }

  function handleProjectClick() {
    history.push(`/estudos/${activity?.projectId}`);
  }

  function onSubmit(values: FormValues) {
    const input = { ...values, activityId };
    performActivity(input);
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Executar atividade</Modal.Header>
      <Modal.Content>
        {isLoading || !activity ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <>
            <HeaderWrapper>
              <Header as="h3">{activity?.type}</Header>
              <DateText>
                {DateTime.fromISO(activity.date).toFormat('dd/MM/yyyy')}
              </DateText>
            </HeaderWrapper>
            <Text variant="primary">
              {activity?.description || 'Sem descrição'}
            </Text>
            <Divider hidden />
            <TextInfo
              label={activity?.customer}
              text={activity?.project}
              onClick={handleProjectClick}
            />
            <Divider hidden />
            <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Data execução</label>
                  <Controller
                    control={control}
                    name="executionDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="dd/MM/yyyy - EEEE"
                      />
                    )}
                  />
                  {errors.executionDate && (
                    <InputError>{errors?.executionDate?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Escala BBCH</label>
                  <Controller
                    control={control}
                    name="bbchScale"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isUpdating}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isUpdating}
          form={FORM_ID}
          loading={isUpdating}
          primary
        >
          Executar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1.28571429em;
`;

const DateText = styled.div`
  font-size: 1.4rem;
`;
