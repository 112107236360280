import { number, object } from 'yup';

const required = 'é um campo obrigatório';
const treatmentsNumberMin = 1;
const treatmentsNumberMax = 99;
const trialsNumberMin = 1;
const trialsNumberMax = 99;

export const schema = object().shape({
  treatmentsNumber: number()
    .required(`Nº de tratamentos ${required}`)
    .typeError(`Nº de tratamentos deve ser um número`)
    .positive(`Nº de tratamentos deve ser positivo`)
    .integer(`Nº de tratamentos deve ser inteiro`)
    .min(
      treatmentsNumberMin,
      `Nº de tratamentos deve ser maior ou igual a ${treatmentsNumberMin}`,
    )
    .max(99, `Nº de tratamentos dever menor ou igual a ${treatmentsNumberMax}`),
  trialsNumber: number()
    .required(`Nº de repetições ${required}`)
    .typeError(`Nº de repetições deve ser um número`)
    .positive(`Nº de repetições deve ser positivo`)
    .integer(`Nº de repetições deve ser inteiro`)
    .min(
      treatmentsNumberMin,
      `Nº de tratamentos deve ser maior ou igual a ${trialsNumberMin}`,
    )
    .max(99, `Nº de tratamentos dever menor ou igual a ${trialsNumberMax}`),
});
