import * as React from 'react';

import fileSize from 'filesize';
import { DateTime } from 'luxon';
import { Control, Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button, Divider, Form, Input, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import {
  Documents,
  DOCUMENT_EXTENSION,
  DOCUMENT_TYPE_DESCRIPTION,
} from 'data/document';
import { Pagination } from 'util/api';
import debounce from 'util/debounce';

import { useDocumentFilter } from './use-document-filter';

const ROWS = 10;
const COLUMNS = 4;

type NextPageProps = {
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
};

export type DocumentTableProps = NextPageProps & {
  isLoading?: boolean;
  documents: Documents[];
  pagination: Pagination;
  onDownloadClick: (documentId: number) => void;
};

function getDirection(dir = 'ascending') {
  switch (dir) {
    case 'asc':
      return 'ascending';
    case 'desc':
      return 'descending';
    default:
      return 'ascending';
  }
}

function changeDirection(dir: string | undefined = 'asc') {
  return dir === 'asc' ? 'desc' : 'asc';
}

function FilteredDocumentTable({
  isLoading = false,
  documents = [],
  pagination,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  onDownloadClick,
}: DocumentTableProps) {
  const { filter, updateFilter } = useDocumentFilter();
  const { control } = useForm({ defaultValues: filter });

  const debouncedFilter = debounce(updateFilter, 600);

  function handleChange(name: string, value: string) {
    debouncedFilter({ [name]: value });
  }

  function getSorted(name?: string) {
    return filter.orderBy === name ? getDirection(filter.sort) : undefined;
  }

  function handleHeaderClick(orderBy: string) {
    updateFilter({
      orderBy,
      sort: changeDirection(filter.sort),
    });
  }

  return (
    <>
      <DocumentFilter control={control} onChangeTest={handleChange} />
      {!isLoading && !documents.length ? (
        <Section>
          <Section.Content>
            <EmptyState>
              <EmptyState.Header>Nenhum documento encontrado</EmptyState.Header>
            </EmptyState>
          </Section.Content>
        </Section>
      ) : (
        <Section>
          <Section.Header>
            <PaginationInfo description="Documentos" pagination={pagination} />
          </Section.Header>
          <Section.Content>
            <Table size="small" basic="very" fixed singleLine sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={getSorted('name')}
                    onClick={() => handleHeaderClick('name')}
                  >
                    Nome
                  </Table.HeaderCell>
                  <Table.HeaderCell width={2}>Extensão</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={getSorted('type')}
                    onClick={() => handleHeaderClick('type')}
                  >
                    Tipo
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={getSorted('size')}
                    onClick={() => handleHeaderClick('size')}
                  >
                    Tamanho
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    width={2}
                    sorted={getSorted('createdAt')}
                    onClick={() => handleHeaderClick('createdAt')}
                  >
                    Criado em
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading && <LoadingRow columns={COLUMNS} rows={ROWS} />}
                {documents?.map((user) => {
                  const { id, createdAt, name, size, type } = user;

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>
                        <Text>
                          <button
                            type="button"
                            style={{
                              all: 'unset',
                              color: '#4a9add',
                              cursor: 'pointer',
                            }}
                            onClick={() => onDownloadClick(id)}
                          >
                            {name.replace(/\.[^/.]+$/, '')}
                          </button>
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          {name
                            .split('.')
                            .pop()
                            ?.toUpperCase()}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Tag color="grey">{type.description}</Tag>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>{fileSize(size)}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
                        </Text>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                <NextPageRow
                  fetchNextPage={fetchNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  hasNextPage={hasNextPage}
                />
              </Table.Body>
            </Table>
          </Section.Content>
        </Section>
      )}
    </>
  );
}

type DocumentFilterProps = {
  control: Control;
  onChangeTest: (name: string, value: string) => void;
};

const documentTypeOptions = Object.entries(DOCUMENT_TYPE_DESCRIPTION)
  .map(([key, value]) => ({
    value: key,
    label: value,
  }))
  .slice()
  .sort((a, b) => (a.label > b.label ? 1 : -1));

const documentExtensionOptions = Object.entries(DOCUMENT_EXTENSION)
  .map(([key, value]) => ({
    value: key,
    label: value.toUpperCase(),
  }))
  .slice()
  .sort((a, b) => (a.label > b.label ? 1 : -1));

function DocumentFilter({ control, onChangeTest }: DocumentFilterProps) {
  return (
    <Form>
      <Form.Group>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="name"
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                onBlur={onBlur}
                onChange={(e, data) => {
                  onChangeTest('name', data?.value || '');
                  onChange(e, data);
                }}
                placeholder="Nome"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="extension"
            render={({ onChange, onBlur, value }) => (
              <Select
                instanceId="extension"
                name="extension"
                onBlur={onBlur}
                isClearable
                onChange={(data) => {
                  onChangeTest('extension', data || '');
                  onChange(data);
                }}
                options={documentExtensionOptions}
                placeholder="Extensão"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="type"
            render={({ onChange, onBlur, value }) => (
              <Select
                instanceId="type"
                name="type"
                isClearable
                onBlur={onBlur}
                onChange={(data) => {
                  onChangeTest('type', data || '');
                  onChange(data);
                }}
                options={documentTypeOptions}
                placeholder="Tipo"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}

function NextPageRow({
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: NextPageProps) {
  return (
    <Table.Row>
      <Table.Cell textAlign="center" colSpan={COLUMNS}>
        <Divider hidden />
        {hasNextPage ? (
          <Button
            type="button"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? 'Carregando...' : ''}
            {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
          </Button>
        ) : (
          <Text variant="secondary">Nada mais para mostrar</Text>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default FilteredDocumentTable;
