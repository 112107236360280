import React, { useState } from 'react';

import { Dropdown } from 'semantic-ui-react';

import useDebounce from 'hooks/useDebounce';
import { useContracts } from 'queries/contract';

export default function ContractSelect({
  name,
  onChange,
  defaultValue,
  defaultSearchQuery,
  fluid = false,
}) {
  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce(search, 300);

  const contractParams = {
    page: 1,
    pageSize: 5,
    orderBy: 'number',
    sort: 'asc',
    number: debouncedSearch,
  };
  const { data, isLoading } = useContracts(contractParams);

  const handleSearchChange = (event, { searchQuery }) => setSearch(searchQuery);

  return (
    <Dropdown
      name={name}
      placeholder="Selecione..."
      noResultsMessage="Nenhum resultado encontrado."
      autoComplete="off"
      options={
        data?.data?.map(({ id, number }) => ({
          key: id,
          value: id,
          text: number,
        })) || []
      }
      onChange={onChange}
      onSearchChange={handleSearchChange}
      defaultValue={defaultValue}
      defaultSearchQuery={defaultSearchQuery}
      loading={isLoading}
      fluid={fluid}
      selection
      clearable
      search
    />
  );
}
