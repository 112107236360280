import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useSortCollection } from 'data/collection';
import { emptyStringToUndefined } from 'util/validation';

export type CollectionSortingModalProps = {
  isOpen: boolean;
  onClose: () => void;
  collectionId: number;
};

const FORM_ID = 'form_collection_sorting';

const SortCollectionInput = yup
  .object()
  .shape({
    arrivalDate: yup
      .date()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    sortingDate: yup
      .date()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    liveInsects: yup
      .number()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    deadInsects: yup
      .number()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    parasitizedInsects: yup
      .number()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    notes: yup
      .string()
      .optional()
      .nullable()
      .transform(emptyStringToUndefined),
  })
  .required();

type CollectionSortingFormValues = yup.InferType<typeof SortCollectionInput>;

export default function CollectionSortingModal({
  isOpen,
  onClose,
  collectionId,
}: CollectionSortingModalProps) {
  const resolver = yupResolver(SortCollectionInput);
  const { control, errors, handleSubmit } = useForm<
    CollectionSortingFormValues
  >({
    mode: 'onBlur',
    resolver,
  });

  const { mutate: sortCollection, isLoading } = useSortCollection({
    onSuccess: () => handleSuccess(),
  });

  function handleSuccess() {
    toast.success('Coleta triada!');
    onClose();
  }

  function onSubmit(values: CollectionSortingFormValues) {
    const input = {
      ...values,
      collectionId,
    };
    sortCollection(input);
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Nova triagem</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Data de chegada</label>
            <Controller
              control={control}
              name="arrivalDate"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  disabled={isLoading}
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
            {errors.arrivalDate && (
              <InputError>{errors?.arrivalDate?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Data de triagem</label>
            <Controller
              control={control}
              name="sortingDate"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  disabled={isLoading}
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
            {errors.sortingDate && (
              <InputError>{errors?.sortingDate?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field />
          <Form.Field>
            <label>Insetos vivos</label>
            <Controller
              control={control}
              name="liveInsects"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  autoComplete="off"
                  decimalScale={0}
                  decimalSeparator=","
                  disabled={isLoading}
                  onBlur={onBlur}
                  onValueChange={(data) => {
                    onChange(data.floatValue);
                  }}
                  thousandSeparator="."
                  value={value}
                />
              )}
            />
            {errors.liveInsects && (
              <InputError>{errors?.liveInsects.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Insetos mortos</label>
            <Controller
              control={control}
              name="deadInsects"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  autoComplete="off"
                  decimalScale={0}
                  decimalSeparator=","
                  disabled={isLoading}
                  onBlur={onBlur}
                  onValueChange={(data) => {
                    onChange(data.floatValue);
                  }}
                  thousandSeparator="."
                  value={value}
                />
              )}
            />
            {errors.deadInsects && (
              <InputError>{errors?.deadInsects.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Insetos parasitados</label>
            <Controller
              control={control}
              name="parasitizedInsects"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  autoComplete="off"
                  decimalScale={0}
                  decimalSeparator=","
                  disabled={isLoading}
                  onBlur={onBlur}
                  onValueChange={(data) => {
                    onChange(data.floatValue);
                  }}
                  thousandSeparator="."
                  value={value}
                />
              )}
            />
            {errors.parasitizedInsects && (
              <InputError>{errors?.parasitizedInsects.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Observações (opcional)</label>
            <Controller
              control={control}
              name="notes"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  autoComplete="off"
                  disabled={isLoading}
                  fluid
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Triar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
