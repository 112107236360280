import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Modal, Button, Message, Form, Grid } from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useAddPackaging } from 'mutations/sample';

export const AddPackaging = yup
  .object()
  .shape({ volume: yup.number().required('Obrigatório') })
  .required();

export type PackagingFormValues = {
  volume: number;
};

export type PackagingModalFormProps = {
  sampleId: number;
  unit: string;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

const formId = 'form_location';

export default function PackagingModalForm({
  sampleId,
  unit,
  isOpen,
  onClose,
  onSuccess,
}: PackagingModalFormProps) {
  const { handleError } = useErrorHandler();

  const resolver = yupResolver(AddPackaging);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const {
    mutate: addPackaging,
    isLoading: isAdding,
    error: createError,
  } = useAddPackaging({
    onSuccess: () => onSuccess('Embalagem adicionada com sucesso!'),
  });

  function onSubmit(values: PackagingFormValues) {
    addPackaging({ ...values, sampleId });
  }
  const isSubmitting = isAdding;

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Nova embalagem</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  <label>Volume</label>
                  <Controller
                    control={control}
                    name="volume"
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        onValueChange={(data) => {
                          onChange(data.floatValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        placeholder={`ex: 200 ${unit}`}
                        decimalSeparator=","
                        thousandSeparator="."
                        suffix={` ${unit}`}
                        isNumericString
                        decimalScale={3}
                        disabled={isSubmitting}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.volume && (
                    <InputError>{errors?.volume?.message}</InputError>
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {createError && <Message content={handleError(createError)} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
