import React from 'react';

import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';
import { formatUnit } from 'util/unitFormatter';

type ProductIngredient = {
  ingredient: Ingredient;
  concentration?: number;
  measurementUnit?: number;
};

type ChemicalGroup = {
  id: number;
  description: string;
};

type ProductCategory = {
  id: number;
  description: string;
  insideCode: string;
};

type Ingredient = {
  id: number;
  name: string;
  categories: [ProductCategory];
  chemicalGroup?: ChemicalGroup;
};

type ProductIngredientTableProps = {
  productIngredients: [ProductIngredient];
  onRemoveClick: (index: number) => void;
  loading?: boolean;
};

const ProductIngredientTable = ({
  productIngredients,
  onRemoveClick,
  loading = false,
}: ProductIngredientTableProps) => {
  return (
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Nome</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Concentração</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Unidade</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={2} rows={5} />}
        {!loading &&
          productIngredients?.map((productIngredient, index) => (
            <Table.Row key={productIngredient.id}>
              <Table.Cell textAlign="left">
                {productIngredient.ingredient.name}
              </Table.Cell>
              <Table.Cell textAlign="right">
                {productIngredient?.concentration?.toLocaleString(undefined, {
                  minimumFractionDigits: 3,
                }) || 'Não informada'}
              </Table.Cell>
              <Table.Cell textAlign="left">
                {formatUnit(productIngredient?.measurementUnit) ||
                  'Não informada'}
              </Table.Cell>
              <Table.Cell textAlign="center">
                <LinkButton
                  onClick={() => {
                    onRemoveClick(index);
                  }}
                >
                  Remover
                </LinkButton>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
};

export default ProductIngredientTable;
