import { useQuery } from 'react-query';

import api from 'util/api';

export const procedureKeys = {
  all: ['quotations'] as const,
};

export function useProcedures() {
  const result = useQuery(procedureKeys.all, () => getProcedures());
  return {
    ...result,
    procedures: result.data || [],
  };
}

type ProcedureQuery = {
  id: number;
  code: string;
  revision: number;
  title: string;
  objective: string;
  documentId: number;
};

async function getProcedures() {
  const { data } = await api.get<ProcedureQuery[]>(`/procedures`);
  return data;
}
