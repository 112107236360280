import { AxiosError } from 'axios';

type Response = {
  status?: number;
  message?: string;
};

const NETWORK_ERROR = 'Network Error';

const networkErroMessage = 'Erro de conexão. Tente novamente.';
const genericErrorMessage = 'Ops! Alguma coisa não funcionou direito';

const isInternalError = (error: Error) => {
  if (
    error instanceof TypeError ||
    error instanceof EvalError ||
    error instanceof RangeError ||
    error instanceof ReferenceError ||
    error instanceof SyntaxError
  ) {
    return true;
  }

  return false;
};

const handleError = (error: Error) => {
  if (isInternalError(error)) {
    throw error;
  }

  if (error.message === NETWORK_ERROR) {
    return networkErroMessage;
  }

  if (isAxiosError<Response>(error)) {
    return error.response?.data?.message || genericErrorMessage;
  }

  if (isAxiosError<Response>(error)) {
    return error.response?.data?.message || genericErrorMessage;
  }

  return genericErrorMessage;
};

export function isAxiosError<T>(error: Error): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

export function useErrorHandler() {
  return { handleError };
}
