export const JobPosition = {
  Administrator: 1,
  Researcher: 2,
  Analyst: 3,
  Technician: 4,
};

export type JobPosition = typeof JobPosition[keyof typeof JobPosition];

export const JobPositionDescription = {
  [JobPosition.Administrator]: 'Administrador(a)',
  [JobPosition.Researcher]: 'Pesquisador(a)',
  [JobPosition.Analyst]: 'Analista',
  [JobPosition.Technician]: 'Técnico(a)',
};

export type Employee = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  jobPosition: JobPosition;
  photo: string | null;
  creaSp: string | null;
  isFormer: boolean;
};
