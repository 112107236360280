import React from 'react';

import fileSize from 'filesize';
import toast from 'react-hot-toast';
import {
  Grid,
  Header,
  Divider,
  Button,
  Icon,
  Loader,
  Table,
} from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LinkButton from 'components/inputs/LinkButton';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import ProjectDocumentsModal from 'components/modal/ProjectDocumentsModal';
import { downloadDocument } from 'hooks/document';
import { useModalWithData } from 'hooks/useModal';
import {
  useCreateApplication,
  useRemoveApplication,
  useUpdateApplication,
} from 'mutations/application';
import { useApplications } from 'queries/application';
import { parseIso } from 'util/DateFormatter';

import ApplicationForm from './ApplicationForm';
import ApplicationsTable from './ApplicationsTable';

const MESSAGE_CREATE_SUCCESS = 'Aplicação adicionada com sucesso!';
const MESSAGE_UPDATE_SUCCESS = 'Aplicação atualizada com sucesso!';
const MESSAGE_REMOVE_SUCCESS = 'Aplicação removida com sucesso!';

export default function ProjectApplicationsPage({
  projectId,
  installation,
  documents,
}) {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedApplication, setSelectedApplication] = React.useState(null);

  const uploadModal = useModalWithData();
  const {
    isOpen: removeModalOpen,
    data: selectedApplicationToRemove,
    open,
    close,
  } = useModalWithData();

  const { data: applicationsData, isLoading } = useApplications({
    projectId,
    page: 1,
    pageSize: 100,
    orderBy: 'startDate',
    sort: 'asc',
  });

  React.useEffect(() => {
    if (selectedApplication) {
      setModalOpen(true);
    }
  }, [selectedApplication]);

  const {
    mutate: mutateCreateApplication,
    isLoading: isCreating,
  } = useCreateApplication({
    onSuccess: () => onSuccess(MESSAGE_CREATE_SUCCESS),
  });

  const {
    mutate: mutateUpdateApplication,
    isLoading: isUpdating,
  } = useUpdateApplication({
    onSuccess: () => onSuccess(MESSAGE_UPDATE_SUCCESS),
  });

  const {
    mutate: mutateRemoveApplication,
    isLoading: isRemoving,
  } = useRemoveApplication({
    onSuccess: onRemoveSuccess,
    onError,
  });

  function onSuccess(message) {
    toggleModal();
    toast.success(message);
  }

  function toggleModal() {
    setModalOpen(!modalOpen);
  }

  function handleAddAttachmentClick() {
    uploadModal.open();
  }

  function handleEditAttachmentClick(documentIds) {
    uploadModal.open(documentIds);
  }

  function handleAddClick() {
    setSelectedApplication(null);
    setModalOpen(true);
  }

  function handleEditClick(application) {
    setSelectedApplication(application);
  }

  function handleRemoveClick(project) {
    open(project);
  }

  function handleClose() {
    setModalOpen(false);
    setSelectedApplication(null);
  }

  function handleRemoveClose() {
    close();
  }

  function onRemoveSuccess() {
    close();
    toast.success(MESSAGE_REMOVE_SUCCESS);
  }

  function onError() {
    close();
  }

  async function handleSubmit(values) {
    const updatedValues = mapFromForm(values);

    if (selectedApplication) {
      mutateUpdateApplication({
        ...updatedValues,
        projectId,
        applicationId: selectedApplication?.id,
      });
      return;
    }

    mutateCreateApplication({ ...updatedValues, projectId });
  }

  function handleRemoveSubmit() {
    if (selectedApplicationToRemove) {
      mutateRemoveApplication({
        projectId,
        applicationId: selectedApplicationToRemove.id,
      });
    }
  }

  if (!installation) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Estudo sem localização</EmptyState.Header>
            <EmptyState.Description>
              Adicione a localização do estudo para poder adicionar aplicações e
              anexar documentos assinados
            </EmptyState.Description>
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  const isEmpty = applicationsData?.applications?.length === 0;
  const applicationDocuments =
    documents?.filter((document) => document.type === 15) || [];

  return (
    <>
      <AlertModal
        header="Remover aplicação"
        content="Você tem certeza que deseja remover esta aplicação?"
        submitText="Remover"
        onClose={handleRemoveClose}
        onSubmit={handleRemoveSubmit}
        isOpen={removeModalOpen}
        isLoading={isRemoving}
        negative
      />

      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Header>
                <Header as="h3" style={{ margin: 0 }}>
                  Anexos de aplicação
                </Header>
                {applicationDocuments.length ? (
                  <Button
                    size="small"
                    basic
                    onClick={() =>
                      handleEditAttachmentClick(
                        applicationDocuments.map(({ id }) => id),
                      )
                    }
                  >
                    <Icon name="edit" /> Editar
                  </Button>
                ) : null}
              </Section.Header>
              <Section.Content>
                {applicationDocuments.length > 0 ? (
                  <Table basic="very" fixed>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>
                          #
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">
                          Nome
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          Tamanho
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {applicationDocuments.map((document, index) => (
                        <Table.Row key={document.id.toString()}>
                          <Table.Cell textAlign="left" width={1}>
                            {index + 1}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {document.name}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {fileSize(document.size)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <LinkButton
                              type="button"
                              onClick={() => downloadDocument(document.id)}
                            >
                              Baixar
                            </LinkButton>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <EmptyState>
                    <EmptyState.Header>Nenhum anexo</EmptyState.Header>
                    <EmptyState.Description>
                      Adicione anexos de aplicação
                    </EmptyState.Description>
                    <EmptyState.Actions>
                      <Button
                        size="small"
                        basic
                        onClick={handleAddAttachmentClick}
                      >
                        <Icon name="add" /> Adicionar
                      </Button>
                    </EmptyState.Actions>
                  </EmptyState>
                )}
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
        {isEmpty && !isLoading && (
          <Grid.Column width={16}>
            <Section>
              <Header as="h3">Aplicações</Header>
              <EmptyState>
                <EmptyState.Header>
                  Nenhuma aplicação adicionada
                </EmptyState.Header>
                <EmptyState.Description>
                  Clique no botão abaixo para adicionar uma aplicação
                </EmptyState.Description>
                <EmptyState.Actions>
                  <Button type="button" onClick={handleAddClick} primary basic>
                    <Icon name="add" />
                    Adicionar
                  </Button>
                </EmptyState.Actions>
              </EmptyState>
            </Section>
          </Grid.Column>
        )}
        {isLoading ? (
          <Grid.Column width={16}>
            <Loader active size="large" style={{ marginTop: '200px' }}>
              Carregando...
            </Loader>
          </Grid.Column>
        ) : null}
        {!isLoading && !isEmpty ? (
          <>
            <Grid.Column width={8} textAlign="left" verticalAlign="middle">
              <Header as="h3">Aplicações</Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right" verticalAlign="middle">
              <Button
                type="button"
                onClick={() => handleAddClick()}
                primary
                basic
              >
                <Icon name="add" />
                Nova aplicação
              </Button>
            </Grid.Column>
          </>
        ) : null}
        {!isLoading &&
          applicationsData?.applications?.map((application, index) => {
            return (
              <Grid.Column width={8} key={index.toString()}>
                <ApplicationsTable
                  application={application}
                  number={index + 1}
                  onEditClick={handleEditClick}
                  onRemoveClick={handleRemoveClick}
                />
              </Grid.Column>
            );
          })}
      </Grid>

      <Divider hidden />
      <Divider hidden />

      {modalOpen ? (
        <ApplicationForm
          formValues={
            selectedApplication
              ? mapToForm({ ...selectedApplication })
              : undefined
          }
          onClose={handleClose}
          onSubmit={handleSubmit}
          open={modalOpen}
          isSubmiting={isCreating || isUpdating}
        />
      ) : null}

      {uploadModal.isOpen ? (
        <ProjectDocumentsModal
          projectId={projectId}
          documentIds={uploadModal.data}
          documentType="application"
          isOpen={uploadModal.isOpen}
          onClose={uploadModal.close}
          onSuccess={() => {
            toast.success('Documentos anexados com sucesso!');
            uploadModal.close();
          }}
        />
      ) : null}
    </>
  );
}

function mapToForm(application) {
  return {
    id: application?.id,
    date: application?.startDate ? parseIso(application?.startDate) : undefined,
    startTime: application?.startDate
      ? parseIso(application?.startDate)
      : undefined,
    endTime: application?.endDate ? parseIso(application?.endDate) : undefined,
    startTemperature: application.startTemperature,
    endTemperature: application?.endTemperature,
    startHumidity: application?.startHumidity,
    endHumidity: application?.endHumidity,
    windSpeed: application?.windSpeed,
    sky: application?.sky,
    soil: application?.soil,
    cropStage: application?.cropStage,
    bbchScale: application?.bbchScale,
    seedTreatment: application?.seedTreatment?.toString(),
    equipment: application?.equipment,
    equipmentPressure: application?.equipmentPressure,
    equipmentVolume: application?.equipmentVolume,
    volumeUnit: application?.volumeUnit
      ? {
          value: application?.volumeUnit?.id,
          label: `${application?.volumeUnit?.description} ${application?.volumeUnit?.abbreviation}`,
        }
      : null,
    windSpeedUnit: application?.windSpeedUnit?.id?.toString() || null,
  };
}

function mapFromForm(values) {
  return {
    ...values,
    cropStage: values?.cropStage || null,
    soil: values?.soil || null,
    windSpeedUnit: values?.windSpeedUnit || null,
    seedTreatment: values?.seedTreatment === 'true',
    equipment: values?.equipment || null,
    equipmentPressure: values?.equipmentPressure || null,
    equipmentVolume: values?.equipmentVolume || null,
    volumeUnit: values?.volumeUnit?.value || null,
  };
}
