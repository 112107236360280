import fileSize from 'filesize';
import { useQuery } from 'react-query';

import api from 'util/api';

export function useDocumentInput(documentId: number) {
  return useQuery({
    queryKey: ['documentInput', documentId],
    queryFn: () => getDocumentInput(documentId),
    enabled: !!documentId,
    staleTime: Infinity,
  });
}

export function useDocumentInputs(documentIds: number[]) {
  return useQuery({
    queryKey: ['documentInputs', documentIds],
    queryFn: () => getDocumentInputs(documentIds),
    enabled: !!documentIds,
    staleTime: Infinity,
  });
}

async function getDocumentInputs(documentIds: number[]) {
  const promises = documentIds.map((id) => getDocumentInput(id));
  const documentInputs = await Promise.all(promises);
  return documentInputs;
}

export async function getDocumentInput(documentId: number) {
  const documentPromise = getDocument(documentId);
  const documentFilePromise = getDocumentFile(documentId);

  const [{ id, url }, { file, filename }] = await Promise.all([
    documentPromise,
    documentFilePromise,
  ]);

  return {
    error: false,
    file,
    id,
    name: filename,
    progress: 100,
    readableSize: fileSize(file.size),
    uploaded: true,
    url,
  };
}

type DocumentQuery = {
  id: number;
  name: string;
  size: number;
  key: string;
  url: string;
  type: number;
};

async function getDocument(documentId: number) {
  const { data } = await api.get<DocumentQuery>(`/documents/${documentId}`);
  return data;
}

async function getDocumentFile(documentId: number) {
  const { data, headers } = await api.get<File>(
    `/documents/${documentId}/file`,
    {
      responseType: 'blob',
    },
  );
  const { filename } = headers;
  return { file: data, filename };
}
