import React from 'react';

import { Table, Popup, Button, Icon, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import { formatPhone } from 'util/mask';

export default function RepresentativesTable({
  representatives = [],
  onAddClick,
  onEditClick,
  onRemoveClick,
  isLoading = false,
  isFetching = false,
  isEditable = false,
}) {
  return (
    <Container>
      {isEditable && (
        <Grid>
          <Grid.Column>
            {isEditable && (
              <Button type="button" floated="right" onClick={onAddClick} basic>
                <Icon name="add" />
                Novo representante
              </Button>
            )}
          </Grid.Column>
        </Grid>
      )}
      <Table size="small" basic="very" fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nome</Table.HeaderCell>
            <Table.HeaderCell>Contato</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoading && <LoadingRow columns={3} rows={3} />}
          {!isLoading &&
            representatives.map((representative, index) => (
              <Table.Row key={representative.id}>
                <Table.Cell>
                  <Text variant="primary">{representative.name}</Text>
                </Table.Cell>
                <Table.Cell>
                  <Text variant="primary">{representative.email}</Text>
                  <Text variant="secondary">
                    {representative.phone
                      ? formatPhone(representative.phone, 1)
                      : 'n/d'}
                  </Text>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  {onRemoveClick ? (
                    <Popup
                      trigger={
                        <Button
                          onClick={() => onRemoveClick(index, representative)}
                          circular
                          basic
                          icon
                        >
                          <Icon name="remove" />
                        </Button>
                      }
                      content="Remover"
                      size="tiny"
                      mouseEnterDelay={500}
                      inverted
                    />
                  ) : null}
                  {onEditClick ? (
                    <Popup
                      trigger={
                        <Button
                          onClick={() => onEditClick(representative, index)}
                          circular
                          basic
                          icon
                        >
                          <Icon name="edit" />
                        </Button>
                      }
                      content="Editar"
                      size="tiny"
                      mouseEnterDelay={500}
                      inverted
                    />
                  ) : null}
                </Table.Cell>
              </Table.Row>
            ))}
          {isFetching ? <LoadingRow columns={6} rows={1} /> : null}
        </Table.Body>
      </Table>
    </Container>
  );
}

export const Container = styled.div`
  margin-top: 16px;
`;
