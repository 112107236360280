import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useMutation, useQueryClient } from 'react-query';
import { Modal, Button, Form, Message, Input, Grid } from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import PhoneInput from 'components/inputs/PhoneInput';
import { customerRepository } from 'container';
import { handleError } from 'util/ErrorHandler';
import { formatCpfOrCnpj } from 'util/mask';
import { emptyStringToUndefined } from 'util/validation';

const required = 'é um campo obrigatório';

export const EditCustomer = yup.object().shape({
  companyName: yup.string().required(`Razão social ${required}`),
  tradingName: yup.string().required(`Nome fantasia ${required}`),
  insideCode: yup
    .string()
    .optional()
    .length(3, 'Código interno deve conter 3 caracteres')
    .nullable()
    .transform(emptyStringToUndefined),
  cnpj: yup
    .string()
    .max(14, 'CNPJ/CPF deve conter no máximo 14 números')
    .optional('Telefone é um campo obrigatório')
    .nullable()
    .transform(emptyStringToUndefined),
  phone: yup
    .string()
    .min(10, 'Telefone deve conter no mínimo 10 números')
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
});

export default function EditCustomerModal({
  onClose,
  onSuccess,
  customer,
  open,
}) {
  const FORM_ID = 'EDIT_CUSTOMER_FORM';

  const [error, setError] = React.useState(null);

  const formRef = React.useRef(null);

  const defaultValues = customer;
  const resolver = yupResolver(EditCustomer);

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const queryClient = useQueryClient();
  const { mutate: updateCustomer, isLoading: isSubmiting } = useMutation(
    customerRepository.updateCustomer,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customer');
        onSuccess();
      },
      onError,
    },
  );

  function onError(e) {
    const message = handleError(e);
    setError(message);
  }

  function onSubmit(values) {
    const { id: customerId } = customer;

    const updatedValues = mapFromForm(values);

    const input = {
      customerId,
      ...updatedValues,
    };

    updateCustomer(input);
  }

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>Editar patrocinador</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Grid verticalAlign="middle">
            <Grid.Column width={16}>
              <Form.Field>
                <label>Razão social</label>
                <Controller
                  control={control}
                  name="companyName"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="new-password"
                      fluid
                    />
                  )}
                />
                {errors.companyName && (
                  <InputError>{errors?.companyName?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Nome fantasia</label>
                <Controller
                  control={control}
                  name="tradingName"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="new-password"
                      fluid
                    />
                  )}
                />
                {errors.tradingName && (
                  <InputError>{errors?.tradingName?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>CNPJ/CPF</label>
                <Controller
                  control={control}
                  name="cnpj"
                  render={({ onChange, onBlur, value }) => (
                    <NumberFormat
                      allowEmptyFormatting
                      autoComplete="off"
                      disabled={isSubmiting}
                      format={formatCpfOrCnpj}
                      isNumericString
                      mask="_"
                      maxLength={18}
                      onBlur={onBlur}
                      onValueChange={(data) => {
                        onChange(data.value);
                      }}
                      value={value}
                    />
                  )}
                />
                {errors.cnpj && (
                  <InputError>{errors?.cnpj?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Telefone</label>
                <Controller
                  control={control}
                  name="phone"
                  render={({ onChange, onBlur, value }) => (
                    <PhoneInput
                      onValueChange={(data) => onChange(data.value)}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.phone && (
                  <InputError>{errors?.phone?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Código interno (opcional)</label>
                <Controller
                  control={control}
                  name="insideCode"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="ex: 01a"
                      maxLength={3}
                      disabled={isSubmiting}
                      autoComplete="off"
                      fluid
                    />
                  )}
                />
                {errors.insideCode && (
                  <InputError>{errors?.insideCode?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Inscrição estadual (opcional)</label>
                <Controller
                  control={control}
                  name="stateRegistration"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      placeholder="ex: 123456789"
                      disabled={isSubmiting}
                      autoComplete="new-password"
                      fluid
                    />
                  )}
                />
                {errors.stateRegistration && (
                  <InputError>{errors?.stateRegistration?.message}</InputError>
                )}
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form={FORM_ID} loading={isSubmiting} primary>
          Atualizar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function mapFromForm(values) {
  return {
    companyName: values.companyName,
    tradingName: values.tradingName,
    phone: values.phone,
    cnpj: values.cnpj || null,
    insideCode: values.insideCode || null,
    stateRegistration: values.stateRegistration || null,
  };
}
