import React from 'react';

import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';

import PropertyForm from 'components/forms/PropertyForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import { useUpdateProperty } from 'mutations/property';
import { getProperty as getPropertyUseCase } from 'queries/property';

export default function EditProperty() {
  const history = useHistory();
  const { propertyId } = useParams();

  const [{ data: property, loading: isLoading }, getProperty] = useAsync(
    getPropertyUseCase,
  );

  const { mutate: updateProperty, isLoading: isUpdating } = useUpdateProperty({
    onSuccess,
  });

  function onSuccess(message) {
    toast.success(message);
    history.goBack();
  }

  React.useEffect(() => {
    getProperty(propertyId);
  }, [getProperty, propertyId]);

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);
    const updatedProperty = {
      ...updatedValues,
      propertyId: parseInt(propertyId, 10),
    };
    updateProperty(updatedProperty);
  }

  return (
    <Base loading={isLoading} text>
      <Container>
        {property ? (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Edição de propriedade</Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <PropertyForm
                      formData={mapToForm(property)}
                      onSubmit={handleSubmit}
                      isSubmiting={isUpdating}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </Container>
    </Base>
  );
}

function mapToForm(property) {
  return {
    ...property,
    state: {
      value: property.state,
      label: property.state.toUpperCase(),
    },
    ownerState: property.ownerState
      ? {
          value: property.ownerState,
          label: property.ownerState.toUpperCase(),
        }
      : null,
  };
}

function mapFromForm(values) {
  return {
    ...values,
    state: values.state.value,
    ownerAddress: values.ownerAddress || null,
    ownerNeighborhood: values.ownerNeighborhood || null,
    ownerCity: values.ownerCity || null,
    ownerState: values?.ownerState?.value || null || null,
    ownerCep: values.ownerCep || null,
  };
}
