import { useState, useCallback, useEffect } from 'react';

export const AsyncStatuses = {
  INIT: 'INIT',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
};

function useAsync(asyncFunction, immediate = false) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState(AsyncStatuses.INIT);

  const execute = useCallback(
    async (...params) => {
      setLoading(true);
      setStatus(AsyncStatuses.LOADING);

      try {
        const callResult = await asyncFunction(...params);

        setData(callResult);
        setLoading(false);
        setStatus(AsyncStatuses.SUCCESS);
      } catch (e) {
        setError(e);
        setLoading(false);
        setStatus(AsyncStatuses.FAILURE);
      }
    },
    [asyncFunction],
  );

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return [{ data, loading, error, status }, execute];
}

export default useAsync;
