import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { getProjectStage, getProjectStageColor } from 'data/project';
import {
  getDivisionDescription,
  getProjectGoalDescription,
} from 'util/Project';

import { useProjectFilter } from './use-project-filter';

const direction = {
  asc: 'ascending',
  desc: 'descending',
};

const changeDirection = {
  asc: 'desc',
  desc: 'asc',
};

const Wrapper = styled.div`
  ${({ isEmpty }) => css`
    /* min-height: 951.69px; */
    ${!isEmpty
      ? css`
          height: auto;
          min-height: 200;
          overflow-x: scroll;
        `
      : css`
          overflow: scroll;
        `}
  `}
`;

export default function ProjectTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  pagination,
  projects = [],
}) {
  const { filter, updateFilter } = useProjectFilter();
  const isEmpty = !isLoading && !projects.length;
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Estudos" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Wrapper isEmpty={isEmpty}>
          <Table size="small" basic="very" sortable singleLine unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={
                    filter.orderBy === 'insideCode'
                      ? direction[filter.sort]
                      : null
                  }
                  onClick={() =>
                    updateFilter({
                      orderBy: 'insideCode',
                      sort: changeDirection[filter.sort],
                    })
                  }
                >
                  Código
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    filter.orderBy === 'harvest' ? direction[filter.sort] : null
                  }
                  onClick={() =>
                    updateFilter({
                      orderBy: 'harvest',
                      sort: changeDirection[filter.sort],
                    })
                  }
                >
                  Safra
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    filter.orderBy === 'tradingName'
                      ? direction[filter.sort]
                      : null
                  }
                  onClick={() =>
                    updateFilter({
                      orderBy: 'tradingName',
                      sort: changeDirection[filter.sort],
                    })
                  }
                >
                  Cliente
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    filter.orderBy === 'protocol'
                      ? direction[filter.sort]
                      : null
                  }
                  onClick={() =>
                    updateFilter({
                      orderBy: 'protocol',
                      sort: changeDirection[filter.sort],
                    })
                  }
                >
                  Protocolo
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={
                    filter.orderBy === 'project_stage_id'
                      ? direction[filter.sort]
                      : null
                  }
                  onClick={() =>
                    updateFilter({
                      orderBy: 'project_stage_id',
                      sort: changeDirection[filter.sort],
                    })
                  }
                >
                  Fase
                </Table.HeaderCell>
                <Table.HeaderCell>Setor</Table.HeaderCell>
                <Table.HeaderCell>Cultura e alvo</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">
                  Data requerida
                </Table.HeaderCell>
                <Table.HeaderCell>Contrato</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading ? <LoadingRow columns={12} rows={5} /> : null}
              {/* {isEmpty ? (
              <Grid>
                <Grid.Column>
                  <EmptyState align="flex-start">
                    <EmptyState.Header>
                      Nenhum dado encontrado
                    </EmptyState.Header>
                    <EmptyState.Actions>
                      <Button type="button" onClick={handleResetClick} basic>
                        Limpar filtros
                      </Button>
                    </EmptyState.Actions>
                  </EmptyState>
                </Grid.Column>
              </Grid>
            ) : null} */}
              {!isLoading &&
                projects.map(
                  ({
                    id,
                    harvest,
                    insideCode,
                    protocol,
                    customer,
                    representative,
                    division,
                    projectCrops,
                    targets,
                    projectGoal,
                    projectStage,
                    requiredDate,
                    reassignedDate,
                    responsible,
                    contract,
                  }) => {
                    const responsibleText = responsible
                      ? `${responsible.firstName} ${responsible.lastName}`
                      : 'Não definido';
                    const cropsFormatted = projectCrops
                      .map((projectCrop) => projectCrop.crop.name)
                      .join(', ');
                    const targetsFormatted = targets
                      .map((target) => target.name)
                      .join(', ');
                    const requiredDateFormatted = DateTime.fromISO(
                      requiredDate,
                    ).toFormat('dd/MM/yyyy');
                    const reassignedDateFormatted = reassignedDate
                      ? `rem. ${DateTime.fromISO(reassignedDate).toFormat(
                          'dd/MM/yyyy',
                        )}`
                      : `-`;

                    return (
                      <Table.Row key={id}>
                        <Table.Cell title={insideCode || 'Sem código'}>
                          <Text variant="primary">
                            <Link to={`/estudos/${id}`} name="project-details">
                              {insideCode || 'Sem código'}
                            </Link>
                          </Text>
                          <Text variant="secondary">
                            {getProjectGoalDescription(projectGoal)}
                          </Text>
                        </Table.Cell>
                        <Table.Cell>{harvest}</Table.Cell>
                        <Table.Cell>
                          <Text variant="primary">{customer.tradingName}</Text>
                          <Text variant="secondary">{representative.name}</Text>
                        </Table.Cell>
                        <Table.Cell>{protocol}</Table.Cell>
                        <Table.Cell>
                          <Tag color={getProjectStageColor(projectStage)}>
                            {getProjectStage(projectStage)}
                          </Tag>
                        </Table.Cell>
                        <Table.Cell>
                          <Text variant="primary">
                            {getDivisionDescription(division)}
                          </Text>
                          <Text variant="secondary">{responsibleText}</Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Text variant="primary">{cropsFormatted}</Text>
                          <Text variant="secondary">{targetsFormatted}</Text>
                        </Table.Cell>
                        <Table.Cell
                          title={requiredDateFormatted}
                          textAlign="right"
                        >
                          <Text variant="primary">{requiredDateFormatted}</Text>
                          <Text variant="secondary">
                            {reassignedDateFormatted}
                          </Text>
                        </Table.Cell>
                        <Table.Cell>
                          <Link
                            to={`/contratos/${contract.id}`}
                            name="contract"
                          >
                            {contract.number}
                          </Link>
                        </Table.Cell>
                      </Table.Row>
                    );
                  },
                )}
            </Table.Body>
          </Table>
        </Wrapper>
        <Divider />
        {hasNextPage ? (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Carregando...' : ''}
                  {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Text variant="secondary">Nada mais para mostrar</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Section.Content>
    </Section>
  );
}
