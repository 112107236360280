import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Button, Form, Input } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelect';

import { schema } from './validation';

export default function ProjectProtocolDetailsForm({
  projectFormData,
  isSubmiting = false,
  onSubmit,
}) {
  const defaultValues = projectFormData || {};
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const onlyUnits = [5, 6];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Nº de tratamentos</label>
          <Controller
            control={control}
            name="treatmentsNumber"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                maxLength={3}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors.treatmentsNumber && (
            <InputError>{errors?.treatmentsNumber?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Nº de repetições</label>
          <Controller
            control={control}
            name="trialsNumber"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                maxLength={3}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors.trialsNumber && (
            <InputError>{errors?.trialsNumber?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Tamanho da parcela:</label>
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Unidade</label>
          <Controller
            control={control}
            name="blockUnit"
            render={({ onChange, onBlur, value }) => (
              <MeasurementUnitSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                onlyUnits={onlyUnits}
                disabled={isSubmiting}
                autoComplete="off"
                isClearable
              />
            )}
          />
          {errors.blockUnit && <InputError>Obrigatório</InputError>}
        </Form.Field>
        <Form.Field>
          <label>Largura</label>
          <Controller
            control={control}
            name="blockWidth"
            render={({ onChange, onBlur, value }) => (
              <NumberFormat
                onValueChange={(data) => {
                  onChange(data.floatValue);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="12,8"
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                disabled={isSubmiting}
                autoComplete="off"
              />
            )}
          />
          {errors.blockWidth && <InputError>Obrigatório</InputError>}
        </Form.Field>
        <Form.Field>
          <label>Comprimento</label>
          <Controller
            control={control}
            name="blockLength"
            render={({ onChange, onBlur, value }) => (
              <NumberFormat
                onValueChange={(data) => {
                  onChange(data.floatValue);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="12,8"
                decimalSeparator=","
                thousandSeparator="."
                decimalScale={2}
                disabled={isSubmiting}
                autoComplete="off"
              />
            )}
          />
          {errors.blockLength && (
            <InputError>Data de plantio é obrigatório</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Button
        type="submit"
        primary
        size="large"
        style={{ marginTop: 20 }}
        loading={isSubmiting}
      >
        Salvar
      </Button>
    </Form>
  );
}
