import React from 'react';

import Select from 'react-select';

export default function ProjectGoalSelect({
  name,
  placeholder = 'Selecione...',
  value,
  styles = undefined,
  onChange,
  disabled,
  isClearable = false,
}) {
  const options = [
    { value: 1, label: 'Exploratório' },
    { value: 2, label: 'Oficial' },
  ];

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete="off"
      styles={styles}
    />
  );
}
