import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import EmployeeForm from 'components/forms/EmployeeForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useCreateEmployee } from 'hooks/employee';

export default function AddEmployee() {
  const history = useHistory();

  const { mutate: createEmployee, isLoading } = useCreateEmployee({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Colaborador adicionado com sucesso!');
    history.replace(`/configuracoes/colaboradores`);
  }

  async function handleSubmit(values) {
    const input = {
      firstName: values.firstName,
      lastName: values.lastName,
      creaSp: values.creaSp,
      jobPosition: parseInt(values.jobPosition, 10),
    };

    createEmployee(input);
  }

  return (
    <Base text>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Novo colaborador</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <EmployeeForm
                    onSubmit={handleSubmit}
                    isSubmiting={isLoading}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}
