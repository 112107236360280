import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllIngredientsUseCase from 'queries/getAllIngredients';

export default function IngredientSearch({
  name,
  placeholder = 'Busque pelo nome...',
  value = undefined,
  onChange = undefined,
  disabled = false,
  isClearable = false,
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const [{ data, loading, error }, getAllIngredients] = useAsync(
    getAllIngredientsUseCase,
  );

  React.useEffect(() => {
    getAllIngredients({ name: searchValue });
  }, [getAllIngredients, searchValue]);

  const ingredients =
    error || !data || !data.ingredients ? [] : data.ingredients;

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      value={value}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      inputValue={searchValue}
      onInputChange={handleInputChange}
      onChange={onChange}
      options={ingredients}
      isLoading={loading}
      isDisabled={disabled}
      isClearable={isClearable}
    />
  );
}
