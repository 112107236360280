import React from 'react';

import { Pagination as SemanticPagination } from 'semantic-ui-react';

import { ItemCount } from './styles';

export default function Pagination({ pagination, onPageChange }) {
  function firstItem() {
    return (
      pagination?.page * pagination?.pageSize - pagination?.pageSize + 1 || 0
    );
  }

  function lastItem() {
    return pagination?.page * pagination?.pageSize || 0;
  }

  function totalItems() {
    return pagination?.total || 0;
  }

  function itemCount() {
    return `${firstItem()}-${lastItem()} de ${totalItems()} itens`;
  }

  return (
    <>
      <ItemCount>{itemCount()}</ItemCount>
      <SemanticPagination
        activePage={pagination?.page || 1}
        totalPages={pagination?.totalPages || 0}
        onPageChange={onPageChange}
        boundaryRange={0}
        ellipsisItem={null}
        firstItem={null}
        lastItem={null}
        siblingRange={1}
      />
    </>
  );
}
