import React from 'react';

import { Header, Button, Icon, Grid } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import Section from 'components/layout/Section';

export default function ProjectDocuments({
  documents,
  onEditDocumentClick,
  onDocumentClick,
}) {
  const {
    protocolDocument,
    areaAssignmentDocument,
    retDocument,
    reportReviewDocument,
    reportDocument,
    secondRetDocument,
    thirdRetDocument,
    fourthRetDocument,
  } = documents;

  return (
    <Section>
      <Section.Header>
        <Header as="h3" style={{ margin: 0 }}>
          Documentos
        </Header>
        <Button type="button" size="small" basic onClick={onEditDocumentClick}>
          <Icon name="edit" />
          Editar
        </Button>
      </Section.Header>
      <Section.Content>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <ItemWrapper>
                <Label>Protocolo</Label>
                <Value
                  onClick={
                    protocolDocument?.id
                      ? () => onDocumentClick(protocolDocument?.id)
                      : undefined
                  }
                >
                  {protocolDocument?.id ? protocolDocument?.name : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>Termo de cessão de área</Label>
                <Value
                  onClick={
                    areaAssignmentDocument?.id
                      ? () => onDocumentClick(areaAssignmentDocument?.id)
                      : undefined
                  }
                >
                  {areaAssignmentDocument?.id
                    ? areaAssignmentDocument?.name
                    : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>Laudo de revisão</Label>
                <Value
                  onClick={
                    reportReviewDocument?.id
                      ? () => onDocumentClick(reportReviewDocument?.id)
                      : undefined
                  }
                >
                  {reportReviewDocument?.id
                    ? reportReviewDocument?.name
                    : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>Laudo final</Label>
                <Value
                  onClick={
                    reportDocument?.id
                      ? () => onDocumentClick(reportDocument?.id)
                      : undefined
                  }
                >
                  {reportDocument?.id ? reportDocument?.name : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <ItemWrapper>
                <Label>1º RET</Label>
                <Value
                  onClick={
                    retDocument?.id
                      ? () => onDocumentClick(retDocument?.id)
                      : undefined
                  }
                >
                  {retDocument?.id ? retDocument?.name : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>2º RET</Label>
                <Value
                  onClick={
                    secondRetDocument?.id
                      ? () => onDocumentClick(secondRetDocument?.id)
                      : undefined
                  }
                >
                  {secondRetDocument?.id
                    ? secondRetDocument?.name
                    : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>3º RET</Label>
                <Value
                  onClick={
                    thirdRetDocument?.id
                      ? () => onDocumentClick(thirdRetDocument?.id)
                      : undefined
                  }
                >
                  {thirdRetDocument?.id ? thirdRetDocument?.name : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>4º RET</Label>
                <Value
                  onClick={
                    fourthRetDocument?.id
                      ? () => onDocumentClick(fourthRetDocument?.id)
                      : undefined
                  }
                >
                  {fourthRetDocument?.id
                    ? fourthRetDocument?.name
                    : 'Sem anexo'}
                </Value>
              </ItemWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Section.Content>
    </Section>
  );
}

const ItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const Label = styled.div`
  display: flex;
  flex: 1;
  color: #767676;
  font-size: 14px;
`;

const wrapperModifiers = {
  onClick: () => css`
    color: #4a9add;
    cursor: pointer;
  `,
};

const Value = styled.div`
  ${({ onClick }) => css`
    display: flex;
    flex: 1;
    font-size: 14px;

    ${onClick && wrapperModifiers.onClick()}
  `}
`;
