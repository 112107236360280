import React from 'react';

import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import TargetForm from 'components/forms/TargetForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useQueryTarget, useUpdateTarget } from 'hooks/target';

export default function EditTarget() {
  const history = useHistory();
  const { targetId } = useParams();

  const { data: target } = useQueryTarget(targetId);

  const { mutate: updateTarget, isLoading } = useUpdateTarget({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Alvo biológico atualizado com sucesso!');
    history.replace('/configuracoes/alvos');
  }

  function handleSubmit(values) {
    const updatedValues = {
      ...values,
      id: targetId,
    };

    updateTarget(updatedValues);
  }

  return (
    <Base text>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Edição de alvo</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              {target ? (
                <Section>
                  <Section.Content>
                    <TargetForm
                      formValues={target}
                      onSubmit={handleSubmit}
                      isSubmiting={isLoading}
                    />
                  </Section.Content>
                </Section>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}
