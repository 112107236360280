import * as React from 'react';

import toast from 'react-hot-toast';
import { Button, Form, Modal } from 'semantic-ui-react';

import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useFinishProject } from 'mutations/project';
import { DOCUMENT_REPORT } from 'util/Document';

type FileEvent = React.ChangeEvent<HTMLInputElement>;

export type ProjectFinishModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
};

const formId = 'form_project_finish';

export default function ProjectFinishModal({
  isOpen,
  onClose,
  projectId,
}: ProjectFinishModalProps) {
  const documentFile = useUpload();

  const { mutate: finishProject, isLoading } = useFinishProject({
    onSuccess: () => handleSuccess(),
  });

  function handleSuccess() {
    toast.success('Estudo concluído!');
    onClose();
  }

  function onSubmit() {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }
    const input = {
      projectId,
      reportId: documentFile.file?.id,
    };
    finishProject(input);
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }
  const isSubmitting = isLoading;
  const disabledButton = !documentFile.file?.url;

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Concluir estudo</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={onSubmit}>
          <Form.Field>
            <label>Laudo final</label>
            <UploadInput
              name="reportId"
              uploadedFile={documentFile?.file}
              onUpload={(e: FileEvent) =>
                handleDocumentFileChange(e, DOCUMENT_REPORT)
              }
              onDelete={documentFile.handleDelete}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || disabledButton}
          primary
        >
          Enviar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
