import * as React from 'react';

import filesize from 'filesize';
import shortid from 'shortid';

import api from 'util/api';

export type ExtendedFile = {
  file: File;
  id: number | string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string | null;
};

export function useUpload() {
  const [file, setFile] = React.useState<ExtendedFile | null>(null);

  function handleUpload(fileToUpload: File | null, documentType: number) {
    if (!fileToUpload) {
      return;
    }

    const updatedFile = createDetailedFile(fileToUpload);

    setFile(updatedFile);

    uploadDocument({
      file: updatedFile,
      documentType,
      onUploadProgress: (progress: number) => {
        setFile((oldValues: any) => ({ ...oldValues, progress }));
      },
    })
      .then((response) => {
        setFile((oldValues: any) => ({
          ...oldValues,
          uploaded: true,
          id: response.id,
          url: response.url,
        }));
      })
      .catch(() => {
        setFile((oldValues: any) => ({
          ...oldValues,
          error: true,
        }));
      });
  }

  function handleDelete() {
    setFile(null);
  }

  return { file, setFile, handleUpload, handleDelete };
}

export function useUploads() {
  const [files, setFiles] = React.useState<Array<ExtendedFile | null>>([]);

  function updateFile(newFile: Partial<ExtendedFile | null>, index: number) {
    const updatedFiles = Object.assign([...files], {
      [index]: newFile,
    });
    setFiles(updatedFiles);
  }

  function handleUpload(
    fileToUpload: File | null,
    documentType: number,
    index: number,
  ) {
    if (!fileToUpload) {
      return;
    }

    const detailedFile = createDetailedFile(fileToUpload);

    updateFile(detailedFile, index);

    uploadDocument({
      file: detailedFile,
      documentType,
      onUploadProgress: (progress: number) => {
        updateFile({ ...detailedFile, progress }, index);
      },
    })
      .then((response) => {
        const updatedFile = {
          ...detailedFile,
          progress: 100,
          uploaded: true,
          id: response.id,
          url: response.url,
        };
        updateFile(updatedFile, index);
      })
      .catch(() => {
        const updatedFile = { ...detailedFile, progress: 100, error: true };
        updateFile(updatedFile, index);
      });
  }

  function append() {
    const updatedFiles = [...files, null];
    setFiles(updatedFiles);
  }

  function remove(index: number) {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  }

  function handleDelete(index: number) {
    updateFile(null, index);
  }

  return { append, files, setFiles, handleUpload, handleDelete, remove };
}

function createDetailedFile(file: File) {
  return {
    file,
    id: shortid.generate(),
    name: file.name,
    readableSize: filesize(file.size),
    progress: 0,
    uploaded: false,
    error: false,
    url: null,
  };
}

async function uploadDocument({ file, documentType, onUploadProgress }: any) {
  const data = new FormData();

  data.append('file', file.file, file.name);
  data.append('documentType', documentType);

  return api
    .post('/documents', data, {
      onUploadProgress: (e) => {
        const progress = calculateProgress(e);

        onUploadProgress(progress);
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function calculateProgress(e: ProgressEventInit) {
  const { loaded = 0, total = 0 } = e;
  return Math.round((loaded * 100) / total);
}
