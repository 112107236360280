import { productRepository } from '../container';

export type UpdateProductParams = {
  id: number;
  name: string;
  productType: number;
  productCategory: number;
  formulationId: number;
  productIngredients: {
    ingredient: {
      id: number;
      name: string;
    };
    concentration?: number | null;
    measurementUnit?: number | null;
  }[];
  ret: string | null;
  retStage: number | null;
  retExpirationDate: Date | null;
  mapaRegister: string | null;
};

export type UpdateProductCallbacks = {
  onSuccess: (message: string) => void;
  onError: (message: string) => void;
};

export default async function updateProduct(
  params: UpdateProductParams,
  { onSuccess, onError }: UpdateProductCallbacks,
) {
  try {
    await productRepository.updateProduct(params);

    onSuccess('Produto atualizado com sucesso!');
  } catch (e) {
    const message = e?.message || 'Problema de conexão. Tente novamente.';
    onError(message);
  }
}
