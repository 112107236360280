import {
  getProjectGoal,
  getProjectStage,
  getDivision,
  getManagementType,
  getProjectStageColor,
} from 'data/project';
import {
  formatToIso,
  formatPeriod,
  isLate,
  nowAsIso,
  formatToBr,
  parseIso,
} from 'util/DateFormatter';

export default ({ api }) => ({
  async getProjectsDetailed(filter) {
    const { data } = await api.get('/projects', { params: filter });

    return data;
  },

  async getProject(id) {
    const { data } = await api.get(`/projects/${id}`);

    const projectModel = projectDataMapper({ data, origin: 'API' });

    return projectModel;
  },

  async createProject(data) {
    const project = await projectDataMapper({ data, origin: 'FORM' });
    const { headers } = await api.post('/projects', project);

    const { location } = headers;
    const projectId = parseInt(location.substring(13), 10);

    return projectId;
  },

  async updateProject(id, data) {
    try {
      await api.patch(`/projects/${id}`, data);

      return true;
    } catch (e) {
      throw e.response.data;
    }
  },

  async updateStageToPreparatory(projectId, responsibleTechnicianId) {
    const data = { responsibleTechnicianId };

    await api.patch(`/projects/${projectId}/enabling`, data);

    return true;
  },

  async updateStageToCanceled(projectId) {
    await api.patch(`/projects/${projectId}/canceled`);

    return true;
  },

  async updateInstallment({ projectId, installmentId, ...installmentUpdate }) {
    await api.patch(
      `/projects/${projectId}/installments/${installmentId}`,
      installmentUpdate,
    );

    return true;
  },

  async createInstallment({ projectId, ...input }) {
    const url = `/projects/${projectId}/installments`;
    await api.post(url, input);
  },
});

const STAGE_FINISHED = 8;

function apiResponseMapper(project) {
  const {
    projectGoal,
    projectStage,
    division,
    managementType,
    projectCrops,
    targets,
    installments,
    products,
    requiredDate,
    notes,
    updatedAt,
  } = project;

  return {
    ...project,
    projectCrops: projectCrops.map((projectCrop) => projectCrop.crop.name),
    targets: targets.map((target) => target.name),
    goalDescription: getProjectGoal(projectGoal),
    stageDescription: getProjectStage(projectStage),
    stageColor: getProjectStageColor(projectStage),
    divisionDescription: getDivision(division),
    managementTypeDescription: getManagementType(managementType),
    requiredDateFormatted: formatToBr(requiredDate),
    timeLeft: formatPeriod(requiredDate, nowAsIso()),
    isLate: isLate(requiredDate, nowAsIso()),
    isFinished: projectStage === STAGE_FINISHED,
    installments: installments
      .map(apiInstallmentResponseMapper)
      .sort(sortInstallmentsByNumber),
    products: productDataMapperList({ data: products, origin: 'API' }),
    notes,
    updatedAt,
    updatedAtFormatted: formatToBr(updatedAt),
  };
}

function apiInstallmentResponseMapper(data: any) {
  const { id, number, value, paymentForecast, projectId, paymentId } = data;

  return {
    ...data,
    id,
    number,
    value: parseFloat(value),
    paymentForecast: paymentForecast ? parseIso(paymentForecast) : null,
    paymentForecastFormatted: paymentForecast
      ? formatToBr(paymentForecast)
      : null,
    projectId,
    paymentId,
    isPaid: paymentId != null,
    situation: paymentId === null ? 'Em aberto' : 'Pago',
    situationColor: paymentId === null ? 'grey' : 'green',
  };
}

function sortInstallmentsByNumber(a, b) {
  return a.number - b.number;
}

function formInputsMapper(data) {
  const {
    harvest,
    projectGoal,
    managementType,
    division,
    crops,
    targets,
    products,
    protocol,
    requiredDate,
    insideCode,
    testLocation,
    notes,
    contractId,
    installments,
    protocolDocumentId,
    areaAssignmentDocumentId,
    retDocumentId,
    reportReviewDocumentId,
    reportDocumentId,
    secondRetDocumentId,
    thirdRetDocumentId,
    fourthRetDocumentId,
  } = data;

  const apiValues = {
    harvest: parseInt(harvest, 10),
    projectGoal: parseInt(projectGoal, 10),
    managementType: parseInt(managementType, 10),
    division: parseInt(division, 10),
    testLocation: parseInt(testLocation, 10),
    cropIds: crops,
    targetIds: targets,
    productIds: products,
    protocol,
    requiredDate: formatToIso(requiredDate),
    insideCode,
    contractId: parseInt(contractId, 10),
    installments: installments.map(({ amount, paymentForecast }, index) => ({
      number: index + 1,
      amount: Math.round(amount * 100),
      paymentForecast: formatToIso(paymentForecast),
    })),
  };

  if (notes) {
    apiValues.notes = notes;
  }

  if (protocolDocumentId) {
    apiValues.protocolDocumentId = protocolDocumentId;
  }

  if (areaAssignmentDocumentId) {
    apiValues.areaAssignmentDocumentId = areaAssignmentDocumentId;
  }

  if (retDocumentId) {
    apiValues.retDocumentId = retDocumentId;
  }

  if (secondRetDocumentId) {
    apiValues.secondRetDocumentId = secondRetDocumentId;
  }

  if (thirdRetDocumentId) {
    apiValues.thirdRetDocumentId = thirdRetDocumentId;
  }

  if (fourthRetDocumentId) {
    apiValues.fourthRetDocumentId = fourthRetDocumentId;
  }

  if (reportReviewDocumentId) {
    apiValues.reportReviewDocumentId = reportReviewDocumentId;
  }

  if (reportDocumentId) {
    apiValues.reportDocumentId = reportDocumentId;
  }

  return apiValues;
}

export function projectDataMapper({ data, origin }) {
  const mappers = {
    API: apiResponseMapper,
    FORM: formInputsMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

export function projectDataMapperList({ data, origin }) {
  return data.map((item) => projectDataMapper({ data: item, origin }));
}

// mapper

function productDataMapperList({ data, origin }) {
  return data.map((item) => productDataMapper({ data: item, origin }));
}

function productDataMapper({ data, origin }) {
  const mappers = {
    API: productResponseMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

function productResponseMapper(data) {
  const {
    id,
    name,
    type,
    category,
    formulation,
    ingredients,
    ret,
    retStage,
    retExpirationDate,
    mapaRegister,
  } = data;

  return {
    id,
    name,
    type,
    typeDescription: getTypeDescription(type),
    category,
    formulation,
    ingredients,
    ret,
    retStage,
    retExpirationDate,
    mapaRegister,
  };
}

function getTypeDescription(type) {
  switch (type) {
    case 1:
      return 'Biológico';
    case 2:
      return 'Químico';
    default:
      return '';
  }
}
