import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import CropSearch from 'components/inputs/CropSearch';
import DivisionSelect from 'components/inputs/DivisionSelect';
import InputError from 'components/inputs/InputError';
import TargetSearch from 'components/inputs/TargetSearch';
import AlertModal from 'components/layout/AlertModal';
import {
  useCreateSaleService,
  useDeleteSaleService,
  useUpdateSaleService,
} from 'data/sale-service';
import { useModal } from 'hooks/useModal';

const ServiceFormInput = yup.object().shape({
  division: yup
    .object({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required('Obrigatório')
    .typeError('Obrigatório'),
  crop: yup
    .object({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required('Obrigatório')
    .typeError('Obrigatório'),
  target: yup
    .object({
      value: yup.number().required(),
      label: yup.string().required(),
    })
    .required('Obrigatório')
    .typeError('Obrigatório'),
  treatmentPrice: yup.number().required('Obrigatório'),
});

export type SaleServiceFormValues = yup.InferType<typeof ServiceFormInput>;

export type ServiceModalProps = {
  formValues?: SaleServiceFormValues;
  isOpen: boolean;
  onClose: () => void;
  saleServiceId?: number;
};

const CENTS_MULTIPLIER = 100;

const defaultNCM = { id: 1, code: '05119190' } as const;
const initialValues = { ncm: defaultNCM.code } as const;
const formId = 'form_sale_product' as const;

const MESSAGE = {
  success: {
    create: 'Serviço cadastrada!',
    update: 'Serviço salva!',
    delete: 'Serviço excluído!',
  },
} as const;

export default function ServiceModal({
  formValues,
  isOpen,
  onClose,
  saleServiceId,
}: ServiceModalProps) {
  const deletionModal = useModal();

  const defaultValues = formValues ? { ...formValues } : initialValues;
  const resolver = yupResolver(ServiceFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit } = useForm(formOptions);

  const createSaleService = useCreateSaleService({
    onSuccess: () => handleSuccess(MESSAGE.success.create),
  });

  const updateSaleService = useUpdateSaleService({
    onSuccess: () => handleSuccess(MESSAGE.success.update),
  });

  const deleteSaleService = useDeleteSaleService({
    onSuccess: () => handleSuccess(MESSAGE.success.delete),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function handleDelete() {
    if (saleServiceId) {
      deletionModal.open();
    }
  }

  function onSubmit(values: SaleServiceFormValues) {
    const input = {
      cropId: values.crop.value,
      divisionId: values.division.value,
      targetId: values.target.value,
      treatmentPrice: Math.round(values.treatmentPrice * CENTS_MULTIPLIER),
    };

    if (saleServiceId) {
      updateSaleService.mutate({ ...input, saleServiceId });
      return;
    }

    createSaleService.mutate(input);
  }

  const isLoading = createSaleService.isLoading || updateSaleService.isLoading;
  const header = saleServiceId ? 'Edição de serviço' : 'Novo serviço';
  const buttonText = saleServiceId ? 'Salvar' : 'Cadastrar';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Setor</label>
              <Controller
                control={control}
                defaultValue=""
                name="division"
                render={({ onChange, onBlur, value }) => (
                  <DivisionSelect
                    disabled={isLoading}
                    isClearable
                    name="division"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Setor"
                    value={value}
                  />
                )}
              />
              {errors.division && (
                <InputError>{errors?.division?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Cultura</label>
              <Controller
                control={control}
                defaultValue=""
                name="crop"
                render={({ onChange, onBlur, value }) => (
                  <CropSearch
                    disabled={isLoading}
                    isClearable
                    isMulti={false}
                    name="crop"
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Cultura"
                    value={value}
                  />
                )}
              />
              {errors.crop && <InputError>{errors?.crop?.message}</InputError>}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Alvo</label>
            <Controller
              control={control}
              defaultValue=""
              name="target"
              render={({ onChange, onBlur, value }) => (
                <TargetSearch
                  disabled={isLoading}
                  isClearable
                  isMulti={false}
                  name="target"
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="Alvo"
                  value={value}
                />
              )}
            />
            {errors.target && (
              <InputError>{errors?.target?.message}</InputError>
            )}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Preço unitário</label>
              <Controller
                control={control}
                name="treatmentPrice"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    prefix="R$ "
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.treatmentPrice && (
                <InputError>{errors?.treatmentPrice?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {saleServiceId ? (
          <Button
            basic
            disabled={isLoading}
            floated="left"
            loading={isLoading}
            negative
            onClick={handleDelete}
            type="button"
          >
            Excluir
          </Button>
        ) : null}
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>

      {deletionModal.isOpen && saleServiceId ? (
        <AlertModal
          content="Tem certeza que deseja excluir o serviço?"
          header="Excluir serviço"
          isLoading={deleteSaleService.isLoading}
          isOpen={deletionModal.isOpen}
          negative
          onClose={deletionModal.close}
          onSubmit={() => deleteSaleService.mutate(saleServiceId)}
          submitText="Excluir"
        />
      ) : null}
    </Modal>
  );
}
