import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import api from 'util/api';

type AddressInput = {
  street: string | null;
  streetNumber: string | null;
  zipcode: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  complementary: string | null;
};

type CustomerInput = {
  tradingName: string;
  companyName: string;
  phone: string;
  insideCode: string | null;
  cnpj: string | null;
  stateRegistration: string | null;
  address: AddressInput;
};

export function useCreateCustomer(
  options?: UseMutationOptions<number, AxiosError, CustomerInput, () => void>,
) {
  return useMutation(createCustomer, options);
}

async function createCustomer(input: CustomerInput) {
  const { headers } = await api.post('customers', input);

  const { location } = headers;
  const customerId = parseInt(location.substring(14), 10);

  return customerId;
}

type RepresentativeInput = {
  name: string;
  email: string;
  phone: string;
  active?: boolean;
  customerId: number;
};

export function useAddRepresentative(
  options?: UseMutationOptions<
    number,
    AxiosError,
    RepresentativeInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(addRepresentative, {
    ...options,
    onSuccess: (data, variables, context) => {
      const representative = { ...variables, id: data };
      queryClient.setQueryData(
        ['representatives', { customerId: variables.customerId }],
        (oldData) => {
          return {
            pagination: oldData.pagination,
            data: [...oldData.data, representative],
          };
        },
      );

      queryClient.invalidateQueries('customer');
      queryClient.invalidateQueries('customers');
      queryClient.invalidateQueries('representative');
      queryClient.invalidateQueries('representatives');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addRepresentative(input: RepresentativeInput) {
  const { headers } = await api.post(
    `/customers/${input.customerId}/representatives`,
    input,
  );
  const { location } = headers;
  const representativeId = location.split('/').pop();
  return representativeId as number;
}

type UpdateCustomerAddressInput = AddressInput & { customerId: number };

export function useUpdateCustomerAddress(
  options?: UseMutationOptions<void, AxiosError, UpdateCustomerAddressInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateCustomerAddress, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('customer');
      queryClient.invalidateQueries('customers');
      queryClient.invalidateQueries('representative');
      queryClient.invalidateQueries('representatives');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateCustomerAddress({
  customerId,
  ...input
}: UpdateCustomerAddressInput) {
  await api.put(`/customers/${customerId}/address`, input);
}
