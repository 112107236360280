import React from 'react';

import { DateTime } from 'luxon';
import { Table, Grid, Button, Icon, Divider } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { WeatherQuery } from 'queries/weather';

const style = { color: 'white' };

type WeatherTableProps = {
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onEditClick: (weather: WeatherQuery, index: number) => void;
  onImportClick?: () => void;
  onRemoveClick: (weather: WeatherQuery, index: number) => void;
  weathers: WeatherQuery[];
};

export default function WeatherTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  onEditClick,
  onImportClick,
  onRemoveClick,
  weathers = [],
}: WeatherTableProps) {
  const isEmpty = !isLoading && weathers?.length === 0;

  if (isEmpty) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Nenhum dado encontrado</EmptyState.Header>
            {onImportClick ? (
              <EmptyState.Actions>
                <Button type="button" onClick={onImportClick} primary>
                  <Icon name="upload" />
                  Importar
                </Button>
              </EmptyState.Actions>
            ) : null}
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Content>
        <Table basic="very" size="small" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary">Data</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary" style={style}>
                  -
                </Text>
                <Text variant="secondary">Média</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary">Temperatura (ºC)</Text>
                <Text variant="secondary">Máxima</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary" style={style}>
                  -
                </Text>
                <Text variant="secondary">Mínima</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary" style={style}>
                  -
                </Text>
                <Text variant="secondary">Média</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary">Umidade (%)</Text>
                <Text variant="secondary">Máxima</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                <Text variant="primary" style={style}>
                  -
                </Text>
                <Text variant="secondary">Mínima</Text>
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Chuva (mm)</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={9} rows={10} />}
            {!isLoading &&
              weathers.map((weather, index) => {
                const {
                  id,
                  date,
                  temperatureAverage,
                  temperatureMax,
                  temperatureMin,
                  humidityAverage,
                  humidityMax,
                  humidityMin,
                  rain,
                } = weather;

                return (
                  <Table.Row key={id}>
                    <Table.Cell textAlign="center">
                      {DateTime.fromISO(date).toFormat('dd/MM/yyyy')}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {temperatureAverage}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{temperatureMax}</Table.Cell>
                    <Table.Cell textAlign="center">{temperatureMin}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {humidityAverage}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{humidityMax}</Table.Cell>
                    <Table.Cell textAlign="center">{humidityMin}</Table.Cell>
                    <Table.Cell textAlign="center">{rain}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {onEditClick ? (
                        <Button
                          type="button"
                          size="mini"
                          onClick={() => onEditClick(weather, index)}
                          basic
                          icon
                        >
                          <Icon name="edit" />
                        </Button>
                      ) : null}
                      {onRemoveClick ? (
                        <Button
                          type="button"
                          size="mini"
                          onClick={() => onRemoveClick(weather, index)}
                          basic
                          icon
                        >
                          <Icon name="trash" />
                        </Button>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
        <Divider />
        {hasNextPage ? (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Carregando...' : ''}
                  {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Text variant="secondary">Nada mais para mostrar</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Section.Content>
    </Section>
  );
}
