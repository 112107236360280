import React from 'react';

import { Link } from 'react-router-dom';
import { Grid, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import { SamplesQueryManipulation } from 'queries/sample';

type SampleManipulationTableProps = {
  manipulations: SamplesQueryManipulation[];
  isLoading: boolean;
};

function SampleManipulationTable({
  manipulations = [],
  isLoading,
}: SampleManipulationTableProps) {
  return (
    <Grid>
      <Grid.Column width={16}>
        <Table size="small" basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. inicial (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. pesada (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. final (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Perda (g)</Table.HeaderCell>
              <Table.HeaderCell>Cód. Balança</Table.HeaderCell>
              <Table.HeaderCell>Estudo</Table.HeaderCell>
              <Table.HeaderCell>Responsável</Table.HeaderCell>
              <Table.HeaderCell>Obs</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={5} rows={10} />}
            {!isLoading &&
              manipulations?.map((manipulation) => {
                const {
                  id,
                  balanceCode,
                  dateFormatted,
                  endAmount,
                  lostAmount,
                  notes,
                  project,
                  projectId,
                  responsible,
                  startAmount,
                  timeFormatted,
                  weighedAmount,
                } = manipulation;

                return (
                  <Table.Row key={id.toString()}>
                    <Table.Cell>
                      <Text>{dateFormatted}</Text>
                      <Text variant="secondary">{timeFormatted}</Text>
                    </Table.Cell>
                    <Table.Cell textAlign="right">{startAmount}</Table.Cell>
                    <Table.Cell textAlign="right">{weighedAmount}</Table.Cell>
                    <Table.Cell textAlign="right">{endAmount}</Table.Cell>
                    <Table.Cell textAlign="right">{lostAmount}</Table.Cell>
                    <Table.Cell>{balanceCode}</Table.Cell>
                    <Table.Cell>
                      <Link to={`/estudos/${projectId}`}>{project}</Link>
                    </Table.Cell>
                    <Table.Cell>{responsible}</Table.Cell>
                    <Table.Cell>{notes}</Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
}

export default SampleManipulationTable;
