import { useInfiniteQuery, useQuery } from 'react-query';

import {
  getDivision,
  getManagementType,
  getProjectGoal,
  getTestLocation,
} from 'data/project';
import api, { emptyPagination, Pagination, Response } from 'util/api';
import { formatToBr, parseIso } from 'util/DateFormatter';
import { toCurrency } from 'util/numberFormat';

type QuotationsQueryContract = {
  id: number;
  number: string;
};

type QuotationsQueryCustomer = {
  id: number;
  tradingName: string;
  cnpj: string;
  phone: string;
};

type QuotationsQueryRepresentative = {
  id: number;
  name: string;
  email: string;
  phone: string;
};

type QuotationsQueryResponsible = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
};

type QuotationsQueryPayment = {
  id: number;
  installment: number;
  percent: number;
  amount: number;
  description: string;
  quotationId: number;
};

export type QuotationProduct = {
  id: number;
  amount: number;
  code: string;
  mercosurCommonNomenclature: string;
  number: number;
  quantity: number;
  quotationId: number;
  target: { id: number; name: string };
  unit: { id: number; abbreviation: string };
};

export type QuotationsQuery = {
  code: string;
  contract: QuotationsQueryContract | null;
  createdAt: string;
  currency: number;
  customer: QuotationsQueryCustomer;
  discountPercent: number | null;
  divisions: number[];
  harvest: number;
  hasDiscount: boolean;
  hasPayments: boolean;
  hasProjects: boolean;
  id: number;
  isAccepted: boolean;
  isAnswered: boolean;
  isInNegotiation: boolean;
  isRejected: boolean;
  notes: string | null;
  payments: QuotationsQueryPayment[];
  products: QuotationProduct[];
  quotationDate: string;
  quotationResponsible: QuotationsQueryResponsible;
  quotationType: number;
  quotationTypeDescription: string;
  representative: QuotationsQueryRepresentative;
  responseDate: string | null;
  isContractGenerated: boolean;
  reviewDate: string | null;
  shipping: {
    id: number;
    amount: number;
    quantity: number;
    quotationId: number;
    shippingType: number;
    totalAmount: number;
  } | null;
  status: number;
  totalAmount: number;
  totalAmountWithDiscount: number;
  totalValue: number;
  updatedAt: string;
  version: number;
};

export type QuotationsResponse = {
  pagination: Pagination;
  projects: QuotationsQuery[];
};

export type QuotationFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  code?: string;
  status?: number;
  quotationType?: number;
  notBound?: boolean;
};

export const QUOTATION_TYPE = {
  service: 1,
  product: 2,
};

export const SHIPPING_TYPE = {
  CIF: 1,
  FOB: 2,
};

export const SHIPPING_TYPE_MAP: Record<number, string> = {
  1: 'CIF',
  2: 'FOB',
} as const;

export function formatShippingType(quotationType: number) {
  if (!SHIPPING_TYPE_MAP[quotationType]) {
    return '';
  }
  return SHIPPING_TYPE_MAP[quotationType];
}

export const quotationKeys = {
  all: ['quotations'] as const,
  detail: (id: number) => [...quotationKeys.all, id] as const,
};

export function useInfiniteQuotations(filter: QuotationFilter) {
  const result = useInfiniteQuery(
    ['quotations', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getQuotations({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const quotations =
    result?.data?.pages
      ?.map((group) => group?.quotations?.map((quotation) => quotation))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    quotations,
  };
}

async function getQuotations(filter: QuotationFilter) {
  const response = await api.get<Response<QuotationsQuery>>(`/quotations`, {
    params: filter,
  });
  const { data, pagination } = response.data;
  const quotations = data.map(apiResponseMapper);

  return { quotations, pagination };
}

export function useQuotation(quotationId: number) {
  return useQuery({
    queryKey: ['quotations', quotationId],
    queryFn: () => getQuotation(quotationId),
  });
}

async function getQuotation(quotationId: number) {
  const quotationPromise = getQuotationById(quotationId);
  const projectsPromise = getQuotationProjects(quotationId);

  const [quotation, { data: projects }] = await Promise.all([
    quotationPromise,
    projectsPromise,
  ]);

  return { ...quotation, projects };
}

async function getQuotationById(quotationId: number) {
  const { data } = await api.get(`/quotations/${quotationId}`);

  const quotation = apiResponseMapper(data);

  return quotation;
}

export type QuotationProjectsResponse = {
  id: number;
  number: number;
  description: string;
  durationForecast: number;
  amount: number;
  totalAmount: number;
  treatmentQuantity: number;
  division: number;
  projectGoal: number;
  managementType: number;
  testLocation: number;
  quotationId: number;
  updatedAt: string;
  crops: [
    {
      id: number;
      name: string;
      insideCode: string;
    },
  ];
  targets: [
    {
      id: number;
      name: string;
      insideCode: string;
    },
  ];
  trialsNumber: number;
  protocol: string;
};

async function getQuotationProjects(quotationId: number) {
  const endpoint = `/quotations/${quotationId}/projects?pageSize=100&orderBy=number&sort=asc`;
  const response = await api.get<Response<QuotationProjectsResponse>>(endpoint);

  const { data, pagination } = response.data;

  const projects = data.map(apiQuotationProjectResponseMapper);

  return { data: projects, pagination };
}

export type QuotationsQueryFormatted = ReturnType<typeof apiResponseMapper>;

function apiResponseMapper(quotationApi: QuotationsQuery) {
  return {
    ...quotationApi,
    quotationStatusDescription: formatQuotationStatus(quotationApi.status),
    quotationStatusColor: formatQuotationStatusColor(quotationApi.status),
    harvest: quotationApi.harvest,
    quotationDate: parseIso(quotationApi.quotationDate),
    quotationDateFormatted: formatToBr(quotationApi.quotationDate),
    reviewDate: quotationApi.reviewDate
      ? parseIso(quotationApi.reviewDate)
      : null,
    reviewDateFormatted: quotationApi.reviewDate
      ? formatToBr(quotationApi.reviewDate)
      : '',
    responseDate: quotationApi.responseDate
      ? parseIso(quotationApi.responseDate)
      : null,
    responseDateFormatted: quotationApi.responseDate
      ? formatToBr(quotationApi.responseDate)
      : '',
    divisionsFormatted: quotationApi.divisions.map((division) =>
      getDivision(division),
    ),
    currency: quotationApi.currency,
    payments: quotationApi.payments.map((payment) => {
      return {
        id: payment.id,
        installment: payment.installment,
        percent: payment.percent,
        percentFormatted: `${Math.round(payment.percent * 100)}%`,
        description: payment.description,
        amount: payment.amount,
      };
    }),
  };
}

function formatQuotationStatus(quotationStatus: number) {
  switch (quotationStatus) {
    case 1:
      return 'Em negociação';
    case 2:
      return 'Aprovado';
    case 3:
      return 'Não aprovado';
    case 4:
      return 'Contrato gerado';
    default:
      return '';
  }
}

function formatQuotationStatusColor(quotationStatus: number) {
  switch (quotationStatus) {
    case 1:
      return 'blue';
    case 2:
      return 'green';
    case 3:
      return 'red';
    case 4:
      return 'transparent';
    default:
      return '';
  }
}

export const QuotationStatus = {
  InNegotiation: 1,
  Accepted: 2,
  Rejected: 3,
  GeneratedContract: 4,
} as const;

type QuotationStatus = typeof QuotationStatus[keyof typeof QuotationStatus];

export function formatStatus(status: number) {
  if (status === QuotationStatus.InNegotiation) {
    return 'Em negociação';
  }

  if (status === QuotationStatus.Accepted) {
    return 'Aprovado';
  }

  if (status === QuotationStatus.Rejected) {
    return 'Não aprovado';
  }

  if (status === QuotationStatus.GeneratedContract) {
    return 'Contrato gerado';
  }

  throw new Error('Status inválido!');
}

function apiQuotationProjectResponseMapper(quotationProjectApi: any) {
  return {
    ...quotationProjectApi,
    productsNames: quotationProjectApi.products.map((p) => p.name),
    cropsNames: quotationProjectApi.crops.map((c) => c.name),
    targetsNames: quotationProjectApi.targets.map((t) => t.name),
    value: parseFloat(quotationProjectApi.value),
    valueFormatted: `R$ ${toCurrency(quotationProjectApi.value)}`,
    totalValue: parseFloat(quotationProjectApi.totalValue),
    totalValueFormatted: `R$ ${toCurrency(quotationProjectApi.totalValue)}`,
    division: quotationProjectApi.division,
    divisionDescription: getDivision(quotationProjectApi.division),
    projectGoal: quotationProjectApi.projectGoal,
    projectGoalDescription: getProjectGoal(quotationProjectApi.projectGoal),
    managementType: quotationProjectApi.managementType,
    managementTypeDescription: getManagementType(
      quotationProjectApi.managementType,
    ),
    testLocation: quotationProjectApi.testLocation,
    testLocationDescription: getTestLocation(quotationProjectApi.testLocation),
    updatedAt: parseIso(quotationProjectApi.updatedAt),
    updatedAtFormatted: formatToBr(quotationProjectApi.updatedAt),
  };
}
