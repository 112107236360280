import * as React from 'react';

import { Button, Divider, Grid, Header, Icon, Loader } from 'semantic-ui-react';

import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { useCompanyInfo } from 'data/company-info';
import { useModal } from 'hooks/useModal';
import { formatCpfOrCnpj, formatPhone } from 'util/mask';

import UpdateCompanyInfoModal from './UpdateCompanyInfo';

export default function CompanyInfoPage() {
  const { data, isLoading } = useCompanyInfo();
  const editModal = useModal();

  function handleEditClick() {
    if (data) {
      editModal.open();
    }
  }

  if (isLoading) {
    return (
      <Loader active size="large" style={{ marginTop: '200px' }}>
        Carregando...
      </Loader>
    );
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h1">Empresa</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button basic onClick={handleEditClick}>
              <Icon name="edit" />
              Alterar dados
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Header>Informações da empresa</Section.Header>
              <Section.Content>
                <Text>
                  <strong>Nome:</strong> {data?.name}
                </Text>
                <Text>
                  <strong>Endereço:</strong> {data?.address}
                </Text>
                <Text>
                  <strong>CEP:</strong> {data?.cep}
                </Text>
                <Text>
                  <strong>Cidade:</strong> {data?.city}
                </Text>
                <Text>
                  <strong>CNPJ:</strong>{' '}
                  {data?.cnpj ? formatCpfOrCnpj(data?.cnpj) : ''}
                </Text>
                <Text>
                  <strong>Caixa postal:</strong> {data?.mailbox}
                </Text>
                <Text>
                  <strong>Telefone:</strong>{' '}
                  {data?.phone ? formatPhone(data.phone, 1) : ''}
                </Text>
                <Text>
                  <strong>Estado:</strong> {data?.state?.toUpperCase()}
                </Text>
                <Text>
                  <strong>Inscrição estadual:</strong> {data?.stateRegistration}
                </Text>
                <Text>
                  <strong>Site:</strong> {data?.websiteUrl}
                </Text>
                <Text>
                  <strong>Versão:</strong> {data?.version}
                </Text>
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {editModal.isOpen && data ? (
        <UpdateCompanyInfoModal
          isOpen={editModal.isOpen}
          onClose={editModal.close}
          formValues={{
            ...data,
            state: { value: data.state, label: data.state },
          }}
        />
      ) : null}
    </>
  );
}
