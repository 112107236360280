import { useQuery } from 'react-query';

import api from 'util/api';

export type UseApplicationsParams = {
  projectId: number;
  page: number;
  pageSize: number;
  orderBy: string;
  sort: string;
};

export function useApplications(params: UseApplicationsParams) {
  const { projectId, ...filter } = params;

  return useQuery(['applications', params], () => {
    return getApplications(projectId, filter);
  });
}

type ApplicationFilter = Omit<UseApplicationsParams, 'projectId'>;

async function getApplications(projectId: number, filter: ApplicationFilter) {
  const response = await api.get(`/projects/${projectId}/applications`, {
    params: filter,
  });
  const { data: applications, pagination } = response.data;
  return { applications, pagination };
}
