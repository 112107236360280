import React from 'react';

import { Button, Grid, Header, Icon } from 'semantic-ui-react';

import FilteredSaleServiceTable from 'components/data/FilteredSaleServiceTable';
import { useSaleServiceFilter } from 'components/data/FilteredSaleServiceTable/use-sale-service-filter';
import ServiceModal, {
  SaleServiceFormValues,
} from 'components/modal/ServiceModal';
import { useInfiniteSaleServices } from 'data/sale-service';
import { useAuth } from 'hooks/auth';
import { useModalWithData } from 'hooks/useModal';

type ModalData = {
  saleServiceId: number;
  formValues: SaleServiceFormValues;
};

export default function ServicesPage() {
  const { user } = useAuth();
  const saleServiceModal = useModalWithData<ModalData>();

  const { getMappedFilter } = useSaleServiceFilter();

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProjects,
    pagination,
    saleServices,
  } = useInfiniteSaleServices(getMappedFilter());

  function handleAddClick() {
    saleServiceModal.open();
  }

  function handleEditClick(saleServiceId: number) {
    const saleService = saleServices.find(({ id }) => id === saleServiceId);

    if (!saleService) return;

    const formValues = {
      crop: { label: saleService.crop.name, value: saleService.crop.id },
      division: {
        label: saleService.division.description,
        value: saleService.division.id,
      },
      target: { label: saleService.target.name, value: saleService.target.id },
      treatmentPrice: saleService.treatmentPrice / 100,
    };

    saleServiceModal.open({ saleServiceId, formValues });
  }

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h1">Serviços</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            {user?.isAdmin ? (
              <Button primary onClick={handleAddClick}>
                <Icon name="add" />
                Nova praga
              </Button>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <FilteredSaleServiceTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoadingProjects}
              onEditClick={handleEditClick}
              pagination={pagination}
              saleServices={saleServices}
              showActions={user?.isAdmin}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {saleServiceModal.isOpen ? (
        <ServiceModal
          formValues={saleServiceModal?.data?.formValues}
          isOpen={saleServiceModal.isOpen}
          onClose={saleServiceModal.close}
          saleServiceId={saleServiceModal?.data?.saleServiceId}
        />
      ) : null}
    </>
  );
}
