import { useQuery } from 'react-query';

import api from 'util/api';

const PT_BR = 'pt-BR';
const EN_US = 'en-US';

const divisionStrategy: Record<number, { [PT_BR]: string; [EN_US]: string }> = {
  1: {
    [PT_BR]: 'Bioensaios',
    [EN_US]: 'Bioassays',
  },
  2: {
    [PT_BR]: 'Biotec',
    [EN_US]: 'Biotec',
  },
  3: {
    [PT_BR]: 'Coleta',
    [EN_US]: 'Collection',
  },
  7: {
    [PT_BR]: 'Fungicida',
    [EN_US]: 'Fungicide',
  },
  8: {
    [PT_BR]: 'Herbicida',
    [EN_US]: 'Herbicide',
  },
  9: {
    [PT_BR]: 'Inseticida',
    [EN_US]: 'Insecticide',
  },
  12: {
    [PT_BR]: 'Nematologia',
    [EN_US]: 'Nematology',
  },
  13: {
    [PT_BR]: 'Projeto especial',
    [EN_US]: 'Special Project',
  },
  14: {
    [PT_BR]: 'Resíduos',
    [EN_US]: 'Residue',
  },
  15: {
    [PT_BR]: 'Fertilizante',
    [EN_US]: 'Fertilizer',
  },
  16: {
    [PT_BR]: 'MIP Experience',
    [EN_US]: 'MIP Experience',
  },
  17: {
    [PT_BR]: 'Criações',
    [EN_US]: 'Breeding',
  },
};

export function formatDivision({
  division,
  locale = PT_BR,
}: {
  division: number;
  locale?: string;
}) {
  if (!divisionStrategy[division] || !divisionStrategy[division][locale]) {
    return '';
  }

  return divisionStrategy[division][locale];
}

export const divisionKeys = {
  all: ['divisions'] as const,
};

export function useDivisions() {
  const result = useQuery({
    queryKey: divisionKeys.all,
    queryFn: getDivisions,
  });
  return { ...result, divisions: result?.data || [] };
}

export type DivisionQuery = {
  id: number;
  description: string;
  descriptionInt: string;
  insideCode: string;
  responsible: string;
  responsibleId: number;
};

async function getDivisions() {
  const { data } = await api.get<DivisionQuery[]>('/admin/divisions');
  return data;
}
