import { quotationRepository } from '../container';

export default async function createQuotationProject(data) {
  try {
    await quotationRepository.createQuotationProject(data);

    return {};
  } catch (e) {
    throw e.message || 'Ops! Alguma coisa não funcionou direito';
  }
}
