/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import Text from 'components/foundation/Text';
import DatePicker from 'components/inputs/date-picker';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import { useAddShipment } from 'data/shipment';
import { ContractProductItem } from 'queries/contract';

export type ShipmentFormValues = {
  items: {
    contractProductId: number;
    quantity: number;
  }[];
  shippingForecast?: Date | null;
  address?: string | null;
};

export type ShipmentModalProps = {
  contractId: number;
  formValues?: ShipmentFormValues;
  isOpen: boolean;
  items: ContractProductItem[];
  onClose: () => void;
  shipmentId?: number;
};

const formId = 'form_product_item';

export default function ShipmentModal({
  contractId,
  formValues,
  isOpen,
  items,
  onClose,
  shipmentId,
}: ShipmentModalProps) {
  const { current: initialItems } = React.useRef(
    items.filter((item) => item.quantity - item.quantityDelivered > 0),
  );

  const defaultValues = formValues
    ? { ...formValues }
    : { shippingForecast: null, items: [{}] };
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
  } as const;
  const { control, errors, handleSubmit, watch } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });
  const watchItems = watch('items');

  const addShipment = useAddShipment({
    onSuccess: () => handleSuccess('Remessa adicionada!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ShipmentFormValues) {
    const input = {
      address: values.address || null,
      contractId,
      items: values.items.map((item) => ({
        contractProductId: item.item.id,
        quantity: item.quantity,
      })),
      shippingForecast: values.shippingForecast || null,
    };

    addShipment.mutate(input);
  }

  const { isLoading } = addShipment;
  const header = shipmentId ? 'Edição de remessa' : 'Nova remessa';
  const buttonText = shipmentId ? 'Salvar' : 'Adicionar';

  const itemsFiltered = initialItems.filter((item) => {
    const alreadySelected = watchItems.some(
      (watchItem) => watchItem?.item?.id === item.id,
    );
    const isAvailable = item.quantity - item.quantityDelivered > 0;

    return !alreadySelected && isAvailable;
  });

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Header as="h3">Informações de envio</Header>
          <Form.Field width={6}>
            <label>Prev. expedição</label>
            <Controller
              control={control}
              name="shippingForecast"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
            <Text variant="secondary">Opcional</Text>
          </Form.Field>
          <Form.Field>
            <label>Endereço</label>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            <Text variant="secondary">
              Opcional: pode ser preenchido posteriormente, ao enviar a remessa
            </Text>
          </Form.Field>
          <Divider hidden />
          <Header as="h3">Itens disponíveis</Header>
          {fields.map((item, index) => {
            const isFirstItem = index === 0;
            const disabledRemoveButton = isFirstItem && watchItems.length === 1;

            const rest =
              watchItems?.[index]?.item?.quantity -
              watchItems?.[index]?.item?.quantityDelivered;
            const unit = watchItems?.[index]?.item?.unit?.abbreviation || '';

            return (
              <Form.Group key={item.id}>
                <Form.Field width={13}>
                  {isFirstItem ? <label>Item</label> : null}
                  <Controller
                    control={control}
                    name={`items[${index}].item`}
                    rules={{
                      required: { value: true, message: 'Obrigatório' },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <Select
                        autoComplete="off"
                        components={{ Option: CustomOption }}
                        getOptionLabel={(option) => option.description}
                        getOptionValue={(option) => option.id}
                        instanceId={`items[${index}].item`}
                        isClearable
                        isDisabled={isLoading}
                        isLoading={isLoading}
                        name={`items[${index}].item`}
                        onBlur={onBlur}
                        onChange={onChange}
                        options={itemsFiltered}
                        placeholder="Selecione..."
                        value={value}
                      />
                    )}
                  />
                  {errors.items && (
                    <InputError>
                      {errors?.items[index]?.item?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field widths={2}>
                  {isFirstItem ? <label>Quantidade</label> : null}
                  <Controller
                    control={control}
                    name={`items[${index}].quantity`}
                    rules={{
                      required: { value: true, message: 'Obrigatório' },
                      max: {
                        value: rest,
                        message: `${rest} ${unit} disponível`,
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        decimalScale={2}
                        decimalSeparator=","
                        disabled={isLoading}
                        suffix={` ${unit}`}
                        onBlur={onBlur}
                        onValueChange={(data) => {
                          onChange(data.floatValue);
                        }}
                        thousandSeparator="."
                        value={value}
                      />
                    )}
                  />
                  {errors.items && (
                    <InputError>
                      {errors?.items[index]?.quantity?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={1}>
                  {isFirstItem ? <HiddenLabel /> : null}
                  <Button
                    type="button"
                    size="small"
                    onClick={() => remove(index)}
                    disabled={disabledRemoveButton}
                    icon
                    basic
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Field>
              </Form.Group>
            );
          })}
          {initialItems.length > fields.length ? (
            <Form.Field width={10}>
              <Button basic fluid onClick={() => append({})} type="button">
                <Icon name="add" />
                Adicionar um item
              </Button>
            </Form.Field>
          ) : null}
          <Divider hidden />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          form={formId}
          loading={isLoading}
          primary
          type="submit"
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function CustomOption({ innerProps, isDisabled, data, ...props }) {
  return (
    <Wrapper {...innerProps} {...props}>
      <PrimaryText isDisabled={isDisabled}>{data.description}</PrimaryText>
      <SecondaryText
        {...props}
        isDisabled={isDisabled}
      >{`Disponível: ${data.quantity - data.quantityDelivered} ${
        data.unit.abbreviation
      }`}</SecondaryText>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ isFocused, isSelected }) => css`
    padding: 1rem 1.4rem;
    background-color: ${isFocused ? '#deebff' : ''};
    background-color: ${isSelected ? '#2684FF' : ''};
    color: ${isSelected ? '#ffffff' : ''};
  `}
`;

const PrimaryText = styled.div`
  ${({ isDisabled }) => css`
    margin-bottom: 2px;
    color: ${isDisabled ? '#b8b8b8' : ''};
  `}
`;

const SecondaryText = styled.div`
  ${({ isDisabled, isSelected }) => css`
    font-size: 12px;
    color: ${isSelected ? '#ffffff' : '#767676'};
    color: ${isDisabled ? '#b8b8b8' : ''};
  `}
`;
