import React from 'react';

import { DateTime } from 'luxon';
import { Step, Header } from 'semantic-ui-react';

import Section from 'components/layout/Section';
import { getProjectStage } from 'data/project';

function ProjectHistory({ history = [] }) {
  const totalItems = history.length;

  function formatTitle(projectStageDescription, durationInDaysDescription) {
    if (!durationInDaysDescription) {
      return projectStageDescription;
    }

    return `${projectStageDescription} - ${durationInDaysDescription};`;
  }

  function formatDurationInDays(durationInDays) {
    if (durationInDays == null) {
      return '';
    }

    return durationInDays === 1
      ? `${durationInDays} dia`
      : `${durationInDays} dias`;
  }

  return (
    <Section>
      <Section.Header>
        <Header as="h3" style={{ margin: 0 }}>
          Histórico
        </Header>
      </Section.Header>
      <Section.Content>
        <Step.Group style={{ marginTop: '16px' }} vertical fluid>
          {history.map(
            ({ id, projectStage, durationInDays, date, userName }, index) => {
              const isLastIndex = totalItems - 1 === index;

              return (
                <Step active={isLastIndex} key={id.toString()}>
                  <Step.Content>
                    <Step.Title>
                      {formatTitle(
                        getProjectStage(projectStage),
                        formatDurationInDays(durationInDays),
                      )}
                    </Step.Title>
                    <Step.Description>{`${DateTime.fromISO(date).toFormat(
                      'dd/MM/yyyy',
                    )} - ${userName}`}</Step.Description>
                  </Step.Content>
                </Step>
              );
            },
          )}
        </Step.Group>
      </Section.Content>
    </Section>
  );
}

export default ProjectHistory;
