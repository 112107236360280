import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import Section from 'components/layout/Section';
import { downloadDocument } from 'hooks/document';
import { useModal, useModalWithData } from 'hooks/useModal';

import ChangeProjectCropModal from './ChangeProjectCropModal';
import EditProjectDocumentsModal from './EditProjectDocumentsModal';
import LocationSection from './LocationSection';
import ProjectDocuments from './ProjectDocuments';

export default function ProjectOverviewPage({
  project,
  installation,
  documents,
  refreshProject,
}) {
  const routerHistory = useHistory();

  const documentsModal = useModalWithData();
  const projectCropModal = useModal();

  const handleSuccess = React.useCallback(() => {
    toast.success('Documentos atualizados com sucesso!');
    documentsModal.close();
    refreshProject();
  }, [documentsModal, refreshProject]);

  function handleEditProtocolClick() {
    routerHistory.push(`/estudos/${project.id}/protocolo/editar`);
  }

  function toggleModal() {
    if (documentsModal.isOpen) {
      documentsModal.close();
    } else {
      documentsModal.open(project);
    }
  }

  function handleEditDocumentClick() {
    toggleModal();
  }

  function handleDocumentClick(documentId) {
    downloadDocument(documentId);
  }

  function handleCropClick() {
    projectCropModal.open();
  }

  const requiredDate = DateTime.fromISO(project?.requiredDate).toFormat(
    'dd/MM/yyyy',
  );
  const reassignedDate = project?.reassignedDate
    ? DateTime.fromISO(project?.reassignedDate).toFormat('dd/MM/yyyy')
    : '';
  const { targets, projectCrops } = project;
  const cropsFormatted =
    projectCrops
      .map(
        ({ crop, variety }) => `${crop.name}${variety ? ` (${variety})` : ''}`,
      )
      .join(', ') || 'n/a';
  const productNames = project.products.length
    ? project.products
        .map((product) => {
          return product.name || 'n/d';
        })
        .join(' | ')
    : 'Não disponível';

  return (
    <>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Header>
                <Header as="h3">Resumo</Header>
              </Section.Header>
              <Section.Content>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Cultura</Label>
                        <Value onClick={handleCropClick}>
                          {cropsFormatted}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Praga alvo</Label>
                        {targets.map((target) => (
                          <Value key={target.id.toString()}>
                            {target.name}
                          </Value>
                        ))}
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Produto</Label>
                        <Value>{productNames}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Data requerida</Label>
                        <Value>{requiredDate}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Data remanejada</Label>
                        <Value>{reassignedDate || 'n/a'}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProjectDocuments
              documents={documents}
              onEditDocumentClick={handleEditDocumentClick}
              onDocumentClick={handleDocumentClick}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LocationSection
              project={project}
              location={installation}
              refreshProject={refreshProject}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Section>
              <Section.Header>
                <Header as="h3" style={{ margin: 0 }}>
                  Estudo
                </Header>
                <Button
                  type="button"
                  size="small"
                  onClick={handleEditProtocolClick}
                  basic
                >
                  <Icon name="edit" />
                  Editar
                </Button>
              </Section.Header>
              <Section.Content>
                <Grid>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Título</Label>
                        <Value>{project.title || 'Não disponível'}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Introdução</Label>
                        <Value>{project.intro || 'Não disponível'}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Objetivo</Label>
                        <Value>{project.objective || 'Não disponível'}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Metodologia de aplicação e avaliação</Label>
                        <Value>
                          {project.applicationMethodology || 'Não disponível'}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Equipamento</Label>
                        <Value>{project.equipment || 'Não disponível'}</Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Barra (no. de bicos)</Label>
                        <Value>
                          {project.equipmentBar || 'Não disponível'}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Modelo de bico</Label>
                        <Value>
                          {project.nozzlesModel || 'Não disponível'}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Volume da calda</Label>
                        <Value>
                          {project.equipmentVolume || 'Não disponível'}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns="equal">
                    <Grid.Column>
                      <ItemWrapper>
                        <Label>Referências</Label>
                        <Value>
                          {project.references.length
                            ? project.references.map(
                                ({ number, description }) => (
                                  <div
                                    key={number.toString()}
                                  >{`${number}. ${description}`}</div>
                                ),
                              )
                            : 'Não disponível'}
                        </Value>
                      </ItemWrapper>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {documentsModal.isOpen ? (
        <EditProjectDocumentsModal
          project={documentsModal.data}
          open={documentsModal.isOpen}
          onClose={toggleModal}
          onSuccess={handleSuccess}
        />
      ) : null}

      {projectCropModal.isOpen ? (
        <ChangeProjectCropModal
          projectId={project.id}
          isOpen={projectCropModal.isOpen}
          onClose={projectCropModal.close}
        />
      ) : null}
    </>
  );
}

const ItemWrapper = styled.div`
  flex-direction: column;
  padding: 8px 0;
`;

const Label = styled.div`
  color: #767676;
  font-size: 14px;
`;

const valueModifiers = {
  onClick: () => css`
    color: #4a9add;
    cursor: pointer;
  `,
};

const Value = styled.span`
  ${({ onClick }) => css`
    font-size: 14px;

    ${onClick && valueModifiers.onClick()}
  `}
`;
