import { useEffect, useState } from 'react';

import { handleError } from 'util/ErrorHandler';

export function useErrorHandler() {
  const [error, setError] = useState<Error | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  useEffect(() => {
    if (error) {
      setMessage(handleError(error));
    } else {
      setMessage(null);
    }
  }, [error]);

  return {
    message,
    error,
    handleError: setError,
    clear: () => setError(null),
  };
}
