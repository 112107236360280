import { useQuery } from 'react-query';

import api from 'util/api';

export type PropertyQuery = {
  id: number;
  name: string;
  owner: string;
  ownerDocument: string;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  cep: string;
  phone: string;
  ownerAddress?: string;
  ownerNeighborhood?: string;
  ownerCity?: string;
  ownerState?: string;
  ownerCep?: string;
};

export type PropertyFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  name?: string;
};

export function useProperties(filter: PropertyFilter) {
  const result = useQuery(['properties', filter], () =>
    getProperties({ orderBy: 'id', sort: 'desc', ...filter }),
  );
  return {
    ...result,
    properties: result.data?.properties,
    pagination: result.data?.pagination,
  };
}

async function getProperties(filter: PropertyFilter) {
  const response = await api.get(`/properties`, { params: filter });
  const { data: properties, pagination } = response.data;
  return { properties, pagination };
}

export async function getProperty(propertyId: number) {
  const { data } = await api.get(`/properties/${propertyId}`);
  return data;
}
