import {
  getDivision,
  getProjectGoal,
  getManagementType,
  getTestLocation,
} from 'data/project';
import api from 'util/api';
import { formatToBr, parseIso, formatToIso } from 'util/DateFormatter';
import { toCurrency } from 'util/numberFormat';

const makeQuotationRepository = () => ({
  async getQuotations(params) {
    const response = await api.get(`/quotations`, { params });

    const { data, pagination } = response.data;

    const quotations = quotationMapperList({ data, origin: 'API' });

    return { data: quotations, pagination };
  },

  async getQuotationProject(quotationId, quotationProjectId) {
    const { data } = await api.get(
      `/quotations/${quotationId}/projects/${quotationProjectId}`,
    );

    return quotationProjectMapper({ data, origin: 'API' });
  },

  async createQuotationProject(data) {
    const createQuotationProjectApi = quotationProjectMapper({
      origin: 'API_CREATE',
      data,
    });

    try {
      await api.post(
        `/quotations/${data.quotationId}/projects`,
        createQuotationProjectApi,
      );
    } catch (e) {
      throw e.response.data;
    }
  },
});

export function quotationMapperList({ data, origin }) {
  return data.map((item) => quotationMapper({ data: item, origin }));
}

export function quotationMapper({ data, origin }) {
  const mappers = {
    API: apiQuotationResponseMapper,
    API_CREATE: apiQuotationCreateMapper,
    API_UPDATE: apiQuotationUpdateMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

function apiQuotationResponseMapper(quotationApi) {
  return {
    ...quotationApi,
    quotationStatusDescription: formatQuotationStatus(quotationApi.status),
    quotationStatusColor: formatQuotationStatusColor(quotationApi.status),
    harvest: quotationApi.harvest,
    quotationDate: parseIso(quotationApi.quotationDate),
    quotationDateFormatted: formatToBr(quotationApi.quotationDate),
    reviewDate: quotationApi.reviewDate
      ? parseIso(quotationApi.reviewDate)
      : null,
    reviewDateFormatted: quotationApi.reviewDate
      ? formatToBr(quotationApi.reviewDate)
      : '',
    responseDate: quotationApi.responseDate
      ? parseIso(quotationApi.responseDate)
      : null,
    responseDateFormatted: quotationApi.responseDate
      ? formatToBr(quotationApi.responseDate)
      : '',
    totalValueFormatted: `R$ ${toCurrency(quotationApi.totalValue)}`,
    divisionsFormatted: quotationApi.divisions.map((division) =>
      getDivision(division),
    ),
    currency: quotationApi.currency,
    payments: quotationApi.payments.map((payment) => {
      return {
        id: payment.id,
        installment: payment.installment,
        percent: payment.percent,
        percentFormatted: `${Math.round(payment.percent * 100)}%`,
        description: payment.description,
        amount:
          payment.amount ||
          getCompatibilityPayments(
            quotationApi.hasDiscount
              ? quotationApi.totalAmountWithDiscount
              : quotationApi.totalAmount,
            payment.percent,
          ),
      };
    }),
  };
}

function getCompatibilityPayments(projectTotalAmount, paymentPercent) {
  return projectTotalAmount * paymentPercent;
}

function formatQuotationStatus(quotationStatus) {
  switch (quotationStatus) {
    case 1:
      return 'Em negociação';
    case 2:
      return 'Aprovado';
    case 3:
      return 'Não aprovado';
    case 4:
      return 'Contrato gerado';
    default:
      return '';
  }
}

export function formatQuotationStatusColor(quotationStatus) {
  switch (quotationStatus) {
    case 1:
      return 'blue';
    case 2:
      return 'green';
    case 3:
      return 'red';
    case 4:
      return 'transparent';
    default:
      return '';
  }
}

function apiQuotationCreateMapper(quotation) {
  return {
    quotationDate: formatToIso(quotation.quotationDate),
    customerId: quotation.customerId,
    representativeId: quotation.representativeId,
    quotationResponsibleId: quotation.quotationResponsibleId,
    currency: quotation.currency,
    discountPercent: quotation.discountPercent,
    harvest: quotation.harvest,
    notes: quotation.notes,
  };
}

function apiQuotationUpdateMapper(quotationForm) {
  const apiValues = {};

  if (quotationForm?.quotationDate) {
    apiValues.quotationDate = formatToIso(quotationForm.quotationDate);
  }

  if (quotationForm?.harvest) {
    apiValues.harvest = quotationForm.harvest;
  }

  if (quotationForm?.representativeId) {
    apiValues.representativeId = quotationForm.representativeId;
  }

  if (quotationForm?.quotationResponsible) {
    apiValues.quotationResponsibleId = quotationForm.quotationResponsible;
  }

  if (quotationForm?.notes) {
    apiValues.notes = quotationForm.notes;
  }

  if (quotationForm?.version) {
    apiValues.version = quotationForm.version;
  }

  if (quotationForm?.reviewDate) {
    apiValues.reviewDate = formatToIso(quotationForm.reviewDate);
  }

  if (quotationForm?.responseDate) {
    apiValues.responseDate = formatToIso(quotationForm.responseDate);
  }

  if (quotationForm?.currency) {
    apiValues.currency = quotationForm.currency;
  }

  if (quotationForm?.discountPercent !== undefined) {
    apiValues.discountPercent = quotationForm.discountPercent;
  }

  return apiValues;
}

function quotationProjectMapper({ data, origin }) {
  const mappers = {
    API: apiQuotationProjectResponseMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

function apiQuotationProjectResponseMapper(quotationProjectApi) {
  return {
    ...quotationProjectApi,
    productsNames: quotationProjectApi.products.map((p) => p.name),
    cropsNames: quotationProjectApi.crops.map((c) => c.name),
    targetsNames: quotationProjectApi.targets.map((t) => t.name),
    value: parseFloat(quotationProjectApi.value),
    valueFormatted: `R$ ${toCurrency(quotationProjectApi.value)}`,
    totalValue: parseFloat(quotationProjectApi.totalValue),
    totalValueFormatted: `R$ ${toCurrency(quotationProjectApi.totalValue)}`,
    division: quotationProjectApi.division,
    divisionDescription: getDivision(quotationProjectApi.division),
    projectGoal: quotationProjectApi.projectGoal,
    projectGoalDescription: getProjectGoal(quotationProjectApi.projectGoal),
    managementType: quotationProjectApi.managementType,
    managementTypeDescription: getManagementType(
      quotationProjectApi.managementType,
    ),
    testLocation: quotationProjectApi.testLocation,
    testLocationDescription: getTestLocation(quotationProjectApi.testLocation),
    updatedAt: parseIso(quotationProjectApi.updatedAt),
    updatedAtFormatted: formatToBr(quotationProjectApi.updatedAt),
  };
}

export default makeQuotationRepository;
