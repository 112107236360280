import * as React from 'react';

import Text from 'components/foundation/Text';
import { Pagination } from 'util/api';

type PaginationInfoProps = {
  description: string;
  pagination: Pagination;
};

export default function PaginationInfo({
  description,
  pagination,
}: PaginationInfoProps) {
  const parcialCount = pagination.page * pagination.pageSize;
  const count =
    parcialCount < pagination.total ? parcialCount : pagination.total;

  return (
    <Text variant="primary">
      {description} {count} de {pagination.total}
    </Text>
  );
}
