import React, { useContext, createContext, useState } from 'react';

import { CustomerQuery } from 'queries/customer';
import { ProfitSharingProjectsFilter } from 'queries/project';

type Option = {
  value: number;
  label: string;
};

type ProfitSharingFilterProps = {
  year?: Date;
  month?: Option;
  customer?: CustomerQuery;
  divisions?: Option[];
  status?: Option;
};

export type ProfitSharingFilterContextData = {
  filter: ProfitSharingFilterProps;
  updateFilter: (newData: ProfitSharingFilterProps) => void;
  getMappedFilter: () => ProfitSharingProjectsFilter;
  resetFilter: () => void;
};

export const profitSharingStatusOptions = [
  { value: 1, label: 'Em andamento' },
  { value: 2, label: 'Todos' },
] as const;

const defaultStatus = profitSharingStatusOptions[0];

export const ProfitSharingFilterContextDefaultValues = {
  filter: {
    year: new Date(),
    month: undefined,
    customer: undefined,
    divisions: undefined,
    status: defaultStatus,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
  resetFilter: () => null,
};

export const ProfitSharingFilterContext = createContext<
  ProfitSharingFilterContextData
>(ProfitSharingFilterContextDefaultValues);

export type ProfitSharingFilterProviderProps = {
  children: React.ReactNode;
};

const ProfitSharingFilterProvider = ({
  children,
}: ProfitSharingFilterProviderProps) => {
  const [profitSharingFilter, setProfitSharingFilter] = useState<
    ProfitSharingFilterProps
  >({
    year: new Date(),
    status: defaultStatus,
  });

  const updateFilter = (data: ProfitSharingFilterProps) => {
    const newFilter = { ...profitSharingFilter, ...data };
    setProfitSharingFilter(newFilter);
  };

  const getMappedFilter = () => {
    const { year, month, customer, divisions, status } = profitSharingFilter;
    return {
      year: year?.getFullYear() || new Date().getFullYear(),
      month: month?.value || null,
      customerId: customer?.id || null,
      divisions: divisions?.map((option) => option?.value) || null,
      status: status?.value || null,
    };
  };

  function resetFilter() {
    setProfitSharingFilter({
      year: new Date(),
      status: defaultStatus,
    });
  }

  return (
    <ProfitSharingFilterContext.Provider
      value={{
        filter: profitSharingFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </ProfitSharingFilterContext.Provider>
  );
};

const useProfitSharingFilter = () => useContext(ProfitSharingFilterContext);

export { ProfitSharingFilterProvider, useProfitSharingFilter };
