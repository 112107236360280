import React, { useContext, createContext, useState } from 'react';

import { allDivisionValues } from 'components/inputs/DivisionSelect';
import {
  allStagesValues,
  inProgressStagesValues,
} from 'components/inputs/ProjectStageSelect';
import { useAuth } from 'hooks/auth';
import { CustomerQuery } from 'queries/customer';

type Option = {
  value: number;
  label: string;
};

type ProjectFilter = {
  contractNumber?: string;
  crops?: Option[];
  customers?: CustomerQuery[];
  divisions?: Option[];
  harvest?: number;
  insideCode?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  projectGoal?: Option[];
  protocol?: string;
  sort?: string;
  stages?: Option[];
  targets?: Option[];
  status: Option;
};

export type ProjectFilterContextData = {
  filter: ProjectFilter;
  updateFilter: (newData: ProjectFilter) => void;
  getMappedFilter: () => void;
  resetFilter: () => void;
  isLoading: boolean;
};

export const IN_PROGRESS = 1;
export const ALL = 2;

const defaultStatus = { value: IN_PROGRESS, label: 'Em andamento' };

export const ProjectFilterContextDefaultValues = {
  filter: {
    contractNumber: '',
    crops: undefined,
    customers: undefined,
    divisions: undefined,
    harvest: undefined,
    insideCode: '',
    orderBy: 'requiredDate',
    page: 1,
    pageSize: 10,
    projectGoal: undefined,
    protocol: '',
    sort: 'desc',
    stages: undefined,
    targets: undefined,
    status: defaultStatus,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
  resetFilter: () => null,
  isLoading: true,
};

export const ProjectFilterContext = createContext<ProjectFilterContextData>(
  ProjectFilterContextDefaultValues,
);

export type ProjectFilterProviderProps = {
  children: React.ReactNode;
};

const ProjectFilterProvider = ({ children }: ProjectFilterProviderProps) => {
  const [projectFilter, setProjectFilter] = useState<ProjectFilter>({
    page: 1,
    pageSize: 10,
    orderBy: 'requiredDate',
    sort: 'desc',
    status: defaultStatus,
  });

  const { user } = useAuth();

  const updateFilter = (data: ProjectFilter) => {
    const newFilter = { ...projectFilter, ...data };
    setProjectFilter(newFilter);
  };

  const extractValue = ({ value }: { value: number }) => value;

  const getMappedFilter = () => {
    const {
      customers,
      crops,
      targets,
      divisions,
      stages,
      status,
      ...filterUpdated
    } = projectFilter;

    const customerIds = customers?.length ? customers?.map(({ id }) => id) : '';
    const cropIds = crops?.length ? crops?.map(extractValue) : '';
    const targetIds = targets?.length ? targets?.map(extractValue) : '';

    const defaultDivisions = user.isAdmin ? allDivisionValues : user.divisions;
    const divisionsValues = divisions?.length
      ? divisions?.map(extractValue)
      : [];
    const filteredDivisionValues = divisions?.length
      ? defaultDivisions.filter((division) =>
          divisionsValues.includes(division),
        )
      : defaultDivisions;

    const stagesDefined =
      status.value === IN_PROGRESS ? inProgressStagesValues : allStagesValues;
    const stagesValues = stages?.map(extractValue) || [];
    const filteredStageValues = stages?.length
      ? stagesDefined.filter((stage) => stagesValues.includes(stage))
      : stagesDefined;

    return {
      ...filterUpdated,
      customerIds,
      cropIds,
      targetIds,
      divisions: filteredDivisionValues,
      stages: filteredStageValues,
    };
  };

  function resetFilter() {
    setProjectFilter({
      page: 1,
      pageSize: 10,
      orderBy: 'requiredDate',
      sort: 'desc',
      insideCode: '',
      protocol: '',
      harvest: undefined,
      customers: undefined,
      stages: undefined,
      crops: undefined,
      targets: undefined,
      divisions: undefined,
      projectGoal: undefined,
      status: defaultStatus,
    });
  }

  return (
    <ProjectFilterContext.Provider
      value={{
        filter: projectFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </ProjectFilterContext.Provider>
  );
};

const useProjectFilter = () => useContext(ProjectFilterContext);

export { ProjectFilterProvider, useProjectFilter };
