import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { divisionKeys } from 'queries/division';
import api from 'util/api';

type UpdateDivisionInput = {
  divisionId: number;
  responsibleId: number;
};

export function useChangeDivisionResponsible(
  options?: UseMutationOptions<void, AxiosError, UpdateDivisionInput>,
) {
  const queryClient = useQueryClient();
  return useMutation(changeDivisionResponsible, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(divisionKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export async function changeDivisionResponsible({
  divisionId,
  responsibleId,
}: UpdateDivisionInput) {
  await api.post(`admin/divisions/${divisionId}`, { responsibleId });
}
