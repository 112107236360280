import React from 'react';

import { Container, UploadContainer, UploadMessage } from './styles';

export default function FileUpload({
  name,
  text = 'Procurar arquivo',
  onUpload,
}) {
  function upload() {
    document.getElementById(name).click();
  }

  return (
    <Container>
      <UploadContainer>
        <UploadMessage onClick={upload}>
          <input name={name} type="file" hidden id={name} onChange={onUpload} />
          {text}
        </UploadMessage>
      </UploadContainer>
    </Container>
  );
}
