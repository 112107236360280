import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { quotationKeys } from 'data/quotation';
import api from 'util/api';

type CreateQuotationInput = {
  quotationDate: string;
  harvest: number;
  customerId: number;
  representativeId: number;
  currency: number;
  quotationType: number;
  discountPercent?: number | null;
  notes?: string | null;
};

export function useCreateQuotation(
  options?: UseMutationOptions<
    number,
    AxiosError,
    CreateQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(createQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createQuotation(input: CreateQuotationInput) {
  const response = await api.post('/quotations', input);
  const { location } = response.headers;
  const quotationId = parseInt(location.substring(15), 10);

  return quotationId;
}

type UpdateQuotationInput = {
  quotationId: number;
  quotationDate?: string;
  harvest?: number;
  customerId?: number;
  representativeId?: number;
  currency?: number;
  quotationType?: number;
  discountPercent?: number | null;
  notes?: string | null;
};

export function useUpdateQuotation(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateQuotation(input: UpdateQuotationInput) {
  await api.patch(`/quotations/${input.quotationId}`, input);
}

type UpdateQuotationProjectInput = {
  id: number;
  quotationId: number;
  description?: string | null;
  protocol?: string | null;
  productIds?: number[];
  cropIds?: number[];
  targetIds?: number[];
  division?: number;
  projectGoal?: number;
  managementType?: number;
  testLocation?: number;
  durationForecast?: number;
  amount?: number;
  treatmentQuantity?: number;
  trialsNumber?: number;
  projectsNumber?: number;
};

export function useUpdateQuotationProject(
  options: UseMutationOptions<
    void,
    AxiosError,
    UpdateQuotationProjectInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateQuotationProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateQuotationProject({
  id,
  quotationId,
  ...input
}: UpdateQuotationProjectInput) {
  await api.patch(`/quotations/${quotationId}/projects/${id}`, input);
}

type PaymentInput = {
  installment: number;
  percent: number;
  description: string;
};

type PaymentType = 'percent' | 'amount';

type UpdateQuotationPaymentsInput = {
  payments: PaymentInput[];
  paymentType: PaymentType;
  quotationId: number;
};

export function useUpdateQuotationPayment(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateQuotationPaymentsInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateQuotationPayment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateQuotationPayment({
  quotationId,
  ...input
}: UpdateQuotationPaymentsInput) {
  await api.put(`/quotations/${quotationId}/payments`, input);
}

export type QuotationProjectDuplicateInput = {
  quotationId: number;
  quotationProjectId: number;
};

export function useDuplicateProject(
  options: UseMutationOptions<
    void,
    AxiosError,
    QuotationProjectDuplicateInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(duplicateProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function duplicateProject({
  quotationId,
  quotationProjectId,
}: QuotationProjectDuplicateInput) {
  api.post(
    `quotations/${quotationId}/projects/${quotationProjectId}/duplicate`,
  );
}

export function useApproveQuotation(
  options: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(approveQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function approveQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/approve`);
}

export function useRejectQuotation(
  options: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(rejectQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function rejectQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/reject`);
}

type DeleteQuotationItemInput = {
  quotationId: number;
  quotationItemId: number;
};

export function useRemoveQuotationItem(
  options: UseMutationOptions<
    void,
    AxiosError,
    DeleteQuotationItemInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(removeItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function removeItem({
  quotationId,
  quotationItemId,
}: DeleteQuotationItemInput) {
  await api.delete(`/quotations/${quotationId}/projects/${quotationItemId}`);
}

type GenerateContractInput = {
  quotationId: number;
  number: string;
  signatureDate: Date;
  notes?: string | null;
};

export function useGenerateContract(
  options: UseMutationOptions<
    number,
    AxiosError,
    GenerateContractInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(generateContract, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('contract');
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function generateContract(input: GenerateContractInput) {
  const { headers } = await api.post(
    `/quotations/${input.quotationId}/generating-contract`,
    input,
  );
  const { location } = headers;
  const contractId = parseInt(location.substring(14), 10);
  return contractId;
}

export function useRevertQuotation(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(revertQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotations');
      queryClient.invalidateQueries('quotation');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function revertQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/revert-to-negotiation`);
}

export function useDeleteQuotation(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(deleteQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(quotationKeys.all);
      queryClient.invalidateQueries(quotationKeys.detail(variables));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteQuotation(quotationId: number) {
  await api.delete(`/quotations/${quotationId}`);
}

export const SHIPPING_TYPES = {
  CIF: 1,
  FOB: 2,
};

type AddShippingQuotationInput = {
  amount: number | null;
  quantity: number;
  quotationId: number;
  shippingType: number;
};

export function useAddShippingQuotation(
  options?: UseMutationOptions<
    void,
    AxiosError,
    AddShippingQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(addShippingQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addShippingQuotation(input: AddShippingQuotationInput) {
  await api.post(`/quotations/${input.quotationId}/shippings`, input);
}

type UpdateShippingQuotationInput = {
  amount: number | null;
  quantity: number;
  quotationId: number;
  shippingType: number;
};

export function useUpdateShippingQuotation(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateShippingQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateShippingQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateShippingQuotation({
  quotationId,
  ...input
}: AddShippingQuotationInput) {
  await api.put(`/quotations/${quotationId}/shippings`, input);
}

export function useDeleteShippingQuotation(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(deleteShippingQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteShippingQuotation(quotationId: number) {
  await api.delete(`/quotations/${quotationId}/shippings`);
}

type AddProductItemInput = {
  division: number;
  quantity: number;
  quotationId: number;
  saleProductId: number;
  shippingForecast: Date | null;
  unit: number;
  unitPrice: number;
};

export function useAddProductItem(
  options?: UseMutationOptions<
    void,
    AxiosError,
    AddProductItemInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(addProductItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addProductItem(input: AddProductItemInput) {
  await api.post(`/quotations/${input.quotationId}/products`, input);
}

type UpdateProductItemInput = AddProductItemInput & {
  productItemId: number;
};

export function useUpdateProductItem(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateProductItemInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateProductItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

type DeleteProductItemInput = {
  quotationId: number;
  productItemId: number;
};

async function updateProductItem({
  productItemId,
  ...input
}: UpdateProductItemInput) {
  const url = `/quotations/${input.quotationId}/products/${productItemId}`;
  await api.patch(url, input);
}

export function useDeleteProductItem(
  options?: UseMutationOptions<void, AxiosError, DeleteProductItemInput>,
) {
  const queryClient = useQueryClient();
  return useMutation(deleteProductItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteProductItem({
  quotationId,
  productItemId,
}: DeleteProductItemInput) {
  await api.delete(`/quotations/${quotationId}/products/${productItemId}`);
}

type DuplicateProductItemInput = {
  quotationId: number;
  productItemId: number;
};

export function useDuplicateProductItem(
  options?: UseMutationOptions<void, AxiosError, DuplicateProductItemInput>,
) {
  const queryClient = useQueryClient();
  return useMutation(duplicateProductItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function duplicateProductItem({
  quotationId,
  productItemId,
}: DeleteProductItemInput) {
  await api.post(
    `/quotations/${quotationId}/products/${productItemId}/duplicate`,
  );
}
