import React from 'react';

import { Button } from './styles';

export type LinkButtonProps = {
  type?: 'button' | 'submit' | 'reset';
  onClick: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  bold?: boolean;
  children: React.ReactNode;
};

export default function LinkButton({
  type = 'button',
  onClick,
  onMouseEnter = undefined,
  onMouseLeave = undefined,
  bold = false,
  children,
}: LinkButtonProps) {
  return (
    <Button
      type={type}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      bold={bold}
    >
      {children}
    </Button>
  );
}
