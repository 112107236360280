import React from 'react';

import toast from 'react-hot-toast';
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useModalWithData } from 'hooks/useModal';
import { useProcedures } from 'queries/procedure';

import ProcedureModalForm from './ProcedureModalForm';
import ProcedureTable from './ProcedureTable';

export default function Procedures() {
  const procedureModal = useModalWithData();
  const { procedures, isLoading } = useProcedures();

  function handleAddClick() {
    procedureModal.open();
  }

  function handleSuccess(message: string) {
    procedureModal.close();
    toast.success(message);
  }

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column verticalAlign="middle">
              <Header as="h1">POPs</Header>
            </Grid.Column>
            {procedures.length ? (
              <Grid.Column verticalAlign="middle" textAlign="right">
                <Button
                  type="button"
                  size="large"
                  onClick={handleAddClick}
                  primary
                >
                  <Icon name="add" />
                  Novo POP
                </Button>
              </Grid.Column>
            ) : null}
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <ProcedureTable
                    items={procedures}
                    onAddClick={handleAddClick}
                    isLoading={isLoading}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      {procedureModal.isOpen ? (
        <ProcedureModalForm
          isOpen={procedureModal.isOpen}
          onClose={procedureModal.close}
          onSuccess={handleSuccess}
        />
      ) : null}
    </Base>
  );
}
