import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';

import api, { emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

export type Activity = {
  id: number;
  activityTypeId: number;
  date: string;
  type: string;
  executionDate: string | null;
  bbchScale: string | null;
  description: string | null;
  projectId: number;
  project: string;
  customer: string;
  responsibleTechnician: string;
  createdAt: string;
  updatedAt: string;
};

export type ActivityFilter = {
  customer?: number;
  divisions?: number[];
  endDate?: Date;
  insideCode?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  startDate?: Date;
  status?: number;
  inProgress?: boolean;
};

const TYPE_APPLICATION = 1;
const TYPE_EVALUATION = 2;
const TYPE_APPLICATION_AND_EVALUATION = 3;
const TYPE_PRODUCTIVITY = 4;
const TYPE_INSTALLATION = 5;
const TYPE_INFESTATION_AND_INOCULATION = 6;
const TYPE_PLANTING = 7;

export const activityTypes: Record<string, string> = {
  [TYPE_APPLICATION]: 'Aplicação',
  [TYPE_APPLICATION_AND_EVALUATION]: 'Aplicação/Avaliação',
  [TYPE_EVALUATION]: 'Avaliação',
  [TYPE_INFESTATION_AND_INOCULATION]: 'Infestação',
  [TYPE_INSTALLATION]: 'Instalação',
  [TYPE_PLANTING]: 'Plantio',
  [TYPE_PRODUCTIVITY]: 'Produtividade',
} as const;

export const ACTIVITY_STATUS = {
  notPerformed: 1,
  performed: 2,
  all: 3,
} as const;

export const activityKeys = {
  all: ['activities'] as const,
  detail: (id: number) => [...activityKeys.all, id] as const,
};

export function useInfiniteActivities(filter: ActivityFilter) {
  const result = useInfiniteQuery(
    [...activityKeys.all, filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getActivities({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const activities =
    result?.data?.pages
      ?.map((group) => group?.activities?.map((activity) => activity))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    activities,
  };
}

export type ActivitiesQuery = {
  id: number;
  activityTypeId: number;
  bbchScale: string;
  customer: string;
  date: string;
  dayOfWeek: string;
  description: string | null;
  executionDate: string | null;
  isLate: boolean;
  project: string;
  projectId: number;
  representative: string;
  representativeId: number;
  responsibleTechnician: string;
  type: string;
  status: {
    description: string;
    color: string;
  };
};

async function getActivities(filter: ActivityFilter) {
  const { data } = await api.get<Response<ActivitiesQuery>>('/activities', {
    params: { ...filter },
  });
  return { activities: data.data, pagination: data.pagination };
}

export function useActivity(
  activityId: number,
  options?: Pick<UseQueryOptions<ActivitiesQuery, AxiosError>, 'onSuccess'>,
) {
  return useQuery({
    queryKey: [activityKeys.detail(activityId)],
    queryFn: () => getActivity(activityId),
    enabled: !!activityId,
    onSuccess: options?.onSuccess,
  });
}

async function getActivity(activityId: number) {
  const { data } = await api.get<Activity>(`/activities/${activityId}`);
  return data;
}

export function useExportActivities(
  options?: UseMutationOptions<void, AxiosError, ActivityFilter, () => void>,
) {
  return useMutation(exportActivities, {
    ...options,
  });
}

const CONFIG = { responseType: 'blob' } as const;

async function exportActivities(params: ActivityFilter) {
  const url = `/activities/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}

export function useExportActivitiesForField(
  options?: UseMutationOptions<void, AxiosError, ActivityFilter, () => void>,
) {
  return useMutation(exportActivitiesForField, {
    ...options,
  });
}

async function exportActivitiesForField(params: ActivityFilter) {
  const url = `/activities/export-for-field`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}
