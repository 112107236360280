import React from 'react';

import Select from 'react-select';

import { useProperties } from 'queries/property';

export default function PropertySearch({
  name,
  placeholder = 'Busque pelo nome...',
  value,
  onBlur,
  onChange,
  disabled,
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const { data, isLoading, error } = useProperties({ name: searchValue });

  const properties = error || !data || !data.properties ? [] : data.properties;

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      isLoading={isLoading}
      value={value}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      inputValue={searchValue}
      onInputChange={handleInputChange}
      onBlur={onBlur}
      onChange={onChange}
      options={properties}
      isDisabled={disabled}
    />
  );
}
