import React from 'react';

import FileUpload from 'components/inputs/FileUpload';
import { makeDetailedFile, uploadDocument } from 'hooks/document';

import DocumentItem from './DocumentItem';

export default function DocumentInput({
  name,
  defaultFile = null,
  onDelete,
  onUploadSuccess,
  onUploadFailed,
  documentType,
}) {
  const [file, setFile] = React.useState(defaultFile);

  function handleUpload(e) {
    const updatedFile = makeDetailedFile(e.target.files[0]);

    uploadDocument({
      file: updatedFile,
      documentType,
      onUploadProgress: (progress) => {
        const updatedFileWithProgress = { ...updatedFile, progress };

        setFile(updatedFileWithProgress);
      },
    })
      .then((response) => {
        const uploadedFile = {
          ...updatedFile,
          progress: 100,
          uploaded: true,
          id: response.id,
          url: response.url,
        };

        setFile(uploadedFile);

        onUploadSuccess(uploadedFile);
      })
      .catch(() => {
        const failedFile = { ...updatedFile, progress: 100, error: true };

        setFile(failedFile);

        onUploadFailed(failedFile);
      });
  }

  function handleDelete() {
    setFile(null);
    onDelete();
  }

  return (
    <>
      {!file && <FileUpload name={name} onUpload={handleUpload} />}
      {file && (
        <DocumentItem name={name} uploadedFile={file} onDelete={handleDelete} />
      )}
    </>
  );
}
