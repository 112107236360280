/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';

import { Container, Form, Button, Message } from 'semantic-ui-react';

import { saveUserAndToken } from 'hooks/use-auth';
import api from 'util/api';

import logoImage from './styles/images/logo.png';

const LOGIN_ENDPOINT = `${process.env.REACT_APP_API_URL}/auth/login`;

export default class AuthPage extends Component {
  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      error: false,
      isLoading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  openProductionsScreen() {
    window.location.reload();
  }

  async login({ email, password }) {
    try {
      const { data } = await api.post(LOGIN_ENDPOINT, { email, password });

      saveUserAndToken(data);
      this.openProductionsScreen();
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ error: true, isLoading: false });
    }
  }

  handleInputChange({ target }) {
    const { value, name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ isLoading: true });

    const { email, password } = this.state;

    this.login({ email, password });
  }

  render() {
    const { isLoading, error, email, password } = this.state;

    return (
      <div
        style={{ textAlign: 'center', paddingTop: '150px', fontSize: '32px' }}
      >
        <div>
          <b>Sismip</b>
        </div>
        <Container style={styles.container}>
          <div style={styles.message}>
            <span>Bem-vindo!</span>
          </div>
          <Form onSubmit={this.handleSubmit} error={!!error}>
            <Form.Field>
              <input
                name="email"
                type="email"
                placeholder="seu@promip.agr.br"
                value={email}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            <Form.Field>
              <input
                name="password"
                type="password"
                placeholder="sua senha"
                value={password}
                onChange={this.handleInputChange}
                required
              />
            </Form.Field>
            <Message error content="E-mail ou senha inválidos" />
            <Button type="submit" primary fluid loading={!!isLoading}>
              Entrar
            </Button>
          </Form>
        </Container>
        <img
          src={logoImage}
          width="240"
          alt="Logo da Staphyt e demais empresas do grupo"
          style={{ marginTop: 24 }}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    textAlign: 'left',
    background: '#fff',
    padding: '0px 20px 30px',
    width: '400px',
    marginTop: '30px',
  },
  message: {
    textAlign: 'center',
    padding: '20px 0',
    fontSize: '18px',
  },
};
