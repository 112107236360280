import * as React from 'react';

import { DateTime } from 'luxon';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Popup,
  Table,
} from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import ShipmentItemsModal, {
  ShipmentItemProps,
} from 'components/modal/ShipmentItemsModal';
import { useInfiniteShipments } from 'data/shipment';
import { useAuth } from 'hooks/auth';
import { downloadDocument } from 'hooks/document';
import { useModalWithData } from 'hooks/useModal';
import ShippingModal from 'pages/contratos/[contractId]/ShippingModal';
import debounce from 'util/debounce';

import { useShippingFilter } from './use-shipping-filter';

const MAP_DESCRIPTION = {
  pending: 'Pendentes',
  sent: 'Concluídas',
} as const;

export default function BreedingPage() {
  const { user } = useAuth();

  const shipmentItemsModal = useModalWithData<ShipmentItemProps[]>();
  const shippingModal = useModalWithData();

  const { filter, getMappedFilter, updateFilter } = useShippingFilter();
  const { control } = useForm({ defaultValues: filter });

  const debouncedFilter = debounce(updateFilter, 600);
  const {
    shipments,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteShipments(getMappedFilter());

  function handleItemsClick(shipmentId: number) {
    const foundShipment = shipments.find(
      (shipment) => shipment.id === shipmentId,
    );

    if (foundShipment) {
      const items = foundShipment.items.map((item) => ({
        id: item.id,
        number: item.number,
        product: item.product.description,
        quantity: item.quantity,
        unit: item.product.unit.abbreviation,
      }));
      shipmentItemsModal.open(items);
    }
  }

  function handleSendClick(shipmentId: number) {
    const shipment = shipments.find((item) => item.id === shipmentId);

    if (shipment) {
      const formValues = {
        address: shipment.address || '',
        shippingDate: shipment.shippingForecast
          ? new Date(shipment.shippingForecast)
          : null,
      };
      shippingModal.open({ shipmentId, formValues });
    }
  }

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Header as="h1">Criações</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <Form>
                <Form.Group>
                  <Form.Field width="3">
                    <Controller
                      control={control}
                      name="status"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Select
                          onChange={(data) => {
                            debouncedFilter({ status: data || '' });
                            onChange(data);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Status"
                          autoComplete="off"
                          isClearable
                          name="status"
                          instanceId="status"
                          options={Object.entries(MAP_DESCRIPTION).map(
                            ([key, description]) => ({
                              value: key,
                              label: description,
                            }),
                          )}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Remessas</Header>
                </Section.Header>
                <Section.Content>
                  {!shipments.length ? (
                    <EmptyState>
                      <EmptyState.Header>Nenhuma remessa</EmptyState.Header>
                    </EmptyState>
                  ) : (
                    <>
                      <Table size="small" basic="very" fixed singleLine>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Cliente</Table.HeaderCell>
                            <Table.HeaderCell>Contrato</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Previsão</Table.HeaderCell>
                            <Table.HeaderCell>Data de envio</Table.HeaderCell>
                            <Table.HeaderCell>Itens</Table.HeaderCell>
                            {user.isAdmin ? (
                              <Table.HeaderCell>NF</Table.HeaderCell>
                            ) : null}
                            {user.isAdmin ? (
                              <Table.HeaderCell textAlign="center" />
                            ) : null}
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {isLoading && <LoadingRow columns={5} rows={5} />}
                          {!isLoading &&
                            shipments?.map(
                              ({
                                contract,
                                customer,
                                id,
                                items,
                                invoiceDocumentId,
                                shippingDate,
                                shippingForecast,
                              }) => {
                                const itemsDescription = `${items.length} ${
                                  items.length === 1 ? 'item' : 'itens'
                                }`;

                                return (
                                  <Table.Row key={id.toString()}>
                                    <Table.Cell>
                                      <Text variant="primary">
                                        {customer.tradingName}
                                      </Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Text variant="primary">
                                        {user.isAdmin ? (
                                          <Link
                                            to={`/contratos/${contract.id}`}
                                          >
                                            {contract.number}
                                          </Link>
                                        ) : (
                                          contract.number
                                        )}
                                      </Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                      {shippingDate ? (
                                        <Tag color="transparent">Concluída</Tag>
                                      ) : (
                                        <Tag color="yellow">Pendente</Tag>
                                      )}
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Text variant="primary">
                                        {shippingForecast
                                          ? DateTime.fromISO(
                                              shippingForecast,
                                            ).toFormat('dd/MM/yyyy')
                                          : 'n/a'}
                                      </Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Text variant="primary">
                                        {shippingDate
                                          ? DateTime.fromISO(
                                              shippingDate,
                                            ).toFormat('dd/MM/yyyy')
                                          : 'n/a'}
                                      </Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                      <LinkButton
                                        onClick={() => handleItemsClick(id)}
                                      >
                                        {itemsDescription}
                                      </LinkButton>
                                    </Table.Cell>
                                    {user.isAdmin ? (
                                      <Table.Cell>
                                        <Text variant="primary">
                                          {invoiceDocumentId ? (
                                            <LinkButton
                                              onClick={() =>
                                                downloadDocument(
                                                  invoiceDocumentId,
                                                )
                                              }
                                            >
                                              Anexo
                                            </LinkButton>
                                          ) : null}
                                        </Text>
                                      </Table.Cell>
                                    ) : null}
                                    {user.isAdmin ? (
                                      <Table.Cell textAlign="center">
                                        <Popup
                                          content="Enviar"
                                          inverted
                                          mouseEnterDelay={500}
                                          trigger={
                                            <Button
                                              size="mini"
                                              onClick={() =>
                                                handleSendClick(id)
                                              }
                                              icon
                                              circular
                                              basic
                                            >
                                              <Icon name="shipping fast" />
                                            </Button>
                                          }
                                        />
                                      </Table.Cell>
                                    ) : null}
                                  </Table.Row>
                                );
                              },
                            )}
                        </Table.Body>
                      </Table>
                      <Divider hidden />
                      {hasNextPage ? (
                        <Grid>
                          <Grid.Row>
                            <Grid.Column textAlign="center">
                              <Button
                                type="button"
                                onClick={() => fetchNextPage()}
                                disabled={!hasNextPage || isFetchingNextPage}
                              >
                                {isFetchingNextPage ? 'Carregando...' : ''}
                                {!isFetchingNextPage && hasNextPage
                                  ? 'Ver mais'
                                  : ''}
                              </Button>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      ) : (
                        <Grid>
                          <Grid.Row>
                            <Grid.Column textAlign="center">
                              <Text variant="secondary">
                                Nada mais para mostrar
                              </Text>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      )}
                    </>
                  )}
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      {shipmentItemsModal.isOpen && shipmentItemsModal?.data ? (
        <ShipmentItemsModal
          isOpen={shipmentItemsModal.isOpen}
          items={shipmentItemsModal?.data}
          onClose={shipmentItemsModal.close}
        />
      ) : null}

      {shippingModal.isOpen && shippingModal.data ? (
        <ShippingModal
          formValues={shippingModal?.data?.formValues}
          isOpen={shippingModal.isOpen}
          onClose={shippingModal.close}
          shipmentId={shippingModal?.data?.shipmentId}
        />
      ) : null}
    </Base>
  );
}
