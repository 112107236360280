import React from 'react';

import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import updateProjectUseCase from 'mutations/updateProject';
import { formatUnit } from 'util/unitFormatter';

import { projectRepository } from '../../container';
import ProjectProtocolDetailsForm from './ProjectProtocolDetailsForm';

export default function EditProtocolDetails() {
  const history = useHistory();
  const { id: projectId } = useParams();

  const [{ data: project, loading: isProjectLoading }, getProject] = useAsync(
    projectRepository.getProject,
  );

  const [
    { data: updated, loading: isUpdating, error: updateError },
    updateProject,
  ] = useAsync(updateProjectUseCase);

  React.useEffect(() => {
    getProject(projectId);
  }, [getProject, projectId]);

  React.useEffect(() => {
    function handleCreateResult() {
      if (updated) {
        toast.success('Protocolo atualizado com sucesso!');
        history.goBack();
      }

      if (updateError) {
        toast.error(updateError);
      }
    }

    handleCreateResult();
  }, [updated, updateError, history]);

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);

    updateProject(projectId, updatedValues);
  }

  return (
    <Base loading={isProjectLoading} text>
      <Container>
        {project && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Edição de materiais e métodos</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <ProjectProtocolDetailsForm
                      projectFormData={mapToForm(project)}
                      isSubmiting={isUpdating}
                      onSubmit={handleSubmit}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </Base>
  );
}

function mapToForm(project) {
  return {
    treatmentsNumber: project.treatmentsNumber || null,
    trialsNumber: project.trialsNumber || null,
    blockUnit: project.blockUnit
      ? {
          value: project.blockUnit,
          label: formatUnit(project.blockUnit),
        }
      : null,
    blockLength: project.blockLength || null,
    blockWidth: project.blockWidth || null,
  };
}

function mapFromForm(values) {
  return {
    treatmentsNumber: values.treatmentsNumber || null,
    trialsNumber: values.trialsNumber || null,
    blockDimension: {
      unit: values.blockUnit ? values.blockUnit.value : null,
      length: values.blockLength || null,
      width: values.blockWidth || null,
    },
  };
}
