import * as React from 'react';

import { createMoney } from '@easymoney/money';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Divider, Form, Input, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import TextInfo from 'components/data/TextInfo';
import DatePicker from 'components/inputs/date-picker';
import DivisionSelect from 'components/inputs/DivisionSelect';
import InputError from 'components/inputs/InputError';
import SaleProductSearch from 'components/inputs/SaleProductSearch';
import { useAddProductItem, useUpdateProductItem } from 'mutations/quotation';
import * as Currency from 'util/Currency';
import { emptyStringToUndefined } from 'util/validation';

const ProductItemFormInput = yup.object().shape({
  division: yup
    .object()
    .required('Obrigatório')
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  quantity: yup
    .number()
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
  saleProduct: yup
    .object()
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
  shippingForecast: yup
    .date()
    .nullable()
    .optional()
    .transform(emptyStringToUndefined),
});

export type ProductItemFormValues = {
  division: {
    value: number;
    label: string;
  };
  quantity: number;
  saleProduct: {
    id: number;
    description: string;
    unitPrice: number;
    measurementUnit: string;
    measurementUnitId: number;
  };
  shippingForecast: Date | null;
  unit: {
    id: number;
    abbreviation: string;
  };
  unitPrice: number;
};

export type ProductItemModalProps = {
  productItemId?: number;
  formValues?: ProductItemFormValues & { productItemId: number };
  isOpen: boolean;
  onClose: () => void;
  quotationId: number;
  onSuccess: () => void;
};

const formId = 'form_product_item';

export default function ProductItemModal({
  productItemId,
  formValues,
  isOpen,
  onClose,
  quotationId,
  onSuccess,
}: ProductItemModalProps) {
  const defaultValues = formValues
    ? { ...formValues }
    : { division: { key: 17, value: 17, label: 'Criações' } };
  const resolver = yupResolver(ProductItemFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit, watch } = useForm(formOptions);
  const watchQuantity = watch('quantity');
  const watchUnitPrice = watch('unitPrice');
  const watchSaleProduct = watch('saleProduct');

  const addProductItem = useAddProductItem({
    onSuccess: () => handleSuccess('Item adicionado!'),
  });

  const updateProductItem = useUpdateProductItem({
    onSuccess: () => handleSuccess('Item salvo!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
    onSuccess();
  }

  function onSubmit(values: ProductItemFormValues) {
    const input = {
      division: values.division.value,
      quantity: values.quantity,
      saleProductId: values.saleProduct.id,
      shippingForecast: values.shippingForecast || null,
      unitPrice: parseInt(
        createMoney({
          amount: Math.round(values.unitPrice * 100),
          currency: Currency.getIsoCode(Currency.Currencies.REAL),
        }).getAmount(),
        10,
      ),
      unit: values.saleProduct.measurementUnitId,
      quotationId,
    };

    if (productItemId) {
      updateProductItem.mutate({ ...input, productItemId });
      return;
    }

    addProductItem.mutate(input);
  }

  const getTotalAmount = React.useCallback(() => {
    const amount = watchUnitPrice ? Math.round(watchUnitPrice * 100) : 0; // watchUnitPrice ? watchUnitPrice * CENTS_MULTIPLIER : 0;
    const currency = Currency.getIsoCode(Currency.Currencies.REAL);
    const unitPrice = createMoney({ amount, currency });

    const quantity = watchQuantity ? parseFloat(watchQuantity) : 1;
    const totalAmount =
      watchQuantity && amount != null
        ? unitPrice.multiply(quantity).getAmount()
        : '0';

    return Currency.formatWithSymbol({
      amount: parseInt(totalAmount, 10),
      currency: Currency.Currencies.REAL,
    });
  }, [watchUnitPrice, watchQuantity]);

  const unitPrice =
    watchUnitPrice != null
      ? Currency.formatWithSymbol({
          amount: Math.round(watchUnitPrice * 100),
          currency: Currency.Currencies.REAL,
        })
      : '';
  const totalAmount = React.useMemo(() => getTotalAmount(), [getTotalAmount]);

  // .toFixed(2)

  const isLoading = addProductItem.isLoading || updateProductItem.isLoading;
  const header = productItemId ? 'Edição de item' : 'Novo item';
  const buttonText = productItemId ? 'Salvar' : 'Adicionar';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Produto</label>
            <Controller
              control={control}
              name="saleProduct"
              render={({ onChange, onBlur, value }) => (
                <SaleProductSearch
                  autoComplete="off"
                  disabled={isLoading}
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.saleProduct && (
              <InputError>{errors?.saleProduct?.message}</InputError>
            )}
          </Form.Field>
          <Divider hidden />
          <Form.Group widths="equal">
            <Form.Field>
              <label>Quantidade</label>
              <Controller
                control={control}
                name="quantity"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.quantity && (
                <InputError>{errors?.quantity?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Valor unitário</label>
              <Controller
                control={control}
                name="unitPrice"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.unitPrice && (
                <InputError>{errors?.unitPrice.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Unidade</label>
              <Input value={watchSaleProduct?.measurementUnit || ''} />
              {errors.saleProduct && (
                <InputError>{errors?.saleProduct.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Divider hidden />
          <Form.Group>
            <Form.Field width={10}>
              <label>Setor</label>
              <Controller
                control={control}
                defaultValue=""
                name="division"
                render={({ onChange, onBlur, value }) => (
                  <DivisionSelect
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Setor"
                    value={value}
                  />
                )}
              />
              {errors.division && (
                <InputError>{errors?.division?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field width={6}>
              <label>Prev. expedição*</label>
              <Controller
                control={control}
                name="shippingForecast"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    dateFormat="dd/MM/yyyy"
                  />
                )}
              />
            </Form.Field>
          </Form.Group>
          <Divider hidden />
          <TextInfo label="Valor total" text={totalAmount} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          form={formId}
          loading={isLoading}
          primary
          type="submit"
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
