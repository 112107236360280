import * as React from 'react';

import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Modal,
  Button,
  Form,
  Input,
  Header,
  Checkbox,
  Message,
  Divider,
  Grid,
} from 'semantic-ui-react';

import TextInfo from 'components/data/TextInfo';
import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useCreatePayment } from 'mutations/payment';
import { formatCurrencyWithSymbol } from 'util/Currency';
import { DOCUMENT_INVOICE } from 'util/Document';

export default function PaymentFormModal({
  currency,
  isOpen,
  onClose,
  installmentsByNumber,
}) {
  const { current: initialState } = React.useRef({ ...installmentsByNumber });
  const [installments, setInstallments] = React.useState(initialState);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalSelected, setTotalSelected] = React.useState(0);
  const [installmentsError, setInstallmentsError] = React.useState(null);
  const formRef = React.useRef(null);

  const invoiceFile = useUpload();
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const { mutate: createPayment, isLoading, error } = useCreatePayment({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Pagamento adicionado!');
    onClose();
  }

  const onSubmit = ({ billingDate, invoiceNumber }) => {
    const installmentIds = Object.entries(installments)
      .map(([, value]) => value)
      .flat(1)
      .filter(({ checked }) => checked === true)
      .map(({ id }) => id);

    if (installmentIds.length === 0) {
      setInstallmentsError(true);
      return;
    }

    const input = {
      amount: totalAmount,
      billingDate,
      invoiceNumber,
      installmentIds,
      invoiceDocumentId: invoiceFile.file?.id || null,
    };
    createPayment(input);
  };

  function handleAccessSketchFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    invoiceFile.handleUpload(file, documentType);
  }

  function handleCheckChange(key, index) {
    const clonedInstallments = { ...installments };

    if (clonedInstallments[key][index]?.checked === undefined) {
      clonedInstallments[key][index].checked = false;
    }
    clonedInstallments[key][index].checked = !clonedInstallments[key][index]
      .checked;

    if (clonedInstallments[key][index].checked) {
      handleCheck(clonedInstallments[key][index].amount);
    } else {
      handleUncheck(clonedInstallments[key][index].amount);
    }

    setInstallments(clonedInstallments);
  }

  function handleCheck(amount) {
    incrementTotalSelected();
    incrementTotalAmount(amount);
  }

  function incrementTotalSelected() {
    setTotalSelected((oldValue) => oldValue + 1);
  }

  function incrementTotalAmount(amount) {
    setTotalAmount((oldValue) => oldValue + amount);
  }

  function handleUncheck(amount) {
    decrementTotalSelected();
    decrementTotalAmount(amount);
  }

  function decrementTotalSelected() {
    setTotalSelected((oldValue) => oldValue - 1);
  }

  function decrementTotalAmount(amount) {
    setTotalAmount((oldValue) => oldValue - amount);
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Adicionar pagamento</Modal.Header>
      <Modal.Content scrolling>
        <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Header as="h3">Nota Fiscal</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Número</label>
              <Controller
                control={control}
                name="invoiceNumber"
                rules={{ required: true }}
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="new-password"
                    fluid
                  />
                )}
              />
              {errors.invoiceNumber && (
                <InputError>Número da nota é obrigatório</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Data de faturamento</label>
              <Controller
                control={control}
                name="billingDate"
                defaultValue=""
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    disabled={isLoading}
                  />
                )}
              />
              {errors.billingDate && (
                <InputError>Data de faturamento é obrigatório</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Anexo (opcional)</label>
            <UploadInput
              name="invoiceDocumentId"
              uploadedFile={invoiceFile?.file}
              onUpload={(e) =>
                handleAccessSketchFileChange(e, DOCUMENT_INVOICE)
              }
              onDelete={invoiceFile.handleDelete}
            />
          </Form.Field>
          <Divider hidden />
          <Header as="h3">Parcelas</Header>
          {Object.entries(installments).map(([key, installmentNumber]) => (
            <div>
              <Header as="h4">Parcela {key}</Header>
              {installmentNumber.map((selectedInstallment, index) => {
                const item = installments[key][index];
                return (
                  <Form.Field
                    key={`${selectedInstallment.id} ${index.toString()}`}
                  >
                    <Checkbox
                      onChange={() => handleCheckChange(key, index)}
                      checked={item?.checked || item?.isBilled}
                      label={`${formatCurrencyWithSymbol(
                        item?.amount,
                        currency,
                      )} - ${item?.project} - ${item?.protocol}${
                        item?.isCanceled ? ' CANCELADO' : ''
                      }`}
                      disabled={item?.isBilled || item?.isCanceled}
                      autoComplete="off"
                    />
                  </Form.Field>
                );
              })}
              <Divider hidden />
            </div>
          ))}
          <Divider hidden />
          {installmentsError && (
            <InputError>
              É necessário selecionar ao menos uma parcela
            </InputError>
          )}
        </Form>
        {error && (
          <Message content="Não foi possível adicionar o pagamento!" negative />
        )}
      </Modal.Content>
      <Modal.Content>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <TextInfo
                label="Total selecionado"
                text={formatCurrencyWithSymbol(totalAmount, currency)}
              />
            </Grid.Column>
            <Grid.Column>
              <TextInfo label="Parcelas selecionadas" text={totalSelected} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form="form" loading={isLoading} primary>
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
