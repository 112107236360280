import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllIngredientsUseCase from 'queries/getAllIngredients';

export default function IngredientSearch({
  name = undefined,
  placeholder = 'Busque pelo nome...',
  value = undefined,
  onChange = undefined,
  onBlur,
  autoComplete,
  disabled = false,
  isClearable = false,
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const [{ data, loading, error }, getAllIngredients] = useAsync(
    getAllIngredientsUseCase,
  );

  React.useEffect(() => {
    getAllIngredients({ name: searchValue });
  }, [getAllIngredients, searchValue]);

  const ingredients =
    error || !data || !data.ingredients ? [] : data.ingredients;

  const options = ingredients?.map((property) => ({
    value: property.id,
    label: property.name,
  }));

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      isLoading={loading}
      value={value}
      inputValue={searchValue}
      onInputChange={handleInputChange}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      autoComplete={autoComplete}
      isDisabled={disabled}
      isClearable={isClearable}
    />
  );
}
