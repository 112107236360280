import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  Button,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  TextArea,
} from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';

import { schema } from './validation';

export default function ProjectProtocolForm({
  projectFormData,
  isSubmiting = false,
  onSubmit,
}) {
  const isEditing = !!projectFormData;

  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  const defaultValues = projectFormData || { references: [{}] };
  const resolver = yupResolver(schema);

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'references',
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Título</label>
        <Controller
          control={control}
          name="title"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              style={{ minHeight: 150 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
            />
          )}
        />
        {errors.title && <InputError>{errors?.title?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Introdução</label>
        <Controller
          control={control}
          name="intro"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              style={{ minHeight: 150 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
            />
          )}
        />
        {errors.intro && <InputError>{errors?.intro?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Objetivo da pesquisa</label>
        <Controller
          control={control}
          name="objective"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              style={{ minHeight: 150 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
            />
          )}
        />
        {errors.objective && (
          <InputError>{errors?.objective?.message}</InputError>
        )}
      </Form.Field>
      <Form.Field>
        <label>Metodologia de aplicação e avaliação</label>
        <Controller
          control={control}
          name="applicationMethodology"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <TextArea
              style={{ minHeight: 150 }}
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
            />
          )}
        />
        {errors.applicationMethodology && (
          <InputError>{errors?.applicationMethodology?.message}</InputError>
        )}
      </Form.Field>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Equipamento</label>
          <Controller
            control={control}
            name="equipment"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>Barra (No. bicos)</label>
          <Controller
            control={control}
            name="equipmentBar"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>Volume da calda</label>
          <Controller
            control={control}
            name="equipmentVolume"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>Modelo do bico</label>
          <Controller
            control={control}
            name="nozzlesModel"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Header as="h3">Referências</Header>
      {fields.map((item, index) => {
        return (
          <Form.Group key={item.id}>
            <Form.Field width={15}>
              <Controller
                control={control}
                name={`references[${index}].description`}
                defaultValue={item.description}
                render={({ onChange, onBlur, value }) => (
                  <TextArea
                    onChange={onChange}
                    onBlur={onBlur}
                    rows={2}
                    value={value}
                    disabled={false}
                    autoComplete="off"
                  />
                )}
              />
              {errors.references && (
                <InputError>
                  {errors?.references[index]?.description?.message}
                </InputError>
              )}
            </Form.Field>
            <Form.Field width={1}>
              <Button
                type="button"
                size="small"
                onClick={() => remove(index)}
                icon
                basic
              >
                <Icon name="trash" />
              </Button>
            </Form.Field>
          </Form.Group>
        );
      })}
      <Form.Group>
        <Form.Field>
          <Button type="button" onClick={() => append({})} basic>
            <Icon name="add" />
            Adicionar referência
          </Button>
        </Form.Field>
      </Form.Group>
      <Divider hidden />
      <Button
        type="submit"
        primary
        size="large"
        style={{ marginTop: 20 }}
        loading={isSubmiting}
      >
        {submitButtonText}
      </Button>
    </Form>
  );
}
