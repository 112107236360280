/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';

import Select from 'react-select';
import styled, { css } from 'styled-components';

import { projectRepository } from 'container';
import useAsync from 'hooks/useAsync';

export default function ProjectSearch({
  name = undefined,
  placeholder = 'Busque pelo código...',
  value,
  onChange,
  onBlur = undefined,
  disabled = false,
  isMulti = false,
  isClearable = false,
  autoComplete = undefined,
}) {
  const [projectSearchValue, setProjectSearchValue] = useState('');

  const [{ data, loading, error }, getProjectsDetailed] = useAsync(
    projectRepository.getProjectsDetailed,
  );

  useEffect(() => {
    getProjectsDetailed({ insideCode: projectSearchValue });
  }, [getProjectsDetailed, projectSearchValue]);

  const options = error || !data || !data.data ? [] : data.data;

  const handleProjectInputChange = (inputValue) => {
    setProjectSearchValue(inputValue);
  };

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      name={name}
      components={{ Option: CustomOption }}
      placeholder={placeholder}
      instanceId={name}
      value={value}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => `${option.insideCode || 'Sem código'}`}
      defaultValue={defaultValue}
      isLoading={loading}
      inputValue={projectSearchValue}
      onInputChange={handleProjectInputChange}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      isMulti={isMulti}
      isClearable={isClearable}
      isDisabled={disabled}
      autoComplete={autoComplete}
    />
  );
}

function CustomOption({ innerProps, isDisabled, data, ...props }) {
  return !isDisabled ? (
    <Wrapper {...innerProps} {...props}>
      <PrimaryText>{data.insideCode}</PrimaryText>
      <SecondaryText {...props}>{data.customer.tradingName}</SecondaryText>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  ${({ isFocused, isSelected }) => css`
    padding: 1rem 1.4rem;
    background-color: ${isFocused ? '#deebff' : ''};
    background-color: ${isSelected ? '#2684FF' : ''};
    color: ${isSelected ? '#ffffff' : ''};
  `}
`;

const PrimaryText = styled.div`
  ${() => css`
    margin-bottom: 2px;
  `}
`;

const SecondaryText = styled.div`
  ${({ isSelected }) => css`
    font-size: 12px;
    color: ${isSelected ? '#ffffff' : '#767676'};
  `}
`;
