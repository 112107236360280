import React from 'react';

import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useParams, useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

import QuotationProjectForm from 'components/forms/QuotationProjectForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import createQuotationProject from 'mutations/createQuotationProject';

export default function AddQuotationProject() {
  const { quotationId } = useParams();
  const history = useHistory();

  const { mutate: mutateCreateQuotationProject, isLoading } = useMutation(
    (props) => createQuotationProject(props.params),
    {
      onSuccess,
    },
  );

  function onSuccess() {
    toast.success('Estudo adicionado com sucesso!');
    history.replace(`/orcamentos/${quotationId}`);
  }

  async function handleSubmit(values) {
    const params = {
      description: values.description || null,
      protocol: values.protocol,
      productIds: values.products ? values.products.map((p) => p.value) : [],
      cropIds: values.crops ? values.crops.map((c) => c.value) : [],
      targetIds: values.targets ? values.targets.map((t) => t.value) : [],
      division: values.division.value,
      projectGoal: parseInt(values.projectGoal, 10),
      managementType: parseInt(values.managementType, 10),
      testLocation: parseInt(values.testLocation, 10),
      durationForecast: values.durationForecast,
      amount:
        values.amount !== null && values.amount !== undefined
          ? Math.round(values.amount * 100)
          : 0,
      treatmentQuantity: parseInt(values.treatmentQuantity, 10),
      trialsNumber: values.trialsNumber
        ? parseInt(values.trialsNumber, 10)
        : null,
      projectsNumber: values.projectsNumber,
      quotationId,
    };

    mutateCreateQuotationProject({ params });
  }

  return (
    <Base text>
      <Container>
        <Header as="h1">Novo item</Header>
        <Section>
          <Section.Content>
            <QuotationProjectForm
              onSubmit={handleSubmit}
              isSubmiting={isLoading}
            />
          </Section.Content>
        </Section>
      </Container>
    </Base>
  );
}
