import React from 'react';

import Select from 'react-select';

const divisionOptions = [
  { key: 1, value: 1, label: 'Bioensaio' },
  { key: 2, value: 2, label: 'Biotec' },
  { key: 3, value: 3, label: 'Coleta' },
  { key: 17, value: 17, label: 'Criações' },
  { key: 15, value: 15, label: 'Fertilizante' },
  { key: 7, value: 7, label: 'Fungicida' },
  { key: 8, value: 8, label: 'Herbicida' },
  { key: 9, value: 9, label: 'Inseticida' },
  { key: 16, value: 16, label: 'MIP Experience' },
  { key: 12, value: 12, label: 'Nematologia' },
  { key: 13, value: 13, label: 'Projeto especial' },
  { key: 14, value: 14, label: 'Resíduo' },
];

export const allDivisionValues = divisionOptions.map(({ value }) => value);

export default function DivisionSelect({
  disabled = false,
  divisions = [],
  isClearable = false,
  isMulti = false,
  name,
  onBlur,
  onChange,
  placeholder = 'Selecione...',
  styles = undefined,
  value,
}) {
  const options = divisions.length
    ? divisionOptions.filter((division) => divisions.includes(division.value))
    : divisionOptions;

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      defaultValue={defaultValue}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      styles={styles}
      value={value}
    />
  );
}
