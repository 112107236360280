import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import api from 'util/api';

export type SampleRequest = {
  code: string;
  productId: string;
  arrivalDate: string;
  packingQuantity: number;
  measurementUnit: number;
  volume: number;
  checkInDocumentId?: number | null;
  custodyChainDocumentId?: number | null;
  manufacturingDate?: Date | null;
  hasManufacturingDay?: boolean;
  expirationDate?: Date | null;
  hasExpirationDay?: boolean;
  associatedProjects?: {
    projectId: number;
    sampleGoal: number;
  } | null;
};

type ExportResponse = {
  file: Blob;
  filename: string;
};

const createSample = async (input: SampleRequest) => {
  const { headers } = await api.post('samples', input);
  const { location } = headers;
  const sampleId = parseInt(location.substring(12), 10);

  return sampleId;
};

function useCreateSample(
  options?: UseMutationOptions<number, AxiosError, SampleRequest, () => void>,
) {
  return useMutation(createSample, options);
}

type UpdateSampleInput = {
  sampleId: number;
};

export function useUpdateSample(
  options?: UseMutationOptions<void, AxiosError, UpdateSampleInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateSample, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('sample');
      queryClient.invalidateQueries('samples');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateSample(input: UpdateSampleInput) {
  const { sampleId, ...updatedInput } = input;
  await api.patch(`/samples/${sampleId}`, updatedInput);
}

type UpdateSampleDocumentsInput = {
  sampleId: number;
  checkInDocumentId: number | null;
  custodyChainDocumentId: number | null;
  invoiceDocumentId: number | null;
};

export function useUpdateSampleDocuments(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateSampleDocumentsInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(updateSampleDocuments, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('sample');
      queryClient.invalidateQueries('sampleFiles');
      queryClient.invalidateQueries('samples');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateSampleDocuments(input: UpdateSampleDocumentsInput) {
  const { sampleId, ...updatedInput } = input;
  await api.patch(`/samples/${sampleId}/documents`, updatedInput);
}

type AssociateSampleInput = {
  sampleId: number;
  projectId: number;
  sampleGoal: number;
};

export function useAssociateSample(
  options?: UseMutationOptions<
    void,
    AxiosError,
    AssociateSampleInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(associateSample, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('samples');
      queryClient.invalidateQueries('sample');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function associateSample({
  sampleId,
  projectId,
  sampleGoal,
}: AssociateSampleInput) {
  await api.post(`samples/${sampleId}/projects/${projectId}`, { sampleGoal });
}

type DissociateSampleInput = {
  sampleId: number;
  projectId: number;
};

export function useDissociateSample(
  options?: UseMutationOptions<
    void,
    AxiosError,
    DissociateSampleInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(dissociateSample, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('samples');
      queryClient.invalidateQueries('sample');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function dissociateSample({
  sampleId,
  projectId,
}: DissociateSampleInput) {
  await api.delete(`samples/${sampleId}/projects/${projectId}`);
}

function useGenerateCustodyChain(
  options?: UseMutationOptions<ExportResponse, AxiosError, number, () => void>,
) {
  return useMutation(generateCustodyChain, { ...options });
}

const generateCustodyChain = async (sampleId: number) => {
  const { data: file, headers } = await api.post(
    `/samples/${sampleId}/custody-chain`,
    {},
    {
      responseType: 'blob',
    },
  );

  const { filename } = headers;

  return { file, filename };
};

function useExportSamples(
  options?: UseMutationOptions<ExportResponse, AxiosError, void, () => void>,
) {
  return useMutation(exportSamples, { ...options });
}

const exportSamples = async () => {
  const { data: file, headers } = await api.get(`/samples/report`, {
    responseType: 'blob',
  });

  const { filename } = headers;

  return { file, filename };
};

type ManipulateSampleInput = {
  sampleId: number;
  packagingId: number;
};

type UseManipulateOptions = UseMutationOptions<
  void,
  AxiosError,
  ManipulateSampleInput,
  () => void
>;

function useManipulateSample(options?: UseManipulateOptions) {
  const queryClient = useQueryClient();

  return useMutation(manipulateSample, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('sample');
      queryClient.invalidateQueries('samples');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function manipulateSample(input: ManipulateSampleInput) {
  const uri = `samples/${input.sampleId}/packagings/${input.packagingId}/manipulations`;
  await api.post(uri, input);
}

export {
  useCreateSample,
  useGenerateCustodyChain,
  useExportSamples,
  useManipulateSample,
};

type PackagingInput = {
  volume: number;
  sampleId: number;
};

const addPackaging = async ({ sampleId, ...input }: PackagingInput) => {
  await api.post(`/samples/${sampleId}/packagings`, input);
};

export function useAddPackaging(
  options?: UseMutationOptions<void, AxiosError, PackagingInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(addPackaging, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('samples');
      queryClient.invalidateQueries('sample');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

type DiscardSamplesInput = {
  date: Date;
  destination: string;
  discardResponsibleId: number;
  documentId: number;
  notes?: string | null;
  sampleIds: number[];
};

export function useDiscardSamples(
  options?: UseMutationOptions<number, AxiosError, DiscardSamplesInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(discardSamples, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('sample');
      queryClient.invalidateQueries('samples');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function discardSamples(input: DiscardSamplesInput) {
  const { headers } = await api.post(`/samples/discards`, input);
  const { location } = headers;
  const sampleDiscardId = parseInt(location.split('/').pop(), 10);
  return sampleDiscardId;
}

type ReturnSamplesInput = {
  date: Date;
  documentId: number;
  returnResponsibleId: number;
  receivingResponsibleId: number;
  notes?: string | null;
  sampleIds: number[];
};

export function useReturnSamples(
  options?: UseMutationOptions<number, AxiosError, ReturnSamplesInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(returnSamples, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('sample');
      queryClient.invalidateQueries('samples');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function returnSamples(input: ReturnSamplesInput) {
  const { headers } = await api.post(`/samples/returns`, input);
  const { location } = headers;
  const sampleReturnId = parseInt(location.split('/').pop(), 10);
  return sampleReturnId;
}
