import React from 'react';

import toast from 'react-hot-toast';
import { useParams, useHistory } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import EmployeeForm from 'components/forms/EmployeeForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useQueryEmployee, useUpdateEmployee } from 'hooks/employee';

export default function EditEmployee() {
  const { employeeId } = useParams();
  const history = useHistory();

  const { data: employee, isLoading } = useQueryEmployee(employeeId);

  const { mutate: updateEmployee, isLoading: isUpdating } = useUpdateEmployee({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Colaborador atualizado com sucesso!');
    history.replace(`/configuracoes/colaboradores`);
  }

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);

    const updatedEmployee = {
      id: parseInt(employeeId, 10),
      ...updatedValues,
    };

    updateEmployee(updatedEmployee);
  }

  return (
    <Base loading={isLoading} text>
      <Container>
        {employee ? (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Edição de colaborador</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <EmployeeForm
                      formValues={mapToForm(employee)}
                      onSubmit={handleSubmit}
                      isSubmiting={isUpdating}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
      </Container>
    </Base>
  );
}

function mapToForm(employeeValues) {
  return {
    firstName: employeeValues?.firstName,
    lastName: employeeValues?.lastName,
    creaSp: employeeValues?.creaSp || undefined,
    jobPosition: employeeValues?.jobPosition?.toString(),
  };
}

function mapFromForm(values) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    creaSp: values.creaSp,
    jobPosition: parseInt(values.jobPosition, 10),
  };
}
