import React from 'react';

import { Table, Button, Icon } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import { isCommonTreatment, formatTreatmentType } from 'queries/treatment';

type Treatment = {
  id: number;
  number: number;
  projectId: number;
  notes: string | null;
  treatmentType: number;
  sampleDoses: {
    quantity: number;
    measurementUnit: { abbreviation: string } | null;
    sample: {
      id: number;
      code: string;
      product: {
        id: number;
        name: string;
      };
    } | null;
    sampleCode: string;
    sampleId: number;
    treatmentId: number;
    ingredientDoses: {
      quantity: number;
      unit: number | null; // verficiar e remover
      measurementUnit: { abbreviation: string } | null;
    }[];
  }[];
  trials: {
    block: string;
    createdAt: string;
    id: number;
    installationId: number | null;
    plot: number;
    projectId: number;
    treatmentId: number;
    updatedAt: string;
  }[];
};

type TreatmentTableProps = {
  isLoading?: boolean;
  onAddClick: () => void;
  onEditClick: (treatment: Treatment) => void;
  onDeleteClick: (treatment: Treatment) => void;
  treatments: Treatment[];
};

export default function TreatmentTable({
  isLoading = false,
  onAddClick,
  onEditClick,
  onDeleteClick,
  treatments = [],
}: TreatmentTableProps) {
  const isEmpty = treatments.length === 0;

  if (isEmpty) {
    return (
      <EmptyState>
        <EmptyState.Header>Nenhum tratamento adicionado</EmptyState.Header>
        <EmptyState.Description>
          Clique no botão abaixo para adicionar
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button type="button" onClick={onAddClick} primary basic>
            <Icon name="add" />
            Adicionar
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    );
  }

  return (
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={1}>No</Table.HeaderCell>
          <Table.HeaderCell>Produto</Table.HeaderCell>
          <Table.HeaderCell>Dose</Table.HeaderCell>
          <Table.HeaderCell>i.a.</Table.HeaderCell>
          <Table.HeaderCell>Observações</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isLoading && <LoadingRow columns={12} rows={1} />}
        {!isLoading &&
          treatments.map((treatment, index) => {
            const key = index.toString();

            return (
              <Table.Row key={key}>
                <Table.Cell>{treatment.number || index + 1}</Table.Cell>
                <Table.Cell>
                  {isCommonTreatment(treatment.treatmentType)
                    ? treatment.sampleDoses
                        .map((sampleDose) => sampleDose.sample?.product?.name)
                        .join(' + ')
                    : formatTreatmentType(treatment.treatmentType)}
                </Table.Cell>
                <Table.Cell>
                  {isCommonTreatment(treatment.treatmentType)
                    ? treatment.sampleDoses
                        .map(
                          (sampleDose) =>
                            `${sampleDose.quantity.toLocaleString(
                              'pt-BR',
                            )} ${sampleDose?.measurementUnit?.abbreviation ||
                              ''}`,
                        )
                        .join(' + ')
                    : '-'}
                </Table.Cell>
                <Table.Cell>
                  {isCommonTreatment(treatment.treatmentType)
                    ? treatment?.sampleDoses
                        ?.map((sampleDose) => {
                          return sampleDose.ingredientDoses?.map(
                            (ingredientDose) =>
                              ingredientDose?.quantity || ingredientDose?.unit
                                ? `${ingredientDose?.quantity?.toLocaleString(
                                    'pt-BR',
                                  )} ${ingredientDose?.measurementUnit
                                    ?.abbreviation || ''}`
                                : 'n/a',
                          );
                        })
                        .flat(1)
                        .join(' + ')
                    : '-'}
                </Table.Cell>
                <Table.Cell>{treatment.notes || '-'}</Table.Cell>
                <Table.Cell textAlign="center">
                  <Button
                    size="mini"
                    onClick={() => onEditClick(treatment)}
                    basic
                    icon
                  >
                    <Icon name="edit" />
                  </Button>
                  <Button
                    size="mini"
                    onClick={() => onDeleteClick(treatment)}
                    basic
                    icon
                  >
                    <Icon name="trash" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}
