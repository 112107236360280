import React, { forwardRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Form, Header, Input, Button, Icon } from 'semantic-ui-react';

import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import PhoneInput from 'components/inputs/PhoneInput';

import { CustomerRepresentative } from './validations';

export type CustomerRepresentativesFormValues = {
  representatives: {
    name: string;
    email: string;
    phone: string;
  }[];
};

type CustomerRepresentativesFormProps = {
  defaultValues: Partial<CustomerRepresentativesFormValues>;
  onSubmit: (values: CustomerRepresentativesFormValues) => void;
};

const CustomerRepresentativesForm = forwardRef<
  HTMLFormElement,
  CustomerRepresentativesFormProps
>(({ defaultValues, onSubmit }, ref) => {
  const resolver = yupResolver(CustomerRepresentative);
  const { control, errors, handleSubmit, watch } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'representatives',
  });
  const watchRepresentatives = watch('representatives');

  return (
    <Form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Header as="h3">Representantes</Header>
      {fields.map((item, index) => {
        const isFirstItem = index === 0;

        const disabledRemoveButton =
          isFirstItem && watchRepresentatives.length === 1;

        return (
          <Form.Group key={item.id}>
            <Form.Field width={6}>
              {isFirstItem ? <label>Nome completo</label> : null}
              <Controller
                control={control}
                name={`representatives[${index}].name`}
                defaultValue={item.name}
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    autoComplete="new-password"
                    fluid
                  />
                )}
              />
              {errors.representatives && (
                <InputError>
                  {errors?.representatives[index]?.name?.message}
                </InputError>
              )}
            </Form.Field>
            <Form.Field width={6}>
              {isFirstItem ? <label>E-mail</label> : null}
              <Controller
                control={control}
                name={`representatives[${index}].email`}
                defaultValue={item.email}
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    autoComplete="new-password"
                    fluid
                  />
                )}
              />
              {errors.representatives && (
                <InputError>
                  {errors?.representatives[index]?.email?.message}
                </InputError>
              )}
            </Form.Field>
            <Form.Field width={4}>
              {isFirstItem ? <label>Telefone</label> : null}
              <Controller
                control={control}
                name={`representatives[${index}].phone`}
                defaultValue={item.phone}
                render={({ onChange, onBlur, value }) => (
                  <PhoneInput
                    onValueChange={(data) => onChange(data.value)}
                    onBlur={onBlur}
                    value={value}
                    autoComplete="new-password"
                  />
                )}
              />
              {errors.representatives && (
                <InputError>
                  {errors?.representatives[index]?.phone?.message}
                </InputError>
              )}
            </Form.Field>
            <Form.Field width={1}>
              {isFirstItem ? <HiddenLabel /> : null}
              <Button
                type="button"
                size="small"
                onClick={() => remove(index)}
                disabled={disabledRemoveButton}
                icon
                basic
              >
                <Icon name="trash" />
              </Button>
            </Form.Field>
          </Form.Group>
        );
      })}
      {errors.representatives && (
        <InputError>{errors?.representatives?.message}</InputError>
      )}
      <Form.Field width={16}>
        <Button type="button" onClick={() => append({})} primary basic fluid>
          <Icon name="add" />
          Novo
        </Button>
      </Form.Field>
    </Form>
  );
});

export default CustomerRepresentativesForm;
