import React from 'react';

import { Button, Divider, Grid, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { FederalReportQuery } from 'data/federal-report';
import { Pagination } from 'util/api';

type FederalReportTableProps = {
  federalReports: FederalReportQuery[];
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onDeleteClick: (documentId: number) => void;
  onDownloadClick: (documentId: number) => void;
  pagination: Pagination;
};

export default function FederalReportTable({
  federalReports = [],
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  onDeleteClick,
  onDownloadClick,
  pagination,
}: FederalReportTableProps) {
  const isEmpty = !isLoading && !federalReports.length;
  return (
    <Section>
      <Section.Header>
        <PaginationInfo
          description="Informes do MAPA"
          pagination={pagination}
        />
      </Section.Header>
      <Section.Content>
        {!isEmpty ? (
          <>
            <Table size="small" basic="very" singleLine unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Ano</Table.HeaderCell>
                  <Table.HeaderCell>Mês</Table.HeaderCell>
                  <Table.HeaderCell>Estação</Table.HeaderCell>
                  <Table.HeaderCell>Observações</Table.HeaderCell>
                  <Table.HeaderCell>Anexo</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? <LoadingRow columns={5} rows={5} /> : null}
                {!isLoading &&
                  federalReports.map(
                    ({
                      id,
                      year,
                      monthDescription,
                      location,
                      notes,
                      documentId,
                    }) => {
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>{year}</Table.Cell>
                          <Table.Cell>{monthDescription}</Table.Cell>
                          <Table.Cell>
                            <Tag color="grey">{location}</Tag>
                          </Table.Cell>
                          <Table.Cell>{notes}</Table.Cell>
                          <Table.Cell>
                            <LinkButton
                              onClick={() => onDownloadClick(documentId)}
                            >
                              Baixar
                            </LinkButton>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <LinkButton onClick={() => onDeleteClick(id)}>
                              Excluir
                            </LinkButton>
                          </Table.Cell>
                        </Table.Row>
                      );
                    },
                  )}
              </Table.Body>
            </Table>
            <Divider hidden />
            {hasNextPage ? (
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Button
                      type="button"
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isFetchingNextPage ? 'Carregando...' : ''}
                      {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : (
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Text variant="secondary">Nada mais para mostrar</Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </>
        ) : (
          <EmptyState>
            <EmptyState.Header>Nenhum informe encontrado</EmptyState.Header>
          </EmptyState>
        )}
      </Section.Content>
    </Section>
  );
}
