import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import EmployeeSearch from 'components/inputs/EmployeeSearch';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import SampleSearch from 'components/inputs/SampleSearch';
import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useDiscardSamples } from 'mutations/sample';
import { DOCUMENT_SAMPLES_RETURN_TERM } from 'util/Document';
import { emptyStringToUndefined } from 'util/validation';

const SamplesDiscardFormInput = yup.object().shape({
  date: yup
    .date()
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
  destination: yup
    .string()
    .required('Obrigatório')
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  discardResponsible: yup
    .object()
    .required('Obrigatório')
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  notes: yup
    .string()
    .nullable()
    .optional()
    .transform(emptyStringToUndefined),
  samples: yup
    .array()
    .of(
      yup.object({
        sample: yup
          .object()
          .required('Obrigatório')
          .typeError('Obrigatório'),
      }),
    )
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
});

type FileEvent = React.ChangeEvent<HTMLInputElement>;

type SamplesDiscardFormValues = {
  date: Date;
  destination: string;
  discardResponsible: {
    value: number;
    label: string;
  };
  notes?: string | null;
  samples: {
    sample: {
      id: number;
      code: string;
      product: {
        id: number;
        name: string;
      };
    };
  }[];
};

export type SamplesDiscardModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const formId = 'form_sample_discard';

const MESSAGE = {
  successDiscard: 'Amostras descartadas!',
} as const;

export default function SamplesDiscardModal({
  isOpen,
  onClose,
}: SamplesDiscardModalProps) {
  const defaultValues = { samples: [{}] };
  const resolver = yupResolver(SamplesDiscardFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit, watch } = useForm(formOptions);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'samples',
  });
  const watchSamples = watch('samples');

  const documentFile = useUpload();

  const discardSamples = useDiscardSamples({
    onSuccess: () => {
      handleSuccess(MESSAGE.successDiscard);
    },
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }

  function onSubmit(values: SamplesDiscardFormValues) {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }

    const input = {
      date: values.date,
      destination: values.destination,
      discardResponsibleId: values.discardResponsible.value,
      documentId: documentFile.file?.id,
      notes: values.notes || null,
      sampleIds: values.samples.map(({ sample }) => sample.id),
    };

    discardSamples.mutate(input);
  }

  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Descarte de amotras</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Descarte</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field width={6}>
                  <label>Data do descarte</label>
                  <Controller
                    control={control}
                    name="date"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  {errors.date && (
                    <InputError>{errors?.date?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Destino</label>
                  <Controller
                    control={control}
                    name="destination"
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={discardSamples.isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.destination && (
                    <InputError>{errors?.destination?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Responsável pelo descarte</label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="discardResponsible"
                    render={({ onChange, onBlur, value }) => (
                      <EmployeeSearch
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Busque pelo nome"
                        value={value}
                      />
                    )}
                  />
                  {errors.discardResponsible && (
                    <InputError>
                      {errors?.discardResponsible?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Observações (opcional)</label>
                  <Controller
                    control={control}
                    name="notes"
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={discardSamples.isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Anexo</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field>
                  <label>Termo de descarte de amostras</label>
                  <UploadInput
                    name="document"
                    uploadedFile={documentFile?.file}
                    onUpload={(e: FileEvent) =>
                      handleDocumentFileChange(e, DOCUMENT_SAMPLES_RETURN_TERM)
                    }
                    onDelete={documentFile.handleDelete}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Amostras</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                {fields.map((item, index) => {
                  const isFirstItem = index === 0;
                  const disabledRemoveButton =
                    isFirstItem && watchSamples.length === 1;

                  return (
                    <Form.Group key={item.id}>
                      <Form.Field width={12}>
                        {isFirstItem ? <label>Amostra</label> : null}
                        <Controller
                          control={control}
                          name={`samples[${index}].sample`}
                          defaultValue={item.sample}
                          render={({ onChange, onBlur, value }) => (
                            <SampleSearch
                              autoComplete="off"
                              isClearable
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Busque pelo código..."
                              value={value}
                              disabled={discardSamples.isLoading}
                            />
                          )}
                        />
                        {errors.samples && (
                          <InputError>
                            {errors?.samples[index]?.sample?.message}
                          </InputError>
                        )}
                      </Form.Field>
                      <Form.Field width={1}>
                        {isFirstItem ? <HiddenLabel /> : null}
                        <Button
                          type="button"
                          size="small"
                          onClick={() => remove(index)}
                          disabled={disabledRemoveButton}
                          icon
                          basic
                        >
                          <Icon name="trash" />
                        </Button>
                      </Form.Field>
                    </Form.Group>
                  );
                })}
                {errors.samples && (
                  <InputError>{errors?.samples?.message}</InputError>
                )}
                <Form.Field width={11}>
                  <Button basic fluid onClick={() => append({})} type="button">
                    <Icon name="add" />
                    Adicionar uma amostra
                  </Button>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={discardSamples.isLoading}
          form={formId}
          loading={discardSamples.isLoading}
          primary
          type="submit"
        >
          Descartar amostras
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
