export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
}

export function emptyStringToUndefined(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
}
