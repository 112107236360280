import { productRepository } from '../container';

export default async function getAllProductCategories(filter) {
  const {
    data: productCategories,
    pagination,
  } = await productRepository.getAllProductCategories(filter);

  return {
    productCategories,
    pagination,
  };
}
