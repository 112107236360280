import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Dimmer, Form, Input, Loader, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import ActivityTypeSelect from 'components/inputs/ActivityTypeSelect';
import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useUpdateActivity } from 'mutations/project';
import { useActivity } from 'queries/activity';
import { emptyStringToNull, emptyStringToUndefined } from 'util/validation';

type ActivityModalProps = {
  activityId: number;
  onClose: () => void;
  isOpen: boolean;
};

export default function ActivityModal({
  activityId,
  onClose,
  isOpen,
}: ActivityModalProps) {
  const [showForm, setShowForm] = React.useState(false);

  const { data: activity } = useActivity(activityId, {
    onSuccess: () => setShowForm(true),
  });

  const { mutate: updateActivity, isLoading: isSubmitting } = useUpdateActivity(
    {
      onSuccess: () => {
        handleSuccess('Atividade atualizada!');
      },
    },
  );

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ActivityFormValues) {
    const input = {
      date: values.date,
      type: values.type.value,
      description: values.description || null,
      executionDate: values.executionDate || null,
      bbchScale: values.bbchScale || null,
      activityId,
    };
    updateActivity(input);
  }

  const header = 'Editar atividade';

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {!showForm || !activity ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <ActivityForm
            formValues={{
              date: DateTime.fromISO(activity.date).toJSDate(),
              type: {
                value: activity.activityTypeId,
                label: activity.type,
              },
              description: activity.description,
              executionDate: activity.executionDate
                ? DateTime.fromISO(activity.executionDate).toJSDate()
                : undefined,
              bbchScale: activity.bbchScale,
              isPerformed: !!activity.executionDate,
            }}
            isLoading={isSubmitting}
            onSubmit={onSubmit}
          />
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitting}
          form={FORM_ID_ACTIVITY}
          loading={isSubmitting}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const NotPerformedActivityInput = yup
  .object({
    date: yup
      .date()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    type: yup
      .object()
      .required('Obrigatório')
      .transform(emptyStringToNull),
    description: yup
      .string()
      .optional()
      .nullable()
      .transform(emptyStringToNull),
    bbchScale: yup
      .string()
      .optional()
      .nullable()
      .transform(emptyStringToNull),
  })
  .required(`Atividades é obrigatório`);

const PerformedActivityInput = NotPerformedActivityInput.shape({
  executionDate: yup
    .date()
    .required('Obrigatório para atividade já executada')
    .typeError('Obrigatório para atividade já executada')
    .transform(emptyStringToUndefined),
}).required(`Atividades é obrigatório`);

export const FORM_ID_ACTIVITY = 'activity_form';

export type ActivityFormValues = {
  date: Date;
  type: {
    value: number;
    label: string;
  };
  description?: string | null;
  executionDate?: Date | null;
  bbchScale?: string | null;
  isPerformed?: boolean;
};

type ActivityFormProps = {
  formValues: ActivityFormValues;
  onSubmit: (formValues: ActivityFormValues) => void;
  isLoading: boolean;
};

function ActivityForm({ formValues, onSubmit, isLoading }: ActivityFormProps) {
  const schema = formValues.isPerformed
    ? PerformedActivityInput
    : NotPerformedActivityInput;
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    defaultValues: formValues,
    mode: 'onBlur',
    resolver,
  });

  return (
    <Form id={FORM_ID_ACTIVITY} onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Data prevista</label>
        <Controller
          control={control}
          name="date"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              dateFormat="dd/MM/yyyy - EEEE"
            />
          )}
        />
        {errors?.date && <InputError>{errors?.date?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Tipo</label>
        <Controller
          control={control}
          name="type"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <ActivityTypeSelect
              name="type"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isLoading}
            />
          )}
        />
        {errors?.type && <InputError>{errors?.type?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Descrição (opcional)</label>
        <Controller
          control={control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isLoading}
              autoComplete="off"
              fluid
            />
          )}
        />
      </Form.Field>
      <Form.Field>
        <label>Data execução</label>
        <Controller
          control={control}
          name="executionDate"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              dateFormat="dd/MM/yyyy - EEEE"
              disabled={isLoading || !formValues.executionDate}
              onBlur={onBlur}
              onChange={onChange}
              selected={value}
            />
          )}
        />
        {errors?.executionDate && (
          <InputError>{errors?.executionDate?.message}</InputError>
        )}
      </Form.Field>
      <Form.Field>
        <label>Escala BBCH</label>
        <Controller
          control={control}
          name="bbchScale"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              autoComplete="off"
              disabled={isLoading || !formValues.executionDate}
              fluid
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </Form.Field>
    </Form>
  );
}
