import React from 'react';

import { Table, Popup, Header, Button, Icon } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { formatToBr } from 'util/DateFormatter';

export default function EvaluationTable({
  evaluations = [],
  onAddClick,
  onEditClick,
  onRemoveClick,
  onAttachmentClick,
  loading,
}) {
  const isEmpty = !loading && evaluations.length === 0;

  if (isEmpty) {
    return (
      <Section>
        <Header as="h2">Avaliações</Header>
        <EmptyState>
          <EmptyState.Header>Nenhuma avaliação adicionada</EmptyState.Header>
          <EmptyState.Description>
            Clique no botão abaixo para adicionar uma avaliação
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button type="button" onClick={onAddClick} primary basic>
              <Icon name="add" />
              Adicionar
            </Button>
          </EmptyState.Actions>
        </EmptyState>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Content>
        <Table size="large" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Data</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Descrição</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Anexos</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading && <LoadingRow columns={4} rows={10} />}
            {!loading &&
              evaluations.map((evaluation) => (
                <Table.Row key={evaluation.id.toString()}>
                  <Table.Cell textAlign="left">
                    {formatToBr(evaluation.date)}
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    {evaluation.description}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {evaluation.documents.length > 0 ? (
                      <LinkButton
                        type="button"
                        onClick={() => onAttachmentClick(evaluation)}
                      >
                        Visualizar ({evaluation.documents.length})
                      </LinkButton>
                    ) : (
                      'Sem anexos'
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Popup
                      trigger={
                        <LinkButton
                          type="button"
                          onClick={() => onEditClick(evaluation)}
                        >
                          Editar
                        </LinkButton>
                      }
                      content="Editar"
                      mouseEnterDelay={500}
                      inverted
                    />
                    <LinkButton onClick={() => onRemoveClick(evaluation)}>
                      Remover
                    </LinkButton>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
