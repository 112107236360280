import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import api, { emptyPagination, Response } from 'util/api';

export type SaleServiceQuery = {
  id: number;
  crop: Crop;
  division: Division;
  target: Target;
  treatmentPrice: number;
};

type Crop = {
  id: number;
  name: string;
};

type Division = {
  id: number;
  description: string;
};

type Target = {
  id: number;
  name: string;
};

type SaleServiceFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

const saleServicesKeys = {
  all: ['saleServices'],
} as const;

export function useInfiniteSaleServices(filter: SaleServiceFilter) {
  const result = useInfiniteQuery(
    [...saleServicesKeys.all, 'infinite', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getSaleServices({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const saleServices =
    result?.data?.pages
      ?.map((group) => group?.data?.map((saleService) => saleService))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    saleServices,
  };
}

export function useSaleServices(filter: SaleServiceFilter) {
  return useQuery(['saleServices', filter], () =>
    getSaleServices({ orderBy: 'division', sort: 'asc', ...filter }),
  );
}

async function getSaleServices(params: SaleServiceFilter) {
  const { data } = await api.get<Response<SaleServiceQuery>>(
    `/admin/sale-services`,
    { params },
  );
  return {
    data: data.data,
    pagination: data.pagination,
  };
}

type CreateSaleServiceInput = {
  cropId: number;
  divisionId: number;
  targetId: number;
  treatmentPrice: number;
};

type UseCreateSaleServiceOptions = UseMutationOptions<
  number,
  AxiosError,
  CreateSaleServiceInput,
  () => void
>;

export function useCreateSaleService(options?: UseCreateSaleServiceOptions) {
  const queryClient = useQueryClient();

  return useMutation(createSaleService, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(saleServicesKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const createSaleService = async (input: CreateSaleServiceInput) => {
  const { headers } = await api.post(`/admin/sale-services`, input);
  const { location } = headers;
  const saleServiceId = parseInt(location.split('/').pop(), 10);

  return saleServiceId;
};

type UpdateSaleServiceInput = CreateSaleServiceInput & {
  saleServiceId: number;
};

type UseUpdateSaleServiceOptions = UseMutationOptions<
  void,
  AxiosError,
  UpdateSaleServiceInput
>;

export function useUpdateSaleService(options?: UseUpdateSaleServiceOptions) {
  const queryClient = useQueryClient();

  return useMutation(updateSaleService, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(saleServicesKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const updateSaleService = async ({
  saleServiceId,
  ...input
}: UpdateSaleServiceInput) => {
  const url = `/admin/sale-services/${saleServiceId}`;
  await api.patch(url, input);
};

type UseDeleteSaleServiceOptions = UseMutationOptions<void, AxiosError, number>;

export function useDeleteSaleService(options?: UseDeleteSaleServiceOptions) {
  const queryClient = useQueryClient();

  return useMutation(deleteSaleService, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(saleServicesKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const deleteSaleService = async (saleServiceId: number) => {
  await api.delete(`/admin/sale-services/${saleServiceId}`);
};
