import * as React from 'react';

import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Modal,
  Button,
  Message,
  Form,
  Input,
  Grid,
  Checkbox,
} from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelectV2';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useUpdateProjectCrop } from 'mutations/project';

export type CropsFormValues = {
  projectId: number;
  variety: string | null;
  plantingDate: string | null;
  customPlantingDate: string | null;
  seedlingEmergenceDate: string | null;
  cropStage: string | null;
  bbchScale: string | null;
  plantsNumber: number | null;
  plantsNumberUnit: {
    id: number;
    description: string;
    abbreviaiton: string;
  } | null;
  plantSpacingWidth: number;
  plantSpacingLength: number;
  plantSpacingUnit: {
    id: number;
    description: string;
    abbreviaiton: string;
  } | null;
};

export type CropsModalFormProps = {
  projectCropId: number;
  projectId: number;
  formValues: CropsFormValues;
  locationId?: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

const formId = 'form_crops';

export default function CropsModalForm({
  projectCropId,
  projectId,
  formValues,
  isOpen,
  onClose,
  onSuccess,
}: CropsModalFormProps) {
  const [hasCustomPlantingDate, setCustomPlantingDate] = React.useState(
    !!formValues.customPlantingDate,
  );

  const { handleError } = useErrorHandler();

  const defaultValues = formValues;
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const {
    mutate: updateProjectCrop,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateProjectCrop({
    onSuccess: () => handleSuccess('Cultura atualizadas com sucesso!'),
  });

  function handleSuccess(message: string) {
    onSuccess(message);
    onClose();
  }

  function onSubmit(values: CropsFormValues) {
    const input = {
      ...values,
      plantsNumberUnitId: values.plantsNumberUnit?.id,
      plantSpacingUnitId: values.plantSpacingUnit?.id,
      projectCropId,
      projectId,
    };

    updateProjectCrop(input);
  }

  function handleCustomDateClick() {
    setCustomPlantingDate(!hasCustomPlantingDate);
  }

  const isSubmitting = isUpdating;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Culturas</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data de plantio</label>
                    {!hasCustomPlantingDate ? (
                      <Controller
                        control={control}
                        name="plantingDate"
                        defaultValue=""
                        render={({ onChange, onBlur, value }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            disabled={isSubmitting}
                          />
                        )}
                      />
                    ) : null}
                    {hasCustomPlantingDate ? (
                      <Controller
                        control={control}
                        name="customPlantingDate"
                        defaultValue=""
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            maxLength={20}
                            autoComplete="off"
                            fluid
                          />
                        )}
                      />
                    ) : null}
                    <Checkbox
                      label="Data personalizada"
                      onChange={handleCustomDateClick}
                      style={{ marginTop: '8px' }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Data de emergência</label>
                    <Controller
                      control={control}
                      name="seedlingEmergenceDate"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isSubmitting}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Nº de plantas/frutos/colmos</label>
                    <Controller
                      control={control}
                      name="plantsNumber"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={0}
                          disabled={isSubmitting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.plantsNumber && (
                      <InputError>Somente números</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Unidade</label>
                    <Controller
                      control={control}
                      name="plantsNumberUnit"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <MeasurementUnitSelect
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          autoComplete="off"
                          isClearable
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Estágio da cultura</label>
                    <Controller
                      control={control}
                      name="cropStage"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Escala BBCH</label>
                    <Controller
                      control={control}
                      name="bbchScale"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          maxLength={50}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Unidade</label>
                    <Controller
                      control={control}
                      name="plantSpacingUnit"
                      render={({ onChange, onBlur, value }) => (
                        <MeasurementUnitSelect
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          autoComplete="off"
                          isClearable
                        />
                      )}
                    />
                    {errors.plantSpacingUnit && (
                      <InputError>Obrigatório</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Largura</label>
                    <Controller
                      control={control}
                      name="plantSpacingWidth"
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="12,8"
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          disabled={isSubmitting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.plantSpacingWidth && (
                      <InputError>Obrigatório</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Comprimento</label>
                    <Controller
                      control={control}
                      name="plantSpacingLength"
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="12,8"
                          decimalSeparator=","
                          thousandSeparator="."
                          decimalScale={2}
                          disabled={isSubmitting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.plantSpacingLength && (
                      <InputError>Data de plantio é obrigatório</InputError>
                    )}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {updateError && <Message content={handleError(updateError)} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
