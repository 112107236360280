import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import { FaRegFilePdf } from 'react-icons/fa';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';

import { downloadDocument } from 'hooks/document';

import {
  Container,
  Content,
  ContentInfo,
  ContentPrimary,
  ContentSecondary,
  ContentStatus,
} from './styles';

export default function FileItem({ name, uploadedFile, onDelete }) {
  return (
    <Container>
      <FaRegFilePdf
        color="#999"
        size={24}
        style={{ marginLeft: '16px', marginRight: '16px' }}
      />
      <Content>
        <ContentInfo>
          <ContentPrimary>{uploadedFile.name}</ContentPrimary>
          <ContentSecondary>
            {uploadedFile.readableSize}{' '}
            {(!!uploadedFile.url || uploadedFile.error) && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(name, uploadedFile);
                }}
              >
                Excluir
              </button>
            )}
          </ContentSecondary>
        </ContentInfo>
        <ContentStatus>
          {!uploadedFile.uploaded && !uploadedFile.error && (
            <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: '#78e5d5' },
              }}
              strokeWidth={10}
              value={uploadedFile.progress}
            />
          )}

          {uploadedFile.url && (
            <MdLink
              style={{ marginRight: 8, cursor: 'pointer' }}
              size={24}
              color="#222"
              onClick={() => downloadDocument(uploadedFile.id)}
            />
          )}
          {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
          {uploadedFile.error && <MdError size={24} color="#e57878" />}
        </ContentStatus>
      </Content>
    </Container>
  );
}
