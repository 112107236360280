import React from 'react';

import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import updateProjectUseCase from 'mutations/updateProject';

import { projectRepository } from '../../container';
import EditProjectForm from './EditProjectForm';

export default function EditProject() {
  const history = useHistory();
  const { id: projectId } = useParams();

  const [{ data: project, loading: isProjectLoading }, getProject] = useAsync(
    projectRepository.getProject,
  );

  const [
    { data: updated, loading, error: updateError },
    updateProject,
  ] = useAsync(updateProjectUseCase);

  React.useEffect(() => {
    getProject(projectId);
  }, [getProject, projectId]);

  React.useEffect(() => {
    function handleCreateResult() {
      if (updated) {
        toast.success('Estudo atualizado com sucesso!');
        history.goBack();
      }

      if (updateError) {
        toast.error(updateError);
      }
    }

    handleCreateResult();
  }, [updated, updateError, history]);

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);

    updateProject(projectId, updatedValues);
  }

  return (
    <Base loading={isProjectLoading} text>
      <Container>
        {project && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Edição de estudo</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <EditProjectForm
                      formValues={mapToForm(project)}
                      isSubmiting={loading}
                      onSubmit={handleSubmit}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </Base>
  );
}

function mapToForm(project) {
  return {
    insideCode: project.insideCode || null,
    protocol: project.protocol || null,
    notes: project.notes || null,
  };
}

function mapFromForm(values) {
  return {
    insideCode: values.insideCode || null,
    protocol: values.protocol || null,
    notes: values.notes || null,
  };
}
