/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import NumberFormat, { NumberFormatProps } from 'react-number-format';

const MASK = '(##) #####-####';

function PhoneInput({ ...props }: NumberFormatProps) {
  return (
    <NumberFormat {...props} format={MASK} mask="_" allowEmptyFormatting />
  );
}

export default PhoneInput;
