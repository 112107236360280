import { useHistory, useParams } from 'react-router-dom';

const tabStrategy: { [key: string]: number } = {
  'visao-geral': 0,
  coletas: 1,
  parcelas: 2,
  historico: 3,
} as const;

export function useColectionDivisionTabs(basePath: string) {
  const { active_tab: activeTab } = useParams<
    Record<string, string | undefined>
  >();
  const history = useHistory();

  const tabPathStrategy: { [key: number]: string } = {
    0: `${basePath}`,
    1: `${basePath}/coletas`,
    2: `${basePath}/parcelas`,
    3: `${basePath}/historico`,
  } as const;

  const toggle = (tabIndex: number) => {
    if (activeTab !== tabPathStrategy[tabIndex]) {
      history.push(
        `${tabIndex ? tabPathStrategy[tabIndex] : tabPathStrategy[0]}`,
      );
    }
  };

  const activeIndex = activeTab ? tabStrategy[activeTab] : 0;

  return { toggle, activeIndex };
}
