import React from 'react';

import { CircularProgressbar } from 'react-circular-progressbar';
import { FaRegFilePdf } from 'react-icons/fa';
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md';
import styled from 'styled-components';

import { downloadDocument } from 'hooks/document';

export default function DocumentItem({ uploadedFile, onDelete }) {
  return (
    <Container>
      <FaRegFilePdf
        color="#999"
        size={24}
        style={{ marginLeft: '16px', marginRight: '16px' }}
      />
      <Content>
        <ContentInfo>
          <ContentPrimary>{uploadedFile.name}</ContentPrimary>
          <ContentSecondary>
            {uploadedFile.readableSize}{' '}
            {(!!uploadedFile.url || uploadedFile.error) && (
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  onDelete(uploadedFile);
                }}
              >
                Excluir
              </button>
            )}
          </ContentSecondary>
        </ContentInfo>
        <ContentStatus>
          {!uploadedFile.uploaded && !uploadedFile.error && (
            <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: '#78e5d5' },
              }}
              strokeWidth={10}
              value={uploadedFile.progress}
            />
          )}

          {uploadedFile.url && (
            <MdLink
              style={{ marginRight: 8, cursor: 'pointer' }}
              size={24}
              color="#222"
              onClick={() => downloadDocument(uploadedFile.id)}
            />
          )}
          {uploadedFile.uploaded && <MdCheckCircle size={24} color="#78e5d5" />}
          {uploadedFile.error && <MdError size={24} color="#e57878" />}
        </ContentStatus>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: height 0.2s ease;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ContentInfo = styled.div``;

const ContentPrimary = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

const ContentSecondary = styled.div`
  font-size: 12px;
  color: #999;

  button {
    border: 0;
    background: transparent;
    color: #e57878;
    margin-left: 5px;
    cursor: pointer;
  }
`;

const ContentStatus = styled.div`
  margin: 0 16px;
`;
