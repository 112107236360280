import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

const required = 'é um campo obrigatório';
const greaterThan = 'Deve ser maior que';
const lessThen = `Deve ser menor que`;
const lessThenOrEqual = `Deve ser menor ou igual a`;

export const EditQuotation = yup.object().shape({
  customer: yup
    .object()
    .shape({})
    .required(`Empresa ${required}`)
    .transform(emptyStringToUndefined),
  representative: yup
    .object()
    .shape({})
    .required(`Representante ${required}`)
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  currency: yup
    .number()
    .required(`Moeda ${required}`)
    .transform(emptyStringToUndefined),
  discountPercent: yup
    .number()
    .min(1, `${greaterThan} 0`)
    .max(100, `${lessThenOrEqual} 100%`)
    .optional()
    .nullable(),
  version: yup
    .number()
    .min(1, `${greaterThan} 0`)
    .max(50, `${lessThen} 50`)
    .optional()
    .transform(emptyStringToUndefined),
  reviewDate: yup
    .date()
    .optional()
    .nullable(),
  notes: yup
    .string()
    .max(1000, 'Máximo de 1000 caracteres')
    .optional()
    .nullable(),
});

export const CreateQuotation = EditQuotation.shape({
  quotationType: yup
    .number()
    .required(`Tipo ${required}`)
    .transform(emptyStringToUndefined),
});
