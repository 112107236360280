import React from 'react';

import { createMoney } from '@easymoney/money';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useParams, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Icon,
  Header,
  Loader,
  Dropdown,
} from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import InfoList from 'components/data/InfoList';
import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import AlertModal from 'components/layout/AlertModal';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import QuotationDuplicationModal from 'components/modal/QuotationDuplicationModal';
import QuotationFormModal from 'components/modal/QuotationFormModal';
import { useAuth } from 'hooks/auth';
import { useModal, useModalWithData } from 'hooks/useModal';
import {
  useApproveQuotation,
  useDeleteProductItem,
  useDeleteQuotation,
  useDuplicateProductItem,
  useRejectQuotation,
  useRemoveQuotationItem,
  useRevertQuotation,
} from 'mutations/quotation';
import { formatDivision } from 'queries/division';
import {
  formatStatus,
  QuotationProjectsResponse,
  QUOTATION_TYPE,
  SHIPPING_TYPE_MAP,
  useQuotation,
} from 'queries/quotation';
import { formatCurrencyWithSymbol } from 'util/Currency';
import { formatToBr } from 'util/DateFormatter';

import ContractGenerationModal from './ContractGenerationModal';
import GenerateQuotationDocumentModal from './GenerateQuotationDocumentModal';
import ProductContractGenerationModal from './ProductContractGenerationModal';
import ProductItemModal from './ProductItemModal';
import ProductItemTable from './ProductItemTable';
import QuotationPaymentFormModal from './QuotationPaymentFormModal';
import QuotationPaymentsTable from './QuotationPaymentsTable';
import QuotationProjectDuplicationModal from './QuotationProjectDuplicationModal';
import QuotationProjectsTable from './QuotationProjectsTable';
import RemoveQuotationProjectModal from './RemoveQuotationProjectModal';
import ShippingModal from './ShippingModal';

export default function QuotationPage() {
  const history = useHistory();
  const params = useParams<{ quotationId: string }>();
  const quotationId = parseInt(params.quotationId, 10);

  const { user } = useAuth();

  const contractGenerationModal = useModal();
  const documentGenerationModal = useModal();
  const paymentModal = useModal();
  const productContractGenerationModal = useModal();
  const productItemDeletionModal = useModalWithData();
  const productItemDuplicationModal = useModalWithData();
  const productItemModal = useModalWithData();
  const projectDeletionModal = useModalWithData();
  const projectItemDuplicationModal = useModalWithData();
  const quotationAppovalModal = useModal();
  const quotationDeletionModal = useModal();
  const quotationDuplicationModal = useModal();
  const quotationEditModal = useModal();
  const quotationRejectionModal = useModal();
  const quotationReversionModal = useModal();
  const shippingModal = useModalWithData();

  const { data: quotation, isLoading: loadingQuotation } = useQuotation(
    quotationId,
  );

  const approveQuotation = useApproveQuotation({
    onSuccess: () => onSuccess(quotationAppovalModal, 'Orçamento aprovado!'),
  });

  const rejectQuotation = useRejectQuotation({
    onSuccess: () => onSuccess(quotationRejectionModal, 'Orçamento rejeitado!'),
  });

  const deleteProjectItem = useRemoveQuotationItem({
    onSuccess: () => onSuccess(projectDeletionModal, 'Item removido!'),
  });

  const revertQuotation = useRevertQuotation({
    onSuccess: () =>
      onSuccess(quotationReversionModal, 'Orçamento revertido com sucesso!'),
  });

  const deleteQuotation = useDeleteQuotation({
    onSuccess: () => {
      onSuccess(quotationDeletionModal, 'Orçamento excluído!');
      history.goBack();
    },
  });

  const deleteProductItem = useDeleteProductItem({
    onSuccess: () => onSuccess(productItemDeletionModal, 'Item excluído!'),
  });

  const duplicateProductItem = useDuplicateProductItem({
    onSuccess: () => onSuccess(productItemDuplicationModal, 'Item duplicado!'),
  });

  function onSuccess(modal: ReturnType<typeof useModal>, message: string) {
    modal.close();
    toast.success(message);
  }

  function handleApproveClick() {
    quotationAppovalModal.open();
  }

  function handleRejectClick() {
    quotationRejectionModal.open();
  }

  function handleDuplicateQuotationClick() {
    quotationDuplicationModal.open();
  }

  function handleRevertQuotationClick() {
    quotationReversionModal.open();
  }

  function handleDeleteQuotationClick() {
    quotationDeletionModal.open();
  }

  function handleDuplicateProjectClick(project: QuotationProjectsResponse) {
    projectItemDuplicationModal.open(project);
  }

  function handleAddProjectClick() {
    history.push(`/orcamentos/${quotationId}/estudos/novo`);
  }

  function handleEditProjectClick(project: QuotationProjectsResponse) {
    history.push(`/orcamentos/${quotationId}/estudos/${project.id}/editar`);
  }

  function handleRemoveItemClick(project: QuotationProjectsResponse) {
    projectDeletionModal.open(project);
  }

  function handleAddShippingQuotationClick() {
    shippingModal.open();
  }

  function handleEditShippingClick() {
    const formValues = {
      ...quotation?.shipping,
      shippingType: quotation?.shipping?.shippingType?.toString(),
      amount: quotation?.shipping?.amount
        ? parseInt(
            createMoney({
              amount: quotation.shipping.amount,
              currency: quotation.currency,
            })
              .divide(100)
              .getAmount(),
            10,
          )
        : null,
    };
    shippingModal.open(formValues);
  }

  function handleAddProductItemClick() {
    productItemModal.open();
  }

  function handleDeleteProductItemClick(productItemId: number) {
    productItemDeletionModal.open(productItemId);
  }

  function handleDuplicateProductItemClick(productItemId: number) {
    productItemDuplicationModal.open(productItemId);
  }

  function handleEditProductItemClick(productItemId: number) {
    const productItem = quotation?.products?.find(
      (item) => item.id === productItemId,
    );

    if (productItem) {
      const formValues = {
        division: {
          value: productItem.division,
          label: formatDivision({ division: productItem.division }),
        },
        quantity: productItem.quantity,
        saleProduct: {
          id: productItem.saleProductId,
          description: productItem.description,
          unitPrice: productItem.unitPrice,
          measurementUnit: `${productItem.unit.description} (${productItem.unit.abbreviation})`,
          measurementUnitId: productItem.unit.id,
        },
        shippingForecast: productItem.shippingForecast
          ? DateTime.fromISO(productItem.shippingForecast).toJSDate()
          : undefined,
        unit: {
          id: productItem.unit.id,
          abbreviation: productItem.unit.abbreviation,
        },
        unitPrice: productItem.unitPrice / 100,
      };

      productItemModal.open({ productItemId, formValues });
    }
  }

  function handleCustomerClick() {
    history.push(`/patrocinadores/${quotation?.customer.id}`);
  }

  function handleRemoveItemSubmit() {
    if (projectDeletionModal?.data) {
      deleteProjectItem.mutate({
        quotationId,
        quotationItemId: projectDeletionModal?.data?.id,
      });
    }
  }

  async function handleRevertQuotationSubmit() {
    revertQuotation.mutate(quotationId);
  }

  async function handleDeleteQuotationSubmit() {
    deleteQuotation.mutate(quotationId);
  }

  if (!quotation && !loadingQuotation) {
    return <div />;
  }

  if (loadingQuotation) {
    return (
      <Loader active size="large" style={{ marginTop: '100px' }}>
        Carregando...
      </Loader>
    );
  }

  const isGenerateDocumentButtonDisabled =
    (!quotation.hasProjects && !quotation.hasProducts) ||
    !quotation.hasPayments;

  const isGenerateContractButtonDisabled = !quotation.isAccepted;

  const isApproveButtonDisabled =
    quotation.isInNegotiation &&
    ((!quotation.hasProjects && !quotation.hasProducts) ||
      !quotation.hasPayments);
  const isRejectButtonDisabled = isApproveButtonDisabled;

  const researcherOptions = [
    {
      key: 'duplicate',
      text: 'Duplicar orçamento',
      value: 'Duplicate quotation',
      icon: 'copy outline',
      onClick: handleDuplicateQuotationClick,
    },
  ];
  const adminOptions = [
    ...researcherOptions,
    {
      key: 'revert',
      text: 'Reverter orçamento',
      value: 'Revert to negotiation',
      icon: 'repeat',
      onClick: handleRevertQuotationClick,
      disabled: !quotation.isAnswered,
    },
    {
      key: 'delete',
      text: 'Excluir orçamento',
      value: 'Delete negotiation',
      icon: 'trash',
      onClick: handleDeleteQuotationClick,
      disabled: !quotation.isInNegotiation,
    },
  ];
  const options = user?.isAdmin ? adminOptions : researcherOptions;

  const totalAmount = quotation.hasDiscount
    ? quotation.totalAmountWithDiscount
    : quotation.totalAmount;
  const totalAmountLabel = quotation.hasDiscount
    ? 'Valor total (com desconto)'
    : 'Valor total';

  const divisionsFormatted = quotation.divisions
    .map((division) => formatDivision({ division }))
    .join(', ');

  const isContractPending =
    quotation.isAccepted && !quotation.isContractGenerated;
  const showRejectButton = quotation.isInNegotiation && user?.isAdmin;
  const showApproveButton = quotation.isInNegotiation && user?.isAdmin;
  const showGenerateContractButton =
    quotation.isAccepted && !quotation.isContractGenerated && user?.isAdmin;

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h1" floated="left">
                {quotation?.code}
              </Header>
              <Tag color={formatQuotationStatusColor(quotation?.status)}>
                {formatStatus(quotation?.status)}
              </Tag>
              {isContractPending ? (
                <span style={{ marginLeft: 4 }}>
                  <Tag color="yellow">Contrato pendente</Tag>
                </span>
              ) : null}
            </Grid.Column>
            <Grid.Column width={10} textAlign="right">
              <Dropdown
                text="Mais opções"
                direction="left"
                loading={false}
                floating
                basic
                button
              >
                <Dropdown.Menu>
                  <Dropdown.Menu scrolling>
                    {options.map((option) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <Dropdown.Item {...option} />
                    ))}
                  </Dropdown.Menu>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                onClick={() => documentGenerationModal.open()}
                disabled={isGenerateDocumentButtonDisabled}
                basic
              >
                <Icon name="file pdf outline" />
                Gerar PDF
              </Button>
              {showRejectButton ? (
                <Button
                  onClick={handleRejectClick}
                  disabled={isRejectButtonDisabled}
                  basic
                >
                  <Icon name="ban" />
                  Rejeitar
                </Button>
              ) : null}
              {showApproveButton ? (
                <Button
                  onClick={handleApproveClick}
                  disabled={isApproveButtonDisabled}
                  primary
                >
                  <Icon name="check" />
                  Aprovar
                </Button>
              ) : null}
              {showGenerateContractButton ? (
                <Button
                  onClick={() =>
                    quotation.quotationType === QUOTATION_TYPE.service
                      ? contractGenerationModal.open()
                      : productContractGenerationModal.open()
                  }
                  disabled={isGenerateContractButtonDisabled}
                  primary
                >
                  <Icon name="file alternate outline" />
                  Gerar contrato
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid>
                <Grid.Row columns="4">
                  <Grid.Column>
                    <Section>
                      <Section.Content>
                        <TextInfo label="Safra" text={quotation?.harvest} />
                      </Section.Content>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Section>
                      <Section.Content>
                        <TextInfo
                          label="Cliente"
                          text={quotation?.customer?.tradingName}
                          onClick={handleCustomerClick}
                        />
                      </Section.Content>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Section>
                      <Section.Content>
                        <TextInfo
                          label="Responsável Promip"
                          text={quotation?.quotationResponsible.fullName}
                        />
                      </Section.Content>
                    </Section>
                  </Grid.Column>
                  <Grid.Column>
                    <Section>
                      <Section.Content>
                        <TextInfo
                          label={totalAmountLabel}
                          text={
                            <span style={{ color: 'green' }}>
                              {formatCurrencyWithSymbol(
                                totalAmount,
                                quotation.currency,
                              )}
                            </span>
                          }
                        />
                      </Section.Content>
                    </Section>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Section>
                <Section.Header>
                  <Header as="h3" style={{ margin: 0 }}>
                    Detalhes
                  </Header>
                  {!quotation.isContractGenerated && (
                    <Button
                      type="button"
                      size="small"
                      floated="right"
                      onClick={() => quotationEditModal.open()}
                      basic
                    >
                      <Icon name="edit" />
                      Editar
                    </Button>
                  )}
                </Section.Header>
                <Section.Content>
                  <InfoList>
                    <InfoList.Item
                      label="Representante:"
                      value={quotation.representative.name}
                    />
                    <InfoList.Item
                      label="Setor:"
                      value={divisionsFormatted || ''}
                    />
                    <InfoList.Item label="Versão:" value={quotation.version} />
                    <InfoList.Item
                      label="Data do orçamento:"
                      value={formatToBr(quotation.quotationDate)}
                    />
                    <InfoList.Item
                      label="Data de revisão:"
                      value={formatToBr(quotation.reviewDate)}
                    />
                    <InfoList.Item
                      label="Data de resposta:"
                      value={formatToBr(quotation.responseDate)}
                    />
                    <InfoList.Item
                      label="Contrato gerado: "
                      value={quotation.contract?.number || ''}
                      onClick={
                        quotation.contract && user?.isAdmin
                          ? () =>
                              history.push(
                                `/contratos/${quotation.contract?.id}`,
                              )
                          : undefined
                      }
                    />
                    <InfoList.Item
                      label="Observações:"
                      value={quotation.notes || ''}
                    />
                  </InfoList>
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column width={8}>
              <Section>
                <Section.Header>
                  <Header as="h3" style={{ margin: 0 }}>
                    Pagamentos
                  </Header>
                  {quotation?.hasPayments && !quotation.isContractGenerated ? (
                    <Button
                      type="button"
                      size="small"
                      onClick={() => paymentModal.open()}
                      basic
                    >
                      <Icon name="edit" />
                      Editar
                    </Button>
                  ) : null}
                </Section.Header>
                <Section.Content>
                  <Grid>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <TextInfo
                          label="Total"
                          text={
                            quotation?.hasDiscount ? (
                              <span style={{ textDecoration: 'line-through' }}>
                                {formatCurrencyWithSymbol(
                                  quotation.totalAmount,
                                  quotation.currency,
                                )}
                              </span>
                            ) : (
                              formatCurrencyWithSymbol(
                                quotation.totalAmount,
                                quotation.currency,
                              )
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <TextInfo
                          label="Desconto (%)"
                          text={
                            quotation?.hasDiscount
                              ? `${quotation?.discountPercent}%`
                              : '-'
                          }
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <TextInfo
                          label="Total c/ desconto"
                          text={
                            quotation?.hasDiscount
                              ? formatCurrencyWithSymbol(
                                  quotation.totalAmountWithDiscount,
                                  quotation.currency,
                                )
                              : '-'
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <QuotationPaymentsTable
                          quotation={quotation}
                          quotationPayments={quotation?.payments}
                          loading={loadingQuotation}
                          onAddClick={() => paymentModal.open()}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {quotation.quotationType === QUOTATION_TYPE.product ? (
                <>
                  {quotation?.shipping ? (
                    <Section>
                      <Section.Header>
                        <Header as="h3" style={{ margin: 0 }}>
                          Frete
                        </Header>
                        {!quotation.isContractGenerated ? (
                          <Button
                            basic
                            onClick={handleEditShippingClick}
                            size="small"
                          >
                            <Icon name="edit" />
                            Editar
                          </Button>
                        ) : null}
                      </Section.Header>
                      <Section.Content>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1 }}>
                            <TextInfo
                              label="Tipo"
                              text={
                                SHIPPING_TYPE_MAP[
                                  quotation?.shipping?.shippingType
                                ]
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <TextInfo
                              label="Qtd de envios"
                              text={quotation?.shipping?.quantity}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <TextInfo
                              label="Valor"
                              text={
                                quotation?.shipping?.amount != null
                                  ? formatCurrencyWithSymbol(
                                      quotation?.shipping?.amount,
                                      quotation?.currency,
                                    )
                                  : 'n/a'
                              }
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <TextInfo
                              label="Total"
                              text={
                                quotation?.shipping?.totalAmount != null
                                  ? formatCurrencyWithSymbol(
                                      quotation?.shipping?.totalAmount,
                                      quotation?.currency,
                                    )
                                  : 'n/a'
                              }
                            />
                          </div>
                        </div>
                      </Section.Content>
                    </Section>
                  ) : (
                    <Section>
                      <Section.Content>
                        <EmptyState>
                          <EmptyState.Header>
                            Frete não adicionado
                          </EmptyState.Header>
                          <EmptyState.Description>
                            Clique no botão abaixo para adicionar
                          </EmptyState.Description>
                          <EmptyState.Actions>
                            <Button
                              basic
                              type="button"
                              onClick={handleAddShippingQuotationClick}
                              primary
                            >
                              <Icon name="add" />
                              Adicionar
                            </Button>
                          </EmptyState.Actions>
                        </EmptyState>
                      </Section.Content>
                    </Section>
                  )}
                </>
              ) : null}
            </Grid.Column>
          </Grid.Row>
          {quotation.quotationType === QUOTATION_TYPE.service ? (
            <Grid.Row>
              <Grid.Column width={16}>
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Itens
                    </Header>
                    {quotation.hasProjects && quotation.isInNegotiation && (
                      <Button
                        floated="right"
                        onClick={handleAddProjectClick}
                        primary
                        basic
                      >
                        <Icon name="add" />
                        Adicionar item
                      </Button>
                    )}
                  </Section.Header>
                  <Section.Content>
                    <QuotationProjectsTable
                      quotation={quotation}
                      quotationProjects={quotation.projects}
                      isLoading={loadingQuotation}
                      onAddClick={handleAddProjectClick}
                      onDuplicateClick={handleDuplicateProjectClick}
                      onEditClick={handleEditProjectClick}
                      onRemoveClick={handleRemoveItemClick}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {quotation.quotationType === QUOTATION_TYPE.product ? (
            <Grid.Row>
              <Grid.Column width={16}>
                <ProductItemTable
                  items={quotation?.products || []}
                  currency={quotation?.currency}
                  onAddClick={handleAddProductItemClick}
                  onDeleteClick={handleDeleteProductItemClick}
                  onDuplicateClick={handleDuplicateProductItemClick}
                  onEditClick={handleEditProductItemClick}
                  showActions={quotation?.isInNegotiation}
                  showAddButton={!quotation.isContractGenerated}
                />
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
      </Container>

      {quotationEditModal.isOpen ? (
        <QuotationFormModal
          onClose={quotationEditModal.close}
          open={quotationEditModal.isOpen}
          quotation={quotation}
        />
      ) : null}

      {quotationAppovalModal.isOpen ? (
        <AlertModal
          header="Aprovar orçamento"
          content="Um orçamento aprovado pode gerar um contrato ou ser revertido para continuar a negociação."
          submitText="Aprovar"
          isOpen={quotationAppovalModal.isOpen}
          isLoading={approveQuotation.isLoading}
          onClose={quotationAppovalModal.close}
          onSubmit={() => approveQuotation.mutate(quotationId)}
        />
      ) : null}

      {quotationRejectionModal.isOpen ? (
        <AlertModal
          header="Rejeitar orçamento"
          content="Um orçamento rejeitado pode ser revertido para negociação, caso necessário."
          submitText="Rejeitar"
          isOpen={quotationRejectionModal.isOpen}
          isLoading={rejectQuotation.isLoading}
          onClose={quotationRejectionModal.close}
          onSubmit={() => rejectQuotation.mutate(quotationId)}
        />
      ) : null}

      {quotation && paymentModal.isOpen ? (
        <QuotationPaymentFormModal
          onClose={paymentModal.close}
          open={paymentModal.isOpen}
          quotation={quotation}
        />
      ) : null}

      {contractGenerationModal.isOpen ? (
        <ContractGenerationModal
          onClose={contractGenerationModal.close}
          open={contractGenerationModal.isOpen}
          quotation={quotation}
        />
      ) : null}

      {productContractGenerationModal.isOpen ? (
        <ProductContractGenerationModal
          onClose={productContractGenerationModal.close}
          open={productContractGenerationModal.isOpen}
          quotation={quotation}
        />
      ) : null}

      {projectDeletionModal.isOpen ? (
        <RemoveQuotationProjectModal
          isOpen={projectDeletionModal.isOpen}
          onClose={projectDeletionModal.close}
          onSubmit={handleRemoveItemSubmit}
          isLoading={deleteProjectItem.isLoading}
        />
      ) : null}

      {documentGenerationModal.isOpen ? (
        <GenerateQuotationDocumentModal
          isOpen={documentGenerationModal.isOpen}
          onClose={documentGenerationModal.close}
          quotationId={quotation?.id}
        />
      ) : null}

      {quotationDuplicationModal.isOpen ? (
        <QuotationDuplicationModal
          quotationId={quotationId}
          isOpen={quotationDuplicationModal.isOpen}
          onClose={quotationDuplicationModal.close}
        />
      ) : null}

      {quotationReversionModal.isOpen ? (
        <AlertModal
          header="Reverter orçamento para negociação"
          content={`O status do orçamento será revertido para "Em negociação" e a data de resposta será apagada. Tem certeza que deseja reverter este orçamento?`}
          submitText="Reverter"
          isOpen={quotationReversionModal.isOpen}
          isLoading={revertQuotation.isLoading}
          onClose={quotationReversionModal.close}
          onSubmit={handleRevertQuotationSubmit}
          negative
        />
      ) : null}

      {quotationDeletionModal.isOpen ? (
        <AlertModal
          header="Excluir orçamento"
          content="O orçamento será excluído e só será possível recuperá-lo através de um administrador."
          submitText="Excluir"
          isOpen={quotationDeletionModal.isOpen}
          isLoading={deleteQuotation.isLoading}
          onClose={quotationDeletionModal.close}
          onSubmit={handleDeleteQuotationSubmit}
          negative
        />
      ) : null}

      {projectItemDuplicationModal.isOpen ? (
        <QuotationProjectDuplicationModal
          isOpen={projectItemDuplicationModal.isOpen}
          onClose={projectItemDuplicationModal.close}
          quotationId={quotationId}
          quotationProjectId={projectItemDuplicationModal.data?.id}
        />
      ) : null}

      {shippingModal.isOpen ? (
        <ShippingModal
          formValues={shippingModal.data}
          isOpen={shippingModal.isOpen}
          onClose={shippingModal.close}
          quotationId={quotationId}
        />
      ) : null}

      {productItemModal.isOpen ? (
        <ProductItemModal
          formValues={productItemModal?.data?.formValues}
          isOpen={productItemModal.isOpen}
          onClose={productItemModal.close}
          productItemId={productItemModal?.data?.productItemId}
          quotationId={quotationId}
        />
      ) : null}

      {productItemDeletionModal.isOpen && productItemDeletionModal.data ? (
        <AlertModal
          header="Excluir item"
          content="Tem certeza que deseja excluir este item?"
          submitText="Excluir"
          isOpen={productItemDeletionModal.isOpen}
          isLoading={deleteProductItem.isLoading}
          onClose={productItemDeletionModal.close}
          onSubmit={() =>
            deleteProductItem.mutate({
              quotationId,
              productItemId: productItemDeletionModal.data,
            })
          }
          negative
        />
      ) : null}

      {productItemDuplicationModal.isOpen &&
      productItemDuplicationModal.data ? (
        <AlertModal
          header="Duplicar item"
          content="Tem certeza que deseja duplicar este item?"
          submitText="Duplicar"
          isOpen={productItemDuplicationModal.isOpen}
          isLoading={duplicateProductItem.isLoading}
          onClose={productItemDuplicationModal.close}
          onSubmit={() =>
            duplicateProductItem.mutate({
              quotationId,
              productItemId: productItemDuplicationModal.data,
            })
          }
        />
      ) : null}
    </Base>
  );
}

function formatQuotationStatusColor(quotationStatus) {
  switch (quotationStatus) {
    case 1:
      return 'blue';
    case 2:
      return 'green';
    case 3:
      return 'red';
    case 4:
      return 'transparent';
    default:
      return '';
  }
}
