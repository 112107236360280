import React, { useContext, createContext, useState } from 'react';

import { CustomerQuery } from 'queries/customer';
import { SAMPLE_STATUS, SAMPLE_STATUS_DESCRIPTION } from 'queries/sample';

type Option = {
  value: number;
  label: string;
};

type StatusOption = {
  value: string;
  label: string;
};

type SampleFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  code?: string;
  projectCode?: string;
  projectGoal?: Option;
  customer?: CustomerQuery;
  productName?: string;
  status?: StatusOption;
  expirationStatus?: Option;
};

export type SampleFilterContextData = {
  filter: SampleFilter;
  updateFilter: (newData: SampleFilter) => void;
  getMappedFilter: () => void;
};

const defaultStatus = {
  label: SAMPLE_STATUS_DESCRIPTION[SAMPLE_STATUS.in_use],
  value: SAMPLE_STATUS.in_use,
};

export const SampleFilterContextDefaultValues = {
  filter: {
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    sort: 'desc',
    code: '',
    projectCode: '',
    projectGoal: undefined,
    customer: undefined,
    status: defaultStatus,
    productName: '',
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
};

export const SampleFilterContext = createContext<SampleFilterContextData>(
  SampleFilterContextDefaultValues,
);

export type SampleFilterProviderProps = {
  children: React.ReactNode;
};

const SampleFilterProvider = ({ children }: SampleFilterProviderProps) => {
  const [projectFilter, setSampleFilter] = useState<SampleFilter>(
    SampleFilterContextDefaultValues.filter,
  );

  const updateFilter = (data: SampleFilter) => {
    const newFilter = { ...projectFilter, ...data };
    setSampleFilter(newFilter);
  };

  const getMappedFilter = () => {
    const {
      projectGoal,
      customer,
      productName,
      status,
      expirationStatus,
      ...filterUpdated
    } = projectFilter;
    return {
      ...filterUpdated,
      projectGoal: projectGoal?.value || '',
      customerId: customer?.id || '',
      productName: productName || '',
      status: status?.value || '',
      expirationStatus: expirationStatus?.value || '',
    };
  };

  return (
    <SampleFilterContext.Provider
      value={{
        filter: projectFilter,
        updateFilter,
        getMappedFilter,
      }}
    >
      {children}
    </SampleFilterContext.Provider>
  );
};

const useSampleFilter = () => useContext(SampleFilterContext);

export { SampleFilterProvider, useSampleFilter };
