import React, { useEffect } from 'react';

import toast from 'react-hot-toast';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
} from 'semantic-ui-react';

import Section from 'components/layout/Section';
import useDebounce from 'hooks/useDebounce';
import { useModal } from 'hooks/useModal';
import { useCreateCustomer } from 'mutations/customer';
import {
  useExportRepresentatives,
  useInfiniteCustomers,
} from 'queries/customer';
import { handleError } from 'util/ErrorHandler';

import CustomerFormModal from './CustomerFormModal';
import CustomersTable from './CustomersTable';

export default function Customers() {
  const history = useHistory();

  const modal = useModal();

  const [tradingName, setTradingName] = React.useState('');

  const debouncedTradingName = useDebounce(tradingName, 400);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingCustomers,
  } = useInfiniteCustomers({
    orderBy: 'tradingName',
    sort: 'asc',
    tradingName: debouncedTradingName,
  });

  const flattenData = data?.pages
    ?.map((group) => group?.customers?.map((sample) => sample))
    .flat();

  const queryClient = useQueryClient();
  const {
    mutate: createCustomer,
    isLoading: isSubmitting,
    error,
    reset,
  } = useCreateCustomer({
    onSuccess: (customerId) => {
      queryClient.invalidateQueries('customers');
      toast.success('Cliente cadastrados com sucesso!');
      modal.close();
      history.push(`/patrocinadores/${customerId}`);
    },
  });

  const {
    mutate: exportRepresentatives,
    isLoading: isExporting,
  } = useExportRepresentatives({
    onSuccess: () => {
      toast.success('Clientes exportados com sucesso!');
    },
  });

  useEffect(() => {
    reset();
  }, [modal.isOpen, reset]);

  function handleSubmit(values) {
    const input = mapFromForm(values);
    createCustomer(input);
  }

  function handleNewCustomerClick(e) {
    e.preventDefault();
    modal.open();
  }

  function handleTradingNameChange(e, { value }) {
    setTradingName(value);
  }

  async function handleExportRepresentativesClick() {
    exportRepresentatives();
  }

  const options = [
    {
      key: 'export-representatives',
      text: 'Todos os representantes',
      value: 'export-representatives',
      onClick: handleExportRepresentativesClick,
    },
  ];

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8} textAlign="left" verticalAlign="middle">
            <Header as="h1">Clientes</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right" verticalAlign="middle">
            <Dropdown
              text="Relatórios"
              direction="left"
              loading={isExporting}
              floating
              basic
              button
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {options.map((option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Dropdown.Item key={option.value} {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <Button type="button" onClick={handleNewCustomerClick} primary>
              <Icon name="add" />
              Novo cliente
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column width={4} textAlign="left" verticalAlign="middle">
            <Form>
              <Form.Field>
                <Form.Input
                  placeholder="Pesquisar..."
                  autoComplete="off"
                  onChange={handleTradingNameChange}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Section>
              <Grid>
                <Grid.Column width={16}>
                  <CustomersTable
                    customers={flattenData}
                    loading={isLoadingCustomers}
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                    isFetchingNextPage={isFetchingNextPage}
                  />
                </Grid.Column>
              </Grid>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <CustomerFormModal
        error={error ? handleError(error) : undefined}
        isSubmitting={isSubmitting}
        isOpen={modal.isOpen}
        onClose={() => modal.close()}
        onSubmit={handleSubmit}
      />
    </>
  );
}

function mapFromForm(values) {
  return {
    companyName: values.companyName,
    tradingName: values.tradingName,
    phone: values.phone,
    cnpj: values.cnpj || '',
    insideCode: values.insideCode || null,
    stateRegistration: values.stateRegistration || null,
    address: {
      street: values.street || null,
      streetNumber: values.streetNumber || null,
      neighborhood: values.neighborhood || null,
      zipcode: values.zipcode || null,
      complementary: values.complementary || null,
      city: values.city || null,
      state: values.state || null,
      country: values.country || null,
    },
    representatives: values.representatives || [],
  };
}
