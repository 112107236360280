import React from 'react';

import { Button, Modal } from 'semantic-ui-react';

export type AlertModalProps = {
  header: string;
  content: string;
  submitText?: string;
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  negative?: boolean;
};

export default function AlertModal({
  header,
  content,
  submitText = 'Confirmar',
  onClose,
  onSubmit,
  isOpen = false,
  isLoading = false,
  negative = false,
}: AlertModalProps) {
  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        {onSubmit ? (
          <Button
            disabled={isLoading}
            type="button"
            onClick={onSubmit}
            loading={isLoading}
            primary={!negative}
            negative={negative}
          >
            {submitText}
          </Button>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}
