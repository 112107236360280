import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Divider, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import {
  sampleMapper,
  SamplesQuery,
  SAMPLE_STATUS_COLOR,
  SAMPLE_STATUS_DESCRIPTION,
} from 'queries/sample';
import theme from 'styles/theme';
import { Pagination } from 'util/api';

type SampleTableProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
  pagination: Pagination;
  samples: SamplesQuery[];
};

export default function SampleTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  pagination,
  samples = [],
}: SampleTableProps) {
  const loadingRows = 5;
  const columns = 9;

  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Amostras" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Cliente</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Finalidades</Table.HeaderCell>
              <Table.HeaderCell>Entrada</Table.HeaderCell>
              <Table.HeaderCell>Validade</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Qtd. total</Table.HeaderCell>
              <Table.HeaderCell>Documentação</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={columns} rows={loadingRows} />}
            {!isLoading &&
              samples?.map((sample) => {
                const {
                  id,
                  type,
                  customer,
                  goals,
                  volume,
                  code,
                  arrivalDateFormatted,
                  product,
                  ingredients,
                  expirationDate,
                  expirationDateFormatted,
                  checkInDocumentId,
                  custodyChainDocumentId,
                  packagingQuantity,
                  status,
                } = sampleMapper(sample);

                const productTitle = `${sample.product.name}\n${ingredients}`;
                const isDocumentationRegularized = !!(
                  checkInDocumentId && custodyChainDocumentId
                );

                return (
                  <Table.Row key={id + code}>
                    <Table.Cell>
                      <Text variant="primary">
                        <Link to={`/amostras/${id}`}>{code}</Link>
                      </Text>
                      <Text variant="secondary">{type}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">
                        {customer?.tradingName || ''}
                      </Text>
                    </Table.Cell>
                    <Table.Cell title={productTitle}>
                      <Text variant="primary">{product.name}</Text>
                      <Text variant="secondary">{product.category}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">
                        <Tag color={SAMPLE_STATUS_COLOR[status]}>
                          {SAMPLE_STATUS_DESCRIPTION[status]}
                        </Tag>
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">
                        <>
                          {goals.map((goal) => (
                            <span key={goal} style={{ marginRight: 4 }}>
                              <Tag color="grey">{goal}</Tag>
                            </span>
                          ))}
                        </>
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{arrivalDateFormatted}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{expirationDateFormatted}</Text>
                      <Text variant="secondary">
                        {// eslint-disable-next-line no-nested-ternary
                        expirationDate ? (
                          expirationDate > new Date().toISOString() ? (
                            'Na validade'
                          ) : (
                            <span style={{ color: theme.colors.red }}>
                              Vencida
                            </span>
                          )
                        ) : (
                          'n/d'
                        )}
                      </Text>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Text variant="primary">{volume}</Text>
                      <Text variant="secondary">
                        {packagingQuantity === 1
                          ? `${packagingQuantity} embalagem`
                          : `${packagingQuantity} embalagens`}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {isDocumentationRegularized ? (
                        <Tag color="transparent">Completa</Tag>
                      ) : (
                        <Tag color="yellow">Pendente</Tag>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={columns}>
                <Divider hidden />
                {hasNextPage ? (
                  <Button
                    type="button"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Carregando...' : ''}
                    {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                  </Button>
                ) : (
                  <Text variant="secondary">Nada mais para mostrar</Text>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
