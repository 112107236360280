import React from 'react';

export function useModal(initialMode = false) {
  const [isOpen, setIsOpen] = React.useState(initialMode);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return { isOpen, open, close };
}

export function useModalWithData<T>(
  initialMode = false,
  initialData: T | null = null,
) {
  const [isOpen, setIsOpen] = React.useState(initialMode);
  const [data, setData] = React.useState<T | null>(initialData);

  const open = (newData?: T) => {
    setData(newData || null);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setData(null);
  };

  return { isOpen, data, open, close };
}
