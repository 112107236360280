import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import EmployeeSearch from 'components/inputs/EmployeeSearch';
import InputError from 'components/inputs/InputError';

import { SampleManipulation } from './validation';

type SampleManipulationModalFormProps = {
  code: string;
  error?: string | null;
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  projects: {
    id: number;
    insideCode: string;
  }[];
  startAmount: number;
};

export type FormValues = {
  balanceCode: string;
  date: Date;
  endAmount: number;
  lostAmount: number;
  lostCode?: string | null;
  notes?: string | null;
  project: {
    value: number;
    label: string;
  };
  responsible: {
    value: number;
    label: string;
  };
  startAmount: number;
  time: Date;
  weighedAmount: number;
};

const formId = 'sample_manipulation_form';

function SampleManipulationModalForm({
  code,
  error,
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  projects,
  startAmount,
}: SampleManipulationModalFormProps) {
  const resolver = yupResolver(SampleManipulation);
  const defaultValues = {
    startAmount,
  };
  const { control, errors, handleSubmit, setValue, watch } = useForm({
    defaultValues,
    resolver,
  });

  const watchStartAmount = watch('startAmount');
  const watchWeighedAmount = watch('weighedAmount');
  const watchLostAmount = watch('lostAmount');

  React.useEffect(() => {
    const endAmount =
      (startAmount || watchStartAmount) - watchWeighedAmount - watchLostAmount;
    setValue('endAmount', endAmount);
  }, [
    setValue,
    startAmount,
    watchStartAmount,
    watchWeighedAmount,
    watchLostAmount,
  ]);

  const projectOptions = projects.map(({ id: value, insideCode: label }) => ({
    value,
    label,
  }));

  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Manipulação da amostra {code}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Data</label>
              <Controller
                control={control}
                defaultValue={new Date()}
                name="date"
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    disabled={isLoading}
                    onBlur={onBlur}
                    onChange={onChange}
                    selected={value}
                    showMonthYearPicker
                  />
                )}
              />
              {errors.date && <InputError>{errors?.date?.message}</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Horário</label>
              <Controller
                control={control}
                name="time"
                defaultValue={new Date()}
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    dateFormat="HH:mm"
                    placeholderText="hh:mm"
                    timeIntervals={5}
                    timeCaption="Hora"
                    showTimeSelect
                    showTimeSelectOnly
                    disabled={isLoading}
                  />
                )}
              />
              {errors.time && <InputError>{errors?.time?.message}</InputError>}
            </Form.Field>
            <Form.Field />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Quantidade inicial</label>
              <Controller
                control={control}
                name="startAmount"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    autoComplete="off"
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={startAmount !== 0}
                    onBlur={onBlur}
                    onValueChange={(data) => onChange(data.floatValue)}
                    placeholder="0.00"
                    suffix=" g"
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.startAmount && (
                <InputError>{errors?.startAmount?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
            <Form.Field />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Quantidade pesada</label>
              <Controller
                control={control}
                name="weighedAmount"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    autoComplete="off"
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    placeholder="0.00"
                    suffix=" g"
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.weighedAmount && (
                <InputError>{errors?.weighedAmount?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Perda</label>
              <Controller
                control={control}
                name="lostAmount"
                defaultValue={0}
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    autoComplete="off"
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    placeholder="0.00"
                    suffix=" g"
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.lostAmount && (
                <InputError>{errors?.lostAmount?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Código possível perda (opcional)</label>
              <Controller
                control={control}
                name="lostCode"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.lostCode && (
                <InputError>{errors?.lostCode?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Quantidade final</label>
              <Controller
                control={control}
                name="endAmount"
                defaultValue={0}
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    autoComplete="off"
                    decimalScale={2}
                    decimalSeparator=","
                    disabled
                    onBlur={onBlur}
                    onValueChange={({ floatValue }) => onChange(floatValue)}
                    placeholder="0.00"
                    suffix=" g"
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.endAmount && (
                <InputError>{errors?.endAmount?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
            <Form.Field />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Código da balança</label>
              <Controller
                control={control}
                name="balanceCode"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    autoComplete="off"
                    disabled={isLoading}
                    fluid
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.balanceCode && (
                <InputError>{errors?.balanceCode?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Responsável</label>
              <Controller
                control={control}
                name="responsible"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <EmployeeSearch
                    autoComplete="off"
                    disabled={isLoading}
                    loading={isLoading}
                    onBlur={onBlur}
                    onChange={onChange}
                    placeholder="Busque..."
                    value={value}
                  />
                )}
              />
              {errors.responsible && (
                <InputError>{errors?.responsible?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
          </Form.Group>
          <Form.Field width={10}>
            <label>Estudo</label>
            <Controller
              control={control}
              name="project"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Select
                  autoComplete="password"
                  defaultValue=""
                  instanceId="project"
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  name="project"
                  onBlur={onBlur}
                  onChange={onChange}
                  options={projectOptions}
                  placeholder="Selecione"
                  value={value}
                />
              )}
            />
            {errors.project && (
              <InputError>{errors?.project?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field width={10}>
            <label>Observações</label>
            <Controller
              control={control}
              name="notes"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Input
                  autoComplete="off"
                  disabled={isLoading}
                  fluid
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.notes && <InputError>{errors?.notes?.message}</InputError>}
          </Form.Field>
        </Form>
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isLoading}
          form={formId}
          loading={isLoading}
          primary
          type="submit"
        >
          Manipular
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default SampleManipulationModalForm;
