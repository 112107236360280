import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';

import ContractForm from 'components/forms/ContractForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useUpdateContract } from 'data/contract';
import { useContractWithDocument } from 'queries/contract';

export default function EditContract() {
  const { id: contractId } = useParams();
  const history = useHistory();

  const { data, isloading, error } = useContractWithDocument(contractId);
  const { mutate: updateContract, isLoading: isUpdating } = useUpdateContract({
    onSuccess: () => {
      toast.success('Contrato atualizado com sucesso!');
      history.push(`/contratos/${contractId}`);
    },
  });

  function handleSubmit(values) {
    const contractInput = contractInputMapper(values);
    updateContract({ contractId, ...contractInput });
  }

  return (
    <Base text loading={isloading} error={error}>
      <Container>
        <Grid>
          <Grid.Column>
            {data ? (
              <>
                <Header as="h1">Edição de contrato</Header>
                <Section>
                  <Section.Content>
                    <ContractForm
                      contract={data?.contract}
                      documentInput={data?.documentInput}
                      edit
                      isLoading={isUpdating}
                      onSubmit={handleSubmit}
                    />
                  </Section.Content>
                </Section>
              </>
            ) : null}
          </Grid.Column>
        </Grid>
      </Container>
    </Base>
  );
}

function contractInputMapper(formValues) {
  return {
    representativeId: parseInt(formValues.representative.value, 10),
    contractType: parseInt(formValues.contractType, 10),
    currency: parseInt(formValues.currency, 10),
    number: formValues.number,
    signatureDate: formValues.signatureDate
      ? DateTime.fromJSDate(formValues.signatureDate).toISO()
      : null,
    requiredDate: formValues.requiredDate
      ? DateTime.fromJSDate(formValues.requiredDate).toISO()
      : null,
    parentContractId: formValues?.parentContract?.value
      ? parseInt(formValues?.parentContract?.value, 10)
      : null,
    quotationId: formValues?.quotation?.value
      ? parseInt(formValues?.quotation?.value, 10)
      : null,
    projectQuantity: parseInt(formValues.projectQuantity, 10),
    notes: formValues.notes || null,
    documentId: formValues?.documentId
      ? parseInt(formValues?.documentId, 10)
      : null,
    workOrder: formValues?.workOrder || null,
  };
}
