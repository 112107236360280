import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';

import { projectKeys } from 'queries/project';
import api, { emptyPagination, Response } from 'util/api';

const shipmentKeys = {
  all: ['shipments'],
} as const;

type AddShipmentInput = {
  contractId: number;
  items: {
    contractProductId: number;
    quantity: number;
  }[];
  shippingForecast?: Date | null;
  address?: string | null;
};

export function useAddShipment(
  options?: UseMutationOptions<number, AxiosError, AddShipmentInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(addShipment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(shipmentKeys.all);
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('contract');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const addShipment = async (input: AddShipmentInput) => {
  const { headers } = await api.post(`/shipments`, input);
  const { location } = headers;
  const shipmentId = parseInt(location.split('/').pop(), 10);

  return shipmentId;
};

type SendShipmentInput = {
  address: string;
  shipmentId: number;
  shippingDate: Date;
};

export function useSendShipment(
  options?: UseMutationOptions<void, AxiosError, SendShipmentInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(sendShipment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(shipmentKeys.all);
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('contract');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const sendShipment = async ({ shipmentId, ...input }: SendShipmentInput) => {
  await api.post(`/shipments/${shipmentId}/send`, input);
};

type ChangeShippingInput = {
  address: string;
  shippingDate: Date;
  shipmentId: number;
};

export function useChangeShipping(
  options?: UseMutationOptions<void, AxiosError, ChangeShippingInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changeShipping, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(shipmentKeys.all);
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('contract');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const changeShipping = async ({
  shipmentId,
  ...input
}: ChangeShippingInput) => {
  await api.patch(`/shipments/${shipmentId}/shipping`, input);
};

type ChangeShippingForecastInput = {
  shippingForecast: Date | null;
  shipmentId: number;
};

export function useChangeShippingForecast(
  options?: UseMutationOptions<void, AxiosError, ChangeShippingForecastInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changeShippingForecast, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(shipmentKeys.all);
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('contract');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const changeShippingForecast = async ({
  shipmentId,
  ...input
}: ChangeShippingForecastInput) => {
  await api.patch(`/shipments/${shipmentId}/shipping-forecast`, input);
};

type ShipmentFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  status?: 'pending' | 'sent';
};

export function useInfiniteShipments(filter: ShipmentFilter) {
  const result = useInfiniteQuery(
    [...shipmentKeys.all, 'infinite', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getShipments({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const shipments =
    result?.data?.pages
      ?.map((group) => group?.data?.map((shipment) => shipment))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    shipments,
  };
}

type ShipmentItem = {
  id: number;
  number: number;
  quantity: number;
  product: {
    id: number;
    description: string;
    unit: {
      id: number;
      abbreviation: string;
      description: string;
    };
  };
};

export type ShipmentsQuery = {
  id: number;
  address: string | null;
  contract: {
    id: number;
    number: string;
  };
  customer: {
    id: number;
    tradingName: string;
  };
  items: ShipmentItem[];
  invoiceDocumentId: number | null;
  contractId: number;
  shippingDate: string | null;
  shippingForecast: string | null;
};

async function getShipments(params: ShipmentFilter) {
  const { data } = await api.get<Response<ShipmentsQuery>>(`/shipments`, {
    params,
  });
  return {
    data: data.data,
    pagination: data.pagination,
  };
}
