import React, { forwardRef } from 'react';

import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Form, Input, Divider } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';

export type CustomerAddressFormValues = {
  street?: string;
  streetNumber?: string;
  neighborhood?: string;
  zipcode?: string;
  complementary?: string;
  city?: string;
  state?: string;
  country?: string;
};

export type CustomerAddressFormProps = {
  defaultValues: Partial<CustomerAddressFormValues>;
  onSubmit: (values: CustomerAddressFormValues) => void;
};

const MASK_CEP = '#####-###';
const CustomerAddressForm = forwardRef<
  HTMLFormElement,
  CustomerAddressFormProps
>(({ defaultValues, onSubmit }, ref) => {
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  return (
    <Form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Field width={11}>
          <label>Rua</label>
          <Controller
            control={control}
            name="street"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.street && <InputError>{errors?.street?.message}</InputError>}
        </Form.Field>
        <Form.Field width={5}>
          <label>Número</label>
          <Controller
            control={control}
            name="streetNumber"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.streetNumber && (
            <InputError>{errors.streetNumber}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={11}>
          <label>Bairro</label>
          <Controller
            control={control}
            name="neighborhood"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.neighborhood && (
            <InputError>{errors?.neighborhood?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field width={5}>
          <label>CEP</label>
          <Controller
            control={control}
            name="zipcode"
            render={({ onChange, onBlur, value }) => (
              <NumberFormat
                onValueChange={(data) => {
                  onChange(data.value);
                }}
                onBlur={onBlur}
                value={value}
                format={MASK_CEP}
                mask="_"
                isNumericString
                allowEmptyFormatting
                autoComplete="off"
              />
            )}
          />
          {errors.zipcode && (
            <InputError>{errors?.zipcode?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Complemento</label>
        <Controller
          control={control}
          name="complementary"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              autoComplete="new-password"
              fluid
            />
          )}
        />
        {errors.complementary && (
          <InputError>{errors.complementary}</InputError>
        )}
      </Form.Field>
      <Form.Group>
        <Form.Field width={7}>
          <label>Cidade</label>
          <Controller
            control={control}
            name="city"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.city && <InputError>{errors?.city?.message}</InputError>}
        </Form.Field>
        <Form.Field width={3}>
          <label>Estado</label>
          <Controller
            control={control}
            name="state"
            render={({ onChange, onBlur, value }) => (
              <Input
                placeholder="UF"
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                maxLength="2"
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.state && <InputError>{errors?.state?.message}</InputError>}
        </Form.Field>
        <Form.Field width={6}>
          <label>País</label>
          <Controller
            control={control}
            name="country"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.country && (
            <InputError>{errors?.country?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Divider hidden />
    </Form>
  );
});

export default CustomerAddressForm;
