import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { CollectionQuery } from 'data/collection';

type ProjectCollectionTableProps = {
  isLoading?: boolean;
  items: CollectionQuery[];
};

function ProjectCollectionTable({
  isLoading,
  items = [],
}: ProjectCollectionTableProps) {
  return (
    <Section>
      <Section.Header>
        <PaginationInfo
          description="Coletas"
          pagination={{
            page: 1,
            pageSize: items.length,
            total: items.length,
            totalPages: 1,
          }}
        />
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" singleLine fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Espécie</Table.HeaderCell>
              <Table.HeaderCell>Região</Table.HeaderCell>
              <Table.HeaderCell>Período</Table.HeaderCell>
              <Table.HeaderCell>Chegada</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. associada
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={11} rows={10} />}
            {!isLoading && items
              ? items.map((collection) => {
                  const {
                    id,
                    crop,
                    target,
                    period,
                    region,
                    startDate,
                    endDate,
                    collector,
                    sorting,
                    totalAssociated,
                  } = collection;

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>
                        <Link to="/coletas">
                          <Text variant="primary">{id}</Text>
                        </Link>
                      </Table.Cell>
                      <Table.Cell title={target}>
                        <Text variant="primary">{target}</Text>
                        <Text variant="secondary">
                          {`${crop}${
                            period === 'Safrinha' ? ` (${period})` : ''
                          }`}
                        </Text>
                      </Table.Cell>
                      <Table.Cell title={region}>
                        <Text variant="primary">{region}</Text>
                        <Text variant="secondary">{collector}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {DateTime.fromISO(startDate)
                            .setLocale('pt-BR')
                            .toFormat('dd/MM/yyyy')}
                        </Text>
                        <Text variant="secondary">
                          {endDate
                            ? `${DateTime.fromISO(endDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')}`
                            : ''}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {sorting
                            ? DateTime.fromISO(sorting.arrivalDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')
                            : null}
                        </Text>
                        <Text variant="secondary">
                          {sorting
                            ? DateTime.fromISO(sorting.sortingDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')
                            : null}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">{totalAssociated}</Text>
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

export default ProjectCollectionTable;
