import * as React from 'react';

import toast from 'react-hot-toast';
import { Button, Divider, Form, Grid, Header, Input } from 'semantic-ui-react';

import FinancesTable from 'components/data/FinancesTable';
import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import DivisionSelect from 'components/inputs/DivisionSelect';
import ProjectStageSelect from 'components/inputs/ProjectStageSelect';
import { CustomerQuery } from 'queries/customer';
import {
  useExportProjectsInDebt,
  useInfiniteProjectsInDebt,
} from 'queries/project';

type Option = { value: number; label: string };

export default function FinancesPage() {
  const [orderBy, setOrderBy] = React.useState<string>('contract');
  const [sort, setSort] = React.useState<'asc' | 'desc'>('asc');
  const [date, setDate] = React.useState<Date | undefined>();
  const [customers, setCustomers] = React.useState<CustomerQuery[] | null>(
    null,
  );
  const [projectSearch, setProjectSearch] = React.useState('');
  const [divisions, setDivisions] = React.useState<Option[] | null>(null);
  const [contractSearch, setContractSearch] = React.useState('');
  const [projectStages, setProjectStages] = React.useState<Option[] | null>(
    null,
  );

  function handleHarvestChange(newDate: Date) {
    setDate(newDate);
  }

  function handleCustomerChange(options: CustomerQuery[]) {
    setCustomers(options);
  }

  function handleDivisionChange(options: Option[]) {
    setDivisions(options);
  }

  function handleProjectStageChange(options: Option[]) {
    setProjectStages(options);
  }

  const {
    mutate: exportProjectsInDebt,
    isLoading: isExportingProjectsInDebt,
  } = useExportProjectsInDebt({
    onSuccess: () => {
      toast.success('Estudos com pagamentos em aberto exportados!');
    },
  });

  const {
    projects,
    pagination,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteProjectsInDebt({
    orderBy,
    sort,
    harvest: date?.getFullYear(),
    customerIds: customers?.map((option) => option.id) || null,
    projectCode: projectSearch || null,
    divisions: divisions?.map((option) => option.value) || null,
    contractNumber: contractSearch || null,
    projectStages: projectStages?.map((option) => option.value) || null,
  });

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column verticalAlign="middle">
          <Header as="h1">Financeiro</Header>
        </Grid.Column>
        <Grid.Column textAlign="right" verticalAlign="middle">
          <Button
            basic
            loading={isExportingProjectsInDebt}
            onClick={() =>
              exportProjectsInDebt({
                orderBy,
                sort,
                harvest: date?.getFullYear(),
                customerIds: customers?.map((option) => option.value) || null,
                projectCode: projectSearch || null,
                divisions: divisions?.map((option) => option.value) || null,
                contractNumber: contractSearch || null,
                projectStages:
                  projectStages?.map((option) => option.value) || null,
              })
            }
          >
            Exportar
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />

      <Grid.Row>
        <Grid.Column>
          <Form>
            <Form.Group>
              <Form.Field width={2}>
                <DatePicker
                  onChange={handleHarvestChange}
                  selected={date}
                  showYearPicker
                  placeholderText="Safra"
                  dateFormat="yyyy"
                  yearItemNumber={9}
                />
              </Form.Field>
              <Form.Field width={4}>
                <CustomerSearch
                  isClearable
                  isMulti
                  onChange={handleCustomerChange}
                  placeholder="Cliente"
                  value={customers}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Input
                  isClearable
                  onChange={(_, data) => setProjectSearch(data.value)}
                  placeholder="Estudo"
                  value={projectSearch}
                />
              </Form.Field>
              <Form.Field width={3}>
                <DivisionSelect
                  isClearable
                  isMulti
                  onChange={handleDivisionChange}
                  placeholder="Setor"
                  value={divisions}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Input
                  isClearable
                  onChange={(_, data) => setContractSearch(data.value)}
                  placeholder="Contrato"
                  value={contractSearch}
                />
              </Form.Field>
              <Form.Field width={3}>
                <ProjectStageSelect
                  isClearable
                  isMulti
                  onChange={handleProjectStageChange}
                  placeholder="Fase"
                  value={projectStages}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <FinancesTable
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            items={projects}
            onSort={(data) => {
              setOrderBy(data.orderBy);
              setSort(data.sort);
            }}
            orderBy={orderBy}
            pagination={pagination}
            sort={sort}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
