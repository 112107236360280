import { useQuery } from 'react-query';

import api, { Response } from 'util/api';

export type MeasurementUnitQuery = {
  id: number;
  description: string;
  abbreviation: string;
};

export type MeasurementUnitFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  description?: string;
};

const getMeasurementUnits = async (filter: MeasurementUnitFilter) => {
  const { data } = await api.get<Response<MeasurementUnitQuery>>(
    `/measurement-units`,
    { params: filter },
  );
  return {
    units: data.data,
    pagination: data.pagination,
  };
};

export function useQueryMeasurementUnits(filter: MeasurementUnitFilter) {
  return useQuery(['measurementUnits', filter], () =>
    getMeasurementUnits({ orderBy: 'description', sort: 'asc', ...filter }),
  );
}
