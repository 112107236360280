import React from 'react';

import { Grid, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Pagination from 'components/data/Pagination';
import LinkButton from 'components/inputs/LinkButton';

export default function PropertyTable({
  properties,
  pagination,
  onPageChange,
  onEditClick,
  loading,
}) {
  return (
    <>
      <Table size="small" basic="very" singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Propriedade</Table.HeaderCell>
            <Table.HeaderCell>Proprietário</Table.HeaderCell>
            <Table.HeaderCell>Município/UF</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && <LoadingRow columns={5} rows={5} />}
          {!loading &&
            properties?.map((property, index) => {
              const { id, name, owner, city, state } = property;

              return (
                <Table.Row key={id.toString()}>
                  <Table.Cell>{name}</Table.Cell>
                  <Table.Cell>{owner}</Table.Cell>
                  <Table.Cell>
                    {city} - {state.toUpperCase()}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <LinkButton
                      type="button"
                      onClick={() => onEditClick(property, index)}
                    >
                      Editar
                    </LinkButton>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row>
          <Grid.Column textAlign="right">
            <Pagination pagination={pagination} onPageChange={onPageChange} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
