import React from 'react';

import { Table, Icon } from 'semantic-ui-react';
import shortid from 'shortid';

import { formatToBr, formatToIso } from 'util/DateFormatter';

export default function InstallmentsTable({ installments = [], onRemove }) {
  return (
    <Table size="large" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left" width="2">
            Parcela
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right" width="2">
            Valor
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right" width="2">
            Previsão
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right" width="2" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {installments.map((installment, index) => (
          <Table.Row key={shortid.generate()}>
            <Table.Cell textAlign="left">{index + 1}</Table.Cell>
            <Table.Cell textAlign="right">{installment.maskedValue}</Table.Cell>
            <Table.Cell textAlign="right">
              {formatToBr(formatToIso(installment.paymentForecast))}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {onRemove ? (
                <Icon
                  name="remove"
                  onClick={() => onRemove(index)}
                  style={{ cursor: 'pointer' }}
                />
              ) : null}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
