import React from 'react';

import Select from 'react-select';

export default function ProductTypeSelect({ name, value, onChange, disabled }) {
  const options = [
    { value: 1, label: 'Biológico' },
    { value: 2, label: 'Químico' },
  ];

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder="Selecione..."
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      autoComplete="off"
    />
  );
}
