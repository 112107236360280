import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';
import * as yup from 'yup';

import ActivityTypeSelect from 'components/inputs/ActivityTypeSelect';
import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { emptyStringToNull } from 'util/validation';

const schema = yup
  .object({
    date: yup
      .date()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    type: yup
      .object()
      .required('Obrigatório')
      .transform(emptyStringToNull),
    description: yup
      .string()
      .optional()
      .nullable()
      .transform(emptyStringToNull),
  })
  .required(`Atividades é obrigatório`);

export const FORM_ID = 'form_activity_add';

export type ActivityAddFormValues = {
  date: Date;
  type: {
    value: number;
    label: string;
  };
  description?: string | null;
};

type ActivityAddFormProps = {
  onSubmit: (formValues: ActivityAddFormValues) => void;
  isLoading: boolean;
};

export default function ActivityAddForm({
  onSubmit,
  isLoading,
}: ActivityAddFormProps) {
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  return (
    <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Data prevista</label>
        <Controller
          control={control}
          name="date"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              onChange={onChange}
              onBlur={onBlur}
              selected={value}
              dateFormat="dd/MM/yyyy - EEEE"
            />
          )}
        />
        {errors?.date && <InputError>{errors?.date?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Tipo</label>
        <Controller
          control={control}
          name="type"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <ActivityTypeSelect
              name="type"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isLoading}
            />
          )}
        />
        {errors?.type && <InputError>{errors?.type?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Descrição (opcional)</label>
        <Controller
          control={control}
          name="description"
          defaultValue=""
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isLoading}
              autoComplete="off"
              fluid
            />
          )}
        />
      </Form.Field>
    </Form>
  );
}
