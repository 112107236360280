import * as React from 'react';

import { Divider, Grid, Header, Loader } from 'semantic-ui-react';

import UserTable from 'components/data/UserTable';
import ChangeDivisionsModal from 'components/modal/ChangeDivisionsModal';
import ChangePasswordModal from 'components/modal/ChangePasswordModal';
import ChangeRoleModal from 'components/modal/ChangeRoleModal';
import UserModal from 'components/modal/UserModal';
import { useInfiniteUsers, Users } from 'data/user';
import { useModalWithData } from 'hooks/useModal';

export default function UsersPage() {
  const roleModal = useModalWithData<Users>();
  const divisionsModal = useModalWithData<Users>();
  const passwordModal = useModalWithData<Users>();
  const userModal = useModalWithData<Users>();

  const {
    users,
    pagination,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteUsers({ pageSize: 10 });

  function handleChangeRoleClick(userId: number) {
    const foundUser = users.find((user) => user.id === userId);
    roleModal.open(foundUser);
  }

  function handleChangeDivisionsClick(userId: number) {
    const foundUser = users.find((user) => user.id === userId);
    divisionsModal.open(foundUser);
  }

  function handleChangePasswordClick(userId: number) {
    const foundUser = users.find((user) => user.id === userId);
    passwordModal.open(foundUser);
  }

  function handleEditClick(userId: number) {
    const foundUser = users.find((user) => user.id === userId);
    userModal.open(foundUser);
  }

  if (isLoading) {
    return (
      <Loader active size="large" style={{ marginTop: '200px' }}>
        Carregando...
      </Loader>
    );
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h1">Usuários</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row columns="equal">
          <Grid.Column>
            <UserTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              onChangeDivisionsClick={handleChangeDivisionsClick}
              onChangePasswordClick={handleChangePasswordClick}
              onChangeRoleClick={handleChangeRoleClick}
              onEditClick={handleEditClick}
              pagination={pagination}
              users={users}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {userModal.isOpen && userModal.data ? (
        <UserModal
          isOpen={userModal.isOpen}
          onClose={userModal.close}
          user={userModal.data}
        />
      ) : null}

      {roleModal.isOpen && roleModal.data ? (
        <ChangeRoleModal
          isOpen={roleModal.isOpen}
          onClose={roleModal.close}
          user={roleModal.data}
        />
      ) : null}

      {divisionsModal.isOpen && divisionsModal.data ? (
        <ChangeDivisionsModal
          isOpen={divisionsModal.isOpen}
          onClose={divisionsModal.close}
          user={divisionsModal.data}
        />
      ) : null}

      {passwordModal.isOpen && passwordModal.data ? (
        <ChangePasswordModal
          isOpen={passwordModal.isOpen}
          onClose={passwordModal.close}
          user={passwordModal.data}
        />
      ) : null}
    </>
  );
}
