import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { ProfitSharingProjectsQuery } from 'queries/project';
import { Pagination } from 'util/api';
import { capitalizeFirstLetter } from 'util/capitalizeFirstLetter';
import { formatCurrency, formatCurrencyWithSymbol } from 'util/numberFormat';

type ProfitSharingTableProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
  items: ProfitSharingProjectsQuery[];
  pagination: Pagination;
};

export default function ProfitSharingTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading = false,
  items,
  pagination,
}: ProfitSharingTableProps) {
  const isEmpty = !isLoading && items?.length === 0;
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Estudos" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Wrapper isEmpty={isEmpty}>
          <Table size="small" singleLine basic="very" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>Safra</Table.HeaderCell>
                <Table.HeaderCell width={2}>Cliente</Table.HeaderCell>
                <Table.HeaderCell width={3}>Estudo</Table.HeaderCell>
                <Table.HeaderCell>Setor</Table.HeaderCell>
                <Table.HeaderCell>Previsão</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Data de Entrega</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 1</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 2</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 3</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && <LoadingRow columns={11} rows={10} />}
              {!isLoading &&
                items.map((item) => {
                  const {
                    id,
                    harvest,
                    customer,
                    representative,
                    insideCode,
                    protocol,
                    division,
                    responsibleTechnician,
                    requiredDate,
                    reassignedDate,
                    deliveryDate,
                    status,
                    contract,
                    totalAmount,
                    installments,
                  } = item;
                  const forecastDate = reassignedDate || requiredDate;

                  const total = formatCurrencyWithSymbol(
                    totalAmount / 100,
                    contract.currency.id,
                  );
                  const installmentsFormatted = installments?.map(
                    ({ amount, paymentId, payment }) => {
                      return {
                        amount: formatCurrency(
                          amount / 100,
                          contract.currency.id,
                        ),
                        isPaid: !!paymentId,
                        billingDate: payment?.billingDate
                          ? DateTime.fromISO(payment.billingDate).toFormat(
                              'dd/MM/yyyy',
                            )
                          : null,
                      };
                    },
                  );

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>{harvest}</Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{customer.tradingName}</Text>
                        <Text variant="secondary">{representative.name}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          <Link to={`/estudos/${id}`}>{insideCode}</Link>
                        </Text>
                        <Text variant="secondary">{protocol}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{division.description}</Text>
                        <Text variant="secondary">
                          {responsibleTechnician.name}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {capitalizeFirstLetter(
                            DateTime.fromISO(forecastDate).toFormat(
                              'MMMM yyyy',
                            ),
                          )}
                        </Text>
                        <Text variant="secondary">
                          {reassignedDate ? 'Remanejado' : ''}
                        </Text>
                      </Table.Cell>
                      <Table.Cell title={status.details}>
                        <Tag color={status.color}>{status.description}</Tag>
                      </Table.Cell>
                      <Table.Cell>
                        {deliveryDate
                          ? capitalizeFirstLetter(
                              DateTime.fromISO(deliveryDate).toFormat(
                                'MMMM yyyy',
                              ),
                            )
                          : null}
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">{total}</Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {installmentsFormatted?.[0]?.amount || ''}
                        </Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[0]?.isPaid ? (
                            installmentsFormatted?.[0]?.billingDate
                          ) : (
                            <span style={{ color: '#E04A3A' }}>
                              {installmentsFormatted?.[0]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {installmentsFormatted?.[1]?.amount || ''}
                        </Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[1]?.isPaid ? (
                            installmentsFormatted?.[1]?.billingDate
                          ) : (
                            <span style={{ color: '#E04A3A' }}>
                              {installmentsFormatted?.[1]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {installmentsFormatted?.[2]?.amount || ''}
                        </Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[2]?.isPaid ? (
                            installmentsFormatted?.[2]?.billingDate
                          ) : (
                            <span style={{ color: '#E04A3A' }}>
                              {installmentsFormatted?.[2]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
        <Divider />
        {hasNextPage ? (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Carregando...' : ''}
                  {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Text variant="secondary">Nada mais para mostrar</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Section.Content>
    </Section>
  );
}

const Wrapper = styled.div<{ isEmpty: boolean }>`
  ${({ isEmpty }) => css`
    ${!isEmpty
      ? css`
          height: auto;
          min-height: 200;
          overflow-x: scroll;
        `
      : css`
          overflow: scroll;
        `}
  `}
`;
