import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 56px;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: height 0.2s ease;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContentInfo = styled.div``;

export const ContentPrimary = styled.span`
  font-size: 14px;
  font-weight: bold;
`;

export const ContentSecondary = styled.div`
  font-size: 12px;
  color: #999;

  button {
    border: 0;
    background: transparent;
    color: #e57878;
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const ContentStatus = styled.div`
  margin: 0 16px;
`;
