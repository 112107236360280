import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Table } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { getProjectStageColor } from 'data/project';
import { ProjectsInDebtQuery } from 'queries/project';
import theme from 'styles/theme';
import { Pagination } from 'util/api';
import { formatCurrency, formatCurrencyWithSymbol } from 'util/numberFormat';

type ProjectsInDebtTableProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
  items: ProjectsInDebtQuery[];
  onSort: ({
    orderBy,
    sort,
  }: {
    orderBy: string;
    sort: 'asc' | 'desc';
  }) => void;
  orderBy: string;
  pagination: Pagination;
  sort: 'asc' | 'desc';
};

const direction = {
  asc: 'ascending',
  desc: 'descending',
} as const;

const changeDirection = {
  asc: 'desc',
  desc: 'asc',
} as const;

export default function FinancesTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading = false,
  items,
  onSort,
  orderBy,
  pagination,
  sort,
}: ProjectsInDebtTableProps) {
  const isEmpty = !isLoading && items?.length === 0;
  return (
    <Section>
      <Section.Header>
        <PaginationInfo
          description="Estudos com pagamentos em aberto"
          pagination={pagination}
        />
      </Section.Header>
      <Section.Content>
        <Wrapper isEmpty={isEmpty}>
          <Table size="small" singleLine basic="very" sortable unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={orderBy === 'harvest' ? direction[sort] : null}
                  onClick={() =>
                    onSort({
                      orderBy: 'harvest',
                      sort: changeDirection[sort],
                    })
                  }
                >
                  Safra
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={orderBy === 'customer' ? direction[sort] : null}
                  onClick={() =>
                    onSort({
                      orderBy: 'customer',
                      sort: changeDirection[sort],
                    })
                  }
                >
                  Cliente
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={orderBy === 'project' ? direction[sort] : null}
                  onClick={() =>
                    onSort({
                      orderBy: 'project',
                      sort: changeDirection[sort],
                    })
                  }
                >
                  Estudo
                </Table.HeaderCell>
                <Table.HeaderCell
                  sorted={orderBy === 'division' ? direction[sort] : null}
                  onClick={() =>
                    onSort({
                      orderBy: 'division',
                      sort: changeDirection[sort],
                    })
                  }
                >
                  Setor
                </Table.HeaderCell>
                <Table.HeaderCell>Previsão</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={orderBy === 'contract' ? direction[sort] : null}
                  onClick={() =>
                    onSort({
                      orderBy: 'contract',
                      sort: changeDirection[sort],
                    })
                  }
                >
                  Contrato
                </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Total</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 1</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 2</Table.HeaderCell>
                <Table.HeaderCell textAlign="right">Parcela 3</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && <LoadingRow columns={9} rows={10} />}
              {!isLoading &&
                items.map((item) => {
                  const {
                    id,
                    contract,
                    customer,
                    division,
                    harvest,
                    insideCode,
                    installments,
                    projectStage,
                    protocol,
                    representative,
                    forecastDate,
                    isReassigned,
                    responsibleTechnician,
                    totalAmount,
                  } = item;

                  const total = formatCurrencyWithSymbol(
                    totalAmount / 100,
                    contract.currency.id,
                  );
                  const installmentsFormatted = installments?.map(
                    ({ amount, paymentId, payment }) => {
                      return {
                        amount: formatCurrency(
                          amount / 100,
                          contract.currency.id,
                        ),
                        isPaid: !!paymentId,
                        billingDate: payment?.billingDate
                          ? DateTime.fromISO(payment.billingDate).toFormat(
                              'dd/MM/yyyy',
                            )
                          : null,
                      };
                    },
                  );

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>
                        <Text>{harvest}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>{customer.tradingName}</Text>
                        <Text variant="secondary">{representative.name}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          <Link to={`/estudos/${id}`}>{insideCode}</Link>
                        </Text>
                        <Text variant="secondary">{protocol}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>{division.description}</Text>
                        <Text variant="secondary">
                          {responsibleTechnician?.name}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          {DateTime.fromISO(forecastDate).toFormat(
                            'dd/MM/yyyy',
                          )}
                        </Text>
                        <Text variant="secondary">
                          {isReassigned ? 'Remanejado' : ''}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text>
                          <Link to={`/contratos/${contract.id}`}>
                            {contract.number}
                          </Link>
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Tag color={getProjectStageColor(projectStage.id)}>
                          {projectStage.description}
                        </Tag>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text>{total}</Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text>{installmentsFormatted?.[0]?.amount || ''}</Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[0]?.isPaid ? (
                            installmentsFormatted?.[0]?.billingDate
                          ) : (
                            <span style={{ color: theme.colors.red }}>
                              {installmentsFormatted?.[0]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text>{installmentsFormatted?.[1]?.amount || ''}</Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[1]?.isPaid ? (
                            installmentsFormatted?.[1]?.billingDate
                          ) : (
                            <span style={{ color: theme.colors.red }}>
                              {installmentsFormatted?.[1]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text>{installmentsFormatted?.[2]?.amount || ''}</Text>
                        <Text variant="secondary">
                          {installmentsFormatted?.[2]?.isPaid ? (
                            installmentsFormatted?.[2]?.billingDate
                          ) : (
                            <span style={{ color: theme.colors.red }}>
                              {installmentsFormatted?.[2]?.amount && 'Pendente'}
                            </span>
                          )}
                        </Text>
                      </Table.Cell>
                      {/* <div
                    style={{ flex: 1, flexDirection: 'row', marginTop: 8 }}
                  >
                    {installmentsFormatted.map(({ amount, isPaid }) => (
                      <span style={{ marginRight: 4 }}>
                        <Tag color={isPaid ? 'green' : 'grey'}>{amount}</Tag>
                      </span>
                    ))}
                  </div> */}
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Wrapper>
        <Divider hidden />
        {hasNextPage ? (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Carregando...' : ''}
                  {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Text variant="secondary">Nada mais para mostrar</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Section.Content>
    </Section>
  );
}

const Wrapper = styled.div<{ isEmpty: boolean }>`
  ${({ isEmpty }) => css`
    ${!isEmpty
      ? css`
          height: auto;
          min-height: 200;
          overflow-x: scroll;
        `
      : css`
          overflow: scroll;
        `}
  `}
`;
