import React from 'react';

import { Button, Icon } from 'semantic-ui-react';

import { PROJECT_STAGE } from 'data/project';

export default function UpdateProjectStageButton({ stage, onClick }) {
  function handleButtonText() {
    switch (stage) {
      case PROJECT_STAGE.enabling:
        return 'Habilitar';
      case PROJECT_STAGE.preparatory:
        return 'Concluir preparatória';
      case PROJECT_STAGE.deployment:
        return 'Concluir implantação';
      case PROJECT_STAGE.evaluation:
        return 'Concluir avaliação';
      case PROJECT_STAGE.harvest:
        return 'Concluir análise';
      default:
        return '';
    }
  }

  return (
    <Button type="button" onClick={onClick} primary>
      <Icon name="check" />
      {handleButtonText()}
    </Button>
  );
}
