import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { procedureKeys } from 'queries/procedure';
import api from 'util/api';

type ProcedureInput = {
  code: string;
  revision: number;
  title: string;
  objective: string;
  documentId: number;
};

export function useCreateProcedure(
  options?: UseMutationOptions<void, AxiosError, ProcedureInput, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(createProcedure, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(procedureKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createProcedure(input: ProcedureInput) {
  await api.post(`/procedures`, input);
}
