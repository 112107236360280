import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export const SampleManipulation = yup.object().shape({
  date: yup.date().required('Obrigatório'),
  time: yup.date().required('Obrigatório'),
  startAmount: yup.number().required('Obrigatório'),
  weighedAmount: yup
    .number()
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
  lostAmount: yup.number().required('Obrigatório'),
  lostCode: yup
    .number()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  endAmount: yup
    .number()
    .required('Obrigatório')
    .min(0.0, 'Não pode ser menor que zero'),
  balanceCode: yup.string().required('Obrigatório'),
  responsible: yup
    .object()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  project: yup
    .object()
    .required('Obrigatório')
    .typeError('Obrigatório'),
  notes: yup
    .string()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
});
