import React from 'react';

import fileSize from 'filesize';
import { Button, Modal, Table } from 'semantic-ui-react';

import LinkButton from 'components/inputs/LinkButton';
import { downloadDocument } from 'hooks/document';
import { Document } from 'util/Document';

export type AttachmentListModalProps = {
  documents?: Document[];
  isOpen: boolean;
  onClose: () => void;
};

export default function AttachmentListModal({
  documents,
  isOpen,
  onClose,
}: AttachmentListModalProps) {
  return (
    <Modal size="small" onClose={onClose} open={isOpen}>
      <Modal.Header>Anexos</Modal.Header>
      <Modal.Content>
        <Table basic="very" fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left" width={1}>
                #
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Nome</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Tamanho</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {documents?.map((document, index) => (
              <Table.Row>
                <Table.Cell textAlign="left" width={1}>
                  {index + 1}
                </Table.Cell>
                <Table.Cell textAlign="left">{document.name}</Table.Cell>
                <Table.Cell textAlign="right">
                  {fileSize(document.size)}
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <LinkButton
                    type="button"
                    onClick={() => downloadDocument(document.id)}
                  >
                    Baixar
                  </LinkButton>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
