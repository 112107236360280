import React from 'react';

import Select, { ValueType } from 'react-select';

export type MonthOption = { value: number; label: string };

type MonthSelectProps = {
  disabled?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: ValueType<MonthOption>) => void;
  placeholder?: string;
  value: MonthOption;
};

const MONTHS: Record<number, string> = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
} as const;

const options = Object.entries(MONTHS).map(([value, key]) => ({
  value: parseInt(value, 10),
  label: key,
}));

const defaultValue = options[0];

export default function MonthSelect({
  disabled = false,
  name,
  onBlur,
  onChange,
  placeholder = 'Selecione',
  value = defaultValue,
}: MonthSelectProps) {
  return (
    <Select
      instanceId={name}
      isDisabled={disabled}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}

export function getOptionByMonth(month: number) {
  const foundOption = options.find((option) => option.value === month);

  if (!foundOption) {
    return null;
  }

  return foundOption;
}
