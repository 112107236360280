import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Modal, Button, Divider, Form, Header, Input } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import { useCreateMeasurementUnit } from 'mutations/measurementUnit';

import schema from './validation';

export type MeasurementUnitFormValues = {
  description: string;
  abbreviation: string;
};

export type MeasurementUnitModalFormProps = {
  error?: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (values: MeasurementUnitFormValues) => void;
};

const defaultValues = {
  description: '',
  abbreviation: '',
};

const formId = 'form_unit';

export default function MeasurementUnitModalForm({
  isOpen,
  onClose,
}: MeasurementUnitModalFormProps) {
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
  });

  const { mutate: createUnit, isLoading } = useCreateMeasurementUnit({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Unidade adicionada com sucesso!');
    onClose();
  }

  function onSubmit(values: MeasurementUnitFormValues) {
    createUnit({ ...values });
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Novo unidade</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Header as="h3">Unidade</Header>
          <Form.Field>
            <label>Descrição</label>
            <Controller
              control={control}
              name="description"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autoComplete="new-password"
                  disabled={isLoading}
                  fluid
                />
              )}
            />
            {errors.description && (
              <InputError>{errors?.description?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Abreviatura</label>
            <Controller
              control={control}
              name="abbreviation"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autoComplete="new-password"
                  disabled={isLoading}
                  fluid
                />
              )}
            />
            {errors.abbreviation && (
              <InputError>{errors?.abbreviation?.message}</InputError>
            )}
          </Form.Field>
          <Divider hidden />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
