import React from 'react';

import Select from 'react-select';
import { ValueType } from 'react-select/src/types';

type Option = {
  value: number;
  label: string;
};

type ContractTypeSelectProps = {
  disabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: ValueType<Option>) => void;
  placeholder?: string;
  value: Option;
};

const options = [
  { value: 1, label: 'Serviço' },
  { value: 4, label: 'Produto' },
];

export default function ContractTypeSelect({
  disabled = false,
  isClearable = false,
  isMulti = false,
  name,
  onBlur,
  onChange,
  placeholder = 'Selecione',
  value,
}: ContractTypeSelectProps) {
  return (
    <Select
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value}
    />
  );
}
