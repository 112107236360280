import { useInfiniteQuery, useQuery } from 'react-query';

import api, { BasicQuery, Response } from 'util/api';

type ProductsQueryCategory = {
  id: number;
  description: string;
  insideCode: string;
};

type ProductsQueryFormulation = {
  id: number;
  code: string;
  description: string;
  type: number;
};

type ProductsQueryProductCategory = {
  id: number;
  description: string;
  insideCode: string;
};

type ProductsQueryIngredientsChemicalGroup = {
  id: number;
  description: string;
};

type ProductsQueryIngredientsIngredient = {
  id: number;
  name: string;
  chemicalGroup: ProductsQueryIngredientsChemicalGroup;
  categories: ProductsQueryProductCategory[];
};

type ProductsQueryIngredients = {
  id: number;
  productId: number;
  concentration: number | null;
  measurementUnit: number | null;
  ingredient: ProductsQueryIngredientsIngredient;
};

export type ProductsQuery = {
  id: number;
  name: string;
  type: number;
  ret: string | null;
  retStage: number | null;
  retExpirationDate: string | null;
  mapaRegister: string | null;
  category: ProductsQueryCategory;
  formulation: ProductsQueryFormulation;
  ingredients: ProductsQueryIngredients[];
};

export type ProductFilter = BasicQuery & { name?: string };

const getProducts = async (filter: ProductFilter) => {
  const { data } = await api.get<Response<ProductsQuery>>('/products', {
    params: { orderBy: 'name', sort: 'asc', ...filter },
  });
  const products = data.data;
  return {
    products,
    pagination: data.pagination,
  };
};

export function useProducts(filter: ProductFilter) {
  const result = useQuery(['products', filter], () => getProducts(filter));
  return {
    ...result,
    products: result.data?.products,
    pagination: result.data?.pagination,
  };
}

export function useInfiniteProducts(filter: ProductFilter) {
  return useInfiniteQuery(
    ['products', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getProducts({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );
}

export const PRODUCT_TYPE_BIOLOGICAL = 1;
export const PRODUCT_TYPE_CHEMICAL = 2;

export function formatProductType(type: number) {
  return type === PRODUCT_TYPE_BIOLOGICAL ? 'Biológico' : 'Químico';
}

export function formatRetStage(retStage: number) {
  switch (retStage) {
    case 1:
      return 'I';
    case 2:
      return 'II';
    case 3:
      return 'III';
    default:
      return '';
  }
}
