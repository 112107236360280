import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useUpdateProject } from 'mutations/project';

type ProjectReassignmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  projectId: number;
  reassignedDate?: Date;
};

type FormValues = {
  reassignedDate: Date;
};

const schema = yup
  .object({
    reassignedDate: yup
      .date()
      .optional()
      .nullable(),
  })
  .optional();

const formId = 'form_project_reassignment';

export default function ProjectReassignmentModal({
  isOpen,
  onClose,
  onSuccess,
  projectId,
  reassignedDate,
}: ProjectReassignmentModalProps) {
  const defaultValues = { reassignedDate };
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const { mutate: reassignDate, isLoading } = useUpdateProject({
    onSuccess: () => onSuccess('Responsável técnico alterado com sucesso!'),
  });

  function onSubmit(values: FormValues) {
    reassignDate({
      reassignedDate: values.reassignedDate || null,
      projectId,
    });
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Remanejar data requerida</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data remanejada</label>
                    <Controller
                      control={control}
                      name="reassignedDate"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isLoading}
                        />
                      )}
                    />
                    {errors.reassignedDate?.value && (
                      <InputError>
                        {errors?.reassignedDate?.value?.message}
                      </InputError>
                    )}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Remanejar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
