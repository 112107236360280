import React from 'react';

import { createMoney } from '@easymoney/money';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'react-currency-input';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import {
  Modal,
  Button,
  Form,
  Input,
  Message,
  Icon,
  Grid,
  Divider,
} from 'semantic-ui-react';
import * as yup from 'yup';

import TextInfo from 'components/data/TextInfo';
import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import { useUpdateQuotationPayment } from 'mutations/quotation';
import { formatWithSymbol, getIsoCode } from 'util/Currency';
import { emptyStringToUndefined } from 'util/validation';

const validationSchema = yup.object({
  paymentType: yup.string().required('Obrigatório'),
  payments: yup.array().when('paymentType', {
    is: (val) => val === 'amount',
    then: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .required('Obrigatório')
            .min(1, 'Maior que 0')
            .transform(emptyStringToUndefined),
          description: yup.string().required('Obrigatório'),
          percent: yup
            .number()
            .notRequired()
            .optional()
            .nullable()
            .transform(emptyStringToUndefined),
        }),
      )
      .required(),
    otherwise: yup
      .array()
      .of(
        yup.object().shape({
          amount: yup
            .number()
            .notRequired()
            .optional()
            .nullable()
            .transform(emptyStringToUndefined),
          description: yup.string().required('Obrigatório'),
          percent: yup
            .number()
            .required('Obrigatório')
            .min(1, 'Maior que 0')
            .max(100, 'Menor ou igual a 100')
            .transform(emptyStringToUndefined),
        }),
      )
      .required(),
  }),
});

export default function QuotationPaymentFormModal({
  quotation = undefined,
  open,
  onClose,
}) {
  const [error, setError] = React.useState('');
  const formRef = React.useRef(null);

  const moneyCurrency = getIsoCode(quotation.currency);
  const totalAmountMoney = createMoney({
    amount: quotation.hasDiscount
      ? quotation.totalAmountWithDiscount
      : quotation.totalAmount,
    currency: moneyCurrency,
  });

  const defaultValues = {
    ...mapToForm(quotation),
    paymentType: quotation.paymentType,
  };
  const resolver = yupResolver(validationSchema);

  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'payments',
  });

  const watchPayments = watch('payments');
  const watchPaymentType = watch('paymentType');

  const updateQuotationPayments = useUpdateQuotationPayment({
    onSuccess: () => onSuccess('Pagamento salvo!'),
  });

  function onSuccess(message) {
    toast.success(message);
    onClose();
  }

  function handleChangePercent(value, index) {
    if (!(value !== undefined)) {
      setValue(`payments[${index}].amount`, '');
      return '';
    }

    if (value === 0) {
      setValue(`payments[${index}].amount`, 0);
      return 0;
    }

    const percent = parseFloat(value) / 100;
    const paymentAmountMoney = totalAmountMoney.multiply(percent); // percentage(percent, quotation.totalValue);
    const paymentAmount = parseInt(paymentAmountMoney.getAmount(), 10) / 100;

    setValue(`payments[${index}].amount`, paymentAmount);

    return value;
  }

  function handleChangeAmount(value, index) {
    if (!(value !== undefined)) {
      setValue(`payments[${index}].amount`, '');
      return '';
    }

    if (value === 0) {
      setValue(`payments[${index}].amount`, 0);
      return 0;
    }

    return value;
  }

  function onSubmit(values) {
    if (values.paymentType === 'percent' && !isPercentageValid(values)) {
      setError('A soma das parcelas precisa ser 100% do valor total');
      return;
    }

    if (values.paymentType === 'amount' && !isAmountValid(values)) {
      setError('A soma das parcelas precisa ser o valor total do orçamento');
      return;
    }

    setError('');

    if (values.paymentType === 'percent') {
      const payments = values.payments?.map((payment, index) => ({
        amount: 0,
        description: payment.description,
        installment: index + 1,
        percent: payment.percent / 100,
      }));
      const input = {
        payments,
        paymentType: values.paymentType,
        quotationId: quotation.id,
      };

      updateQuotationPayments.mutate(input);
    }

    if (values.paymentType === 'amount') {
      const payments = values.payments?.map((payment, index) => ({
        amount: payment.amount * 100,
        description: payment.description,
        installment: index + 1,
        percent: 0,
      }));
      const input = {
        payments,
        paymentType: values.paymentType,
        quotationId: quotation.id,
      };

      updateQuotationPayments.mutate(input);
    }
  }

  function isPercentageValid(values) {
    const totalPercent = values.payments?.reduce(
      (total, { percent }) => total + parseInt(percent, 10),
      0,
    );

    return totalPercent === 100;
  }

  function isAmountValid(values) {
    const paymentAmounts = values.payments?.reduce(
      (total, { amount }) => total + amount * 100,
      0,
    );
    return parseInt(totalAmountMoney.getAmount(), 10) === paymentAmounts;
  }

  const totalPayments =
    watchPayments?.reduce(
      (acc, cur) => acc + Math.round(cur.amount * 100),
      0,
    ) || 0;

  const disabledPercent =
    watchPaymentType === 'amount' || updateQuotationPayments.isLoading;
  const disabledAmount =
    watchPaymentType === 'percent' || updateQuotationPayments.isLoading;

  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>Edição de pagamentos</Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <TextInfo
                label="Total"
                text={
                  quotation.hasDiscount ? (
                    <span style={{ textDecoration: 'line-through' }}>
                      {formatWithSymbol({
                        amount: quotation.totalAmount,
                        currency: quotation.currency,
                      })}
                    </span>
                  ) : (
                    '-'
                  )
                }
              />
            </Grid.Column>
            <Grid.Column>
              <TextInfo
                label="Desconto"
                text={
                  quotation?.hasDiscount
                    ? `${quotation?.discountPercent}%`
                    : '-'
                }
              />
            </Grid.Column>
            <Grid.Column>
              <TextInfo
                label="Total c/ desconto"
                text={formatWithSymbol({
                  amount: quotation.totalAmountWithDiscount,
                  currency: quotation.currency,
                })}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Tipo</label>
            <RadioGroup>
              <RadioInput
                label="Porcentagem"
                name="paymentType"
                ref={register}
                value="percent"
              />
              <RadioInput
                label="Valor"
                name="paymentType"
                ref={register}
                value="amount"
              />
            </RadioGroup>
            {errors.paymentType && (
              <InputError>{errors?.paymentType?.message}</InputError>
            )}
          </Form.Field>
          <Divider hidden />
          <Divider hidden />
          {fields.map((item, index) => {
            const isFirst = index === 0;
            const disabledRemoveButton = isFirst && watchPayments.length === 1;

            return (
              <Form.Group key={item.id}>
                <Form.Field width={2}>
                  {isFirst && <label>Parcela</label>}
                  <div style={{ marginTop: '13px', marginLeft: '4px' }}>
                    {index + 1}
                  </div>
                </Form.Field>
                <Form.Field width={7}>
                  {isFirst && <label>Descrição</label>}
                  <Controller
                    control={control}
                    name={`payments[${index}].description`}
                    defaultValue={item.description}
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={updateQuotationPayments.isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors?.payments && (
                    <InputError>
                      {errors?.payments[index]?.description?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={3}>
                  {isFirst && <label>Porcentagem (%)</label>}
                  <Controller
                    control={control}
                    name={`payments[${index}].percent`}
                    defaultValue={item.percent}
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        onValueChange={(data) => {
                          handleChangePercent(data.floatValue, index);
                          onChange(data.floatValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        maxLength={3}
                        disabled={disabledPercent}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors?.payments && (
                    <InputError>
                      {errors?.payments[index]?.percent?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={3}>
                  {isFirst && <label>Valor</label>}
                  <Controller
                    control={control}
                    name={`payments[${index}].amount`}
                    defaultValue={item.amount}
                    render={({ onChange, onBlur, value }) => (
                      <CurrencyInput
                        onChange={(_, floatValue) => {
                          handleChangeAmount(floatValue, index);
                          onChange(floatValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        prefix="R$"
                        decimalSeparator=","
                        thousandSeparator="."
                        precision="2"
                        disabled={disabledAmount}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors?.payments && (
                    <InputError>
                      {errors?.payments[index]?.amount?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={1}>
                  {isFirst && <label>Ação</label>}
                  <Button
                    basic
                    icon
                    disabled={disabledRemoveButton}
                    onClick={() => remove(index)}
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Field>
              </Form.Group>
            );
          })}
        </Form>
        <Grid>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field width={2} />
                <Form.Field width={7}>
                  <Button
                    onClick={() =>
                      append({
                        amount: '',
                        description: '',
                        percent: '',
                      })
                    }
                    basic
                    fluid
                  >
                    <Icon name="add" />
                    Nova parcela
                  </Button>
                </Form.Field>
                <Form.Field width={3} />
                <Form.Field width={3}>
                  <Input
                    value={formatWithSymbol({
                      amount: totalPayments,
                      currency: quotation.currency,
                    })}
                  />
                </Form.Field>
                <Form.Field width={1} />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
        {error && <Message content={error} negative />}
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          type="submit"
          form="form"
          loading={updateQuotationPayments.isLoading}
          disabled={updateQuotationPayments.isLoading}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function mapToForm(quotation) {
  return {
    payments: quotation.payments.map((payment) => ({
      percent: Math.round(payment.percent * 100),
      amount: payment.amount / 100,
      description: payment.description,
    })),
  };
}
