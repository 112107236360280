import * as React from 'react';

import { DateTime } from 'luxon';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Button, Divider, Form, Header, Input } from 'semantic-ui-react';

import ContractSelect from 'components/inputs/ContractSelect';
import ContractTypeSelect from 'components/inputs/ContractTypeSelect';
import CurrencySelect from 'components/inputs/CurrencySelect';
import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import QuotationSearch from 'components/inputs/QuotationSearch';
import RepresentativeSearch from 'components/inputs/RepresentativeSearch';
import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { formatContractType } from 'queries/contract';
import { DOCUMENT_CONTRACT } from 'util/Document';

const QUOTATION_APPROVED = 2;

export default function ContractForm({
  contract,
  documentInput,
  onSubmit,
  isLoading = false,
}) {
  const [mounted, setMounted] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(
    contract.customer.id || '',
  );

  const documentFile = useUpload();

  const defaultValues = contractFormMapper(contract);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const { setFile } = documentFile;

  React.useEffect(() => {
    if (!mounted && documentInput) {
      setFile(documentInput);
      setMounted(true);
    }
  }, [mounted, documentInput, setFile]);

  const submit = async (values) => {
    onSubmit({ ...values, documentId: documentFile.file?.id || null });
  };

  function handleDocumentFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <Divider hidden />
      <Header as="h2">Cliente</Header>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Empresa</label>
          <Controller
            control={control}
            name="customer"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                onChange={(data) => {
                  setCustomerId(data?.id);
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Cliente"
                autoComplete="off"
                isClearable
              />
            )}
          />
          {errors.customer && (
            <InputError>{errors.customer.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Representante</label>
          <Controller
            control={control}
            name="representative"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <RepresentativeSearch
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                customerId={customerId}
                autoComplete="off"
              />
            )}
          />
          {errors.representative && (
            <InputError>{errors.representative.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Divider hidden />

      <Header as="h2">Contrato</Header>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Tipo de contrato</label>
          <Controller
            control={control}
            name="contractType"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <ContractTypeSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="Tipo de contrato"
                autoComplete="off"
                isClearable
              />
            )}
          />
          {errors.contractType && (
            <InputError>{errors.contractType.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Número</label>
          <Controller
            control={control}
            name="number"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors.number && <InputError>{errors?.number?.message}</InputError>}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Moeda</label>
          <Controller
            control={control}
            name="currency"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <CurrencySelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                autoComplete="off"
              />
            )}
          />
          {errors.currency && (
            <InputError>{errors.currency.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Total de estudos</label>
          <Controller
            control={control}
            name="projectQuantity"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <NumberFormat
                onValueChange={(data) => {
                  onChange(data.floatValue);
                }}
                onBlur={onBlur}
                value={value}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={0}
                disabled={isLoading}
                autoComplete="off"
              />
            )}
          />
          {errors.projectQuantity && (
            <InputError>{errors?.projectQuantity?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Data de assinatura (opcional)</label>
          <Controller
            control={control}
            name="signatureDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                disabled={isLoading}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>Data requerida (opcional)</label>
          <Controller
            control={control}
            name="requiredDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onChange={onChange}
                onBlur={onBlur}
                selected={value}
                disabled={isLoading}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Divider hidden />

      <Header as="h2">Outras informações (opcional)</Header>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Pertence a um contrato</label>
          <Controller
            control={control}
            name="parentContract"
            render={({ onChange, onBlur, value }) => (
              <ContractSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                autoComplete="off"
              />
            )}
          />
          {errors?.parentContract && (
            <InputError>{errors?.parentContract?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Orçamento aprovado</label>
          <Controller
            control={control}
            name="quotation"
            render={({ onChange, onBlur, value }) => (
              <QuotationSearch
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                quotationFilter={{
                  status: QUOTATION_APPROVED,
                  notBound: true,
                }}
                autoComplete="off"
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Ordem de serviço</label>
          <Controller
            control={control}
            name="workOrder"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <label>Observações</label>
          <Controller
            control={control}
            name="notes"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isLoading}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Divider hidden />

      <Header as="h2">Anexos</Header>
      <Form.Field>
        <label>Documento do contrato</label>
        <UploadInput
          name="document"
          uploadedFile={documentFile?.file}
          onUpload={(e) => handleDocumentFileChange(e, DOCUMENT_CONTRACT)}
          onDelete={documentFile.handleDelete}
        />
        {errors.document && (
          <InputError>É necessário enviar um documento</InputError>
        )}
      </Form.Field>
      {errors.documentId && <InputError>{errors.documentId}</InputError>}
      <Button
        type="submit"
        size="large"
        style={{ marginTop: 20 }}
        loading={isLoading}
        primary
      >
        Salvar
      </Button>
    </Form>
  );
}

function contractFormMapper(contract) {
  return {
    customer: contract.customer,
    representative: {
      value: contract.representative.id,
      label: `${contract.representative.name}`,
    },
    contractType: {
      value: contract.type,
      label: formatContractType(contract.type),
    },
    currency: {
      value: contract.currency,
      label: contract.currency === 1 ? 'Real' : 'Dólar',
    },
    number: contract.number,
    signatureDate: contract.signatureDate
      ? DateTime.fromISO(contract.signatureDate).toJSDate()
      : undefined,
    requiredDate: contract.requiredDate
      ? DateTime.fromISO(contract.requiredDate).toJSDate()
      : undefined,
    parentContractId: contract.parentContract
      ? {
          value: contract.parentContract.id,
          label: contract.parentContract.number,
        }
      : undefined,
    quotation: contract?.quotation
      ? {
          value: contract?.quotation?.id,
          label: contract?.quotation?.code,
        }
      : undefined,
    projectQuantity: contract.projectQuantity,
    notes: contract.notes,
    workOrder: contract.workOrder,
  };
}
