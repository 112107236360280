import React, { useContext, createContext, useState } from 'react';

type Option = {
  value: number;
  label: string;
};

type SaleServiceFilterProps = {
  crop?: Option;
  division?: Option;
  target?: Option;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
};

type MappedSaleServiceFilterProps = Omit<
  SaleServiceFilterProps,
  'crop' | 'division' | 'target'
> & {
  crop?: number;
  division?: number;
  target?: number;
};

export type SaleServiceFilterContextData = {
  filter: SaleServiceFilterProps;
  updateFilter: (newData: SaleServiceFilterProps) => void;
  getMappedFilter: () => MappedSaleServiceFilterProps;
  resetFilter: () => void;
};

export const SaleServiceFilterContext = createContext<
  SaleServiceFilterContextData
>({} as SaleServiceFilterContextData);

export type SaleServiceFilterProviderProps = {
  children: React.ReactNode;
};

const SaleServiceFilterProvider = ({
  children,
}: SaleServiceFilterProviderProps) => {
  const [documentFilter, setSaleServiceFilter] = useState<
    SaleServiceFilterProps
  >({
    page: 1,
    pageSize: 10,
    orderBy: 'division',
    sort: 'asc',
  });

  const updateFilter = (data: SaleServiceFilterProps) => {
    const newFilter = { ...documentFilter, ...data };
    setSaleServiceFilter(newFilter);
  };

  const getMappedFilter = () => {
    const { crop, division, target, ...filterUpdated } = documentFilter;

    return {
      ...filterUpdated,
      cropId: crop?.value || undefined,
      divisionId: division?.value || undefined,
      targetId: target?.value || undefined,
    };
  };

  function resetFilter() {
    setSaleServiceFilter({
      crop: undefined,
      division: undefined,
      target: undefined,
      orderBy: 'id',
      page: 1,
      pageSize: 10,
      sort: 'desc',
    });
  }

  return (
    <SaleServiceFilterContext.Provider
      value={{
        filter: documentFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </SaleServiceFilterContext.Provider>
  );
};

const useSaleServiceFilter = () => useContext(SaleServiceFilterContext);

export { SaleServiceFilterProvider, useSaleServiceFilter };
