import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Form, Input } from 'semantic-ui-react';

import CustomerSearch from 'components/inputs/CustomerSearch';
import ProjectGoalSelect from 'components/inputs/ProjectGoalSelect';
import {
  SAMPLE_EXPIRATION_STATUS_DESCRIPTION,
  SAMPLE_STATUS_DESCRIPTION,
} from 'queries/sample';
import debounce from 'util/debounce';

import { useSampleFilter } from './use-sample-filter';

export default function SampleFilter() {
  const { filter, updateFilter } = useSampleFilter();
  const { control } = useForm({ defaultValues: filter });

  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group>
        <Form.Field width="3">
          <Controller
            control={control}
            name="code"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  debouncedFilter({ code: data.value });
                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Código da amostra"
                autoComplete="new-password"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width="4">
          <Controller
            control={control}
            name="customer"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                onChange={(data) => {
                  updateFilter({ customer: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Cliente"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </Form.Field>
        <Form.Field width="3">
          <Controller
            control={control}
            name="productName"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  debouncedFilter({ productName: data.value });
                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Nome do produto"
                autoComplete="new-password"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width="3">
          <Controller
            control={control}
            name="status"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Select
                onChange={(data) => {
                  updateFilter({ status: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Status"
                autoComplete="off"
                isClearable
                name="status"
                instanceId="status"
                options={Object.entries(SAMPLE_STATUS_DESCRIPTION).map(
                  ([key, description]) => ({
                    value: key,
                    label: description,
                  }),
                )}
              />
            )}
          />
        </Form.Field>
        <Form.Field width="3">
          <Controller
            control={control}
            name="expirationStatus"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Select
                onChange={(data) => {
                  updateFilter({ expirationStatus: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Validade"
                autoComplete="off"
                isClearable
                name="expirationStatus"
                instanceId="expirationStatus"
                options={Object.entries(
                  SAMPLE_EXPIRATION_STATUS_DESCRIPTION,
                ).map(([key, description]) => ({
                  value: key,
                  label: description,
                }))}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width="3">
          <Controller
            control={control}
            name="projectCode"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  debouncedFilter({ projectCode: data.value });
                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Código do estudo"
                autoComplete="new-password"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width="3">
          <Controller
            control={control}
            name="projectGoal"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <ProjectGoalSelect
                onChange={(data) => {
                  updateFilter({ projectGoal: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Finalidade do estudo"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
