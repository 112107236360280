import React from 'react';

import Select from 'react-select';

import { useRepresentatives } from 'queries/customer';

export default function RepresentativeSearchV2({
  name = 'representative',
  customerId = undefined,
  disabled,
  isLoading = false,
  onBlur,
  onChange,
  placeholder = 'Selecione...',
  value,
}) {
  const {
    data,
    isLoading: isLoadingRepresentatives,
    error,
  } = useRepresentatives(customerId, { enabled: !!customerId });

  const representatives = error || !data?.data ? [] : data.data;

  return (
    <Select
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.name}
      instanceId={name}
      isDisabled={disabled}
      isLoading={isLoading || isLoadingRepresentatives}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      options={representatives}
      placeholder={placeholder}
      value={value}
    />
  );
}
