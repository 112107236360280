const currencyOptions = [
  { key: 1, value: 1, text: 'Real' },
  { key: 2, value: 2, text: 'Dólar' },
];

const divisionOptions = [
  { key: 1, value: 1, text: 'Bioensaio' },
  { key: 2, value: 2, text: 'Biotec' },
  { key: 3, value: 3, text: 'Coleta' },
  { key: 17, value: 17, text: 'Criações' },
  { key: 15, value: 15, text: 'Fertilizante' },
  { key: 7, value: 7, text: 'Fungicida' },
  { key: 8, value: 8, text: 'Herbicida' },
  { key: 9, value: 9, text: 'Inseticida' },
  { key: 16, value: 16, text: 'MIP Experience' },
  { key: 12, value: 12, text: 'Nematologia' },
  { key: 13, value: 13, text: 'Projeto especial' },
  { key: 14, value: 14, text: 'Resíduo' },
];

const managementTypeOptions = [
  { key: 1, value: 1, text: 'Campo' },
  { key: 2, value: 2, text: 'Casa de vegetação' },
  { key: 3, value: 3, text: 'Laboratório' },
];

const projectGoalOptions = [
  {
    key: 1,
    value: 1,
    text: 'Exploratório',
    code: 'E',
  },
  {
    key: 2,
    value: 2,
    text: 'Oficial',
    code: 'O',
  },
];

const testLocationOptions = [
  {
    key: 1,
    value: 1,
    text: 'Engenheiro Coelho',
    code: 'ENG',
  },
  {
    key: 2,
    value: 2,
    text: 'Conchal',
    code: 'CON',
  },
  {
    key: 3,
    value: 3,
    text: 'Externo',
    code: 'EXT',
  },
];

export {
  currencyOptions,
  divisionOptions,
  managementTypeOptions,
  projectGoalOptions,
  testLocationOptions,
};
