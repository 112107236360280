import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {
  Button,
  Form,
  Header,
  Input,
  Divider,
  Icon,
  Grid,
} from 'semantic-ui-react';
import { object, date, string } from 'yup';

import EmptyState from 'components/data/EmptyState';
import ProductIngredientTable from 'components/data/ProductIngredientTable';
import DatePicker from 'components/inputs/date-picker';
import FormulationSelect from 'components/inputs/FormulationSelect';
import InputError from 'components/inputs/InputError';
import ProductCategorySelect from 'components/inputs/ProductCategorySelect';
import ProductTypeSelect from 'components/inputs/ProductTypeSelect';
import RetStageSelect from 'components/inputs/RetStageSelect';
import AddProductIngredientModal from 'components/modal/AddProductIngredientModal';
import { emptyStringToNull, emptyStringToUndefined } from 'util/validation';

const required = 'é um campo obrigatório';

const schema = object().shape({
  name: string()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  productType: object().required(`Tipo ${required}`),
  productCategory: object().required(`Classe ${required}`),
  formulation: object().required(`Formulação ${required}`),
  ret: string()
    .nullable()
    .transform(emptyStringToUndefined),
  retStage: object()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  retExpirationDate: date()
    .nullable()
    .transform(emptyStringToUndefined),
  mapaRegister: string()
    .nullable()
    .transform(emptyStringToNull),
});

const INITIAL_VALUES = {};

export default function ProductForm({
  formValues = undefined,
  isSubmiting = false,
  onSubmit,
}) {
  const modalRef = React.useRef(null);

  const [productIngredients, setProductIngredients] = React.useState(
    formValues?.ingredients || [],
  );

  const isEditing = !!formValues;

  const submitButtonText = isEditing ? 'Salvar' : 'Adicionar produto';

  const defaultValues = formValues || INITIAL_VALUES;
  const resolver = yupResolver(schema);

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  function handleModalOpen() {
    modalRef.current.openModal();
  }

  function handleIngredientSubmit(values) {
    const productIngredient = {
      ...values,
      ingredient: {
        id: values.ingredient.value,
        name: values.ingredient.label,
      },
      measurementUnit: values.measurementUnit?.id,
    };

    setProductIngredients((oldValues) => [...oldValues, productIngredient]);

    handleModalOpen();
  }

  function handleRemoveIngredientClick(index) {
    const nextIndex = index + 1;

    const updatedIngredients = [
      ...productIngredients.slice(0, index),
      ...productIngredients.slice(nextIndex),
    ];

    setProductIngredients(updatedIngredients);
  }

  function innerSubmit(values) {
    onSubmit({ ...values, productIngredients });
  }

  return (
    <>
      <Form onSubmit={handleSubmit(innerSubmit)}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <Header as="h2">Produto</Header>
            </Grid.Column>
            <Grid.Column width={11}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Nome</label>
                  <Controller
                    control={control}
                    name="name"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.name && (
                    <InputError>{errors?.name?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Tipo</label>
                  <Controller
                    control={control}
                    name="productType"
                    render={({ onChange, onBlur, value }) => (
                      <ProductTypeSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.productType && (
                    <InputError>{errors?.productType?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Classe</label>
                  <Controller
                    control={control}
                    name="productCategory"
                    render={({ onChange, onBlur, value }) => (
                      <ProductCategorySelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.productCategory && (
                    <InputError>{errors?.productCategory?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Tipo de formulação</label>
                  <Controller
                    control={control}
                    name="formulation"
                    render={({ onChange, onBlur, value }) => (
                      <FormulationSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.formulation && (
                    <InputError>{errors?.formulation?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={5}>
              <Header as="h2">Ingredientes</Header>
            </Grid.Column>
            <Grid.Column width={11}>
              {productIngredients.length === 0 ? (
                <EmptyState>
                  <EmptyState.Header>
                    Nenhum ingrediente adicionado
                  </EmptyState.Header>
                  <EmptyState.Description>
                    Clique no botão abaixo para adicionar um ingrediente
                  </EmptyState.Description>
                  <EmptyState.Actions>
                    <Button
                      type="button"
                      onClick={handleModalOpen}
                      primary
                      basic
                    >
                      <Icon name="add" />
                      Adicionar
                    </Button>
                  </EmptyState.Actions>
                </EmptyState>
              ) : (
                <>
                  <ProductIngredientTable
                    productIngredients={productIngredients}
                    onRemoveClick={handleRemoveIngredientClick}
                  />
                  {productIngredients.length > 0 ? (
                    <Button
                      type="button"
                      onClick={handleModalOpen}
                      primary
                      basic
                    >
                      <Icon name="add" />
                      Adicionar
                    </Button>
                  ) : null}
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={5}>
              <Header as="h2">RET (opcional)</Header>
            </Grid.Column>
            <Grid.Column width={11}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>N° do RET</label>
                  <Controller
                    control={control}
                    name="ret"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.ret && (
                    <InputError>{errors?.ret?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Fase</label>
                  <Controller
                    control={control}
                    name="retStage"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <RetStageSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.retStage && (
                    <InputError>{errors?.retStage?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Data de validade</label>
                  <Controller
                    control={control}
                    name="retExpirationDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={isSubmiting}
                      />
                    )}
                  />
                  {errors.retExpirationDate && (
                    <InputError>
                      {errors?.retExpirationDate?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Registro MAPA</label>
                  <Controller
                    control={control}
                    name="mapaRegister"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.mapaRegister && (
                    <InputError>{errors?.mapaRegister?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={5} />
            <Grid.Column width={11}>
              <Form.Field>
                <Button
                  type="submit"
                  primary
                  style={{ marginTop: 20 }}
                  loading={isSubmiting}
                >
                  {submitButtonText}
                </Button>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <AddProductIngredientModal
        ref={modalRef}
        onSubmit={handleIngredientSubmit}
      />
    </>
  );
}
