import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Button, Form, Modal } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import SampleSearch from 'components/inputs/SampleSearch';

const formId = 'form_associate_sample';

type AssociateSampleModalProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

export function AssociateSampleModal({
  isLoading,
  isOpen,
  onClose,
  onSubmit,
}: AssociateSampleModalProps) {
  const { control, errors, handleSubmit } = useForm();
  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Adicionar amostra existente</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Amostra</label>
            <Controller
              control={control}
              name="sample"
              defaultValue=""
              rules={{
                required: { value: true, message: 'Selecione uma amostra' },
              }}
              render={({ onChange, onBlur, value }) => (
                <SampleSearch
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autoComplete="off"
                  isClearable
                />
              )}
            />
            {errors.sample && (
              <InputError>{errors?.sample?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Finalidade</label>
            <Controller
              control={control}
              name="sampleGoal"
              defaultValue=""
              rules={{
                required: { value: true, message: 'Selecione a finalidade' },
              }}
              render={({ onChange, onBlur, value }) => (
                <Select
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="Selecione"
                  options={[
                    { value: 1, label: 'Padrão' },
                    { value: 2, label: 'Teste' },
                    { value: 3, label: 'Adjuvante' },
                  ]}
                />
              )}
            />
            {errors.sampleGoal && (
              <InputError>{errors?.sampleGoal?.message}</InputError>
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic disabled={isLoading} onClick={onClose} type="button">
          Cancelar
        </Button>
        <Button
          type="submit"
          form={formId}
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
