export const UNIT_KILOGRAM = 1;
export const UNIT_GRAM = 2;
export const UNIT_MILLILITER = 3;
export const UNIT_LITER = 4;
export const UNIT_CENTIMETER = 5;
export const UNIT_METER = 6;
export const UNIT_PLANTS_PER_HECTARE = 7;
export const UNIT_PLANTS_PER_POT = 8;
export const UNIT_GRAMS_PER_LITERS = 9;
export const UNIT_GRAMS_PER_KILOGRAMS = 10;
export const UNIT_METERS_PER_SECONDS = 11;
export const UNIT_MILLILITERS_PER_HECATRE = 12;
export const UNIT_MILLILITERS_PER_POT = 13;
export const UNIT_LITERS_PER_HECTARE = 14;
export const UNIT_LITERS_PER_POT = 15;
export const UNIT_FRUITS_PER_PLOT = 16;
export const UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM = 17;
export const UNIT_PERCENTAGE = 18;
export const UNIT_PLANTS_PER_PLOT = 19;
export const UNIT_CULMS_PER_HECTARE = 20;
export const UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER = 21;
export const UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER = 22;
export const UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER = 23;
export const UNIT_GRAMS_PER_HECTARE = 24;

export function formatUnit(unit) {
  if (unit === UNIT_KILOGRAM) {
    return 'Quilograma';
  }

  if (unit === UNIT_GRAM) {
    return 'Grama';
  }

  if (unit === UNIT_MILLILITER) {
    return 'Mililitro';
  }

  if (unit === UNIT_LITER) {
    return 'Litro';
  }

  if (unit === UNIT_CENTIMETER) {
    return 'Centímetro';
  }

  if (unit === UNIT_METER) {
    return 'Metro';
  }

  if (unit === UNIT_PLANTS_PER_HECTARE) {
    return 'Plantas/ha';
  }

  if (unit === UNIT_PLANTS_PER_POT) {
    return 'Plantas/vaso';
  }

  if (unit === UNIT_GRAMS_PER_LITERS) {
    return 'Gramas por litros';
  }

  if (unit === UNIT_GRAMS_PER_KILOGRAMS) {
    return 'Gramas por kilogramas';
  }

  if (unit === UNIT_METERS_PER_SECONDS) {
    return 'metros por segundo';
  }

  if (unit === UNIT_MILLILITERS_PER_HECATRE) {
    return 'mililitros por hectare';
  }

  if (unit === UNIT_MILLILITERS_PER_POT) {
    return 'mililitros por vaso';
  }

  if (unit === UNIT_LITERS_PER_HECTARE) {
    return 'litros por hectare';
  }

  if (unit === UNIT_LITERS_PER_POT) {
    return 'litros por vaso';
  }

  if (unit === UNIT_FRUITS_PER_PLOT) {
    return 'frutos/parcela';
  }

  if (unit === UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM) {
    return 'x 10(9)UFC/g';
  }

  if (unit === UNIT_PERCENTAGE) {
    return '%';
  }

  if (unit === UNIT_PLANTS_PER_PLOT) {
    return 'plantas/parcela';
  }

  if (unit === UNIT_CULMS_PER_HECTARE) {
    return 'Colmos/hectare';
  }

  if (unit === UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'x 10(10)UFC/ml';
  }

  if (unit === UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'x 10(9)UFC/ml';
  }

  if (unit === UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'x 10(7)UFC/ml';
  }

  if (unit === UNIT_GRAMS_PER_HECTARE) {
    return 'Gramas por hectare';
  }

  return '';
}

export function formatInitials(unit) {
  if (unit === UNIT_KILOGRAM) {
    return 'Kg';
  }

  if (unit === UNIT_GRAM) {
    return 'gr';
  }

  if (unit === UNIT_MILLILITER) {
    return 'ml';
  }

  if (unit === UNIT_LITER) {
    return 'L';
  }

  if (unit === UNIT_CENTIMETER) {
    return 'cm';
  }

  if (unit === UNIT_METER) {
    return 'm';
  }

  if (unit === UNIT_PLANTS_PER_HECTARE) {
    return 'plantas/ha';
  }

  if (unit === UNIT_PLANTS_PER_POT) {
    return 'plantas/vaso';
  }

  if (unit === UNIT_GRAMS_PER_LITERS) {
    return 'g/L';
  }

  if (unit === UNIT_GRAMS_PER_KILOGRAMS) {
    return 'g/kg';
  }

  if (unit === UNIT_METERS_PER_SECONDS) {
    return 'm/s';
  }

  if (unit === UNIT_MILLILITERS_PER_HECATRE) {
    return 'ml/ha';
  }

  if (unit === UNIT_MILLILITERS_PER_POT) {
    return 'ml/vaso';
  }

  if (unit === UNIT_LITERS_PER_HECTARE) {
    return 'L/ha';
  }

  if (unit === UNIT_LITERS_PER_POT) {
    return 'L/vaso';
  }

  if (unit === UNIT_FRUITS_PER_PLOT) {
    return 'frutos/parcela';
  }

  if (unit === UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM) {
    return 'x 10(9)UFC/g';
  }

  if (unit === UNIT_PERCENTAGE) {
    return '%';
  }

  if (unit === UNIT_PLANTS_PER_PLOT) {
    return 'plantas/parcela';
  }

  if (unit === UNIT_CULMS_PER_HECTARE) {
    return 'Colmos/ha';
  }

  if (unit === UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'x 10(10)UFC/ml';
  }

  if (unit === UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'x 10(9)UFC/ml';
  }

  if (unit === UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER) {
    return 'X 10(7)UFC/ml';
  }

  if (unit === UNIT_GRAMS_PER_HECTARE) {
    return 'g/ha';
  }

  return '';
}

export function getMassAndVolumeUnits() {
  return [
    UNIT_LITER,
    UNIT_GRAM,
    UNIT_GRAMS_PER_KILOGRAMS,
    UNIT_GRAMS_PER_LITERS,
    UNIT_MILLILITER,
    UNIT_KILOGRAM,
    UNIT_GRAMS_PER_HECTARE,
    UNIT_LITERS_PER_HECTARE,
    UNIT_MILLILITERS_PER_HECATRE,
  ];
}

export function getTreatmentUnits() {
  return [
    UNIT_LITER,
    UNIT_GRAM,
    UNIT_GRAMS_PER_KILOGRAMS,
    UNIT_GRAMS_PER_LITERS,
    UNIT_MILLILITER,
    UNIT_KILOGRAM,
    UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM,
    UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER,
    UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER,
    UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER,
    UNIT_PERCENTAGE,
    UNIT_GRAMS_PER_HECTARE,
    UNIT_LITERS_PER_HECTARE,
    UNIT_MILLILITERS_PER_HECATRE,
  ];
}

export function getCropUnits() {
  return [
    UNIT_PLANTS_PER_HECTARE,
    UNIT_PLANTS_PER_POT,
    UNIT_PLANTS_PER_PLOT,
    UNIT_FRUITS_PER_PLOT,
    UNIT_CULMS_PER_HECTARE,
  ];
}
