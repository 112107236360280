import React from 'react';

import Select from 'react-select';

import { useQueryEmployees } from 'hooks/employee';

export default function EmployeeSearch({
  name = 'employee',
  placeholder = 'Busque pelo nome...',
  value,
  jobPosition = undefined,
  isFormer = false,
  onChange,
  onBlur,
  disabled,
  loading = false,
  autoComplete = '',
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const { data, isLoading, error } = useQueryEmployees({
    fullName: searchValue,
    jobPosition,
    isFormer,
  });

  const employees = error || !data || !data.employees ? [] : data.employees;

  const options = employees?.map((employee) => ({
    value: employee.id,
    label: employee.fullName,
  }));

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      isLoading={isLoading || loading}
      value={value}
      inputValue={searchValue}
      onInputChange={handleInputChange}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      isDisabled={disabled}
      autoComplete={autoComplete}
    />
  );
}
