import React, { useState } from 'react';

import Select, { Styles, ValueType } from 'react-select';

import { TARGET_TYPE, useQueryTargets } from 'hooks/target';

type TargetSearchProps = {
  autoComplete?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange: (valeu: ValueType<TargetOption>) => void;
  placeholder?: string;
  styles?: Styles;
  type?: number;
  value: TargetOption | null;
};

type TargetOption = {
  label: string;
  value: number;
};

export default function TargetSearch({
  autoComplete = undefined,
  disabled = false,
  isClearable = false,
  isMulti = true,
  name = undefined,
  onBlur = undefined,
  onChange,
  placeholder = 'Busque pelo nome...',
  styles = undefined,
  type = TARGET_TYPE.service,
  value,
}: TargetSearchProps) {
  const [searchValue, setSearchValue] = useState('');

  const { data, isLoading, error } = useQueryTargets({
    name: searchValue,
    type,
  });

  const targets = error || !data || !data.targets ? [] : data.targets;

  const options = targets?.map((target) => ({
    value: target.id,
    label: target.name,
  }));

  const handleInputChange = (inputValue: string) => {
    setSearchValue(inputValue);
  };

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      inputValue={searchValue}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={handleInputChange}
      options={options}
      placeholder={placeholder}
      styles={styles}
      value={value}
    />
  );
}
