import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllProductCategoriesUseCase from 'queries/getAllProductCategories';

export default function ProductCategorySelect({
  name,
  placeholder = 'Selecione...',
  value,
  onChange,
  disabled,
}) {
  const [{ data, loading, error }, getAllProductCategories] = useAsync(
    getAllProductCategoriesUseCase,
  );

  React.useEffect(() => {
    getAllProductCategories({
      page: 1,
      pageSize: 100,
      orderBy: 'description',
      sort: 'asc',
    });
  }, [getAllProductCategories]);

  const productCategories =
    error || !data || !data.productCategories ? [] : data.productCategories;

  const options = productCategories?.map((property) => ({
    value: property.id,
    label: property.description,
  }));

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      isLoading={loading}
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
    />
  );
}
