import React from 'react';

import Select from 'react-select';

export default function RetStageSelect({
  name,
  value = undefined,
  onChange,
  disabled = false,
  isClearable = false,
}) {
  const options = [
    { value: 1, label: 'I' },
    { value: 2, label: 'II' },
    { value: 3, label: 'III' },
  ];

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder="Selecione..."
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete="off"
    />
  );
}
