import React from 'react';

import styled, { css } from 'styled-components';

export type TextProps = {
  variant?: 'primary' | 'secondary';
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export default function Text({
  variant = 'primary',
  children,
  style,
}: TextProps) {
  return (
    <Wrapper variant={variant} style={style}>
      {children}
    </Wrapper>
  );
}

type WrapperProps = Pick<TextProps, 'variant'>;

const wrapperModifiers = {
  primary: () => css`
    font-size: 12.6px;
    color: #030517;
  `,
  secondary: () => css`
    margin-top: 4px;
    font-size: 12px;
    color: #8a8a8a;
  `,
};

const Wrapper = styled.div<WrapperProps>`
  ${({ variant }) => css`
    overflow: hidden;
    text-overflow: ellipsis;

    ${variant && wrapperModifiers[variant]()}
  `}
`;
