import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Modal,
  Button,
  Message,
  Form,
  Input,
  Grid,
  TextArea,
} from 'semantic-ui-react';
import * as Yup from 'yup';

import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useUpload } from 'hooks/use-upload';
import { useCreateProcedure } from 'mutations/procedure';

const required = 'é um campo obrigatório';

const ProcedureFormInput = Yup.object().shape({
  code: Yup.string()
    .required(`Código ${required}`)
    .length(10, 'Código deve conter 10 caracteres'),
  revision: Yup.number()
    .required(`Revisão ${required}`)
    .typeError('Revisão deve ser um número entre 1 e 99')
    .min(1, 'Revisão deve ser a partir de 1')
    .max(99, 'Revisão deve ser até 99'),
  title: Yup.string()
    .required(`Título ${required}`)
    .max(1000, 'Título deve conter no máximo 1000 caracteres'),
  objective: Yup.string()
    .required(`Objetivo ${required}`)
    .max(2000, 'Objetivo deve conter no máximo 2000 caracteres'),
});

type FileEvent = React.ChangeEvent<HTMLInputElement>;

export type ProcedureFormValues = {
  code: string;
  revision: number;
  title: string;
  objective: string;
};

export type ProcedureModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

const documentTypeProcedure = 14;
const formId = 'form_procedure';

export default function ProcedureModalForm({
  isOpen,
  onClose,
  onSuccess,
}: ProcedureModalFormProps) {
  const { handleError } = useErrorHandler();
  const documentFile = useUpload();

  const resolver = yupResolver(ProcedureFormInput);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const {
    mutate: createProcedure,
    isLoading: isAdding,
    error: createError,
  } = useCreateProcedure({
    onSuccess: () => handleSuccess('POP adicionado com sucesso!'),
  });

  function handleSuccess(message: string) {
    onSuccess(message);
  }

  function onSubmit(values: ProcedureFormValues) {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }

    const input = {
      code: values.code,
      revision: values.revision,
      title: values.title,
      objective: values.objective,
      documentId: documentFile.file?.id,
    };

    createProcedure(input);
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }
  const isSubmitting = isAdding;
  const disabledButton = !documentFile.file?.url;

  const header = 'Novo POP';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Código</label>
                    <Controller
                      control={control}
                      name="code"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          maxLength={10}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.code && (
                      <InputError>{errors?.code?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Revisão</label>
                    <Controller
                      control={control}
                      name="revision"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmitting}
                          loading={isSubmitting}
                          maxLength={20}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.revision && (
                      <InputError>{errors?.revision?.message}</InputError>
                    )}
                  </Form.Field>
                </Form.Group>
                <Form.Field>
                  <label>Título</label>
                  <Controller
                    control={control}
                    name="title"
                    render={({ onChange, onBlur, value }) => (
                      <TextArea
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.title && (
                    <InputError>{errors?.title?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Objetivo</label>
                  <Controller
                    control={control}
                    name="objective"
                    render={({ onChange, onBlur, value }) => (
                      <TextArea
                        style={{ minHeight: 150 }}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.objective && (
                    <InputError>{errors?.objective?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Documento</label>
                  <UploadInput
                    name="document"
                    uploadedFile={documentFile?.file}
                    onUpload={(e: FileEvent) =>
                      handleDocumentFileChange(e, documentTypeProcedure)
                    }
                    onDelete={documentFile.handleDelete}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {createError ? (
          <Message content={handleError(createError)} negative />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || disabledButton}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
