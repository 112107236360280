import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Table } from 'semantic-ui-react';
import shortid from 'shortid';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import {
  isFinished,
  isCanceled,
  formatContractType,
  formatContractStatus,
} from 'queries/contract';
import { formatCurrencyWithSymbol } from 'util/Currency';

export default function ContractsTable({
  contracts = [],
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  pagination,
}) {
  function getStatusColor(status) {
    switch (status) {
      case 1:
        return 'blue';
      case 2:
        return 'transparent';
      case 3:
        return 'yellow';
      case 4:
        return 'red';
      default:
        return '';
    }
  }

  function formatLeftTime({ years, months, days }) {
    const isLate = years < 0 || months < 0 || days < 0;

    const hasYears = years !== 0;
    const hasMonths = months !== 0;

    const absoluteYears = Math.abs(years);
    const absoluteMonths = Math.abs(months);
    const absoluteDays = Math.round(Math.abs(days));

    const suffix = isLate ? ' atrás' : '';

    if (hasYears) {
      return `${absoluteYears} ${
        absoluteYears === 1 ? 'ano' : 'anos'
      }${suffix}`;
    }

    if (hasMonths) {
      return `${absoluteMonths} ${
        absoluteMonths === 1 ? 'mês' : 'meses'
      }${suffix}`;
    }

    return `${absoluteDays} ${absoluteDays === 1 ? 'dia' : 'dias'}${suffix}`;
  }

  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Contratos" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Número</Table.HeaderCell>
              <Table.HeaderCell textAlign="left" width="1">
                Safra
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Cliente</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Tipo</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Status</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">
                Data assinatura
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">
                Data requerida
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor total</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">
                Atualizado em
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={7} rows={10} />}
            {!isLoading &&
              contracts?.map((contract) => {
                const {
                  id,
                  number,
                  customer = {},
                  representative = {},
                  type,
                  status,
                  harvests,
                  totalAmount,
                  signatureDate,
                  requiredDate,
                  updatedAt,
                  currency,
                } = contract;

                const harvestsFormatted = harvests?.join(', ') || '';
                const representativeText = `${representative.name}`;
                const today = DateTime.local();
                const requiredDateObj = requiredDate
                  ? DateTime.fromISO(requiredDate)
                  : null;

                const restDate = requiredDate
                  ? requiredDateObj
                      .diff(today, ['years', 'months', 'days', 'day'])
                      .toObject()
                  : null;

                const restDateFormatted = restDate
                  ? formatLeftTime(restDate)
                  : '';
                const restDateText =
                  isFinished(contract) || isCanceled(contract)
                    ? 'Finalizado'
                    : restDateFormatted;

                return (
                  <Table.Row key={shortid.generate()}>
                    <Table.Cell textAlign="left">
                      <Text variant="primary">
                        <Link to={`/contratos/${id}`} name="contract-details">
                          {number}
                        </Link>
                      </Text>
                    </Table.Cell>
                    <Table.Cell textAlign="left" width={1}>
                      <Text variant="primary">{harvestsFormatted}</Text>
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      <Text variant="primary">{customer?.tradingName}</Text>
                      <Text variant="secondary">{representativeText}</Text>
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      <Tag color="grey">{formatContractType(type)}</Tag>
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      <Tag color={getStatusColor(status)}>
                        {formatContractStatus(status)}
                      </Tag>
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      {signatureDate
                        ? DateTime.fromISO(signatureDate).toFormat('dd/MM/yyyy')
                        : null}
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      <Text variant="primary">
                        {requiredDate
                          ? DateTime.fromISO(requiredDate).toFormat(
                              'dd/MM/yyyy',
                            )
                          : null}
                      </Text>
                      <Text
                        variant="secondary"
                        style={{
                          color:
                            today > DateTime.fromISO(requiredDate) &&
                            !isFinished(contract) &&
                            !isCanceled(contract)
                              ? 'red'
                              : '',
                        }}
                      >
                        {restDateText}
                      </Text>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      {formatCurrencyWithSymbol(totalAmount, currency)}
                    </Table.Cell>
                    <Table.Cell textAlign="left">
                      {DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={7}>
                <Divider hidden />
                {hasNextPage ? (
                  <Button
                    type="button"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Carregando...' : ''}
                    {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                  </Button>
                ) : (
                  <Text variant="secondary">Nada mais para mostrar</Text>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
