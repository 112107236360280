import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { projectKeys } from 'queries/project';
import api from 'util/api';

const treatmentKeys = {
  all: ['treatments'] as const,
  detail: (id: number) => [...treatmentKeys.all, id] as const,
};

export function useAddTreatment(
  options?: UseMutationOptions<void, AxiosError, any, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(addTreatment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addTreatment(input: any) {
  await api.post(`/treatments`, input);
}

export function useUpdateTreatment(
  options?: UseMutationOptions<void, AxiosError, any, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(updateTreatment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateTreatment({ treatmentId, ...input }: any) {
  await api.put(`/treatments/${treatmentId}`, input);
}

export type TrialInput = {
  date: string;
  description: string;
  type: number;
  treatmentId: number;
};

export type TrialsInput = {
  projectId: number;
  treatmentId: number;
  trials: TrialInput;
};

export function useAddTrials(
  options?: UseMutationOptions<void, AxiosError, TrialsInput, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(addTrials, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addTrials({ treatmentId, ...input }: TrialsInput) {
  await api.post(`/treatments/${treatmentId}/trials`, input);
}

export function useDeleteTrails(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  const queryClient = useQueryClient();

  return useMutation(deleteTrails, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const deleteTrails = async (projectId: number) => {
  await api.delete(`/treatments/trials/${projectId}`);
};

export function useDeleteTreatment(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  const queryClient = useQueryClient();

  return useMutation(deleteTreatment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const deleteTreatment = async (treatmentId: number) => {
  await api.delete(`/treatments/${treatmentId}`);
};
