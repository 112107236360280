import * as React from 'react';

import { getCurrentUser } from 'hooks/auth';
import { CustomerQuery } from 'queries/customer';
import { formatDivision } from 'queries/division';

type Option = {
  value: number;
  label: string;
};

type QuotationFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  code?: string;
  harvest?: number;
  customers?: CustomerQuery[];
  status?: Option[];
  quotationType?: Option;
  divisions?: Option[];
};

export type QuotationFilterContextData = {
  filter: QuotationFilter;
  updateFilter: (newData: QuotationFilter) => void;
  getMappedFilter: () => void;
  isLoading: boolean;
};

export const QuotationFilterContextDefaultValues = {
  filter: {
    page: 1,
    pageSize: 10,
    orderBy: 'code',
    sort: 'desc',
    code: '',
    harvest: undefined,
    customers: undefined,
    status: undefined,
    quotationType: undefined,
    divisions: undefined,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
  isLoading: true,
};

export const QuotationFilterContext = React.createContext<
  QuotationFilterContextData
>(QuotationFilterContextDefaultValues);

export type QuotationFilterProviderProps = {
  children: React.ReactNode;
};

const QuotationFilterProvider = ({
  children,
}: QuotationFilterProviderProps) => {
  const [quotationFilter, setQuotationFilter] = React.useState<QuotationFilter>(
    {
      page: 1,
      pageSize: 10,
      orderBy: 'code',
      sort: 'desc',
    },
  );
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    const setDefaultValuesForReseachers = () => {
      setIsLoading(true);

      const user = getCurrentUser();

      if (user && user.role === 2) {
        setQuotationFilter((oldValues) => {
          return {
            ...oldValues,
            quotationResponsibleId: !user.isAdmin ? user.employeeId : '',
          };
        });
      }

      setIsLoading(false);
    };

    setDefaultValuesForReseachers();
  }, []);

  const updateFilter = (data: QuotationFilter) => {
    const newFilter = {
      ...quotationFilter,
      ...data,
    };
    setQuotationFilter(newFilter);
  };

  const extractValue = ({ value }: { value: number }) => value;

  const getMappedFilter = () => {
    const user = getCurrentUser();

    const {
      customers,
      status,
      divisions,
      quotationType,
      ...filterUpdated
    } = quotationFilter;

    const customerIds = customers?.length ? customers?.map(({ id }) => id) : '';
    const statusValues = status?.length ? status?.map(extractValue) : '';
    const divisionValues = divisions?.length
      ? divisions?.map(extractValue)
      : user.divisions;
    const hasDivisionFilter = divisions?.length ? 'true' : '';

    return {
      ...filterUpdated,
      customerIds,
      status: statusValues,
      divisions: divisionValues,
      quotationType: quotationType?.value,
      hasDivisionFilter,
    };
  };

  return (
    <QuotationFilterContext.Provider
      value={{
        filter: quotationFilter,
        updateFilter,
        getMappedFilter,
        isLoading,
      }}
    >
      {children}
    </QuotationFilterContext.Provider>
  );
};

const useQuotationFilter = () => React.useContext(QuotationFilterContext);

export { QuotationFilterProvider, useQuotationFilter };
