import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { CollectionQuery } from 'data/collection';
import { Pagination } from 'util/api';

type CollectionTableProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
  items: CollectionQuery[];
  onSortingClick: (collectionId: number) => void;
  onAssociateClick: (collectionId: number) => void;
  pagination: Pagination;
};

function CollectionTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  items = [],
  onSortingClick,
  onAssociateClick,
  pagination,
}: CollectionTableProps) {
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Coletas" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" singleLine fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>Id</Table.HeaderCell>
              <Table.HeaderCell width={2}>Espécie</Table.HeaderCell>
              <Table.HeaderCell width={2}>Região</Table.HeaderCell>
              <Table.HeaderCell width={2}>Período</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right">
                Coletado
              </Table.HeaderCell>
              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell width={2}>Chegada</Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right">
                Vivos
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right">
                Mortos
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="right">
                Parasitados
              </Table.HeaderCell>
              <Table.HeaderCell width={1} textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={11} rows={10} />}
            {!isLoading && items
              ? items.map((collection) => {
                  const {
                    id,
                    crop,
                    target,
                    period,
                    region,
                    startDate,
                    endDate,
                    totalCollected,
                    collector,
                    status,
                    statusColor,
                    sorting,
                  } = collection;

                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>
                        <Link to="/coletas">
                          <Text variant="primary">{id}</Text>
                        </Link>
                      </Table.Cell>
                      <Table.Cell title={target}>
                        <Text variant="primary">{target}</Text>
                        <Text variant="secondary">
                          {`${crop}${
                            period === 'Safrinha' ? ` (${period})` : ''
                          }`}
                        </Text>
                      </Table.Cell>
                      <Table.Cell title={region}>
                        <Text variant="primary">{region}</Text>
                        <Text variant="secondary">{collector}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {DateTime.fromISO(startDate)
                            .setLocale('pt-BR')
                            .toFormat('dd/MM/yyyy')}
                        </Text>
                        <Text variant="secondary">
                          {endDate
                            ? `${DateTime.fromISO(endDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')}`
                            : ''}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">{totalCollected}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Tag color={statusColor}>{status}</Tag>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {sorting
                            ? DateTime.fromISO(sorting.arrivalDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')
                            : null}
                        </Text>
                        <Text variant="secondary">
                          {sorting
                            ? DateTime.fromISO(sorting.sortingDate)
                                .setLocale('pt-BR')
                                .toFormat('dd/MM/yyyy')
                            : null}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">{sorting?.liveInsects}</Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">{sorting?.deadInsects}</Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {sorting?.parasitizedInsects}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {status !== 'Triada' ? (
                          <LinkButton onClick={() => onSortingClick(id)}>
                            Triar
                          </LinkButton>
                        ) : null}
                        {status === 'Triada' ? (
                          <LinkButton onClick={() => onAssociateClick(id)}>
                            Associar
                          </LinkButton>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              : null}
          </Table.Body>
        </Table>
        <Divider hidden />
        {hasNextPage ? (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Button
                  type="button"
                  onClick={() => fetchNextPage()}
                  disabled={!hasNextPage || isFetchingNextPage}
                >
                  {isFetchingNextPage ? 'Carregando...' : ''}
                  {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : (
          <Grid>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <Text variant="secondary">Nada mais para mostrar</Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Section.Content>
    </Section>
  );
}

export default CollectionTable;
