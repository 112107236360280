import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Button, Form, Grid, Dimmer, Loader } from 'semantic-ui-react';
import * as yup from 'yup';

import EmployeeSearch from 'components/inputs/EmployeeSearch';
import InputError from 'components/inputs/InputError';
import { useChangeResponsible } from 'mutations/changeResponsible';
import { useProject } from 'queries/project';

type ChangeResponsibleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
  projectId: number;
};

type FormValues = {
  responsibleTechnician: {
    value: number;
    label: string;
  };
};

const schema = yup
  .object()
  .shape({
    responsibleTechnician: yup
      .object()
      .shape({
        value: yup.number().required('Obrigatório'),
        label: yup.string().required('Obrigatório'),
      })
      .required('Obrigatório'),
  })
  .required('Obrigatório');

const formId = 'form_ingredient';

export default function ChangeResponsibleModal({
  isOpen,
  onClose,
  onSuccess,
  projectId,
}: ChangeResponsibleModalProps) {
  const resolver = yupResolver(schema);
  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { data, isLoading } = useProject(projectId);
  const {
    mutate: changeResponsible,
    isLoading: isMutating,
  } = useChangeResponsible({
    onSuccess: () => onSuccess('Responsável técnico alterado com sucesso!'),
  });

  React.useEffect(() => {
    if (data) {
      const option = {
        value: data?.project?.responsible?.id,
        label: `${data?.project?.responsible?.firstName} ${data?.project?.responsible?.lastName}`,
      };
      setValue('responsibleTechnician', option);
    }
  }, [data, setValue]);

  function onSubmit(values: FormValues) {
    const input = {
      projectId,
      responsibleTechnicianId: values.responsibleTechnician.value,
    };
    changeResponsible(input);
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Alterar responsável técnico</Modal.Header>
      <Modal.Content>
        {projectId && isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Responsável</label>
                      <Controller
                        control={control}
                        name="responsibleTechnician"
                        defaultValue=""
                        render={({ onChange, onBlur, value }) => (
                          <EmployeeSearch
                            name="responsibleTechnician"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            placeholder="Busque..."
                            autoComplete="off"
                            disabled={isMutating}
                            loading={isMutating}
                          />
                        )}
                      />
                      {errors.responsibleTechnician?.value && (
                        <InputError>
                          {errors?.responsibleTechnician?.value?.message}
                        </InputError>
                      )}
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isMutating}
          disabled={isMutating}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
