import React from 'react';

import { Settings } from 'luxon';
import toast, { Toaster } from 'react-hot-toast';
import { MutationCache, QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import { ThemeProvider } from 'styled-components';

import { AuthProvider } from 'hooks/use-auth';
import GlobalStyles from 'styles/global';
import theme from 'styles/theme';
import { handleError } from 'util/ErrorHandler';

import Routes from './routes';

Settings.defaultLocale = 'pt-BR';

export const queryClient = new QueryClient({
  mutationCache: new MutationCache({
    onError: (error) => {
      const message = handleError(error);
      toast.error(message);
    },
  }),
});

export default function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <QueryClientProvider client={queryClient}>
              <Container style={styles.container} fluid>
                <Routes />
                <GlobalStyles />
              </Container>
            </QueryClientProvider>
            <Toaster />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

const styles = {
  container: {
    paddingTop: '20px',
    paddingBottom: '80px',
    textAlign: 'left',
  },
};
