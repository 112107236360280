import { AxiosError } from 'axios';
import fileSize from 'filesize';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
} from 'react-query';

import api, { BasicQuery, emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

type ContractCustomer = {
  id: number;
  tradingName: string;
};

type ContractRepresentative = {
  id: number;
  name: string;
};

type ParentContract = {
  id: number;
  number: string;
};

type ContractQuotation = {
  id: number;
  code: string;
};

type PaymentQuery = {
  id: number;
  amount: number;
  invoiceNumber: string;
  billingDate: string;
  invoiceDocumentId: number;
};

export type ContractsQuery = {
  id: number;
  createdAt: string;
  currency: number;
  customer: ContractCustomer;
  documentId: number;
  harvest: number;
  harvests: number[];
  installmentsByNumber: any;
  notes: string | null;
  number: number;
  parentContract: ParentContract | null;
  parentContractId: number | null;
  payments: PaymentQuery[];
  projectQuantity: number;
  quotation: ContractQuotation | null;
  quotationId: number | null;
  representative: ContractRepresentative;
  representativeId: number;
  requiredDate: string | null;
  signatureDate: string | null;
  status: number;
  totalAmount: number;
  type: number;
  updatedAt: string;
  workOrder: string | null;
};

export type ContractProductItem = {
  id: number;
  amount: number;
  description: string;
  division: number;
  ncm: {
    id: number;
    code: string;
  };
  number: number;
  quantity: number;
  quantityDelivered: number;
  quotationId: number;
  totalAmount: number;
  unit: { id: number; abbreviation: string };
  unitPrice: number;
};

export type ContractQuery = {
  id: number;
  createdAt: string;
  currency: number;
  customer: ContractCustomer;
  documentId: number;
  harvest: number;
  harvests: number[];
  installments: { id: number }[];
  installmentsByNumber: any;
  notes: string | null;
  number: number;
  parentContract: ParentContract | null;
  parentContractId: number | null;
  payments: PaymentQuery[];
  products: ContractProductItem[];
  projectQuantity: number;
  projects: { id: number }[];
  quotation: ContractQuotation | null;
  quotationId: number | null;
  representative: ContractRepresentative;
  representativeId: number;
  requiredDate: string | null;
  shipments: {
    id: number;
    address: string | null;
    contract: {
      id: number;
      number: string;
    };
    customer: {
      id: number;
      tradingName: string;
    };
    items: {
      id: number;
      number: number;
      quantity: number;
      product: {
        id: number;
        description: string;
        unit: {
          id: number;
          abbreviation: string;
          description: string;
        };
      };
    }[];
    contractId: number;
    shippingDate: string | null;
    shippingForecast: string | null;
  }[];
  signatureDate: string | null;
  status: number;
  totalAmount: number;
  type: number;
  updatedAt: string;
  workOrder: string | null;
};

export type ContractFilter = BasicQuery & { number?: string };

const getContractsInfinite = async (params: ContractFilter) => {
  const { data } = await api.get<Response<ContractsQuery>>(`/contracts`, {
    params,
  });

  return {
    contracts: data.data,
    pagination: data.pagination,
  };
};

export function useInfiniteContracts(filter: ContractFilter) {
  const result = useInfiniteQuery(
    ['contracts', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getContractsInfinite({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const contracts =
    result?.data?.pages
      ?.map((group) => group?.contracts?.map((contract) => contract))
      .flat() || [];

  return { ...result, pagination: pagination || emptyPagination, contracts };
}

export function useContracts(params: ContractFilter) {
  return useQuery(['contracts', params], () => getContracts(params));
}

async function getContracts(params: ContractFilter) {
  const { data } = await api.get<Response<ContractsQuery>>(`/contracts`, {
    params,
  });
  return data;
}

export function useContract(contractId: number) {
  const result = useQuery(['contract', contractId], () =>
    getContract(contractId),
  );
  return { ...result, contract: result?.data };
}

export function useContractWithDocument(contractId: number) {
  return useQuery(['contractWithDocument', contractId], () =>
    getContractWithDocument(contractId),
  );
}

async function getContractWithDocument(contractId: number) {
  const contract = await getContract(contractId);

  if (!contract.documentId) {
    return { contract, documentInput: null };
  }

  const [document, { file, filename }] = await Promise.all([
    getDocument(contract.documentId),
    getDocumentFile(contract.documentId),
  ]);

  const documentInput = {
    file,
    id: document.id,
    name: filename,
    readableSize: fileSize(file.size),
    progress: 100,
    uploaded: true,
    error: false,
    url: document.url,
  };

  return { contract, documentInput };
}

async function getContract(contractId: number) {
  const { data } = await api.get<ContractQuery>(`/contracts/${contractId}`);
  return data;
}

async function getDocument(documentId: number) {
  const { data } = await api.get(`/documents/${documentId}`);

  return data;
}

async function getDocumentFile(documentId: number) {
  const { data, headers } = await api.get(`/documents/${documentId}/file`, {
    responseType: 'blob',
  });

  const { filename } = headers;

  return { file: data, filename };
}

export function useExportContracts(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  return useMutation(exportContracts, { ...options });
}

async function exportContracts(status?: number) {
  const url = `/contracts/export`;
  const { data: file, headers } = await api.get(url, {
    ...CONFIG,
    params: { status },
  });
  const { filename } = headers;

  downloadFile(file, filename);
}

export function useExportContractsWithoutAttachment(
  options?: UseMutationOptions<void, AxiosError, void>,
) {
  return useMutation(exportContractsWithoutAttachment, { ...options });
}

async function exportContractsWithoutAttachment() {
  const url = `/contracts/reports/without-attachment`;
  const { data: file, headers } = await api.get(url, { ...CONFIG });
  const { filename } = headers;

  downloadFile(file, filename);
}

const CONFIG = { responseType: 'blob' } as const;

export function useExportProjectCost(
  options?: UseMutationOptions<void, AxiosError, void>,
) {
  return useMutation(exportProjectCost, {
    ...options,
  });
}

async function exportProjectCost() {
  const url = `/contracts/reports/project-cost`;
  const { data: file, headers } = await api.get(url, { ...CONFIG });
  const { filename } = headers;

  downloadFile(file, filename);
}

export const ContractStatus = {
  InProgress: 1,
  Finished: 2,
  Enabling: 3,
  Canceled: 4,
} as const;

type ContractStatus = typeof ContractStatus[keyof typeof ContractStatus];

export function isFinished(contract: ContractQuery) {
  return contract.status === ContractStatus.Finished;
}

export function isCanceled(contract: ContractQuery) {
  return contract.status === ContractStatus.Canceled;
}

export function formatContractStatus(status: number) {
  switch (status) {
    case ContractStatus.InProgress:
      return 'Em andamento';
    case ContractStatus.Finished:
      return 'Finalizado';
    case ContractStatus.Enabling:
      return 'Habilitação';
    case ContractStatus.Canceled:
      return 'Cancelado';
    default:
      throw new TypeError('Status de contrato inválido!');
  }
}

export const ContractTypes = {
  Regular: 1,
  Additive: 2,
  Main: 3,
  Product: 4,
} as const;

type ContractTypes = typeof ContractTypes[keyof typeof ContractTypes];

export function formatContractType(type: ContractTypes) {
  switch (type) {
    case ContractTypes.Regular:
      return 'Serviço';
    case ContractTypes.Additive:
      return 'Aditivo';
    case ContractTypes.Main:
      return 'Contrato-mãe';
    case ContractTypes.Product:
      return 'Produto';
    default:
      throw new TypeError('Tipo de contrato inválido!');
  }
}
