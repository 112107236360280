import * as React from 'react';

import { DateTime } from 'luxon';
import { Icon as IconUI } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import Tag from 'components/data/Tag';

type RecentActivity = {
  id: number;
  description: string;
  type: string;
  date: string;
  project: string;
  projectId: number;
  customer: string;
  status: {
    color: string;
    description: string;
  };
};

type RecentActivityListProps = {
  items: RecentActivity[];
  onClick: (activityId: number) => void;
};

function RecentActivityList({ items = [], onClick }: RecentActivityListProps) {
  return (
    <Wrapper>
      {items.map((activity) => (
        <RecentActivity
          key={activity.id}
          activity={{
            ...activity,
            date: DateTime.fromISO(activity.date).toFormat('dd/MM/yyyy'),
          }}
          onClick={onClick}
        />
      ))}
    </Wrapper>
  );
}

const Wrapper = styled.div``;

type RecentActivityProps = {
  activity: RecentActivity;
  onClick: (activityId: number) => void;
};

function RecentActivity({ activity, onClick }: RecentActivityProps) {
  return (
    <RecentActivityWrapper onClick={() => onClick(activity.id)}>
      <Content>
        <ContentLeft>
          <PrimaryText>{activity.type}</PrimaryText>
          <SecondaryText>{activity.customer}</SecondaryText>
          <SecondaryText>{activity.project}</SecondaryText>
          <SecondaryText>{activity.description}</SecondaryText>
        </ContentLeft>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'right',
          }}
        >
          <ContentRight>
            <PrimaryText>{activity.date}</PrimaryText>
            <div style={{ marginTop: '4px' }}>
              <Tag color={activity.status.color}>
                {activity.status.description}
              </Tag>
            </div>
          </ContentRight>
        </div>
      </Content>
      <IconWrapper>
        <Icon name="chevron right" />
      </IconWrapper>
    </RecentActivityWrapper>
  );
}

const RecentActivityWrapper = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    padding: 8px 0px;
    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  `}
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
`;

const ContentLeft = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  margin-right: 32px;
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const PrimaryText = styled.div`
  font-size: 14px;
  line-height: 1.5;
  font-weight: 600;
  color: rgb(0 0 0 / 87%);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SecondaryText = styled.div`
  font-size: 12px;
  line-height: 1.43;
  color: #788083;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const IconWrapper = styled.div`
  margin-left: 32px;
`;

const Icon = styled(IconUI)`
  color: #d9d9d9;
`;

export default RecentActivityList;
