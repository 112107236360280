import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import MonthSelect, {
  getOptionByMonth,
  MonthOption,
} from 'components/inputs/MonthSelect';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import UploadInput from 'components/inputs/UploadInput';
import { useAddFederalReport } from 'data/federal-report';
import { TEST_LOCATION } from 'data/project';
import { useUpload } from 'hooks/use-upload';
import { DOCUMENT_FEDERAL_REPORT } from 'util/Document';

const ProcedureFormInput = yup.object().shape({
  year: yup
    .number()
    .required(`Obrigatório`)
    .min(2010, 'Ano inválido')
    .max(2050, 'Ano inválido'),
  month: yup
    .object({
      value: yup.string().required(),
      label: yup.string().required(),
    })
    .required(`Obrigatório`),
  notes: yup
    .string()
    .optional()
    .nullable()
    .max(255, 'Máximo de 255 caracteres'),
});

type FileEvent = React.ChangeEvent<HTMLInputElement>;

export type ProcedureFormValues = {
  year: number;
  month: MonthOption;
  notes: string;
  locationId: number;
  documentId: number;
};

export type FederalReportModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const defaultValues = {
  year: new Date().getFullYear(),
  month: getOptionByMonth(new Date().getMonth() + 1),
};
const formId = 'form_procedure';

export default function FederalReportModal({
  isOpen,
  onClose,
}: FederalReportModalProps) {
  const documentFile = useUpload();

  const resolver = yupResolver(ProcedureFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit, register } = useForm(formOptions);

  const { mutate: addFederalReport, isLoading } = useAddFederalReport({
    onSuccess: () => handleSuccess(),
  });

  function handleSuccess() {
    toast.success('Informe do MAPA adicionado!');
    onClose();
  }

  function onSubmit(values: ProcedureFormValues) {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }

    const input = {
      year: values.year,
      month: values.month.value,
      notes: values.notes,
      locationId: values.locationId,
      documentId: documentFile.file?.id,
    };

    addFederalReport(input);
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }
  const isSubmitting = isLoading;
  const disabledButton = !documentFile.file?.url;

  const header = 'Novo informe do MAPA';

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Ano</label>
              <Controller
                control={control}
                name="year"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    decimalScale={0}
                    maxLength={4}
                    min={2010}
                    max={2050}
                    disabled={isSubmitting}
                  />
                )}
              />
              {errors.year && <InputError>{errors?.year?.message}</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Mês</label>
              <Controller
                control={control}
                name="month"
                render={({ onChange, onBlur, value }) => (
                  <MonthSelect
                    disabled={isSubmitting}
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.month && (
                <InputError>{errors?.month?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Localização</label>
            <RadioGroup>
              <RadioInput
                name="locationId"
                label="Conchal"
                value={TEST_LOCATION.conchal}
                ref={register}
                defaultChecked
              />
              <RadioInput
                name="locationId"
                label="Engenheiro"
                value={TEST_LOCATION.engenheiro}
                ref={register}
              />
            </RadioGroup>
          </Form.Field>
          <Form.Field>
            <label>Observações (opcional)</label>
            <Controller
              control={control}
              name="notes"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmitting}
                  maxLength={20}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.notes && <InputError>{errors?.notes?.message}</InputError>}
          </Form.Field>
          <Form.Field>
            <label>Documento</label>
            <UploadInput
              name="document"
              uploadedFile={documentFile?.file}
              onUpload={(e: FileEvent) =>
                handleDocumentFileChange(e, DOCUMENT_FEDERAL_REPORT)
              }
              onDelete={documentFile.handleDelete}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || disabledButton}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
