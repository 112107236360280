import * as yup from 'yup';

export type CropFormValues = yup.InferType<typeof CropFormInput>;

export const CropFormInput = yup.object({
  name: yup
    .string()
    .required('Obrigatório')
    .max(255, 'Máximo de 255 caracteres')
    .typeError('Obrigatório'),
  nameInt: yup
    .string()
    .required('Obrigatório')
    .max(255, 'Máximo de 255 caracteres')
    .typeError('Obrigatório'),
  insideCode: yup
    .string()
    .required('Obrigatório')
    .min(3, 'Deve conter 3 caracteres')
    .max(3, 'Deve conter 3 caracteres')
    .typeError('Obrigatório'),
});
