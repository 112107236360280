import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Modal,
  Button,
  Message,
  Form,
  Grid,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useUpdateProjectCrop } from 'mutations/project';
import { useProject } from 'queries/project';
import { emptyStringToUndefined } from 'util/validation';

type ChangeProjectCropModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
};

type FormValues = {
  variety: string;
};

const schema = yup
  .object()
  .shape({
    variety: yup
      .string()
      .required('Obrigatório')
      .min(3, 'Mínimo de 3 caracteres')
      .transform(emptyStringToUndefined),
  })
  .required('Obrigatório');

const formId = 'form_crop_variety';

export default function ChangeProjectCropModal({
  isOpen,
  onClose,
  projectId,
}: ChangeProjectCropModalProps) {
  const { handleError } = useErrorHandler();

  const resolver = yupResolver(schema);
  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { data, isLoading } = useProject(projectId);
  const {
    mutate: changeProjectCrop,
    isLoading: isMutating,
    error,
  } = useUpdateProjectCrop({
    onSuccess: () => {
      toast.success('Variedade alterada com sucesso!');
      onClose();
    },
  });

  const projectCrop = data?.project?.projectCrops?.length
    ? data?.project?.projectCrops?.[0]
    : null;

  React.useEffect(() => {
    if (projectCrop) {
      const variety = projectCrop.variety || '';
      setValue('variety', variety);
    }
  }, [projectCrop, setValue]);

  function onSubmit(values: FormValues) {
    const input = {
      projectId,
      projectCropId: projectCrop.id,
      variety: values.variety,
    };
    changeProjectCrop(input);
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Alterar cultura</Modal.Header>
      <Modal.Content>
        {projectId && isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    <label>Variedade/Híbrido</label>
                    <Controller
                      control={control}
                      name="variety"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isMutating}
                          loading={isMutating}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.variety && (
                      <InputError>{errors?.variety?.message}</InputError>
                    )}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
        {error ? <Message content={handleError(error)} negative /> : null}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isMutating}
          disabled={isMutating}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
