import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export type ChangeRoleFormValues = yup.InferType<typeof ChangeRoleFormInput>;

export const ChangeRoleFormInput = yup.object({
  role: yup
    .object({
      label: yup.string().required(),
      value: yup.string().required(),
    })
    .required('Obrigatório')
    .typeError('Obrigatório')
    .transform(emptyStringToUndefined),
});
