import * as React from 'react';

import { Button, Divider, Modal, Table } from 'semantic-ui-react';

export type ShipmentItemProps = {
  id: number;
  number: number;
  product: string;
  quantity: number;
  unit: string;
};

export type ShipmentItemsModalProps = {
  isOpen: boolean;
  items: ShipmentItemProps[];
  onClose: () => void;
};

export default function ShipmentItemsModal({
  isOpen,
  items,
  onClose,
}: ShipmentItemsModalProps) {
  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Itens da remessa</Modal.Header>
      <Modal.Content>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>N° do item</Table.HeaderCell>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Quantidade</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {items?.map((item) => (
              <Table.Row>
                <Table.Cell>{item.number}</Table.Cell>
                <Table.Cell>{item.product}</Table.Cell>
                <Table.Cell textAlign="right">{`${item.quantity} ${item.unit}`}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Fechar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
