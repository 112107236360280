import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

const schema = yup
  .object()
  .shape({
    description: yup
      .string()
      .required('Descrição é um campo obrigatório')
      .max(40, 'Máximo de 40 caracteres')
      .transform(emptyStringToUndefined),
    abbreviation: yup
      .string()
      .required('Abreviatura é um campo obrigatório')
      .max(40, 'Máximo de 40 caracteres')
      .transform(emptyStringToUndefined),
  })
  .required();

export default schema;
