import React from 'react';

import { Grid, Tab } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import ProjectsPage from 'pages/pesquisa/estudos';
import Dashboard from 'templates/Dashboard';
import FederalReports from 'templates/FederalReports';
import ProfitSharing from 'templates/ProfitSharing';
import Properties from 'templates/Properties';
import Weathers from 'templates/Weathers';

import Activities from './atividades';
import { useResearchTabs } from './use-research-tabs';

const paneStyle = { paddingTop: '20px' };
const panes = [
  {
    menuItem: 'Dashboard',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <Dashboard />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Atividades',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <Activities />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Estudos',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <ProjectsPage />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'PLR',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <ProfitSharing />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Dados meteorológicos',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <Weathers />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Propriedades',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <Properties />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'MAPA',
    render: () => (
      <Tab.Pane as="div" style={paneStyle} attached="false">
        <FederalReports />
      </Tab.Pane>
    ),
  },
];

export default function ResearchPage() {
  const { activeIndex, toggle } = useResearchTabs();

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Tab
                activeIndex={activeIndex}
                menu={{ color: 'blue', secondary: true, pointing: true }}
                onTabChange={(_, tabData) => {
                  toggle(tabData.activeIndex as number);
                }}
                panes={panes}
                renderActiveOnly
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}
