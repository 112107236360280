import React from 'react';

import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import 'react-circular-progressbar/dist/styles.css';
import './semantic-ui-custom.css';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));
