import styled from 'styled-components';

export const Container = styled.div``;

export const UploadContainer = styled.div`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: height 0.2s ease;
`;

export const UploadMessage = styled.p`
  display: flex;
  height: 56px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  color: #999;
  font-weight: bold;
`;
