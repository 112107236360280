import { quotationRepository } from '../container';

export default async function getQuotations({
  page,
  pageSize,
  orderBy,
  sort,
  code,
  status,
  notBound,
}) {
  const { data, pagination } = await quotationRepository.getQuotations({
    page,
    pageSize,
    orderBy,
    sort,
    code,
    status,
    notBound,
  });

  return { data, pagination };
}
