import React from 'react';

import toast from 'react-hot-toast';
import { Grid, Header, Loader, Icon, Button } from 'semantic-ui-react';

import AlertModal from 'components/layout/AlertModal';
import { useModalWithData } from 'hooks/useModal';
import {
  useCreateEvaluation,
  useRemoveEvaluation,
  useUpdateEvaluation,
} from 'mutations/evaluation';
import { useEvaluations } from 'queries/evaluation';

import AddEvaluationModal from './AddEvaluationModal';
import AttachmentListModal from './AttachmentListModal';
import EvaluationTable from './EvaluationTable';

const MESSAGE_CREATE_SUCCESS = 'Avaliação adicionada com sucesso!';
const MESSAGE_UPDATE_SUCCESS = 'Avaliação atualizada com sucesso!';
const MESSAGE_REMOVE_SUCCESS = 'Avaliação removida com sucesso!';

export default function ProjectEvaluationsPage({ projectId }) {
  const [error, setError] = React.useState(false);

  const evaluationModal = useModalWithData();
  const attachmentModal = useModalWithData();
  const removeModal = useModalWithData();

  const evaluationsFilter = {
    projectId,
    page: 1,
    pageSize: 100,
    orderBy: 'date',
    sort: 'asc',
  };
  const { data: evaluationsData, isLoading, isSuccess } = useEvaluations(
    evaluationsFilter,
  );

  const {
    mutate: createEvaluation,
    isLoading: isCreating,
  } = useCreateEvaluation({
    onSuccess: () => onSuccess(MESSAGE_CREATE_SUCCESS),
    onFailure,
  });

  const {
    mutate: mutateUpdateEvaluation,
    isLoading: isUpdating,
  } = useUpdateEvaluation({
    onSuccess: () => onSuccess(MESSAGE_UPDATE_SUCCESS),
    onFailure,
  });

  function onSuccess(message) {
    toggleModal();
    setError(null);
    toast.success(message);
  }

  const {
    mutate: removeEvaluation,
    isLoading: isRemoving,
  } = useRemoveEvaluation({
    onSuccess: onRemoveSuccess,
    onError,
  });

  function onRemoveSuccess() {
    removeModal.close();
    toast.success(MESSAGE_REMOVE_SUCCESS);
  }

  function onError() {
    removeModal.close();
  }

  function toggleModal() {
    if (evaluationModal.isOpen) {
      evaluationModal.close();
    } else {
      evaluationModal.open();
    }
  }

  function handleAttachmentClose() {
    attachmentModal.close();
  }

  function onFailure(e) {
    setError(e);
  }

  function handleAddClick() {
    evaluationModal.open();
  }

  function handleEditClick(evaluation) {
    evaluationModal.open(evaluation);
  }

  function handleRemoveClick(project) {
    removeModal.open(project);
  }

  function handleAttachmentClick(evaluation) {
    attachmentModal.open(evaluation.documents);
  }

  function handleClose() {
    evaluationModal.close();
  }

  function handleRemoveClose() {
    removeModal.close();
  }

  async function handleSubmit(values) {
    if (evaluationModal.data) {
      mutateUpdateEvaluation({
        ...values,
        projectId,
        evaluationId: evaluationModal.data?.id,
      });
      return;
    }

    createEvaluation({ projectId, ...values });
  }

  function handleRemoveSubmit() {
    if (removeModal.data) {
      removeEvaluation({
        projectId,
        evaluationId: removeModal.data.id,
      });
    }
  }

  const isEmpty = evaluationsData?.evaluations?.length === 0;

  return (
    <>
      <AlertModal
        header="Remover avaliação"
        content="Você tem certeza que deseja remover esta avaliação?"
        submitText="Remover"
        onClose={handleRemoveClose}
        onSubmit={handleRemoveSubmit}
        isOpen={removeModal.isOpen}
        isLoading={isRemoving}
        negative
      />

      <AttachmentListModal
        documents={attachmentModal.data}
        isOpen={attachmentModal.isOpen}
        onClose={handleAttachmentClose}
      />

      <Grid>
        {!isLoading && !isEmpty ? (
          <>
            <Grid.Column width={8} textAlign="left" verticalAlign="middle">
              <Header as="h2">Avaliações</Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right" verticalAlign="middle">
              <Button
                type="button"
                onClick={() => handleAddClick()}
                primary
                basic
              >
                <Icon name="add" />
                Nova avaliação
              </Button>
            </Grid.Column>
          </>
        ) : null}
        <Grid.Column width={16}>
          {isLoading ? (
            <Loader active size="large" style={{ marginTop: '200px' }}>
              Carregando...
            </Loader>
          ) : null}
          {isSuccess ? (
            <EvaluationTable
              evaluations={evaluationsData?.evaluations}
              onAddClick={handleAddClick}
              onEditClick={handleEditClick}
              onAttachmentClick={handleAttachmentClick}
              onRemoveClick={handleRemoveClick}
              loading={isLoading}
            />
          ) : null}
        </Grid.Column>
      </Grid>

      {evaluationModal.isOpen ? (
        <AddEvaluationModal
          projectId={projectId}
          evaluationId={
            evaluationModal.data ? evaluationModal.data.id : undefined
          }
          error={error}
          onClose={handleClose}
          onSubmit={handleSubmit}
          open={evaluationModal.isOpen}
          isSubmiting={isCreating || isUpdating}
        />
      ) : null}
    </>
  );
}
