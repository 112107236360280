import React from 'react';

import Select from 'react-select';

import { formatStatus, QuotationStatus } from 'queries/quotation';

export default function QuotationStatusSelect({
  name,
  placeholder = 'Selecione',
  value,
  onChange,
  disabled = false,
  isClearable = false,
  isMulti = false,
}) {
  const options = Object.entries(QuotationStatus).map(([, status]) => {
    return { value: status, label: formatStatus(status) };
  });

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
}
