import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Divider, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { QuotationsQueryFormatted } from 'queries/quotation';
import { Pagination } from 'util/api';
import { formatCurrencyWithSymbol } from 'util/Currency';

type QuotationsTableProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  pagination: Pagination;
  quotations: QuotationsQueryFormatted[];
};

export default function QuotationsTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  pagination,
  quotations = [],
}: QuotationsTableProps) {
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Orçamentos" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="large" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Orçamento</Table.HeaderCell>
              <Table.HeaderCell>Cliente</Table.HeaderCell>
              <Table.HeaderCell>Tipo</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Responsável</Table.HeaderCell>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell width={2}>Contrato</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={7} rows={5} />}
            {!isLoading &&
              quotations.map((quotation) => {
                const {
                  id,
                  code,
                  quotationDateFormatted,
                  customer,
                  representative,
                  harvest,
                  quotationTypeDescription,
                  quotationStatusDescription,
                  quotationStatusColor,
                  quotationResponsible,
                  totalAmount,
                  totalAmountWithDiscount,
                  discountPercent,
                  currency,
                  divisionsFormatted,
                  hasDiscount,
                } = quotation;

                return (
                  <Table.Row key={id}>
                    <Table.Cell title={code || 'Sem código'}>
                      <Text variant="primary">
                        <Link to={`/orcamentos/${id}`}>
                          {code || 'Sem código'}
                        </Link>
                      </Text>
                      <Text variant="secondary">{harvest}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{customer?.tradingName}</Text>
                      <Text variant="secondary">{representative?.name}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Tag color="grey">{quotationTypeDescription}</Tag>
                    </Table.Cell>
                    <Table.Cell>
                      <Tag color={quotationStatusColor}>
                        {quotationStatusDescription}
                      </Tag>
                    </Table.Cell>
                    <Table.Cell title={divisionsFormatted.join(', ') || ''}>
                      <Text variant="primary">
                        {quotationResponsible.fullName}
                      </Text>
                      <Text variant="secondary">
                        {divisionsFormatted.join(', ') || ''}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{quotationDateFormatted}</Text>
                      <Text variant="secondary">
                        {formatResponseDate(quotation)}
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      {renderContractGeneratedTag(quotation)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Text variant="primary">
                        {hasDiscount
                          ? formatCurrencyWithSymbol(
                              totalAmountWithDiscount,
                              currency,
                            )
                          : formatCurrencyWithSymbol(totalAmount, currency)}
                      </Text>
                      <Text variant="secondary">
                        {hasDiscount ? `${discountPercent}% de desconto` : ''}
                      </Text>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            <Table.Row>
              <Table.Cell textAlign="center" colSpan={6}>
                <Divider hidden />
                {hasNextPage ? (
                  <Button
                    type="button"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Carregando...' : ''}
                    {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                  </Button>
                ) : (
                  <Text variant="secondary">Nada mais para mostrar</Text>
                )}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

function formatResponseDate(quotation: QuotationsQueryFormatted) {
  if (quotation.status === 2 || quotation.status === 4) {
    return `Aprovado em ${quotation.responseDateFormatted.substring(0, 5)}`;
  }

  if (quotation.status === 3) {
    return `Não aprovado em ${quotation.responseDateFormatted}`;
  }

  return 'Sem resposta';
}

function renderContractGeneratedTag(quotation: QuotationsQueryFormatted) {
  if (quotation.status === 2 && quotation.isContractGenerated) {
    return <Tag color="transparent">Gerado</Tag>;
  }

  if (quotation.status === 2 && !quotation.isContractGenerated) {
    return <Tag color="yellow">Pendente</Tag>;
  }

  return '';
}
