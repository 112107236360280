import React from 'react';

import Select from 'react-select';

import { activityTypes } from 'queries/activity';

const options = Object.entries(activityTypes)
  .map(([key, value]) => ({
    value: parseInt(key, 10),
    label: value,
  }))
  .slice()
  .sort(sortByLabel);

function sortByLabel(itemA, itemB) {
  if (itemA.label < itemB.label) {
    return -1;
  }
  if (itemA.label > itemB.label) {
    return 1;
  }
  return 0;
}

export default function ActivityTypeSelect({
  disabled = false,
  isClearable = false,
  name,
  onBlur,
  onChange,
  placeholder = 'Selecione',
  value = undefined,
}) {
  return (
    <Select
      name={name}
      instanceId={name}
      placeholder={placeholder}
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete="off"
    />
  );
}
