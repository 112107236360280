import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'react-currency-input';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Button,
  Form,
  Input,
  Divider,
  TextArea,
  Grid,
  Header,
} from 'semantic-ui-react';

import CropSearch from 'components/inputs/CropSearch';
import DivisionSelect from 'components/inputs/DivisionSelect';
import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import TargetSearch from 'components/inputs/TargetSearch';
import {
  formatProjectGoal,
  formatManagementType,
  formatTestLocation,
  PROJECT_GOAL,
  MANAGEMENT_TYPE,
  TEST_LOCATION,
  DIVISION,
} from 'data/project';

import { schema, schemaWithProjectsNumber } from './validation';

const DEFAULT_VALUES = {
  description: '',
  protocol: '',
  division: '',
  startForecast: '',
  endForecast: '',
  crops: '',
  targets: '',
  amount: '',
  treatmentQuantity: '',
  trialsNumber: '',
  projectsNumber: 1,
};

export default function QuotationProjectForm({
  onSubmit,
  formValues = undefined,
  isSubmiting,
}) {
  const formRef = React.useRef(null);

  const defaultValues = formValues || DEFAULT_VALUES;
  const definedSchema = !formValues ? schemaWithProjectsNumber : schema;
  const resolver = yupResolver(definedSchema);

  const { control, errors, register, watch, handleSubmit, setValue } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
  });
  const watchDivision = watch('division');
  const watchDurationForecast = watch('durationForecast');

  const isEditing = !!formValues;
  const submitButtonText = isEditing ? 'Salvar' : 'Adicionar item';

  const MAX_VAL = 100;
  // eslint-disable-next-line consistent-return
  function withValueLimit(inputObj) {
    const { value } = inputObj;
    if (value < MAX_VAL) return inputObj;
  }

  return (
    <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={5}>
            <Header as="h3">Estudo</Header>
          </Grid.Column>
          <Grid.Column width={11}>
            <Form.Field>
              <label>Finalidade</label>
              <RadioGroup>
                <RadioInput
                  name="projectGoal"
                  label={formatProjectGoal(PROJECT_GOAL.exploratory)}
                  ref={register}
                  value={PROJECT_GOAL.exploratory}
                />
                <RadioInput
                  name="projectGoal"
                  label={formatProjectGoal(PROJECT_GOAL.oficial)}
                  ref={register}
                  value={PROJECT_GOAL.oficial}
                />
              </RadioGroup>
            </Form.Field>
            <Form.Field width={8}>
              <label>Setor</label>
              <Controller
                control={control}
                name="division"
                render={({ onChange, onBlur, value }) => (
                  <DivisionSelect
                    onChange={(data) => {
                      setValue('managementType', null);
                      onChange(data);
                    }}
                    onBlur={onBlur}
                    value={value}
                    disabled={isSubmiting}
                    autoComplete="off"
                  />
                )}
              />
              {errors.division && (
                <InputError>{errors?.division?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Tipo de condução</label>
              <RadioGroup>
                <RadioInput
                  name="managementType"
                  label={formatManagementType(MANAGEMENT_TYPE.field)}
                  ref={register}
                  value={MANAGEMENT_TYPE.field}
                  disabled={
                    !watchDivision ||
                    (watchDivision.value &&
                      !DIVISION[watchDivision.value].managementTypes.includes(
                        MANAGEMENT_TYPE.field,
                      ))
                  }
                />
                <RadioInput
                  name="managementType"
                  label={formatManagementType(MANAGEMENT_TYPE.greenhouse)}
                  ref={register}
                  value={MANAGEMENT_TYPE.greenhouse}
                  disabled={
                    !watchDivision ||
                    (watchDivision.value &&
                      !DIVISION[watchDivision.value].managementTypes.includes(
                        MANAGEMENT_TYPE.greenhouse,
                      ))
                  }
                />
                <RadioInput
                  name="managementType"
                  label={formatManagementType(MANAGEMENT_TYPE.laboratory)}
                  ref={register}
                  value={MANAGEMENT_TYPE.laboratory}
                  disabled={
                    !watchDivision ||
                    (watchDivision.value &&
                      !DIVISION[watchDivision.value].managementTypes.includes(
                        MANAGEMENT_TYPE.laboratory,
                      ))
                  }
                />
              </RadioGroup>
              {errors?.managementType && (
                <InputError>{errors?.managementType?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Unidade teste</label>
              <RadioGroup>
                <RadioInput
                  name="testLocation"
                  label={formatTestLocation(TEST_LOCATION.conchal)}
                  value={TEST_LOCATION.conchal}
                  ref={register}
                />
                <RadioInput
                  name="testLocation"
                  label={formatTestLocation(TEST_LOCATION.external)}
                  value={TEST_LOCATION.external}
                  ref={register}
                />
              </RadioGroup>
              {errors?.testLocation && (
                <InputError>{errors?.testLocation?.message}</InputError>
              )}
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Previsão de condução (meses)</label>
                <Controller
                  control={control}
                  name="durationForecast"
                  defaultValue=""
                  render={({ onChange, onBlur, value }) => (
                    <NumberFormat
                      onValueChange={(data) => {
                        onChange(data.floatValue);
                      }}
                      onBlur={onBlur}
                      value={value}
                      decimalScale={0}
                      disabled={isSubmiting}
                      isAllowed={withValueLimit}
                      suffix={watchDurationForecast === 1 ? ' mês' : ' meses'}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.durationForecast && (
                  <InputError>{errors?.durationForecast?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Protocolo (opcional)</label>
                <Controller
                  control={control}
                  name="protocol"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      loading={isSubmiting}
                      autoComplete="off"
                      fluid
                    />
                  )}
                />
              </Form.Field>
              <Form.Field />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Culturas (opcional)</label>
                <Controller
                  control={control}
                  name="crops"
                  render={({ onChange, onBlur, value }) => (
                    <CropSearch
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  )}
                />
              </Form.Field>
              <Form.Field>
                <label>Alvos (opcional)</label>
                <Controller
                  control={control}
                  name="targets"
                  render={({ onChange, onBlur, value }) => (
                    <TargetSearch
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Descrição</label>
              <Controller
                control={control}
                name="description"
                render={({ onChange, onBlur, value }) => (
                  <TextArea
                    style={{ minHeight: 80 }}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isSubmiting}
                    loading={isSubmiting}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors?.description && (
                <InputError>{errors?.description?.message}</InputError>
              )}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
        <Divider />
        <Grid.Row>
          <Grid.Column width={5}>
            <Header as="h3">Tratamentos</Header>
          </Grid.Column>
          <Grid.Column width={11}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Custo por tratamento</label>
                <Controller
                  control={control}
                  name="amount"
                  render={({ onChange, onBlur, value }) => (
                    <CurrencyInput
                      onChange={(_, floatValue) => {
                        onChange(floatValue);
                      }}
                      onBlur={onBlur}
                      value={value}
                      decimalSeparator=","
                      thousandSeparator="."
                      precision="2"
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.amount && (
                  <InputError>{errors?.amount?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Nº de tratamentos</label>
                <Controller
                  control={control}
                  name="treatmentQuantity"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      loading={isSubmiting}
                      autoComplete="off"
                      fluid
                    />
                  )}
                />
                {errors.treatmentQuantity && (
                  <InputError>{errors?.treatmentQuantity?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Nº de repetições (opcional)</label>
                <Controller
                  control={control}
                  name="trialsNumber"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      loading={isSubmiting}
                      maxLength={3}
                      autoComplete="off"
                      fluid
                    />
                  )}
                />
                {errors.trialsNumber && (
                  <InputError>{errors?.trialsNumber?.message}</InputError>
                )}
              </Form.Field>
            </Form.Group>
            {!formValues ? (
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Nº de estudos adicionados (iguais)</label>
                  <Controller
                    control={control}
                    name="projectsNumber"
                    defaultValue="1"
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isSubmiting}
                        loading={isSubmiting}
                        maxLength={2}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.projectsNumber && (
                    <InputError>{errors?.projectsNumber?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
                <Form.Field />
              </Form.Group>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5} />
          <Grid.Column width={11}>
            <Form.Field>
              <Button type="submit" size="large" loading={isSubmiting} primary>
                {submitButtonText}
              </Button>
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Form>
  );
}
