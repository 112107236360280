import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
} from 'react-query';

import { CollectionQuery } from 'data/collection';
import { ProjectOverview } from 'data/project.types';
import api, { emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

export type ProjectsInDebtQuery = {
  id: number;
  harvest: number;
  insideCode: string;
  protocol: string;
  requiredDate: string;
  reassignedDate: string | null;
  forecastDate: string;
  isReassigned: boolean;
  deliveryDate: string | null;
  projectStage: {
    id: number;
    description: string;
  };
  division: {
    id: number;
    description: number;
  };
  responsibleTechnician: {
    id: number;
    name: string;
  };
  status: {
    description: string;
    details: string;
    color: string;
  };
  contract: {
    id: number;
    number: string;
    currency: {
      id: number;
      description: string;
    };
  };
  customer: {
    id: number;
    tradingName: string;
  };
  representative: {
    id: number;
    name: string;
  };
  totalAmount: number;
  projectCrops: {
    id: number;
    cropStage: string;
    crop: {
      id: number;
      name: string;
    };
  }[];
  projectTargets: {
    id: number;
    target: {
      id: number;
      name: string;
    };
  }[];
  installments: {
    id: number;
    number: number;
    amount: number;
    paymentForecast: string | null;
    projectId: number;
    paymentId: number | null;
    payment: {
      id: number;
      invoiceNumber: string;
      billingDate: string;
      invoiceDocumentId: number;
    };
  }[];
};

export type ProjectsInDebtFilter = {
  harvest?: number;
  customerIds?: number[] | null;
  projectCode?: string | null;
  divisions?: number[] | null;
  contractNumber?: string | null;
  projectStages?: number[] | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

export function useInfiniteProjectsInDebt(filter: ProjectsInDebtFilter) {
  const result = useInfiniteQuery(
    ['projectsInDebt', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getProjectsInDebt({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const projects =
    result?.data?.pages
      ?.map((group) => group?.projects?.map((project) => project))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    projects,
  };
}

async function getProjectsInDebt(filter: ProjectsInDebtFilter) {
  const { data } = await api.get<Response<ProjectsInDebtQuery>>(
    '/admin/projects/debts',
    {
      params: { ...filter },
    },
  );
  return { projects: data.data, pagination: data.pagination };
}

export function useExportProjectsInDebt(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ProjectsInDebtFilter,
    () => void
  >,
) {
  return useMutation(exportProjectsInDebt, {
    ...options,
  });
}

const CONFIG = { responseType: 'blob' } as const;

async function exportProjectsInDebt(params: ProjectsInDebtFilter) {
  const url = `/admin/projects/debts/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}

export type ProfitSharingProjectsFilter = {
  year: number;
  month?: number | null;
  customerId?: number | null;
  divisions?: number[] | null;
  projectStage?: number | null;
  status?: number | null;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

export type ProfitSharingProjectsQuery = ProjectsInDebtQuery;

export function useInfiniteProfitSharingProjects(
  filter: ProfitSharingProjectsFilter,
) {
  const result = useInfiniteQuery(
    ['projects', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getProfitSharingProjects({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const projects =
    result?.data?.pages
      ?.map((group) => group?.projects?.map((project) => project))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    projects,
  };
}

async function getProfitSharingProjects(filter: ProfitSharingProjectsFilter) {
  const { data } = await api.get<Response<ProfitSharingProjectsQuery>>(
    '/admin/projects/profit-sharing',
    {
      params: { ...filter },
    },
  );
  return { projects: data.data, pagination: data.pagination };
}

type ExportProfitSharingProjectsFilter = Omit<
  ProfitSharingProjectsFilter,
  'page' | 'pageSize'
>;

export function useExportProfitSharingProjects(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ExportProfitSharingProjectsFilter,
    () => void
  >,
) {
  return useMutation(exportProfitSharingProjects, {
    ...options,
  });
}

async function exportProfitSharingProjects(
  params: ExportProfitSharingProjectsFilter,
) {
  const url = `/admin/projects/profit-sharing/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}

export function useGenerateProfitSharingDocument(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ExportProfitSharingProjectsFilter,
    () => void
  >,
) {
  return useMutation(generateProfitSharingDocument, {
    ...options,
  });
}

async function generateProfitSharingDocument(
  params: ExportProfitSharingProjectsFilter,
) {
  const url = `/admin/projects/profit-sharing/document`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}

// Research

type ProjectsQueryContract = {
  id: number;
  number: string;
  currency: number;
};

type ProjectsQueryCustomer = {
  id: number;
  tradingName: string;
};

type ProjectsQueryRepresentative = {
  id: number;
  name: string;
};

type ProjectsQueryResponsible = {
  id: number;
  firstName: string | null;
  lastName: string;
};

type ProjectsQueryCrop = {
  id: number;
  crop: {
    id: number;
    name: string;
  };
  projectId: number;
  variety?: string | null;
  plantingDate?: string | null;
  customPlantingDate?: string | null;
  seedlingEmergenceDate?: string | null;
  cropStage?: string | null;
  bbchScale?: string | null;
  plantsNumber?: number | null;
  plantsNumberUnitId?: number | null;
};

type ProjectsQueryTarget = {
  id: number;
  name: string;
  projectId: number;
};

type ProjectsQueryProduct = {
  id: number;
  name: string;
};

type ProjectsQueryInstallment = {
  id: number;
  number: number;
  paymentForecast: string | null;
  paymentDate: string | null;
  amount: number;
  invoiceNumber: string | null;
  currency: number | null;
  projectId: number;
  paymentId: number | null;
  invoiceDocumentId: number | null;
};

export type PropertyQuery = {
  id: number;
  name: string;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  cep: string;
  owner: string;
  ownerDocument: string;
  phone: string;
  ownerAddress: string | null;
  ownerNeighborhood: string | null;
  ownerCep: string | null;
  ownerCity: string | null;
  ownerState: string | null;
};

export type LocationQuery = {
  id: number;
  latitude: string;
  longitude: string;
  altitude: number;
  locationSketchId: number;
  accessSketchId: number;
  locationId: number;
  property: PropertyQuery;
};

export type ProjectsQuery = {
  id: number;
  harvest: number;
  requiredDate: string;
  contractId: number;
  projectGoal: number;
  projectStage: number;
  managementType: number;
  division: number;
  testLocation: number;
  cancellationReason: string | null;
  reinstalled: boolean;
  updatedAt: string;
  protocol: string | null;
  insideCode: string | null;
  notes: string | null;
  protocolDocumentId: number | null;
  retDocumentId: number | null;
  areaAssignmentDocumentId: number | null;
  reportReviewDocumentId: number | null;
  reportDocumentId: number | null;
  treatmentsNumber: number | null;
  trialsNumber: number | null;
  objective: string | null;
  applicationMethodology: string | null;
  evaluationMethodology: string | null;
  experimentalDesign: string | null;
  equipment: string | null;
  equipmentVolume: string | null;
  equipmentBar: string | null;
  nozzlesModel: string | null;
  plantSpacingLength: number | null;
  plantSpacingWidth: number | null;
  plantSpacingUnit: null;
  cropStage: string | null;
  blockLength: number | null;
  blockWidth: number | null;
  blockUnit: number | null;
  secondRetDocumentId: number | null;
  thirdRetDocumentId: number | null;
  fourthRetDocumentId: number | null;
  createdAt: string;
  contract: ProjectsQueryContract;
  customer: ProjectsQueryCustomer;
  representative: ProjectsQueryRepresentative;
  responsible: ProjectsQueryResponsible;
  projectCrops: ProjectsQueryCrop[];
  targets: ProjectsQueryTarget[];
  products: ProjectsQueryProduct[];
  installments: ProjectsQueryInstallment[];
  totalValue: number;
};

export type ProjectQuery = ProjectsQuery & {
  activities: [];
};

type ProjectFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

export const projectKeys = {
  all: ['projects'] as const,
  detail: (id: number) => [...projectKeys.all, id] as const,
};

export function useProject(projectId: number) {
  return useQuery(projectKeys.detail(projectId), () => getProject(projectId));
}

async function getProject(projectId: number) {
  const url = `/projects/${projectId}/overview`;
  const { data } = await api.get<ProjectOverview>(url);

  const {
    project,
    contract,
    history,
    treatments,
    installation,
    documents,
  } = data;

  return {
    project,
    contract,
    history,
    treatments: treatments || [],
    installation,
    documents,
  };
}

export function useInfiniteProjects(filter: ProjectFilter) {
  const result = useInfiniteQuery(
    ['projects', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getProjects({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const projects =
    result?.data?.pages
      ?.map((group) => group?.projects?.map((project) => project))
      .flat() || [];

  return { ...result, pagination: pagination || emptyPagination, projects };
}

async function getProjects(filter: ProjectFilter) {
  const { data } = await api.get<Response<ProjectsQuery>>('/projects', {
    params: { ...filter },
  });
  return { projects: data.data, pagination: data.pagination };
}

export function useProjectColections(projectId: number) {
  const result = useQuery({
    queryKey: ['projects', projectId, 'collections'],
    queryFn: () => getProjectCollections(projectId),
  });
  return { ...result, collections: result?.data || [] };
}

async function getProjectCollections(projectId: number) {
  const url = `/projects/${projectId}/collections`;
  const { data } = await api.get<CollectionQuery[]>(url);
  return data;
}
