import React from 'react';

import FileItem from '../FileItem';
import FileUpload from '../FileUpload';

export default function UploadInput({
  name,
  uploadedFile,
  onUpload,
  onDelete,
}) {
  return (
    <>
      {!uploadedFile && <FileUpload name={name} onUpload={onUpload} />}
      {uploadedFile && (
        <FileItem name={name} uploadedFile={uploadedFile} onDelete={onDelete} />
      )}
    </>
  );
}
