/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Divider, Form, Modal, TextArea } from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useChangeShipping } from 'data/shipment';

export type ChangeShippingFormValues = {
  address: string;
  shippingDate: Date;
};

export type ChangeShippingModalProps = {
  formValues?: ChangeShippingFormValues;
  isOpen: boolean;
  onClose: () => void;
  shipmentId: number;
};

const FORM_ID = 'form_change_shipping';

const ChangeShippingInput = yup.object({
  address: yup
    .string()
    .required('Obrigatório')
    .max(500, 'Máximo de 500 caracteres')
    .typeError('Obrigatório'),
  shippingDate: yup.date().required(),
});

export default function ChangeShippingModal({
  formValues,
  isOpen,
  onClose,
  shipmentId,
}: ChangeShippingModalProps) {
  const defaultValues = formValues;
  const resolver = yupResolver(ChangeShippingInput);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const changeShipping = useChangeShipping({
    onSuccess: () => handleSuccess('Envio alterado!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ChangeShippingFormValues) {
    changeShipping.mutate({
      address: values.address,
      shippingDate: values.shippingDate,
      shipmentId,
    });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alteração de envio</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field width={6}>
            <label>Previsão de expedição</label>
            <Controller
              control={control}
              name="shippingDate"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
          </Form.Field>
          <Form.Field>
            <label>Endereço</label>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{ required: 'Obrigatório' }}
              render={({ onChange, onBlur, value }) => (
                <TextArea
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={changeShipping.isLoading}
                  loading={changeShipping.isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.address && (
              <InputError>{errors?.address?.message}</InputError>
            )}
          </Form.Field>
        </Form>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={changeShipping.isLoading}
          form={FORM_ID}
          loading={changeShipping.isLoading}
          primary
          type="submit"
        >
          Alterar envio
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
