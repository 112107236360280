import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';

export default function EditProjectForm({
  formValues,
  isSubmiting = false,
  onSubmit,
}) {
  const defaultValues = formValues;

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Código interno</label>
        <Controller
          control={control}
          name="insideCode"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
              fluid
            />
          )}
        />
      </Form.Field>
      <Form.Field>
        <label>Protocolo</label>
        <Controller
          control={control}
          name="protocol"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
              fluid
            />
          )}
        />
      </Form.Field>
      <Form.Field>
        <label>Observações</label>
        <Controller
          control={control}
          name="notes"
          rules={{
            maxLength: {
              value: 750,
              message: 'Observações deve conter até 750 caracteres',
            },
          }}
          render={({ onChange, onBlur, value }) => (
            <TextArea
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
              fluid
            />
          )}
        />
        {errors.notes && <InputError>{errors?.notes?.message}</InputError>}
      </Form.Field>

      <Button
        type="submit"
        primary
        style={{ marginTop: 20 }}
        loading={isSubmiting}
      >
        Atualizar
      </Button>
    </Form>
  );
}
