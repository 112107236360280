import styled, { css } from 'styled-components';

import { LinkButtonProps } from '.';

export const Button = styled.button<LinkButtonProps>`
  ${({ bold }) => css`
    padding: 0;
    margin: 0 8px;
    background: transparent;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: ${bold ? 'bold' : 'normal'};
    color: #4a9add;
    cursor: pointer;
  `}
`;
