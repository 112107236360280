import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid, Tab } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Customers from 'templates/Customers';
import Divisions from 'templates/Divisions';
import Employees from 'templates/Employees';
import MeasurementUnits from 'templates/MeasurementUnits';
import Targets from 'templates/Targets';

import CropsPage from './culturas';
import DocumentsPage from './documentos';
import CompanyInfoPage from './empresa';
import UsersPage from './usuarios';

const paneStyle = { paddingTop: '20px' };

export default function SettingsPage() {
  const { activeIndex, toggle } = useSettingsTabs();

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Tab
                renderActiveOnly
                menu={{ color: 'blue', secondary: true, pointing: true }}
                activeIndex={activeIndex}
                onTabChange={(_, tabData) => {
                  toggle(tabData.activeIndex as number);
                }}
                panes={[
                  {
                    menuItem: 'Alvos',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <Targets />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Clientes',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <Customers />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Colaboradores',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <Employees />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Culturas',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <CropsPage />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Documentos',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <DocumentsPage />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Empresa',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <CompanyInfoPage />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Setores',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <Divisions />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Unidades',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <MeasurementUnits />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Usuários',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <UsersPage />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}

const tabStrategy: { [key: string]: number } = {
  alvos: 0,
  clientes: 1,
  colaboradores: 2,
  culturas: 3,
  documentos: 4,
  empresa: 5,
  setores: 6,
  unidades: 7,
  usuarios: 8,
} as const;

const tabPathStrategy: { [key: number]: string } = {
  0: 'configuracoes',
  1: 'configuracoes/clientes',
  2: 'configuracoes/colaboradores',
  3: 'configuracoes/culturas',
  4: 'configuracoes/documentos',
  5: 'configuracoes/empresa',
  6: 'configuracoes/setores',
  7: 'configuracoes/unidades',
  8: 'configuracoes/usuarios',
} as const;

export function useSettingsTabs() {
  const { active_tab: activeTab } = useParams<
    Record<string, string | undefined>
  >();
  const history = useHistory();

  const toggle = (tabIndex: number) => {
    if (activeTab !== tabPathStrategy[tabIndex]) {
      history.push(
        `/${tabIndex ? tabPathStrategy[tabIndex] : tabPathStrategy[0]}`,
      );
    }
  };

  const activeIndex = activeTab ? tabStrategy[activeTab] : 0;

  return { toggle, activeIndex };
}
