import * as React from 'react';

import { Control, Controller } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';

import CropSearch from 'components/inputs/CropSearch';
import CustomerSearch from 'components/inputs/CustomerSearch';
import DivisionSelect from 'components/inputs/DivisionSelect';
import ProjectStageSelect from 'components/inputs/ProjectStageSelect';
import TargetSearch from 'components/inputs/TargetSearch';
import debounce from 'util/debounce';

import { useProjectFilter } from './use-project-filter';

type ProjectFilterProps = {
  divisionFilters?: number[];
  stageFilters?: number[];
  control: Control;
};

export default function ProjectFilter({
  divisionFilters,
  stageFilters,
  control,
}: ProjectFilterProps) {
  const { updateFilter } = useProjectFilter();
  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <Controller
            control={control}
            defaultValue=""
            name="insideCode"
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                onBlur={onBlur}
                onChange={(e, data) => {
                  debouncedFilter({ insideCode: data.value });
                  onChange(e, data);
                }}
                placeholder="Código"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={6}>
          <Controller
            control={control}
            defaultValue=""
            name="harvest"
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                maxLength={4}
                onBlur={onBlur}
                onChange={(e, data) => {
                  if (data.value.length === 4) {
                    debouncedFilter({ harvest: data.value });
                  } else {
                    debouncedFilter({ harvest: data.value });
                  }

                  onChange(e, data);
                }}
                placeholder="Safra"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <Controller
            control={control}
            name="customers"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                isClearable
                isMulti
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ customers: data || '' });
                  onChange(data);
                }}
                placeholder="Clientes"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <Controller
            control={control}
            name="protocol"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                onBlur={onBlur}
                onChange={(e, data) => {
                  debouncedFilter({ protocol: data.value });
                  onChange(e, data);
                }}
                placeholder="Protocolo"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <Controller
            control={control}
            defaultValue=""
            name="stages"
            render={({ onChange, onBlur, value }) => (
              <ProjectStageSelect
                filters={stageFilters}
                isClearable
                isMulti
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ stages: data });
                  onChange(data);
                }}
                placeholder="Fases"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <Controller
            control={control}
            defaultValue=""
            name="divisions"
            render={({ onChange, onBlur, value }) => (
              <DivisionSelect
                divisions={divisionFilters}
                isClearable
                isMulti
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ divisions: data || '' });
                  onChange(data);
                }}
                placeholder="Setores"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field>
          <Controller
            control={control}
            name="contractNumber"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                onBlur={onBlur}
                onChange={(e, data) => {
                  debouncedFilter({ contractNumber: data.value });
                  onChange(e, data);
                }}
                placeholder="Contrato"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={4}>
          <Controller
            control={control}
            defaultValue=""
            name="crops"
            render={({ onChange, onBlur, value }) => (
              <CropSearch
                isClearable
                isMulti
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ crops: data || '' });
                  onChange(data);
                }}
                placeholder="Culturas"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={5}>
          <Controller
            control={control}
            defaultValue=""
            name="targets"
            render={({ onChange, onBlur, value }) => (
              <TargetSearch
                isClearable
                isMulti
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ targets: data || '' });
                  onChange(data);
                }}
                placeholder="Alvos"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
