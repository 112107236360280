/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Select from 'react-select';
import styled, { css } from 'styled-components';

import { useSaleProducts } from 'data/sale-product';
import * as Currency from 'util/Currency';
import debounce from 'util/debounce';

export default function SaleProductSearch({
  name = undefined,
  value = undefined,
  onChange,
  onBlur,
  autoComplete,
  disabled = false,
  isClearable = false,
}) {
  const [search, setSearch] = React.useState('');
  const setSearchDebounced = debounce(setSearch, 400);

  const { data, isLoading } = useSaleProducts({
    pageSize: 10,
    description: search,
  });

  const defaultValue = {};
  const options = data?.data ? data.data : [];

  const handleChange = (inputValue) => {
    setSearchDebounced(inputValue);
  };

  return (
    <Select
      autoComplete={autoComplete}
      components={{ Option: CustomOption }}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.description}
      getOptionValue={(option) => option.id}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={handleChange}
      options={options}
      placeholder="Selecione..."
      value={value}
    />
  );
}

function CustomOption({ innerProps, isDisabled, data, ...props }) {
  return !isDisabled ? (
    <Wrapper {...innerProps} {...props}>
      <PrimaryText>{data.description}</PrimaryText>
      <SecondaryText {...props}>{`${
        data.measurementUnit
      } - ${Currency.formatWithSymbol({
        amount: data.unitPrice,
        currency: Currency.Currencies.REAL,
      })}`}</SecondaryText>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  ${({ isFocused, isSelected }) => css`
    padding: 1rem 1.4rem;
    background-color: ${isFocused ? '#deebff' : ''};
    background-color: ${isSelected ? '#2684FF' : ''};
    color: ${isSelected ? '#ffffff' : ''};
  `}
`;

const PrimaryText = styled.div`
  ${() => css`
    margin-bottom: 2px;
  `}
`;

const SecondaryText = styled.div`
  ${({ isSelected }) => css`
    font-size: 12px;
    color: ${isSelected ? '#ffffff' : '#767676'};
  `}
`;
