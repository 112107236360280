import React from 'react';

import toast from 'react-hot-toast';
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';

import WeatherTable from 'components/data/WeatherTable';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/useModal';
import { useInfiniteWeathers, useExportWeathers } from 'queries/weather';
import { useWeatherFilter } from 'templates/Weathers/use-weather-filter';
import WeatherFilter from 'templates/Weathers/WeatherFilter';

import ImportWeathersModal from './ImportWeathersModal';

export default function Weathers() {
  const modal = useModal();
  const { user } = useAuth();
  const { getMappedFilter } = useWeatherFilter();

  const {
    weathers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteWeathers(getMappedFilter());

  const {
    mutate: exportWeathers,
    isLoading: isExportingWeathers,
  } = useExportWeathers({
    onSuccess: () => {
      toast.success('Dados meteorológicos exportados!');
    },
  });

  function handleImportClick() {
    modal.open();
  }

  const isEmpty = !weathers.length;

  return (
    <>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h1">Dados meteorológicos</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            {!isEmpty ? (
              <Button
                basic
                loading={isExportingWeathers}
                onClick={() => exportWeathers(getMappedFilter())}
              >
                Exportar
              </Button>
            ) : null}
            {!isEmpty && user.isAdmin ? (
              <Button type="button" onClick={handleImportClick} primary>
                <Icon name="upload" />
                Importar
              </Button>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <WeatherFilter />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <WeatherTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              onImportClick={handleImportClick}
              weathers={weathers}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ImportWeathersModal open={modal.isOpen} onClose={modal.close} />
    </>
  );
}
