import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Divider, Form, Icon, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import ProjectSearch from 'components/inputs/ProjectSearch';
import { useAssociateProjects } from 'data/collection';
import { emptyStringToUndefined } from 'util/validation';

export type CollectionAssociationModalProps = {
  collectionId: number;
  isOpen: boolean;
  onClose: () => void;
};

const FORM_ID = 'form_collection_project_association';

const AssociateProjectsInput = yup
  .object()
  .shape({
    associations: yup
      .array()
      .of(
        yup.object({
          project: yup
            .object()
            .required('Obrigatório')
            .typeError('Obrigatório'),
          amount: yup.number().required('Obrigatório'),
        }),
      )
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
  })
  .required();

type CollectionAssociationFormValues = yup.InferType<
  typeof AssociateProjectsInput
>;

export default function CollectionAssociationModal({
  collectionId,
  isOpen,
  onClose,
}: CollectionAssociationModalProps) {
  const resolver = yupResolver(AssociateProjectsInput);
  const { control, errors, handleSubmit, watch } = useForm<
    CollectionAssociationFormValues
  >({
    defaultValues: { projects: [{}] },
    mode: 'onBlur',
    resolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'projects',
  });
  const watchProjects = watch('projects');

  const { mutate: associateProjects, isLoading } = useAssociateProjects({
    onSuccess: () => handleSuccess(),
  });

  function handleSuccess() {
    toast.success('Estudos associados!');
    onClose();
  }

  function onSubmit(values: CollectionAssociationFormValues) {
    const input = {
      associations: values.associations.map(({ project, amount }) => ({
        projectId: project.id,
        amount,
      })),
      collectionId,
    };
    associateProjects(input);
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Associar estudos</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          {fields.map((item, index) => {
            const isFirstItem = index === 0;

            const disabledRemoveButton =
              isFirstItem && watchProjects.length === 1;

            return (
              <Form.Group key={item.id}>
                <Form.Field width={11}>
                  {isFirstItem ? <label>Estudo</label> : null}
                  <Controller
                    control={control}
                    name={`associations[${index}].project`}
                    defaultValue={item.project}
                    render={({ onChange, onBlur, value }) => (
                      <ProjectSearch
                        autoComplete="off"
                        isClearable
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Busque pelo código..."
                        value={value}
                      />
                    )}
                  />
                  {errors.associations && (
                    <InputError>
                      {errors?.associations[index]?.project?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={4}>
                  {isFirstItem ? <label>Quantidade</label> : null}
                  <Controller
                    control={control}
                    name={`associations[${index}].amount`}
                    defaultValue={item.amount}
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        autoComplete="off"
                        decimalScale={0}
                        decimalSeparator=","
                        disabled={isLoading}
                        onBlur={onBlur}
                        onValueChange={(data) => {
                          onChange(data.floatValue);
                        }}
                        thousandSeparator="."
                        value={value}
                      />
                    )}
                  />
                  {errors.associations && (
                    <InputError>
                      {errors?.associations[index]?.amount?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={1}>
                  {isFirstItem ? <HiddenLabel /> : null}
                  <Button
                    type="button"
                    size="small"
                    onClick={() => remove(index)}
                    disabled={disabledRemoveButton}
                    icon
                    basic
                  >
                    <Icon name="trash" />
                  </Button>
                </Form.Field>
              </Form.Group>
            );
          })}
          {errors.associations && (
            <InputError>{errors?.associations?.message}</InputError>
          )}
          <Form.Field width={11}>
            <Button basic fluid onClick={() => append({})} type="button">
              <Icon name="add" />
              Adicionar um estudo
            </Button>
          </Form.Field>
        </Form>

        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Associar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
