import React from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Divider, Dropdown, Grid, Header } from 'semantic-ui-react';

import { useAuth } from 'hooks/auth';
import {
  ContractStatus,
  useExportContracts,
  useExportContractsWithoutAttachment,
  useExportProjectCost,
  useInfiniteContracts,
} from 'queries/contract';

import ContractsFilter from './ContractsFilter';
import ContractsTable from './ContractsTable';
import { useContractFilter } from './use-contract-filter';

const MESSAGES = {
  contracts: {
    loading: 'Exportando contratos...',
    success: 'Contratos exportados!',
    error: 'Não foi possível exportar os contratos. Tente novamente.',
  },
  contractsWithoutAttachment: {
    loading: 'Exportando contratos sem anexo...',
    success: 'Contratos sem anexo exportados!',
    error: 'Não foi possível exportar so contratos sem anexo. Tente novamente.',
  },
  projectCost: {
    loading: 'Exportando relatório de custo de estudo...',
    success: 'Relatório de custo de estudo exportado!',
    error: 'Não foi possível exportar o relatório. Tente novamente.',
  },
};

export default function Contracts() {
  const { user } = useAuth();
  const { filter, getMappedFilter } = useContractFilter();
  const { control } = useForm({ defaultValues: filter });

  const {
    contracts,
    pagination,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteContracts(getMappedFilter());

  const exportContracts = useExportContracts();
  const exportContractsWithoutAttachment = useExportContractsWithoutAttachment();
  const exportProjectCostReport = useExportProjectCost();

  async function handleExportContractsInProgressClick() {
    const promise = exportContracts.mutateAsync(ContractStatus.InProgress);
    toast.promise(promise, MESSAGES.contracts);
  }

  async function handleExportContractsClick() {
    const promise = exportContracts.mutateAsync();
    toast.promise(promise, MESSAGES.contracts);
  }

  async function handleContractsWithoutAttachmentReportClick() {
    const promise = exportContractsWithoutAttachment.mutateAsync();
    toast.promise(promise, MESSAGES.contractsWithoutAttachment);
  }

  async function handleProjectCostReportClick() {
    const promise = exportProjectCostReport.mutateAsync();
    toast.promise(promise, MESSAGES.projectCost);
  }

  const options = [
    {
      key: 'contracts-in-progress',
      icon: 'file excel',
      text: 'Contratos em andamento',
      value: 'contracts-in-progress',
      onClick: handleExportContractsInProgressClick,
    },
    {
      key: 'all-contracts',
      icon: 'file excel',
      text: 'Todos os contratos',
      value: 'all-contracts',
      onClick: handleExportContractsClick,
    },
    {
      key: 'contracts-without-attachment',
      icon: 'file excel',
      text: 'Contratos sem anexo',
      value: 'contracts-without-attachment',
      onClick: handleContractsWithoutAttachmentReportClick,
    },
    {
      key: 'project-costs',
      icon: 'file excel',
      text: 'Custo de estudo',
      value: 'project-costs',
      onClick: handleProjectCostReportClick,
    },
  ];

  const isExporting =
    exportContracts.isLoading ||
    exportContractsWithoutAttachment.isLoading ||
    exportProjectCostReport.isLoading;

  return (
    <Grid>
      <Grid.Row columns="equal" verticalAlign="middle">
        <Grid.Column>
          <Header as="h1">Contratos</Header>
        </Grid.Column>
        <Grid.Column width={2} textAlign="right" verticalAlign="middle">
          <Dropdown
            text="Relatórios"
            direction="left"
            loading={isExporting}
            floating
            basic
            button
            fluid
          >
            <Dropdown.Menu>
              <Dropdown.Menu scrolling>
                {options.map((option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Dropdown.Item {...option} />
                ))}
              </Dropdown.Menu>
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          <ContractsFilter control={control} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <ContractsTable
            contracts={contracts}
            isLoading={isLoading}
            hasNextPage={hasNextPage}
            isFetching={isFetching}
            isFetchingNextPage={isFetchingNextPage}
            fetchNextPage={fetchNextPage}
            pagination={pagination}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
