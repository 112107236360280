export function toCurrency(value, locale = 'pt-BR') {
  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function formatCurrencyWithSymbol(value, currency = 1) {
  const formattedCurrency = formatCurrency(value, currency);
  const symbol = formatSymbol(currency);

  return `${symbol} ${formattedCurrency}`;
}

export function formatCurrency(value, currency = 1) {
  const locale = currency === 1 ? 'pt-BR' : 'en-US';

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  });

  return formatter.format(value);
}

export function formatSymbol(currency) {
  if (currency === 1) {
    return 'R$';
  }

  if (currency === 2) {
    return '$';
  }

  return '';
}
