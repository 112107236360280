import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import { getDivision } from 'data/project';
import api, { emptyPagination, Pagination, Response } from 'util/api';
import { formatToBr, parseIso } from 'util/DateFormatter';
import { downloadFile } from 'util/FileManager';

type QuotationsQueryContract = {
  id: number;
  number: string;
};

type QuotationsQueryCustomer = {
  id: number;
  tradingName: string;
  cnpj: string;
  phone: string;
};

type QuotationsQueryRepresentative = {
  id: number;
  name: string;
  email: string;
  phone: string;
};

type QuotationsQueryResponsible = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
};

type QuotationsQueryPayment = {
  id: number;
  installment: number;
  percent: number;
  amount: number;
  description: string;
  quotationId: number;
};

export type QuotationsQuery = {
  id: number;
  code: string;
  harvest: number;
  version: number;
  quotationDate: string;
  status: number;
  currency: number;
  discountPercent: number | null;
  reviewDate: string | null;
  responseDate: string | null;
  isContractGenerated: boolean;
  notes: string | null;
  createdAt: string;
  updatedAt: string;
  customer: QuotationsQueryCustomer;
  representative: QuotationsQueryRepresentative;
  quotationResponsible: QuotationsQueryResponsible;
  contract: QuotationsQueryContract | null;
  divisions: number[];
  totalValue: number;
  totalAmount: number;
  totalAmountWithDiscount: number;
  payments: QuotationsQueryPayment[];
  hasPayments: boolean;
  hasDiscount: boolean;
  hasProjects: boolean;
  isInNegotiation: boolean;
  isAccepted: boolean;
  isRejected: boolean;
  isAnswered: boolean;
};

export type QuotationsResponse = {
  pagination: Pagination;
  projects: QuotationsQuery[];
};

export type QuotationFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  code?: string;
  status?: number;
  notBound?: boolean;
};

export const quotationKeys = {
  all: ['quotations'] as const,
  detail: (id: number) => [...quotationKeys.all, id] as const,
};

export function useInfiniteQuotations(filter: QuotationFilter) {
  const result = useInfiniteQuery(
    ['quotations', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getQuotations({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const quotations =
    result?.data?.pages
      ?.map((group) => group?.quotations?.map((quotation) => quotation))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    quotations,
  };
}

async function getQuotations(filter: QuotationFilter) {
  const response = await api.get<Response<QuotationsQuery>>(`/quotations`, {
    params: filter,
  });
  const { data, pagination } = response.data;
  const quotations = data.map(apiResponseMapper);

  return { quotations, pagination };
}

export type QuotationsQueryFormatted = ReturnType<typeof apiResponseMapper>;

function apiResponseMapper(quotationApi: QuotationsQuery) {
  return {
    ...quotationApi,
    quotationStatusDescription: formatQuotationStatus(quotationApi.status),
    quotationStatusColor: formatQuotationStatusColor(quotationApi.status),
    harvest: quotationApi.harvest,
    quotationDate: parseIso(quotationApi.quotationDate),
    quotationDateFormatted: formatToBr(quotationApi.quotationDate),
    reviewDate: quotationApi.reviewDate
      ? parseIso(quotationApi.reviewDate)
      : null,
    reviewDateFormatted: quotationApi.reviewDate
      ? formatToBr(quotationApi.reviewDate)
      : '',
    responseDate: quotationApi.responseDate
      ? parseIso(quotationApi.responseDate)
      : null,
    responseDateFormatted: quotationApi.responseDate
      ? formatToBr(quotationApi.responseDate)
      : '',
    divisionsFormatted: quotationApi.divisions.map((division) =>
      getDivision(division),
    ),
    currency: quotationApi.currency,
    payments: quotationApi.payments.map((payment) => {
      return {
        id: payment.id,
        installment: payment.installment,
        percent: payment.percent,
        percentFormatted: `${Math.round(payment.percent * 100)}%`,
        description: payment.description,
        amount:
          payment.amount ||
          getCompatibilityPayments(
            quotationApi.hasDiscount
              ? quotationApi.totalAmountWithDiscount
              : quotationApi.totalAmount,
            payment.percent,
          ),
      };
    }),
  };
}

function formatQuotationStatus(quotationStatus: number) {
  switch (quotationStatus) {
    case 1:
      return 'Em negociação';
    case 2:
      return 'Aprovado';
    case 3:
      return 'Não aprovado';
    case 4:
      return 'Contrato gerado';
    default:
      return '';
  }
}

function formatQuotationStatusColor(quotationStatus: number) {
  switch (quotationStatus) {
    case 1:
      return 'blue';
    case 2:
      return 'green';
    case 3:
      return 'red';
    case 4:
      return 'transparent';
    default:
      return '';
  }
}

function getCompatibilityPayments(
  projectTotalAmount: number,
  paymentPercent: number,
) {
  return projectTotalAmount * paymentPercent;
}

export const QuotationStatus = {
  InNegotiation: 1,
  Accepted: 2,
  Rejected: 3,
  GeneratedContract: 4,
} as const;

type QuotationStatus = typeof QuotationStatus[keyof typeof QuotationStatus];

export function formatStatus(status: number) {
  if (status === QuotationStatus.InNegotiation) {
    return 'Em negociação';
  }

  if (status === QuotationStatus.Accepted) {
    return 'Aprovado';
  }

  if (status === QuotationStatus.Rejected) {
    return 'Não aprovado';
  }

  if (status === QuotationStatus.GeneratedContract) {
    return 'Contrato gerado';
  }

  throw new Error('Status inválido!');
}

// Mutations ------------------------------------------

type CreateQuotationInput = {
  quotationDate: string;
  harvest: number;
  customerId: number;
  representativeId: number;
  currency: number;
  discountPercent?: number | null;
  notes?: string | null;
};

export function useCreateQuotation(
  options?: UseMutationOptions<
    number,
    AxiosError,
    CreateQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(createQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createQuotation(input: CreateQuotationInput) {
  const response = await api.post('/quotations', input);
  const { location } = response.headers;
  const quotationId = parseInt(location.substring(15), 10);

  return quotationId;
}

type UpdateQuotationInput = {
  quotationId: number;
  quotationDate?: string;
  harvest?: number;
  customerId?: number;
  representativeId?: number;
  currency?: number;
  discountPercent?: number | null;
  notes?: string | null;
};

export function useUpdateQuotation(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateQuotationInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateQuotation(input: UpdateQuotationInput) {
  await api.patch(`/quotations/${input.quotationId}`, input);
}

type UpdateQuotationProjectInput = {
  id: number;
  quotationId: number;
  description?: string | null;
  protocol?: string | null;
  productIds?: number[];
  cropIds?: number[];
  targetIds?: number[];
  division?: number;
  projectGoal?: number;
  managementType?: number;
  testLocation?: number;
  durationForecast?: number;
  amount?: number;
  treatmentQuantity?: number;
  trialsNumber?: number;
  projectsNumber?: number;
};

export function useUpdateQuotationProject(
  options: UseMutationOptions<
    void,
    AxiosError,
    UpdateQuotationProjectInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateQuotationProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateQuotationProject({
  id,
  quotationId,
  ...input
}: UpdateQuotationProjectInput) {
  await api.patch(`/quotations/${quotationId}/projects/${id}`, input);
}

export type QuotationProjectDuplicateInput = {
  quotationId: number;
  quotationProjectId: number;
};

export function useDuplicateProject(
  options: UseMutationOptions<
    void,
    AxiosError,
    QuotationProjectDuplicateInput,
    () => void
  >,
) {
  return useMutation(duplicateProject, options);
}

async function duplicateProject({
  quotationId,
  quotationProjectId,
}: QuotationProjectDuplicateInput) {
  api.post(
    `quotations/${quotationId}/projects/${quotationProjectId}/duplicate`,
  );
}

export function useApproveQuotation(
  options: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(approveQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function approveQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/approve`);
}

export function useRejectQuotation(
  options: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(rejectQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function rejectQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/reject`);
}

type DeleteQuotationItemInput = {
  quotationId: number;
  quotationItemId: number;
};

export function useRemoveQuotationItem(
  options: UseMutationOptions<
    void,
    AxiosError,
    DeleteQuotationItemInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(removeItem, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function removeItem({
  quotationId,
  quotationItemId,
}: DeleteQuotationItemInput) {
  await api.delete(`/quotations/${quotationId}/projects/${quotationItemId}`);
}

type GenerateContractInput = {
  quotationId: number;
  number: string;
  signatureDate: Date;
  notes?: string | null;
};

export function useGenerateContract(
  options: UseMutationOptions<
    number,
    AxiosError,
    GenerateContractInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(generateContract, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('contract');
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function generateContract(input: GenerateContractInput) {
  const { headers } = await api.post(
    `/quotations/${input.quotationId}/generating-contract`,
    input,
  );
  const { location } = headers;
  const contractId = parseInt(location.substring(14), 10);
  return contractId;
}

export function useRevertQuotation(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(revertQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotations');
      queryClient.invalidateQueries('quotation');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function revertQuotation(quotationId: number) {
  await api.post(`/quotations/${quotationId}/revert-to-negotiation`);
}

export function useDeleteQuotation(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(deleteQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(quotationKeys.all);
      queryClient.invalidateQueries(quotationKeys.detail(variables));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteQuotation(quotationId: number) {
  await api.delete(`/quotations/${quotationId}`);
}

const CONFIG = { responseType: 'blob' } as const;

export function useExportQuotationProjects(
  options?: UseMutationOptions<void, AxiosError, void>,
) {
  return useMutation(exportQuotationProjects, {
    ...options,
  });
}

async function exportQuotationProjects() {
  const url = `/quotations/projects/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG });
  const { filename } = headers;

  downloadFile(file, filename);
}

export function useExportQuotations(
  options?: UseMutationOptions<void, AxiosError, QuotationFilter>,
) {
  return useMutation(exportQuotations, {
    ...options,
  });
}

async function exportQuotations(params: QuotationFilter) {
  const url = `/quotations/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;

  downloadFile(file, filename);
}

export function useQuotationSummary(year: number) {
  const result = useQuery({
    queryKey: ['quotations', 'summary'],
    queryFn: () => getQuotationSummary(year),
  });

  return { ...result };
}

type QuotationSummaryMonths = {
  january: number;
  february: number;
  march: number;
  april: number;
  may: number;
  june: number;
  july: number;
  august: number;
  september: number;
  october: number;
  november: number;
  december: number;
};

type QuotationSummaryStatus = {
  inNegotiation: QuotationSummaryMonths;
  rejected: QuotationSummaryMonths;
  approved: QuotationSummaryMonths;
  total: QuotationSummaryMonths;
};

type QuotationSummaryResult = {
  servicesAndPests: QuotationSummaryStatus;
  services: QuotationSummaryStatus;
  pests: QuotationSummaryStatus;
};

async function getQuotationSummary(year: number) {
  const url = '/quotations/summary';
  const config = { params: { year } };

  const { data } = await api.get<QuotationSummaryResult>(url, config);

  return data;
}

export function useExportQuotationSummary(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  return useMutation(exportQuotationSummary, {
    ...options,
  });
}

async function exportQuotationSummary(year: number) {
  const url = `/quotations/summary/export`;
  const config = { ...CONFIG, params: { year } };
  const { data: file, headers } = await api.get(url, config);
  const { filename } = headers;

  downloadFile(file, filename);
}

type GenerateQuotationDocumentProps = {
  quotationId: number;
  locale: string;
};

export function useGenerateQuotationDocument(
  options?: UseMutationOptions<
    void,
    AxiosError,
    GenerateQuotationDocumentProps
  >,
) {
  return useMutation(generateQuotationDocument, {
    ...options,
  });
}

async function generateQuotationDocument({
  quotationId,
  locale,
}: GenerateQuotationDocumentProps) {
  const url = `/quotations/${quotationId}/document`;
  const params = { locale };
  const config = { ...CONFIG, params };
  const { data: file, headers } = await api.get(url, config);
  const { filename } = headers;

  downloadFile(file, filename);
}

type DuplicateQuotationProps = {
  quotationId: number;
  quotationResponsibleId: number;
  harvest: number;
};

export function useDuplicateQuotation(
  options?: UseMutationOptions<number, AxiosError, DuplicateQuotationProps>,
) {
  const queryClient = useQueryClient();

  return useMutation(duplicateQuotation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('quotation');
      queryClient.invalidateQueries('quotations');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function duplicateQuotation({
  quotationId,
  quotationResponsibleId,
  harvest,
}: DuplicateQuotationProps) {
  const data = { quotationResponsibleId, harvest };
  const { headers } = await api.post(
    `/quotations/${quotationId}/duplicate`,
    data,
  );
  const { location } = headers;
  const duplicatedQuotationId = parseInt(location.split('/').pop(), 10);

  return duplicatedQuotationId;
}
