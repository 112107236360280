import React from 'react';

import fileSize from 'filesize';
import toast from 'react-hot-toast';
import { Button, Grid, Header, Icon, Loader, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LinkButton from 'components/inputs/LinkButton';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import ProjectDocumentsModal from 'components/modal/ProjectDocumentsModal';
import { downloadDocument } from 'hooks/document';
import { useModalWithData } from 'hooks/useModal';
import { useRemoveMaintenance } from 'mutations/maintenance';
import { useMaintenances } from 'queries/maintenance';

import MaintenanceForm from './MaintenanceForm';
import MaintenanceTable from './MaintenanceTable';

const MESSAGE_REMOVE_SUCCESS = 'Manutenção removida com sucesso';

export default function ProjectMaintenancesPage({
  projectId,
  installation,
  documents,
}) {
  const maintenanceModal = useModalWithData();
  const uploadModal = useModalWithData();

  const {
    isOpen: maintenanceRemoveModal,
    data: selectedMaintenance,
    open,
    close,
  } = useModalWithData();

  const { data: maintenances, isLoading } = useMaintenances({ projectId });

  function handleSuccess(message) {
    maintenanceModal.close();
    toast.success(message);
  }

  const {
    mutate: removeMaintenance,
    isLoading: isRemoving,
  } = useRemoveMaintenance({
    onSuccess: onRemoveSuccess,
  });

  function onRemoveSuccess() {
    close();
    toast.success(MESSAGE_REMOVE_SUCCESS);
  }

  function handleRemoveSubmit() {
    if (selectedMaintenance) {
      removeMaintenance(selectedMaintenance.id);
    }
  }

  function handleAddClick() {
    maintenanceModal.open();
  }

  function handleRemoveClick(project) {
    open(project);
  }

  function handleAddAttachmentClick() {
    uploadModal.open();
  }

  function handleEditAttachmentClick(documentIds) {
    uploadModal.open(documentIds);
  }

  function handleRemoveMaintenanceClose() {
    close();
  }

  if (!installation) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Estudo sem localização</EmptyState.Header>
            <EmptyState.Description>
              Adicione a localização do estudo para poder adicionar manutenções
              e anexar documentos assinados
            </EmptyState.Description>
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  const maintenanceDocuments =
    documents?.filter((document) => document.type === 16) || [];

  return (
    <>
      <AlertModal
        header="Remover Manutenção"
        content="Você tem certeza que deseja remover esta manutenção?"
        submitText="Remover"
        onClose={handleRemoveMaintenanceClose}
        onSubmit={handleRemoveSubmit}
        isOpen={maintenanceRemoveModal}
        isLoading={isRemoving}
        negative
      />

      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Header>
                <Header as="h3" style={{ margin: 0 }}>
                  Anexos de manutenção
                </Header>
                {maintenanceDocuments.length ? (
                  <Button
                    size="small"
                    basic
                    onClick={() =>
                      handleEditAttachmentClick(
                        maintenanceDocuments.map(({ id }) => id),
                      )
                    }
                  >
                    <Icon name="edit" /> Editar
                  </Button>
                ) : null}
              </Section.Header>
              <Section.Content>
                {maintenanceDocuments.length > 0 ? (
                  <Table basic="very" fixed>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="left" width={1}>
                          #
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">
                          Nome
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">
                          Tamanho
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center" />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {maintenanceDocuments.map((document, index) => (
                        <Table.Row key={document.id.toString()}>
                          <Table.Cell textAlign="left" width={1}>
                            {index + 1}
                          </Table.Cell>
                          <Table.Cell textAlign="left">
                            {document.name}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            {fileSize(document.size)}
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            <LinkButton
                              type="button"
                              onClick={() => downloadDocument(document.id)}
                            >
                              Baixar
                            </LinkButton>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                ) : (
                  <EmptyState>
                    <EmptyState.Header>Nenhum anexo</EmptyState.Header>
                    <EmptyState.Description>
                      Adicione anexos de manutenção
                    </EmptyState.Description>
                    <EmptyState.Actions>
                      <Button
                        size="small"
                        basic
                        onClick={handleAddAttachmentClick}
                      >
                        <Icon name="add" /> Adicionar
                      </Button>
                    </EmptyState.Actions>
                  </EmptyState>
                )}
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
        {maintenances?.length > 0 && (
          <Grid.Row>
            <Grid.Column width={8} verticalAlign="middle" textAlign="left">
              <Header as="h3">Manutenções</Header>
            </Grid.Column>
            <Grid.Column width={8} verticalAlign="middle" textAlign="right">
              <Button type="button" onClick={handleAddClick} primary basic>
                <Icon name="add" />
                Nova manutenção
              </Button>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            {isLoading ? (
              <Loader active size="large" style={{ marginTop: '200px' }}>
                Carregando...
              </Loader>
            ) : (
              <Section>
                <Section.Content>
                  <MaintenanceTable
                    maintenances={maintenances}
                    onAddClick={handleAddClick}
                    onEditClick={(maintenance) =>
                      maintenanceModal.open(maintenance.id)
                    }
                    onRemoveClick={handleRemoveClick}
                    loading={isLoading}
                  />
                </Section.Content>
              </Section>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {maintenanceModal ? (
        <MaintenanceForm
          open={maintenanceModal.isOpen}
          projectId={projectId}
          maintenanceId={maintenanceModal.data}
          onClose={maintenanceModal.close}
          onSuccess={handleSuccess}
        />
      ) : null}

      {uploadModal.isOpen ? (
        <ProjectDocumentsModal
          projectId={projectId}
          documentIds={uploadModal.data}
          documentType="maintenance"
          isOpen={uploadModal.isOpen}
          onClose={uploadModal.close}
          onSuccess={() => {
            toast.success('Documentos anexados com sucesso!');
            uploadModal.close();
          }}
        />
      ) : null}
    </>
  );
}
