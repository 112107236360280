import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQuery } from 'react-query';

import { getDocumentInput } from 'hooks/document';
import { ExtendedFile } from 'hooks/use-upload';
import api from 'util/api';
import { parseIso } from 'util/DateFormatter';

export type Location = {
  id: 1;
  latitude: string;
  longitude: string;
  altitude: number;
  accessSketchId: number;
  locationSketchId: number;
  property: {
    id: number;
    name: string;
    owner: string;
    ownerDocument: string;
    address: string;
    neighborhood: string;
    city: string;
    state: string;
    cep: string;
    phone: string;
    ownerAddress: string;
    ownerNeighborhood: string | null;
    ownerCity: string;
    ownerState: string;
    ownerCep: string | null;
  };
  projectId: number;
  plantingDate: string;
  seedlingEmergenceDate: string | null;
  plantsNumber: number | null;
  bbchScale: string | null;
  plantsNumberUnit: string | number;
  cropStage: string | null;
  customPlantingDate: string | null;
};

type LocationWithFiles = Location & {
  accessSketchFile: ExtendedFile;
  locationSketchFile: ExtendedFile;
};

export function useLocationWithFiles(projectId: number, locationId: number) {
  return useQuery<LocationWithFiles, AxiosError>({
    queryKey: ['location', locationId],
    queryFn: () => getInstallationWithFiles(projectId, locationId),
    enabled: !!(projectId && locationId),
    staleTime: Infinity,
  });
}

async function getInstallationWithFiles(projectId: number, locationId: number) {
  const { data } = await api.get<Location>(
    `/projects/${projectId}/installations/${locationId}`,
  );
  const location = apiResponseMapper(data);

  const [accessSketchFile, locationSketchFile] = await Promise.all([
    getDocumentInput(location.accessSketchId),
    getDocumentInput(location.locationSketchId),
  ]);

  return { ...location, accessSketchFile, locationSketchFile };
}

function apiResponseMapper(installationApi: any) {
  return {
    ...installationApi,
    plantingDate: installationApi.plantingDate
      ? parseIso(installationApi.plantingDate)
      : null,
    seedlingEmergenceDate: installationApi.seedlingEmergenceDate
      ? parseIso(installationApi.seedlingEmergenceDate)
      : null,
  };
}

type GenerateResearchProjectResponse = {
  file: Blob;
  filename: string;
};

type GenerateAssignmentAreaInput = {
  locationId: number;
  date: Date;
};

export function useGenerateAssignmentArea(
  options?: UseMutationOptions<
    GenerateResearchProjectResponse,
    AxiosError,
    GenerateAssignmentAreaInput,
    () => void
  >,
) {
  return useMutation(generateAssignmentArea, { ...options });
}

async function generateAssignmentArea({
  locationId,
  date,
}: GenerateAssignmentAreaInput) {
  const url = `/locations/${locationId}/assignment-area`;
  const data = { date };
  const config = { responseType: 'blob' } as const;

  const { data: file, headers } = await api.post(url, data, config);

  const { filename } = headers;
  return { file, filename };
}
