/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import Select from 'react-select';
import styled, { css } from 'styled-components';

import { useCustomers } from 'queries/customer';
import { formatCpfOrCnpj } from 'util/mask';

export default function CustomerSearch({
  disabled,
  isClearable = false,
  isLoading = false,
  isMulti = false,
  name,
  onBlur,
  onChange,
  placeholder = 'Busque pela nome...',
  styles = undefined,
  value,
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const params = {
    page: 1,
    pageSize: 10,
    orderBy: 'tradingName',
    sort: 'asc',
    tradingName: searchValue,
  };
  const { data, isLoading: isLoadingCustomers, error } = useCustomers(params);

  const customers = error || !data?.customers ? [] : data.customers;

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  const defaultValue = !isMulti ? {} : [];

  return (
    <Select
      components={{ Option: CustomOption }}
      defaultValue={defaultValue}
      getOptionLabel={(option) => option.tradingName}
      getOptionValue={(option) => option.id}
      inputValue={searchValue}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading || isLoadingCustomers}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={handleInputChange}
      options={customers}
      placeholder={placeholder}
      styles={styles}
      value={value}
    />
  );
}

function CustomOption({ innerProps, isDisabled, data, ...props }) {
  return !isDisabled ? (
    <Wrapper {...innerProps} {...props}>
      <PrimaryText>{data.tradingName}</PrimaryText>
      <SecondaryText {...props}>{formatCpfOrCnpj(data.cnpj)}</SecondaryText>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  ${({ isFocused, isSelected }) => css`
    padding: 1rem 1.4rem;
    background-color: ${isFocused ? '#deebff' : ''};
    background-color: ${isSelected ? '#2684FF' : ''};
    color: ${isSelected ? '#ffffff' : ''};
  `}
`;

const PrimaryText = styled.div`
  ${() => css`
    margin-bottom: 2px;
  `}
`;

const SecondaryText = styled.div`
  ${({ isSelected }) => css`
    font-size: 12px;
    color: ${isSelected ? '#ffffff' : '#767676'};
  `}
`;
