import * as React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import ActivityPerformModal from 'components/modal/ActivityPerformModal';
import { useModal, useModalWithData } from 'hooks/useModal';
import { useDeleteActivity } from 'mutations/project';
import { ProjectQuery } from 'queries/project';

import ActivitiesModalForm from './ActivitiesModalForm';
import ActivityAddModal from './ActivityAddModal';
import ActivityModal from './ActivityModal';
import ActivityTable from './ActivityTable';

export type ProjectActivitiesPageProps = {
  project: ProjectQuery;
  refreshProject: () => void;
};

type Activity = {
  id: number;
  date: string;
  description: string;
  activityType: {
    id: number;
    description: string;
  };
};

export default function ProjectActivitiesPage({
  project,
  refreshProject,
}: ProjectActivitiesPageProps) {
  const addModal = useModal();
  const addManyModal = useModal();
  const deleteModal = useModalWithData<Activity>();
  const editModal = useModalWithData<number>();
  const performModal = useModalWithData<number>();

  const { mutate: deleteActivity, isLoading: isDeleting } = useDeleteActivity({
    onSuccess: () => {
      toast.success('Atividade excluída!');
    },
  });

  function handleClose() {
    addManyModal.close();
  }

  function handleAddManyClick() {
    addManyModal.open();
  }

  function handleAddClick() {
    addModal.open();
  }

  function handleDeleteClick(activityId: number) {
    const activity = project.activities.find(({ id }) => id === activityId);
    if (activity) {
      deleteModal.open(activity);
    }
  }

  function handleSuccess() {
    refreshProject();
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h2" style={{ margin: 0 }}>
              Atividades
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button basic primary onClick={handleAddClick}>
              <Icon name="add" />
              Nova atividade
            </Button>
          </Grid.Column>
        </Grid.Row>

        {!project?.activities?.length ? (
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <EmptyState>
                    <EmptyState.Header>
                      Atividades não adicionadas
                    </EmptyState.Header>
                    <EmptyState.Description>
                      Clique no botão abaixo para adicionar as atividades
                    </EmptyState.Description>
                    <EmptyState.Actions>
                      <Button
                        type="button"
                        onClick={handleAddManyClick}
                        primary
                        basic
                      >
                        <Icon name="add" />
                        Adicionar
                      </Button>
                    </EmptyState.Actions>
                  </EmptyState>
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <Grid.Row>
            <Grid.Column>
              <ActivityTable
                activities={project?.activities}
                onDeleteClick={handleDeleteClick}
                onEditClick={(activityId) => editModal.open(activityId)}
                onPerformClick={(activityId) => performModal.open(activityId)}
              />
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>

      {addManyModal.isOpen ? (
        <ActivitiesModalForm
          onClose={handleClose}
          onSuccess={handleSuccess}
          open={addManyModal.isOpen}
          projectId={project.id}
        />
      ) : null}

      {performModal.isOpen && performModal.data ? (
        <ActivityPerformModal
          activityId={performModal.data}
          isOpen={performModal.isOpen}
          onClose={performModal.close}
        />
      ) : null}

      {addModal.isOpen ? (
        <ActivityAddModal
          isOpen={addModal.isOpen}
          onClose={addModal.close}
          projectId={project.id}
        />
      ) : null}

      {editModal.isOpen && editModal.data ? (
        <ActivityModal
          isOpen={editModal.isOpen}
          onClose={editModal.close}
          activityId={editModal.data}
        />
      ) : null}

      {deleteModal.isOpen && deleteModal.data?.id ? (
        <AlertModal
          header="Excluir atividade"
          content={`Tem certeza que deseja excluir a atividade "${DateTime.fromISO(
            deleteModal?.data?.date,
          ).toFormat('dd/MM/yyyy')} - ${
            deleteModal?.data?.activityType?.description
          }"?`}
          submitText="Excluir"
          negative
          isOpen={deleteModal.isOpen}
          isLoading={isDeleting}
          onClose={deleteModal.close}
          onSubmit={() => deleteActivity(deleteModal?.data?.id)}
        />
      ) : null}
    </>
  );
}
