import React from 'react';

import { Grid, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import LinkButton from 'components/inputs/LinkButton';
import { SamplesQueryPackaging } from 'queries/sample';

type PackagingTableProps = {
  items: SamplesQueryPackaging[];
  isLoading: boolean;
  onManipulateClick: (item: SamplesQueryPackaging, index: number) => void;
};

function PackagingTable({
  items = [],
  isLoading,
  onManipulateClick,
}: PackagingTableProps) {
  return (
    <Grid>
      <Grid.Column width={16}>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">N°</Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Código</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. original
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. inicial (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. restante (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Total Perda (g)
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Status</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={5} rows={10} />}
            {!isLoading &&
              items?.map((item, index) => {
                const {
                  id,
                  number,
                  code,
                  volume,
                  startAmount,
                  endAmount,
                  lostAmount,
                  isAvailable,
                } = item;

                return (
                  <Table.Row key={id.toString()}>
                    <Table.Cell>{number}</Table.Cell>
                    <Table.Cell>{code}</Table.Cell>
                    <Table.Cell textAlign="right">{volume}</Table.Cell>
                    <Table.Cell textAlign="right">{startAmount}</Table.Cell>
                    <Table.Cell textAlign="right">{endAmount}</Table.Cell>
                    <Table.Cell textAlign="right">{lostAmount}</Table.Cell>
                    <Table.Cell>
                      {isAvailable ? (
                        <Tag color="green">Disponível</Tag>
                      ) : (
                        <Tag color="grey">Indisponível</Tag>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {isAvailable ? (
                        <LinkButton
                          onClick={() => onManipulateClick(item, index)}
                        >
                          Manipular
                        </LinkButton>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Grid.Column>
    </Grid>
  );
}

export default PackagingTable;
