import React, { useContext, createContext, useState } from 'react';

type Option = {
  value: number;
  label: string;
};

type DocumentFilterProps = {
  name?: string;
  type?: Option;
  extension?: Option;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
};

type MappedDocumentFilter = Omit<DocumentFilterProps, 'type' | 'extension'> & {
  extension?: number;
  type?: number;
};

export type DocumentFilterContextData = {
  filter: DocumentFilterProps;
  updateFilter: (newData: DocumentFilterProps) => void;
  getMappedFilter: () => MappedDocumentFilter;
  resetFilter: () => void;
};

export const DocumentFilterContext = createContext<DocumentFilterContextData>(
  {} as DocumentFilterContextData,
);

export type DocumentFilterProviderProps = {
  children: React.ReactNode;
};

const DocumentFilterProvider = ({ children }: DocumentFilterProviderProps) => {
  const [documentFilter, setDocumentFilter] = useState<DocumentFilterProps>({
    page: 1,
    pageSize: 10,
    orderBy: 'createdAt',
    sort: 'desc',
  });

  const updateFilter = (data: DocumentFilterProps) => {
    const newFilter = { ...documentFilter, ...data };
    setDocumentFilter(newFilter);
  };

  const getMappedFilter = () => {
    const { name, extension, type, ...filterUpdated } = documentFilter;

    return {
      ...filterUpdated,
      name,
      extension: extension?.value || undefined,
      type: type?.value || undefined,
    };
  };

  function resetFilter() {
    setDocumentFilter({
      name: '',
      extension: undefined,
      type: undefined,
      orderBy: 'id',
      page: 1,
      pageSize: 10,
      sort: 'desc',
    });
  }

  return (
    <DocumentFilterContext.Provider
      value={{
        filter: documentFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </DocumentFilterContext.Provider>
  );
};

const useDocumentFilter = () => useContext(DocumentFilterContext);

export { DocumentFilterProvider, useDocumentFilter };
