import filesize from 'filesize';
import shortid from 'shortid';

import { ExtendedFile } from 'hooks/use-upload';
import api from 'util/api';
import { Document } from 'util/Document';
import { downloadFile } from 'util/FileManager';

type ProjectDocuments = {
  protocolDocument: Document | null;
  retDocument: Document | null;
  areaAssignmentDocument: Document | null;
  reportReviewDocument: Document | null;
  reportDocument: Document | null;
  secondRetDocument: Document | null;
  thirdRetDocument: Document | null;
  fourthRetDocument: Document | null;
};

export async function downloadDocument(documentId: number) {
  const { file, filename } = await getDocumentFile(documentId);
  return downloadFile(file, filename);
}

type UploadDocumentInput = {
  file: ExtendedFile;
  documentType: number;
  onUploadProgress: (progress: number) => void;
};

export async function uploadDocument({
  file,
  documentType,
  onUploadProgress,
}: UploadDocumentInput) {
  const data = new FormData();

  data.append('file', file.file, file.name);
  data.append('documentType', documentType.toString());

  return api
    .post('/documents', data, {
      onUploadProgress: (e) => {
        const progress = calculateProgress(e);

        onUploadProgress(progress);
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function calculateProgress(e: ProgressEventInit) {
  const { loaded = 0, total = 0 } = e;
  return Math.round((loaded * 100) / total);
}

export function makeDetailedFile(file: File) {
  return {
    file,
    id: shortid.generate(),
    name: file.name,
    readableSize: filesize(file.size),
    progress: 0,
    uploaded: false,
    error: false,
    url: null,
  };
}

export async function getProjectDocumentFiles(projectId: number) {
  const documents: ProjectDocuments = await getProjectDocuments(projectId);

  const [
    protocolFile,
    retFile,
    areaAssignmentFile,
    reportReviewFile,
    reportFile,
    secondRetFile,
    thirdRetFile,
    fourthRetFile,
  ] = await Promise.all(
    Object.entries(documents).map(async ([, document]) => {
      if (!document) {
        return null;
      }

      const { file, filename } = await getDocumentFile(document.id);

      return {
        file,
        id: document.id,
        name: filename,
        readableSize: filesize(file.size),
        progress: 100,
        uploaded: true,
        error: false,
        url: document.url,
      };
    }),
  );

  return {
    protocolFile,
    retFile,
    areaAssignmentFile,
    reportReviewFile,
    reportFile,
    secondRetFile,
    thirdRetFile,
    fourthRetFile,
  };
}

export async function getProjectDocuments(projectId: number) {
  const { data } = await api.get(`/projects/${projectId}/documents`);
  return data;
}

export async function getDocumentInput(documentId: number) {
  const document = await getDocument(documentId);

  const { file, filename } = await getDocumentFile(documentId);

  return {
    file,
    id: document.id,
    name: filename,
    readableSize: filesize(file.size),
    progress: 100,
    uploaded: true,
    error: false,
    url: document.url,
  };
}

async function getDocument(documentId: number) {
  const { data } = await api.get(`/documents/${documentId}`);
  return data;
}

async function getDocumentFile(documentId: number) {
  const { data, headers } = await api.get(`/documents/${documentId}/file`, {
    responseType: 'blob',
  });

  const { filename } = headers;

  return { file: data, filename };
}

export async function downloadImage(name: string) {
  const { data, headers } = await api.get(`/documents/images/${name}`, {
    responseType: 'blob',
  });

  const { filename } = headers;

  return { file: data, filename };
}

export async function deleteDocument(documentId: number) {
  await api.delete(`/documents/${documentId}`);
}
