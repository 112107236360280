import { useHistory, useParams } from 'react-router-dom';

const tabStrategy: { [key: string]: number } = {
  dashboard: 0,
  atividades: 1,
  estudos: 2,
  plr: 3,
  'dados-meteorologicos': 4,
  propriedades: 5,
  mapa: 6,
} as const;

const tabPathStrategy: { [key: number]: string } = {
  0: 'pesquisa',
  1: 'pesquisa/atividades',
  2: 'pesquisa/estudos',
  3: 'pesquisa/plr',
  4: 'pesquisa/dados-meteorologicos',
  5: 'pesquisa/propriedades',
  6: 'pesquisa/mapa',
} as const;

export function useResearchTabs() {
  const { active_tab: activeTab } = useParams<
    Record<string, string | undefined>
  >();
  const history = useHistory();

  const toggle = (tabIndex: number) => {
    if (activeTab !== tabPathStrategy[tabIndex]) {
      history.push(
        `/${tabIndex ? tabPathStrategy[tabIndex] : tabPathStrategy[0]}`,
      );
    }
  };

  const activeIndex = activeTab ? tabStrategy[activeTab] : 0;

  return { toggle, activeIndex };
}
