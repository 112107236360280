import React from 'react';

import { Table } from 'semantic-ui-react';

import LinkButton from 'components/inputs/LinkButton';
import { formatToBr, formatTime } from 'util/DateFormatter';
import { formatInitials } from 'util/unitFormatter';

export default function ApplicationsTable({
  application,
  number,
  onEditClick,
  onRemoveClick,
}) {
  const {
    startDate,
    endDate,
    startTemperature,
    endTemperature,
    startHumidity,
    endHumidity,
    windSpeed,
    windSpeedUnit,
    sky,
    soil,
    cropStage,
    bbchScale,
    equipment,
    equipmentPressure,
    equipmentVolume,
    volumeUnit,
  } = application;

  return (
    <>
      <Table size="small" fixed celled singleLine structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left" />
            <Table.HeaderCell textAlign="center">Início</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Fim</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Aplicação Nº</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {number}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Data</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {formatToBr(startDate)}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Hora</strong>
            </Table.Cell>
            <Table.Cell textAlign="center">{formatTime(startDate)}</Table.Cell>
            <Table.Cell textAlign="center">{formatTime(endDate)}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Temperatura Real</strong>
            </Table.Cell>
            <Table.Cell textAlign="center">{startTemperature}</Table.Cell>
            <Table.Cell textAlign="center">{endTemperature}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>%UR Ar</strong>
            </Table.Cell>
            <Table.Cell textAlign="center">{startHumidity}</Table.Cell>
            <Table.Cell textAlign="center">{endHumidity}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Vento</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {`${windSpeed} ${windSpeedUnit?.abbreviation || ''}`}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Céu</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {sky}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Solo</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {soil}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Estágio da Cultura</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {cropStage}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Escala BBCH</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {bbchScale}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Equip. de pulverização</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {equipment}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Pressão</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {equipmentPressure || ''}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="left">
              <strong>Volume Calda/há</strong>
            </Table.Cell>
            <Table.Cell textAlign="center" colSpan={2}>
              {`${equipmentVolume || ''} ${
                volumeUnit ? formatInitials(volumeUnit) : ''
              }`}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center" colSpan={3}>
              <LinkButton onClick={() => onEditClick(application)}>
                Editar
              </LinkButton>
              <LinkButton onClick={() => onRemoveClick(application)}>
                Remover
              </LinkButton>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </>
  );
}
