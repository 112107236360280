import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQuery } from 'react-query';

import { queryClient } from 'App';
import { projectKeys } from 'queries/project';
import api, { Response } from 'util/api';

export const TARGET_TYPE = {
  service: 1,
  product: 2,
};

type TargetFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  name?: string;
  type?: number;
};

type TargetResponse = {
  id: number;
  insideCode: string;
  name: string;
};

export function useQueryTargets(params: TargetFilter) {
  return useQuery(['targets', params], () =>
    getAllTargets({ orderBy: 'name', sort: 'asc', ...params }),
  );
}

async function getAllTargets(filter: TargetFilter) {
  const url = '/biological-targets';
  const config = { params: filter } as const;
  const { data } = await api.get<Response<TargetResponse>>(url, config);

  return {
    pagination: data.pagination,
    targets: data.data,
  };
}

export function useQueryTarget(targetId: number) {
  return useQuery(['target', targetId], () => getTarget(targetId));
}

async function getTarget(id: number) {
  const { data } = await api.get(`/biological-targets/${id}`);
  return data;
}

type TargetInput = {
  name: string;
  insideCode: string;
};

export function useCreateTarget(
  config?: UseMutationOptions<number, AxiosError, TargetInput, () => void>,
) {
  return useMutation(createTarget, {
    ...config,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('targets');
      queryClient.invalidateQueries('target');
      if (config?.onSuccess) {
        config?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createTarget(input: TargetInput) {
  const { headers } = await api.post('/biological-targets', input);
  const { location } = headers;
  const targetId = parseInt(location.substring(23), 10);

  return targetId;
}

type TargetUpdate = TargetInput & {
  id: number;
};

export function useUpdateTarget(
  config?: UseMutationOptions<void, AxiosError, TargetUpdate, () => void>,
) {
  return useMutation(updateTarget, {
    ...config,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('targets');
      queryClient.invalidateQueries('target');
      queryClient.invalidateQueries(projectKeys.all);
      if (config?.onSuccess) {
        config?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateTarget(input: TargetUpdate) {
  await api.patch(`/biological-targets/${input.id}`, input);
}
