/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { ptBR } from 'date-fns/locale';
import ReactDatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.css';

registerLocale('ptBR', ptBR);

export default function DatePicker({
  dateFormat = 'dd/MM/yyyy',
  placeholderText = 'dd/mm/aaaa',
  autoComplete = 'off',
  ...props
}: ReactDatePickerProps) {
  return (
    <ReactDatePicker
      {...props}
      locale={ptBR}
      dateFormat={dateFormat}
      placeholderText={placeholderText}
      autoComplete={autoComplete}
      maxDate={new Date(2100, 11, 31)}
    />
  );
}
