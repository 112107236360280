import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form, Input } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import { useGenerateContract } from 'mutations/quotation';

export default function ProductContractGenerationModal({
  quotation,
  open,
  onClose,
}) {
  const formRef = React.useRef(null);

  const history = useHistory();

  const { control, errors, handleSubmit } = useForm({ mode: 'onBlur' });
  // const watchDhippingDefinition = watch('shippingsDefinition');

  const { mutate: generateContract, isLoading } = useGenerateContract({
    onSuccess: (contractId) => onSuccess('Contrato gerado!', contractId),
  });

  function onSuccess(message, contractId) {
    toast.success(message);
    history.push(`/contratos/${contractId}`);
    onClose();
  }

  function onSubmit(values) {
    const input = {
      quotationId: quotation.id,
      number: quotation.code,
      signatureDate: quotation.responseDate,
      notes: values.notes || null,
      documentId: null,
      // hasDefinedShippings: values.shippingsDefinition === 'defined',
    };
    generateContract(input);
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Geração de contrato</Modal.Header>
      <Modal.Content>
        <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          {/* <Form.Field>
            <label>Remessas</label>
            <RadioGroup>
              <RadioInput
                name="shippingsDefinition"
                label="Definidas"
                value="defined"
                ref={register}
                defaultChecked
              />
              <RadioInput
                name="shippingsDefinition"
                label="A definir"
                value="undefined"
                ref={register}
              />
            </RadioGroup>
            {watchDhippingDefinition === 'defined' ? (
              <Text variant="secondary">
                Gera todas as remessas no contrato automaticamente.
              </Text>
            ) : (
              <Text variant="secondary">
                É necessário adicionar as remessas no contrato posteriormente.
              </Text>
            )}
          </Form.Field>
          <Divider hidden /> */}
          <Form.Field>
            <label>Observações (opcional)</label>
            <Controller
              control={control}
              name="notes"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors?.notes && <InputError>{errors?.notes?.message}</InputError>}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form="form" loading={isLoading} primary>
          Gerar contrato
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
