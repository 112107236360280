import React from 'react';

import { Table, Button, Icon } from 'semantic-ui-react';
import shortid from 'shortid';
import styled from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import { formatCurrencyWithSymbol } from 'util/Currency';

export default function QuotationPaymentsTable({
  quotation,
  quotationPayments = [],
  loading,
  onAddClick,
}) {
  const isEmpty = quotationPayments.length === 0;

  if (isEmpty) {
    return (
      <EmptyState>
        <EmptyState.Header>
          {quotation?.projects?.length > 0
            ? 'Pagamento não adicionado'
            : 'Nenhum estudo adicionado'}
        </EmptyState.Header>
        <EmptyState.Description>
          {quotation?.projects?.length > 0
            ? 'Clique no botão abaixo para adicionar o pagamento'
            : `Adicione estudos ao orçamento antes de adicionar os pagamentos`}
        </EmptyState.Description>
        <EmptyState.Actions>
          {quotation?.projects?.length > 0 ? (
            <Button type="button" onClick={onAddClick} primary basic>
              <Icon name="add" />
              Adicionar
            </Button>
          ) : null}
        </EmptyState.Actions>
      </EmptyState>
    );
  }

  return (
    <Container>
      <Table size="large" basic="very" fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Parcela</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Valor</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Descrição</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && <LoadingRow columns={10} rows={1} />}
          {!loading &&
            quotationPayments.map((payment) => {
              return (
                <Table.Row key={shortid.generate()}>
                  <Table.Cell textAlign="left" width={1}>
                    {payment.installment}
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={payment.description}>
                    {formatCurrencyWithSymbol(
                      payment.amount,
                      quotation.currency,
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={payment.description}>
                    {`${payment.description} (${payment.percentFormatted})`}
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 16px;
`;
