import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form } from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import EmployeeSearch from 'components/inputs/EmployeeSearch';
import InputError from 'components/inputs/InputError';
import { useDuplicateQuotation } from 'data/quotation';

type QuotationDuplicationModalProps = {
  quotationId: number;
  isOpen: boolean;
  onClose: () => void;
};

type FormValues = {
  harvestDate: Date;
  responsible: {
    value: number;
    label: string;
  };
};

const FORM_ID = 'form_quotation_duplication';

export const DuplicateQuotationInput = yup
  .object({
    harvestDate: yup
      .date()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    responsible: yup
      .object()
      .required('Obrigatório')
      .typeError('Obrigatório'),
  })
  .required();

export default function QuotationDuplicationModal({
  quotationId,
  isOpen,
  onClose,
}: QuotationDuplicationModalProps) {
  const history = useHistory();

  const resolver = yupResolver(DuplicateQuotationInput);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues: { harvest: new Date() },
  });

  const duplicateQuotation = useDuplicateQuotation({
    onSuccess: (duplicatedQuotationId) => {
      toast.success('Orçamento duplicado!');
      history.push(`/orcamentos/${duplicatedQuotationId}`);
      onClose();
    },
  });

  function onSubmit({ responsible, harvestDate }: FormValues) {
    const quotationResponsibleId = responsible.value;
    const harvest = harvestDate.getFullYear();

    duplicateQuotation.mutate({
      quotationId,
      quotationResponsibleId,
      harvest,
    });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Duplicar orçamento</Modal.Header>
      <Modal.Content>
        <p>
          Será gerado um novo orçamento com as mesmas informações, mas com a
          data atual e com o responsável selecionado abaixo
        </p>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field width={4}>
            <label>Safra</label>
            <Controller
              control={control}
              name="harvestDate"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  showYearPicker
                  placeholderText="Safra"
                  dateFormat="yyyy"
                />
              )}
            />
            {errors?.harvestDate && (
              <InputError>{errors?.harvestDate?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Responsável pelo orçamento</label>
            <Controller
              control={control}
              name="responsible"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <EmployeeSearch
                  autoComplete="off"
                  disabled={duplicateQuotation.isLoading}
                  loading={duplicateQuotation.isLoading}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="Busque pelo nome..."
                  value={value}
                />
              )}
            />
            {errors.responsible && (
              <InputError>{errors?.responsible?.message}</InputError>
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          type="submit"
          loading={duplicateQuotation.isLoading}
          disabled={duplicateQuotation.isLoading}
          primary
        >
          Duplicar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
