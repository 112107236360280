import { useHistory, useParams } from 'react-router-dom';

const tabStrategy: { [key: string]: number } = {
  'visao-geral': 0,
  atividades: 1,
  protocolo: 2,
  manutencoes: 3,
  aplicacoes: 4,
  avaliacoes: 5,
  amostras: 6,
  'dados-meteorologicos': 7,
  parcelas: 8,
  historico: 9,
} as const;

export function useProjectTabs(basePath: string) {
  const { active_tab: activeTab } = useParams<
    Record<string, string | undefined>
  >();
  const history = useHistory();

  const tabPathStrategy: { [key: number]: string } = {
    0: `${basePath}`,
    1: `${basePath}/atividades`,
    2: `${basePath}/protocolo`,
    3: `${basePath}/manutencoes`,
    4: `${basePath}/aplicacoes`,
    5: `${basePath}/avaliacoes`,
    6: `${basePath}/amostras`,
    7: `${basePath}/dados-meteorologicos`,
    8: `${basePath}/parcelas`,
    9: `${basePath}/historico`,
  } as const;

  const toggle = (tabIndex: number) => {
    if (activeTab !== tabPathStrategy[tabIndex]) {
      history.push(
        `${tabIndex ? tabPathStrategy[tabIndex] : tabPathStrategy[0]}`,
      );
    }
  };

  const activeIndex = activeTab ? tabStrategy[activeTab] : 0;

  return { toggle, activeIndex };
}
