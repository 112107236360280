import { AxiosError } from 'axios';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
  useInfiniteQuery,
} from 'react-query';

import api, { BasicQuery, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

type AddressResponse = {
  street: string | null;
  streetNumber: string | null;
  zipcode: string | null;
  neighborhood: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  complementary: string | null;
};

export type CustomerQuery = {
  id: number;
  companyName: string;
  tradingName: string;
  insideCode: string | null;
  cnpj: string | null;
  stateRegistration: string | null;
  phone: string | null;
  createdAt: string;
  updatedAt: string;
  address: AddressResponse;
};

type RepresentativeResponse = {
  id: number;
  name: string;
  email: string;
  phone: string | null;
  active: boolean;
  customerId: number;
  createdAt: string;
  updatedAt: string;
};

type CustomerWithRepresentativesResponse = CustomerQuery & {
  representatives: RepresentativeResponse[];
};

const getCustomer = async (customerId: number) => {
  const { data: customer } = await api.get<CustomerWithRepresentativesResponse>(
    `/customers/${customerId}`,
  );

  return customer;
};

export function useCustomer(customerId: number) {
  return useQuery(['customer', customerId], () => getCustomer(customerId));
}

type UseRepresentativeParams = {
  customerId: number;
  representativeId: number;
};

export function useRepresentative(
  params: UseRepresentativeParams,
  options?: UseQueryOptions<RepresentativeResponse, AxiosError>,
) {
  return useQuery(
    ['representative', params],
    () => getRepresentative(params),
    options,
  );
}

const getRepresentative = async ({
  customerId,
  representativeId,
}: UseRepresentativeParams) => {
  const { data } = await api.get<RepresentativeResponse>(
    `/customers/${customerId}/representatives/${representativeId}`,
  );
  return data;
};

export function useRepresentatives(
  customerId: number,
  options?: UseQueryOptions<RepresentativeResponse, AxiosError>,
) {
  return useQuery(
    ['representatives', { customerId }],
    () => getRepresentatives(customerId),
    options,
  );
}

async function getRepresentatives(customerId: number) {
  const { data } = await api.get<RepresentativeResponse>(
    `/customers/${customerId}/representatives`,
  );
  return data;
}

type CustomerFilter = BasicQuery & { tradingName: string };

export function useCustomers(params: CustomerFilter) {
  return useQuery(['customers', params], () => getCustomers(params));
}

export function useInfiniteCustomers(filter: CustomerFilter) {
  return useInfiniteQuery(
    ['samples', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getCustomers({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );
}

async function getCustomers(params: CustomerFilter) {
  const { data } = await api.get<Response<CustomerQuery>>(`/customers`, {
    params,
  });
  const { data: customers, pagination } = data;
  return { customers, pagination };
}

export function useExportRepresentatives(
  options?: UseMutationOptions<void, AxiosError, void, () => void>,
) {
  return useMutation(exportRepresentatives, {
    ...options,
  });
}

async function exportRepresentatives() {
  const { data: file, headers } = await api.get(
    `/customers/representatives/report`,
    {
      responseType: 'blob',
    },
  );

  const { filename } = headers;

  downloadFile(file, filename);
}
