import React from 'react';

import toast from 'react-hot-toast';
import { useParams, useHistory } from 'react-router-dom';
import { Header } from 'semantic-ui-react';

import QuotationProjectForm from 'components/forms/QuotationProjectForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import { useUpdateQuotationProject } from 'mutations/quotation';
import getQuotationProjectUseCase from 'queries/getQuotationProject';

export default function EditQuotationProject() {
  const { quotationId, quotationProjectId } = useParams();
  const history = useHistory();

  const [{ data: quotationProject, loading }, getQuotationProject] = useAsync(
    getQuotationProjectUseCase,
  );

  const formData = quotationProject ? mapToForm(quotationProject) : undefined;

  const {
    mutate: updateQuotationProject,
    isLoading: isUpdating,
  } = useUpdateQuotationProject({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Item atualizado com sucesso!');
    history.replace(`/orcamentos/${quotationId}`);
  }

  React.useEffect(() => {
    getQuotationProject({ quotationId, quotationProjectId });
  }, [getQuotationProject, quotationId, quotationProjectId]);

  function handleSubmit(values) {
    if (!quotationProject) {
      return;
    }

    const quotationProjectUpdated = {
      ...mapFromForm(values),
      id: quotationProjectId,
      quotationId,
    };

    updateQuotationProject(quotationProjectUpdated);
  }

  return (
    <Base loading={loading} text>
      <Container>
        {quotationProject ? (
          <>
            <Header as="h1">Edição de item</Header>
            <Section>
              <Section.Content>
                <QuotationProjectForm
                  formValues={formData}
                  onSubmit={handleSubmit}
                  isSubmiting={isUpdating}
                />
              </Section.Content>
            </Section>
          </>
        ) : null}
      </Container>
    </Base>
  );
}

function mapToForm(quotationProject) {
  return {
    description: quotationProject.description || null,
    protocol: quotationProject.protocol || null,
    products: quotationProject.products?.map((p) => ({
      value: p.id,
      label: p.name,
    })),
    crops: quotationProject.crops?.map((c) => ({
      value: c.id,
      label: c.name,
    })),
    targets: quotationProject.targets?.map((t) => ({
      value: t.id,
      label: t.name,
    })),
    division: {
      value: quotationProject.division,
      label: quotationProject.divisionDescription,
    },
    projectGoal: quotationProject.projectGoal?.toString(),
    managementType: quotationProject.managementType?.toString(),
    testLocation: quotationProject.testLocation?.toString(),
    durationForecast: quotationProject.durationForecast,
    amount:
      quotationProject.amount > 0
        ? quotationProject.amount / 100
        : quotationProject.amount,
    treatmentQuantity: parseInt(quotationProject.treatmentQuantity, 10),
    trialsNumber: quotationProject.trialsNumber
      ? parseInt(quotationProject.trialsNumber, 10)
      : null,
  };
}

function mapFromForm(values) {
  return {
    description: values.description || null,
    protocol: values.protocol || null,
    productIds: values.products ? values.products.map((p) => p.value) : [],
    cropIds: values.crops ? values.crops.map((c) => c.value) : [],
    targetIds: values.targets ? values.targets.map((t) => t.value) : [],
    division: values.division.value,
    projectGoal: parseInt(values.projectGoal, 10),
    managementType: parseInt(values.managementType, 10),
    testLocation: parseInt(values.testLocation, 10),
    durationForecast: values.durationForecast,
    amount: values.amount != null ? Math.round(values.amount * 100) : 0,
    treatmentQuantity: parseInt(values.treatmentQuantity, 10),
    trialsNumber: values.trialsNumber
      ? parseInt(values.trialsNumber, 10)
      : null,
  };
}
