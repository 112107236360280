import React from 'react';

import { Modal } from 'semantic-ui-react';

export default function AlertDialog({
  open,
  content,
  onClose,
  onClick,
  buttonText,
  negative,
  primary,
  isLoading = false,
}) {
  return (
    <Modal
      size="mini"
      open={open}
      header={content}
      onClose={onClose}
      actions={[
        {
          key: 'cancel',
          content: 'Cancelar',
          basic: true,
        },
        {
          key: buttonText,
          content: buttonText,
          negative,
          primary,
          onClick,
          loading: isLoading,
          disabled: isLoading,
        },
      ]}
    />
  );
}
