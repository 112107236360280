import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Button, Form, Grid } from 'semantic-ui-react';
import * as yup from 'yup';

import EmployeeSearch from 'components/inputs/EmployeeSearch';
import InputError from 'components/inputs/InputError';

export type ChangeDivisionResponsibleFormValues = {
  responsible: {
    value: number;
    label: string;
  };
};

type ChangeDivisionResponsibleModalProps = {
  defaultValues: ChangeDivisionResponsibleFormValues;
  isLoading?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: ChangeDivisionResponsibleFormValues) => void;
};

const schema = yup
  .object({
    responsible: yup
      .object()
      .shape({
        value: yup.number().required('Obrigatório'),
        label: yup.string().required('Obrigatório'),
      })
      .required('Obrigatório'),
  })
  .required('Obrigatório');

const formId = 'form_ingredient';

export default function ChangeDivisionResponsibleModal({
  defaultValues,
  isLoading = false,
  isOpen,
  onClose,
  onSubmit,
}: ChangeDivisionResponsibleModalProps) {
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Alterar responsável técnico</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Responsável</label>
                    <Controller
                      control={control}
                      name="responsible"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <EmployeeSearch
                          name="responsible"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          placeholder="Busque..."
                          autoComplete="off"
                          disabled={isLoading}
                          loading={isLoading}
                        />
                      )}
                    />
                    {errors.responsible?.value && (
                      <InputError>
                        {errors?.responsible?.value?.message}
                      </InputError>
                    )}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
