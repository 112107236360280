import React from 'react';

import styled from 'styled-components';

type Colors = 'green' | 'red' | 'grey' | 'yellow' | 'blue' | 'transparent';

type TagProps = {
  color: Colors;
  children: React.ReactNode;
};

export default function Tag({ color, children }: TagProps) {
  return <Container color={color}>{children}</Container>;
}

type ContainerProps = { color: Colors };

const Container = styled.span<ContainerProps>`
  padding: 4px 14px;
  border-radius: 4px;
  font-size: 1.26rem;
  color: ${({ color }) => handleColor(color)};
  background-color: ${({ color }) => handleBackgroundColor(color)};
`;

function handleBackgroundColor(color: Colors) {
  switch (color) {
    case 'green':
      return '#d0e7dc';
    case 'red':
      return '#e7c5d3';
    case 'grey':
      return '#e7e9f1';
    case 'yellow':
      return '#f7eda1';
    case 'blue':
      return '#c5e6f8';
    default:
      return 'hsl(0, 0%, 96%)';
  }
}

function handleColor(color: Colors) {
  switch (color) {
    case 'red':
      return '#af4b6c';
    case 'transparent':
      return '#b4b4b4';
    case 'yellow':
      return '#7a7127';
    case 'green': {
      return '#188752';
    }
    case 'blue': {
      return '#2581b6';
    }
    default:
      return '#222';
  }
}
