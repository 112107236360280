import { createMoneyIntlFormatter } from '@easymoney/formatter';
// eslint-disable-next-line import/no-unresolved
import { MoneyIntlOptions } from '@easymoney/formatter/dist/types';
import { createMoney } from '@easymoney/money';

type Real = 1;

type Dollar = 2;

type Euro = 3;

type Currency = Real | Dollar | Euro;

export const Currencies = {
  REAL: 1,
  DOLLAR: 2,
  EURO: 3,
} as const;

export const Locales = {
  PT_BR: 'pt-BR',
  EN_US: 'en-US',
};

const locales = {
  [Currencies.REAL]: 'pt-BR',
  [Currencies.DOLLAR]: 'en-US',
} as const;

export function getIsoCode(currency: Currency) {
  switch (currency) {
    case Currencies.REAL:
      return 'BRL';
    case Currencies.DOLLAR:
      return 'USD';
    case Currencies.EURO:
      return 'EUR';
    default:
      throw new TypeError('Código de moeda inválido!');
  }
}

/* useGrouping é obrigatório, sem ele é gerado um erro */
export function formatCurrency(
  amount: number,
  currency: Currency,
  options: MoneyIntlOptions,
) {
  const money = createMoney({ amount, currency: getIsoCode(currency) });
  const definedOptions = { useGrouping: true, ...options } || {
    style: 'decimal',
    useGrouping: true,
  };

  return createMoneyIntlFormatter().format(
    money,
    locales[currency],
    definedOptions,
  );
}

export function formatCurrencyWithSymbol(amount: number, currency: number) {
  return formatCurrency(amount, currency, { style: 'currency' });
}

type FormatCurrencyInput = {
  amount: number;
  currency: Currency;
  options?: MoneyIntlOptions;
};

/* useGrouping é obrigatório, sem ele é gerado um erro */
const defaultOptions: MoneyIntlOptions = {
  minimumFractionDigits: 2,
  style: 'decimal',
  useGrouping: true,
} as const;

export function format({
  amount,
  currency,
  options = defaultOptions,
}: FormatCurrencyInput) {
  const moneyCurrency = getIsoCode(currency);
  const money = createMoney({ amount, currency: moneyCurrency });
  const locale = locales[currency];
  return createMoneyIntlFormatter().format(money, locale, options);
}

/* useGrouping é obrigatório, sem ele é gerado um erro */
const codeOptions: MoneyIntlOptions = {
  currencyDisplay: 'code',
  style: 'currency',
  useGrouping: true,
} as const;

export function formatWithCode({ amount, currency }: FormatCurrencyInput) {
  return format({ amount, currency, options: codeOptions });
}

/* useGrouping é obrigatório, sem ele é gerado um erro */
const symbolOptions: MoneyIntlOptions = {
  currencyDisplay: 'symbol',
  style: 'currency',
  useGrouping: true,
} as const;

export function formatWithSymbol({ amount, currency }: FormatCurrencyInput) {
  return format({ amount, currency, options: symbolOptions });
}
