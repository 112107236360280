import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import { activityKeys } from 'queries/activity';
import { projectKeys } from 'queries/project';
import api, { ExportResponse } from 'util/api';
import { downloadFile } from 'util/FileManager';

export type ReferenceInput = {
  number: number;
  description: string;
};

export type ProjectInput = {
  projectId: number;
  references?: ReferenceInput;
  reassignedDate?: Date | null;
};

export type UpdateProjectCropInput = {
  projectCropId: number;
  projectId: number;
  cropId?: number;
  variety?: string | null;
  plantingDate?: string | null;
  customPlantingDate?: string | null;
  seedlingEmergenceDate?: string | null;
  cropStage?: string | null;
  bbchScale?: string | null;
  plantsNumber?: number | null;
  plantsNumberUnitId?: number | null;
};

export type LocationInput = {
  latitude: string;
  longitude: string;
  altitude: number;
  accessSketchId: number;
  locationSketchId: number;
  propertyId: number;
  projectId: number;
};

export type LocationUpdateInput = LocationInput & {
  locationId: number;
};

export type ActivityInput = {
  date: string;
  description: string | null;
  type: number;
  executionDate: Date | null;
  bbchScale: string | null;
};

export type ActivitiesInput = {
  projectId: number;
  activities: ActivityInput;
};

export function useUpdateProject(
  options?: UseMutationOptions<void, AxiosError, ProjectInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateProject(input: ProjectInput) {
  const { projectId, ...inputWithoutId } = input;
  await api.patch(`/projects/${projectId}`, inputWithoutId);
}

export function useUpdateProjectToNextStage(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateProjectToNextStage, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateProjectToNextStage(projectId: number) {
  await api.patch(`/projects/${projectId}/next-stage`);
}

export function useUpdateProjectCrop(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateProjectCropInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(updateProjectCrop, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateProjectCrop(input: UpdateProjectCropInput) {
  const { projectId, projectCropId, ...updatedInput } = input;
  await api.patch(
    `/projects/${projectId}/project-crops/${projectCropId}`,
    updatedInput,
  );
}

const addLocation = async ({ projectId, ...input }: LocationInput) => {
  await api.post(`/projects/${projectId}/location`, input);
};

export function useAddLocation(
  options?: UseMutationOptions<void, AxiosError, LocationInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(addLocation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export function useUpdateLocation(
  options?: UseMutationOptions<
    void,
    AxiosError,
    LocationUpdateInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(updateLocation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('locations');
      queryClient.invalidateQueries('location');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateLocation(input: LocationUpdateInput) {
  const { locationId, projectId, ...updatedInput } = input;
  await api.patch(`projects/${projectId}/location/${locationId}`, updatedInput);
}

const addActivities = async ({ projectId, ...input }: ActivitiesInput) => {
  await api.post(`/projects/${projectId}/activities`, input);
};

export function useAddActivities(
  options?: UseMutationOptions<void, AxiosError, ActivitiesInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(addActivities, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

type PerformActivityInput = {
  activityId: number;
  executionDate: Date;
  bbchScale?: string;
};

export function usePerformActivity(
  options?: UseMutationOptions<
    void,
    AxiosError,
    PerformActivityInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(performActivity, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function performActivity({ activityId, ...input }: PerformActivityInput) {
  await api.post(`/activities/${activityId}/perform`, input);
}

type AddActivityInput = {
  date: Date;
  description?: string | null;
  projectId: number;
  type: number;
};

export function useAddActivity(
  options?: UseMutationOptions<void, AxiosError, AddActivityInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(addActivity, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addActivity(input: AddActivityInput) {
  await api.post(`/activities`, input);
}

type UpdateActivityInput = {
  activityId: number;
  date: Date;
  type: number;
  description?: string | null;
  executionDate?: Date | null;
  bbchScale?: string | null;
};

export function useUpdateActivity(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateActivityInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(updateActivity, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
      queryClient.invalidateQueries(activityKeys.detail(variables.activityId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateActivity({ activityId, ...input }: UpdateActivityInput) {
  await api.patch(`/activities/${activityId}`, input);
}

export function useDeleteActivity(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(deleteActivity, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(activityKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteActivity(activityId: number) {
  await api.delete(`/activities/${activityId}`);
}

const CONFIG = { responseType: 'blob' } as const;

export function useExportApplicationForm(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  return useMutation(exportApplicationForm, {
    ...options,
  });
}

async function exportApplicationForm(projectId: number) {
  const url = `/projects/${projectId}/applications/form`;
  const { data: file, headers } = await api.get(url, CONFIG);
  const { filename } = headers;
  downloadFile(file, filename);
}

export function useExportShortResearchProject(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  return useMutation(exportShortResearchProject, { ...options });
}

async function exportShortResearchProject(projectId: number) {
  const url = `/projects/${projectId}/field-research-project`;
  const { data: file, headers } = await api.post(url, {}, CONFIG);
  const { filename } = headers;
  downloadFile(file, filename);
}

export function useExportResearchProject(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  return useMutation(exportResearchProject, { ...options });
}

async function exportResearchProject(projectId: number) {
  const url = `/projects/${projectId}/research-project`;
  const { data: file, headers } = await api.post(url, {}, CONFIG);
  const { filename } = headers;
  downloadFile(file, filename);
}

export function useExportProjectsInProgress(
  options?: UseMutationOptions<ExportResponse, AxiosError, void, () => void>,
) {
  return useMutation(exportProjectsInProgress, { ...options });
}

const exportProjectsInProgress = async () => {
  const { data: file, headers } = await api.get(
    `/projects/reports/in-progress`,
    CONFIG,
  );

  const { filename } = headers;

  return { file, filename };
};

export function useExportProjects(
  options?: UseMutationOptions<ExportResponse, AxiosError, void, () => void>,
) {
  return useMutation(exportProjects, { ...options });
}

const exportProjects = async () => {
  const { data: file, headers } = await api.get(
    `/projects/reports/minimal`,
    CONFIG,
  );
  const { filename } = headers;
  return { file, filename };
};

export function useExportCompletedProjectsWithoutReport(
  options?: UseMutationOptions<ExportResponse, AxiosError, void, () => void>,
) {
  return useMutation(exportCompletedProjectsWithoutReport, { ...options });
}

const exportCompletedProjectsWithoutReport = async () => {
  const url = `/projects/reports/without-report`;
  const { data: file, headers } = await api.get(url, CONFIG);
  const { filename } = headers;

  return { file, filename };
};

export function useExportFinalStageProjects(
  options?: UseMutationOptions<ExportResponse, AxiosError, void, () => void>,
) {
  return useMutation(exportFinalStageProjects, { ...options });
}

const exportFinalStageProjects = async () => {
  const url = `/projects/reports/final-stage`;
  const { data: file, headers } = await api.get(url, CONFIG);
  const { filename } = headers;

  return { file, filename };
};

export function useCancelAndDuplicateProject(
  options?: UseMutationOptions<number, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(cancelAndDuplicateProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function cancelAndDuplicateProject(projectId: number) {
  const { headers } = await api.post(
    `/projects/${projectId}/cancel-and-duplicate`,
  );
  const { location } = headers;
  const duplicatedProjectId = parseInt(location.substring(13), 10);
  return duplicatedProjectId;
}

type ChangeProjectStageInput = {
  projectId: number;
  projectStageId: number;
};

export function useChangeProjectStage(
  options?: UseMutationOptions<void, AxiosError, ChangeProjectStageInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changeProjectStage, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function changeProjectStage({
  projectId,
  projectStageId,
}: ChangeProjectStageInput) {
  const url = `/projects/${projectId}/stage`;
  const data = { projectStageId } as const;
  await api.patch(url, data);
}

type UseDeployProjectOptions = UseMutationOptions<void, AxiosError, number>;

export function useDeployProject(options?: UseDeployProjectOptions) {
  const queryClient = useQueryClient();

  return useMutation(deployProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deployProject(projectId: number) {
  const url = `/projects/${projectId}/deployment`;
  await api.post(url);
}

type SendToCustomerReviewInput = {
  projectId: number;
  reportReviewId: number;
};

type UseSendToCustomerReviewOptions = UseMutationOptions<
  void,
  AxiosError,
  SendToCustomerReviewInput
>;

export function useSendToCustomerReview(
  options?: UseSendToCustomerReviewOptions,
) {
  const queryClient = useQueryClient();

  return useMutation(sendToCustomerReview, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const sendToCustomerReview = async ({
  projectId,
  reportReviewId,
}: SendToCustomerReviewInput) => {
  const url = `/projects/${projectId}/send-to-customer-review`;
  await api.post(url, { reportReviewId });
};

type FinishProjectInput = {
  projectId: number;
  reportId: number;
};

type UseFinishProjectOptions = UseMutationOptions<
  void,
  AxiosError,
  FinishProjectInput
>;

export function useFinishProject(options?: UseFinishProjectOptions) {
  const queryClient = useQueryClient();

  return useMutation(finishProject, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function finishProject({ projectId, reportId }: FinishProjectInput) {
  const url = `/projects/${projectId}/finish`;
  const body = { reportId };
  await api.post(url, body);
}

type ProjectDocumentInput = {
  projectId: number;
  documentIds: number[];
  documentType: number;
};

export function useAddProjectDocuments(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ProjectDocumentInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();

  return useMutation(addProjectDocuments, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function addProjectDocuments({
  projectId,
  documentIds,
  documentType,
}: ProjectDocumentInput) {
  await api.post(`/projects/${projectId}/documents`, {
    documentIds,
    documentType,
  });
}
