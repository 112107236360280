import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  flex-direction: column;
  padding: 8px 0;
`;

export const Label = styled.div`
  color: #767676;
  font-size: 14px;
`;

const valueModifiers = {
  onClick: () => css`
    color: #4a9add;
    cursor: pointer;
  `,
};

type ValueProps = {
  onClick?: () => void;
};

export const Value = styled.span<ValueProps>`
  ${({ onClick }) => css`
    font-size: 14px;

    ${onClick && valueModifiers.onClick()}
  `}
`;
