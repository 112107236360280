import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { Form, Input } from 'semantic-ui-react';

import CustomerSearch from 'components/inputs/CustomerSearch';
import DivisionSelect from 'components/inputs/DivisionSelect';
import { useAuth } from 'hooks/auth';
import { QUOTATION_TYPE } from 'queries/quotation';
import { useQuotationFilter } from 'templates/Quotations/use-quotation-filter';
import debounce from 'util/debounce';

import QuotationStatusSelect from './QuotationStatusSelect';

export default function QuotationFilter() {
  const { filter, updateFilter } = useQuotationFilter();
  const { control } = useForm({ defaultValues: filter });
  const { user } = useAuth();

  const divisions = !user.isAdmin ? user.divisions : [];

  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="code"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  debouncedFilter({ code: data.value });
                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Código"
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="customers"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                onChange={(data) => {
                  updateFilter({ customers: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Cliente"
                autoComplete="off"
                isClearable
                isMulti
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="quotationType"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Select
                defaultValue=""
                instanceId="quotationType"
                isClearable
                name="quotationType"
                onBlur={onBlur}
                onChange={(data) => {
                  updateFilter({ quotationType: data || '' });
                  onChange(data);
                }}
                options={[
                  { value: QUOTATION_TYPE.service, label: 'Serviço' },
                  { value: QUOTATION_TYPE.product, label: 'Produto' },
                ]}
                placeholder="Tipo"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="harvest"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  if (data.value.length === 4) {
                    debouncedFilter({ harvest: data.value });
                  } else {
                    debouncedFilter({ harvest: undefined });
                  }

                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                maxLength={4}
                placeholder="Safra"
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="status"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <QuotationStatusSelect
                onChange={(data) => {
                  updateFilter({ status: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Status"
                autoComplete="off"
                isClearable
                isMulti
              />
            )}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Controller
            control={control}
            name="divisions"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <DivisionSelect
                onChange={(data) => {
                  updateFilter({ divisions: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Setor"
                autoComplete="off"
                isClearable
                isMulti
                divisions={divisions}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
