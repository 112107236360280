export const DOCUMENT_CONTRACT = 1;
export const DOCUMENT_ASSAY = 2;
export const DOCUMENT_PROTOCOL = 3;
export const DOCUMENT_AREA_ASSIGNMENT = 4;
export const DOCUMENT_RET = 5;
export const DOCUMENT_CHECKIN = 6;
export const DOCUMENT_CUSTODYCHAIN = 7;
export const DOCUMENT_INVOICE = 8;
export const DOCUMENT_ACCESSSKETCH = 9;
export const DOCUMENT_LOCATIONSKETCH = 10;
export const DOCUMENT_REPORTREVIEW = 11;
export const DOCUMENT_REPORT = 12;
export const DOCUMENT_EVALUATION = 13;
export const DOCUMENT_STANDARD_OPERATING_PROCEDURE = 14;
export const DOCUMENT_APPLICATION = 15;
export const DOCUMENT_MAINTENANCE = 16;
export const DOCUMENT_FEDERAL_REPORT = 17;
export const DOCUMENT_SAMPLES_RETURN_TERM = 18;

export type Document = {
  id: number;
  name: string;
  size: number;
  key: string;
  url: string;
  type: number;
};

export type DocumentContract = 1;
export type DocumentAssay = 2;
export type DocumentProtocol = 3;
export type DocumentAreaAssignment = 4;
export type DocumentRet = 5;
export type DocumentCheckin = 6;
export type DocumentCustodyChain = 7;
export type DocumentInvoice = 8;
export type DocumentAccessSketch = 9;
export type DocumentLocationSketch = 10;
export type DocumentReportReview = 11;
export type DocumentReport = 12;
export type DocumentEvaluation = 13;
export type DocumentStandardOperatingProcedure = 14;
export type DocumentApplication = 15;
export type DocumentMaintenance = 16;
export type DocumentFederalReport = 17;

export type DocumentTypes =
  | DocumentContract
  | DocumentAssay
  | DocumentProtocol
  | DocumentAreaAssignment
  | DocumentRet
  | DocumentCheckin
  | DocumentCustodyChain
  | DocumentInvoice
  | DocumentAccessSketch
  | DocumentLocationSketch
  | DocumentReportReview
  | DocumentReport
  | DocumentEvaluation
  | DocumentStandardOperatingProcedure
  | DocumentApplication
  | DocumentMaintenance
  | DocumentFederalReport;
