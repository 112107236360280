import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Controller,
  NestedValue,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Modal,
  Button,
  Form,
  Checkbox,
  Divider,
  Icon,
  Message,
} from 'semantic-ui-react';
import { number, object } from 'yup';

import IngredientModalForm from 'components/forms/IngredientModalForm';
import HiddenLabel from 'components/inputs/HiddenLabel';
import IngredientSearch from 'components/inputs/IngredientSearch';
import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelectV2';
import { useModal } from 'hooks/useModal';
import { emptyStringToUndefined } from 'util/validation';

export type AddProductIngredientModalHandles = {
  openModal: () => void;
};

export type AddProductIngredientModalFormData = {
  ingredient: NestedValue<{
    value: number;
    label: string;
  }>;
  concentration?: number;
  measurementUnit?: number;
  isUnreportedConcentration?: boolean;
};

type AddProductIngredientModalProps = {
  onSubmit: SubmitHandler<AddProductIngredientModalFormData>;
};

const schema = object().shape({
  ingredient: object()
    .required('Ingrediente é um campo obrigatório')
    .transform(emptyStringToUndefined),
  concentration: number()
    .notRequired()
    .transform(emptyStringToUndefined)
    .when('isUnreportedConcentration', {
      is: (val) => val === false,
      then: number()
        .required('Concentração é um campo obrigatório')
        .transform(emptyStringToUndefined),
      otherwise: number()
        .notRequired()
        .optional()
        .nullable()
        .transform(emptyStringToUndefined),
    }),
  measurementUnit: object()
    .optional()
    .nullable(),
});

const formId = 'form_product_ingredient';

const AddProductIngredientModal = React.forwardRef(
  ({ onSubmit }: AddProductIngredientModalProps, ref) => {
    const newIngredientModal = useModal();

    const [isVisible, setVisible] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false);

    const formRef = React.useRef(null);

    const { control, errors, watch, clearErrors, handleSubmit } = useForm<
      AddProductIngredientModalFormData
    >({
      mode: 'onBlur',
      resolver: yupResolver<AddProductIngredientModalFormData>(schema),
      defaultValues: {
        isUnreportedConcentration: false,
      },
    });

    const isUnreportedConcentration = watch('isUnreportedConcentration');

    const openModal = React.useCallback(() => {
      setVisible(!isVisible);
    }, [isVisible]);

    React.useImperativeHandle(ref, () => {
      return {
        openModal,
      };
    });

    const handleCloseModal = React.useCallback(() => {
      setVisible(false);
    }, []);

    function handleSuccess() {
      newIngredientModal.close();
      setIsSuccess(true);
    }

    if (!isVisible) {
      return null;
    }

    return (
      <>
        <Modal size="tiny" open={isVisible} onClose={handleCloseModal}>
          <Modal.Header>Adicionar ingrediente do produto</Modal.Header>
          <Modal.Content>
            <Form
              id={formId}
              ref={formRef}
              onSubmit={handleSubmit((values) => {
                const updatedValues = isUnreportedConcentration
                  ? {
                      ...values,
                      concentration: undefined,
                      measurementUnit: undefined,
                    }
                  : values;

                onSubmit(updatedValues);
              })}
            >
              <Form.Group>
                <Form.Field width={12}>
                  <label>Ingrediente ativo</label>
                  <Controller
                    control={control}
                    name="ingredient"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <IngredientSearch
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.ingredient && (
                    <InputError>{errors?.ingredient?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={4}>
                  <HiddenLabel />
                  <Button
                    type="button"
                    onClick={newIngredientModal.open}
                    basic
                    primary
                    fluid
                  >
                    <Icon name="add" />
                    Novo
                  </Button>
                </Form.Field>
              </Form.Group>
              {isSuccess ? (
                <Message
                  content="Novo ingrediente salvo com sucesso!"
                  positive
                />
              ) : null}
              <Divider hidden />
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Concentração</label>
                  <Controller
                    control={control}
                    name="concentration"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <NumberFormat
                        onValueChange={(data) => {
                          onChange(data.floatValue);
                        }}
                        onBlur={onBlur}
                        value={value}
                        decimalSeparator=","
                        thousandSeparator="."
                        decimalScale={3}
                        disabled={isUnreportedConcentration}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.concentration && (
                    <InputError>{errors?.concentration.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Unidade</label>
                  <Controller
                    control={control}
                    name="measurementUnit"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <MeasurementUnitSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        autoComplete="off"
                        disabled={isUnreportedConcentration}
                        isClearable
                      />
                    )}
                  />
                  {errors.measurementUnit && (
                    <InputError>{errors?.measurementUnit.message}</InputError>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Controller
                  control={control}
                  name="isUnreportedConcentration"
                  defaultValue={false}
                  render={({ onChange, onBlur, value }) => (
                    <Checkbox
                      onChange={(e, data) => {
                        if (data.checked) {
                          clearErrors(['concentration', 'measurementUnit']);
                        }

                        onChange(data.checked);
                      }}
                      onBlur={onBlur}
                      checked={value}
                      value={value}
                      label="Concentração não informada"
                      autoComplete="off"
                    />
                  )}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleCloseModal} basic>
              Cancelar
            </Button>
            <Button form={formId} primary>
              Adicionar
            </Button>
          </Modal.Actions>
        </Modal>

        {newIngredientModal.isOpen ? (
          <IngredientModalForm
            isOpen={newIngredientModal.isOpen}
            onClose={newIngredientModal.close}
            onSuccess={handleSuccess}
          />
        ) : null}
      </>
    );
  },
);

export default AddProductIngredientModal;
