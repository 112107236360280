import React from 'react';

import filesize from 'filesize';
import CurrencyInput from 'react-currency-input';
import toast from 'react-hot-toast';
import { useLocation, Link, useHistory } from 'react-router-dom';
import {
  Button,
  Form,
  Header,
  Dropdown,
  Divider,
  Input,
  Modal,
} from 'semantic-ui-react';
import shortid from 'shortid';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useQueryCrops } from 'data/crop';
import { useQueryTargets } from 'hooks/target';
import { useErrorHandler } from 'hooks/use-error-handler';
import useAsync from 'hooks/useAsync';
import useDebounce from 'hooks/useDebounce';
import useFormValidation from 'hooks/useFormValidation';
import createProjectUseCase from 'mutations/createProject';
import getAllProductsUseCase from 'queries/getProducts';
import api from 'util/api';
import {
  divisionOptions,
  managementTypeOptions,
  projectGoalOptions,
  testLocationOptions,
} from 'util/options';

import InstallmentsTable from '../AddProjectInstallmentsTable';
import validate from './validate';

export default function AddProject() {
  const INITIAL_STATE = {
    harvest: '',
    projectGoal: '',
    managementType: '',
    division: '',
    crops: [],
    targets: [],
    protocol: '',
    requiredDate: '',
    products: [],
    installments: [],
    insideCode: '',
    testLocation: '',
    notes: '',
    protocolDocument: '',
    areaAssignmentDocument: '',
    retDocument: '',
  };

  const INITIAL_STATE_PAYMENT = {
    amount: '',
    maskedValue: '',
    paymentForecast: '',
  };

  const DOCUMENT_TYPE_PROTOCOL = 3;
  const DOCUMENT_TYPE_AREA_ASSIGNMENT = 4;
  const DOCUMENT_TYPE_RET = 5;

  const [payment, setPayment] = React.useState(INITIAL_STATE_PAYMENT);
  const [selectedTargets, setSelectedTargets] = React.useState([]);
  const [selectedProducts, setSelectedProducts] = React.useState([]);
  const [deleteAlert, setDeleteAlert] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState({});

  const location = useLocation();
  const { contractId, currency } = location.state;

  const history = useHistory();
  const { handleError } = useErrorHandler();

  const handleNavigation = React.useCallback(
    (id) => {
      history.replace(`/estudos/${id}`);
    },
    [history],
  );

  const { data: crops, isLoading: loadingCrops } = useQueryCrops({
    pageSize: 100,
  });
  const cropOptions = crops?.crops?.map((crop) => ({
    key: crop.id,
    value: crop.id,
    text: crop.name,
  }));

  const [
    { data: products, loading: loadingProducts },
    getAllProducts,
  ] = useAsync(getAllProductsUseCase);
  const productOptions = products?.data?.map((product) => ({
    key: product.id,
    value: product.id,
    text: product.name,
  }));

  const [
    { data: projectId, loading: loadingCreate, error: errorCreate },
    createProject,
  ] = useAsync(createProjectUseCase);

  const submitFunction = async () => {
    await createProject({
      ...values,
      contractId,
      protocolDocumentId: values?.protocolDocument?.id,
      areaAssignmentDocumentId: values?.areaAssignmentDocument?.id,
      retDocumentId: values?.retDocument?.id,
    });
  };

  const {
    handleSubmit,
    handleChange,
    handleDateChange,
    handleBlur,
    handleSearch,
    setValue,
    values,
    errors,
    search,
  } = useFormValidation(INITIAL_STATE, validate, submitFunction);

  const debouncedSearch = useDebounce(search, 300);
  const { data: targetsData, isLoading: loadingTargets } = useQueryTargets({
    name: debouncedSearch.targets,
    page: 1,
    pageSize: 20,
    orderBy: 'name',
    sort: 'asc',
  });

  const targetOptions = targetsData?.targets?.map((target) => ({
    key: target.id,
    value: target.id,
    text: target.name,
  }));

  React.useEffect(() => {
    if (debouncedSearch.products) {
      getAllProducts({
        name: debouncedSearch.products,
        page: 1,
        pageSize: 20,
        orderBy: 'name',
        sort: 'asc',
      });
    }
  }, [debouncedSearch, getAllProducts]);

  React.useEffect(() => {
    if (projectId) {
      toast.success('Estudo adicionado com sucesso');
      handleNavigation(projectId);
    }
  }, [handleNavigation, projectId]);

  React.useEffect(() => {
    if (errorCreate) {
      toast.error('Não foi possível adicionar o estudo!');
    }
  }, [errorCreate]);

  function isPaymentValid(newPayment) {
    return newPayment?.amount && newPayment?.paymentForecast;
  }

  function handleAddPayment(e) {
    e.preventDefault();

    if (!isPaymentValid(payment)) {
      return;
    }

    const updatedPayments = [...values.installments, payment];

    setValue(updatedPayments, 'installments');
    setPayment(INITIAL_STATE_PAYMENT);
  }

  function handleTargetsChange(e, data) {
    const options = data.value.map((it) =>
      data.options.find((option) => option.value === it),
    );

    setSelectedTargets(options);

    handleChange(e, data);
  }

  function handleProductsChange(e, data) {
    const options = data.value.map((it) =>
      data.options.find((option) => option.value === it),
    );

    setSelectedProducts(options);

    handleChange(e, data);
  }

  function handleUpload(e, documentType) {
    const file = e.target.files[0];
    const { name } = e.target;

    const uploadedFile = {
      file,
      id: shortid.generate(),
      name: file.name,
      readableSize: filesize(file.size),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    };

    setValue(uploadedFile, name);

    processUpload({
      uploadedFile,
      documentType,
      onUploadProgress: (event) =>
        handleUploadProgress(event, { name, uploadedFile }),
      onUploadSuccess: (response) =>
        handleUploadSuccess(response, { name, uploadedFile }),
      onUploadFailure: () => handleUploadFailure({ name, uploadedFile }),
    });
  }

  function handleUploadProgress(e, { name, uploadedFile }) {
    const progress = parseInt(Math.round((e.loaded * 100) / e.total), 10);

    setValue({ ...uploadedFile, progress }, name);
  }

  function handleUploadSuccess(response, { name, uploadedFile }) {
    const { id, url } = response.data;

    setValue(
      {
        ...uploadedFile,
        uploaded: true,
        id,
        url,
      },
      name,
    );
  }

  function handleUploadFailure({ name, uploadedFile }) {
    setValue({ ...uploadedFile, error: true }, name);
  }

  async function handleDelete(name, uploadedFile) {
    if (!values[name]?.url) {
      setValue('', name);
      return;
    }

    setSelectedFile({ id: uploadedFile.id, name });
    setDeleteAlert(true);
  }

  function processUpload({
    uploadedFile,
    documentType,
    onUploadProgress,
    onUploadSuccess,
    onUploadFailure,
  }) {
    const data = new FormData();

    data.append('file', uploadedFile.file, uploadedFile.name);
    data.append('documentType', documentType);

    api
      .post('/documents', data, {
        onUploadProgress: (e) => onUploadProgress(e, { uploadedFile }),
      })
      .then((response) => onUploadSuccess(response))
      .catch(() => onUploadFailure());
  }

  async function deleteDocumentFromApi() {
    try {
      await api.delete(`/documents/${selectedFile?.id}`);

      setValue('', selectedFile?.name);
    } catch (error) {
      handleError(error);
    }
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Header as="h2">Estudo</Header>
      <Form.Field>
        <label>Safra</label>
        <Input
          name="harvest"
          placeholder="Ex: 2020"
          value={values.harvest}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength="4"
          autoComplete="off"
          fluid
        />
        {errors.harvest && <InputError>{errors.harvest}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Protocolo</label>
        <Input
          name="protocol"
          value={values.protocol}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          fluid
        />
        {errors.protocol && <InputError>{errors.protocol}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Prazo Requerido</label>
        <DatePicker
          name="requiredDate"
          placeholderText="dd/mm/aaaa"
          dateFormat="dd/MM/yyyy"
          autoComplete="off"
          selected={values.requiredDate}
          onChange={(value) => handleDateChange(value, 'requiredDate')}
        />
      </Form.Field>
      <Form.Field>
        <label>Finalidade</label>
        <Dropdown
          name="projectGoal"
          placeholder="Selecione a finalidade do estudo"
          value={values.projectGoal}
          options={projectGoalOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          selection
          fluid
        />
        {errors.projectGoal && <InputError>{errors.projectGoal}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Tipo de condução</label>
        <Dropdown
          name="managementType"
          placeholder="Selecione o tipo de condução"
          value={values.managementType}
          options={managementTypeOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          selection
          fluid
        />
        {errors.managementType && (
          <InputError>{errors.managementType}</InputError>
        )}
      </Form.Field>
      <Form.Field>
        <label>Centro de custo</label>
        <Dropdown
          name="division"
          placeholder="Selecione o setor do estudo"
          value={values.division}
          options={divisionOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          selection
          fluid
        />
        {errors.division && <InputError>{errors.division}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Unidade teste</label>
        <Dropdown
          name="testLocation"
          placeholder="Selecione a unidade teste"
          value={values.testLocation}
          options={testLocationOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          selection
          fluid
        />
        {errors.division && <InputError>{errors.division}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Culturas (opcional)</label>
        <Dropdown
          name="crops"
          placeholder="Selecione as culturas"
          value={values.crops}
          options={cropOptions}
          onChange={handleChange}
          onBlur={handleBlur}
          loading={loadingCrops}
          closeOnChange
          selection
          multiple
          search
          fluid
        />
        {errors.crops && <InputError>{errors.crops}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Alvos biológicos (opcional)</label>
        <Dropdown
          name="targets"
          icon="search"
          placeholder="Buscar..."
          value={values.targets}
          options={[...(targetOptions || []), ...selectedTargets]}
          onChange={handleTargetsChange}
          onSearchChange={handleSearch}
          onBlur={handleBlur}
          loading={loadingTargets}
          closeOnChange
          selection
          multiple
          search
          fluid
        />
        {errors.targets && <InputError>{errors.targets}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Produtos (opcional)</label>
        <Dropdown
          name="products"
          icon="search"
          placeholder="Buscar..."
          value={values.products}
          options={[...(productOptions || []), ...selectedProducts]}
          onChange={handleProductsChange}
          onSearchChange={handleSearch}
          onBlur={handleBlur}
          loading={loadingProducts}
          closeOnChange
          selection
          multiple
          search
          fluid
        />
        {errors.products && <InputError>{errors.products}</InputError>}
      </Form.Field>
      <Link to="/produtos/novo" target="_blank">
        Cadastrar novo produto
      </Link>
      <Divider hidden />
      <Form.Field>
        <label>Código interno (opcional)</label>
        <Input
          name="insideCode"
          placeholder=""
          value={values.insideCode}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          fluid
        />
        {errors.insideCode && <InputError>{errors.insideCode}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Observações (opcional)</label>
        <Input
          name="notes"
          placeholder=""
          value={values.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          autoComplete="off"
          fluid
        />
      </Form.Field>

      <Divider hidden />
      <Divider hidden />
      <Divider />
      <Divider hidden />

      <Header as="h2">Parcelas</Header>
      <Form.Group>
        <Form.Field width="7">
          <label>Valor</label>
          <CurrencyInput
            name="amount"
            prefix={currency === 2 ? '$' : 'R$'}
            decimalSeparator={currency === 2 ? '.' : ','}
            thousandSeparator={currency === 2 ? ',' : '.'}
            precision="2"
            value={payment.amount}
            onChange={(maskedValue, floatvalue) => {
              setPayment({ ...payment, amount: floatvalue, maskedValue });
            }}
          />
        </Form.Field>
        <Form.Field width="7">
          <label>Previsão</label>
          <DatePicker
            name="paymentForecast"
            placeholderText="dd/mm/aaaa"
            dateFormat="dd/MM/yyyy"
            autoComplete="off"
            selected={payment.paymentForecast}
            onChange={(value) => {
              setPayment({ ...payment, paymentForecast: value });
            }}
          />
        </Form.Field>
        <Form.Field width="2">
          <label style={{ color: '#fff' }}>.</label>
          <Button icon="add" onClick={handleAddPayment} fluid positive />
        </Form.Field>
      </Form.Group>

      <InstallmentsTable installments={values.installments} />

      <Divider hidden />

      <Header as="h2">Documentos</Header>
      <Form.Field>
        <label>Protocolo (opcional)</label>
        <UploadInput
          name="protocolDocument"
          uploadedFile={values.protocolDocument}
          onUpload={(e) => handleUpload(e, DOCUMENT_TYPE_PROTOCOL)}
          onDelete={handleDelete}
        />
        {errors.protocolDocument && (
          <InputError>{errors.protocolDocument}</InputError>
        )}
      </Form.Field>
      <Form.Field>
        <label>Termo de cessão de área (opcional)</label>
        <UploadInput
          name="areaAssignmentDocument"
          uploadedFile={values.areaAssignmentDocument}
          onUpload={(e) => handleUpload(e, DOCUMENT_TYPE_AREA_ASSIGNMENT)}
          onDelete={handleDelete}
        />
        {errors.areaAssignmentDocument && (
          <InputError>{errors.areaAssignmentDocument}</InputError>
        )}
      </Form.Field>
      <Form.Field>
        <label>RET (opcional)</label>
        <UploadInput
          name="retDocument"
          uploadedFile={values.retDocument}
          onUpload={(e) => handleUpload(e, DOCUMENT_TYPE_RET)}
          onDelete={handleDelete}
        />
        {errors.retDocument && <InputError>{errors.retDocument}</InputError>}
      </Form.Field>

      <Button
        type="submit"
        primary
        floated="right"
        style={{ marginTop: 20 }}
        loading={loadingCreate}
      >
        Adicionar estudo
      </Button>

      <Modal
        size="mini"
        open={deleteAlert}
        header="Excluir documento?"
        content="Tem certeza que deseja excluir o documento?"
        onClose={() => setDeleteAlert(false)}
        actions={[
          'Cancelar',
          {
            key: 'delete',
            content: 'Excluir',
            negative: true,
            onClick: () => deleteDocumentFromApi(),
          },
        ]}
      />
    </Form>
  );
}
