/* eslint-disable import/named */
import { Employee } from 'util/Employee';

type Installment = {
  id: number;
  number: number;
  amount: number;
  paymentForecast: Date | null;
  projectId: number;
  paymentId: number | null;
};

export type Exploratory = 1;
export type Official = 2;

export type ProjectGoal = Exploratory | Official;

export type Field = 1;
export type Greenhouse = 2;
export type Laboratory = 3;

export type ManagementType = Field | Greenhouse | Laboratory;

export type Bioassays = 1;
export type Biotec = 2;
export type Collection = 3;
export type Fungicide = 7;
export type Herbicide = 8;
export type Insecticide = 9;
export type Nematology = 12;
export type SpecialProject = 13;
export type Residue = 14;
export type Fertilizer = 15;
export type MipExperience = 16;

export type Division =
  | Bioassays
  | Biotec
  | Collection
  | Fungicide
  | Herbicide
  | Insecticide
  | Nematology
  | SpecialProject
  | Residue
  | Fertilizer
  | MipExperience;

export type ProjectQuery = {
  id: number;
  harvest: number;
  requiredDate: Date | null;
  contractId: number;
  projectGoal: ProjectGoal;
  projectStage: number;
  managementType: ManagementType;
  division: Division;
  testLocation: number;
  cancellationReason: string | null;
  reinstalled: boolean;
  updatedAt: Date;
  targets: [Target];
  projectCrops: [Crop];
  products: [Product];
  installments: [Installment];
  protocol: string | null;
  installForecast: Date | null;
  insideCode: string | null;
  notes: string | null;
  protocolDocumentId: number | null;
  retDocumentId: number | null;
  areaAssignmentDocumentId: number | null;
  reportReviewDocumentId: number | null;
  reportDocumentId: number | null;
  responsible: Employee | null;
  isEnabling: boolean;
  isReview: boolean;
  isExperimentation: boolean;
  isFinalStage: boolean;
  isComplete: boolean;
  isCanceled: boolean;
  totalValue: number;
  treatmentsNumber: number | null;
  trialsNumber: number | null;
  objective: string | null;
  applicationMethodology: string | null;
  evaluationMethodology: string | null;
  experimentalDesign: string | null;
  equipment: string | null;
  equipmentVolume: number | null;
  equipmentBar: number | null;
  nozzlesModel: string | null;
  plantSpacingLength: number | null;
  plantSpacingWidth: number | null;
  plantSpacingUnit: number | null;
  cropStage: string | null;
  blockLength: number | null;
  blockWidth: number | null;
  blockUnit: number | null;
  installDate: Date | null;
  probableLastEvaluationDate: Date | null;
  completionDate: Date | null;
  reportCompletionForecast: Date | null;
  secondRetDocumentId: number | null;
  thirdRetDocumentId: number | null;
  fourthRetDocumentId: number | null;
  customer: ProjectCustomer;
  representative: ProjectRepresentative;
};

type Crop = {
  id: number;
  name: string;
  insideCode: string;
};

type ProductIngredient = {
  id: number;
  ingredient: Ingredient;
  productId: number;
  concentration: number | null;
  measurementUnit: number | null;
};

type Formulation = {
  id: number;
  code: string;
  description: string;
  type: number;
};

type Product = {
  id: number;
  name: string;
  type: number;
  category: ProductCategory;
  ingredients: [ProductIngredient];
  formulation: Formulation;
  ret: string | null;
  retStage: number | null;
  retExpirationDate: Date | null;
  mapaRegister: string | null;
};

type ChemicalGroup = {
  id: number;
  description: string;
};

type ProductCategory = {
  id: number;
  description: string;
  insideCode: string;
};

type Ingredient = {
  id: number;
  name: string;
  categories: [ProductCategory];
  chemicalGroup?: ChemicalGroup;
};

type Target = {
  id: number;
  name: string;
  insideCode: string;
};

export type ProjectCustomer = {
  tradingName: string;
};

export type ProjectRepresentative = {
  name: string;
  firstName: string;
  lastName: string;
};

export const ProjectGoals = {
  EXPLORATORY: 1,
  OFFICIAL: 2,
};

export const ManagementTypes = {
  FIELD: 1,
  GREENHOUSE: 2,
  LABORATORY: 3,
};

export const Divisions = {
  BIOASSAYS: 1,
  BIOTEC: 2,
  COLLECTION: 3,
  FUNGICIDE: 7,
  HERBICIDE: 8,
  INSECTICIDE: 9,
  NEMATOLOGY: 12,
  SPECIALPROJECT: 13,
  RESIDUE: 14,
  FERTILIZER: 15,
  MIP_EXPERIENCE: 16,
  BREEDING: 17,
};

export const ProjectStage = {
  ENABLING: 1,
  PREPARATORY: 2,
  DEPLOYMENT: 3,
  EVALUATION: 4,
  HARVEST: 5,
  REPORT_WRITING: 6,
  CUSTOMER_REVIEW: 7,
  COMPLETED: 8,
  CANCELED: 9,
};

export const projectGoalDescriptions = {
  [ProjectGoals.EXPLORATORY]: 'Exploratório',
  [ProjectGoals.OFFICIAL]: 'Oficial',
};

export const managementTypeDescriptions = {
  [ManagementTypes.FIELD]: 'Campo',
  [ManagementTypes.GREENHOUSE]: 'Casa de vegetação',
  [ManagementTypes.LABORATORY]: 'Laboratório',
};

export const divisionDescriptions = {
  [Divisions.BIOASSAYS]: 'Bioensaio',
  [Divisions.BIOTEC]: 'Biotec',
  [Divisions.COLLECTION]: 'Coleta',
  [Divisions.FUNGICIDE]: 'Fungicida',
  [Divisions.HERBICIDE]: 'Herbicida',
  [Divisions.INSECTICIDE]: 'Inseticida',
  [Divisions.NEMATOLOGY]: 'Nematologia',
  [Divisions.SPECIALPROJECT]: 'Projeto especial',
  [Divisions.RESIDUE]: 'Resíduo',
  [Divisions.FERTILIZER]: 'Fertilizante',
  [Divisions.MIP_EXPERIENCE]: 'MIP Experience',
  [Divisions.BREEDING]: 'Criações',
};

export function isProjectCompleted(projectStage: number) {
  return projectStage === ProjectStage.COMPLETED;
}

export function getProjectGoalDescription(projectGoal: ProjectGoal) {
  return projectGoalDescriptions[projectGoal];
}

export function getManagementTypeDescription(managementType: ManagementType) {
  return managementTypeDescriptions[managementType];
}

export function getDivisionDescription(division: Division) {
  return divisionDescriptions[division];
}
