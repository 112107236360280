import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllRepresentativesUseCase from 'queries/getAllCustomerRepresentatives';

export default function RepresentativeSearch({
  customerId,
  name = 'ingredients',
  placeholder = 'Busque pelo nome...',
  value,
  onChange,
  disabled,
}) {
  const [selected, setSelected] = React.useState(value);
  const [{ data, loading, error }, getAllCustomerRepresentatives] = useAsync(
    getAllRepresentativesUseCase,
  );

  React.useEffect(() => {
    const filter = {
      page: 1,
      pageSize: 100,
      orderBy: 'name',
      sort: 'asc',
    };

    getAllCustomerRepresentatives(customerId, filter);
  }, [getAllCustomerRepresentatives, customerId]);

  const firstUpdate = React.useRef(true);

  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    function clearSelected() {
      setSelected(null);
    }

    clearSelected();
  }, [customerId]);

  const representatives = error || !data || !data.data ? [] : data.data;

  const options = representatives?.map((representative) => ({
    value: representative.id,
    label: representative.name,
  }));

  function handleChange(newValue) {
    setSelected(newValue);
    onChange(newValue);
  }

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder={placeholder}
      value={selected}
      isLoading={loading}
      onChange={handleChange}
      options={options}
      isDisabled={disabled}
    />
  );
}
