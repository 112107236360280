import React from 'react';

import { Grid, Header, Icon, Button } from 'semantic-ui-react';

import Info from 'components/data/Info';
import Section from 'components/layout/Section';

export default function ProjectCropDetails({
  plantingDate,
  seedlingEmergenceDate,
  plantsNumber,
  cropStage,
  bbchScale,
  plantSpacing,
  onEditClick,
}) {
  return (
    <Section>
      <Section.Header>
        <Header as="h3" style={{ margin: 0 }}>
          Cultura
        </Header>
        <Button size="small" type="button" onClick={onEditClick} basic>
          <Icon name="edit" />
          Editar
        </Button>
      </Section.Header>
      <Section.Content>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Info>
                <Info.Label>Data do plantio</Info.Label>
                <Info.Value>{plantingDate}</Info.Value>
              </Info>
            </Grid.Column>
            <Grid.Column>
              <Info>
                <Info.Label>Espaçamento</Info.Label>
                <Info.Value>{plantSpacing}</Info.Value>
              </Info>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Info>
                <Info.Label>Nº plantas</Info.Label>
                <Info.Value>{plantsNumber}</Info.Value>
              </Info>
            </Grid.Column>
            <Grid.Column>
              <Info>
                <Info.Label>Emergência</Info.Label>
                <Info.Value>{seedlingEmergenceDate}</Info.Value>
              </Info>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Info>
                <Info.Label>Estágio</Info.Label>
                <Info.Value>{cropStage || 'n/d'} </Info.Value>
              </Info>
            </Grid.Column>
            <Grid.Column>
              <Info>
                <Info.Label>Escala BBCH</Info.Label>
                <Info.Value>{bbchScale || 'n/d'}</Info.Value>
              </Info>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Section.Content>
    </Section>
  );
}
