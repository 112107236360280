/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './styles.css';

const RadioInput = React.forwardRef(
  ({ name, label, value, defaultChecked = false, disabled, ...props }, ref) => {
    return (
      <li className="segmented-control__item">
        <input
          className="segmented-control__input"
          type="radio"
          name={name}
          value={value}
          id={name + value}
          ref={ref}
          defaultChecked={defaultChecked}
          disabled={disabled}
          {...props}
        />
        <label
          className={
            !disabled
              ? 'segmented-control__label'
              : 'segmented-control__label__disabled'
          }
          htmlFor={name + value}
        >
          {label}
        </label>
      </li>
    );
  },
);

export default RadioInput;
