import React from 'react';

import { Controller } from 'react-hook-form';
import { Form, Input } from 'semantic-ui-react';

import ContractTypeSelect from 'components/inputs/ContractTypeSelect';
import CustomerSearch from 'components/inputs/CustomerSearch';
import { useContractFilter } from 'templates/Contracts/use-contract-filter';
import debounce from 'util/debounce';

import ContractStatusSelect from '../ContractStatusSelect';

export default function ContractsFilter({ control }) {
  const { updateFilter } = useContractFilter();

  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="number"
            defaultValue=""
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  debouncedFilter({ number: data.value });
                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Número"
                autoComplete="new-password"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="harvest"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={(e, data) => {
                  if (data.value.length === 4) {
                    debouncedFilter({ harvest: data.value });
                  } else {
                    debouncedFilter({ harvest: data.value });
                  }

                  onChange(e, data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Safra"
                maxLength={4}
                autoComplete="off"
                fluid
              />
            )}
          />
        </Form.Field>
        <Form.Field width={4}>
          <Controller
            control={control}
            name="customers"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                onChange={(data) => {
                  updateFilter({ customers: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Cliente"
                autoComplete="off"
                isClearable
                isMulti
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="types"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <ContractTypeSelect
                onChange={(data) => {
                  updateFilter({ types: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Tipo"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="status"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <ContractStatusSelect
                onChange={(data) => {
                  updateFilter({ status: data || '' });
                  onChange(data);
                }}
                onBlur={onBlur}
                value={value}
                placeholder="Status"
                autoComplete="off"
                isClearable
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
