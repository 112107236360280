type CommonTreatment = 1;

type Untreated = 2;

type UntreatedClean = 3;

type TreatmentType = CommonTreatment | Untreated | UntreatedClean;

export const TreatmentTypes = {
  COMMON: 1,
  UNTREATED: 2,
  UNTREATED_CLEAN: 3,
  UNTREATED_INOCULATED: 4,
  UNTREATED_NOT_INOCULATED: 5,
};

const treatmentTypeDescriptions = {
  [TreatmentTypes.COMMON]: 'Comum',
  [TreatmentTypes.UNTREATED]: 'Testemunha',
  [TreatmentTypes.UNTREATED_CLEAN]: 'T. capinada',
  [TreatmentTypes.UNTREATED_INOCULATED]: 'T. inoculada',
  [TreatmentTypes.UNTREATED_NOT_INOCULATED]: 'T. não-inoculada',
};

export function formatTreatmentType(type: number) {
  if (!treatmentTypeDescriptions[type]) {
    return '';
  }

  return treatmentTypeDescriptions[type];
}

export function isCommonTreatment(treatmentType: number) {
  return treatmentType === TreatmentTypes.COMMON;
}

export function isUntreated(treatmentType: number) {
  return treatmentType !== TreatmentTypes.COMMON;
}
