import React from 'react';

import toast from 'react-hot-toast';

import AlertModal from 'components/layout/AlertModal';
import { useDuplicateProject } from 'mutations/quotation';

export type QuotationProjectDuplicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  quotationId: number;
  quotationProjectId: number;
};

export default function QuotationProjectDuplicationModal({
  isOpen,
  onClose,
  quotationId,
  quotationProjectId,
}: QuotationProjectDuplicationModalProps) {
  const { mutate: duplicateProject, isLoading } = useDuplicateProject({
    onSuccess: () => {
      toast.success('Estudo duplicado com sucesso!');
      onClose();
    },
  });

  function handleSubmit() {
    duplicateProject({ quotationId, quotationProjectId });
  }

  return (
    <AlertModal
      header="Duplicar estudo"
      content="Tem certeza que deseja duplicar este estudo?"
      submitText="Duplicar"
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  );
}
