import { productRepository } from '../container';

export default async function getProducts({
  page,
  pageSize,
  orderBy,
  sort,
  name,
}) {
  const { data, pagination } = await productRepository.getProducts({
    page,
    pageSize,
    orderBy,
    sort,
    name,
  });

  return { data, pagination };
}
