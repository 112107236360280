import React, { useEffect } from 'react';

import { Controller } from 'react-hook-form';
import { Table, Input, Button, Icon } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import InputError from 'components/inputs/InputError';

import trialPatterns from './trialPatterns';

export default function RandomizationTable({
  project,
  treatments = [],
  onAddClick = undefined,
  isEditable = false,
  loading = false,
  form,
}) {
  const ALPHABET_START_CODE = 97;

  const trialsNumber = project?.trialsNumber;
  const treatmentsNumber = treatments.length;
  const trialPatternIndex = treatmentsNumber.toString();
  const isLaboratory = project.managementType === 3;
  const hasTrialPattern = !isLaboratory;

  useEffect(() => {
    if (form) {
      treatments.forEach((treatment, treatmentIndex) => {
        Array.from({ length: trialsNumber }).forEach((_, rowIndex) => {
          const blockKey = `treatments[${treatmentIndex}].trials[${rowIndex}].block`;
          const plotKey = `treatments[${treatmentIndex}].trials[${rowIndex}].plot`;
          const treatmentIdKey = `treatments[${treatmentIndex}].trials[${rowIndex}].treatmentId`;

          form.register({ name: blockKey });
          form.register({ name: plotKey });
          form.register({ name: treatmentIdKey });

          form.setValue(
            `treatments[${treatmentIndex}].trials[${rowIndex}].treatmentId`,
            treatment.id,
          );

          if (
            typeof trialPatterns[trialPatternIndex] !== 'undefined' &&
            hasTrialPattern
          ) {
            form.setValue(
              `treatments[${treatmentIndex}].trials[${rowIndex}].block`,
              formatToAlphabet(rowIndex),
            );
            form.setValue(
              `treatments[${treatmentIndex}].trials[${rowIndex}].plot`,
              trialPatterns[trialPatternIndex][treatmentIndex][rowIndex],
            );
          }
        });
      });
    }
  }, [treatments, form, trialsNumber, trialPatternIndex, hasTrialPattern]);

  function formatToAlphabet(number) {
    return String.fromCharCode(ALPHABET_START_CODE + number).toUpperCase();
  }

  function handleChange(name, label, value) {
    const nameWihoutPlot = name.substring(0, name.length - 4);

    const blockName = `${nameWihoutPlot}block`;

    form.setValue(blockName, label);

    return value;
  }

  if (!treatmentsNumber) {
    return (
      <EmptyState>
        <EmptyState.Header>Estudo sem tratamentos</EmptyState.Header>
        <EmptyState.Description>
          Adicione tratamentos para poder adicionar a randomização
        </EmptyState.Description>
      </EmptyState>
    );
  }

  const trials = treatments.map((treatment) => treatment.trials).flat(1);
  if (treatmentsNumber && !trials.length && !isEditable) {
    return (
      <EmptyState>
        <EmptyState.Header>Randomização não adicionada</EmptyState.Header>
        <EmptyState.Description>
          Clique no botão abaixo para adicionar a randomização
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button type="button" onClick={onAddClick} primary basic>
            <Icon name="add" />
            Adicionar
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    );
  }

  if (!form) {
    return (
      <Table size="small" basic="very" fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan={trialsNumber + 1}>
              Desenho experimental: Blocos Casualizados
            </Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={4}>
              Tratamento
            </Table.HeaderCell>
            {Array.from({ length: trialsNumber }, (_, columnIndex) => (
              <Table.HeaderCell textAlign="center" key={columnIndex}>
                {`Bloco ${formatToAlphabet(columnIndex)}`}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {treatments.map((treatment, treatmentIndex) => {
            return (
              <Table.Row key={treatmentIndex.toString()}>
                <Table.Cell textAlign="center">
                  {treatment.number || treatmentIndex + 1}
                </Table.Cell>
                {Array.from({ length: trialsNumber }, (_, columnIndex) => (
                  <Table.Cell textAlign="center" key={columnIndex}>
                    {treatment.trials?.length > 0
                      ? `${formatToAlphabet(columnIndex)}${treatment.trials[
                          columnIndex
                        ]?.plot || ''}`
                      : ''}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  const { control, errors } = form;

  return (
    <Table size="small" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan={trialsNumber + 1}>
            Desenho experimental: Blocos Casualizados
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width={4}>
            Tratamento
          </Table.HeaderCell>
          {Array.from({ length: trialsNumber }, (_, columnIndex) => (
            <Table.HeaderCell textAlign="center" key={columnIndex}>
              {`Bloco ${formatToAlphabet(columnIndex)}`}
            </Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={12} rows={1} />}
        {!loading &&
          treatments.map((treatment, treatmentIndex) => {
            return (
              <Table.Row key={treatmentIndex.toString()}>
                <Table.Cell textAlign="center">
                  {treatment.number || treatmentIndex + 1}
                </Table.Cell>
                {Array.from({ length: trialsNumber }, (_, columnIndex) => (
                  <Table.Cell textAlign="center" key={columnIndex}>
                    {isEditable && (
                      <>
                        <Controller
                          control={control}
                          name={`treatments[${treatmentIndex}].trials[${columnIndex}].plot`}
                          defaultValue=""
                          render={({ onChange, onBlur, value }) => (
                            <Input
                              onChange={(e, data) => {
                                handleChange(
                                  `treatments[${treatmentIndex}].trials[${columnIndex}].plot`,
                                  formatToAlphabet(columnIndex),
                                  data.value,
                                );
                                onChange(e, data);
                              }}
                              onBlur={onBlur}
                              value={value}
                              size="mini"
                              label={formatToAlphabet(columnIndex)}
                              autoComplete="new-password"
                              fluid
                            />
                          )}
                        />
                        {errors.treatments &&
                          errors.treatments[treatmentIndex] &&
                          errors.treatments[treatmentIndex].trials &&
                          errors.treatments[treatmentIndex].trials[
                            columnIndex
                          ] &&
                          errors.treatments[treatmentIndex].trials[columnIndex]
                            .plot && (
                            <InputError>
                              {
                                errors.treatments[treatmentIndex].trials[
                                  columnIndex
                                ].plot.message
                              }
                            </InputError>
                          )}
                      </>
                    )}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}
