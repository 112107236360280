import React from 'react';

import { Header, Button, Icon, Grid } from 'semantic-ui-react';
import styled from 'styled-components';

import Section from 'components/layout/Section';
import { ProjectQuery } from 'util/Project';
import { formatInitials } from 'util/unitFormatter';

export type ProjectProtocolDetailsProps = {
  project: ProjectQuery;
  treatmentsNumber: number;
  onEditClick: () => void;
};

export default function ProjectProtocolDetails({
  project,
  treatmentsNumber,
  onEditClick,
}: ProjectProtocolDetailsProps) {
  const { blockUnit, blockLength, blockWidth, trialsNumber } = project;

  const blockInitials = blockUnit ? formatInitials(blockUnit) : '';
  const blockSpacing =
    blockLength && blockWidth
      ? `${blockWidth} ${blockInitials} x ${blockLength} ${blockInitials} `
      : '';

  const blocksNumber =
    treatmentsNumber && trialsNumber ? treatmentsNumber * trialsNumber : 'n/d';

  return (
    <Section>
      <Section.Header>
        <Header as="h3" style={{ margin: 0 }}>
          Detalhes
        </Header>
        <Button
          type="button"
          size="small"
          floated="right"
          onClick={onEditClick}
          basic
        >
          <Icon name="edit" />
          Editar
        </Button>
      </Section.Header>
      <Section.Content>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <ItemWrapper>
                <Label>Tratamentos</Label>
                <Value>{treatmentsNumber || 'n/d'}</Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>Parcelas</Label>
                <Value>{blocksNumber || 'n/d'}</Value>
              </ItemWrapper>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <ItemWrapper>
                <Label>Repetições</Label>
                <Value>{trialsNumber || 'n/d'}</Value>
              </ItemWrapper>
            </Grid.Column>
            <Grid.Column>
              <ItemWrapper>
                <Label>Tamanho da parcela</Label>
                <Value>{blockSpacing || 'n/d'}</Value>
              </ItemWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Section.Content>
    </Section>
  );
}

const ItemWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const Label = styled.div`
  display: flex;
  flex: 1;
  color: #767676;
  font-size: 14px;
`;

const Value = styled.div`
  display: flex;
  flex: 1;
  font-size: 14px;
`;
