import React from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Grid, Tab } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import { useAuth } from 'hooks/auth';
import Contracts from 'templates/Contracts';
import Quotations from 'templates/Quotations';
import SaleProducts from 'templates/SaleProducts';

import FinancesPage from './financeiro';
import ServicesPage from './servicos';

const paneStyle = { paddingTop: '20px' };

export default function AdministrationPage() {
  const { user } = useAuth();
  const { activeIndex, toggle } = useAdministrationTabs();

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Tab
                renderActiveOnly
                menu={{ color: 'blue', secondary: true, pointing: true }}
                activeIndex={activeIndex}
                onTabChange={(_, data) => {
                  toggle(data.activeIndex as number);
                }}
                panes={[
                  {
                    menuItem: 'Orçamentos',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <Quotations />
                      </Tab.Pane>
                    ),
                  },
                  user?.isAdmin
                    ? {
                        menuItem: 'Contratos',
                        render: () => (
                          <Tab.Pane as="div" style={paneStyle} attached="false">
                            <Contracts />
                          </Tab.Pane>
                        ),
                      }
                    : {},
                  user?.isAdmin
                    ? {
                        menuItem: 'Financeiro',
                        render: () => (
                          <Tab.Pane as="div" style={paneStyle} attached="false">
                            <FinancesPage />
                          </Tab.Pane>
                        ),
                      }
                    : {},
                  {
                    menuItem: 'Pragas',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <SaleProducts />
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Serviços',
                    render: () => (
                      <Tab.Pane as="div" style={paneStyle} attached="false">
                        <ServicesPage />
                      </Tab.Pane>
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}

const tabStrategy: { [key: string]: number } = {
  orcamentos: 0,
  contratos: 1,
  financeiro: 2,
  clientes: 3,
  pragas: 4,
  servicos: 5,
} as const;

const tabPathStrategy: { [key: number]: string } = {
  0: 'administracao',
  1: 'administracao/contratos',
  2: 'administracao/financeiro',
  3: 'administracao/clientes',
  4: 'administracao/pragas',
  5: 'administracao/servicos',
} as const;

export function useAdministrationTabs() {
  const { active_tab: activeTab } = useParams<
    Record<string, string | undefined>
  >();
  const history = useHistory();

  const toggle = (tabIndex: number) => {
    if (activeTab !== tabPathStrategy[tabIndex]) {
      history.push(
        `/${tabIndex ? tabPathStrategy[tabIndex] : tabPathStrategy[0]}`,
      );
    }
  };

  const activeIndex = activeTab ? tabStrategy[activeTab] : 0;

  return { toggle, activeIndex };
}
