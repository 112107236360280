import React from 'react';

import { DateTime } from 'luxon';
import { Icon, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { ActivitiesQuery } from 'queries/activity';

type ActivityTableProps = {
  activities: ActivitiesQuery[];
  isLoading?: boolean;
  onDeleteClick: (activityId: number) => void;
  onEditClick: (activityId: number) => void;
  onPerformClick: (activityId: number) => void;
};

const Container = styled.div`
  margin-top: 16px;
`;

export default function ActivityTable({
  activities = [],
  isLoading = false,
  onDeleteClick,
  onEditClick,
  onPerformClick,
}: ActivityTableProps) {
  return (
    <Container>
      <Section>
        <Section.Header>
          <Text variant="primary">
            {`Atividades ${activities.length} de ${activities.length}`}
          </Text>
        </Section.Header>
        <Section.Content>
          <Table size="small" basic="very" fixed singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Data prevista</Table.HeaderCell>
                <Table.HeaderCell width={3}>Tipo</Table.HeaderCell>
                <Table.HeaderCell width={3}>Descrição</Table.HeaderCell>
                <Table.HeaderCell>Data execução</Table.HeaderCell>
                <Table.HeaderCell>Escala BBCH</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell textAlign="center" />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && <LoadingRow columns={8} rows={5} />}
              {!isLoading &&
                activities.map((activity) => {
                  return (
                    <Table.Row key={activity.id.toString()}>
                      <Table.Cell>
                        <Text variant="primary">
                          {DateTime.fromISO(activity.date).toFormat(
                            'dd/MM/yyyy',
                          )}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          <Tag color="grey">{activity.type}</Tag>
                        </Text>
                      </Table.Cell>
                      <Table.Cell title={activity.description}>
                        <Text variant="primary">
                          {activity.description || '-'}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        {activity.executionDate
                          ? DateTime.fromISO(activity.executionDate).toFormat(
                              'dd/MM/yyyy',
                            )
                          : ''}
                      </Table.Cell>
                      <Table.Cell>{activity.bbchScale || ''}</Table.Cell>
                      <Table.Cell title={activity.status.description}>
                        <Tag color={activity.status.color}>
                          {activity.status.description}
                        </Tag>
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {!activity.executionDate ? (
                          <LinkButton
                            onClick={() => onPerformClick(activity.id)}
                          >
                            Executar
                          </LinkButton>
                        ) : null}
                        <LinkButton onClick={() => onEditClick(activity.id)}>
                          <Icon name="edit" />
                        </LinkButton>
                        <LinkButton onClick={() => onDeleteClick(activity.id)}>
                          <Icon name="trash" />
                        </LinkButton>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Section.Content>
      </Section>
    </Container>
  );
}
