import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import api from 'util/api';

type CreatePaymentInput = {
  amount: number;
  billingDate: Date;
  invoiceNumber: string;
  installmentIds: number[];
  invoiceDocumentId: number | null;
};

export function useCreatePayment(
  options?: UseMutationOptions<number, AxiosError, CreatePaymentInput>,
) {
  const queryClient = useQueryClient();
  return useMutation(createPayment, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('contract');
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('payment');
      queryClient.invalidateQueries('payments');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createPayment(data: CreatePaymentInput) {
  const response = await api.post('/payments', data);

  const { location } = response.headers;
  const paymentId = parseInt(location.substring(13), 10);

  return paymentId;
}
