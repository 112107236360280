import { useState, useEffect } from 'react';

const useFormValidation = (initialState, validate, runOnSubmit) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState({});
  const [touched, setTouched] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      const noErrors = Object.keys(errors).length === 0;
      if (noErrors) {
        setTouched([]);
        runOnSubmit();
        setSubmitting(false);
      } else {
        setSubmitting(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    const validationErrors = validate(values);
    const touchedErrors = Object.keys(validationErrors)
      .filter((key) => touched.includes(key)) // get all touched keys
      .reduce((acc, key) => {
        if (!acc[key]) {
          acc[key] = validationErrors[key];
        }
        return acc;
      }, {});
    setErrors(touchedErrors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched, values]);

  const handleChange = (event, data) => {
    setValues({
      ...values,
      [data.name]: data.value,
    });
  };

  const handleDateChange = (value, name) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSearch = (event, data) => {
    setSearch({
      ...search,
      [data.name]: data.searchQuery,
    });
  };

  const handleBlur = (event) => {
    if (!touched.includes(event.target.name)) {
      setTouched([...touched, event.target.name]);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    setSubmitting(true);
  };

  const setValue = (value, name) => {
    setValues((oldValues) => ({ ...oldValues, [name]: value }));
  };

  return {
    handleSubmit,
    handleChange,
    handleDateChange,
    handleBlur,
    handleSearch,
    setValue,
    values,
    errors,
    search,
    isSubmitting,
  };
};

export default useFormValidation;
