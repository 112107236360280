import * as React from 'react';

import { Controller, useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import debounce from 'util/debounce';

import { useWeatherFilter } from '../use-weather-filter';

export default function WeatherFilter() {
  const { filter, updateFilter } = useWeatherFilter();
  const { control } = useForm({ defaultValues: filter });

  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="startDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onBlur={onBlur}
                onChange={(date: Date) => {
                  debouncedFilter({ startDate: date });
                  onChange(date);
                }}
                placeholderText="Data inicial"
                selected={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="endDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onBlur={onBlur}
                onChange={(date: Date) => {
                  debouncedFilter({ endDate: date });
                  onChange(date);
                }}
                placeholderText="Data final"
                selected={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={10} />
      </Form.Group>
    </Form>
  );
}
