export function formatCpfOrCnpj(inputValue: string) {
  if (inputValue.length > 3 && inputValue.length <= 6) {
    return inputValue.replace(/^(\d{3})(\d{3})/, '$1.$2');
  }

  if (inputValue.length > 6 && inputValue.length <= 9) {
    return inputValue.replace(/^(\d{3})(\d{3})(\d{3})/, '$1.$2.$3');
  }

  if (inputValue.length > 9 && inputValue.length <= 11) {
    return inputValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  if (inputValue.length > 11) {
    return inputValue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }

  return inputValue;
}

function formatBrazilianPhone(phone: string) {
  const hasTenDigits = phone.length === 10;

  const areaCode = phone.substring(0, 2);
  const firstPart = hasTenDigits
    ? phone.substring(2, 6)
    : phone.substring(2, 7);
  const secondPart = hasTenDigits ? phone.substring(6) : phone.substring(7);

  return `(${areaCode}) ${firstPart}-${secondPart}`;
}

export function formatPhone(phone: string, type: number) {
  switch (type) {
    case 1:
      return formatBrazilianPhone(phone);
    case 2:
      return phone;
    default:
      throw new TypeError('Formato de telefone não existente');
  }
}
