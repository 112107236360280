import React, { useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Modal,
  Button,
  Form,
  Message,
  Header,
  Dimmer,
  Loader,
  Input,
} from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useErrorHandler } from 'hooks/use-error-handler';
import {
  useCreateMaintenance,
  useUpdateMaintenance,
} from 'mutations/maintenance';
import { useMaintenance } from 'queries/maintenance';
import { emptyStringToUndefined } from 'util/validation';

export const CreateMaintenance = yup.object().shape({
  date: yup
    .date()
    .required(`Data é um campo obrigatório`)
    .transform(emptyStringToUndefined),
  product: yup
    .string()
    .required(`Produto é um campo obrigatório`)
    .transform(emptyStringToUndefined),
  ingredient: yup
    .string()
    .required(`Ingrediente é um campo obrigatório`)
    .transform(emptyStringToUndefined),
  dose: yup
    .number()
    .required(`Dose é um campo obrigatório`)
    .transform(emptyStringToUndefined),
  goal: yup
    .string()
    .required(`Finalidade é um campo obrigatório`)
    .transform(emptyStringToUndefined),
  windSpeed: yup
    .number()
    .optional()
    .transform(emptyStringToUndefined)
    .nullable(),
  humidity: yup
    .number()
    .optional()
    .transform(emptyStringToUndefined)
    .nullable(),
  temperature: yup
    .number()
    .optional()
    .transform(emptyStringToUndefined)
    .nullable(),
  precipitation: yup
    .number()
    .optional()
    .transform(emptyStringToUndefined)
    .nullable(),
});

const formId = 'maintenance_form';

export default function MaintenanceForm({
  maintenanceId,
  projectId,
  onClose,
  onSuccess,
  open,
}) {
  const formRef = useRef(null);
  const { handleError } = useErrorHandler();

  const resolver = yupResolver(CreateMaintenance);
  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { data: maintenance, isLoading: isLoadingMaintenance } = useMaintenance(
    maintenanceId,
  );

  const {
    mutate: createMaintenance,
    isLoading: isAdding,
    error: createError,
  } = useCreateMaintenance({
    onSuccess: () => {
      onSuccess('Manutenção adicionada com sucesso');
    },
  });

  const {
    mutate: updateMaintenance,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateMaintenance({
    onSuccess: () => {
      onSuccess('Manutenção atualizada com sucesso');
    },
  });

  React.useEffect(() => {
    if (maintenance) {
      setValue('date', DateTime.fromISO(maintenance.date).toJSDate());
      setValue('product', maintenance.product);
      setValue('ingredient', maintenance.ingredient);
      setValue('dose', maintenance.dose);
      setValue('goal', maintenance.goal);
      setValue('windSpeed', maintenance.windSpeed);
      setValue('humidity', maintenance.humidity);
      setValue('temperature', maintenance.temperature);
      setValue('precipitation', maintenance.precipitation);
    }
  }, [maintenance, setValue]);

  async function onSubmit(values) {
    const input = {
      ...values,
      windSpeed: values.windSpeed ? parseFloat(values.windSpeed) : null,
      humidity: values.humidity ? parseFloat(values.humidity) : null,
      temperature: values.temperature ? parseFloat(values.temperature) : null,
      precipitation: values.precipitation
        ? parseFloat(values.precipitation)
        : null,
      projectId,
    };

    if (maintenance) {
      updateMaintenance({ ...input, maintenanceId });
      return;
    }

    createMaintenance(input);
  }

  const isLoading = isAdding || isUpdating;
  const isEditing = !!maintenanceId;
  const header = isEditing ? 'Atualizar manutenção' : 'Nova manutenção';
  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {maintenanceId && isLoadingMaintenance ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Form.Field>
              <label>Data</label>
              <Controller
                control={control}
                name="date"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
              {errors.date && <InputError>{errors?.date?.message}</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Produto</label>
              <Controller
                control={control}
                name="product"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.product && (
                <InputError>{errors?.product?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Ingrediente</label>
              <Controller
                control={control}
                name="ingredient"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.ingredient && (
                <InputError>{errors?.ingredient?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Dose i/ha</label>
              <Controller
                control={control}
                name="dose"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="0,000"
                    decimalSeparator=","
                    thousandSeparator="."
                    isNumericString
                    decimalScale={3}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
              {errors.dose && <InputError>{errors?.dose?.message}</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Finalidade</label>
              <Controller
                control={control}
                name="goal"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.goal && <InputError>{errors?.goal?.message}</InputError>}
            </Form.Field>
            <Header as="h3">Opcionais</Header>
            <Form.Field>
              <label>Vento</label>
              <Controller
                control={control}
                name="windSpeed"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="0,00"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={3}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
            </Form.Field>
            <Form.Field>
              <label>Umidade inicial (%)</label>
              <Controller
                control={control}
                name="humidity"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="0,00"
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix="%"
                    decimalScale={3}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
            </Form.Field>
            <Form.Field>
              <label>Temperatura (ºC)</label>
              <Controller
                control={control}
                name="temperature"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="0,00"
                    decimalSeparator=","
                    thousandSeparator="."
                    suffix="ºC"
                    decimalScale={3}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
            </Form.Field>
            <Form.Field>
              <label>Precipitação</label>
              <Controller
                control={control}
                name="precipitation"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    onBlur={onBlur}
                    value={value}
                    placeholder="0,00"
                    decimalSeparator=","
                    thousandSeparator="."
                    decimalScale={3}
                    disabled={isLoading}
                    autoComplete="off"
                  />
                )}
              />
            </Form.Field>
          </Form>
        )}
        {createError ||
          (updateError && (
            <Message
              content={handleError(createError || updateError)}
              negative
            />
          ))}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form={formId} loading={isLoading} primary>
          {submitButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
