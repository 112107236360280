import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { projectKeys } from 'queries/project';
import api from 'util/api';

type CreateEvaluationInput = {
  projectId: number;
};

type UseCreateEvaluationOptions = UseMutationOptions<
  void,
  AxiosError,
  CreateEvaluationInput
>;

export function useCreateEvaluation(options: UseCreateEvaluationOptions) {
  const queryClient = useQueryClient();
  return useMutation(createEvaluation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('evaluations');
      queryClient.invalidateQueries('evaluation');
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export async function createEvaluation({
  projectId,
  ...input
}: CreateEvaluationInput) {
  await api.post(`/projects/${projectId}/evaluations`, input);
}

type UpdateEvaluationInput = CreateEvaluationInput & {
  evaluationId: number;
};

type UseUpdateEvaluationOptions = UseMutationOptions<
  void,
  AxiosError,
  UpdateEvaluationInput
>;

export function useUpdateEvaluation(options: UseUpdateEvaluationOptions) {
  const queryClient = useQueryClient();
  return useMutation(updateEvaluation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('evaluations');
      queryClient.invalidateQueries('evaluation');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export async function updateEvaluation({
  projectId,
  evaluationId,
  ...input
}: UpdateEvaluationInput) {
  await api.patch(`/projects/${projectId}/evaluations/${evaluationId}`, input);
}

type RemoveEvaluationInput = {
  projectId: number;
  evaluationId: number;
};

type UseRemoveEvaluationOptions = UseMutationOptions<
  void,
  AxiosError,
  RemoveEvaluationInput
>;

export function useRemoveEvaluation(options: UseRemoveEvaluationOptions) {
  const queryClient = useQueryClient();
  return useMutation(removeEvaluation, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('evaluations');
      queryClient.invalidateQueries('evaluation');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function removeEvaluation({
  projectId,
  evaluationId,
}: RemoveEvaluationInput) {
  await api.delete(`/projects/${projectId}/evaluations/${evaluationId}`);
}
