import * as React from 'react';

import toast from 'react-hot-toast';
import { Modal, Button, Form, Radio } from 'semantic-ui-react';

import { useUpdateContract } from 'data/contract';

export default function UpdateStatusModal({
  contractId,
  currentStatus,
  isOpen,
  onClose,
}) {
  const [contractStatus, setContractStage] = React.useState(currentStatus);

  const { mutate: updateContract, isLoading } = useUpdateContract({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Status atualizado!');
    onClose();
  }

  function handleChange(e, { value }) {
    setContractStage(value);
  }

  function submit() {
    updateContract({ contractId, contractStatus });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Atualizar status</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <Radio
              name="contractStatus"
              label="Habilitação"
              value={3}
              onChange={handleChange}
              checked={contractStatus === 3}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              name="contractStatus"
              label="Em andamento"
              value={1}
              onChange={handleChange}
              checked={contractStatus === 1}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              name="contractStatus"
              label="Concluído"
              value={2}
              onChange={handleChange}
              checked={contractStatus === 2}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={() => submit()} loading={isLoading} primary>
          Atualizar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
