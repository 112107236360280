import { AxiosError } from 'axios';

type Response = {
  status?: number;
  message?: string;
};

const NETWORK_ERROR = 'Network Error';

const GENERIC_MSG_ERROR = 'Ops! Alguma coisa não funcionou direito';
const MESSAGE_NETWORK_ERROR = 'Erro de conexão. Tente novamente.';

export function handleError(error: Error) {
  if (isInternalError(error)) {
    throw error;
  }

  if (error.message === NETWORK_ERROR) {
    return MESSAGE_NETWORK_ERROR;
  }

  if (isAxiosError<Response>(error)) {
    return error.response?.data?.message || GENERIC_MSG_ERROR;
  }

  if (isAxiosError<Response>(error)) {
    return error.response?.data?.message || GENERIC_MSG_ERROR;
  }

  return GENERIC_MSG_ERROR;
}

function isAxiosError<T>(error: Error): error is AxiosError<T> {
  return (error as AxiosError).isAxiosError !== undefined;
}

function isInternalError(error: Error) {
  if (
    error instanceof TypeError ||
    error instanceof EvalError ||
    error instanceof RangeError ||
    error instanceof ReferenceError ||
    error instanceof SyntaxError
  ) {
    return true;
  }

  return false;
}
