import React from 'react';

import Select from 'react-select';

export default function StateSelect({
  name = undefined,
  value = undefined,
  onChange,
  onBlur,
  disabled = false,
  isClearable = false,
  autoComplete = undefined,
}) {
  const states = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  const options = states?.map((state) => ({
    value: state.toLowerCase(),
    label: state,
  }));

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder="Selecione..."
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete={autoComplete}
    />
  );
}
