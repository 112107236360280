import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import {
  Modal,
  Button,
  Form,
  Message,
  Input,
  Loader,
  Dimmer,
  Icon,
  Grid,
} from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import UploadInputSmart from 'components/inputs/DocumentInput';
import InputError from 'components/inputs/InputError';
import { useEvaluationWithDocument } from 'queries/evaluation';
import { parseIso } from 'util/DateFormatter';

const DOCUMENT_TYPE_EVALUATION = 13;
const FORM_ID = 'EVALUATION_FORM';

const INITIAL_VALUES = {
  date: '',
  description: '',
};

export default function AddEvaluationModal({
  projectId,
  evaluationId,
  error,
  onClose,
  onSubmit,
  open,
  isSubmiting = false,
}) {
  const [isMounted, setMounted] = React.useState(false);
  const [documentFiles, setDocumentFiles] = React.useState([]);

  const isEditing = !!evaluationId;
  const header = isEditing ? 'Atualizar avaliação' : 'Nova avaliação';
  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  const formRef = React.useRef(null);

  const defaultValues = INITIAL_VALUES;

  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
  });

  const {
    data: evaluationData,
    isLoading,
    isFetching,
  } = useEvaluationWithDocument({
    projectId,
    evaluationId,
  });

  React.useEffect(() => {
    if (!isMounted && evaluationData && !isFetching) {
      setValue('date', parseIso(evaluationData?.evaluation?.date));
      setValue('description', evaluationData?.evaluation?.description);
      setDocumentFiles(evaluationData?.documentFiles);
      setMounted(true);
    }
  }, [evaluationData, isFetching, isMounted, setValue]);

  function handleClose() {
    onClose();
  }

  function handleAddDocumentClick() {
    setDocumentFiles((oldValues) => [...oldValues, null]);
  }

  function handleRemoveDocumentItemClick(index) {
    const updatedDocuments = documentFiles.filter((_, i) => i !== index);

    setDocumentFiles(updatedDocuments);
  }

  function handleUploadSuccess(uploadedFile, index) {
    const updatedDocuments = documentFiles.map((document, i) => {
      if (i === index) {
        return uploadedFile;
      }

      return document;
    });

    setDocumentFiles(updatedDocuments);
  }

  function handleUploadFailed(failedFile, index) {
    const updatedDocuments = documentFiles.map((document, i) => {
      if (i === index) {
        return failedFile;
      }

      return document;
    });

    setDocumentFiles(updatedDocuments);
  }

  async function handleDeleteButton(index) {
    const updatedDocuments = documentFiles.map((document, i) => {
      if (i === index) {
        return null;
      }

      return document;
    });

    setDocumentFiles(updatedDocuments);
  }

  function isDocumentNotValid(document) {
    return !document || document.error;
  }

  const disabledButton = documentFiles.some(isDocumentNotValid);

  return (
    <Modal size="tiny" open={open} onClose={handleClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {(!isMounted && isFetching && !evaluationData) || isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form
            id={FORM_ID}
            ref={formRef}
            onSubmit={handleSubmit((values) =>
              onSubmit({
                ...values,
                documentIds: documentFiles.map(({ id }) => id),
              }),
            )}
          >
            <Form.Field>
              <label>Data</label>
              <Controller
                control={control}
                name="date"
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    disabled={isSubmiting}
                  />
                )}
              />
              {errors.date && <InputError>Data é obrigatória</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Descrição</label>
              <Controller
                control={control}
                name="description"
                rules={{ required: true }}
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isSubmiting}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.description && (
                <InputError>Descrição é obrigatório</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Anexos</label>
            </Form.Field>
            <Grid>
              {documentFiles.map((document, index) => (
                <React.Fragment key={index.toString()}>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <UploadInputSmart
                        name={`document${index}`}
                        defaultFile={document}
                        documentType={DOCUMENT_TYPE_EVALUATION}
                        onUploadSuccess={(res) =>
                          handleUploadSuccess(res, index)
                        }
                        onUploadFailed={(failedFile) =>
                          handleUploadFailed(failedFile, index)
                        }
                        onDelete={() => handleDeleteButton(index)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={2} verticalAlign="middle">
                    <Button
                      type="button"
                      onClick={() => handleRemoveDocumentItemClick(index)}
                      basic
                      icon
                    >
                      <Icon name="trash" />
                    </Button>
                  </Grid.Column>
                </React.Fragment>
              ))}
              <Grid.Column width={16}>
                {documentFiles.length < 5 ? (
                  <Button
                    type="button"
                    onClick={handleAddDocumentClick}
                    fluid
                    basic
                  >
                    <Icon name="add" />
                    Novo anexo
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid>
          </Form>
        )}
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          loading={isSubmiting}
          disabled={disabledButton}
          primary
        >
          {submitButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
