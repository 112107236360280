import React from 'react';

import toast from 'react-hot-toast';
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';

import AlertModal from 'components/layout/AlertModal';
import {
  useDeleteFederalReport,
  FederalReportQuery,
  useInfiniteFederalReports,
} from 'data/federal-report';
import { downloadDocument } from 'hooks/document';
import { useModal, useModalWithData } from 'hooks/useModal';

import FederalReportModal from './FederalReportModal';
import FederalReportTable from './FederalReportTable';

export default function FederalReports() {
  const addModal = useModal();
  const deleteModal = useModalWithData<FederalReportQuery>();

  const {
    pagination,
    federalReports,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProjects,
  } = useInfiniteFederalReports({ pageSize: 10 });

  const {
    mutate: deleteFederalReport,
    isLoading: isDeleting,
  } = useDeleteFederalReport({
    onSuccess: () => {
      toast.success('Informe excluído!');
      deleteModal.close();
    },
  });

  function handleDownloadClick(documentId: number) {
    downloadDocument(documentId);
  }

  function handleDeleteClick(federalReportId: number) {
    const federalReport = federalReports.find(
      ({ id }) => id === federalReportId,
    );
    if (federalReport) {
      deleteModal.open(federalReport);
    }
  }

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h1">MAPA</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Button primary onClick={addModal.open}>
              <Icon name="add" />
              Novo informe
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <FederalReportTable
              federalReports={federalReports}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoadingProjects}
              onDeleteClick={handleDeleteClick}
              onDownloadClick={handleDownloadClick}
              pagination={pagination}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {addModal.isOpen ? (
        <FederalReportModal isOpen={addModal.isOpen} onClose={addModal.close} />
      ) : null}

      {deleteModal.isOpen && deleteModal.data ? (
        <AlertModal
          header="Excluir informe"
          content={`Tem certeza que deseja excluir o informe "${deleteModal?.data?.year} - ${deleteModal?.data?.monthDescription} - ${deleteModal?.data?.location}"?`}
          submitText="Excluir"
          negative
          isOpen={deleteModal.isOpen}
          isLoading={isDeleting}
          onClose={deleteModal.close}
          onSubmit={() => deleteFederalReport(deleteModal?.data?.id)}
        />
      ) : null}
    </>
  );
}
