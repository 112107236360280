import * as React from 'react';

import styled from 'styled-components';

const Container = styled.div``;

const Label = styled.small`
  margin-bottom: 0;
  font-size: 14px;
  color: hsl(214, 7%, 47%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Text = styled.p`
  font-size: 18px;
  color: ${({ onClick }) => (onClick ? '#4a9add' : '#393939')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type TextInfoProps = {
  label: string;
  text: string | number | React.ReactNode;
  onClick?: () => void;
};

export default function TextInfo({ label, text, onClick }: TextInfoProps) {
  return (
    <Container>
      <Label>{label}</Label>
      {onClick && <Text onClick={onClick}>{text}</Text>}
      {!onClick && <Text onClick={onClick}>{text}</Text>}
    </Container>
  );
}
