/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Divider, Form, Input, Modal } from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useSendShipment } from 'data/shipment';
import { useUpload } from 'hooks/use-upload';
import { DOCUMENT_INVOICE } from 'util/Document';

export type ShipmentFormValues = {
  shippingDate: Date;
  address: string;
};

export type ShipmentModalProps = {
  formValues?: ShipmentFormValues;
  isOpen: boolean;
  onClose: () => void;
  shipmentId: number;
};

type FileEvent = React.ChangeEvent<HTMLInputElement>;

const formId = 'form_shipping';

const INITIAL_STATE = { shippingDate: null };

export default function ShipmentModal({
  formValues,
  isOpen,
  onClose,
  shipmentId,
}: ShipmentModalProps) {
  const defaultValues = formValues || INITIAL_STATE;
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
  } as const;
  const invoiceFile = useUpload();
  const { control, errors, handleSubmit } = useForm(formOptions);

  const sendShipment = useSendShipment({
    onSuccess: () => handleSuccess('Remessa enviada!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function handleFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    invoiceFile.handleUpload(file, documentType);
  }

  function onSubmit(values: ShipmentFormValues) {
    if (!invoiceFile.file?.url || typeof invoiceFile.file?.id === 'string') {
      return;
    }

    const input = {
      address: values.address,
      invoiceDocumentId: invoiceFile.file.id,
      shippingDate: values.shippingDate,
      shipmentId,
    };

    sendShipment.mutate(input);
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Envio de remessa</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field width={6}>
            <label>Data de envio</label>
            <Controller
              control={control}
              name="shippingDate"
              defaultValue=""
              rules={{ required: 'Obrigatório' }}
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
            {errors.shippingDate && (
              <InputError>{errors?.shippingDate?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Endereço</label>
            <Controller
              control={control}
              name="address"
              defaultValue=""
              rules={{ required: 'Obrigatório' }}
              render={({ onChange, onBlur, value }) => (
                <Input
                  autoComplete="off"
                  disabled={sendShipment.isLoading}
                  fluid
                  onBlur={onBlur}
                  onChange={onChange}
                  value={value}
                />
              )}
            />
            {errors.address && (
              <InputError>{errors?.address?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Nota fiscal</label>
            <UploadInput
              name="document"
              uploadedFile={invoiceFile?.file}
              onUpload={(e: FileEvent) => handleFileChange(e, DOCUMENT_INVOICE)}
              onDelete={invoiceFile.handleDelete}
            />
          </Form.Field>
        </Form>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={sendShipment.isLoading}
          form={formId}
          loading={sendShipment.isLoading}
          primary
          type="submit"
        >
          Enviar remessa
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
