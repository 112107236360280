import * as React from 'react';

import { Divider, Grid, Header } from 'semantic-ui-react';

import FilteredDocumentTable from 'components/data/FilteredDocumentTable';
import { useDocumentFilter } from 'components/data/FilteredDocumentTable/use-document-filter';
import { useInfiniteDocuments } from 'data/document';
import { downloadDocument } from 'hooks/document';

export default function DocumentsPage() {
  const { getMappedFilter } = useDocumentFilter();

  const {
    documents,
    pagination,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteDocuments(getMappedFilter());

  function handleDownloadClick(documentId: number) {
    downloadDocument(documentId);
  }

  return (
    <Grid>
      <Grid.Row columns="equal" verticalAlign="middle">
        <Grid.Column>
          <Header as="h1">Documentos</Header>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row columns="equal">
        <Grid.Column>
          <FilteredDocumentTable
            documents={documents}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            onDownloadClick={handleDownloadClick}
            pagination={pagination}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
