import React from 'react';

import styled from 'styled-components';

type InfoListProps = {
  children: React.ReactNode;
};

function InfoList({ children }: InfoListProps) {
  return <Container>{children}</Container>;
}

type InfoItemProps = {
  label: string;
  value: number | string;
  bold?: boolean;
  onClick?: () => void;
};

function InfoItem({
  label,
  value,
  bold = false,
  onClick = undefined,
}: InfoItemProps) {
  return (
    <ItemContainer>
      <Label>{label}</Label>
      {!onClick ? (
        <Value bold={bold}>
          <p>{value}</p>
        </Value>
      ) : (
        <LinkContainer>
          <Button type="button" onClick={onClick}>
            {value}
          </Button>
        </LinkContainer>
      )}
    </ItemContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 8px 0;
  word-break: break-all;
`;

const ItemContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  padding: 8px 0;
`;

const Label = styled.div`
  display: flex;
  flex: 1;
  color: #767676;
  font-size: 14px;
`;

const Value = styled.div<{ bold?: boolean }>`
  display: flex;
  flex: 1;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const LinkContainer = styled.div`
  display: flex;
  flex: 1;
`;

const Button = styled.button<{ bold?: boolean }>`
  padding: 0;
  background: transparent;
  border: none;
  text-align: left;
  outline: none;
  font-size: 14px;
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: #4a9add;
  cursor: pointer;
`;

InfoList.Item = InfoItem;

export default InfoList;
