import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {
  Modal,
  Button,
  Form,
  Message,
  Input,
  Grid,
  Header,
  Divider,
} from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelect';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';

export const Application = yup.object().shape({
  date: yup.date().required('Obrigatório'),
  startTime: yup.date().required('Obrigatório'),
  endTime: yup.date().required('Obrigatório'),
  startTemperature: yup.number().required('Obrigatório'),
  endTemperature: yup.number().required('Obrigatório'),
  startHumidity: yup.number().required('Obrigatório'),
  endHumidity: yup.number().required('Obrigatório'),
  bbchScale: yup.string().required('Obrigatório'),
  windSpeed: yup.number().required('Obrigatório'),
  sky: yup.string().required('Obrigatório'),
  soil: yup.string().required('Obrigatório'),
  cropStage: yup.string().required('Obrigatório'),
  windSpeedUnit: yup.number().required('Obrigatório'),
});

const FORM_ID = 'APPLICATION_FORM';

type FormValues = {
  date: Date;
  startTime: number;
  endTime: number;
  startTemperature: number;
  endTemperature: number;
  startHumidity: number;
  endHumidity: number;
  windSpeed: number;
  bbchScale: string;
  sky: string;
  soil: string;
  cropStage: string;
  seedTreatment: 'true' | 'false';
  equipment: string;
  equipmentPressure: string;
  equipmentVolume: number;
  volumeUnit: {
    value: number;
    label: string;
  };
  windSpeedUnit: number;
};

export type ApplicationFormProps = {
  formValues: FormValues;
  error: string;
  onClose: () => void;
  onSubmit: (values: FormValues) => void;
  open: boolean;
  isSubmiting: boolean;
};

const UNIT_METERS_PER_SECOND = 11;
const UNIT_KILOMETERS_PER_HOUR = 26;

export default function ApplicationForm({
  formValues,
  error,
  onClose,
  onSubmit,
  open,
  isSubmiting = false,
}: ApplicationFormProps) {
  const isEditing = !!formValues;
  const header = isEditing ? 'Atualizar aplicação' : 'Nova aplicação';
  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  const formRef = React.useRef(null);

  const resolver = yupResolver(Application);
  const { control, errors, register, handleSubmit, watch } = useForm<
    FormValues
  >({
    mode: 'onBlur',
    defaultValues: formValues,
    resolver,
  });
  const watchSeedTreatment = watch('seedTreatment');

  return (
    <Modal closeOnDimmerClick={false} open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Data e horário</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Data</label>
                    <Controller
                      control={control}
                      name="date"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isSubmiting}
                        />
                      )}
                    />
                    {errors.date && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field>
                    <label>Início</label>
                    <Controller
                      control={control}
                      name="startTime"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isSubmiting}
                          dateFormat="HH:mm"
                          placeholderText="hh:mm"
                          timeIntervals={15}
                          timeCaption="Hora"
                          showTimeSelect
                          showTimeSelectOnly
                        />
                      )}
                    />
                    {errors.startTime && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field>
                    <label>Fim</label>
                    <Controller
                      control={control}
                      name="endTime"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isSubmiting}
                          dateFormat="HH:mm"
                          placeholderText="hh:mm"
                          timeIntervals={15}
                          timeCaption="Hora"
                          showTimeSelect
                          showTimeSelectOnly
                        />
                      )}
                    />
                    {errors.endTime && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Dados meteorológicos</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Temperatura inícial</label>
                    <Controller
                      control={control}
                      name="startTemperature"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="0,00"
                          decimalSeparator=","
                          thousandSeparator="."
                          suffix="ºC"
                          isNumericString
                          decimalScale={1}
                          disabled={isSubmiting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.startTemperature && (
                      <InputError>Obrigatório</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Temperatura final</label>
                    <Controller
                      control={control}
                      name="endTemperature"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="0,00"
                          decimalSeparator=","
                          thousandSeparator="."
                          suffix="ºC"
                          isNumericString
                          decimalScale={1}
                          disabled={isSubmiting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.endTemperature && (
                      <InputError>Obrigatório</InputError>
                    )}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Umidade inicial</label>
                    <Controller
                      control={control}
                      name="startHumidity"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="0,00"
                          decimalSeparator=","
                          thousandSeparator="."
                          suffix="%"
                          isNumericString
                          decimalScale={1}
                          disabled={isSubmiting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.startHumidity && (
                      <InputError>Obrigatório</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Umidade final</label>
                    <Controller
                      control={control}
                      name="endHumidity"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="0,00"
                          decimalSeparator=","
                          thousandSeparator="."
                          suffix="%"
                          isNumericString
                          decimalScale={1}
                          disabled={isSubmiting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.endHumidity && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Céu</label>
                    <Controller
                      control={control}
                      name="sky"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmiting}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.sky && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field>
                    <label>Vento</label>
                    <Controller
                      control={control}
                      name="windSpeed"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <NumberFormat
                          onValueChange={(data) => {
                            onChange(data.floatValue);
                          }}
                          onBlur={onBlur}
                          value={value}
                          placeholder="0,00"
                          decimalSeparator=","
                          thousandSeparator="."
                          isNumericString
                          decimalScale={1}
                          disabled={isSubmiting}
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.windSpeed && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                <Form.Field>
                  <label>Unidade do vento</label>
                  <RadioGroup>
                    <RadioInput
                      name="windSpeedUnit"
                      label="Metros/segundo (m/s)"
                      value={UNIT_METERS_PER_SECOND}
                      ref={register}
                      defaultChecked
                    />
                    <RadioInput
                      name="windSpeedUnit"
                      label="Quilômetros/hora (km/h)"
                      value={UNIT_KILOMETERS_PER_HOUR}
                      ref={register}
                    />
                  </RadioGroup>
                  {errors.windSpeedUnit && <InputError>Obrigatório</InputError>}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Cultura</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Estágio</label>
                    <Controller
                      control={control}
                      name="cropStage"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmiting}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.cropStage && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field>
                    <label>Escala BBCH</label>
                    <Controller
                      control={control}
                      name="bbchScale"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmiting}
                          maxLength={50}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.bbchScale && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                  <Form.Field>
                    <label>Solo</label>
                    <Controller
                      control={control}
                      name="soil"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmiting}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.soil && <InputError>Obrigatório</InputError>}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Tratamento de semente</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field>
                  <label>Tratamento de semente</label>
                  <RadioGroup>
                    <RadioInput
                      name="seedTreatment"
                      label="Sim"
                      ref={register}
                      value="true"
                    />
                    <RadioInput
                      name="seedTreatment"
                      label="Não"
                      ref={register}
                      value="false"
                    />
                  </RadioGroup>
                  {errors.seedTreatment && <InputError>Obrigatório</InputError>}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            {watchSeedTreatment === 'false' ? (
              <>
                <Divider />
                <Grid.Row>
                  <Grid.Column width={6}>
                    <Header as="h3">Aplicação</Header>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Form.Field>
                      <label>Equipamento de pulverização</label>
                      <RadioGroup>
                        <RadioInput
                          name="equipment"
                          label="CO² Costal"
                          value="CO² Costal"
                          ref={register}
                          defaultChecked
                        />
                        <RadioInput
                          name="equipment"
                          label="Atomizador"
                          value="Atomizador"
                          ref={register}
                        />
                        <RadioInput
                          name="equipment"
                          label="Drone"
                          value="Drone"
                          ref={register}
                        />
                      </RadioGroup>
                      {errors.equipment && <InputError>Obrigatório</InputError>}
                    </Form.Field>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>Pressão</label>
                        <Controller
                          control={control}
                          name="equipmentPressure"
                          defaultValue=""
                          render={({ onChange, onBlur, value }) => (
                            <Input
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              disabled={isSubmiting}
                              autoComplete="off"
                              fluid
                            />
                          )}
                        />
                        {errors.equipmentPressure && (
                          <InputError>Obrigatório</InputError>
                        )}
                      </Form.Field>
                      <Form.Field>
                        <label>Volume Calda</label>
                        <Controller
                          control={control}
                          name="equipmentVolume"
                          defaultValue=""
                          render={({ onChange, onBlur, value }) => (
                            <NumberFormat
                              onValueChange={(data) => {
                                onChange(data.floatValue);
                              }}
                              onBlur={onBlur}
                              value={value}
                              placeholder="0,00"
                              decimalSeparator=","
                              thousandSeparator="."
                              isNumericString
                              decimalScale={3}
                              disabled={isSubmiting}
                              autoComplete="off"
                            />
                          )}
                        />
                        {errors.equipmentVolume && (
                          <InputError>Obrigatório</InputError>
                        )}
                      </Form.Field>
                      <Form.Field>
                        <label>Unidade</label>
                        <Controller
                          control={control}
                          name="volumeUnit"
                          defaultValue=""
                          render={({ onChange, onBlur, value }) => (
                            <MeasurementUnitSelect
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              onlyUnits={[12, 13, 14, 15]}
                              autoComplete="off"
                            />
                          )}
                        />
                        {errors.volumeUnit && (
                          <InputError>Obrigatório</InputError>
                        )}
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
              </>
            ) : null}
          </Grid>
        </Form>
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form={FORM_ID} loading={isSubmiting} primary>
          {submitButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
