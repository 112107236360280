/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { DateTime } from 'luxon';
import Select from 'react-select';
import styled, { css } from 'styled-components';

import { useSamples } from 'queries/sample';

export default function SampleSearch({
  name = undefined,
  placeholder = 'Busque pelo código ou produto...',
  value,
  onBlur = undefined,
  onChange,
  disabled = undefined,
  isMulti = undefined,
  isClearable = false,
  autoComplete = undefined,
  filter = {},
}) {
  const [searchValue, setSearchValue] = React.useState('');

  const { data, isLoading, error } = useSamples({
    ...filter,
    search: searchValue,
  });

  const products = error || !data || !data.samples ? [] : data.samples;

  const handleInputChange = (inputValue) => {
    setSearchValue(inputValue);
  };

  const defaultValue = !isMulti ? undefined : [];

  function formatLabel({ code, product }) {
    return `${code} - ${product?.name}`;
  }

  return (
    <Select
      autoComplete={autoComplete}
      components={{ Option: CustomOption }}
      defaultValue={defaultValue}
      getOptionLabel={formatLabel}
      getOptionValue={(option) => option.id}
      inputValue={searchValue}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={handleInputChange}
      options={products}
      placeholder={placeholder}
      value={value}
    />
  );
}

function CustomOption({ innerProps, isDisabled, data, ...props }) {
  const dateFormat = data?.hasExpirationDay ? 'dd/MM/yyyy' : 'MM/yyyy';

  return !isDisabled ? (
    <Wrapper {...innerProps} {...props}>
      <PrimaryText>{data.code}</PrimaryText>
      <SecondaryText {...props}>{`${data.product.name}, val. ${DateTime.fromISO(
        data.expirationDate,
      ).toFormat(dateFormat)}`}</SecondaryText>
    </Wrapper>
  ) : null;
}

const Wrapper = styled.div`
  ${({ isFocused, isSelected }) => css`
    padding: 1rem 1.4rem;
    background-color: ${isFocused ? '#deebff' : ''};
    background-color: ${isSelected ? '#2684FF' : ''};
    color: ${isSelected ? '#ffffff' : ''};
  `}
`;

const PrimaryText = styled.div`
  ${() => css`
    margin-bottom: 2px;
  `}
`;

const SecondaryText = styled.div`
  ${({ isSelected }) => css`
    font-size: 12px;
    color: ${isSelected ? '#ffffff' : '#767676'};
  `}
`;
