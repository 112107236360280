import React, { useRef, useState } from 'react';

import toast from 'react-hot-toast';
import { Modal, Button, Form } from 'semantic-ui-react';

import { useImportWeathers } from 'mutations/weather';

const FORM_ID = 'EVALUATION_FORM';

export default function ImportWeathersModal({ open, onClose }) {
  const [file, setFile] = useState(null);
  const formRef = useRef(null);

  const { mutate: importWeathers, isLoading } = useImportWeathers({
    onSuccess,
  });

  function onSuccess() {
    toast.success('Dados importados com sucesso!');
    onClose();
  }

  function handleChange(e) {
    const fileEnd = e.target.files ? e.target.files[0] : null;
    setFile(fileEnd);
  }

  function handleSubmit() {
    importWeathers({
      file: { file, filename: file.name },
    });
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Importação de dados</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} ref={formRef}>
          <Form.Field>
            <input type="file" onChange={handleChange} />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          type="button"
          onClick={() => handleSubmit()}
          loading={isLoading}
          primary
          disabled={!file}
        >
          Importar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
