import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

import { queryClient } from 'App';
import { projectKeys } from 'queries/project';
import api from 'util/api';

type ChangeResponsibleInput = {
  projectId: number;
  responsibleTechnicianId: number;
};

export function useChangeResponsible(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ChangeResponsibleInput,
    () => void
  >,
) {
  return useMutation(changeResponsible, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(projectKeys.all);
      queryClient.invalidateQueries(projectKeys.detail(variables.projectId));
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function changeResponsible({
  projectId,
  responsibleTechnicianId,
}: ChangeResponsibleInput) {
  await api.post(`/projects/${projectId}/change-responsible`, {
    responsibleTechnicianId,
  });
}
