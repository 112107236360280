/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import Text from 'components/foundation/Text';
import DatePicker from 'components/inputs/date-picker';
import { useChangeShippingForecast } from 'data/shipment';

export type ShippingForecastFormValues = {
  shippingForecast: Date | null;
};

export type ShippingForecastModalProps = {
  formValues?: ShippingForecastFormValues;
  isOpen: boolean;
  onClose: () => void;
  shipmentId: number;
};

const FORM_ID = 'form_shipping_forecast';

const ShippingForecastInput = yup.object({
  shippingForecast: yup
    .date()
    .optional()
    .nullable(),
});

export default function ShippingForecastModal({
  formValues,
  isOpen,
  onClose,
  shipmentId,
}: ShippingForecastModalProps) {
  const defaultValues = formValues;
  const resolver = yupResolver(ShippingForecastInput);
  const { control, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const changeShippingForecast = useChangeShippingForecast({
    onSuccess: () => handleSuccess('Previsão de expedição alterada!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ShippingForecastFormValues) {
    changeShippingForecast.mutate({
      shippingForecast: values.shippingForecast || null,
      shipmentId,
    });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alteração de previsão de expedição</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field width={6}>
            <label>Previsão de expedição</label>
            <Controller
              control={control}
              name="shippingForecast"
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  onBlur={onBlur}
                  onChange={onChange}
                  selected={value}
                />
              )}
            />
            <Text variant="secondary">Opcional</Text>
          </Form.Field>
        </Form>
        <Divider hidden />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={changeShippingForecast.isLoading}
          form={FORM_ID}
          loading={changeShippingForecast.isLoading}
          primary
          type="submit"
        >
          Alterar previsão
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
