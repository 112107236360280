const validate = (values) => {
  const errors = {};

  if (!values.harvest) {
    errors.harvest = 'Safra é obrigatória';
  }

  if (values.harvest && Number.isNaN(parseInt(values.harvest, 10))) {
    errors.harvest = 'Ano inválido';
  }

  if (values.harvest < 2000 || values.harvest > 2050) {
    errors.harvest = 'Ano inválido';
  }

  if (!values.projectGoal) {
    errors.projectGoal = 'Finalidade é obrigatória';
  }

  if (!values.division) {
    errors.division = 'Centro de custo é obrigatório';
  }

  if (!values.protocol) {
    errors.protocol = 'Protocolo é obrigatório';
  }

  if (!values.requiredDate) {
    errors.requiredDate = 'Data requerida é obrigatória';
  }

  return errors;
};

export default validate;
