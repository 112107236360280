import React from 'react';

import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import { MeasurementUnitQuery } from 'queries/measurementUnit';

export type MeasurementUnitTableProps = {
  items: MeasurementUnitQuery[];
  loading?: boolean;
};

export default function MeasurementUnitTable({
  items = [],
  loading = false,
}: MeasurementUnitTableProps) {
  return (
    <Table size="small" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Descrição</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Abreviatura</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={2} rows={5} />}
        {!loading &&
          items.map((item) => (
            <Table.Row key={item.id.toString()}>
              <Table.Cell textAlign="left">{item.description}</Table.Cell>
              <Table.Cell textAlign="left">{item.abbreviation}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}
