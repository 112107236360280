import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export const CustomerBasicInfo = yup
  .object()
  .shape({
    companyName: yup.string().required('Razão social é um campo obrigatório'),
    tradingName: yup.string().required('Nome fantasia é um campo obrigatório'),
    insideCode: yup
      .string()
      .optional()
      .length(3, 'Código interno deve conter 3 caracteres')
      .nullable()
      .transform(emptyStringToUndefined),
    cnpj: yup
      .string()
      .max(14, 'CNPJ deve conter no máximo 14 números')
      .optional()
      .nullable(),
    phone: yup
      .string()
      .min(10, 'Telefone deve conter no mínimo 10 números')
      .optional()
      .nullable()
      .transform(emptyStringToUndefined),
    stateRegistration: yup
      .string()
      .optional()
      .nullable()
      .transform(emptyStringToUndefined),
  })
  .required();

export const CustomerRepresentative = yup.object().shape({
  representatives: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          name: yup
            .string()
            .transform(emptyStringToUndefined)
            .required(`Obrigatório`),
          email: yup
            .string()
            .transform(emptyStringToUndefined)
            .required(`Obrigatório`),
          phone: yup
            .string()
            .required('Obrigatório')
            .min(10, 'Mínimo de 10 números'),
        })
        .required(),
    )
    .required()
    .min(1, 'É necessário pelo menos um representante'),
});
