import * as React from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Divider, Form, Grid, Header, Modal } from 'semantic-ui-react';

import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import Text from 'components/foundation/Text';
import { useDisableUser, useEnableUser, Users } from 'data/user';
import { ROLE_DESCRIPTION } from 'hooks/use-auth';

export type UserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: Users;
};

const formId = 'form_crop' as const;

const MESSAGE = {
  successDeactivate: 'Usuário desativado!',
  successActivate: 'Usuário ativado!',
} as const;

export default function UserModal({ isOpen, onClose, user }: UserModalProps) {
  const { handleSubmit } = useForm();

  const enableUser = useEnableUser({
    onSuccess: () => handleSuccess(MESSAGE.successActivate),
  });

  const disableUser = useDisableUser({
    onSuccess: () => handleSuccess(MESSAGE.successDeactivate),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit() {
    if (user.active === true) {
      disableUser.mutate(user.id);
      return;
    }

    enableUser.mutate(user.id);
  }

  const isLoading = enableUser.isLoading || disableUser.isLoading;
  const header =
    user.active === true ? 'Desabilitar usuário' : 'Habilitar usuário';
  const buttonText = user.active === true ? 'Desabilitar' : 'Habilitar';
  const description =
    user.active === true
      ? 'Após confirmar, o usuário não poderá mais acessar o sistema. Esta operação pode ser desfeita posteriormente.'
      : 'Após confirmar, o usuário poderá acessar o sistema novamente. Esta operação pode ser desfeita posteriormente.';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Header as="h3">Usuário</Header>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo label="Nome" text={user.employee.name} />
              </Grid.Column>
              <Grid.Column>
                <TextInfo label="Perfil" text={ROLE_DESCRIPTION[user.role]} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo
                  label="Setores"
                  text={
                    user.role === 'admin' ? (
                      <Tag color="green">Todos</Tag>
                    ) : (
                      <>
                        {user.employee.divisions.map((division) => (
                          <>
                            <Tag key={division.id} color="grey">
                              {division.description}
                            </Tag>
                            <span style={{ marginRight: 4 }} />
                          </>
                        ))}
                      </>
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <Text variant="secondary">{description}</Text>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
