import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllFormulationsUseCase from 'queries/getAllFormulations';

export default function FormulationSelect({
  name,
  placeholder = 'Selecione...',
  value,
  onChange,
  disabled,
}) {
  const [{ data, loading, error }, getAllFormulations] = useAsync(
    getAllFormulationsUseCase,
  );

  React.useEffect(() => {
    getAllFormulations({
      page: 1,
      pageSize: 200,
      orderBy: 'description',
      sort: 'asc',
    });
  }, [getAllFormulations]);

  const formulations =
    error || !data || !data.formulations ? [] : data.formulations;

  const options = formulations?.map((formulation) => ({
    value: formulation.id,
    label: `${formulation.description} (${formulation.code})`,
  }));

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      isLoading={loading}
      value={value}
      onChange={onChange}
      options={options}
      isDisabled={disabled}
    />
  );
}
