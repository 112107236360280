import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import { queryClient } from 'App';
import api from 'util/api';

export type IngredientInput = {
  name: string;
};

export function useCreateIngredient(
  options?: UseMutationOptions<void, AxiosError, IngredientInput, () => void>,
) {
  return useMutation(createIngredient, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('ingredients');
      queryClient.invalidateQueries('ingredient');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createIngredient(input: IngredientInput) {
  await api.post('active-ingredients', input);
}
