import * as React from 'react';

import styled, { css } from 'styled-components';

type StepItemProps = {
  description: string;
  done: boolean;
};

type ProjectStageProgressProps = {
  activeIndex: number;
  items: StepItemProps[];
};

function ProjectStageProgress({
  activeIndex,
  items,
}: ProjectStageProgressProps) {
  return (
    <Wrapper>
      <Stepper activeIndex={activeIndex} items={items} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    background: ${theme.colors.bg};
  `}
`;

type StepperProps = {
  items: StepItemProps[];
  activeIndex: number;
};

function Stepper({ activeIndex, items }: StepperProps) {
  return (
    <StepperWrapper>
      {items.map(({ done, description }, index) => (
        <Step
          key={description}
          active={activeIndex === index}
          done={done}
          description={description}
        />
      ))}
    </StepperWrapper>
  );
}

const StepperWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 5px;
  padding-bottom: 10px;
  overflow: hidden;
`;

type StepProps = {
  active: boolean;
  done: boolean;
  description: string;
};

function Step({ active, done, description }: StepProps) {
  return (
    <StepWrapper>
      <StepLabel active={active} done={done}>
        {description}
      </StepLabel>
      <StepBar active={active} done={done} />
    </StepWrapper>
  );
}

const StepWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 2px;
  &:last-child {
    margin-right: 0;
    border-radius: 6px;
  }
`;

type StepBarProps = {
  active: boolean;
  done?: boolean;
};

const stepBarModifiers = {
  active: () => css`
    background-color: #2581b6;
  `,
};

const StepBar = styled.div<StepBarProps>`
  ${({ active, done }) => css`
    margin-top: 4px;
    height: 5px;
    background-color: ${done ? '#2581b69d' : '#ddd'};

    ${active && stepBarModifiers.active()}
  `}
`;

const stepLabelModifiers = {
  active: () => css`
    color: #2581b6;
    font-weight: bold;
  `,
};

const StepLabel = styled.div<StepBarProps>`
  ${({ active, done }) => css`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: ${done ? '#2581b69d' : '#d7d7d7'};

    ${active && stepLabelModifiers.active()}
  `}
`;

export default ProjectStageProgress;
