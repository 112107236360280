import api from 'util/api';

import makeCustomerRepository from './customerRepository';
import makeProductRepository from './productRepository';
import makeProjectRepository from './projectRepository';
import makeQuotationRepository from './quotationRepository';

// Repository
export const projectRepository = makeProjectRepository({ api });
export const customerRepository = makeCustomerRepository({
  api,
});
export const quotationRepository = makeQuotationRepository();
export const productRepository = makeProductRepository({
  api,
});
