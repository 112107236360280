import * as React from 'react';

import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  PaginationProps,
} from 'semantic-ui-react';

import CropsTable from 'components/data/CropsTable';
import Pagination from 'components/data/Pagination';
import Section from 'components/layout/Section';
import CropModal from 'components/modal/CropModal';
import { useQueryCrops } from 'data/crop';
import { useModalWithData } from 'hooks/useModal';

type MouseEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;

export default function CropsPage() {
  const [cropsPage, setCropsPage] = React.useState(1);

  const cropModal = useModalWithData<number>();
  const cropsQuery = useQueryCrops({ page: cropsPage, pageSize: 10 });

  function handlePageChange(_: MouseEvent, { activePage }: PaginationProps) {
    setCropsPage(activePage as number);
  }

  function handleAddClick() {
    cropModal.open();
  }

  function handleEditClick(cropId: number) {
    cropModal.open(cropId);
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h1">Culturas</Header>
          </Grid.Column>
          <Grid.Column>
            <Button primary floated="right" onClick={handleAddClick}>
              <Icon name="add" />
              Nova cultura
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <CropsTable
                        crops={cropsQuery?.data?.crops}
                        isLoading={cropsQuery?.isLoading}
                        onEditClick={handleEditClick}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="right">
                    <Grid.Column>
                      <Pagination
                        pagination={cropsQuery?.data?.pagination}
                        onPageChange={handlePageChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {cropModal.isOpen ? (
        <CropModal
          cropId={cropModal.data || undefined}
          formValues={cropsQuery.data?.crops?.find(
            (crop) => crop.id === cropModal.data,
          )}
          isOpen={cropModal.isOpen}
          onClose={cropModal.close}
        />
      ) : null}
    </>
  );
}
