import React from 'react';

import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import ProductForm from 'components/forms/ProductForm';
import Base from 'components/layout/Base';
import Content from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import updateProduct from 'mutations/product';
import getProductUseCase from 'queries/getProduct';
import { formatRetStage } from 'queries/product';

export default function EditProduct() {
  const history = useHistory();
  const { productId } = useParams();

  const [{ data: product, loading: isProductLoading }, getProduct] = useAsync(
    getProductUseCase,
  );

  const {
    mutate: mutateUpdateProduct,
    isLoading: isUpdating,
  } = useMutation((params) => updateProduct(params, { onSuccess }));

  function onSuccess(message) {
    toast.success(message);
    history.goBack();
  }

  React.useEffect(() => {
    getProduct(productId);
  }, [getProduct, productId]);

  function handleSubmit(values) {
    const params = {
      ...values,
      productType: values.productType.value,
      productCategory: values.productCategory.value,
      formulationId: values.formulation ? values.formulation.value : null,
      ret: values.ret || null,
      retStage: values?.retStage?.value || null,
      mapaRegister: values.mapaRegister || null,
      id: parseInt(productId, 10),
    };

    mutateUpdateProduct(params);
  }

  return (
    <Base loading={isProductLoading} text>
      <Content>
        {product && (
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <Header as="h1">Edição de produto</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <ProductForm
                      formValues={mapToForm(product)}
                      isSubmiting={isUpdating}
                      onSubmit={handleSubmit}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Content>
    </Base>
  );
}

function mapToForm(product) {
  return {
    ...product,
    productType: {
      value: product.type,
      label: product.type === 1 ? 'Biológico' : 'Químico',
    },
    productCategory: {
      value: product.category.id,
      label: product.category.description,
    },
    formulation: product.formulation
      ? {
          value: product.formulation.id,
          label: `(${product.formulation.code}) ${product.formulation.description}`,
        }
      : undefined,
    retStage: product.retStage
      ? { value: product.retStage, label: formatRetStage(product.retStage) }
      : undefined,
    retExpirationDate: product.retExpirationDate
      ? new Date(product.retExpirationDate)
      : undefined,
  };
}
