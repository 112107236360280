import * as React from 'react';

import toast from 'react-hot-toast';
import { Divider, Grid, Header } from 'semantic-ui-react';

import { useModalWithData } from 'hooks/useModal';
import { useChangeDivisionResponsible } from 'mutations/division';
import { DivisionQuery, useDivisions } from 'queries/division';

import ChangeDivisionResponsibleModal, {
  ChangeDivisionResponsibleFormValues,
} from './ChangeDivisionResponsibleModal';
import { DivisionTable } from './DivisionTable';

export default function Divisions() {
  const modal = useModalWithData<DivisionQuery>();

  const { divisions, isLoading } = useDivisions();

  const {
    mutate: changeDivisionResponsbile,
    isLoading: isMutating,
  } = useChangeDivisionResponsible({
    onSuccess: handleSuccess,
  });

  function handleSuccess() {
    modal.close();
    toast.success('Responsável alterado!');
  }

  function handleChangeClick(divisionId: number) {
    const division = divisions.find(({ id }) => id === divisionId);
    modal.open(division);
  }

  function handleSubmit(values: ChangeDivisionResponsibleFormValues) {
    if (modal?.data?.id) {
      changeDivisionResponsbile({
        divisionId: modal.data.id,
        responsibleId: values.responsible.value,
      });
    }
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h1">Setores</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row columns="equal">
          <Grid.Column>
            <DivisionTable
              items={divisions}
              isLoading={isLoading}
              onChangeClick={handleChangeClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {modal.isOpen && modal?.data ? (
        <ChangeDivisionResponsibleModal
          isLoading={isMutating}
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={handleSubmit}
          defaultValues={{
            responsible: {
              value: modal?.data?.responsibleId,
              label: modal?.data?.responsible,
            },
          }}
        />
      ) : null}
    </>
  );
}
