import React, { useEffect, useRef, useState } from 'react';

import {
  Modal,
  Button,
  Message,
  Step,
  Divider,
  Header,
} from 'semantic-ui-react';

import CustomerAddressForm, {
  CustomerAddressFormValues,
} from './CustomerAddressForm';
import CustomerBasicInfoForm, {
  CustomerBasicInfoFormValues,
} from './CustomerBasicInfoForm';
import CustomerRepresentativesForm, {
  CustomerRepresentativesFormValues,
} from './CustomerRepresentativesForm';

export type CustomerFormValues = {
  companyName: string;
  tradingName: string;
  cnpj: string;
  phone: string;
  insideCode?: string;
  stateRegistration?: string;
  street?: string;
  streetNumber?: string;
  neighborhood?: string;
  zipcode?: string;
  complementary?: string;
  city?: string;
  state?: string;
  country?: string;
  representatives: {
    name: string;
    email: string;
    phone: string;
  }[];
};

export type CustomerFormModalProps = {
  error?: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSubmit: (values: CustomerFormValues) => void;
};

const defaultValues = {
  companyName: '',
  tradingName: '',
  cnpj: '',
  phone: '',
  insideCode: undefined,
  stateRegistration: undefined,
  street: undefined,
  streetNumber: undefined,
  neighborhood: undefined,
  zipcode: undefined,
  complementary: undefined,
  city: '',
  state: '',
  country: '',
  representatives: [{ name: '', email: '', phone: '' }],
};

export default function CustomerFormModal({
  error,
  isOpen,
  isSubmitting = false,
  onClose,
  onSubmit,
}: CustomerFormModalProps) {
  const [input, setInput] = useState<CustomerFormValues>(defaultValues);
  const [step, setStep] = useState(1);

  const basicInfoFormRef = useRef<HTMLFormElement>(null);
  const addressFormRef = useRef<HTMLFormElement>(null);
  const representativesFormRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setStep(1);
  }, [isOpen]);

  function updateInput(values: Partial<CustomerFormValues>) {
    setInput((oldValues) => ({ ...oldValues, ...values }));
  }

  function nextStep() {
    setStep(step + 1);
  }

  function prevStep() {
    setStep(step - 1);
  }

  function handleBasicInfoSubmit(values: CustomerBasicInfoFormValues) {
    updateInput(values);
    nextStep();
  }

  function handleAddressSubmit(values: CustomerAddressFormValues) {
    updateInput(values);
    nextStep();
  }

  function handleRepresentativesSubmit(
    values: CustomerRepresentativesFormValues,
  ) {
    updateInput(values);
    onSubmit({ ...input, ...values });
  }

  function renderForm() {
    switch (step) {
      case 1:
        return (
          <CustomerBasicInfoForm
            ref={basicInfoFormRef}
            defaultValues={{ ...input }}
            onSubmit={handleBasicInfoSubmit}
          />
        );
      case 2:
        return (
          <>
            <Header as="h3">Endereço (opcional)</Header>
            <CustomerAddressForm
              ref={addressFormRef}
              defaultValues={{ ...input }}
              onSubmit={handleAddressSubmit}
            />
          </>
        );
      case 3:
        return (
          <CustomerRepresentativesForm
            defaultValues={{ ...input }}
            ref={representativesFormRef}
            onSubmit={handleRepresentativesSubmit}
          />
        );
      default:
        throw new Error('Passo inválido!');
    }
  }

  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Novo cliente</Modal.Header>
      <Modal.Content style={{ minHeight: 450, maxHeight: 450 }}>
        <Step.Group size="mini" fluid>
          <Step active={step === 1}>
            <Step.Content>
              <Step.Title>Informações básicas</Step.Title>
            </Step.Content>
          </Step>
          <Step active={step === 2}>
            <Step.Content>
              <Step.Title>Endereço (opcional)</Step.Title>
            </Step.Content>
          </Step>
          <Step active={step === 3}>
            <Step.Content>
              <Step.Title>Representantes</Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
        <Divider hidden />
        {renderForm()}
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        {step === 1 ? (
          <>
            <Button onClick={onClose} basic>
              Cancelar
            </Button>
            <Button
              onClick={() => basicInfoFormRef.current?.handleSubmit()}
              loading={isSubmitting}
              primary
            >
              Próximo
            </Button>
          </>
        ) : null}
        {step === 2 ? (
          <>
            <Button onClick={prevStep} basic>
              Voltar
            </Button>
            <Button
              onClick={() => addressFormRef.current?.handleSubmit()}
              loading={isSubmitting}
              primary
            >
              Próximo
            </Button>
          </>
        ) : null}
        {step === 3 ? (
          <>
            <Button onClick={prevStep} basic>
              Voltar
            </Button>
            <Button
              onClick={() => representativesFormRef.current?.handleSubmit()}
              loading={isSubmitting}
              primary
            >
              Finalizar
            </Button>
          </>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}
