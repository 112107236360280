import React from 'react';

import { Modal, Button } from 'semantic-ui-react';

export default function RemoveQuotationProjectModal({
  onClose,
  onSubmit,
  isOpen = false,
  isLoading = false,
}) {
  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>Remover item</Modal.Header>
      <Modal.Content>Você tem certeza que deseja remover o item?</Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button type="button" onClick={onSubmit} loading={isLoading} negative>
          Remover
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
