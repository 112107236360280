import { AxiosError } from 'axios';
import { useInfiniteQuery, UseMutationOptions, useMutation } from 'react-query';

import { queryClient } from 'App';
import api, { emptyPagination, Response } from 'util/api';

export const federalReportKeys = {
  all: ['federalReports'] as const,
};

type FederalReportFilter = {
  page?: number;
  pageSize?: number;
};

export function useInfiniteFederalReports(filter: FederalReportFilter) {
  const result = useInfiniteQuery(
    [...federalReportKeys.all, filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getFederalReports({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const federalReports =
    result?.data?.pages
      ?.map((group) =>
        group?.federalReports?.map((federalReport) => federalReport),
      )
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    federalReports,
  };
}

export type FederalReportQuery = {
  id: number;
  year: number;
  month: number;
  monthDescription: string;
  notes: string | null;
  location: string;
  locationId: number;
  documentId: number;
};

async function getFederalReports(params: FederalReportFilter) {
  const url = '/admin/federal-reports';
  const { data } = await api.get<Response<FederalReportQuery>>(url, {
    params,
  });
  return {
    federalReports: data.data,
    pagination: data.pagination,
  };
}

type AddFederalReportInput = {
  year: number;
  month: number;
  notes: string;
  locationId: number;
  documentId: number;
};

type UseAddFederalReportOptions = UseMutationOptions<
  number,
  AxiosError,
  AddFederalReportInput,
  () => void
>;

export function useAddFederalReport(options?: UseAddFederalReportOptions) {
  return useMutation(addFederalReport, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(federalReportKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const addFederalReport = async (input: AddFederalReportInput) => {
  const { headers } = await api.post(`/admin/federal-reports`, input);
  const { location } = headers;
  const federalReportId = parseInt(location.split('/').pop(), 10);
  return federalReportId;
};

export function useDeleteFederalReport(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  return useMutation(deleteFederalReport, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(federalReportKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteFederalReport(federalReportId: number) {
  await api.delete(`/admin/federal-reports/${federalReportId}`);
}
