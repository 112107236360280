import { AxiosError } from 'axios';
import { DateTime } from 'luxon';
import { useInfiniteQuery, useQuery } from 'react-query';

import { getDocumentInput } from 'hooks/document';
import { ExtendedFile } from 'hooks/use-upload';
import api, { BasicQuery, emptyPagination, Response } from 'util/api';
import { formatInitials } from 'util/unitFormatter';

type SampleStatus = 'in_use' | 'returned' | 'discarded' | 'depleted';

type SamplesDiscard = {
  id: number;
  date: string;
  destination: string;
  documentId: number;
  notes: string | null;
  discardResponsible: {
    id: number;
    firstName: string;
    lastName: string;
  };
};

type SamplesReturn = {
  date: string;
  documentId: number;
  id: number;
  notes: string | null;
  receivingResponsible: {
    id: number;
    name: string;
  };
  returnResponsible: {
    id: number;
    firstName: string;
    lastName: string;
  };
};

export type SamplesQuery = {
  id: number;
  volume: number;
  code: string;
  batchNumber: string;
  arrivalDate: string;
  measurementUnit: MeasurementUnitQuery;
  sampleGoal: number;
  sampleType: number;
  status: SampleStatus;
  associatedProjects: SamplesQueryProject[];
  createdAt: string;
  updatedAt: string;
  checkInDocumentId: number | null;
  custodyChainDocumentId: number | null;
  invoiceDocumentId: number | null;
  finalVolume: number | null;
  manufacturingDate: string | null;
  hasManufacturingDay: boolean;
  expirationDate: string | null;
  hasExpirationDay: boolean;
  product: SamplesQueryProduct;
  ingredients: SamplesQueryIngredients[];
  packagings: SamplesQueryPackaging[];
  manipulations: SamplesQueryManipulation[];
  isEmpty: boolean;
  discard: SamplesDiscard | null;
  return: SamplesReturn | null;
};

type MeasurementUnitQuery = {
  id: number;
  description: string;
  abbreviation: string;
};

type SamplesQueryProject = {
  id: number;
  contract: SamplesQueryContract;
  customer: SamplesQueryCustomer;
  insideCode: string | null;
  lastApplication: LastApplication | null;
  projectGoal: number;
  projectStage: number;
  sampleGoal: number;
};

type LastApplication = {
  id: number;
  date: string;
  status: LastApplicationStatus;
};

type LastApplicationStatus = {
  description: string;
  color: string;
};

type SamplesQueryCustomer = {
  id: number;
  tradingName: string;
};

type SamplesQueryContract = {
  id: number;
  number: string;
};

type SamplesQueryProduct = {
  id: number;
  name: string;
  productTypeId: number;
  category: SamplesQueryProductCategory;
  formulation: SamplesQueryProductFormulation | null;
  mapaRegister: string | null;
  ret: string | null;
  retStage: number | null;
  retExpirationDate: string | null;
};

type SamplesQueryProductCategory = {
  id: number;
  description: string;
};

type SamplesQueryProductFormulation = {
  id: number;
  code: string;
  description: string;
};

type SamplesQueryIngredients = {
  id: number;
  name: string;
  chemicalGroup: SamplesQueryIngredientsChemicalGroup;
  concentration: number | null;
  measurementUnit: number | null;
  productId: number;
};

type SamplesQueryIngredientsChemicalGroup = {
  id: number;
  description: string;
};

export type SamplesQueryPackaging = {
  id: number;
  code: string;
  number: number;
  volume: number;
  startAmount: number;
  endAmount: number;
  lostAmount: number;
  isAvailable: boolean;
};

export type SamplesQueryManipulation = {
  id: number;
  date: string;
  dateFormatted: string;
  timeFormatted: string;
  startAmount: number;
  weighedAmount: number;
  endAmount: number;
  lostAmount: number;
  lostCode: string | null;
  balanceCode: string;
  notes: string | null;
  responsibleId: number;
  packagingId: number;
  projectId: number;
  createdAt: string;
  updatedAt: string;
  project: string;
  responsible: string;
};

export type SampleFilter = BasicQuery & {
  search?: string;
  code?: string;
  projectCode?: string;
  projectGoal?: number;
  customerId?: number;
  productName?: string;
  status?: number;
  expirationStatus?: 'valid' | 'expired';
};

export const SAMPLE_STATUS = {
  in_use: 'in_use',
  returned: 'returned',
  discarded: 'discarded',
  depleted: 'depleted',
} as const;

export const SAMPLE_STATUS_DESCRIPTION: Record<string, string> = {
  in_use: 'Em uso',
  returned: 'Devolvida',
  discarded: 'Descartada',
  depleted: 'Esgotada',
} as const;

export const SAMPLE_STATUS_COLOR = {
  in_use: 'blue',
  returned: 'transparent',
  discarded: 'transparent',
  depleted: 'transparent',
} as const;

export const SAMPLE_EXPIRATION_STATUS_DESCRIPTION: Record<string, string> = {
  valid: 'Válida',
  expired: 'Vencida',
} as const;

const getSamples = async (filter: SampleFilter) => {
  const { data } = await api.get<Response<SamplesQuery>>('/samples', {
    params: filter,
  });

  return {
    samples: data.data,
    pagination: data.pagination,
  };
};

export function useInfiniteSamples(filter: SampleFilter) {
  const result = useInfiniteQuery(
    ['samples', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getSamples({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const samples =
    result?.data?.pages
      ?.map((group) => group?.samples?.map((sample) => sample))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    samples,
  };
}

async function getSample(sampleId: number) {
  const { data } = await api.get<SamplesQuery>(`/samples/${sampleId}`);
  return sampleMapper(data);
}

export function sampleMapper(sample: SamplesQuery) {
  const numberFormatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
  });
  const volume = sample.packagings.reduce((acc, cur) => acc + cur.volume, 0);
  const finalVolume = 0;
  const manufacturingDateFormat = sample?.hasManufacturingDay
    ? 'dd/MM/yyyy'
    : 'MM/yyyy';
  const expirationDateFormat = sample?.hasExpirationDay
    ? 'dd/MM/yyyy'
    : 'MM/yyyy';
  const goalsSet = new Set([
    ...sample.associatedProjects.map((project) =>
      formatSampleGoal(project.sampleGoal),
    ),
  ]);
  const goals = Array.from(goalsSet);

  return {
    ...sample,
    id: sample.id,
    code: sample.code,
    batchNumber: sample.batchNumber,
    type: formatSampleType(sample.sampleType),
    volume: `${volume} ${sample.measurementUnit?.abbreviation}`,
    finalVolume: numberFormatter.format(finalVolume),
    unit: sample.measurementUnit,
    arrivalDateFormatted: DateTime.fromISO(sample.arrivalDate).toFormat(
      'dd/MM/yyyy',
    ),
    manufacturingDateFormatted: sample.manufacturingDate
      ? DateTime.fromISO(sample.manufacturingDate).toFormat(
          manufacturingDateFormat,
        )
      : 'n/d',
    expirationDateFormatted: sample.expirationDate
      ? DateTime.fromISO(sample.expirationDate).toFormat(expirationDateFormat)
      : 'n/d',
    checkInDocumentId: sample.checkInDocumentId,
    custodyChainDocumentId: sample.custodyChainDocumentId,
    invoiceDocumentId: sample.invoiceDocumentId,
    isEmpty: sample.isEmpty,
    packagingQuantity: sample.packagings.length,
    packagings: sample.packagings.map((packaging) =>
      packagingMapper(sample, packaging),
    ),
    product: productMapper(sample.product, sample.ingredients),
    goal: goals.join(', '),
    goals,
    associatedProjects: sample.associatedProjects.map((project) => ({
      ...project,
      sampleGoal: formatSampleGoal(project.sampleGoal),
    })),
    updatedAt: DateTime.fromISO(sample.updatedAt).toFormat('dd/MM/yyyy'),
    manipulations: sample.manipulations,
    customer: sample?.associatedProjects?.[0]?.customer || null,
    discard: sample?.discard
      ? {
          ...sample?.discard,
          dateFormatted: DateTime.fromISO(sample.discard.date).toFormat(
            'dd/MM/yyyy',
          ),
        }
      : null,
    return: sample?.return
      ? {
          ...sample?.return,
          dateFormatted: DateTime.fromISO(sample.return.date).toFormat(
            'dd/MM/yyyy',
          ),
        }
      : null,
  };
}

const RetStages: Record<number, string> = {
  1: 'Fase I',
  2: 'Fase I',
  3: 'Fase III',
};

function productMapper(
  product: SamplesQueryProduct,
  ingredients: SamplesQueryIngredients[],
) {
  return {
    ...product,
    name: product.name.toUpperCase(),
    type: product.productTypeId === 1 ? 'Biológico' : 'Químico',
    category: product.category.description,
    formulation: product.formulation
      ? `(${product.formulation?.code}) ${product.formulation?.description}`
      : 'n/d',
    ingredients: ingredients
      ?.map(
        (ingredient) =>
          `${ingredient.name} ${
            ingredient.concentration
              ? `(${ingredient.concentration} ${formatInitials(
                  ingredient.measurementUnit,
                )})`
              : ''
          }`,
      )
      .join('\n'),
    mapaRegister: product?.mapaRegister || 'n/d',
    ret: product?.ret || 'n/d',
    retStage:
      product?.retStage && RetStages?.[product.retStage]
        ? RetStages?.[product?.retStage]
        : '',
    retExpirationDate: product?.retExpirationDate
      ? DateTime.fromISO(product.retExpirationDate).toFormat('dd/MM/yyyy')
      : 'n/d',
  };
}

function packagingMapper(
  sample: SamplesQuery,
  packaging: SamplesQueryPackaging,
) {
  const numberFormatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
  });

  return {
    id: packaging.id,
    number: packaging.number,
    code: `${sample.code}-${packaging.number}`,
    volume: `${numberFormatter.format(packaging.volume)} ${
      sample.measurementUnit.abbreviation
    }`,
    startAmount: packaging.startAmount,
    endAmount: packaging.endAmount,
    lostAmount: packaging.lostAmount,
    isAvailable: packaging.isAvailable,
  };
}

function formatSampleGoal(sampleGoal: number) {
  switch (sampleGoal) {
    case 1:
      return 'Padrão';
    case 2:
      return 'Teste';
    case 3:
      return 'Adjuvante';
    default:
      throw new Error('Objetivo de amostra não encontrado');
  }
}

function formatSampleType(sampleType: number) {
  switch (sampleType) {
    case 1:
      return 'Produto';
    case 2:
      return 'Semente';
    default:
      throw new Error('Tipo de amostra não encontrado');
  }
}

export function useQuerySample(sampleId: number) {
  return useQuery(['samples', sampleId], () => getSample(sampleId));
}

export function useSamples(params: SampleFilter) {
  return useQuery(['samples', params], () =>
    getSamples({ orderBy: 'code', sort: 'desc', ...params }),
  );
}

type SampleFiles = {
  checkInFile: ExtendedFile | null;
  custodyChainFile: ExtendedFile | null;
  invoiceFile: ExtendedFile | null;
};

type SampleFilesParams = {
  sampleId: number;
  checkInDocumentId?: number | null;
  custodyChainDocumentId?: number | null;
  invoiceDocumentId?: number | null;
};

export function useSampleFiles(params: SampleFilesParams) {
  return useQuery<SampleFiles, AxiosError>({
    queryKey: ['sampleFiles', { ...params }],
    queryFn: () => getSampleFiles(params),
    enabled: !!params.sampleId,
  });
}

async function getSampleFiles({
  checkInDocumentId,
  custodyChainDocumentId,
  invoiceDocumentId,
}: SampleFilesParams) {
  const checkInPromise = checkInDocumentId
    ? getDocumentInput(checkInDocumentId)
    : null;
  const custodyChainPromise = custodyChainDocumentId
    ? getDocumentInput(custodyChainDocumentId)
    : null;
  const invoicePromise = invoiceDocumentId
    ? getDocumentInput(invoiceDocumentId)
    : null;

  const [checkInFile, custodyChainFile, invoiceFile] = await Promise.all([
    checkInPromise,
    custodyChainPromise,
    invoicePromise,
  ]);

  return {
    checkInFile,
    custodyChainFile,
    invoiceFile,
  };
}

export const UNIT_KILOGRAM = 1;
export const UNIT_GRAM = 2;
export const UNIT_MILLILITER = 3;
export const UNIT_LITER = 4;

export const SampleUnit = {
  Kilogram: 1,
  Gram: 2,
  Milliliter: 3,
  Liter: 4,
} as const;

export function formatSampleUnit(unit: number) {
  if (unit === UNIT_KILOGRAM) {
    return 'kg';
  }

  if (unit === UNIT_GRAM) {
    return 'g';
  }

  if (unit === UNIT_MILLILITER) {
    return 'ml';
  }

  if (unit === UNIT_LITER) {
    return 'l';
  }

  return '';
}
