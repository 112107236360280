import React, { useContext, createContext, useState } from 'react';

import { CONTRACT_STATUS } from 'data/contract';
import { CustomerQuery } from 'queries/customer';

type Option = {
  value: number;
  label: string;
};

type ContractFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  number?: string;
  harvest?: number;
  customers?: CustomerQuery[];
  types?: Option;
  status?: Option;
};

export type ContractFilterContextData = {
  filter: ContractFilter;
  updateFilter: (newData: ContractFilter) => void;
  getMappedFilter: () => void;
};

const INITIAL_CONTRACT_STATUS = {
  value: CONTRACT_STATUS.inProgress.id,
  label: CONTRACT_STATUS.inProgress.description,
};

export const ContractFilterContextDefaultValues = {
  filter: {
    page: 1,
    pageSize: 10,
    orderBy: 'requiredDate',
    sort: 'desc',
    number: '',
    harvest: undefined,
    customers: undefined,
    types: undefined,
    status: INITIAL_CONTRACT_STATUS,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
};

export const ContractFilterContext = createContext<ContractFilterContextData>(
  ContractFilterContextDefaultValues,
);

export type ContractFilterProviderProps = {
  children: React.ReactNode;
};

const ContractFilterProvider = ({ children }: ContractFilterProviderProps) => {
  const [projectFilter, setContractFilter] = useState<ContractFilter>({
    page: 1,
    pageSize: 10,
    orderBy: 'id',
    sort: 'desc',
    status: INITIAL_CONTRACT_STATUS,
  });

  const updateFilter = (data: ContractFilter) => {
    const newFilter = { ...projectFilter, ...data };
    setContractFilter(newFilter);
  };

  const getMappedFilter = () => {
    const { customers, status, types, ...filterUpdated } = projectFilter;

    const customerIds = customers?.length ? customers?.map(({ id }) => id) : '';
    const statusValues = status?.value || '';
    const typesValues = types?.value || '';

    return {
      ...filterUpdated,
      customerIds,
      types: typesValues,
      status: statusValues,
    };
  };

  return (
    <ContractFilterContext.Provider
      value={{
        filter: projectFilter,
        updateFilter,
        getMappedFilter,
      }}
    >
      {children}
    </ContractFilterContext.Provider>
  );
};

const useContractFilter = () => useContext(ContractFilterContext);

export { ContractFilterProvider, useContractFilter };
