import React, { createRef, Fragment } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import {
  Modal,
  Grid,
  Button,
  Form,
  Icon,
  Header,
  Divider,
  Input,
} from 'semantic-ui-react';

import './styles.css';

import InputError from 'components/inputs/InputError';
import LinkButton from 'components/inputs/LinkButton';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelectV2';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import SampleSearch from 'components/inputs/SampleSearch';
import { useAddTreatment, useUpdateTreatment } from 'mutations/treatment';
import {
  formatTreatmentType,
  isCommonTreatment,
  isUntreated,
  TreatmentTypes,
} from 'queries/treatment';

import IngredientsArray from './IngredientsArray';
import schema from './validationSchema';

const FORM_ID = 'TREATMENT_FORM';

const successMessage = {
  addedTreatment: 'Tratamento adicionado!',
  editedTreatment: 'Tratamento salvo!',
};

export default function TreatmentForm({
  isOpen,
  onClose,
  number,
  projectId,
  formValues,
}) {
  const defaultTreatmentProduct = {
    sample: null,
    quantity: null,
  };

  const defaultTreatmentIngredient = {
    ingredient: null,
    quantity: null,
  };

  const defaultValues = {
    treatmentType: '1',
    sampleDoses: [defaultTreatmentProduct],
    ingredientDoses: [defaultTreatmentIngredient],
  };

  const formRef = React.useRef(null);

  const resolver = yupResolver(schema);

  const { control, errors, watch, register, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: formValues || defaultValues,
    resolver,
  });

  const watchTreatmentType = watch('treatmentType');
  const watchTreatmentProducts = watch('sampleDoses');

  const {
    fields: sampleDoseFields,
    append: appendTreatmentProduct,
    remove: removeTreatmentProduct,
  } = useFieldArray({
    control,
    name: 'sampleDoses',
  });

  const addTreatmentMutation = useAddTreatment({
    onSuccess: () => handleSuccess(successMessage.addedTreatment),
  });

  const updateTreatmentMutation = useUpdateTreatment({
    onSuccess: () => handleSuccess(successMessage.editedTreatment),
  });

  function handleSuccess(message) {
    toast.success(message);
    onClose();
  }

  function handleAddTreatmentProductClick() {
    appendTreatmentProduct(defaultTreatmentProduct);
  }

  function handleRemoveTreatmentProductClick(index) {
    removeTreatmentProduct(index);
  }

  function submit(values) {
    const input = {
      treatmentType: parseInt(values.treatmentType, 10),
      sampleDoses: isCommonTreatment(parseInt(watchTreatmentType, 10))
        ? values.sampleDoses.map((sampleDose) => ({
            ...sampleDose,
            sampleId: sampleDose.sample.id,
            measurementUnitId: sampleDose.measurementUnit.id,
            ingredientDoses: sampleDose?.ingredientDoses?.map(
              (ingredientDose) => ({
                ingredientId: ingredientDose?.ingredient?.id || undefined,
                quantity: ingredientDose?.quantity || undefined,
                measurementUnitId:
                  ingredientDose?.measurementUnit?.id || undefined,
              }),
            ),
          }))
        : undefined,
      notes: values?.notes || null,
      projectId,
      number,
    };

    if (!formValues) {
      addTreatmentMutation.mutate(input);
      return;
    }

    const updatedInput = { ...input, treatmentId: formValues.treatmentId };
    updateTreatmentMutation.mutate(updatedInput);
  }

  const isLoading =
    addTreatmentMutation.isLoading || updateTreatmentMutation.isLoading;

  const isEditing = !!formValues;
  const submitButtonText = !isEditing ? 'Adicionar' : 'Salvar';
  const header = `Tratamento ${formValues?.number || number}`;

  return (
    <Modal
      closeOnDimmerClick={false}
      onClose={onClose}
      open={isOpen}
      size="large"
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(submit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header as="h3">Tratamento</Header>
              </Grid.Column>
              <Grid.Column width={11}>
                <Form.Field>
                  <label>Tipo de tratamento</label>
                  <RadioGroup>
                    <RadioInput
                      name="treatmentType"
                      label={formatTreatmentType(TreatmentTypes.COMMON)}
                      ref={register}
                      value={TreatmentTypes.COMMON}
                      defaultChecked
                    />
                    <RadioInput
                      name="treatmentType"
                      label={formatTreatmentType(TreatmentTypes.UNTREATED)}
                      ref={register}
                      value={TreatmentTypes.UNTREATED}
                    />
                    <RadioInput
                      name="treatmentType"
                      label={formatTreatmentType(
                        TreatmentTypes.UNTREATED_CLEAN,
                      )}
                      ref={register}
                      value={TreatmentTypes.UNTREATED_CLEAN}
                    />
                    <RadioInput
                      name="treatmentType"
                      label={formatTreatmentType(
                        TreatmentTypes.UNTREATED_INOCULATED,
                      )}
                      ref={register}
                      value={TreatmentTypes.UNTREATED_INOCULATED}
                    />
                    <RadioInput
                      name="treatmentType"
                      label={formatTreatmentType(
                        TreatmentTypes.UNTREATED_NOT_INOCULATED,
                      )}
                      ref={register}
                      value={TreatmentTypes.UNTREATED_NOT_INOCULATED}
                    />
                  </RadioGroup>
                </Form.Field>
                <Divider hidden />
                <Form.Group>
                  <Form.Field width={8}>
                    <label>Observações (opcional)</label>
                    <Controller
                      control={control}
                      name="notes"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isLoading}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
                <Divider hidden />
              </Grid.Column>
            </Grid.Row>
            <Divider />
            {sampleDoseFields.map((item, productIndex) => {
              const ingredientsRef = createRef();

              const isFirstItem = productIndex === 0;
              const productNumber = productIndex + 1;
              const shouldShowEditButton = !!watchTreatmentProducts[
                productIndex
              ]?.product;

              return (
                <Fragment key={item.id.toString()}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Header as="h3">Amostra #{productNumber}</Header>
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column textAlign="right">
                            <Button
                              basic
                              disabled={isFirstItem}
                              onClick={() =>
                                handleRemoveTreatmentProductClick(productIndex)
                              }
                              size="small"
                              type="button"
                            >
                              Remover
                            </Button>
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column>
                            <Form.Group>
                              <Form.Field width={8}>
                                <label>Amostra</label>
                                <Controller
                                  control={control}
                                  name={`sampleDoses[${productIndex}].sample`}
                                  defaultValue={item.sample}
                                  render={({ onChange, onBlur, value }) => (
                                    <SampleSearch
                                      onChange={(data) => {
                                        ingredientsRef.current.remove();
                                        data?.ingredients?.forEach(
                                          (ingredient) => {
                                            ingredientsRef.current.append({
                                              ingredient,
                                              quantity: '',
                                            });
                                          },
                                        );

                                        onChange(data);
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      filter={{ projectId }}
                                      autoComplete="off"
                                      disabled={
                                        isLoading ||
                                        !isCommonTreatment(
                                          parseInt(watchTreatmentType, 10),
                                        )
                                      }
                                      isClearable
                                    />
                                  )}
                                />
                                {errors?.sampleDoses ? (
                                  <InputError>
                                    {
                                      errors?.sampleDoses[productIndex]?.sample
                                        ?.message
                                    }
                                  </InputError>
                                ) : null}
                                <div style={{ paddingTop: '8px' }}>
                                  {shouldShowEditButton ? (
                                    <Link
                                      to={`/amostras/${watchTreatmentProducts[productIndex]?.product?.id}/editar`}
                                      target="_blank"
                                    >
                                      <LinkButton type="button">
                                        Ver amostra
                                      </LinkButton>
                                    </Link>
                                  ) : null}
                                </div>
                              </Form.Field>
                              <Form.Field width={3}>
                                <label>Dose utilizada</label>
                                <Controller
                                  control={control}
                                  name={`sampleDoses[${productIndex}].quantity`}
                                  defaultValue={item.quantity}
                                  render={({ onChange, onBlur, value }) => (
                                    <NumberFormat
                                      onValueChange={(data) => {
                                        onChange(data.floatValue);
                                      }}
                                      onBlur={onBlur}
                                      value={value}
                                      placeholder="0,00"
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      decimalScale={3}
                                      disabled={isUntreated(
                                        parseInt(watchTreatmentType, 10),
                                      )}
                                      autoComplete="off"
                                    />
                                  )}
                                />
                                {errors?.sampleDoses ? (
                                  <InputError>
                                    {
                                      errors?.sampleDoses[productIndex]
                                        ?.quantity?.message
                                    }
                                  </InputError>
                                ) : null}
                              </Form.Field>
                              <Form.Field width={5}>
                                <label>Unidade</label>
                                <Controller
                                  control={control}
                                  name={`sampleDoses[${productIndex}].measurementUnit`}
                                  defaultValue={item.measurementUnit || ''}
                                  render={({ onChange, onBlur, value }) => (
                                    <MeasurementUnitSelect
                                      onChange={onChange}
                                      onBlur={onBlur}
                                      value={value}
                                      disabled={
                                        isLoading ||
                                        isUntreated(
                                          parseInt(watchTreatmentType, 10),
                                        )
                                      }
                                      autoComplete="off"
                                      isClearable
                                    />
                                  )}
                                />
                                {errors?.measurementUnit ? (
                                  <InputError>
                                    {errors?.measurementUnit?.message}
                                  </InputError>
                                ) : null}
                              </Form.Field>
                            </Form.Group>
                            <IngredientsArray
                              nestIndex={productIndex}
                              treatmentType={watchTreatmentType}
                              ref={ingredientsRef}
                              {...{ control, register, errors }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                  <Divider />
                </Fragment>
              );
            })}
            <Grid.Row>
              <Grid.Column width={5} />
              <Grid.Column width={11}>
                <Form.Field>
                  <Button
                    type="button"
                    onClick={handleAddTreatmentProductClick}
                    basic
                    primary
                  >
                    <Icon name="add" />
                    Adicionar amostra
                  </Button>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form={FORM_ID} loading={isLoading} disabled={isLoading} primary>
          {submitButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
