import { useInfiniteQuery } from 'react-query';

import api, { emptyPagination, Response } from 'util/api';

type DocumentType = {
  id: number;
  description: string;
};

export type Documents = {
  createdAt: string;
  id: number;
  key: string;
  name: string;
  size: number;
  updatedAt: string;
  url: string;
  type: DocumentType;
};

type DocumentsFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  name?: string;
  extension?: string;
  type?: string;
};

export const DOCUMENT_TYPE = {
  contract: 1,
  assay: 2,
  protocol: 3,
  areaAssignment: 4,
  ret: 5,
  checkin: 6,
  custodyChain: 7,
  invoice: 8,
  accessSketch: 9,
  locationSketch: 10,
  reportReview: 11,
  report: 12,
  evaluation: 13,
  standardOperatingProcedure: 14,
  application: 15,
  maintenance: 16,
  federalReport: 17,
  documentSamplesReturnTerm: 18,
} as const;

export const DOCUMENT_TYPE_DESCRIPTION: Record<string, string> = {
  contract: 'Contrato',
  assay: 'Laudo',
  protocol: 'Protocolo',
  areaAssignment: 'Termo de cessão de área',
  ret: 'RET',
  checkin: 'Registro de Entrada de Amostras de Substância Teste (REA-SST)',
  custodyChain: 'Cadeia de Custódia da Substância Teste (CCST)',
  invoice: 'Nota fiscal',
  accessSketch: 'Croqui de acesso a área do ensaio',
  locationSketch: 'Croqui da área do ensaio',
  reportReview: 'Laudo de revisão',
  report: 'Laudo final',
  evaluation: 'Avaliação',
  standardOperatingProcedure: 'Procedimento operacional padrão',
  application: 'Aplicação',
  maintenance: 'Manutenção',
  federalReport: 'Informe do MAPA',
  documentSamplesReturnTerm: 'Termo de devolução de amostras',
} as const;

export const DOCUMENT_EXTENSION: Record<string, string> = {
  '7z': '7z',
  csv: 'csv',
  jpg: 'jpg',
  jpeg: 'jpeg',
  doc: 'doc',
  docx: 'docx',
  pdf: 'pdf',
  png: 'png',
  ppt: 'ppt',
  pptx: 'pptx',
  xls: 'xls',
  xlsx: 'xlsx',
  zip: 'zip',
} as const;

export const documentKeys = {
  all: ['documents'] as const,
};

export function useInfiniteDocuments(params: DocumentsFilter) {
  const result = useInfiniteQuery(
    [...documentKeys.all, params],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getDocuments({ ...params, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination ||
    emptyPagination;
  const documents =
    result?.data?.pages
      ?.map((group) => group?.data?.map((user) => user))
      .flat() || [];

  return {
    ...result,
    pagination,
    documents,
  };
}

async function getDocuments(params: DocumentsFilter) {
  const url = '/documents';
  const config = { params };
  const { data } = await api.get<Response<Documents>>(url, config);

  return data;
}
