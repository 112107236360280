import React, { useContext, createContext, useState } from 'react';

import { allDivisionValues } from 'components/inputs/DivisionSelect';
import { useAuth } from 'hooks/auth';
import { ActivityFilter, ACTIVITY_STATUS } from 'queries/activity';
import { CustomerQuery } from 'queries/customer';

type Option = {
  value: number;
  label: string;
};

type ActivityFilterProps = {
  customer?: CustomerQuery;
  type?: Option;
  endDate?: Date;
  divisions?: number[];
  division?: Option;
  insideCode?: string;
  orderBy?: string;
  page?: number;
  pageSize?: number;
  sort?: string;
  startDate?: Date;
  status?: Option;
};

export type ActivityFilterContextData = {
  filter: ActivityFilterProps;
  updateFilter: (newData: ActivityFilterProps) => void;
  getMappedFilter: () => ActivityFilter;
  resetFilter: () => void;
};

export const activityStatusOptions = [
  {
    value: ACTIVITY_STATUS.notPerformed,
    label: 'Não executadas',
  },
  { value: ACTIVITY_STATUS.performed, label: 'Executadas' },
  { value: ACTIVITY_STATUS.all, label: 'Todas' },
] as const;

const defaultStatus = activityStatusOptions[0];

export const ActivityFilterContextDefaultValues = {
  filter: {
    customer: undefined,
    type: undefined,
    endDate: undefined,
    insideCode: '',
    orderBy: 'date',
    page: 1,
    pageSize: 10,
    sort: 'desc',
    startDate: undefined,
    status: defaultStatus,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
  resetFilter: () => null,
};

export const ActivityFilterContext = createContext<ActivityFilterContextData>(
  ActivityFilterContextDefaultValues,
);

export type ActivityFilterProviderProps = {
  children: React.ReactNode;
};

const ActivityFilterProvider = ({ children }: ActivityFilterProviderProps) => {
  const [activityFilter, setActivityFilter] = useState<ActivityFilterProps>({
    page: 1,
    pageSize: 10,
    orderBy: 'date',
    sort: 'asc',
    status: defaultStatus,
  });

  const { user } = useAuth();

  const updateFilter = (data: ActivityFilterProps) => {
    const newFilter = { ...activityFilter, ...data };
    setActivityFilter(newFilter);
  };

  const getMappedFilter = () => {
    const {
      customer,
      division,
      status,
      type,
      ...filterUpdated
    } = activityFilter;
    const customerId = customer?.id || '';
    const divisions = user.isAdmin ? allDivisionValues : user.divisions;
    const divisionId = division?.value || undefined;

    return {
      ...filterUpdated,
      activityTypeId: type?.value,
      customerId,
      divisionId,
      divisions,
      status: status?.value,
    };
  };

  function resetFilter() {
    setActivityFilter({
      customer: undefined,
      type: undefined,
      endDate: undefined,
      insideCode: '',
      orderBy: 'date',
      page: 1,
      pageSize: 10,
      sort: 'asc',
      startDate: new Date(),
      status: defaultStatus,
    });
  }

  return (
    <ActivityFilterContext.Provider
      value={{
        filter: activityFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </ActivityFilterContext.Provider>
  );
};

const useActivityFilter = () => useContext(ActivityFilterContext);

export { ActivityFilterProvider, useActivityFilter };
