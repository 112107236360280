import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Button, Divider, Grid, Table } from 'semantic-ui-react';
import styled from 'styled-components';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { ActivitiesQuery } from 'queries/activity';
import { Pagination } from 'util/api';

const Container = styled.div`
  margin-top: 16px;
`;

type ActivityTableProps = {
  activities: ActivitiesQuery[];
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  isLoading?: boolean;
  onClick: (activityId: number) => void;
  pagination: Pagination;
};

export default function ActivityTable({
  activities = [],
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading = false,
  onClick,
  pagination,
}: ActivityTableProps) {
  return (
    <Container>
      <Section>
        <Section.Header>
          <PaginationInfo description="Atividades" pagination={pagination} />
        </Section.Header>
        <Section.Content>
          <Table size="small" basic="very" fixed singleLine>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>Data prevista</Table.HeaderCell>
                <Table.HeaderCell width={3}>Atividade</Table.HeaderCell>
                <Table.HeaderCell width={3}>Estudo</Table.HeaderCell>
                <Table.HeaderCell>Cliente</Table.HeaderCell>
                <Table.HeaderCell width={2}>Data execução</Table.HeaderCell>
                <Table.HeaderCell>Escala BBCH</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell />
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {isLoading && <LoadingRow columns={8} rows={5} />}
              {!isLoading &&
                activities.map((activity, index) => {
                  return (
                    <Table.Row key={`${index} ${activity.type}`.toString()}>
                      <Table.Cell>
                        <Text variant="primary">
                          {DateTime.fromISO(activity.date).toFormat(
                            'dd/MM/yyyy',
                          )}
                        </Text>
                        <Text variant="secondary">{activity.dayOfWeek}</Text>
                      </Table.Cell>
                      <Table.Cell title={activity.description}>
                        <Text variant="primary">
                          <Tag color="grey">{activity.type}</Tag>
                        </Text>
                        <Text variant="secondary">
                          {activity.description || '-'}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          <Link to={`/estudos/${activity.projectId}`}>
                            {activity.project}
                          </Link>
                        </Text>
                        <Text variant="secondary">
                          {activity.responsibleTechnician}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{activity.customer}</Text>
                        <Text variant="secondary">
                          {activity.representative}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        {activity.executionDate
                          ? DateTime.fromISO(activity.executionDate).toFormat(
                              'dd/MM/yyyy',
                            )
                          : ''}
                      </Table.Cell>
                      <Table.Cell>{activity.bbchScale || ''}</Table.Cell>
                      <Table.Cell>
                        <Tag color={activity.status.color}>
                          {activity.status.description}
                        </Tag>
                      </Table.Cell>
                      <Table.Cell>
                        {!activity.executionDate ? (
                          <LinkButton onClick={() => onClick(activity.id)}>
                            Executar
                          </LinkButton>
                        ) : null}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          <Divider hidden />
          {hasNextPage ? (
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Button
                    type="button"
                    onClick={() => fetchNextPage()}
                    disabled={!hasNextPage || isFetchingNextPage}
                  >
                    {isFetchingNextPage ? 'Carregando...' : ''}
                    {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : (
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Text variant="secondary">Nada mais para mostrar</Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Section.Content>
      </Section>
    </Container>
  );
}
