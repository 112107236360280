import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  Button,
  Icon,
  Header,
  Divider,
  Dropdown,
  DropdownMenu,
} from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import InfoList from 'components/data/InfoList';
import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import AlertModal from 'components/layout/AlertModal';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import ShipmentItemsModal, {
  ShipmentItemProps,
} from 'components/modal/ShipmentItemsModal';
import { useCancelContract } from 'data/contract';
import { downloadDocument } from 'hooks/document';
import { useModal, useModalWithData } from 'hooks/useModal';
import {
  useContract,
  formatContractStatus,
  ContractStatus,
} from 'queries/contract';
import { formatCurrencyWithSymbol } from 'util/Currency';

import ChangeShippingModal, {
  ChangeShippingFormValues,
} from './ChangeShippingModal';
import ContractProductItemTable from './ContractProductItemTable';
import ContractProjectTable from './ContractProjectTable';
import PaymentFormModal from './PaymentsFormModal';
import PaymentsTable from './PaymentsTable';
import ShipmentModal from './ShipmentModal';
import ShipmentTable from './ShipmentTable';
import ShippingForecastModal, {
  ShippingForecastFormValues,
} from './ShippingForecastModal';
import ShippingModal from './ShippingModal';
import UpdateStatusModal from './UpdateStatusModal';

type ContractPageProps = {
  id: string;
};

type ChangeShippingModalData = {
  shipmentId: number;
  formValues: ChangeShippingFormValues;
};

type ShippingForecastModalData = {
  shipmentId: number;
  formValues: ShippingForecastFormValues;
};

export default function ContractPage() {
  const history = useHistory();
  const params = useParams<ContractPageProps>();
  const contractId = parseInt(params.id, 10);

  const cancelModal = useModal();
  const paymentModal = useModal();
  const shipmentItemsModal = useModalWithData<ShipmentItemProps[]>();
  const changeShippingModal = useModalWithData<ChangeShippingModalData>();
  const shippingForecastModal = useModalWithData<ShippingForecastModalData>();
  const shipmentModal = useModal();
  const shippingModal = useModalWithData();
  const statusModal = useModal();

  const { contract, isLoading } = useContract(contractId);

  const { mutate: cancelContract, isLoading: isCanceling } = useCancelContract({
    onSuccess: handleSuccess,
  });

  function handleSuccess() {
    toast.success('Contrato e estudos cancelados!');
    cancelModal.close();
  }

  const handleAttachmentClick = () => {
    if (contract?.documentId) {
      downloadDocument(contract.documentId);
    }
  };

  function navigateToAddProject() {
    history.push('/estudos/novo', {
      contractId,
      currency: contract?.currency,
    });
  }

  function handleEditClick() {
    history.push(`/contratos/${contractId}/editar`);
  }

  function handleCancelClick() {
    cancelModal.open();
  }

  function handleParentContract() {
    if (contract?.parentContract) {
      history.push(`/orcamentos/${contract.parentContract.id}`);
    }
  }

  function handleQuotationClick() {
    if (contract?.quotation) {
      history.push(`/orcamentos/${contract.quotation.id}`);
    }
  }

  function handleChangeAddressClick(shipmentId: number) {
    const foundShipment = contract?.shipments?.find(
      (shipment) => shipment.id === shipmentId,
    );

    if (foundShipment) {
      changeShippingModal.open({
        shipmentId,
        formValues: {
          address: foundShipment.address || '',
          shippingDate: foundShipment.shippingDate
            ? DateTime.fromISO(foundShipment.shippingDate).toJSDate()
            : new Date(),
        },
      });
    }
  }

  function handleChangeShippingForecastClick(shipmentId: number) {
    const foundShipment = contract?.shipments?.find(
      (shipment) => shipment.id === shipmentId,
    );

    if (foundShipment) {
      const shippingForecast = foundShipment.shippingForecast
        ? DateTime.fromISO(foundShipment.shippingForecast).toJSDate()
        : null;
      shippingForecastModal.open({
        shipmentId,
        formValues: { shippingForecast },
      });
    }
  }

  function handleSendClick(shipmentId: number) {
    const shipment = contract?.shipments.find((item) => item.id === shipmentId);

    if (shipment) {
      const formValues = {
        address: shipment.address || '',
        shippingDate: shipment.shippingForecast
          ? new Date(shipment.shippingForecast)
          : null,
      };
      shippingModal.open({ shipmentId, formValues });
    }
  }

  function handleItemsClick(shipmentId: number) {
    const foundShipment = contract?.shipments?.find(
      (shipment) => shipment.id === shipmentId,
    );

    if (foundShipment) {
      const items = foundShipment.items.map((item) => ({
        id: item.id,
        number: item.number,
        product: item.product.description,
        quantity: item.quantity,
        unit: item.product.unit.abbreviation,
      }));
      shipmentItemsModal.open(items);
    }
  }

  const tagColor = contract
    ? formatStatusColor(contract?.status)
    : 'transparent';
  const options = [
    {
      key: 'edit',
      text: 'Editar',
      value: 'edit',
      icon: 'edit',
      onClick: handleEditClick,
    },
    {
      key: 'cancel',
      text: 'Cancelar contrato',
      value: 'Cancel contract',
      icon: 'delete',
      onClick: handleCancelClick,
      disabled:
        contract?.status === ContractStatus.Finished ||
        contract?.status === ContractStatus.Canceled,
    },
  ];

  if (isLoading || !contract) {
    return (
      <Base loading={isLoading}>
        <Container />
      </Base>
    );
  }

  return (
    <Base>
      {contract && statusModal.isOpen && (
        <UpdateStatusModal
          contractId={contract?.id}
          currentStatus={contract?.status}
          isOpen={statusModal.isOpen}
          onClose={statusModal.close}
        />
      )}

      {contract && contract?.projects && paymentModal.isOpen && (
        <PaymentFormModal
          currency={contract?.currency}
          onClose={paymentModal.close}
          isOpen={paymentModal.isOpen}
          installmentsByNumber={contract.installmentsByNumber}
        />
      )}

      {contract && cancelModal.isOpen ? (
        <AlertModal
          header="Cancelar contrato"
          content="Todos os estudos do contrato também serão cancelados. Tem certeza que deseja cancelar o contrato?"
          submitText="Cancelar"
          negative
          isOpen={cancelModal.isOpen}
          isLoading={isCanceling}
          onClose={cancelModal.close}
          onSubmit={() => cancelContract(contract?.id)}
        />
      ) : null}

      <Container>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Header as="h1" floated="left">
                {contract?.number}
              </Header>
              {contract ? (
                <Tag color={tagColor}>
                  {formatContractStatus(contract?.status)}
                </Tag>
              ) : null}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Dropdown
                text="Mais opções"
                direction="left"
                loading={false}
                floating
                basic
                button
              >
                <DropdownMenu>
                  <DropdownMenu scrolling>
                    {options.map((option) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <Dropdown.Item {...option} />
                    ))}
                  </DropdownMenu>
                </DropdownMenu>
              </Dropdown>
              {contract?.status !== ContractStatus.Canceled ? (
                <Button
                  type="button"
                  primary
                  basic
                  onClick={() => statusModal.open()}
                >
                  <Icon name="refresh" />
                  Atualizar status
                </Button>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container>
        <Grid>
          <Grid.Row columns="4">
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo label="Safra" text={contract.harvest} />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Cliente"
                    text={contract?.customer?.tradingName}
                    onClick={() =>
                      history.push(`/patrocinadores/${contract?.customer?.id}`)
                    }
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Data requerida"
                    text={
                      contract?.requiredDate
                        ? DateTime.fromISO(contract?.requiredDate).toFormat(
                            'dd/MM/yyyy',
                          )
                        : 'n/d'
                    }
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Valor total"
                    text={
                      contract?.totalAmount != null
                        ? formatCurrencyWithSymbol(
                            contract?.totalAmount,
                            contract?.currency,
                          )
                        : ''
                    }
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {contract ? (
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Detalhes
                    </Header>
                  </Section.Header>
                  <Section.Content>
                    <InfoList>
                      <InfoList.Item
                        label="Representante: "
                        value={`${contract.representative.name}`}
                        bold
                      />
                      <InfoList.Item
                        label="Data de assinatura: "
                        value={
                          contract.signatureDate
                            ? DateTime.fromISO(contract.signatureDate).toFormat(
                                'dd/MM/yyyy',
                              )
                            : 'n/d'
                        }
                      />
                      <InfoList.Item
                        label="Ordem de serviço: "
                        value={contract.workOrder || 'n/d'}
                      />
                      <InfoList.Item
                        label="Total de estudos: "
                        value={contract.projectQuantity}
                      />
                      <InfoList.Item
                        label="Observações: "
                        value={contract.notes || 'n/d'}
                      />
                      <InfoList.Item
                        label="Orçamento: "
                        value={contract?.quotation?.code || ''}
                        onClick={
                          contract.quotation ? handleQuotationClick : undefined
                        }
                      />
                      <InfoList.Item
                        label="Pertence a outro contrato: "
                        value={
                          contract.parentContract
                            ? contract.parentContract.number
                            : 'Não'
                        }
                        onClick={
                          contract.parentContract
                            ? handleParentContract
                            : undefined
                        }
                      />
                      <InfoList.Item
                        label="Documento: "
                        value={contract.documentId ? 'Anexo' : 'Sem anexo'}
                        onClick={
                          contract?.documentId
                            ? () => handleAttachmentClick()
                            : undefined
                        }
                      />
                      <InfoList.Item
                        label="Atualizado em: "
                        value={
                          contract.updatedAt
                            ? DateTime.fromISO(contract.updatedAt).toFormat(
                                'dd/MM/yyyy',
                              )
                            : 'n/d'
                        }
                      />
                    </InfoList>
                  </Section.Content>
                </Section>
              ) : null}
            </Grid.Column>
            <Grid.Column width={8}>
              {contract?.type !== 4 ? (
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Pagamentos
                    </Header>
                    {contract?.payments?.length > 0 ? (
                      <Button
                        type="button"
                        onClick={() => paymentModal.open()}
                        primary
                        basic
                      >
                        <Icon name="add" />
                        Adicionar pagamento
                      </Button>
                    ) : null}
                  </Section.Header>
                  <Section.Content>
                    {isLoading === false && contract?.payments?.length <= 0 ? (
                      <EmptyState>
                        <EmptyState.Header>
                          Nenhum pagamento encontrado
                        </EmptyState.Header>
                        <EmptyState.Description>
                          Clique no botão abaixo para adicionar
                        </EmptyState.Description>
                        <EmptyState.Actions>
                          <Button
                            type="button"
                            onClick={() => paymentModal.open()}
                            primary
                            basic
                          >
                            <Icon name="add" />
                            Adicionar
                          </Button>
                        </EmptyState.Actions>
                      </EmptyState>
                    ) : (
                      <>
                        <Divider hidden />
                        <PaymentsTable
                          payments={contract?.payments}
                          loading={isLoading}
                          currency={contract?.currency}
                        />
                      </>
                    )}
                  </Section.Content>
                </Section>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      <Container>
        <Grid>
          {contract?.type !== 4 ? (
            <Grid.Row>
              <Grid.Column width={16}>
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Estudos
                    </Header>
                  </Section.Header>
                  <Section.Content>
                    {isLoading === false && contract?.projects?.length <= 0 ? (
                      <EmptyState>
                        <EmptyState.Header>
                          Nenhum estudo encontrado
                        </EmptyState.Header>
                        <EmptyState.Description>
                          Clique no botão abaixo para adicionar um estudo
                        </EmptyState.Description>
                        <EmptyState.Actions>
                          <Button
                            type="button"
                            onClick={() => {
                              navigateToAddProject();
                            }}
                            primary
                            basic
                          >
                            <Icon name="add" />
                            Adicionar
                          </Button>
                        </EmptyState.Actions>
                      </EmptyState>
                    ) : (
                      <>
                        <Divider hidden />
                        <ContractProjectTable
                          projects={contract?.projects}
                          isLoading={isLoading}
                        />
                      </>
                    )}
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          ) : null}
          {contract?.type === 4 ? (
            <>
              <Grid.Row>
                <Grid.Column width={16}>
                  <ContractProductItemTable
                    items={contract?.products || []}
                    currency={contract?.currency}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <ShipmentTable
                    isLoading={isLoading}
                    items={contract?.shipments || []}
                    onAddClick={() => shipmentModal.open()}
                    onChangeAddressClick={handleChangeAddressClick}
                    onChangeShippingForecastClick={
                      handleChangeShippingForecastClick
                    }
                    onSendClick={handleSendClick}
                    onItemsClick={handleItemsClick}
                  />
                </Grid.Column>
              </Grid.Row>
            </>
          ) : null}
        </Grid>
      </Container>

      {shipmentModal.isOpen ? (
        <ShipmentModal
          contractId={contractId}
          formValues={shipmentModal?.data?.formValues}
          isOpen={shipmentModal.isOpen}
          items={contract.products}
          onClose={shipmentModal.close}
          shipmentId={shipmentModal?.data?.shipmentId}
        />
      ) : null}

      {changeShippingModal.isOpen && changeShippingModal.data ? (
        <ChangeShippingModal
          formValues={changeShippingModal?.data?.formValues}
          isOpen={changeShippingModal.isOpen}
          onClose={changeShippingModal.close}
          shipmentId={changeShippingModal?.data?.shipmentId}
        />
      ) : null}

      {shippingForecastModal.isOpen && shippingForecastModal.data ? (
        <ShippingForecastModal
          formValues={shippingForecastModal?.data?.formValues}
          isOpen={shippingForecastModal.isOpen}
          onClose={shippingForecastModal.close}
          shipmentId={shippingForecastModal?.data?.shipmentId}
        />
      ) : null}

      {shippingModal.isOpen && shippingModal.data ? (
        <ShippingModal
          formValues={shippingModal?.data?.formValues}
          isOpen={shippingModal.isOpen}
          onClose={shippingModal.close}
          shipmentId={shippingModal?.data?.shipmentId}
        />
      ) : null}

      {shipmentItemsModal.isOpen && shipmentItemsModal?.data ? (
        <ShipmentItemsModal
          isOpen={shipmentItemsModal.isOpen}
          items={shipmentItemsModal?.data}
          onClose={shipmentItemsModal.close}
        />
      ) : null}
    </Base>
  );
}

function formatStatusColor(status: number) {
  switch (status) {
    case 1:
      return 'green';
    case 2:
      return 'transparent';
    case 3:
      return 'grey';
    case 4:
      return 'red';
    default:
      throw new TypeError('Status de contrato inválido!');
  }
}
