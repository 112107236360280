import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Modal,
  Button,
  Form,
  Grid,
  Input,
  Divider,
  Checkbox,
} from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import { useUpdateSample } from 'mutations/sample';
import { SampleUnit } from 'queries/sample';
import { emptyStringToUndefined } from 'util/validation';

type SampleEditModalProps = {
  formValues: FormValues;
  isOpen: boolean;
  onClose: () => void;
  sampleId: number;
};

type FormValues = {
  arrivalDate: Date;
  batchNumber: string;
  code: string;
  expirationDate?: Date;
  hasExpirationDay: boolean;
  hasManufacturingDay: boolean;
  manufacturingDate?: Date;
  sampleType: string;
  measurementUnit: string;
};

const schema = yup
  .object()
  .shape({
    code: yup
      .string()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    batchNumber: yup
      .string()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    sampleType: yup
      .string()
      .required('Obrigatório')
      .transform(emptyStringToUndefined),
    arrivalDate: yup
      .date()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    manufacturingDate: yup
      .date()
      .optional()
      .nullable()
      .transform(emptyStringToUndefined),
    expirationDate: yup
      .date()
      .optional()
      .nullable()
      .transform(emptyStringToUndefined),
    measurementUnit: yup
      .string()
      .required(`Obrigatório`)
      .transform(emptyStringToUndefined),
  })
  .required('Obrigatório');

const formId = 'form_crop_variety';

export default function SampleEditModal({
  formValues,
  isOpen,
  onClose,
  sampleId,
}: SampleEditModalProps) {
  const [hasManufacturingDay, setManufacturingDay] = React.useState(
    formValues.hasManufacturingDay,
  );
  const [hasExpirationDay, setExpirationDay] = React.useState(
    formValues.hasExpirationDay,
  );

  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit, register } = useForm({
    defaultValues: formValues,
    mode: 'onBlur',
    resolver,
  });

  const { mutate: updateSample, isLoading } = useUpdateSample({
    onSuccess: () => {
      toast.success('Amostra salva!');
      onClose();
    },
  });

  function onSubmit(values: FormValues) {
    const { manufacturingDate, expirationDate } = values;

    const input = {
      ...values,
      manufacturingDate: manufacturingDate || null,
      hasManufacturingDay,
      expirationDate: expirationDate || null,
      hasExpirationDay,
      measurementUnitId: values.measurementUnit,
      sampleId,
    };

    updateSample(input);
  }

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>Amostra</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
                <Form.Field>
                  <label>Tipo de amostra</label>
                  <RadioGroup>
                    <RadioInput
                      name="sampleType"
                      label="Produto"
                      value={1}
                      ref={register}
                      defaultChecked
                    />
                    <RadioInput
                      name="sampleType"
                      label="Semente"
                      value={2}
                      ref={register}
                    />
                  </RadioGroup>
                </Form.Field>
                <Divider hidden />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Código</label>
                    <Controller
                      control={control}
                      name="code"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isLoading}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.code && (
                      <InputError>{errors?.code?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Lote</label>
                    <Controller
                      control={control}
                      name="batchNumber"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isLoading}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.batchNumber && (
                      <InputError>{errors?.batchNumber?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Data de entrada</label>
                    <Controller
                      control={control}
                      name="arrivalDate"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isLoading}
                        />
                      )}
                    />
                    {errors.arrivalDate && (
                      <InputError>{errors?.arrivalDate?.message}</InputError>
                    )}
                  </Form.Field>
                </Form.Group>
                <Divider hidden />
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Fabricação (opcional)</label>
                    <Controller
                      control={control}
                      name="manufacturingDate"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          placeholderText={
                            hasManufacturingDay ? 'dd/mm/aaaa' : 'mm/aaaa'
                          }
                          dateFormat={
                            hasManufacturingDay ? 'dd/MM/yyyy' : 'MM/yyyy'
                          }
                          showMonthYearPicker={!hasManufacturingDay}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isLoading}
                        />
                      )}
                    />
                    {errors.manufacturingDate && (
                      <InputError>
                        {errors?.manufacturingDate?.message}
                      </InputError>
                    )}
                    <Checkbox
                      label="dd/mm/aaaa"
                      onChange={() => setManufacturingDay(!hasManufacturingDay)}
                      style={{ marginTop: '8px' }}
                      checked={hasManufacturingDay}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Validade (opcional)</label>
                    <Controller
                      control={control}
                      name="expirationDate"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          placeholderText={
                            hasExpirationDay ? 'dd/mm/aaaa' : 'mm/aaaa'
                          }
                          dateFormat={
                            hasExpirationDay ? 'dd/MM/yyyy' : 'MM/yyyy'
                          }
                          showMonthYearPicker={!hasExpirationDay}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isLoading}
                        />
                      )}
                    />
                    {errors.expirationDate && (
                      <InputError>{errors?.expirationDate?.message}</InputError>
                    )}
                    <Checkbox
                      label="dd/mm/aaaa"
                      onChange={() => setExpirationDay(!hasExpirationDay)}
                      style={{ marginTop: '8px' }}
                      checked={hasExpirationDay}
                    />
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                <Form.Field>
                  <label>Unidade de medida</label>
                  <RadioGroup>
                    <RadioInput
                      name="measurementUnit"
                      label="Grama"
                      value={SampleUnit.Gram}
                      ref={register}
                      defaultChecked
                    />
                    <RadioInput
                      name="measurementUnit"
                      label="Quilograma"
                      value={SampleUnit.Kilogram}
                      ref={register}
                    />
                    <RadioInput
                      name="measurementUnit"
                      label="Mililitro"
                      value={SampleUnit.Milliliter}
                      ref={register}
                    />
                    <RadioInput
                      name="measurementUnit"
                      label="Litro"
                      value={SampleUnit.Liter}
                      ref={register}
                    />
                  </RadioGroup>
                  {errors.measurementUnit && (
                    <InputError>{errors?.measurementUnit?.message}</InputError>
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
