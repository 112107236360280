import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { DateTime } from 'luxon';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Modal,
  Button,
  Form,
  Input,
  Icon,
  Grid,
  Header,
} from 'semantic-ui-react';
import { array, date, object, string } from 'yup';

import ActivityTypeSelect from 'components/inputs/ActivityTypeSelect';
import DatePicker from 'components/inputs/date-picker';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import { useAddActivities } from 'mutations/project';
import { activityTypes } from 'queries/activity';
import { emptyStringToNull } from 'util/validation';

const schema = object().shape({
  activities: array()
    .of(
      object().shape({
        date: date()
          .required('Obrigatório')
          .typeError('Obrigatório'),
        type: object()
          .required('Obrigatório')
          .transform(emptyStringToNull),
        description: string()
          .optional()
          .nullable()
          .transform(emptyStringToNull),
      }),
    )
    .required(`Atividades é obrigatório`),
});

const FORM_ID = 'activity_form';

export default function ActivitiesModalForm({
  projectId,
  formValues = undefined,
  onClose,
  onSuccess,
  open,
}) {
  const formRef = React.useRef(null);

  const defaultValues = formValues?.activities
    ? mapToForm(formValues.activities)
    : { activities: [{}] };
  const resolver = yupResolver(schema);
  const { control, errors, watch, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const {
    fields: activityFields,
    append: appendActivity,
    remove: removeActivity,
  } = useFieldArray({
    control,
    name: 'activities',
  });

  const watchActivities = watch('activities');

  function handleAddActivityClick() {
    appendActivity({ data: '', type: '', description: '' });
  }

  const { mutate: addActivities, isLoading: isAdding } = useAddActivities({
    onSuccess: () => {
      handleSuccess('Atividades adicionadas com sucesso!');
    },
  });

  function handleSuccess(message) {
    toast.success(message);
    onSuccess();
    onClose();
  }

  function onSubmit(values) {
    const updatedValues = {
      projectId,
      activities: mapFromForm(values),
    };
    addActivities(updatedValues);
  }

  const header = !formValues?.activities
    ? 'Adicionar atividades'
    : 'Editar atividades';
  const isLoading = isAdding;

  return (
    <Modal size="large" open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Grid verticalAlign="middle">
            <Grid.Column width={8}>
              <Header as="h3">Atividades</Header>
            </Grid.Column>
            <Grid.Column width={16}>
              {activityFields.map((item, index) => {
                const isFirstItem = index === 0;

                const disabledRemoveButton =
                  isFirstItem && watchActivities.length === 1;

                return (
                  <Form.Group key={item.id}>
                    <Form.Field width={3}>
                      {isFirstItem ? <label>Data prevista</label> : null}
                      <Controller
                        control={control}
                        name={`activities[${index}].date`}
                        defaultValue={item.date}
                        render={({ onChange, onBlur, value }) => (
                          <DatePicker
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            dateFormat="dd/MM/yyyy - EEEE"
                          />
                        )}
                      />
                      {errors.activities && (
                        <InputError>
                          {errors?.activities[index]?.date?.message}
                        </InputError>
                      )}
                    </Form.Field>
                    <Form.Field width={4}>
                      {isFirstItem ? <label>Tipo</label> : null}
                      <Controller
                        control={control}
                        name={`activities[${index}].type`}
                        defaultValue={item.type}
                        render={({ onChange, onBlur, value }) => (
                          <ActivityTypeSelect
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isLoading}
                            autoComplete="off"
                          />
                        )}
                      />
                      {errors.activities && (
                        <InputError>
                          {errors?.activities[index]?.type?.message}
                        </InputError>
                      )}
                    </Form.Field>
                    <Form.Field width={9}>
                      {isFirstItem ? <label>Descrição (opcional)</label> : null}
                      <Controller
                        control={control}
                        name={`activities[${index}].description`}
                        defaultValue={item.description}
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isLoading}
                            autoComplete="off"
                            fluid
                          />
                        )}
                        rules={{ required: true }}
                      />
                    </Form.Field>
                    <Form.Field width={1}>
                      {isFirstItem ? <HiddenLabel /> : null}
                      <Button
                        type="button"
                        size="small"
                        onClick={() => removeActivity(index)}
                        disabled={disabledRemoveButton}
                        icon
                        basic
                      >
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  </Form.Group>
                );
              })}
              <Form.Field width={3}>
                <Button
                  type="button"
                  onClick={handleAddActivityClick}
                  primary
                  basic
                  fluid
                >
                  <Icon name="add" />
                  Adicionar
                </Button>
              </Form.Field>
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form={FORM_ID} loading={isLoading} primary>
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function mapToForm(activities) {
  const formValues = activities.map((activity) => {
    return {
      ...activity,
      date: DateTime.fromISO(activity.date).toJSDate(),
      executionDate: activity.executionDate
        ? DateTime.fromISO(activity.executionDate).toJSDate()
        : null,
      bbchScale: activity.bbchScale || null,
      type: {
        value: activity.type,
        label: formatActivityType(activity.type),
      },
    };
  });
  return { activities: formValues };
}

function formatActivityType(type) {
  if (!activityTypes[type]) {
    return '';
  }
  return activityTypes[type];
}

function mapFromForm(values) {
  return values.activities.map((activity) => {
    return {
      ...activity,
      type: activity.type.value,
    };
  });
}
