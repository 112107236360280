import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import {
  Modal,
  Button,
  Form,
  Input,
  Icon,
  Header,
  Message,
  Divider,
} from 'semantic-ui-react';

import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import RepresentativeSearch from 'components/inputs/RepresentativeSearchV2';
import RepresentativeFormModal from 'components/modal/RepresentativeFormModal';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/useModal';
import { useAddRepresentative } from 'mutations/customer';
import { useCreateQuotation, useUpdateQuotation } from 'mutations/quotation';

import { CreateQuotation, EditQuotation } from './validation';

const DEFAULT_VALUES = {
  customer: '',
  representative: '',
  quotationResponsible: '',
  discountPercent: null,
  version: '',
  reviewDate: '',
  notes: null,
};

export default function QuotationFormModal({ open, quotation, onClose }) {
  const formRef = React.useRef(null);
  const [representativeResult, setRepresentativeMessage] = React.useState(null);

  const history = useHistory();
  const { user } = useAuth();
  const representativeModal = useModal();
  const queryClient = useQueryClient();

  const defaultValues = quotation ? mapToForm(quotation) : DEFAULT_VALUES;
  const resolver = quotation
    ? yupResolver(EditQuotation)
    : yupResolver(CreateQuotation);
  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });
  const watchCustomer = watch('customer');

  const { mutate: createQuotation, loading: isCreating } = useCreateQuotation({
    onSuccess: (quotationId) => onCreateSuccess(quotationId),
  });

  function onCreateSuccess(quotationId) {
    toast.success('Orçamento criado!');
    history.push(`/orcamentos/${quotationId}`);
    onClose();
  }

  const { mutate: updateQuotation, loading: isUpdating } = useUpdateQuotation({
    onSuccess: () => onUpdateSuccess(),
  });

  function onUpdateSuccess() {
    toast.success('Orçamento salvo!');
    onClose();
  }

  const {
    mutate: addRepresentative,
    isLoading: isAddingRepresentative,
    error: addRepresentativeError,
  } = useAddRepresentative({
    onSuccess: handleRepresentativeSuccess,
  });

  function handleRepresentativeSuccess(representativeId) {
    setRepresentativeMessage({
      message: 'Representante adicionado com sucesso!',
      type: 'success',
    });
    const updatedRepresentatives = queryClient.getQueryData([
      'representatives',
      { customerId: watchCustomer?.id },
    ]);
    const representative = updatedRepresentatives?.data?.find(
      (rep) => rep.id === representativeId,
    );
    setValue('representative', representative);
    representativeModal.close();
  }

  function handleRepresentativeSubmit(values) {
    const input = { ...values, active: true, customerId: watchCustomer?.id };
    addRepresentative(input);
  }

  function onSubmit(values) {
    const input = {
      ...values,
      customerId: values.customer.id,
      representativeId: values.representative.id,
      currency: parseInt(values.currency, 10),
      quotationType: parseInt(values.quotationType, 10),
      discountPercent: values.discountPercent ? values.discountPercent : null,
      notes: values.notes,
      quotationResponsibleId: user.employeeId,
    };

    if (isEditing) {
      updateQuotation({
        ...input,
        quotationId: quotation?.id,
        quotationType: quotation?.quotationType,
        reviewDate: input.reviewDate ? input.reviewDate : null,
        notes: input.notes ? input.notes : null,
      });
      return;
    }

    createQuotation({ ...input, quotationDate: new Date() });
  }

  const isSubmiting = isCreating || isUpdating;
  const isEditing = !!quotation;
  const header = isEditing ? 'Edição de orçamento' : 'Novo orçamento';
  const submitButtonText = isEditing ? 'Salvar' : 'Adicionar';

  return (
    <>
      <Modal size="tiny" open={open} onClose={onClose}>
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>
          <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
            <Header as="h3">Cliente</Header>
            <Form.Field>
              <label>Empresa</label>
              <Controller
                control={control}
                name="customer"
                render={({ onChange, onBlur, value }) => (
                  <>
                    <CustomerSearch
                      onChange={(customerData) => {
                        setValue('representative', null);
                        onChange(customerData);
                      }}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  </>
                )}
              />
              {errors.customer && (
                <InputError>{errors.customer.message}</InputError>
              )}
            </Form.Field>
            <Form.Group>
              <Form.Field width={12}>
                <label>Representante</label>
                <Controller
                  control={control}
                  name="representative"
                  render={({ onChange, onBlur, value }) => (
                    <>
                      <RepresentativeSearch
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        customerId={watchCustomer?.id}
                        isLoading={isAddingRepresentative}
                        disabled={isSubmiting || !watchCustomer}
                        autoComplete="off"
                      />
                    </>
                  )}
                />
                {errors.representative && (
                  <InputError>{errors.representative.message}</InputError>
                )}
              </Form.Field>
              <Form.Field width={4}>
                <HiddenLabel />
                <Button
                  type="button"
                  onClick={() => representativeModal.open()}
                  disabled={isSubmiting || !watchCustomer}
                  basic
                  fluid
                >
                  <Icon name="add" />
                  Novo
                </Button>
              </Form.Field>
            </Form.Group>
            {representativeResult && (
              <Message
                content={representativeResult?.message}
                negative={representativeResult?.type === 'error'}
                positive={representativeResult?.type === 'success'}
              />
            )}
            <Divider hidden />
            {!quotation ? (
              <>
                <Header as="h3">Orçamento</Header>
                <Form.Field>
                  <label>Tipo</label>
                  <RadioGroup>
                    <RadioInput
                      name="quotationType"
                      label="Serviço"
                      value={1}
                      ref={register}
                    />
                    <RadioInput
                      name="quotationType"
                      label="Produto"
                      value={2}
                      ref={register}
                    />
                  </RadioGroup>
                  {errors.quotationType && (
                    <InputError>{errors?.quotationType?.message}</InputError>
                  )}
                </Form.Field>
                <Divider hidden />
              </>
            ) : null}
            <Form.Field>
              <label>Moeda</label>
              <RadioGroup>
                <RadioInput
                  name="currency"
                  label="Real (R$)"
                  value={1}
                  ref={register}
                  defaultChecked
                />
                <RadioInput
                  name="currency"
                  label="Dólar (US$)"
                  value={2}
                  ref={register}
                />
              </RadioGroup>
              {errors.currency && <InputError>Obrigatório</InputError>}
            </Form.Field>
            <Divider hidden />
            {isEditing && (
              <>
                <Header as="h3">Revisão</Header>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Versão</label>
                    <Controller
                      control={control}
                      name="version"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isSubmiting}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.version && (
                      <InputError>{errors?.version?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field>
                    <label>Data de revisão (opcional)</label>
                    <Controller
                      control={control}
                      name="reviewDate"
                      render={({ onChange, onBlur, value }) => (
                        <DatePicker
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          disabled={isSubmiting}
                        />
                      )}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}
            <Form.Group widths="equal">
              <Form.Field>
                <label>Desconto % (opcional)</label>
                <Controller
                  control={control}
                  name="discountPercent"
                  render={({ onChange, onBlur, value }) => (
                    <NumberFormat
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue);
                      }}
                      onBlur={onBlur}
                      value={value}
                      decimalSeparator=","
                      thousandSeparator="."
                      suffix="%"
                      decimalScale={3}
                      disabled={isSubmiting}
                      autoComplete="off"
                    />
                  )}
                />
                {errors.discountPercent && (
                  <InputError>{errors?.discountPercent?.message}</InputError>
                )}
              </Form.Field>
              <Form.Field>
                <label>Observações (opcional)</label>
                <Controller
                  control={control}
                  name="notes"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      autoComplete="off"
                      fluid
                    />
                  )}
                />
                {errors.notes && (
                  <InputError>{errors?.notes?.message}</InputError>
                )}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose} basic>
            Cancelar
          </Button>
          <Button form="form" loading={isSubmiting} primary>
            {submitButtonText}
          </Button>
        </Modal.Actions>
      </Modal>

      <RepresentativeFormModal
        onClose={representativeModal.close}
        onSubmit={handleRepresentativeSubmit}
        isOpen={representativeModal.isOpen}
        error={addRepresentativeError}
        isSubmiting={isAddingRepresentative}
      />
    </>
  );
}

function mapToForm(quotation) {
  return {
    ...quotation,
    currency: quotation.currency.toString(),
    quotationType: quotation.quotationType.toString(),
  };
}
