import React from 'react';

import { Button, Divider, Grid, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { SaleProductQuery } from 'data/sale-product';
import { Pagination } from 'util/api';
import { Currencies, formatWithSymbol } from 'util/Currency';

type SaleProductTableProps = {
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onEditClick: (saleProductId: number) => void;
  pagination: Pagination;
  saleProducts: SaleProductQuery[];
  showActions?: boolean;
};

export default function SaleProductTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  onEditClick,
  pagination,
  saleProducts = [],
  showActions = true,
}: SaleProductTableProps) {
  const isEmpty = !isLoading && !saleProducts.length;
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Pragas" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        {!isEmpty ? (
          <>
            <Table size="small" basic="very" singleLine unstackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Descrição</Table.HeaderCell>
                  <Table.HeaderCell>NCM</Table.HeaderCell>
                  <Table.HeaderCell>Unidade</Table.HeaderCell>
                  <Table.HeaderCell>Preço unitário</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {isLoading ? <LoadingRow columns={5} rows={5} /> : null}
                {!isLoading &&
                  saleProducts.map(
                    ({
                      id,
                      description,
                      ncmCode,
                      measurementUnit,
                      unitPrice,
                    }) => {
                      const unitPriceFormatted = formatWithSymbol({
                        amount: unitPrice,
                        currency: Currencies.REAL,
                      });
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>{description}</Table.Cell>
                          <Table.Cell>{ncmCode}</Table.Cell>
                          <Table.Cell>{measurementUnit}</Table.Cell>
                          <Table.Cell>{unitPriceFormatted}</Table.Cell>
                          <Table.Cell textAlign="center">
                            {showActions ? (
                              <LinkButton onClick={() => onEditClick(id)}>
                                Editar
                              </LinkButton>
                            ) : null}
                          </Table.Cell>
                        </Table.Row>
                      );
                    },
                  )}
              </Table.Body>
            </Table>
            <Divider hidden />
            {hasNextPage ? (
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Button
                      type="button"
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}
                    >
                      {isFetchingNextPage ? 'Carregando...' : ''}
                      {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : (
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="center">
                    <Text variant="secondary">Nada mais para mostrar</Text>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
          </>
        ) : (
          <EmptyState>
            <EmptyState.Header>Nenhum produto encontrado</EmptyState.Header>
          </EmptyState>
        )}
      </Section.Content>
    </Section>
  );
}
