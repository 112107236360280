import { UserResponse } from './use-auth';

const TOKEN_KEY = 'token_key';
const USER_KEY = 'user_key';

function useAuth() {
  const user = getCurrentUser();
  return { user };
}

function getCurrentUser() {
  const storagedUser = localStorage.getItem(USER_KEY);
  const user: UserResponse = storagedUser ? JSON.parse(storagedUser) : null;

  const updatedUser = user
    ? {
        ...user,
        roleType: user?.roleType || getRoleType(user),
      }
    : null;

  return updatedUser;
}

const ROLES = {
  1: 'admin',
  2: 'research',
  3: 'warehouse',
  4: 'breeding',
} as const;

export type RoleType = typeof ROLES[keyof typeof ROLES];

function getRoleType(user: UserResponse) {
  if (!ROLES[user.role]) {
    throw new Error('Papel de usuário não encontrado!');
  }

  return ROLES[user.role];
}

function logout() {
  localStorage.removeItem(USER_KEY);
  localStorage.removeItem(TOKEN_KEY);
}

export { useAuth, getCurrentUser, logout };
