import React from 'react';

import toast from 'react-hot-toast';
import { Modal, Button } from 'semantic-ui-react';

import { useUpdateProjectToNextStage } from 'mutations/project';

export default function UpdateStageModal({
  projectId,
  currentStage,
  open,
  onClose,
}) {
  const {
    mutate: updateProjectToNextStage,
    isLoading,
  } = useUpdateProjectToNextStage({
    onSuccess: () => {
      toast.success('Fase atualizada com sucesso!');
      onClose();
    },
  });

  function submit() {
    updateProjectToNextStage(projectId);
  }

  function formatTexts() {
    switch (currentStage) {
      case 1:
        return {
          header: 'Concluir habilitação?',
          content: 'Concluir habilitação e ir para a fase preparatória?',
          positive: 'Habilitar',
        };
      case 2:
        return {
          header: 'Concluir fase preparatória?',
          content:
            'Concluir fase preparatória e ir para a fase de implantação?',
          positive: 'Concluir',
        };
      case 3:
        return {
          header: 'Concluir implantação?',
          content: 'Concluir implantação e ir para a fase de avaliação?',
          positive: 'Concluir',
        };
      case 4:
        return {
          header: 'Concluir avaliação?',
          content:
            'Concluir fase de avaliação e ir para a fase de análise de dados?',
          positive: 'Concluir',
        };
      case 5:
        return {
          header: 'Concluir análise de dados?',
          content:
            'Concluir fase de análise de dados e ir para a fase de redação de laudo?',
          positive: 'Concluir',
        };
      case 6:
        return {
          header: 'Concluir redação de laudo?',
          content:
            'Concluir redação de laudo e ir para a fase de revisão administrativa?',
          positive: 'Concluir',
        };
      case 10:
        return {
          header: 'Concluir revisão administrativa?',
          content:
            'Concluir revisão administrativa e ir para a fase de revisão do cliente?',
          positive: 'Concluir',
        };
      default:
        return {
          header: '',
          content: '',
          positive: '',
        };
    }
  }

  const texts = formatTexts();

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>{texts.header}</Modal.Header>
      <Modal.Content>{texts.content}</Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={() => submit()} loading={isLoading} primary>
          {texts.positive}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
