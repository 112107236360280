import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
} from 'semantic-ui-react';

import QuotationFormModal from 'components/modal/QuotationFormModal';
import {
  useExportQuotationProjects,
  useExportQuotations,
} from 'data/quotation';
import { useAuth } from 'hooks/auth';
import { useInfiniteQuotations } from 'queries/quotation';
import { useQuotationFilter } from 'templates/Quotations/use-quotation-filter';

import QuotationFilter from './QuotationFilter';
import QuotationsTable from './QuotationsTable';

const MESSAGES = {
  loading: 'Exportando estudos...',
  success: 'Estudos exportadas!',
  error: 'Não foi possível exportar os estudos. Tente novamente.',
};

export default function Quotations() {
  const [modalOpen, setModalOpen] = React.useState(false);

  const history = useHistory();
  const { user } = useAuth();

  const { getMappedFilter } = useQuotationFilter();

  const {
    pagination,
    quotations,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuotations(getMappedFilter());

  const exportQuotations = useExportQuotations();
  const exportQuotationProjects = useExportQuotationProjects();

  function handleExportQuotationClick() {
    const promise = exportQuotations.mutateAsync(getMappedFilter());
    toast.promise(promise, MESSAGES);
  }

  function handleExportProjectsClick() {
    const promise = exportQuotationProjects.mutateAsync();
    toast.promise(promise, MESSAGES);
  }

  function handleClick() {
    setModalOpen(true);
  }

  function handleSuccess(quotationId) {
    toast.success('Orçamento adicionado com sucesso!');
    navigateToQuotationDetails(quotationId);
  }

  function navigateToQuotationDetails(quotationId) {
    history.push(`/orcamentos/${quotationId}`);
  }

  const options = [
    {
      key: 'export-quotations-csv',
      text: 'Orçamentos (CSV)',
      icon: 'file excel',
      onClick: handleExportQuotationClick,
    },
    {
      key: 'export-projects-csv',
      text: 'Custo tratamento (CSV)',
      icon: 'file excel',
      onClick: handleExportProjectsClick,
    },
  ];

  return (
    <>
      {modalOpen && (
        <QuotationFormModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onSuccess={handleSuccess}
        />
      )}
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column verticalAlign="middle">
            <Header as="h1">Orçamentos</Header>
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <Dropdown
              basic
              button
              direction="left"
              disabled={exportQuotationProjects.isLoading}
              text="Relatórios"
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {options.map((option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Dropdown.Item {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            <Button primary onClick={handleClick}>
              <Icon name="add" />
              Novo orçamento
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <QuotationFilter />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <QuotationsTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetching={isFetching}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              pagination={pagination}
              quotations={quotations}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
