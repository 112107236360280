import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Form, Input, Modal } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import { useCreateCrop, useUpdateCrop } from 'data/crop';

import { CropFormInput, CropFormValues } from './validation';

export type CropModalProps = {
  formValues?: CropFormValues;
  isOpen: boolean;
  onClose: () => void;
  cropId?: number;
};

const formId = 'form_crop' as const;

const MESSAGE = {
  successCreate: 'Cultura cadastrada!',
  successUpdate: 'Cultura salva!',
} as const;

export default function CropModal({
  formValues,
  isOpen,
  onClose,
  cropId,
}: CropModalProps) {
  const defaultValues = formValues ? { ...formValues } : {};
  const resolver = yupResolver(CropFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit } = useForm(formOptions);

  const createCrop = useCreateCrop({
    onSuccess: () => handleSuccess(MESSAGE.successCreate),
  });

  const updateCrop = useUpdateCrop({
    onSuccess: () => handleSuccess(MESSAGE.successUpdate),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: CropFormValues) {
    const input = {
      name: values.name,
      nameInt: values.nameInt,
      insideCode: values.insideCode,
    };

    if (cropId) {
      updateCrop.mutate({ cropId, ...input });
      return;
    }

    createCrop.mutate(input);
  }

  const isLoading = createCrop.isLoading || updateCrop.isLoading;
  const header = cropId ? 'Edição de cultura' : 'Nova cultura';
  const buttonText = cropId ? 'Salvar' : 'Cadastrar';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Nome</label>
              <Controller
                control={control}
                name="name"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    maxLength={255}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.name && <InputError>{errors?.name?.message}</InputError>}
            </Form.Field>
            <Form.Field>
              <label>Nome internacional</label>
              <Controller
                control={control}
                name="nameInt"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    maxLength={255}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.nameInt && (
                <InputError>{errors?.nameInt?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Código</label>
              <Controller
                control={control}
                name="insideCode"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    maxLength={3}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors.insideCode && (
                <InputError>{errors?.insideCode?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
