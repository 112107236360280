import { quotationRepository } from '../container';

export type GetQuotationProjectParams = {
  quotationId: number;
  quotationProjectId: number;
};

export default async function getQuotationProject(
  params: GetQuotationProjectParams,
) {
  return quotationRepository.getQuotationProject(
    params.quotationId,
    params.quotationProjectId,
  );
}
