import React from 'react';

import { Grid } from 'semantic-ui-react';

import { useProjectColections } from 'queries/project';

import ProjectCollectionTable from '../ProjectCollectionTable';

type ProjectCollectionsPageProps = {
  projectId: number;
};

export default function ProjectCollectionsPage({
  projectId,
}: ProjectCollectionsPageProps) {
  const { collections, isLoading } = useProjectColections(projectId);

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <ProjectCollectionTable isLoading={isLoading} items={collections} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
