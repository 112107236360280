import * as React from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Modal, Button, Form, Dimmer, Loader } from 'semantic-ui-react';

import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useUpdateSampleDocuments } from 'mutations/sample';
import { useSampleFiles } from 'queries/sample';

type SampleDocumentEditModalProps = {
  isOpen: boolean;
  onClose: () => void;
  checkInDocumentId: number | null;
  custodyChainDocumentId: number | null;
  invoiceDocumentId: number | null;
  sampleId: number;
};

const formId = 'form_sample_documents';

const DOCUMENT_TYPE_CHECK_IN = 6;
const DOCUMENT_TYPE_CUSTODY_CHAIN = 7;
const DOCUMENT_TYPE_INVOICE = 8;

export default function SampleDocumentEditModal({
  isOpen,
  onClose,
  sampleId,
  checkInDocumentId,
  custodyChainDocumentId,
  invoiceDocumentId,
}: SampleDocumentEditModalProps) {
  const [isMounted, setMounted] = React.useState(false);

  const checkInFile = useUpload();
  const custodyChainFile = useUpload();
  const invoiceFile = useUpload();

  const { handleSubmit } = useForm();

  const { data: sampleFiles, isLoading } = useSampleFiles({
    sampleId,
    checkInDocumentId,
    custodyChainDocumentId,
    invoiceDocumentId,
  });

  const { setFile: setCheckInFile } = checkInFile;
  const { setFile: setCustodyChainFile } = custodyChainFile;
  const { setFile: setInvoiceFile } = invoiceFile;

  const {
    mutate: updateSampleDocuments,
    isLoading: isSubmitting,
  } = useUpdateSampleDocuments({
    onSuccess: () => {
      toast.success('Documentos atualizados!');
      onClose();
    },
  });

  React.useEffect(() => {
    if (!isMounted && sampleFiles) {
      setCheckInFile(sampleFiles.checkInFile);
      setCustodyChainFile(sampleFiles.custodyChainFile);
      setInvoiceFile(sampleFiles.invoiceFile);
      setMounted(true);
    }
  }, [
    isMounted,
    sampleFiles,
    setCheckInFile,
    setCustodyChainFile,
    setInvoiceFile,
  ]);

  function handleCheckInFileChange(e: any, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    checkInFile.handleUpload(file, documentType);
  }

  function handleCustodyChainFileChange(e: any, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    custodyChainFile.handleUpload(file, documentType);
  }

  function handleInvoiceFileChange(e: any, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    invoiceFile.handleUpload(file, documentType);
  }

  function onSubmit() {
    if (
      typeof checkInFile.file?.id === 'string' ||
      typeof custodyChainFile.file?.id === 'string' ||
      typeof invoiceFile.file?.id === 'string'
    ) {
      return;
    }

    const input = {
      sampleId,
      checkInDocumentId: checkInFile.file?.id || null,
      custodyChainDocumentId: custodyChainFile.file?.id || null,
      invoiceDocumentId: invoiceFile.file?.id || null,
    };
    updateSampleDocuments(input);
  }

  return (
    <Modal open={isOpen} onClose={onClose} size="tiny">
      <Modal.Header>Documentos</Modal.Header>
      <Modal.Content>
        {sampleId && isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Form.Field>
              <label>
                Registro de Entrada de Amostras de Substância Teste (REA-SST)
              </label>
              <UploadInput
                name="checkIn"
                uploadedFile={checkInFile?.file}
                onUpload={(e) =>
                  handleCheckInFileChange(e, DOCUMENT_TYPE_CHECK_IN)
                }
                onDelete={checkInFile.handleDelete}
              />
            </Form.Field>
            <Form.Field>
              <label>Cadeia de Custódia da Substância Teste (CCST)</label>
              <UploadInput
                name="custodyChain"
                uploadedFile={custodyChainFile?.file}
                onUpload={(e) =>
                  handleCustodyChainFileChange(e, DOCUMENT_TYPE_CUSTODY_CHAIN)
                }
                onDelete={custodyChainFile.handleDelete}
              />
            </Form.Field>
            <Form.Field>
              <label>Nota fiscal</label>
              <UploadInput
                name="invoice"
                uploadedFile={invoiceFile?.file}
                onUpload={(e) =>
                  handleInvoiceFileChange(e, DOCUMENT_TYPE_INVOICE)
                }
                onDelete={invoiceFile.handleDelete}
              />
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
