import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  padding: 32px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  background: #ffffff;
`;

const Header = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

function Section({ children }) {
  return <Container>{children}</Container>;
}

function SectionHeader({ children }) {
  return <Header>{children}</Header>;
}

function SectionContent({ children }) {
  return <Content>{children}</Content>;
}

Section.Header = SectionHeader;
Section.Content = SectionContent;

export default Section;
