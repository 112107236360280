import { Project } from './project.types';

export const PROJECT_STAGE = {
  enabling: 1,
  preparatory: 2,
  deployment: 3,
  evaluation: 4,
  harvest: 5,
  reportWriting: 6,
  customerReview: 7,
  completed: 8,
  canceled: 9,
} as const;

export const PROJECT_GOAL = {
  exploratory: 1,
  oficial: 2,
};

export const MANAGEMENT_TYPE = {
  field: 1,
  greenhouse: 2,
  laboratory: 3,
};

export const TEST_LOCATION = {
  engenheiro: 1,
  conchal: 2,
  external: 3,
};

export const DIVISION = {
  1: {
    description: 'Bioensaio',
    descriptionInt: 'Bioassay',
    managementTypes: [MANAGEMENT_TYPE.greenhouse, MANAGEMENT_TYPE.laboratory],
  },
  2: {
    description: 'Biotec',
    descriptionInt: 'Biotec',
    managementTypes: [MANAGEMENT_TYPE.greenhouse, MANAGEMENT_TYPE.laboratory],
  },
  3: {
    description: 'Coleta',
    descriptionInt: 'Collection',
    managementTypes: [MANAGEMENT_TYPE.field],
  },
  17: {
    description: 'Criações',
    descriptionInt: 'Breeding',
    managementTypes: [MANAGEMENT_TYPE.laboratory],
  },
  15: {
    description: 'Fertilizante',
    descriptionInt: 'Fertilizer',
    managementTypes: [
      MANAGEMENT_TYPE.field,
      MANAGEMENT_TYPE.greenhouse,
      MANAGEMENT_TYPE.laboratory,
    ],
  },
  7: {
    description: 'Fungicida',
    descriptionInt: 'Fungicide',
    managementTypes: [
      MANAGEMENT_TYPE.field,
      MANAGEMENT_TYPE.greenhouse,
      MANAGEMENT_TYPE.laboratory,
    ],
  },
  8: {
    description: 'Herbicida',
    descriptionInt: 'Herbicide',
    managementTypes: [MANAGEMENT_TYPE.field, MANAGEMENT_TYPE.greenhouse],
  },
  9: {
    description: 'Inseticida',
    descriptionInt: 'Insecticide',
    managementTypes: [MANAGEMENT_TYPE.field, MANAGEMENT_TYPE.greenhouse],
  },
  16: {
    description: 'MIP Experience',
    descriptionInt: 'MIP mIPExperience',
    managementTypes: [
      MANAGEMENT_TYPE.field,
      MANAGEMENT_TYPE.greenhouse,
      MANAGEMENT_TYPE.laboratory,
    ],
  },
  12: {
    description: 'Nematologia',
    descriptionInt: 'Nematology',
    managementTypes: [
      MANAGEMENT_TYPE.field,
      MANAGEMENT_TYPE.greenhouse,
      MANAGEMENT_TYPE.laboratory,
    ],
  },
  13: {
    description: 'Projeto especial',
    descriptionInt: 'Projeto specialProject',
    managementTypes: [
      MANAGEMENT_TYPE.field,
      MANAGEMENT_TYPE.greenhouse,
      MANAGEMENT_TYPE.laboratory,
    ],
  },
  14: {
    description: 'Resíduo',
    descriptionInt: 'Residue',
    managementTypes: [MANAGEMENT_TYPE.field],
  },
};

const projectGoalDisplayMap = {
  [PROJECT_GOAL.exploratory]: 'Exploratório',
  [PROJECT_GOAL.oficial]: 'Oficial',
};

export function formatProjectGoal(projectGoal: number) {
  if (!projectGoalDisplayMap[projectGoal]) {
    return '';
  }

  return projectGoalDisplayMap[projectGoal];
}

const managementTypeDisplayMap = {
  [MANAGEMENT_TYPE.field]: 'Campo',
  [MANAGEMENT_TYPE.greenhouse]: 'Casa de vegetação',
  [MANAGEMENT_TYPE.laboratory]: 'Laboratório',
};

export function formatManagementType(managementType: number) {
  if (!managementTypeDisplayMap[managementType]) {
    return '';
  }

  return managementTypeDisplayMap[managementType];
}

const testLocationDisplayMap = {
  [TEST_LOCATION.engenheiro]: 'Engenheiro',
  [TEST_LOCATION.conchal]: 'Conchal',
  [TEST_LOCATION.external]: 'Externo',
};

export function formatTestLocation(testLocation: number) {
  if (!testLocationDisplayMap[testLocation]) {
    return '';
  }

  return testLocationDisplayMap[testLocation];
}

export function isInProgress(project: Project) {
  return (
    project.projectStage === PROJECT_STAGE.preparatory ||
    project.projectStage === PROJECT_STAGE.deployment ||
    project.projectStage === PROJECT_STAGE.evaluation ||
    project.projectStage === PROJECT_STAGE.harvest ||
    project.projectStage === PROJECT_STAGE.reportWriting ||
    project.projectStage === PROJECT_STAGE.customerReview
  );
}

export function canBeUpdatedByUsers(project: Project) {
  return (
    project.projectStage === PROJECT_STAGE.deployment ||
    project.projectStage === PROJECT_STAGE.evaluation ||
    project.projectStage === PROJECT_STAGE.harvest
  );
}

export function getProjectGoal(goal: number) {
  switch (goal) {
    case 1:
      return 'Exploratório';
    case 2:
      return 'Oficial';
    default:
      return '';
  }
}

export function getProjectStage(stage: number) {
  switch (stage) {
    case PROJECT_STAGE.enabling:
      return 'Habilitação';
    case PROJECT_STAGE.preparatory:
      return 'Preparatória';
    case PROJECT_STAGE.deployment:
      return 'Implantação';
    case PROJECT_STAGE.evaluation:
      return 'Avaliação';
    case PROJECT_STAGE.harvest:
      return 'Análise de dados';
    case PROJECT_STAGE.reportWriting:
      return 'Redação de Laudo';
    case PROJECT_STAGE.customerReview:
      return 'Revisão do Cliente';
    case PROJECT_STAGE.completed:
      return 'Concluído';
    case PROJECT_STAGE.canceled:
      return 'Cancelado';
    default:
      return '';
  }
}

export function getManagementType(type: number) {
  switch (type) {
    case 1:
      return 'Campo';
    case 2:
      return 'Casa de vegetação';
    case 3:
      return 'Laboratório';
    default:
      return '';
  }
}

export function getTestLocation(testLocation: number) {
  switch (testLocation) {
    case 1:
      return 'Engenheiro Coelho';
    case 2:
      return 'Conchal';
    case 3:
      return 'Externo';
    default:
      return '';
  }
}

export function getDivision(division: number) {
  switch (division) {
    case 1:
      return 'Bioensaios';
    case 2:
      return 'Biotec';
    case 3:
      return 'Coleta';
    case 7:
      return 'Fungicida';
    case 8:
      return 'Herbicida';
    case 9:
      return 'Inseticida';
    case 12:
      return 'Nematologia';
    case 13:
      return 'Projeto especial';
    case 14:
      return 'Resíduos';
    case 15:
      return 'Fertilizante';
    case 16:
      return 'MIP Experience';
    case 17:
      return 'Criações';
    default:
      return '';
  }
}

export function getProjectStageColor(type: number) {
  switch (type) {
    case 1:
      return 'yellow';
    case 8:
      return 'transparent';
    case 9:
      return 'red';
    default:
      return 'blue';
  }
}
