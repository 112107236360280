import React from 'react';

import toast from 'react-hot-toast';
import { Grid, Header, Button, Icon, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import Info from 'components/data/Info';
import Section from 'components/layout/Section';
import { downloadDocument } from 'hooks/document';
import { useModalWithData } from 'hooks/useModal';
import { LocationQuery, ProjectsQuery } from 'queries/project';
import { formatCpfOrCnpj, formatPhone } from 'util/mask';

import AssignmentAreaGenerationModal from './AssignmentAreaGenerationModal';
import LocationModalForm from './LocationModalForm';

export type LocationSectionProps = {
  project: ProjectsQuery;
  location: LocationQuery;
  refreshProject: () => void;
};

type Info = {
  label: string;
  value: string;
  onClick?: () => void;
};

type InfoMap = Record<string, Info>;

export default function LocationSection({
  project,
  location,
  refreshProject,
}: LocationSectionProps) {
  const locationModal = useModalWithData<number>();
  const assignmentAreaModal = useModalWithData<number>();

  function handleAddClick() {
    locationModal.open();
  }

  function handleEditClick() {
    locationModal.open(location.id);
  }

  async function handleGenerateClick() {
    assignmentAreaModal.open(location.id);
  }

  function handleLocationSuccess(message: string) {
    refreshProject();
    toast.success(message);
    locationModal.close();
  }

  const infoMap: InfoMap = {
    latitude: { label: 'Latitude', value: location?.latitude },
    longitude: { label: 'Longitude', value: location?.longitude },
    altitude: { label: 'Altitude', value: `${location?.altitude} m` },
    accessSketch: {
      label: 'Croqui de acesso',
      value: 'Anexo',
      onClick: () => downloadDocument(location?.accessSketchId),
    },
    location: {
      label: 'Croqui da área',
      value: 'Anexo',
      onClick: () => downloadDocument(location?.locationSketchId),
    },
  };

  return (
    <>
      <Section>
        <Section.Header>
          <Header as="h3" style={{ margin: 0 }}>
            Local de instalação
          </Header>
          <div>
            {location ? (
              <>
                <Button
                  type="button"
                  size="small"
                  onClick={handleGenerateClick}
                  basic
                >
                  <Icon name="download" />
                  Gerar termo de cessão de área
                </Button>
                <Button
                  type="button"
                  size="small"
                  onClick={handleEditClick}
                  basic
                >
                  <Icon name="edit" />
                  Editar
                </Button>
              </>
            ) : null}
          </div>
        </Section.Header>
        <Section.Content>
          {!location ? (
            <EmptyState>
              <EmptyState.Header>Local não adicionado</EmptyState.Header>
              <EmptyState.Description>
                Clique no botão abaixo para adicionar o local da instalação
              </EmptyState.Description>
              <EmptyState.Actions>
                <Button type="button" onClick={handleAddClick} primary basic>
                  <Icon name="add" />
                  Adicionar
                </Button>
              </EmptyState.Actions>
            </EmptyState>
          ) : (
            <Grid>
              <Divider hidden />
              <Grid.Row columns="equal">
                {Object.entries(infoMap).map(([infoMapKey, info]) => (
                  <Grid.Column key={infoMapKey}>
                    <Info>
                      <Info.Label>{info.label}</Info.Label>
                      <Info.Value onClick={info?.onClick}>
                        {info.value}
                      </Info.Value>
                    </Info>
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Divider hidden />
              <Grid.Row columns="equal">
                <Grid.Column>
                  <Label>Propriedade</Label>
                  <Title>{location?.property.name}</Title>
                  <Meta>{location?.property.address} </Meta>
                  <Meta>{location?.property.neighborhood} </Meta>
                  <Meta>
                    {`${location?.property.city} - ${location?.property.state}`}{' '}
                  </Meta>
                  <Meta>{location?.property.cep}</Meta>
                </Grid.Column>
                <Grid.Column>
                  <Label>Proprietário</Label>
                  <Title>{location?.property.owner}</Title>
                  <Meta>
                    {location?.property?.owner &&
                      formatCpfOrCnpj(location?.property?.owner)}
                  </Meta>
                  <Meta>
                    {location?.property?.ownerDocument
                      ? formatCpfOrCnpj(location?.property?.ownerDocument)
                      : 'n/d'}
                  </Meta>
                  <Meta>
                    {location?.property?.phone
                      ? formatPhone(location?.property?.phone, 1)
                      : null}
                  </Meta>
                  <Meta>{location?.property?.ownerAddress}</Meta>
                  <Meta>{location?.property?.ownerNeighborhood}</Meta>
                  <Meta>{location?.property?.ownerCep || 'n/d (CEP)'}</Meta>
                  <Meta>
                    {location?.property?.ownerCity &&
                    location?.property?.ownerState
                      ? `${
                          location?.property?.ownerCity
                        }-${location?.property?.ownerState?.toUpperCase()}`
                      : 'n/d (cidade/UF)'}
                  </Meta>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Section.Content>
      </Section>

      {locationModal.isOpen ? (
        <LocationModalForm
          projectId={project.id}
          locationId={locationModal.data || undefined}
          isOpen={locationModal.isOpen}
          onClose={locationModal.close}
          onSuccess={handleLocationSuccess}
        />
      ) : null}

      {assignmentAreaModal.isOpen && assignmentAreaModal.data ? (
        <AssignmentAreaGenerationModal
          locationId={assignmentAreaModal.data}
          isOpen={assignmentAreaModal.isOpen}
          onClose={assignmentAreaModal.close}
        />
      ) : null}
    </>
  );
}

const Label = styled.div`
  margin-bottom: 8px;
  color: #5d6672;
  font-family: 'Open Sans';
  font-weight: 600;
  text-transform: uppercase;
`;

const Title = styled.div`
  font-size: 16px;
  color: #313131;
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 8px;
`;

const Meta = styled.div`
  color: hsl(214, 7%, 47%);
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 400;
  margin-bottom: 2px;
`;

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
`;
