import React from 'react';

import { DateTime } from 'luxon';
import CurrencyInput from 'react-currency-input';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { Modal, Button, Form } from 'semantic-ui-react';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { projectRepository } from 'container';
import { projectKeys } from 'queries/project';

export default function InstallmentForm({
  installment,
  projectId,
  currency,
  open,
  onClose,
}) {
  const [isMounted, setMounted] = React.useState(false);

  const formRef = React.useRef(null);

  const isEditing = installment != null;

  const queryClient = useQueryClient();
  const { control, handleSubmit, errors, setValue } = useForm({
    mode: 'onBlur',
  });

  React.useEffect(() => {
    function setDefaultValue() {
      setValue(
        'amount',
        installment?.amount ? installment?.amount / 100 : undefined,
      );
      setValue(
        'paymentForecast',
        installment?.paymentForecast
          ? DateTime.fromISO(installment?.paymentForecast).toJSDate()
          : undefined,
      );
    }

    if (isMounted) {
      setDefaultValue();
      setMounted(true);
    }
  }, [setValue, installment, isMounted]);

  const { mutate: createInstallment, isLoading: creating } = useMutation(
    projectRepository.createInstallment,
    {
      onSuccess: () => handleSuccess('Parcela adicionada!'),
    },
  );

  const { mutate: updateInstallment, isLoading: updating } = useMutation(
    projectRepository.updateInstallment,
    {
      onSuccess: () => handleSuccess('Parcela atualizada!'),
    },
  );

  function handleSuccess(message) {
    queryClient.invalidateQueries(projectKeys.all);
    queryClient.invalidateQueries(projectKeys.detail(projectId));
    toast.success(message);
    onClose();
  }

  const loading = creating || updating;

  function onSubmit(data) {
    const updatedData = { ...data, amount: Math.round(data.amount * 100) };

    if (isEditing) {
      updateInstallment({
        ...updatedData,
        projectId: installment.projectId,
        installmentId: installment.id,
      });

      return;
    }

    createInstallment({ ...updatedData, projectId });
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>
        {!isEditing ? 'Nova parcela' : `Parcela ${installment.number}`}
      </Modal.Header>
      <Modal.Content>
        <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Valor</label>
            <Controller
              control={control}
              name="amount"
              render={({ onChange, onBlur, value }) => (
                <CurrencyInput
                  onChange={(_, floatValue) => {
                    onChange(floatValue);
                  }}
                  onBlur={onBlur}
                  value={value}
                  prefix={currency === 2 ? '$' : 'R$'}
                  decimalSeparator={currency === 2 ? '.' : ','}
                  thousandSeparator={currency === 2 ? ',' : '.'}
                  precision="2"
                  /* disabled={isSubmiting} */
                  autoComplete="off"
                />
              )}
            />
            {errors.amount && (
              <InputError>{errors?.amount?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Previsão de faturamento</label>
            <Controller
              control={control}
              name="paymentForecast"
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  /* disabled={isSubmiting} */
                />
              )}
            />
          </Form.Field>
          {errors.paymentForecast && (
            <InputError>Previsão de faturamento é obrigatória</InputError>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form="form" loading={loading} primary>
          {!isEditing ? 'Adicionar' : 'Atualizar'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
