import React from 'react';

import { Message, Loader } from 'semantic-ui-react';

import Navbar from 'components/navigation/Navbar';

export type BaseProps = {
  error?: string;
  loading?: boolean;
  fluid?: boolean;
  text?: boolean;
  children: React.ReactNode;
};

export default function Base({
  error = '',
  loading = false,
  children,
}: BaseProps) {
  return (
    <>
      {error && (
        <Message error>
          <Message.Header>{error}</Message.Header>
        </Message>
      )}
      {loading && !error && (
        <Loader active size="large" style={{ marginTop: '100px' }}>
          Carregando...
        </Loader>
      )}
      {children && !loading && !error && (
        <>
          <Navbar />
          <div style={styles.wrapper}>{children}</div>
        </>
      )}
    </>
  );
}

const styles = {
  wrapper: {
    marginTop: '6.4rem',
  },
  container: {
    backgroundColor: '#ffffff',
    padding: '32px',
    marginTop: '40px',
    minHeight: '700px',
  },
};
