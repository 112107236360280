import React from 'react';

import Select from 'react-select';

import useAsync from 'hooks/useAsync';
import getAllQuotationsUseCase from 'queries/getQuotations';

export default function QuotationSearch({
  name,
  placeholder = 'Busque pelo código...',
  value,
  onChange,
  quotationFilter,
}) {
  const [quotationSearchValue, setQuotationSearchValue] = React.useState('');

  const [{ data, loading, error }, getAllQuotations] = useAsync(
    getAllQuotationsUseCase,
  );

  React.useEffect(() => {
    getAllQuotations({
      ...quotationFilter,
      code: quotationSearchValue,
    });
  }, [getAllQuotations, quotationSearchValue, quotationFilter]);

  const quotations = error || !data || !data.data ? [] : data.data;

  const options = quotations?.map((crop) => ({
    value: crop.id,
    label: crop.code,
  }));

  const handleCropInputChange = (inputValue) => {
    setQuotationSearchValue(inputValue);
  };

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      inputValue={quotationSearchValue}
      onInputChange={handleCropInputChange}
      options={options}
      isLoading={loading}
      isClearable
    />
  );
}
