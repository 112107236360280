import React from 'react';

import { Control, Controller, useForm } from 'react-hook-form';
import { OptionProps, ValueType } from 'react-select/src/types';
import { Button, Divider, Form, Grid, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Text from 'components/foundation/Text';
import CropSearch from 'components/inputs/CropSearch';
import DivisionSelect from 'components/inputs/DivisionSelect';
import LinkButton from 'components/inputs/LinkButton';
import TargetSearch from 'components/inputs/TargetSearch';
import Section from 'components/layout/Section';
import { SaleServiceQuery } from 'data/sale-service';
import { Pagination } from 'util/api';
import { Currencies, formatWithSymbol } from 'util/Currency';

import { useSaleServiceFilter } from './use-sale-service-filter';

type FilteredSaleServiceTableProps = {
  fetchNextPage: () => void;
  hasNextPage: boolean | undefined;
  isFetchingNextPage: boolean;
  isLoading: boolean;
  onEditClick: (saleServiceId: number) => void;
  pagination: Pagination;
  saleServices: SaleServiceQuery[];
  showActions?: boolean;
};

type Option = {
  label: string;
  value: number;
};

export default function FilteredSaleServiceTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading,
  onEditClick,
  pagination,
  saleServices = [],
  showActions = true,
}: FilteredSaleServiceTableProps) {
  const { filter, updateFilter } = useSaleServiceFilter();

  const { control } = useForm({ defaultValues: filter });

  function handleSelectChange(name: string, value: ValueType<Option>) {
    updateFilter({ [name]: value });
  }

  const isEmpty = !isLoading && !saleServices.length;

  return (
    <>
      <SaleServiceFilter
        control={control}
        onSelectChange={handleSelectChange}
      />
      <Section>
        <Section.Header>
          <PaginationInfo description="Serviços" pagination={pagination} />
        </Section.Header>
        <Section.Content>
          {!isEmpty ? (
            <>
              <Table size="small" basic="very" singleLine unstackable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Setor</Table.HeaderCell>
                    <Table.HeaderCell>Cultura</Table.HeaderCell>
                    <Table.HeaderCell>Alvo</Table.HeaderCell>
                    <Table.HeaderCell>Preço tratamento</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center" />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {isLoading ? <LoadingRow columns={4} rows={10} /> : null}
                  {!isLoading &&
                    saleServices.map(
                      ({ id, crop, division, target, treatmentPrice }) => {
                        const treatmentPriceFormatted = formatWithSymbol({
                          amount: treatmentPrice,
                          currency: Currencies.REAL,
                        });
                        return (
                          <Table.Row key={id}>
                            <Table.Cell>{division.description}</Table.Cell>
                            <Table.Cell>{crop.name}</Table.Cell>
                            <Table.Cell>{target.name}</Table.Cell>
                            <Table.Cell>{treatmentPriceFormatted}</Table.Cell>
                            <Table.Cell textAlign="center">
                              {showActions ? (
                                <LinkButton onClick={() => onEditClick(id)}>
                                  Editar
                                </LinkButton>
                              ) : null}
                            </Table.Cell>
                          </Table.Row>
                        );
                      },
                    )}
                </Table.Body>
              </Table>
              <Divider hidden />
              {hasNextPage ? (
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Button
                        type="button"
                        onClick={() => fetchNextPage()}
                        disabled={!hasNextPage || isFetchingNextPage}
                      >
                        {isFetchingNextPage ? 'Carregando...' : ''}
                        {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : (
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Text variant="secondary">Nada mais para mostrar</Text>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </>
          ) : (
            <EmptyState>
              <EmptyState.Header>Nenhum serviço encontrado</EmptyState.Header>
            </EmptyState>
          )}
        </Section.Content>
      </Section>
    </>
  );
}

type SaleServiceFilterProps = {
  control: Control;
  onSelectChange: (name: string, value: ValueType<Option>) => void;
};

function SaleServiceFilter({
  control,
  onSelectChange,
}: SaleServiceFilterProps) {
  return (
    <Form>
      <Form.Group>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="division"
            render={({ onChange, onBlur, value }) => (
              <DivisionSelect
                isClearable
                name="division"
                onBlur={onBlur}
                onChange={(data: OptionProps) => {
                  onSelectChange('division', data || '');
                  onChange(data);
                }}
                placeholder="Setor"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="crop"
            render={({ onChange, onBlur, value }) => (
              <CropSearch
                isClearable
                isMulti={false}
                name="crop"
                onBlur={onBlur}
                onChange={(data) => {
                  onSelectChange('crop', data);
                  onChange(data);
                }}
                placeholder="Cultura"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={5}>
          <Controller
            control={control}
            defaultValue=""
            name="target"
            render={({ onChange, onBlur, value }) => (
              <TargetSearch
                isClearable
                isMulti={false}
                name="target"
                onBlur={onBlur}
                onChange={(data) => {
                  onSelectChange('target', data);
                  onChange(data);
                }}
                placeholder="Alvo"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
