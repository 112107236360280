import React from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Form, Modal } from 'semantic-ui-react';

import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import { useGenerateQuotationDocument } from 'data/quotation';

const MESSAGES = {
  loading: 'Gerando PDF...',
  success: 'PDF gerado!',
  error: 'Não foi possível gerar o PDF. Tente novamente.',
};

export default function GenerateQuotationDocumentModal({
  isOpen,
  onClose,
  quotationId,
}) {
  const generateQuotationDocument = useGenerateQuotationDocument();

  const { register, watch } = useForm({
    mode: 'onBlur',
    defaultValues: { locale: 'pt-BR' },
  });
  const watchLocale = watch('locale');

  function handleConfirmClick() {
    const locale = watchLocale;
    const params = { quotationId, locale };

    const promise = generateQuotationDocument.mutateAsync(params);
    toast.promise(promise, MESSAGES);

    onClose();
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Gerar PDF do orçamento</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Idioma</label>
            <RadioGroup>
              <RadioInput
                name="locale"
                label="Português"
                value="pt-BR"
                ref={register}
                defaultChecked
              />
              <RadioInput
                name="locale"
                label="Inglês"
                value="en-US"
                ref={register}
              />
            </RadioGroup>
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button type="button" onClick={handleConfirmClick} primary>
          Gerar PDF
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
