import * as React from 'react';

import toast from 'react-hot-toast';
import { Button, Dimmer, Form, Loader, Modal } from 'semantic-ui-react';

import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useSendToCustomerReview } from 'mutations/project';
import { getDocumentInput } from 'queries/document';
import { DOCUMENT_REPORTREVIEW } from 'util/Document';

type FileEvent = React.ChangeEvent<HTMLInputElement>;

export type SendToCustomerReviewModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
  reportReviewId?: number | null;
};

const formId = 'form_send_to_customer_review';

export default function SendToCustomerReviewModal({
  isOpen,
  onClose,
  projectId,
  reportReviewId,
}: SendToCustomerReviewModalProps) {
  const [isLoading, setLoading] = React.useState(false);
  const documentFile = useUpload();

  const {
    mutate: sendToCustomerReview,
    isLoading: isSubmitting,
  } = useSendToCustomerReview({
    onSuccess: () => handleSuccess(),
  });

  const { setFile } = documentFile;

  React.useEffect(() => {
    async function getFileInput() {
      if (reportReviewId) {
        try {
          setLoading(true);
          const fileInput = await getDocumentInput(reportReviewId);
          setFile(fileInput);
          setLoading(false);
        } catch (e) {
          toast.error('Não foi possível carregar o laudo de revisão');
          setLoading(false);
        }
      }
      setLoading(false);
    }

    getFileInput();
  }, [reportReviewId, setFile]);

  function handleSuccess() {
    toast.success('Enviado para revisão do cliente!');
    onClose();
  }

  function onSubmit() {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }
    const input = {
      projectId,
      reportReviewId: documentFile.file?.id,
    };
    sendToCustomerReview(input);
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }
  const disabledButton = !documentFile.file?.url;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Enviar para revisão do cliente</Modal.Header>
      <Modal.Content>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} onSubmit={onSubmit}>
            <Form.Field>
              <label>Laudo de revisão</label>
              <UploadInput
                name="reportReviewId"
                uploadedFile={documentFile?.file}
                onUpload={(e: FileEvent) =>
                  handleDocumentFileChange(e, DOCUMENT_REPORTREVIEW)
                }
                onDelete={documentFile.handleDelete}
              />
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || disabledButton}
          primary
        >
          Enviar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
