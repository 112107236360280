import React from 'react';

import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import { getProjectStageColor } from 'data/project';

export default function ContractProjectTable({ isLoading, projects = [] }) {
  return (
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Cód. interno</Table.HeaderCell>
          <Table.HeaderCell>Safra</Table.HeaderCell>
          <Table.HeaderCell>Finalidade</Table.HeaderCell>
          <Table.HeaderCell>Setor</Table.HeaderCell>
          <Table.HeaderCell>Fase</Table.HeaderCell>
          <Table.HeaderCell>Atualizado em</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isLoading && <LoadingRow columns={6} rows={5} />}
        {!isLoading &&
          projects?.map(
            ({
              id,
              insideCode,
              protocol,
              harvest,
              division,
              responsibleTechnician,
              projectGoal,
              projectStageId,
              projectStage,
              updatedAt,
            }) => (
              <Table.Row key={id}>
                <Table.Cell>
                  <Text variant="primary">
                    <Link to={`/estudos/${id}`} name="project-details">
                      {insideCode || 'Sem código'}
                    </Link>
                  </Text>
                  <Text variant="secondary">{protocol}</Text>
                </Table.Cell>
                <Table.Cell>
                  <Text variant="primary">{harvest}</Text>
                </Table.Cell>
                <Table.Cell>{projectGoal}</Table.Cell>
                <Table.Cell>
                  <Text variant="primary">{division}</Text>
                  <Text variant="secondary">{responsibleTechnician.name}</Text>
                </Table.Cell>
                <Table.Cell>
                  <Tag color={getProjectStageColor(projectStageId)}>
                    {projectStage}
                  </Tag>
                </Table.Cell>
                <Table.Cell>
                  {DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}
                </Table.Cell>
              </Table.Row>
            ),
          )}
      </Table.Body>
    </Table>
  );
}
