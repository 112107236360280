import * as React from 'react';

import { Controller, useFieldArray } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Form } from 'semantic-ui-react';

import HiddenLabel from 'components/inputs/HiddenLabel';
import IngredientSearch from 'components/inputs/IngredientSearchV2';
import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelectV2';
import { isUntreated } from 'queries/treatment';

const IngredientsArray = React.forwardRef(
  ({ nestIndex, treatmentType, control, errors }, ref) => {
    const { fields, remove, append } = useFieldArray({
      control,
      name: `sampleDoses[${nestIndex}].ingredientDoses`,
    });

    React.useImperativeHandle(ref, () => {
      return {
        append,
        remove,
      };
    });

    return (
      <div>
        {fields.map((item, k) => {
          const isFirst = k === 0;
          return (
            <Form.Group key={item.id}>
              <Form.Field width={8}>
                {isFirst ? <label>Ingredientes:</label> : null}
                <Controller
                  control={control}
                  name={`sampleDoses[${nestIndex}].ingredientDoses[${k}].ingredient`}
                  defaultValue={item.ingredient}
                  render={({ onChange, onBlur, value }) => (
                    <IngredientSearch
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      autoComplete="off"
                      isClearable
                      disabled
                    />
                  )}
                />
              </Form.Field>
              <Form.Field width={3}>
                {isFirst ? <HiddenLabel /> : null}
                <Controller
                  control={control}
                  name={`sampleDoses[${nestIndex}].ingredientDoses[${k}].quantity`}
                  defaultValue={item.quantity}
                  render={({ onChange, onBlur, value }) => (
                    <NumberFormat
                      onValueChange={(data) => {
                        onChange(data.floatValue);
                      }}
                      onBlur={onBlur}
                      value={value}
                      placeholder="0,00"
                      decimalSeparator=","
                      thousandSeparator="."
                      decimalScale={3}
                      disabled={isUntreated(parseInt(treatmentType, 10))}
                      autoComplete="off"
                    />
                  )}
                />
                {errors?.ingredientDoses ? (
                  <InputError>
                    {errors?.ingredientDoses[k]?.quantity?.message}
                  </InputError>
                ) : null}
              </Form.Field>
              <Form.Field width={5}>
                {isFirst ? <HiddenLabel /> : null}
                <Controller
                  control={control}
                  name={`sampleDoses[${nestIndex}].ingredientDoses[${k}].measurementUnit`}
                  defaultValue={item.measurementUnit || ''}
                  render={({ onChange, onBlur, value }) => (
                    <MeasurementUnitSelect
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isUntreated(parseInt(treatmentType, 10))}
                      autoComplete="off"
                      isClearable
                    />
                  )}
                />
                {errors?.ingredientDoses ? (
                  <InputError>
                    {errors?.ingredientDoses[k]?.measurementUnit?.message}
                  </InputError>
                ) : null}
              </Form.Field>
            </Form.Group>
          );
        })}
      </div>
    );
  },
);

export default IngredientsArray;
