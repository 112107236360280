import * as Yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

const required = 'é um campo obrigatório';

export const schema = Yup.object().shape({
  description: Yup.string()
    .optional()
    .nullable()
    .max(1500, 'Número máximo de caracteres é 1500'),
  protocol: Yup.string()
    .nullable()
    .optional(),
  projectGoal: Yup.string().required(`Finalidade ${required}`),
  managementType: Yup.string().required(`Tipo de condução ${required}`),
  testLocation: Yup.string().required(`Unidade teste ${required}`),
  division: Yup.object()
    .required(`Centro de custo ${required}`)
    .transform(emptyStringToUndefined),
  durationForecast: Yup.number()
    .required(`Previsão de condução ${required}`)
    .transform(emptyStringToUndefined),
  amount: Yup.number()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  treatmentQuantity: Yup.number()
    .required(`Nº de tratamentos ${required}`)
    .typeError(`Nº de tratamentos deve ser um número`)
    .positive(`Nº de tratamentos deve ser positivo`)
    .integer(`Nº de tratamentos deve ser inteiro`),
  trialsNumber: Yup.number()
    .typeError(`Nº de repetições deve ser um número`)
    .positive(`Nº de repetições deve ser positivo`)
    .transform(emptyStringToUndefined)
    .integer(`Nº de repetições deve ser inteiro`)
    .nullable(),
});

export const schemaWithProjectsNumber = schema.shape({
  projectsNumber: Yup.number()
    .required(`Nº de ensaios ${required}`)
    .typeError(`Nº de ensaios deve ser um número`)
    .positive(`Nº de ensaios deve ser positivo`)
    .integer(`Nº de ensaios deve ser inteiro`)
    .min(1, `Nº de ensaios deve ser maior que 0`)
    .max(50, `Nº de ensaios deve ser menor ou igual a 50`),
});
