import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';

import api, { emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

export const collectionKeys = {
  all: ['collections'] as const,
};

export function useExportCollections(
  options?: UseMutationOptions<void, AxiosError, void, () => void>,
) {
  return useMutation(exportCollections, {
    ...options,
  });
}

const exportCollections = async () => {
  const { data: file, headers } = await api.post(
    `/collection/collections/report`,
    {},
    {
      responseType: 'blob',
    },
  );

  const { filename } = headers;

  downloadFile(file, filename);
};

type CollectionsFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

export function useInfiniteCollections(filter: CollectionsFilter) {
  const result = useInfiniteQuery(
    [...collectionKeys.all, filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getCollections({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const collections =
    result?.data?.pages
      ?.map((group) => group?.data?.map((project) => project))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    collections,
  };
}

type CollectionSorting = {
  id: number;
  arrivalDate: string;
  collectionId: number;
  createdAt: string;
  deadInsects: number;
  liveInsects: number;
  notes: string | null;
  parasitizedInsects: number;
  sortingDate: string;
  updatedAt: string;
};

export type CollectionQuery = {
  id: number;
  target: string;
  crop: string;
  stage: string;
  period: string;
  startDate: string;
  endDate: string;
  region: string;
  status: string;
  statusColor: string;
  userId: number;
  totalCollected: number;
  collector: string;
  totalAssociated: number;
  sorting: CollectionSorting | null;
};

async function getCollections(params: CollectionsFilter) {
  const { data } = await api.get<Response<CollectionQuery>>(
    `/collection/collections`,
    { params },
  );
  return data;
}

type SortCollectionInput = {
  arrivalDate: Date;
  sortingDate: Date;
  liveInsects: number;
  deadInsects: number;
  parasitizedInsects: number;
  collectionId: number;
  notes?: string | null;
};

type UseSortCollectionOptions = UseMutationOptions<
  void,
  AxiosError,
  SortCollectionInput,
  () => void
>;

export function useSortCollection(options?: UseSortCollectionOptions) {
  const queryClient = useQueryClient();
  return useMutation(sortCollection, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(collectionKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const sortCollection = async ({
  collectionId,
  ...input
}: SortCollectionInput) => {
  const url = `/collection/collections/${collectionId}/sortings`;
  await api.post(url, input);
};

type AssociateProjectsInput = {
  collectionId: number;
  associations: {
    projectId: number;
    amount: number;
  };
};

type UseAssociateProjectsOptions = UseMutationOptions<
  void,
  AxiosError,
  AssociateProjectsInput,
  () => void
>;

export function useAssociateProjects(options?: UseAssociateProjectsOptions) {
  const queryClient = useQueryClient();
  return useMutation(associateProjects, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(collectionKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const associateProjects = async ({
  collectionId,
  ...input
}: AssociateProjectsInput) => {
  const url = `/collection/collections/${collectionId}/associations`;
  await api.post(url, input);
};
