import React from 'react';

import { Modal, Button } from 'semantic-ui-react';

import useAsync from 'hooks/useAsync';
import cancelProjectUseCase from 'mutations/cancelProject';

export default function CancelProjectModal({
  projectId,
  open,
  onClose,
  onFailure,
  onSuccess,
}) {
  const [
    { data: updated, error: updateError, loading },
    cancelProject,
  ] = useAsync(cancelProjectUseCase);

  React.useEffect(() => {
    const handleUpdateResult = () => {
      if (updated) {
        onSuccess();
      }

      if (updateError) {
        onFailure();
      }
    };

    handleUpdateResult();
  }, [updated, updateError]);

  function submit() {
    cancelProject(projectId);
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Cancelar estudo?</Modal.Header>
      <Modal.Content>
        Tem certeza que deseja cancelar o estudo? Esta operação não pode ser
        desfeita.
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>Sair</Button>
        <Button onClick={() => submit()} loading={loading} negative>
          Cancelar estudo
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
