import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import api, { emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

export type SaleProductQuery = {
  code: string;
  description: string;
  id: number;
  measurementUnit: string;
  measurementUnitId: number;
  ncmCode: string;
  ncmId: number;
  unitPrice: number;
};

type SaleProductFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  description?: string;
};

const saleProductsKeys = {
  all: ['saleProducts'],
} as const;

export function useInfiniteSaleProducts(filter: SaleProductFilter) {
  const result = useInfiniteQuery(
    [...saleProductsKeys.all, 'infinite', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getSaleProducts({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const saleProducts =
    result?.data?.pages
      ?.map((group) => group?.data?.map((saleProduct) => saleProduct))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    saleProducts,
  };
}

export function useSaleProducts(filter: SaleProductFilter) {
  return useQuery(['saleProducts', filter], () =>
    getSaleProducts({ orderBy: 'description', sort: 'asc', ...filter }),
  );
}

async function getSaleProducts(params: SaleProductFilter) {
  const { data } = await api.get<Response<SaleProductQuery>>(
    `/admin/sale-products`,
    { params },
  );
  return {
    data: data.data,
    pagination: data.pagination,
  };
}

type CreateSaleProductInput = {
  description: string;
  measurementUnitId: number;
  ncmId: number;
  unitPrice: number;
};

type UseCreateSaleProductOptions = UseMutationOptions<
  number,
  AxiosError,
  CreateSaleProductInput,
  () => void
>;

export function useCreateSaleProduct(options?: UseCreateSaleProductOptions) {
  const queryClient = useQueryClient();
  return useMutation(createSaleProduct, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(saleProductsKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const createSaleProduct = async (input: CreateSaleProductInput) => {
  const { headers } = await api.post(`/admin/sale-products`, input);
  const { location } = headers;
  const saleProductId = parseInt(location.split('/').pop(), 10);
  return saleProductId;
};

type UpdateSaleProductInput = CreateSaleProductInput & {
  saleProductId: number;
};

type UseUpdateSaleProductOptions = UseMutationOptions<
  void,
  AxiosError,
  UpdateSaleProductInput
>;

export function useUpdateSaleProduct(options?: UseUpdateSaleProductOptions) {
  const queryClient = useQueryClient();
  return useMutation(updateSaleProduct, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(saleProductsKeys.all);
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const updateSaleProduct = async ({
  saleProductId,
  ...input
}: UpdateSaleProductInput) => {
  await api.patch(`/admin/sale-products/${saleProductId}`, input);
};

type ExportSaleProductsInput = SaleProductFilter & {
  fileFormat: 'pdf' | 'csv';
};

const CONFIG = { responseType: 'blob' } as const;

export function useExportSaleProducts(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ExportSaleProductsInput,
    () => void
  >,
) {
  return useMutation(exportSaleProducts, {
    ...options,
  });
}

async function exportSaleProducts(params: ExportSaleProductsInput) {
  const url = `/admin/sale-products/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;

  downloadFile(file, filename);
}
