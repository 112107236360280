import { array, number, object, string } from 'yup';

const sampleDoseSchema = object()
  .shape({
    sample: object()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    quantity: number()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    measurementUnit: object()
      .required('Obrigatório')
      .typeError('Obrigatório'),
  })
  .required();

const ingredientDoseSchema = object()
  .shape(
    {
      ingredient: object()
        .optional()
        .nullable(),
      quantity: number()
        .optional()
        .nullable(),
    },
    [['quantity']],
  )
  .required();

const schema = object().shape({
  treatmentType: string().required(),
  sampleDoses: array()
    .of(object().shape({}))
    .when('treatmentType', {
      is: (val) => val === '1',
      then: array()
        .of(sampleDoseSchema)
        .required('Obrigatório'),
      otherwise: array()
        .of(
          object()
            .shape({})
            .notRequired(),
        )
        .notRequired()
        .optional()
        .nullable(),
    }),
  ingredientDoses: array()
    .of(
      object()
        .shape({})
        .notRequired(),
    )
    .when('treatmentType', {
      is: (val) => val === '1',
      then: array()
        .of(ingredientDoseSchema)
        .notRequired(),
      otherwise: array()
        .of(
          object()
            .shape({})
            .notRequired(),
        )
        .notRequired()
        .optional()
        .nullable(),
    }),
});

export default schema;
