import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import {
  Button,
  Form,
  Header,
  Input,
  Checkbox,
  Divider,
  Grid,
  Icon,
} from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import ProductIngredientTable from 'components/data/ProductIngredientTable';
import DatePicker from 'components/inputs/date-picker';
import FormulationSelect from 'components/inputs/FormulationSelect';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import ProductCategorySelect from 'components/inputs/ProductCategorySelect';
import ProductTypeSelect from 'components/inputs/ProductTypeSelect';
import ProjectSearch from 'components/inputs/ProjectSearch';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import RetStageSelect from 'components/inputs/RetStageSelect';
import UploadInput from 'components/inputs/UploadInput';
import AddProductIngredientModal from 'components/modal/AddProductIngredientModal';
import { useUpload } from 'hooks/use-upload';
import { formatSampleUnit, SampleUnit } from 'queries/sample';

import schema from './validation';

const DOCUMENT_TYPE_CHECK_IN = 6;
const DOCUMENT_TYPE_CUSTODY_CHAIN = 7;
const DOCUMENT_TYPE_INVOICE = 8;

export default function SampleForm({ onSubmit, onCancelClick, isLoading }) {
  const [hasManufacturingDay, setManufacturingDay] = useState(false);
  const [hasExpirationDay, setExpirationDay] = useState(false);
  const modalRef = React.useRef(null);

  function handleModalOpen() {
    modalRef.current.openModal();
  }

  const resolver = yupResolver(schema);
  const { control, errors, register, watch, handleSubmit } = useForm({
    defaultValues: { associatedProjects: [{}], packagings: [{ number: 1 }] },
    resolver,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'associatedProjects',
  });
  const ingredientsArray = useFieldArray({
    control,
    name: 'ingredients',
  });
  const packagingArray = useFieldArray({
    control,
    name: 'packagings',
  });

  const watchIngredients = watch('ingredients');
  const watchMeasurementUnit = watch('measurementUnit');

  const checkInFile = useUpload();
  const custodyChainFile = useUpload();
  const invoiceFile = useUpload();

  function handleCheckInFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    checkInFile.handleUpload(file, documentType);
  }

  function handleCustodyChainFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    custodyChainFile.handleUpload(file, documentType);
  }

  function handleInvoiceFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    invoiceFile.handleUpload(file, documentType);
  }

  function handleIngredientSubmit(values) {
    const productIngredient = {
      ...values,
      ingredient: {
        id: values.ingredient.value,
        name: values.ingredient.label,
      },
      concentration: values.concentration || null,
      measurementUnit: values.measurementUnit?.id || null,
    };

    ingredientsArray.append(productIngredient);
    handleModalOpen();
  }

  function handleRemoveIngredientClick(index) {
    ingredientsArray.remove(index);
  }

  function submit(values) {
    const { manufacturingDate, expirationDate, ...updatedValues } = values;

    const finalData = {
      ...updatedValues,
      manufacturingDate: manufacturingDate || null,
      hasManufacturingDay,
      expirationDate: expirationDate || null,
      hasExpirationDay,
      ingredients: watchIngredients,
      checkInDocumentId:
        typeof checkInFile.file?.id === 'number'
          ? checkInFile.file?.id
          : undefined,
      custodyChainDocumentId:
        typeof custodyChainFile.file?.id === 'number'
          ? custodyChainFile.file?.id
          : undefined,
      invoiceDocumentId:
        typeof invoiceFile.file?.id === 'number'
          ? invoiceFile.file?.id
          : undefined,
    };

    onSubmit(finalData);
  }

  return (
    <>
      <Form onSubmit={handleSubmit(submit)}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Tipo de amostra</Header>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Field>
                <label>Tipo</label>
                <RadioGroup>
                  <RadioInput
                    name="sampleType"
                    label="Produto"
                    value={1}
                    ref={register}
                    defaultChecked
                  />
                  <RadioInput
                    name="sampleType"
                    label="Semente"
                    value={2}
                    ref={register}
                  />
                </RadioGroup>
              </Form.Field>
              <Divider hidden />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Identificação</Header>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Lote</label>
                  <Controller
                    control={control}
                    name="batchNumber"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.batchNumber && (
                    <InputError>{errors?.batchNumber?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Data de entrada</label>
                  <Controller
                    control={control}
                    name="arrivalDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={isLoading}
                      />
                    )}
                  />
                  {errors.arrivalDate && (
                    <InputError>{errors?.arrivalDate?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <Divider hidden />
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Fabricação (opcional)</label>
                  <Controller
                    control={control}
                    name="manufacturingDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        placeholderText={
                          hasManufacturingDay ? 'dd/mm/aaaa' : 'mm/aaaa'
                        }
                        dateFormat={
                          hasManufacturingDay ? 'dd/MM/yyyy' : 'MM/yyyy'
                        }
                        showMonthYearPicker={!hasManufacturingDay}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={isLoading}
                      />
                    )}
                  />
                  {errors.manufacturingDate && (
                    <InputError>
                      {errors?.manufacturingDate?.message}
                    </InputError>
                  )}
                  <Checkbox
                    label="dd/mm/aaaa"
                    onChange={() => setManufacturingDay(!hasManufacturingDay)}
                    style={{ marginTop: '8px' }}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Validade (opcional)</label>
                  <Controller
                    control={control}
                    name="expirationDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        placeholderText={
                          hasExpirationDay ? 'dd/mm/aaaa' : 'mm/aaaa'
                        }
                        dateFormat={hasExpirationDay ? 'dd/MM/yyyy' : 'MM/yyyy'}
                        showMonthYearPicker={!hasExpirationDay}
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={isLoading}
                      />
                    )}
                  />
                  {errors.expirationDate && (
                    <InputError>{errors?.expirationDate?.message}</InputError>
                  )}
                  <Checkbox
                    label="dd/mm/aaaa"
                    onChange={() => setExpirationDay(!hasExpirationDay)}
                    style={{ marginTop: '8px' }}
                  />
                </Form.Field>
                <Form.Field />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Produto</Header>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Nome do produto</label>
                  <Controller
                    control={control}
                    name="productName"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.productName && (
                    <InputError>{errors?.productName?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Tipo</label>
                  <Controller
                    control={control}
                    name="productType"
                    render={({ onChange, onBlur, value }) => (
                      <ProductTypeSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.productType && (
                    <InputError>{errors?.productType?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Classe</label>
                  <Controller
                    control={control}
                    name="productCategory"
                    render={({ onChange, onBlur, value }) => (
                      <ProductCategorySelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.productCategory && (
                    <InputError>{errors?.productCategory?.message}</InputError>
                  )}
                </Form.Field>
              </Form.Group>
              <Divider hidden />
              <Form.Group>
                <Form.Field width={11}>
                  <label>Tipo de formulação</label>
                  <Controller
                    control={control}
                    name="formulation"
                    render={({ onChange, onBlur, value }) => (
                      <FormulationSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.formulation && (
                    <InputError>{errors?.formulation?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={5}>
                  <label>Registro MAPA (opcional)</label>
                  <Controller
                    control={control}
                    name="mapaRegister"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.mapaRegister && (
                    <InputError>{errors?.mapaRegister?.message}</InputError>
                  )}
                </Form.Field>
              </Form.Group>
              <Divider hidden />
              {watchIngredients.length === 0 ? (
                <EmptyState>
                  <EmptyState.Header>
                    Nenhum ingrediente adicionado
                  </EmptyState.Header>
                  <EmptyState.Description>
                    Clique no botão abaixo para adicionar um ingrediente
                  </EmptyState.Description>
                  <EmptyState.Actions>
                    <Button
                      type="button"
                      onClick={handleModalOpen}
                      primary
                      basic
                    >
                      <Icon name="add" />
                      Adicionar
                    </Button>
                  </EmptyState.Actions>
                </EmptyState>
              ) : (
                <>
                  <Header as="h4">Ingredientes:</Header>
                  <ProductIngredientTable
                    productIngredients={ingredientsArray.fields}
                    onRemoveClick={handleRemoveIngredientClick}
                  />
                  <Button type="button" onClick={handleModalOpen} primary basic>
                    <Icon name="add" />
                    Adicionar
                  </Button>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">RET</Header>
              <HelpText>
                As informações do RET são obrigatórias para produto teste
              </HelpText>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>N° do RET</label>
                  <Controller
                    control={control}
                    name="ret"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.ret && (
                    <InputError>{errors?.ret?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>Fase</label>
                  <Controller
                    control={control}
                    name="retStage"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <RetStageSelect
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        autoComplete="off"
                      />
                    )}
                  />
                  {errors.retStage && (
                    <InputError>{errors?.retStage?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field />
              </Form.Group>
              <Divider hidden />
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Data de validade</label>
                  <Controller
                    control={control}
                    name="retExpirationDate"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                      />
                    )}
                  />
                  {errors.retExpirationDate && (
                    <InputError>
                      {errors?.retExpirationDate?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field />
                <Form.Field />
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Embalagens</Header>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Field>
                <label>Unidade de medida</label>
                <RadioGroup>
                  <RadioInput
                    name="measurementUnit"
                    label="Grama"
                    value={SampleUnit.Gram}
                    ref={register}
                    defaultChecked
                  />
                  <RadioInput
                    name="measurementUnit"
                    label="Quilograma"
                    value={SampleUnit.Kilogram}
                    ref={register}
                  />
                  <RadioInput
                    name="measurementUnit"
                    label="Mililitro"
                    value={SampleUnit.Milliliter}
                    ref={register}
                  />
                  <RadioInput
                    name="measurementUnit"
                    label="Litro"
                    value={SampleUnit.Liter}
                    ref={register}
                  />
                </RadioGroup>
                {errors.measurementUnit && (
                  <InputError>{errors?.measurementUnit?.message}</InputError>
                )}
              </Form.Field>
              <Divider hidden />
              <Form.Field>
                <label>Embalagens:</label>
              </Form.Field>
              {packagingArray.fields.map((item, index) => {
                const isFirst = index === 0;
                const number = index + 1;
                return (
                  <Form.Group key={item.id}>
                    <Form.Field width={2}>
                      {isFirst ? <label>N°</label> : null}
                      <Controller
                        control={control}
                        name={`packagings[${index}].number`}
                        defaultValue={number}
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            autoComplete="off"
                            disabled
                            fluid
                          />
                        )}
                      />
                    </Form.Field>
                    <Form.Field width={6}>
                      {isFirst ? <label>Quantidade</label> : null}
                      <Controller
                        control={control}
                        name={`packagings[${index}].volume`}
                        defaultValue={item.packaging}
                        render={({ onChange, onBlur, value }) => (
                          <NumberFormat
                            onValueChange={(data) => {
                              onChange(data.floatValue);
                            }}
                            onBlur={onBlur}
                            value={value}
                            thousandSeparator="."
                            decimalSeparator=","
                            decimalScale={3}
                            suffix={
                              watchMeasurementUnit
                                ? ` ${formatSampleUnit(
                                    parseInt(watchMeasurementUnit, 10),
                                  )}`
                                : ''
                            }
                            disabled={isLoading}
                            autoComplete="off"
                          />
                        )}
                      />
                      {errors?.packagings ? (
                        <InputError>
                          {errors?.packagings[index]?.volume?.message}
                        </InputError>
                      ) : null}
                    </Form.Field>
                    <Form.Field width={1}>
                      {isFirst ? <HiddenLabel /> : null}
                      <Button
                        type="button"
                        onClick={() => packagingArray.remove(index)}
                        disabled={isFirst}
                        basic
                        icon
                      >
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  </Form.Group>
                );
              })}
              <Form.Group>
                <Form.Field width={2} />
                <Form.Field width={6}>
                  <Button
                    type="button"
                    size="small"
                    onClick={() => packagingArray.append({})}
                    primary
                    basic
                    fluid
                  >
                    <Icon name="add" />
                    Adicionar
                  </Button>
                </Form.Field>
              </Form.Group>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Documentos</Header>
              <HelpText>
                São opcionais agora, mas devem ser incluídos posteriormente
              </HelpText>
            </Grid.Column>
            <Grid.Column width={10}>
              <Form.Field>
                <label>
                  Registro de Entrada de Amostras de Substância Teste (REA-SST)
                </label>
                <UploadInput
                  name="checkIn"
                  uploadedFile={checkInFile?.file}
                  onUpload={(e) =>
                    handleCheckInFileChange(e, DOCUMENT_TYPE_CHECK_IN)
                  }
                  onDelete={checkInFile.handleDelete}
                />
              </Form.Field>
              <Form.Field>
                <label>Cadeia de Custódia da Substância Teste (CCST)</label>
                <UploadInput
                  name="custodyChain"
                  uploadedFile={custodyChainFile?.file}
                  onUpload={(e) =>
                    handleCustodyChainFileChange(e, DOCUMENT_TYPE_CUSTODY_CHAIN)
                  }
                  onDelete={custodyChainFile.handleDelete}
                />
              </Form.Field>
              <Form.Field>
                <label>Nota fiscal</label>
                <UploadInput
                  name="invoice"
                  uploadedFile={invoiceFile?.file}
                  onUpload={(e) =>
                    handleInvoiceFileChange(e, DOCUMENT_TYPE_INVOICE)
                  }
                  onDelete={invoiceFile.handleDelete}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6}>
              <Header as="h3">Estudos</Header>
              <HelpText>Estudos que utilizarão a amostra.</HelpText>
            </Grid.Column>
            <Grid.Column width={10}>
              {fields.map((item, index) => {
                const isFirstItem = index === 0;
                return (
                  <Form.Group key={item.id}>
                    <Form.Field width={10}>
                      {isFirstItem ? <label>Estudo</label> : null}
                      <Controller
                        control={control}
                        name={`associatedProjects[${index}].project`}
                        defaultValue=""
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <ProjectSearch
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              placeholder="Busque pelo código..."
                              autoComplete="off"
                            />
                          );
                        }}
                      />
                      {errors.associatedProjects && (
                        <InputError>
                          {errors?.associatedProjects[index]?.project?.message}
                        </InputError>
                      )}
                    </Form.Field>
                    <Form.Field width={5}>
                      {isFirstItem ? <label>Finalidade</label> : null}
                      <Controller
                        control={control}
                        name={`associatedProjects[${index}].sampleGoal`}
                        defaultValue=""
                        render={({ onChange, onBlur, value }) => {
                          return (
                            <Select
                              onChange={onChange}
                              onBlur={onBlur}
                              value={value}
                              placeholder="Selecione"
                              options={[
                                { value: 1, label: 'Padrão' },
                                { value: 2, label: 'Teste' },
                                { value: 3, label: 'Adjuvante' },
                              ]}
                            />
                          );
                        }}
                      />
                      {errors.associatedProjects && (
                        <InputError>
                          {
                            errors?.associatedProjects[index]?.sampleGoal
                              ?.message
                          }
                        </InputError>
                      )}
                    </Form.Field>
                    <Form.Field width={1}>
                      {isFirstItem ? <HiddenLabel /> : null}
                      <Button
                        type="button"
                        size="small"
                        onClick={() => remove(index)}
                        icon
                        basic
                      >
                        <Icon name="trash" />
                      </Button>
                    </Form.Field>
                  </Form.Group>
                );
              })}
              <Form.Field width={10}>
                <Button
                  type="button"
                  size="small"
                  onClick={() => append()}
                  basic
                  fluid
                >
                  <Icon name="add" />
                  Adicionar
                </Button>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={6} />
            <Grid.Column width={10}>
              <Button
                type="submit"
                size="large"
                loading={isLoading}
                disabled={isLoading}
                primary
              >
                Cadastrar amostra
              </Button>
              <Button
                type="button"
                size="large"
                onClick={onCancelClick}
                disabled={isLoading}
                basic
              >
                Cancelar
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>

      <AddProductIngredientModal
        ref={modalRef}
        onSubmit={handleIngredientSubmit}
      />
    </>
  );
}

const HelpText = styled.div`
  color: #666;
  font-style: italic;
`;
