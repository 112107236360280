const trialPatterns = {
  '4': [
    [1, 2, 3, 4, 2, 4],
    [2, 4, 1, 3, 1, 2],
    [3, 1, 4, 2, 3, 1],
    [4, 3, 2, 1, 4, 3],
  ],
  '5': [
    [1, 2, 3, 4, 5, 2],
    [2, 4, 5, 3, 1, 5],
    [3, 5, 2, 1, 4, 3],
    [4, 3, 1, 5, 2, 1],
    [5, 1, 4, 2, 3, 4],
  ],
  '6': [
    [1, 5, 6, 3, 2, 4],
    [2, 4, 1, 5, 3, 6],
    [3, 6, 4, 1, 5, 2],
    [4, 3, 2, 6, 1, 5],
    [5, 2, 3, 4, 6, 1],
    [6, 1, 5, 2, 4, 3],
  ],
  '7': [
    [1, 4, 7, 2, 3, 6],
    [2, 6, 3, 5, 1, 7],
    [3, 5, 6, 4, 7, 1],
    [4, 7, 2, 1, 6, 5],
    [5, 1, 4, 6, 2, 3],
    [6, 3, 5, 7, 4, 2],
    [7, 2, 1, 3, 5, 4],
  ],
  '8': [
    [1, 8, 3, 7, 6, 5],
    [2, 6, 5, 4, 8, 7],
    [3, 2, 1, 6, 5, 4],
    [4, 5, 7, 8, 1, 2],
    [5, 7, 2, 1, 4, 3],
    [6, 4, 8, 3, 7, 8],
    [7, 3, 6, 5, 2, 1],
    [8, 1, 4, 2, 3, 6],
  ],
  '9': [
    [1, 7, 8, 5, 2, 9],
    [2, 4, 6, 9, 7, 3],
    [3, 8, 1, 2, 6, 8],
    [4, 3, 9, 7, 8, 1],
    [5, 6, 4, 3, 1, 2],
    [6, 9, 2, 8, 5, 7],
    [7, 1, 5, 4, 3, 6],
    [8, 5, 3, 6, 9, 4],
    [9, 2, 7, 1, 4, 5],
  ],
  '10': [
    [1, 7, 8, 9, 10, 6],
    [2, 10, 6, 3, 5, 9],
    [3, 8, 4, 5, 6, 10],
    [4, 6, 7, 10, 8, 1],
    [5, 9, 2, 1, 4, 2],
    [6, 1, 10, 8, 9, 5],
    [7, 3, 5, 4, 2, 4],
    [8, 5, 9, 2, 7, 3],
    [9, 2, 3, 7, 1, 8],
    [10, 4, 1, 6, 3, 7],
  ],
  '11': [
    [1, 6, 10, 9, 7, 8],
    [2, 8, 7, 11, 4, 2],
    [3, 5, 4, 2, 10, 1],
    [4, 10, 11, 7, 8, 9],
    [5, 1, 8, 3, 6, 11],
    [6, 9, 2, 10, 11, 7],
    [7, 3, 5, 6, 9, 4],
    [8, 11, 1, 4, 2, 10],
    [9, 7, 6, 8, 3, 5],
    [10, 2, 3, 1, 5, 6],
    [11, 4, 9, 5, 1, 3],
  ],
  '12': [
    [1, 6, 7, 11, 2, 10],
    [2, 10, 4, 8, 6, 7],
    [3, 5, 9, 12, 8, 11],
    [4, 8, 11, 3, 7, 6],
    [5, 12, 1, 9, 11, 8],
    [6, 7, 10, 2, 12, 3],
    [7, 2, 5, 10, 4, 9],
    [8, 11, 3, 6, 9, 1],
    [9, 1, 12, 4, 3, 2],
    [10, 9, 6, 7, 5, 12],
    [11, 4, 8, 1, 10, 5],
    [12, 3, 2, 5, 1, 4],
  ],
  '13': [
    [1, 6, 10, 12, 9, 8],
    [2, 9, 7, 8, 5, 12],
    [3, 13, 11, 5, 8, 9],
    [4, 10, 1, 9, 13, 7],
    [5, 8, 3, 11, 7, 6],
    [6, 4, 13, 2, 12, 5],
    [7, 12, 5, 4, 3, 10],
    [8, 1, 12, 7, 4, 13],
    [9, 3, 6, 13, 10, 4],
    [10, 11, 2, 1, 6, 3],
    [11, 7, 9, 6, 1, 2],
    [12, 5, 4, 10, 11, 1],
    [13, 2, 8, 3, 2, 11],
  ],
  '14': [
    [1, 5, 10, 9, 4, 8],
    [2, 12, 6, 7, 10, 14],
    [3, 8, 13, 14, 1, 6],
    [4, 11, 1, 8, 12, 10],
    [5, 2, 11, 3, 7, 4],
    [6, 14, 4, 13, 3, 12],
    [7, 10, 9, 12, 11, 1],
    [8, 3, 7, 2, 6, 11],
    [9, 13, 12, 5, 14, 3],
    [10, 4, 14, 1, 8, 7],
    [11, 6, 3, 4, 2, 9],
    [12, 9, 8, 11, 5, 13],
    [13, 7, 5, 6, 9, 2],
    [14, 1, 2, 10, 13, 5],
  ],
  '15': [
    [1, 6, 15, 13, 12, 8],
    [2, 12, 5, 7, 8, 4],
    [3, 9, 10, 14, 15, 7],
    [4, 11, 8, 1, 7, 5],
    [5, 2, 14, 12, 14, 9],
    [6, 10, 9, 4, 1, 15],
    [7, 15, 13, 11, 9, 1],
    [8, 3, 1, 6, 5, 11],
    [9, 14, 3, 15, 6, 12],
    [10, 7, 2, 5, 3, 14],
    [11, 13, 4, 8, 10, 3],
    [12, 1, 7, 2, 11, 13],
    [13, 5, 12, 9, 4, 2],
    [14, 8, 6, 3, 13, 10],
    [15, 4, 11, 10, 2, 6],
  ],
  '16': [
    [1, 10, 14, 8, 16, 7],
    [2, 7, 9, 5, 3, 13],
    [3, 13, 11, 12, 7, 4],
    [4, 15, 6, 16, 13, 3],
    [5, 9, 16, 11, 2, 16],
    [6, 14, 2, 13, 11, 12],
    [7, 12, 10, 3, 15, 11],
    [8, 4, 1, 15, 10, 14],
    [9, 5, 3, 4, 6, 2],
    [10, 2, 15, 7, 1, 8],
    [11, 1, 8, 14, 12, 15],
    [12, 16, 13, 6, 4, 10],
    [13, 3, 7, 2, 8, 6],
    [14, 11, 5, 9, 14, 5],
    [15, 6, 12, 1, 5, 9],
    [16, 8, 4, 10, 9, 1],
  ],
  '17': [
    [1, 10, 16, 15, 1, 14],
    [2, 13, 6, 11, 17, 15],
    [3, 9, 12, 17, 15, 16],
    [4, 14, 8, 7, 16, 13],
    [5, 11, 13, 1, 14, 12],
    [6, 16, 15, 10, 13, 17],
    [7, 5, 2, 14, 12, 10],
    [8, 3, 17, 4, 10, 1],
    [9, 12, 3, 16, 2, 9],
    [10, 17, 7, 9, 11, 8],
    [11, 8, 1, 3, 9, 2],
    [12, 6, 14, 5, 8, 11],
    [13, 15, 4, 2, 3, 7],
    [14, 2, 10, 8, 7, 6],
    [15, 4, 5, 12, 6, 3],
    [16, 7, 11, 6, 4, 5],
    [17, 1, 9, 13, 5, 4],
  ],
  '18': [
    [1, 12, 9, 6, 18, 13],
    [2, 8, 17, 11, 12, 14],
    [3, 15, 16, 18, 8, 6],
    [4, 10, 14, 7, 13, 7],
    [5, 13, 1, 12, 6, 17],
    [6, 11, 5, 10, 16, 8],
    [7, 4, 18, 15, 5, 2],
    [8, 16, 3, 17, 1, 5],
    [9, 2, 13, 14, 7, 18],
    [10, 18, 4, 16, 11, 12],
    [11, 9, 7, 3, 3, 10],
    [12, 17, 15, 5, 9, 11],
    [13, 6, 8, 2, 17, 4],
    [14, 1, 10, 9, 2, 15],
    [15, 5, 6, 13, 4, 3],
    [16, 7, 11, 8, 15, 9],
    [17, 14, 2, 4, 10, 11],
    [18, 3, 12, 1, 14, 16],
  ],
  '19': [
    [1, 12, 10, 16, 17, 15],
    [2, 9, 18, 6, 10, 18],
    [3, 13, 7, 17, 5, 11],
    [4, 8, 11, 15, 1, 10],
    [5, 14, 19, 12, 15, 2],
    [6, 5, 14, 18, 4, 17],
    [7, 16, 13, 2, 19, 9],
    [8, 1, 3, 4, 9, 14],
    [9, 11, 15, 19, 6, 5],
    [10, 17, 16, 7, 14, 19],
    [11, 10, 9, 5, 8, 16],
    [12, 18, 6, 1, 2, 13],
    [13, 4, 2, 8, 18, 8],
    [14, 19, 17, 3, 13, 7],
    [15, 6, 5, 14, 12, 3],
    [16, 2, 8, 11, 7, 4],
    [17, 15, 4, 10, 16, 1],
    [18, 7, 1, 13, 3, 12],
    [19, 3, 12, 9, 11, 6],
  ],
};

export default trialPatterns;
