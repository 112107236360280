import React from 'react';

import { Grid } from 'semantic-ui-react';

import ProjectHistory from './ProjectHistory';

export type ProjectHistoryResponse = {
  id: number;
  date: string;
  projectStage: number;
  userId: number;
  userName: string;
  durationInDays: number;
};

export type ProjectHistoryPageProps = {
  history: ProjectHistoryResponse[];
};

export default function ProjectHistoryPage({
  history,
}: ProjectHistoryPageProps) {
  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <ProjectHistory history={history} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
