import {
  format,
  formatDistanceStrict,
  formatISO,
  compareAsc,
  parseISO,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

const FORMAT_BR = 'dd/MM/yyyy';
const FORMAT_INTL = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';

export function formatToBr(isoDate) {
  if (!isoDate) {
    return '';
  }

  const date = Date.parse(isoDate);

  return format(date, FORMAT_BR);
}

export function formatToIntl(isoDate) {
  if (!isoDate) {
    return '';
  }

  const date = Date.parse(isoDate);

  return format(date, FORMAT_INTL);
}

export function formatTime(isoDate) {
  const date = Date.parse(isoDate);

  return format(date, TIME_FORMAT);
}

export function isLate(firstDate, secondDate) {
  const startDate = new Date(firstDate);
  const endDate = secondDate ? new Date(secondDate) : new Date();

  const result = compareAsc(startDate, endDate);

  return result === -1;
}

export function formatPeriod(firstDate, secondDate) {
  if (!firstDate || !secondDate) {
    return null;
  }

  const startDate = new Date(firstDate);
  const endDate = secondDate ? new Date(secondDate) : new Date();

  const result = compareAsc(startDate, endDate);

  if (result === -1) {
    return `${formatDistanceStrict(startDate, endDate, { locale: ptBR })}`;
  }

  return formatDistanceStrict(startDate, endDate, { locale: ptBR });
}

export const nowAsIso = () => formatToIso(new Date());

export function formatToIso(date) {
  return formatISO(date);
}

export function parseIso(isoDate) {
  return parseISO(isoDate);
}
