import * as React from 'react';

import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  InputOnChangeData,
  PaginationProps,
} from 'semantic-ui-react';

import Pagination from 'components/data/Pagination';
import Section from 'components/layout/Section';
import { useQueryTargets } from 'hooks/target';

import TargetsTable from './TargetsTable';

type MouseEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;

type ChangeEvent = React.ChangeEvent<HTMLInputElement>;

type ButtonEvent = React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

const FIRST_PAGE = 1;

export default function Targets() {
  const history = useHistory();

  const [targetsPage, setTargetsPage] = React.useState(1);
  const [targetName, setTargetName] = React.useState('');

  const pageSize = 10;
  const targetsQuery = useQueryTargets({
    page: targetsPage,
    pageSize,
    name: targetName,
  });

  function handlePageChange(_: MouseEvent, { activePage }: PaginationProps) {
    setTargetsPage(activePage as number);
  }

  function handleNameChange(_: ChangeEvent, { value }: InputOnChangeData) {
    setTargetName(value);
    setTargetsPage(FIRST_PAGE);
  }

  function handleAddClick(e: ButtonEvent) {
    e.preventDefault();
    history.push('/alvos/novo');
  }

  function handleEditClick(target: any) {
    history.push(`/alvos/${target.id}/editar`);
  }

  return (
    <Grid>
      <Grid.Row columns="equal" verticalAlign="middle">
        <Grid.Column>
          <Header as="h1">Alvos</Header>
        </Grid.Column>
        <Grid.Column textAlign="right">
          <Button type="button" onClick={handleAddClick} primary>
            <Icon name="add" />
            Novo alvo
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column width={8}>
          <Form>
            <Form.Field>
              <Form.Input
                autoComplete="off"
                placeholder="Pesquisar..."
                name="name"
                value={targetName}
                onChange={handleNameChange}
              />
            </Form.Field>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Section>
            <Section.Content>
              <Grid>
                <Grid.Row columns={1}>
                  <Grid.Column>
                    <TargetsTable
                      targets={targetsQuery?.data?.targets}
                      onEditClick={handleEditClick}
                      loading={targetsQuery?.isLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1} textAlign="right">
                  <Grid.Column>
                    <Pagination
                      pagination={targetsQuery?.data?.pagination}
                      onPageChange={handlePageChange}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Section.Content>
          </Section>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
