import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import api from 'util/api';

type CreateApplicationInput = {
  projectId: number;
  date: Date;
  startTime: Date;
  endTime: Date;
};

type UseCreateApplicationOptions = UseMutationOptions<
  void,
  AxiosError,
  CreateApplicationInput
>;

export function useCreateApplication(options: UseCreateApplicationOptions) {
  const queryClient = useQueryClient();
  return useMutation(createApplication, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('applications');
      queryClient.invalidateQueries('application');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createApplication(input: CreateApplicationInput) {
  const { date, startTime, endTime } = input;

  const startDate = getDateByTime(date, startTime);
  const endDate = getDateByTime(date, endTime);

  const updatedApplication = { ...input, startDate, endDate };

  await api.post(
    `/projects/${input.projectId}/applications`,
    updatedApplication,
  );
}

type UpdateApplicationInput = CreateApplicationInput & {
  applicationId: number;
};

type UseUpdateApplicationOptions = UseMutationOptions<
  void,
  AxiosError,
  UpdateApplicationInput
>;

export function useUpdateApplication(options: UseUpdateApplicationOptions) {
  const queryClient = useQueryClient();
  return useMutation(updateApplication, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('applications');
      queryClient.invalidateQueries('application');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateApplication(input: UpdateApplicationInput) {
  const { date, startTime, endTime } = input;

  const startDate = getDateByTime(date, startTime);
  const endDate = getDateByTime(date, endTime);

  const updatedApplication = { ...input, startDate, endDate };

  await api.patch(
    `/projects/${input.projectId}/applications/${input.applicationId}`,
    updatedApplication,
  );
}

function getDateByTime(date: Date, time: Date) {
  const datetime = new Date(date);
  datetime.setHours(time.getHours(), time.getMinutes());
  return datetime;
}

type RemoveApplicationInput = {
  projectId: number;
  applicationId: number;
};

type UseRemoveApplicationOptions = UseMutationOptions<
  void,
  AxiosError,
  RemoveApplicationInput
>;

export function useRemoveApplication(options: UseRemoveApplicationOptions) {
  const queryClient = useQueryClient();
  return useMutation(removeApplication, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('applications');
      queryClient.invalidateQueries('application');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function removeApplication({
  projectId,
  applicationId,
}: RemoveApplicationInput) {
  await api.delete(`/projects/${projectId}/applications/${applicationId}`);
}
