import React from 'react';

import { Table, Popup } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';
import { JobPositionDescription } from 'util/Employee';

export default function EmployeeTable({
  employees = [],
  onEditClick,
  loading,
}) {
  return (
    <Table size="small" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Nome</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Posição</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">CREA-SP</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns="4" rows={5} />}
        {!loading &&
          employees.map((employee) => (
            <Table.Row key={employee.id.toString()}>
              <Table.Cell textAlign="left">{employee.fullName}</Table.Cell>
              <Table.Cell textAlign="left">
                {JobPositionDescription[employee.jobPosition]}
              </Table.Cell>
              <Table.Cell textAlign="left">{employee?.creaSp}</Table.Cell>
              <Table.Cell textAlign="center">
                <Popup
                  trigger={
                    <LinkButton
                      type="button"
                      onClick={() => onEditClick(employee)}
                    >
                      Editar
                    </LinkButton>
                  }
                  content="Editar"
                  mouseEnterDelay={500}
                  inverted
                />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}
