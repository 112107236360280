import React from 'react';

import { NavLink } from 'react-router-dom';
import { Menu, Dropdown } from 'semantic-ui-react';

import { logout, useAuth } from 'hooks/auth';

const MENU_ITEMS = {
  /* dashboard: {
    index: 1,
    name: 'dashboard',
    text: 'Dashboard',
    to: '/dashboard',
  }, */
  administracao: {
    index: 1,
    name: 'administracao',
    text: 'Administração',
    to: '/administracao',
  },
  amostras: {
    index: 2,
    name: 'amostras',
    text: 'Amostras',
    to: '/amostras',
  },
  coletas: {
    index: 5,
    name: 'coletas',
    text: 'Coleta',
    to: '/coletas',
  },
  configuracoes: {
    index: 7,
    name: 'configuracoes',
    text: 'Configurações',
    to: '/configuracoes',
  },
  criacoes: {
    index: 4,
    name: 'criacoes',
    text: 'Criações',
    to: '/criacoes',
  },
  pesquisa: {
    index: 3,
    name: 'pesquisa',
    text: 'Pesquisa',
    to: '/pesquisa',
  },
  pops: {
    index: 6,
    name: 'pops',
    text: 'POPs',
    to: '/pops',
  },
};

const MENU_ITEMS_BY_ROLE = {
  admin: [
    /* MENU_ITEMS.dashboard, */
    MENU_ITEMS.administracao,
    MENU_ITEMS.amostras,
    MENU_ITEMS.pesquisa,
    MENU_ITEMS.criacoes,
    MENU_ITEMS.coletas,
    MENU_ITEMS.pops,
    MENU_ITEMS.configuracoes,
  ],
  breeding: [MENU_ITEMS.criacoes],
  research: [
    MENU_ITEMS.administracao,
    MENU_ITEMS.amostras,
    MENU_ITEMS.pesquisa,
    MENU_ITEMS.criacoes,
    MENU_ITEMS.coletas,
    MENU_ITEMS.pops,
  ],
  warehouse: [MENU_ITEMS.amostras],
};

export default function Navbar() {
  const { user } = useAuth();

  function handleLogout() {
    logout();
    window.location.reload();
  }

  return (
    <Menu size="large" fixed="top" style={styles.menu} inverted color="green">
      <Menu.Item header style={styles.item}>
        SISMIP
      </Menu.Item>
      {MENU_ITEMS_BY_ROLE[user.roleType].map(({ name, text, to }) => (
        <Menu.Item
          as={NavLink}
          key={name}
          name={name}
          style={styles.item}
          to={to}
        >
          {text}
        </Menu.Item>
      ))}
      <Menu.Menu position="right">
        <Dropdown item text={user?.firstName}>
          <Dropdown.Menu direction="left">
            <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Menu>
    </Menu>
  );
}

const styles = {
  menu: {
    height: '50px',
    boxShadow: 'none',
    color: '#ffffff',
    background: '#374667',
    backgroundColor: '#374667',
  },
  item: {
    color: '#cdd0d9',
  },
};
