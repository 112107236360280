import React from 'react';

import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';
import { CropResponse } from 'data/crop';

type CropsTableProps = {
  crops?: CropResponse[];
  isLoading?: boolean;
  onEditClick: (cropId: number) => void;
};

export default function CropsTable({
  crops = [],
  isLoading,
  onEditClick,
}: CropsTableProps) {
  return (
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nome</Table.HeaderCell>
          <Table.HeaderCell>Nome internacional</Table.HeaderCell>
          <Table.HeaderCell>Código</Table.HeaderCell>
          <Table.HeaderCell width={2} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isLoading && <LoadingRow columns={2} rows={10} />}
        {!isLoading &&
          crops?.map(({ id, name, nameInt, insideCode }) => (
            <Table.Row key={id}>
              <Table.Cell>{name}</Table.Cell>
              <Table.Cell>{nameInt}</Table.Cell>
              <Table.Cell>{insideCode.toUpperCase()}</Table.Cell>
              <Table.Cell>
                <LinkButton onClick={() => onEditClick(id)}>Editar</LinkButton>
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}
