import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Divider, Form, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import AlertModal from 'components/layout/AlertModal';
import { useModal } from 'hooks/useModal';
import {
  SHIPPING_TYPES,
  useAddShippingQuotation,
  useDeleteShippingQuotation,
  useUpdateShippingQuotation,
} from 'mutations/quotation';
import { emptyStringToUndefined } from 'util/validation';

const ShippingFormInput = yup.object().shape({
  amount: yup
    .number()
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  quantity: yup.number().required(`Obrigatório`),
  shippingType: yup.number().required(`Obrigatório`),
});

export type ShippingFormValues = {
  amount: number;
  quantity: number;
  shippingType: number;
};

export type ShippingModalProps = {
  formValues: ShippingFormValues;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  quotationId: number;
};

const formId = 'form_shipping';

export default function ShippingModal({
  formValues,
  isOpen,
  onClose,
  onSuccess,
  quotationId,
}: ShippingModalProps) {
  const defaultValues = formValues ? { ...formValues } : undefined;
  const resolver = yupResolver(ShippingFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit, register } = useForm(formOptions);

  const deletionModal = useModal();

  const addShipping = useAddShippingQuotation({
    onSuccess: () => handleSuccess('Frete adicionado!'),
  });

  const updateShipping = useUpdateShippingQuotation({
    onSuccess: () => handleSuccess('Frete salvo!'),
  });

  const deleteShipping = useDeleteShippingQuotation({
    onSuccess: () => handleSuccess('Frete excluído!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    deletionModal.close();
    onClose();
    onSuccess();
  }

  function handleDeleteClick() {
    deletionModal.open();
  }

  function onSubmit(values: ShippingFormValues) {
    const input = {
      amount: values.amount ? Math.round(values.amount * 100) : null,
      quantity: values.quantity,
      quotationId,
      shippingType: values.shippingType,
    };

    if (formValues) {
      updateShipping.mutate(input);
      return;
    }

    addShipping.mutate(input);
  }

  const isSubmitting = addShipping.isLoading || updateShipping.isLoading;
  const header = formValues ? 'Editar frete' : 'Novo frete';
  const buttonText = formValues ? 'Salvar' : 'Adicionar';

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Tipo</label>
            <RadioGroup>
              <RadioInput
                name="shippingType"
                label="CIF"
                value={SHIPPING_TYPES.CIF}
                ref={register}
                defaultChecked
              />
              <RadioInput
                name="shippingType"
                label="FOB"
                value={SHIPPING_TYPES.FOB}
                ref={register}
              />
            </RadioGroup>
          </Form.Field>
          <Divider hidden />
          <Form.Group widths="equal">
            <Form.Field>
              <label>Qtd. de envios</label>
              <Controller
                control={control}
                name="quantity"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={0}
                    decimalSeparator=","
                    disabled={isSubmitting}
                    onBlur={onBlur}
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.quantity && (
                <InputError>{errors?.quantity?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Valor (opcional)</label>
              <Controller
                control={control}
                name="amount"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isSubmitting}
                    onBlur={onBlur}
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.amount && (
                <InputError>{errors?.amount?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        {formValues ? (
          <Button
            type="button"
            onClick={handleDeleteClick}
            floated="left"
            basic
          >
            Excluir
          </Button>
        ) : null}
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>

      {deletionModal.isOpen ? (
        <AlertModal
          content="Tem certeza que deseja excluir o frete?"
          header="Exclusão de frete"
          isLoading={deleteShipping.isLoading}
          isOpen={deletionModal.isOpen}
          negative
          onClose={deletionModal.close}
          onSubmit={() => deleteShipping.mutate(quotationId)}
          submitText="Excluir"
        />
      ) : null}
    </Modal>
  );
}
