import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Loader,
  Table,
} from 'semantic-ui-react';
import styled, { css } from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import PackagingTable from 'components/data/PackagingTable';
import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Inline from 'components/layout/Inline';
import Section from 'components/layout/Section';
import PackagingModalForm from 'components/modal/PackagingModalForm';
import SampleManipulationModalForm, {
  FormValues,
} from 'components/modal/SampleManipulationModalForm';
import { getProjectStage, getProjectStageColor } from 'data/project';
import { useAuth } from 'hooks/auth';
import { downloadDocument } from 'hooks/document';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useModal, useModalWithData } from 'hooks/useModal';
import { useGenerateCustodyChain, useManipulateSample } from 'mutations/sample';
import {
  SAMPLE_STATUS_COLOR,
  SAMPLE_STATUS_DESCRIPTION,
  useQuerySample,
} from 'queries/sample';
import { downloadFile } from 'util/FileManager';

import SampleDocumentEditModal from './SampleDocumentEditModal';
import SampleEditModal from './SampleEditModal';
import SampleManipulationTable from './SampleManipulationTable';

export default function SampleDetailsPage() {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const sampleId = parseInt(params.id, 10);

  const { user } = useAuth();
  const { handleError } = useErrorHandler();

  const modal = useModalWithData();
  const editModal = useModal();
  const editDocumentModal = useModal();
  const packagingModal = useModal();

  const { data: sample, isLoading } = useQuerySample(sampleId);

  function handleDownloadDocument(documentId: number) {
    downloadDocument(documentId);
  }

  const { mutate, isLoading: isLoadingCustodyChain } = useGenerateCustodyChain({
    onSuccess: ({ file, filename }) => {
      downloadFile(file, filename);
      toast.success('Termo de cessão de área gerado com sucesso!');
    },
  });

  const {
    mutate: manipulateSample,
    isLoading: isManipulating,
    error: manipulateError,
  } = useManipulateSample({
    onSuccess: () => {
      modal.close();
      toast.success('Amostra manipulada com sucesso!');
    },
  });

  function handleEditSampleClick() {
    editModal.open();
  }

  function handleEditProductClick() {
    history.push(`/produtos/${sample?.product?.id}/editar`);
  }

  function handleEditDocumentClick() {
    editDocumentModal.open();
  }

  function handleAddPackagingClick() {
    packagingModal.open();
  }

  function handleManipulateClick(packaging: any) {
    modal.open(packaging);
  }

  async function handleCustodyChainClick() {
    mutate(sampleId);
  }

  function handleAddPackagingSuccess(message: string) {
    toast.success(message);
    packagingModal.close();
  }

  function handleManipulationSubmit(values: FormValues) {
    const date = `${DateTime.fromJSDate(values.date).toFormat(
      'yyyy-MM-dd',
    )} ${DateTime.fromJSDate(values.time).toFormat('hh:mm:ss')}`;
    const input = {
      sampleId: sample!.id,
      packagingId: modal!.data!.id,
      balanceCode: values.balanceCode,
      date,
      endAmount: values.endAmount,
      lostAmount: values.lostAmount,
      lostCode: values.lostCode || null,
      notes: values.notes || null,
      projectId: values.project.value,
      responsibleId: values.responsible.value,
      startAmount: values.startAmount,
      weighedAmount: values.weighedAmount,
    };
    manipulateSample(input);
  }

  const exportOptions = [
    {
      key: 'empty-chain-of-custody',
      text: 'Cadeia de custódia (vazia)',
      value: 'empty-chain-of-custody',
      icon: 'download',
      onClick: handleCustodyChainClick,
    },
  ];

  const options = [
    {
      key: 'edit-sample',
      text: 'Editar amostra',
      value: 'edit-sample',
      icon: 'edit',
      onClick: handleEditSampleClick,
    },
    {
      key: 'edit-product',
      text: 'Editar produto',
      value: 'edit-product',
      icon: 'edit',
      onClick: handleEditProductClick,
    },
  ];

  if (!sample || isLoading) {
    return <Loader />;
  }

  const tags = [
    sample.type,
    sample.product.type,
    sample.product.category,
    sample.product.formulation,
    ...sample.goals,
  ];

  return (
    <Base loading={isLoading}>
      <Container>
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <HeaderWrapper>
                <HeaderRow>
                  <HeaderColumn>
                    <HeaderText as="h1">{sample.code}</HeaderText>
                    <span style={{ marginLeft: 4 }}>
                      <Tag color={SAMPLE_STATUS_COLOR[sample.status]}>
                        {SAMPLE_STATUS_DESCRIPTION[sample.status]}
                      </Tag>
                    </span>
                  </HeaderColumn>
                  <HeaderColumn>
                    <Dropdown
                      text="Exportar"
                      direction="left"
                      basic
                      button
                      floating
                      loading={isLoadingCustodyChain}
                    >
                      <Dropdown.Menu>
                        <Dropdown.Menu scrolling>
                          {exportOptions.map((option) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <Dropdown.Item {...option} />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown.Menu>
                    </Dropdown>
                    {user.isAdmin ? (
                      <Dropdown
                        text="Opções"
                        direction="left"
                        floating
                        basic
                        button
                      >
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling>
                            {options.map((option) => (
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              <Dropdown.Item {...option} />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : null}
                  </HeaderColumn>
                </HeaderRow>
              </HeaderWrapper>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Tags>
                {tags.map((tag) => (
                  <React.Fragment key={tag}>
                    <Tag color="grey">{tag}</Tag>
                    <Inline size="s" />
                  </React.Fragment>
                ))}
              </Tags>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="4">
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Nome do produto"
                    text={sample.product.name}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Validade"
                    text={sample.expirationDateFormatted}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo label="Quantidade total" text={sample.volume} />
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TextInfo
                    label="Cliente"
                    text={
                      sample.associatedProjects.length
                        ? sample.associatedProjects[0].customer.tradingName
                        : 'Indefinido'
                    }
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Informações da amostra</Header>
                </Section.Header>
                <Section.Content>
                  <Grid>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Ingredientes</Label>
                          <Value>{sample.product?.ingredients}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Lote</Label>
                          <Value>{sample.batchNumber}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Data de entrada</Label>
                          <Value>{sample.arrivalDateFormatted}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Data de fabricação</Label>
                          <Value>{sample.manufacturingDateFormatted}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">RET</Header>
                </Section.Header>
                <Section.Content>
                  <Grid>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Número</Label>
                          <Value>{sample.product?.ret}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Fase</Label>
                          <Value>{sample.product?.retStage}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Validade</Label>
                          <Value>{sample.product?.retExpirationDate}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Registro MAPA</Label>
                          <Value>{sample.product?.mapaRegister}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Documentos</Header>
                  <Button
                    basic
                    onClick={handleEditDocumentClick}
                    size="small"
                    disabled={isLoading}
                  >
                    <Icon name="edit" />
                    Editar
                  </Button>
                </Section.Header>
                <Section.Content>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>
                            Registro de Entrada de Amostras de Substância Teste
                            (REA-SST)
                          </Label>
                          <Value
                            onClick={
                              sample.checkInDocumentId
                                ? () =>
                                    handleDownloadDocument(
                                      sample.checkInDocumentId,
                                    )
                                : undefined
                            }
                          >
                            {sample.checkInDocumentId ? 'Anexo' : 'Sem anexo'}
                          </Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>
                            Cadeia de Custódia da Substância Teste (CCST)
                          </Label>
                          <Value
                            onClick={
                              sample.custodyChainDocumentId
                                ? () =>
                                    handleDownloadDocument(
                                      sample.custodyChainDocumentId,
                                    )
                                : undefined
                            }
                          >
                            {sample.custodyChainDocumentId
                              ? 'Anexo'
                              : 'Sem anexo'}
                          </Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Nota fiscal</Label>
                          <Value
                            onClick={
                              sample.invoiceDocumentId
                                ? () =>
                                    handleDownloadDocument(
                                      sample.invoiceDocumentId,
                                    )
                                : undefined
                            }
                          >
                            {sample.invoiceDocumentId ? 'Anexo' : 'Sem anexo'}
                          </Value>
                        </ItemWrapper>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Destino final</Header>
                </Section.Header>
                <Section.Content>
                  {sample?.status === 'in_use' ? (
                    <EmptyState>
                      <EmptyState.Header>Amostra em uso</EmptyState.Header>
                      <EmptyState.Description>
                        A amostra pode ser descartada ou devolvida após o uso
                      </EmptyState.Description>
                    </EmptyState>
                  ) : null}

                  {sample?.status === 'depleted' ? (
                    <EmptyState>
                      <EmptyState.Header>Amostra esgotada</EmptyState.Header>
                      <EmptyState.Description>
                        Amostra esgotada não precisa ser descartada ou devolvida
                      </EmptyState.Description>
                    </EmptyState>
                  ) : null}

                  {sample?.status === 'discarded' && sample.discard ? (
                    <Grid>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Tipo</Label>
                            <Value>
                              <strong>Descarte</strong>
                            </Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Data do descarte</Label>
                            <Value>{sample.discard.dateFormatted}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Responsável pelo descarte</Label>
                            <Value>{`${sample.discard.discardResponsible?.firstName} ${sample.discard.discardResponsible.lastName}`}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Destino</Label>
                            <Value>{sample.discard.destination}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Termo</Label>
                            <Value>
                              <LinkButton
                                onClick={() =>
                                  handleDownloadDocument(
                                    sample.discard.documentId,
                                  )
                                }
                              >
                                Anexo
                              </LinkButton>
                            </Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Observações</Label>
                            <Value>{sample.discard?.notes || 'n/a'}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : null}

                  {sample?.status === 'returned' && sample.return ? (
                    <Grid>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Tipo</Label>
                            <Value>
                              <strong>Devolução</strong>
                            </Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Data da devolução</Label>
                            <Value>{sample.return.dateFormatted}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Responsável pela devolução</Label>
                            <Value>{`${sample.return.returnResponsible?.firstName} ${sample.return.returnResponsible?.lastName}`}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Responsável pelo recebimento</Label>
                            <Value>
                              {sample.return.receivingResponsible?.name}
                            </Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns="equal">
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Termo</Label>
                            <Value>
                              <LinkButton
                                onClick={() =>
                                  handleDownloadDocument(
                                    sample.return.documentId,
                                  )
                                }
                              >
                                Anexo
                              </LinkButton>
                            </Value>
                          </ItemWrapper>
                        </Grid.Column>
                        <Grid.Column>
                          <ItemWrapper>
                            <Label>Observações</Label>
                            <Value>{sample.return?.notes || 'n/a'}</Value>
                          </ItemWrapper>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  ) : null}
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3" style={{ margin: 0 }}>
                    Embalagens
                  </Header>
                  <Button
                    primary
                    basic
                    onClick={handleAddPackagingClick}
                    size="small"
                  >
                    <Icon name="add" />
                    Adicionar
                  </Button>
                </Section.Header>
                <Section.Content>
                  <Grid>
                    <Grid.Row columns="equal">
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Nº de embalagens</Label>
                          <Value>{sample.packagingQuantity}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Quantidade total</Label>
                          <Value>{sample.volume}</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column>
                        <ItemWrapper>
                          <Label>Quantidade final (g)</Label>
                          <Value>{/* {finalVolume} */}-</Value>
                        </ItemWrapper>
                      </Grid.Column>
                      <Grid.Column />
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row>
                      <Grid.Column>
                        <PackagingTable
                          items={sample.packagings}
                          onManipulateClick={handleManipulateClick}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Manipulações</Header>
                </Section.Header>
                <Section.Content>
                  {sample.manipulations.length ? (
                    <SampleManipulationTable
                      manipulations={sample.manipulations}
                      isLoading={isLoading}
                    />
                  ) : (
                    <EmptyState>
                      <EmptyState.Header>Nenhuma manipulação</EmptyState.Header>
                    </EmptyState>
                  )}
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3">Estudos associados à amostra</Header>
                </Section.Header>
                <Section.Content>
                  {sample?.associatedProjects?.length ? (
                    <Grid style={{ marginTop: 8 }}>
                      <Table size="small" singleLine basic="very">
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>#</Table.HeaderCell>
                            <Table.HeaderCell>Código</Table.HeaderCell>
                            <Table.HeaderCell>Fase</Table.HeaderCell>
                            <Table.HeaderCell>
                              Última aplicação
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                              Status aplicação
                            </Table.HeaderCell>
                            <Table.HeaderCell>Finalidade</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {sample.associatedProjects?.map((project, index) => (
                            <Table.Row key={project.id.toString()}>
                              <Table.Cell>
                                <Text variant="primary">{index + 1}</Text>
                              </Table.Cell>
                              <Table.Cell>
                                <Text variant="primary">
                                  <Link to={`/estudos/${project.id}`}>
                                    {project.insideCode}
                                  </Link>
                                </Text>
                              </Table.Cell>
                              <Table.Cell>
                                <Tag
                                  color={getProjectStageColor(
                                    project.projectStage,
                                  )}
                                >
                                  {getProjectStage(project.projectStage)}
                                </Tag>
                              </Table.Cell>
                              <Table.Cell>
                                <Text variant="primary">
                                  {project.lastApplication
                                    ? DateTime.fromISO(
                                        project.lastApplication.date,
                                      ).toFormat('dd/MM/yyyy')
                                    : ''}
                                </Text>
                              </Table.Cell>
                              <Table.Cell>
                                {project.lastApplication ? (
                                  <Tag
                                    color={project.lastApplication.status.color}
                                  >
                                    {project.lastApplication.status.description}
                                  </Tag>
                                ) : null}
                              </Table.Cell>
                              <Table.Cell>
                                <Text variant="primary">
                                  {project.sampleGoal}
                                </Text>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </Grid>
                  ) : (
                    <EmptyState>
                      <EmptyState.Header>
                        Nenhum estudo associado
                      </EmptyState.Header>
                    </EmptyState>
                  )}
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      {editModal.isOpen && sample ? (
        <SampleEditModal
          formValues={mapToForm(sample)}
          isOpen={editModal.isOpen}
          onClose={editModal.close}
          sampleId={sample.id}
        />
      ) : null}

      {editDocumentModal.isOpen && sample ? (
        <SampleDocumentEditModal
          isOpen={editDocumentModal.isOpen}
          onClose={editDocumentModal.close}
          checkInDocumentId={sample.checkInDocumentId}
          custodyChainDocumentId={sample.custodyChainDocumentId}
          invoiceDocumentId={sample.invoiceDocumentId}
          sampleId={sample.id}
        />
      ) : null}

      {packagingModal && sample ? (
        <PackagingModalForm
          isOpen={packagingModal.isOpen}
          onClose={packagingModal.close}
          onSuccess={handleAddPackagingSuccess}
          sampleId={sample.id}
          unit={sample.unit.description}
        />
      ) : null}

      {modal.isOpen && modal.data ? (
        <SampleManipulationModalForm
          code={modal?.data?.code}
          error={manipulateError ? handleError(manipulateError) : null}
          isLoading={isManipulating}
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSubmit={handleManipulationSubmit}
          packaging={modal.data}
          projects={sample.associatedProjects}
          // startAmount={parseFloat(modal?.data?.endAmount?.replace(/,/g, '.'))}
          startAmount={parseFloat(modal?.data?.endAmount)}
        />
      ) : null}
    </Base>
  );
}

function mapToForm(sample: any) {
  return {
    sampleType: sample.sampleType.toString(),
    code: sample.code,
    batchNumber: sample.batchNumber,
    arrivalDate: DateTime.fromISO(sample.arrivalDate).toJSDate(),
    manufacturingDate: sample.manufacturingDate
      ? DateTime.fromISO(sample.manufacturingDate).toJSDate()
      : undefined,
    expirationDate: sample.expirationDate
      ? DateTime.fromISO(sample.expirationDate).toJSDate()
      : undefined,
    hasManufacturingDay: sample.hasManufacturingDay,
    hasExpirationDay: sample.hasExpirationDay,
    measurementUnit: sample.measurementUnit.id.toString(),
  };
}

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HeaderColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const HeaderText = styled(Header)`
  margin: 0;
  font-size: 2.8rem;
  margin-right: 8px;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1.6rem;
`;

const ItemWrapper = styled.div`
  flex-direction: column;
  padding: 8px 0;
`;

const Label = styled.div`
  color: #767676;
  font-size: 14px;
`;

const valueModifiers = {
  onClick: () => css`
    color: #4a9add;
    cursor: pointer;
  `,
};

type ValueProps = {
  onClick?: () => void;
};

const Value = styled.span<ValueProps>`
  ${({ onClick }) => css`
    font-size: 14px;

    ${onClick && valueModifiers.onClick()}
  `}
`;
