import { AxiosError } from 'axios';
import {
  useInfiniteQuery,
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from 'react-query';

import { RoleType } from 'hooks/auth';
import api, { emptyPagination, Response } from 'util/api';

type JobPosition = {
  id: number;
  description: string;
};

type Division = {
  id: number;
  description: string;
};

type Employee = {
  id: number;
  firstName: string;
  lastName: string;
  name: string;
  jobPosition: JobPosition;
  creaSp: string | null;
  divisions: Division[];
};

export type Users = {
  id: number;
  email: string;
  active: boolean;
  employee: Employee;
  role: RoleType;
};

type UsersFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

export const userKeys = {
  all: ['users'] as const,
};

export function useInfiniteUsers(params: UsersFilter) {
  const result = useInfiniteQuery(
    [...userKeys.all, params],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getUsers({ ...params, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination ||
    emptyPagination;
  const users =
    result?.data?.pages
      ?.map((group) => group?.data?.map((user) => user))
      .flat() || [];

  return {
    ...result,
    pagination,
    users,
  };
}

async function getUsers(params: UsersFilter) {
  const url = '/users';
  const config = { params };
  const { data } = await api.get<Response<Users>>(url, config);

  return data;
}

export function useDisableUser(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  const queryClient = useQueryClient();

  return useMutation(disableUser, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('users');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function disableUser(userId: number) {
  const url = `/users/${userId}/status`;
  await api.delete(url);
}

export function useEnableUser(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  const queryClient = useQueryClient();

  return useMutation(enableUser, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('users');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function enableUser(userId: number) {
  const url = `/users/${userId}/status`;
  await api.post(url);
}

type ChangeRoleInput = {
  userId: number;
  role: string;
};

export function useChangeRole(
  options?: UseMutationOptions<void, AxiosError, ChangeRoleInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changeRole, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('users');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function changeRole({ userId, ...input }: ChangeRoleInput) {
  const url = `/users/${userId}/role`;
  await api.patch(url, input);
}

type ChangePasswordInput = {
  userId: number;
  newPassword: string;
  newPasswordConfirmation: string;
};

export function useChangePassword(
  options?: UseMutationOptions<void, AxiosError, ChangePasswordInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changePassword, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('users');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function changePassword({ userId, ...input }: ChangePasswordInput) {
  const url = `/users/${userId}/password`;
  await api.patch(url, input);
}

type ChangeDivisionsInput = {
  userId: number;
  divisionIds: number[];
};

export function useChangeDivisions(
  options?: UseMutationOptions<void, AxiosError, ChangeDivisionsInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(changeDivisions, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('users');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function changeDivisions({ userId, ...input }: ChangeDivisionsInput) {
  const url = `/users/${userId}/divisions`;
  await api.patch(url, input);
}
