import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Modal, Button, Message, Form } from 'semantic-ui-react';
import * as Yup from 'yup';

import InputError from 'components/inputs/InputError';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useAddTrials } from 'mutations/treatment';
import { ProjectQuery } from 'queries/project';
import { emptyStringToUndefined } from 'util/validation';

import RandomizationTable from './RandomizationTable';

export type RandomizationFormValues = {
  description: string;
};

export type RandomizationModalFormProps = {
  error?: string;
  project: ProjectQuery;
  treatments: any[];
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

function getSchema(maxValue: number) {
  return Yup.object().shape({
    treatments: Yup.array()
      .of(
        Yup.object()
          .shape({
            trials: Yup.array()
              .of(
                Yup.object()
                  .shape({
                    plot: Yup.number()
                      .required('Obrigatório')
                      .transform(emptyStringToUndefined)
                      .min(1, 'Min: 1')
                      .max(maxValue, `Max: ${maxValue}`),
                  })
                  .required('Obrigatório'),
              )
              .required('Obrigatório'),
          })
          .required('Obrigatório'),
      )
      .required('Obrigatório'),
  });
}

const formId = 'form_trials';

export default function RandomizationModalForm({
  project,
  treatments,
  isOpen,
  onClose,
  onSuccess,
}: RandomizationModalFormProps) {
  const { handleError } = useErrorHandler();
  const [trialsError, setTrialsError] = React.useState(``);

  const maxValue = treatments.length;
  const resolver = yupResolver(getSchema(maxValue));
  const form = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { mutate: addTrials, isLoading, error: createError } = useAddTrials({
    onSuccess: () => {
      onSuccess('Randomização adicionada com sucesso!');
    },
  });

  function onSubmit(values: any) {
    const allTrials = values.treatments
      .map((treatment) => treatment.trials)
      .flat(1)
      .map((trial) => ({
        ...trial,
        plot: parseInt(trial.plot, 10),
        projectId: project.id,
      }));

    const plots = allTrials.map((f) => `${f.block}${f.plot}`);

    if (hasDuplicates(plots)) {
      setTrialsError('As parcelas devem ser únicas');
    } else {
      addTrials({
        projectId: project.id,
        treatmentId: allTrials[0]?.treatmentId,
        trials: allTrials,
      });
    }
  }

  function hasDuplicates(plots: any[]) {
    return new Set(plots).size !== plots.length;
  }

  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Nova randomização</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
          <RandomizationTable
            project={project}
            treatments={treatments}
            form={form}
            loading={isLoading}
            isEditable
          />
        </Form>
        {trialsError && <InputError>{trialsError}</InputError>}
        {createError && <Message content={handleError(createError)} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
