import React from 'react';

import * as S from './styles';

export type InfoProps = {
  children: React.ReactNode;
};

export type LabelProps = {
  children: React.ReactNode;
};

export type ValueProps = {
  onClick?: () => void;
  children: React.ReactNode;
};

function Info({ children }: InfoProps) {
  return <S.Wrapper>{children}</S.Wrapper>;
}

function Label({ children }: LabelProps) {
  return <S.Label>{children}</S.Label>;
}

function Value({ children, onClick }: ValueProps) {
  return <S.Value onClick={onClick}>{children}</S.Value>;
}

Info.Label = Label;
Info.Value = Value;

export default Info;
