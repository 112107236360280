import { formatToIso } from 'util/DateFormatter';

const makeProductRepository = ({ api }) => ({
  async getProducts({
    page = 1,
    pageSize = 10,
    orderBy = 'name',
    sort = 'asc',
    name = '',
  }) {
    const { data } = await api.get(
      `/products?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sort=${sort}&name=${name}`,
    );

    const products = data.data;

    return { data: products, pagination: data.pagination };
  },

  async getProduct(id) {
    const { data } = await api.get(`/products/${id}`);
    return data;
  },

  async getAllProductCategories({
    page = 1,
    pageSize = 100,
    orderBy = 'description',
    sort = 'asc',
  } = {}) {
    const response = await api.get(
      `/product-categories?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sort=${sort}`,
    );

    const { data, pagination } = response.data;

    return { data, pagination };
  },

  async getAllFormulations(params) {
    const response = await api.get('/formulations', { params });

    const { data, pagination } = response.data;

    return { data, pagination };
  },

  async getAllIngredients({
    page = 1,
    pageSize = 100,
    orderBy = 'name',
    sort = 'asc',
    name = '',
  } = {}) {
    const response = await api.get(
      `/active-ingredients?name=${name}&page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sort=${sort}`,
    );

    const { data, pagination } = response.data;

    return { data, pagination };
  },

  async createProduct(product) {
    try {
      const createProductInput = apiCreateMapper(product);

      const response = await api.post(`/products`, createProductInput);
      const { location } = response.headers;
      const productId = parseInt(location.substring(13), 10);

      return productId;
    } catch (e) {
      throw e.response.data;
    }
  },

  async updateProduct(product) {
    try {
      const updateProductInput = apiUpdateMapper(product);
      await api.patch(`/products/${product.id}`, updateProductInput);
      return true;
    } catch (e) {
      throw e.response.data;
    }
  },
});

function apiCreateMapper(product) {
  return {
    name: product.name,
    productType: product.productType,
    productCategory: product.productCategory,
    formulationId: product.formulationId,
    productIngredients: product.productIngredients.map((productIngredient) => ({
      ...productIngredient,
      ingredientId: productIngredient.ingredient.id,
    })),
    ret: product.ret,
    retStage: product.retStage,
    retExpirationDate: product.retExpirationDate
      ? formatToIso(product.retExpirationDate)
      : null,
    mapaRegister: product.mapaRegister,
  };
}

function apiUpdateMapper(product) {
  return {
    name: product.name,
    productType: product.productType,
    productCategory: product.productCategory,
    formulationId: product.formulationId,
    productIngredients: product.productIngredients.map((productIngredient) => ({
      ...productIngredient,
      ingredientId: productIngredient.ingredient.id,
    })),
    ret: product.ret,
    retStage: product.retStage,
    retExpirationDate: product.retExpirationDate
      ? formatToIso(product.retExpirationDate)
      : null,
    mapaRegister: product.mapaRegister,
  };
}

export default makeProductRepository;
