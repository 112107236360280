import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Grid, Header } from 'semantic-ui-react';

import PropertyForm from 'components/forms/PropertyForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useCreateProperty } from 'mutations/property';

export default function AddProperty() {
  const history = useHistory();

  const { mutate: createProperty, isLoading } = useCreateProperty({
    onSuccess: () => {
      toast.success('Propriedade criada com sucesso!');
      history.replace('/pesquisa/propriedades');
    },
  });

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);
    createProperty(updatedValues);
  }

  return (
    <Base text>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Nova propriedade</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <PropertyForm
                    onSubmit={handleSubmit}
                    isSubmiting={isLoading}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}

function mapFromForm(values) {
  return {
    ...values,
    state: values.state.value,
    ownerAddress: values.ownerAddress || null,
    ownerNeighborhood: values.ownerNeighborhood || null,
    ownerCity: values.ownerCity || null,
    ownerState: values?.ownerState?.value || null || null,
    ownerCep: values.ownerCep || null,
  };
}
