import React from 'react';

import { DateTime } from 'luxon';
import { Table, Icon, Button, Popup } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import { downloadDocument } from 'hooks/document';
import { formatCurrencyWithSymbol } from 'util/Currency';

export default function PaymentsTable({ payments, loading, currency }) {
  return (
    <Table size="small" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Número</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">
            Data de Faturamento
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Valor</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Ações</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={4} rows={1} />}
        {payments?.map((payment) => (
          <Table.Row key={payment.id}>
            <Table.Cell textAlign="left">{payment.invoiceNumber}</Table.Cell>
            <Table.Cell textAlign="right">
              {DateTime.fromISO(payment.billingDate).toFormat('dd/MM/yyyy')}
            </Table.Cell>
            <Table.Cell textAlign="right">
              {formatCurrencyWithSymbol(payment.amount, currency)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Popup
                trigger={
                  <Button
                    onClick={() => downloadDocument(payment.invoiceDocumentId)}
                    icon
                    circular
                    basic
                    disabled={!payment.invoiceDocumentId}
                  >
                    <Icon name="download" />
                  </Button>
                }
                content="Baixar anexo"
                mouseEnterDelay={500}
                inverted
              />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}
