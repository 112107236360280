import React from 'react';

import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Button, Form, Input, Divider, Header } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import StateSelect from 'components/inputs/StateSelect';
import { formatCpfOrCnpj } from 'util/mask';

export type PropertyFormProps = {
  formData?: FormData;
  onSubmit: () => void;
  isSubmiting: boolean;
};

type FormData = {
  name: string;
  owner: string;
  ownerDocument: string;
  phone: string;
  address: string;
  neighborhood: string;
  city: string;
  state: {
    value: string;
    label: string;
  };
  cep: string;
  ownerAddress?: string | null;
  ownerNeighborhood?: string | null;
  ownerCity?: string | null;
  ownerState?: {
    value: string;
    label: string;
  } | null;
};

const MASK_CEP = '#####-###';
const MASK_PHONE = '(##) #####-####';

const INITIAL_VALUES = {
  name: '',
  address: '',
  neighborhood: '',
  city: '',
  state: { value: 'sp', label: 'SP' },
  cep: '',
  owner: '',
  ownerDocument: '',
  phone: '',
  ownerAddress: '',
  ownerNeighborhood: '',
  ownerCity: '',
  ownerState: null,
  ownerCep: '',
};

export default function PropertyForm({
  formData,
  onSubmit,
  isSubmiting = false,
}: PropertyFormProps) {
  const FORM_ID = 'PROPERTY_FORM';

  const formRef = React.useRef(null);

  const defaultValues = formData || INITIAL_VALUES;

  const { control, errors, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    defaultValues,
  });

  const submitButtonText = formData ? 'Salvar' : 'Adicionar';

  return (
    <>
      <Form id={FORM_ID} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Form.Field>
          <Header as="h2">Propriedade</Header>
          <label>Nome </label>
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.name && <InputError>Obrigatório</InputError>}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Endereço</label>
            <Controller
              control={control}
              name="address"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
            {errors.address && <InputError>Obrigatório</InputError>}
          </Form.Field>
          <Form.Field>
            <label>Localidade/Bairro</label>
            <Controller
              control={control}
              name="neighborhood"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
            {errors.neighborhood && <InputError>Obrigatório</InputError>}
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <label>Município</label>
            <Controller
              control={control}
              name="city"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
            {errors.city && <InputError>Obrigatório</InputError>}
          </Form.Field>
          <Form.Field width={4}>
            <label>Estado</label>
            <Controller
              control={control}
              name="state"
              rules={{ required: true }}
              render={({ onChange, onBlur, value }) => (
                <StateSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="off"
                />
              )}
            />
            {errors.state && <InputError>Obrigatório</InputError>}
          </Form.Field>
          <Form.Field width={4}>
            <label>CEP</label>
            <Controller
              control={control}
              name="cep"
              rules={{
                required: {
                  value: true,
                  message: 'CEP é um campo obrigatório',
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={MASK_CEP}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  maxLength={18}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                />
              )}
            />
            {errors.cep && <InputError>{errors?.cep?.message}</InputError>}
          </Form.Field>
        </Form.Group>

        <Divider hidden />

        <Header as="h2">Proprietário</Header>
        <Form.Field>
          <label>Nome</label>
          <Controller
            control={control}
            name="owner"
            rules={{ required: true }}
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors.owner && <InputError>Obrigatório</InputError>}
        </Form.Field>
        <Form.Group widths="equal">
          <Form.Field>
            <label>CPF/CNPJ</label>
            <Controller
              control={control}
              name="ownerDocument"
              rules={{
                required: {
                  value: true,
                  message: 'CPF/CNPJ é um campo obrigatório',
                },
                minLength: {
                  value: 11,
                  message: 'Telefone deve conter no mínimo 11 números',
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={formatCpfOrCnpj}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  maxLength={18}
                  disabled={isSubmiting}
                  autoComplete="off"
                />
              )}
            />
            {errors.ownerDocument && (
              <InputError>{errors?.ownerDocument?.message}</InputError>
            )}
          </Form.Field>
          <Form.Field>
            <label>Telefone</label>
            <Controller
              control={control}
              name="phone"
              rules={{
                required: {
                  value: true,
                  message: 'Telefone é um campo obrigatório',
                },
                minLength: {
                  value: 10,
                  message: 'Telefone deve conter no mínimo 10 números',
                },
              }}
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={MASK_PHONE}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  disabled={isSubmiting}
                  autoComplete="off"
                />
              )}
            />
            {errors.phone && <InputError>{errors?.phone?.message}</InputError>}
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Endereço (opcional)</label>
            <Controller
              control={control}
              name="ownerAddress"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
          </Form.Field>
          <Form.Field>
            <label>Localidade/Bairro (opcional)</label>
            <Controller
              control={control}
              name="ownerNeighborhood"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group>
          <Form.Field width={8}>
            <label>Município (opcional)</label>
            <Controller
              control={control}
              name="ownerCity"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                  fluid
                />
              )}
            />
          </Form.Field>
          <Form.Field width={4}>
            <label>Estado (opcional)</label>
            <Controller
              control={control}
              name="ownerState"
              render={({ onChange, onBlur, value }) => (
                <StateSelect
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isSubmiting}
                  autoComplete="off"
                  isClearable
                />
              )}
            />
          </Form.Field>
          <Form.Field width={4}>
            <label>CEP (opcional)</label>
            <Controller
              control={control}
              name="ownerCep"
              render={({ onChange, onBlur, value }) => (
                <NumberFormat
                  onValueChange={(data) => {
                    onChange(data.value);
                  }}
                  onBlur={onBlur}
                  value={value}
                  format={MASK_CEP}
                  mask="_"
                  isNumericString
                  allowEmptyFormatting
                  maxLength={18}
                  disabled={isSubmiting}
                  autoComplete="new-password"
                />
              )}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      <Divider hidden />
      <Button form={FORM_ID} loading={isSubmiting} primary>
        {submitButtonText}
      </Button>
    </>
  );
}
