import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input } from 'semantic-ui-react';
import { object, string } from 'yup';

import InputError from 'components/inputs/InputError';

const required = 'é um campo obrigatório';

const schema = object().shape({
  name: string()
    .required(`Nome ${required}`)
    .min(3, 'Nome deve conter mais de 2 caracteres')
    .max(100, 'Nome deve conter até 100 caracteres'),
  insideCode: string()
    .required(`Código interno ${required}`)
    .length(3, 'Código interno deve conter 3 caracteres'),
});

const INITIAL_VALUES = {
  name: '',
  insideCode: '',
};

export default function TargetForm({
  formValues = undefined,
  isSubmiting = false,
  onSubmit,
}) {
  const isEditing = !!formValues;

  const defaultValues = formValues || INITIAL_VALUES;
  const resolver = yupResolver(schema);

  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Field>
        <label>Nome científico</label>
        <Controller
          control={control}
          name="name"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              loading={isSubmiting}
              autoComplete="off"
              fluid
            />
          )}
        />
        {errors.name && <InputError>{errors?.name?.message}</InputError>}
      </Form.Field>
      <Form.Field>
        <label>Código interno</label>
        <Controller
          control={control}
          name="insideCode"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="Ex: abc"
              disabled={isSubmiting}
              loading={isSubmiting}
              maxLength={3}
              autoComplete="off"
              fluid
            />
          )}
        />
        {errors.insideCode && (
          <InputError>{errors?.insideCode?.message}</InputError>
        )}
      </Form.Field>
      <Button
        type="submit"
        style={{ marginTop: 20 }}
        loading={isSubmiting}
        primary
      >
        {submitButtonText}
      </Button>
    </Form>
  );
}
