import React from 'react';

import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Input,
} from 'semantic-ui-react';

import {
  useExportSaleProducts,
  useInfiniteSaleProducts,
} from 'data/sale-product';
import { useAuth } from 'hooks/auth';
import { useModalWithData } from 'hooks/useModal';
import debounce from 'util/debounce';

import SaleProductModal from './SaleProductModal';
import SaleProductTable from './SaleProductTable';

const MESSAGES = {
  loading: 'Exportando pragas...',
  success: 'Pragas exportadas!',
  error: 'Não foi possível exportar as pragas. Tente novamente.',
} as const;

export default function SaleProducts() {
  const [search, setSearch] = React.useState('');

  const { control } = useForm({ mode: 'onBlur' });

  const { user } = useAuth();
  const setSearchDebounced = debounce(setSearch, 1000);
  const saleProductModal = useModalWithData();

  const {
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProjects,
    pagination,
    saleProducts,
  } = useInfiniteSaleProducts({ description: search, pageSize: 10 });

  const exportSaleProducts = useExportSaleProducts();

  function handleEditClick(saleProductId: number) {
    const saleProduct = saleProducts.find(({ id }) => id === saleProductId);
    if (!saleProduct) return;
    const formValues = {
      description: saleProduct.description,
      ncm: saleProduct.ncmCode,
      measurementUnit: {
        id: saleProduct.measurementUnitId,
        description: saleProduct.measurementUnit,
        abbreviation: saleProduct.measurementUnit,
      },
      unitPrice: saleProduct.unitPrice / 100,
    };
    saleProductModal.open({ saleProductId, formValues });
  }

  function handleExportClick(fileFormat: 'pdf' | 'csv') {
    const promise = exportSaleProducts.mutateAsync({
      description: search,
      fileFormat,
    });
    toast.promise(promise, MESSAGES);
  }

  const options = [
    {
      key: 'export-pdf',
      text: 'PDF',
      icon: 'file pdf',
      onClick: () => handleExportClick('pdf'),
    },
    {
      key: 'export-csv',
      text: 'CSV',
      icon: 'file excel',
      onClick: () => handleExportClick('csv'),
    },
  ];

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h1">Pragas</Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign="right">
            <Dropdown
              text="Exportar"
              disabled={exportSaleProducts.isLoading}
              basic
              button
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {options.map((option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Dropdown.Item {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
            {user?.isAdmin ? (
              <Button primary onClick={saleProductModal.open}>
                <Icon name="add" />
                Nova praga
              </Button>
            ) : null}
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column width={6}>
            <Controller
              control={control}
              name="description"
              render={({ onChange, onBlur, value }) => (
                <Input
                  autoComplete="new-password"
                  fluid
                  icon="search"
                  iconPosition="left"
                  onBlur={onBlur}
                  onChange={(e, data) => {
                    setSearchDebounced(data.value);
                    onChange(e, data);
                  }}
                  placeholder="Busque pela descrição..."
                  value={value}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <SaleProductTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoadingProjects}
              onEditClick={handleEditClick}
              pagination={pagination}
              saleProducts={saleProducts}
              showActions={user.isAdmin}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {saleProductModal.isOpen ? (
        <SaleProductModal
          formValues={saleProductModal?.data?.formValues}
          isOpen={saleProductModal.isOpen}
          onClose={saleProductModal.close}
          saleProductId={saleProductModal?.data?.saleProductId}
        />
      ) : null}
    </>
  );
}
