import React from 'react';

import { Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import Section from 'components/layout/Section';
import { DivisionQuery } from 'queries/division';

type DivisionTableProps = {
  isLoading?: boolean;
  items: DivisionQuery[];
  onChangeClick: (responsibleId: number) => void;
};

export function DivisionTable({
  isLoading = false,
  items,
  onChangeClick,
}: DivisionTableProps) {
  const pagination = {
    page: 1,
    pageSize: items.length,
    total: items.length,
    totalPages: 1,
  };
  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Setores" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="small" singleLine basic="very" unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Descrição</Table.HeaderCell>
              <Table.HeaderCell>Descrição (Int.)</Table.HeaderCell>
              <Table.HeaderCell>Código interno</Table.HeaderCell>
              <Table.HeaderCell>Responsável</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={3} rows={5} />}
            {!isLoading &&
              items.map(
                ({
                  id,
                  description,
                  descriptionInt,
                  insideCode,
                  responsible,
                }) => {
                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell>{description}</Table.Cell>
                      <Table.Cell>{descriptionInt}</Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{insideCode}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{responsible}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <LinkButton onClick={() => onChangeClick(id)}>
                          Alterar
                        </LinkButton>
                      </Table.Cell>
                    </Table.Row>
                  );
                },
              )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
