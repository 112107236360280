import * as React from 'react';

import { Header, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { formatDivision } from 'queries/division';
import * as Currency from 'util/Currency';

type ContractProductItem = {
  id: number;
  amount: number;
  description: string;
  division: number;
  ncm: {
    id: number;
    code: string;
  };
  number: number;
  quantity: number;
  quantityDelivered: number;
  quotationId: number;
  totalAmount: number;
  unit: { id: number; abbreviation: string };
  unitPrice: number;
};

export type ContractProductItemTable = {
  currency: number;
  isLoading?: boolean;
  items: ContractProductItem[];
};

function ContractProductItemTable({
  isLoading = false,
  items = [],
}: ContractProductItemTable) {
  return (
    <Section>
      <Section.Header>
        <Header as="h3">Itens</Header>
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={3}>Descrição</Table.HeaderCell>
              <Table.HeaderCell>NCM</Table.HeaderCell>
              <Table.HeaderCell>Setor</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Qtd. total</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">
                Qtd. entregue
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor unit.</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor total</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={5} rows={5} />}
            {!isLoading &&
              items?.map(
                ({
                  description,
                  division,
                  id,
                  ncm,
                  number,
                  quantity,
                  quantityDelivered,
                  totalAmount,
                  unit,
                  unitPrice,
                }) => {
                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell width={1}>{number}</Table.Cell>
                      <Table.Cell width={3}>
                        <Text variant="primary">{description}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{ncm.code}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {formatDivision({ division })}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {quantity} {unit.abbreviation}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {quantityDelivered} {unit.abbreviation}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {Currency.formatWithSymbol({
                            amount: unitPrice,
                            currency: Currency.Currencies.REAL,
                          })}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {Currency.formatWithSymbol({
                          amount: totalAmount,
                          currency: Currency.Currencies.REAL,
                        })}
                      </Table.Cell>
                    </Table.Row>
                  );
                },
              )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

export default ContractProductItemTable;
