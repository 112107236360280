import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Form, Grid, Header, Input, Modal } from 'semantic-ui-react';

import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import InputError from 'components/inputs/InputError';
import { useChangePassword, Users } from 'data/user';
import { ROLE_DESCRIPTION } from 'hooks/use-auth';

import {
  ChangePasswordFormInput,
  ChangePasswordFormValues,
} from './validation';

export type ChangePasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: Users;
};

const formId = 'form_change_password';

const MESSAGE = {
  success: 'Senha alterada!',
} as const;

export default function ChangePasswordModal({
  isOpen,
  onClose,
  user,
}: ChangePasswordModalProps) {
  const [error, setError] = React.useState('');

  const resolver = yupResolver(ChangePasswordFormInput);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const changePassword = useChangePassword({
    onSuccess: () => handleSuccess(MESSAGE.success),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ChangePasswordFormValues) {
    if (!isEqual(values.newPassword, values.newPasswordConfirmation)) {
      setError('As senhas são diferentes');
      return;
    }

    const input = { userId: user.id, ...values };
    changePassword.mutate(input);
    setError('');
  }

  function isEqual(newPassword: string, newPasswordConfirmation: string) {
    return newPassword === newPasswordConfirmation;
  }

  const isLoading = changePassword.isLoading || changePassword.isLoading;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alteração de senha</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Header as="h3">Usuário</Header>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo label="Nome" text={user.employee.name} />
              </Grid.Column>
              <Grid.Column>
                <TextInfo label="Perfil" text={ROLE_DESCRIPTION[user.role]} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo
                  label="Setores"
                  text={
                    user.role === 'admin' ? (
                      <Tag color="green">Todos</Tag>
                    ) : (
                      <>
                        {user.employee.divisions.map((division) => (
                          <>
                            <Tag key={division.id} color="grey">
                              {division.description}
                            </Tag>
                            <span style={{ marginRight: 4 }} />
                          </>
                        ))}
                      </>
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Nova senha</label>
                    <Controller
                      control={control}
                      name="newPassword"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          autoComplete="new-password"
                          disabled={isLoading}
                          fluid
                        />
                      )}
                    />
                    {errors.newPassword && (
                      <InputError>{errors?.newPassword?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Confirmação da nova senha</label>
                    <Controller
                      control={control}
                      name="newPasswordConfirmation"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          autoComplete="new-password"
                          disabled={isLoading}
                          fluid
                        />
                      )}
                    />
                    {errors.newPasswordConfirmation && (
                      <InputError>
                        {errors?.newPasswordConfirmation?.message}
                      </InputError>
                    )}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
                {error && <InputError>{error}</InputError>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
