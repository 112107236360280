import React, { useState } from 'react';

import { Grid, Header } from 'semantic-ui-react';

import Base from 'components/layout/Base';

import ProjectForm from './ProjectForm';

export default function AddProject() {
  const [error] = useState('');

  return (
    <Base error={error} text>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">Novo estudo</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProjectForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Base>
  );
}
