import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export type ChangeProjectStageFormValues = yup.InferType<
  typeof ChangeProjectStageFormInput
>;

export const ChangeProjectStageFormInput = yup.object({
  projectStage: yup
    .object()
    .required('Obrigatório')
    .typeError('Obrigatório')
    .transform(emptyStringToUndefined),
});
