import React from 'react';

import toast from 'react-hot-toast';
import { Divider, Dropdown, Grid, Header } from 'semantic-ui-react';

import ActivityPerformModal from 'components/modal/ActivityPerformModal';
import { useModalWithData } from 'hooks/useModal';
import {
  useExportActivities,
  useExportActivitiesForField,
  useInfiniteActivities,
} from 'queries/activity';

import ActivityFilter from './ActivityFilter';
import ActivityTable from './ActivityTable';
import { useActivityFilter } from './use-activity-filter';

const MESSAGES = {
  loading: 'Exportando atividades...',
  success: 'Atividades exportadas!',
  error: 'Não foi possível exportar as atividades. Tente novamente.',
} as const;

export default function Activities() {
  const { getMappedFilter } = useActivityFilter();
  const activityModal = useModalWithData<number>();

  const {
    activities,
    pagination,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteActivities({ ...getMappedFilter(), inProgress: true });

  const exportActivities = useExportActivities();
  const exportActivitiesForField = useExportActivitiesForField();

  function handleExportClick() {
    const promise = exportActivities.mutateAsync(getMappedFilter());
    toast.promise(promise, MESSAGES);
  }

  function handleExportForFieldClick() {
    const promise = exportActivitiesForField.mutateAsync(getMappedFilter());
    toast.promise(promise, MESSAGES);
  }

  function handleProjectClick(activityId: number) {
    activityModal.open(activityId);
  }

  const isExporting =
    exportActivities.isLoading || exportActivitiesForField.isLoading;
  const options = [
    {
      key: 'export',
      text: 'Atividades',
      value: 'export',
      icon: 'download',
      onClick: handleExportClick,
    },
    {
      key: 'export-for-field',
      text: 'Atividades para campo',
      value: 'export-for-field',
      icon: 'download',
      onClick: handleExportForFieldClick,
    },
  ];

  return (
    <>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column>
            <Header as="h1">Atividades</Header>
          </Grid.Column>
          <Grid.Column textAlign="right" verticalAlign="middle">
            <Dropdown
              basic
              button
              direction="left"
              floating
              disabled={isExporting}
              text="Exportar"
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {options.map((option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Dropdown.Item {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <ActivityFilter />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ActivityTable
              activities={activities}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoading}
              onClick={handleProjectClick}
              pagination={pagination}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {activityModal.isOpen && activityModal.data ? (
        <ActivityPerformModal
          activityId={activityModal.data}
          isOpen={activityModal.isOpen}
          onClose={activityModal.close}
        />
      ) : null}
    </>
  );
}
