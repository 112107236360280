import React, { useContext, createContext, useState } from 'react';

type Option = {
  value: number;
  label: string;
};

type ShippingFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  status?: Option;
};

type ShippingMapped = {
  page?: number | undefined;
  pageSize?: number | undefined;
  orderBy?: string | undefined;
  sort?: string | undefined;
};

export type ShippingFilterContextData = {
  filter: ShippingFilter;
  updateFilter: (newData: ShippingFilter) => void;
  getMappedFilter: () => ShippingMapped;
};

export const shippingStatusOptions = [
  { value: 'pending', label: 'Pendentes' },
  { value: 'sent', label: 'Concluídas' },
  { value: '', label: 'Todos' },
] as const;

const defaultStatus = shippingStatusOptions[0];

export const ShippingFilterContextDefaultValues = {
  filter: {
    page: 1,
    pageSize: 10,
    status: defaultStatus,
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
};

export const ShippingFilterContext = createContext<ShippingFilterContextData>(
  ShippingFilterContextDefaultValues,
);

export type ShippingFilterProviderProps = {
  children: React.ReactNode;
};

const ShippingFilterProvider = ({ children }: ShippingFilterProviderProps) => {
  const [shippingFilter, setShippingFilter] = useState<ShippingFilter>(
    ShippingFilterContextDefaultValues.filter,
  );

  const updateFilter = (data: ShippingFilter) => {
    const newFilter = { ...shippingFilter, ...data };
    setShippingFilter(newFilter);
  };

  const getMappedFilter = () => {
    const { status, ...filterUpdated } = shippingFilter;
    return {
      ...filterUpdated,
      status: status?.value || '',
    };
  };

  return (
    <ShippingFilterContext.Provider
      value={{
        filter: shippingFilter,
        updateFilter,
        getMappedFilter,
      }}
    >
      {children}
    </ShippingFilterContext.Provider>
  );
};

const useShippingFilter = () => useContext(ShippingFilterContext);

export { ShippingFilterProvider, useShippingFilter };
