/* eslint-disable eqeqeq */
import axios, { AxiosInstance } from 'axios';

export type Pagination = {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
};

export type Response<T> = {
  data: T[];
  pagination: Pagination;
};

type Sort = 'asc' | 'desc';

export type BasicQuery = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: Sort;
};

export type ExportResponse = {
  file: Blob;
  filename: string;
};

export type ApiInstance = AxiosInstance;

const baseURL = process.env.REACT_APP_API_URL;

const api = axios.create({ baseURL });

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (axiosError) => {
    const error = axiosError;

    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') != -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result as string);
          resolve(Promise.reject(error));
        };

        reader.onerror = () => {
          reject(error);
        };

        reader.readAsText(error.response.data);
      });
    }

    return Promise.reject(error);
  },
);

export const emptyPagination = {
  page: 1,
  pageSize: 0,
  total: 0,
  totalPages: 1,
} as const;

export default api;
