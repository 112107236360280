import { array, object, string } from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export const schema = object().shape({
  title: string()
    .max(1500, `O número máximo de caracteres é 1500`)
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  intro: string()
    .max(6000, `O número máximo de caracteres é 6000`)
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  objective: string()
    .max(2000, `O número máximo de caracteres é 2000`)
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  applicationMethodology: string()
    .max(2000, `O número máximo de caracteres é 2000`)
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
  references: array()
    .of(
      object().shape({
        description: string().required('Obrigatório'),
      }),
    )
    .optional(),
});
