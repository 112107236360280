import * as React from 'react';

import { Icon } from 'semantic-ui-react';
import styled, { css } from 'styled-components';

type IconItemProps = {
  icon: string;
  label: string;
  onClick?: () => void;
};

export default function IconItem({ icon, label, onClick }: IconItemProps) {
  return (
    <Wrapper>
      <IconWrapper>
        <StyledIcon name={icon} size="small" />
      </IconWrapper>
      <Label onClick={onClick}>{label}</Label>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 0 0;
  margin-right: 24px;
  color: hsl(214, 7%, 80%);
  font-size: 1.6rem;
`;

const StyledIcon = styled(Icon)`
  color: #646464;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  padding: 10px;
  background-color: #ececec;
  border-radius: 50%;

  ${StyledIcon} {
    margin: 0px;
  }
`;

const Label = styled.span<{ onClick?: () => void }>`
  ${({ onClick }) => css`
    margin-left: 0.8rem;
    font-size: 1.6rem;
    color: ${onClick ? '#4a9add' : 'hsl(214, 7%, 47%)'};

    &:hover {
      cursor: ${onClick ? 'pointer' : 'auto'};
    }
  `}
`;
