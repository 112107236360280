import * as React from 'react';

import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import {
  Divider,
  Dropdown,
  DropdownMenu,
  Form,
  Grid,
  Header,
} from 'semantic-ui-react';

import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import DivisionSelect from 'components/inputs/DivisionSelect';
import {
  useExportProfitSharingProjects,
  useGenerateProfitSharingDocument,
  useInfiniteProfitSharingProjects,
} from 'queries/project';
import debounce from 'util/debounce';

import ProfitSharingTable from './ProfitSharingTable';
import { useProfitSharingFilter } from './use-profit-sharing-filter';

export default function ProfitSharing() {
  const { filter, getMappedFilter, updateFilter } = useProfitSharingFilter();

  const { control } = useForm({ defaultValues: filter });

  const debouncedFilter = debounce(updateFilter, 600);

  const {
    pagination,
    projects,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteProfitSharingProjects(getMappedFilter());

  const {
    mutate: exportProfitSharingProjects,
    isLoading: isExportingProfitSharingProjects,
  } = useExportProfitSharingProjects({
    onSuccess: () => {
      toast.success('PLR exportado!');
    },
  });

  const {
    mutate: generateProfitSharingDocument,
    isLoading: isGeneratingProfitSharingDocument,
  } = useGenerateProfitSharingDocument({
    onSuccess: () => {
      toast.success('PDF gerado!');
    },
  });

  const exportOptions = [
    {
      key: 'export-csv',
      text: 'CSV',
      icon: 'file excel',
      onClick: () => exportProfitSharingProjects(getMappedFilter()),
    },
    {
      key: 'export-pdf',
      text: 'PDF',
      icon: 'file pdf',
      onClick: () => generateProfitSharingDocument(getMappedFilter()),
    },
  ];

  return (
    <Grid>
      <Grid.Row columns="equal">
        <Grid.Column>
          <Header as="h1">PLR</Header>
        </Grid.Column>
        <Grid.Column textAlign="right" verticalAlign="middle">
          <Dropdown
            basic
            button
            floating
            loading={
              isExportingProfitSharingProjects ||
              isGeneratingProfitSharingDocument
            }
            text="Exportar para"
          >
            <DropdownMenu>
              <DropdownMenu scrolling>
                {exportOptions.map((option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <Dropdown.Item {...option} />
                ))}
              </DropdownMenu>
            </DropdownMenu>
          </Dropdown>
        </Grid.Column>
      </Grid.Row>
      <Divider hidden />
      <Grid.Row>
        <Grid.Column>
          <Form>
            <Form.Group>
              <Form.Field width={2}>
                <Controller
                  control={control}
                  name="year"
                  render={({ onChange, onBlur, value }) => (
                    <DatePicker
                      dateFormat="yyyy"
                      onBlur={onBlur}
                      onChange={(date: Date) => {
                        debouncedFilter({ year: date });
                        onChange(date);
                      }}
                      selected={value}
                      showYearPicker
                      yearItemNumber={9}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="month"
                  render={({ onChange, onBlur, value }) => (
                    <Select
                      instanceId="month"
                      isClearable
                      name="month"
                      onBlur={onBlur}
                      onChange={(data) => {
                        debouncedFilter({ month: data });
                        onChange(data);
                      }}
                      options={[
                        { value: 1, label: 'Janeiro' },
                        { value: 2, label: 'Fevereiro' },
                        { value: 3, label: 'Março' },
                        { value: 4, label: 'Abril' },
                        { value: 5, label: 'Maio' },
                        { value: 6, label: 'Junho' },
                        { value: 7, label: 'Julho' },
                        { value: 8, label: 'Agosto' },
                        { value: 9, label: 'Setembro' },
                        { value: 10, label: 'Outubro' },
                        { value: 11, label: 'Novembro' },
                        { value: 12, label: 'Dezembro' },
                      ]}
                      placeholder="Mês"
                      value={value}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field width={3}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="divisions"
                  render={({ onChange, onBlur, value }) => (
                    <DivisionSelect
                      isClearable
                      isMulti
                      onBlur={onBlur}
                      onChange={(data) => {
                        debouncedFilter({ divisions: data });
                        onChange(data);
                      }}
                      placeholder="Setor"
                      value={value}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field width={4}>
                <Controller
                  control={control}
                  defaultValue=""
                  name="customer"
                  render={({ onChange, onBlur, value }) => (
                    <CustomerSearch
                      isClearable
                      onBlur={onBlur}
                      onChange={(data) => {
                        debouncedFilter({ customer: data });
                        onChange(data);
                      }}
                      placeholder="Cliente"
                      value={value}
                    />
                  )}
                />
              </Form.Field>
              <Form.Field width={4}>
                {/* <ProfitSharingStatusSelect
                  isClearable
                  onChange={handleStatusChange}
                  placeholder="Status"
                  value={status}
                /> */}
                <Controller
                  control={control}
                  defaultValue=""
                  name="status"
                  render={({ onChange, onBlur, value }) => (
                    <Select
                      instanceId="status"
                      name="status"
                      onBlur={onBlur}
                      onChange={(data) => {
                        debouncedFilter({ status: data });
                        onChange(data);
                      }}
                      options={[
                        { value: 1, label: 'Em andamento' },
                        { value: 2, label: 'Todos' },
                      ]}
                      placeholder="Status"
                      value={value}
                    />
                  )}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <ProfitSharingTable
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isLoading={isLoading}
            items={projects}
            pagination={pagination}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
