import * as React from 'react';

import toast from 'react-hot-toast';
import { Button, Modal } from 'semantic-ui-react';

import { useUpdateCustomerAddress } from 'mutations/customer';
import CustomerAddressForm, {
  CustomerAddressFormValues,
} from 'templates/Customers/CustomerFormModal/CustomerAddressForm';

export type CustomerAddressModalProps = {
  customerId: number;
  formValues?: CustomerAddressFormValues;
  isOpen: boolean;
  onClose: () => void;
};

const formId = 'form_product_item';

export default function CustomerAddressModal({
  customerId,
  formValues,
  isOpen,
  onClose,
}: CustomerAddressModalProps) {
  const addressFormRef = React.useRef<HTMLFormElement>(null);

  const updateCustomerAddress = useUpdateCustomerAddress({
    onSuccess: () => handleSuccess('Enderaço salvo!'),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: CustomerAddressFormValues) {
    const input = { ...values, customerId };
    updateCustomerAddress.mutate(input);
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Edição de endereço</Modal.Header>
      <Modal.Content>
        <CustomerAddressForm
          ref={addressFormRef}
          defaultValues={{ ...formValues }}
          onSubmit={onSubmit}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={updateCustomerAddress.isLoading}
          form={formId}
          loading={updateCustomerAddress.isLoading}
          onClick={() => addressFormRef.current?.handleSubmit()}
          primary
          type="submit"
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
