import React from 'react';

import { Table, Popup } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';

export default function TargetsTable({ targets = [], onEditClick, loading }) {
  return (
    <Table size="small" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={8}>Nome</Table.HeaderCell>
          <Table.HeaderCell width={2}>Cód.</Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="center" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={4} rows={5} />}
        {!loading &&
          targets.map((target) => (
            <Table.Row key={target.id.toString()}>
              <Table.Cell>{target.name}</Table.Cell>
              <Table.Cell>
                {target.insideCode
                  ? target.insideCode.charAt(0).toUpperCase() +
                    target.insideCode.slice(1)
                  : ''}
              </Table.Cell>
              {onEditClick ? (
                <Table.Cell textAlign="center">
                  <Popup
                    trigger={
                      <LinkButton
                        type="button"
                        onClick={() => onEditClick(target)}
                      >
                        Editar
                      </LinkButton>
                    }
                    content="Editar"
                    mouseEnterDelay={500}
                    inverted
                  />
                </Table.Cell>
              ) : null}
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}
