import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Form, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import { useGenerateAssignmentArea } from 'queries/location';
import { downloadFile } from 'util/FileManager';

type FormValues = {
  date: Date;
};

export type AssignmentAreaGenerationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  locationId: number;
};

const formId = 'form_send_to_review';

const schema = yup.object({
  date: yup.date().required('Obrigatório'),
});

const MESSAGES = {
  loading: 'Gerando termo...',
  success: 'Termo gerado!',
  error: 'Não foi possível gerar o termo. Tente novamente.',
} as const;

export default function AssignmentAreaGenerationModal({
  isOpen,
  onClose,
  locationId,
}: AssignmentAreaGenerationModalProps) {
  const defaultValues = { date: new Date() };
  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const { mutateAsync, isLoading } = useGenerateAssignmentArea({
    onSuccess: ({ file, filename }) => {
      downloadFile(file, filename);
    },
  });

  function onSubmit({ date }: FormValues) {
    const promise = mutateAsync({ date, locationId });
    toast.promise(promise, MESSAGES);
    onClose();
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Termo de cessão de área</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Data do documento</label>
            <Controller
              control={control}
              name="date"
              render={({ onChange, onBlur, value }) => (
                <DatePicker
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  disabled={isLoading}
                />
              )}
            />
            {errors.date && <InputError>{errors?.date?.message}</InputError>}
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Gerar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
