import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, Input, Divider } from 'semantic-ui-react';
import { object, string } from 'yup';

import InputError from 'components/inputs/InputError';
import RadioGroup from 'components/inputs/RadioGroup';
import RadioInput from 'components/inputs/RadioInput';
import { JobPositionDescription, JobPosition } from 'util/Employee';

const required = 'é um campo obrigatório';

const schema = object().shape({
  firstName: string().required(`Nome ${required}`),
  lastName: string().required(`Sobrenome ${required}`),
  jobPosition: string().required(`Posição ${required}`),
  creaSp: string().optional(),
});

const INITIAL_VALUES = {
  firstName: '',
  lastName: '',
  creaSp: '',
};

export default function EmployeeForm({ onSubmit, formValues, isSubmiting }) {
  const formRef = React.useRef(null);

  const defaultValues = formValues || INITIAL_VALUES;
  const resolver = yupResolver(schema);

  const { control, errors, register, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  const isEditing = !!formValues;
  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';

  return (
    <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Nome</label>
          <Controller
            control={control}
            name="firstName"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors?.firstName && (
            <InputError>{errors?.firstName?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Sobrenome</label>
          <Controller
            control={control}
            name="lastName"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={isSubmiting}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors?.lastName && (
            <InputError>{errors?.lastName?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Field>
        <label>Posição</label>
        <RadioGroup>
          <RadioInput
            name="jobPosition"
            label={JobPositionDescription[JobPosition.Administrator]}
            value={JobPosition.Administrator}
            ref={register}
            defaultChecked
          />
          <RadioInput
            name="jobPosition"
            label={JobPositionDescription[JobPosition.Researcher]}
            value={JobPosition.Researcher}
            ref={register}
          />
          <RadioInput
            name="jobPosition"
            label={JobPositionDescription[JobPosition.Analyst]}
            value={JobPosition.Analyst}
            ref={register}
          />
          <RadioInput
            name="jobPosition"
            label={JobPositionDescription[JobPosition.Technician]}
            value={JobPosition.Technician}
            ref={register}
          />
        </RadioGroup>
      </Form.Field>
      <Form.Field>
        <label>CREA-SP (Opcional)</label>
        <Controller
          control={control}
          name="creaSp"
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              disabled={isSubmiting}
              autoComplete="off"
              fluid
            />
          )}
        />
      </Form.Field>
      <Divider hidden />
      <Button type="submit" size="large" loading={isSubmiting} primary>
        {submitButtonText}
      </Button>
    </Form>
  );
}
