import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import {
  Modal,
  Button,
  Message,
  Form,
  Header,
  Input,
  Grid,
  Divider,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import * as yup from 'yup';

import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useUpload } from 'hooks/use-upload';
import { useAddLocation, useUpdateLocation } from 'mutations/project';
import { useLocationWithFiles } from 'queries/location';
import { PropertyQuery } from 'queries/property';
import { emptyStringToUndefined } from 'util/validation';

import PropertySearch from './PropertySearch';

type FileEvent = React.ChangeEvent<HTMLInputElement>;

export type LocationFormValues = {
  latitude: string;
  longitude: string;
  altitude: number;
  property: PropertyQuery;
};

export type LocationModalFormProps = {
  projectId: number;
  locationId?: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

const required = 'é um campo obrigatório';

const schema = yup.object().shape({
  latitude: yup.string().required(`Latitude ${required}`),
  longitude: yup.string().required(`Longitude ${required}`),
  altitude: yup.string().required(`Altitude ${required}`),
  property: yup
    .object()
    .required(`Propriedade ${required}`)
    .transform(emptyStringToUndefined),
});

const documentTypeAccessSketch = 9;
const documentTypeLocationSketch = 10;
const formId = 'form_location';

export default function LocationModalForm({
  projectId,
  locationId,
  isOpen,
  onClose,
  onSuccess,
}: LocationModalFormProps) {
  const { handleError } = useErrorHandler();

  const accessSketchFile = useUpload();
  const locationSketchFile = useUpload();

  const resolver = yupResolver(schema);
  const { control, errors, watch, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });
  const watchProperty = watch('property');

  const {
    mutate: addLocation,
    isLoading: isAdding,
    error: createError,
  } = useAddLocation({
    onSuccess: () => handleSuccess('Localização adicionada com sucesso!'),
  });
  const {
    mutate: updateLocation,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateLocation({
    onSuccess: () => handleSuccess('Localização atualizada com sucesso!'),
  });
  const { data: location, isLoading } = useLocationWithFiles(
    projectId,
    locationId!,
  );

  const { setFile: setAccessSketchFile } = accessSketchFile;
  const { setFile: setLocationSketchFile } = locationSketchFile;

  React.useEffect(() => {
    if (location) {
      setValue('latitude', location.latitude);
      setValue('longitude', location.longitude);
      setValue('altitude', location.altitude);
      setValue('property', location.property);
      setAccessSketchFile(location.accessSketchFile);
      setLocationSketchFile(location.locationSketchFile);
    }
  }, [location, setValue, setAccessSketchFile, setLocationSketchFile]);

  function handleSuccess(message: string) {
    onSuccess(message);
  }

  function onSubmit(values: LocationFormValues) {
    if (
      !accessSketchFile.file?.url ||
      !locationSketchFile.file?.url ||
      typeof accessSketchFile.file?.id === 'string' ||
      typeof locationSketchFile.file?.id === 'string'
    ) {
      return;
    }

    const input = {
      latitude: values.latitude,
      longitude: values.longitude,
      altitude: values.altitude,
      propertyId: values.property.id,
      accessSketchId: accessSketchFile.file?.id,
      locationSketchId: locationSketchFile.file?.id,
      projectId,
    };

    if (locationId) {
      updateLocation({ ...input, locationId });
    } else {
      addLocation(input);
    }
  }

  function handleAccessSketchFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    accessSketchFile.handleUpload(file, documentType);
  }

  function handleLocationFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    locationSketchFile.handleUpload(file, documentType);
  }

  const buttonText = locationId ? 'Salvar' : 'Adicionar';
  const isSubmitting = isAdding || isUpdating;
  const disabledButton =
    !accessSketchFile.file?.url || !locationSketchFile.file?.url;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>Localização</Modal.Header>
      <Modal.Content>
        {locationId && isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as="h3">Localização</Header>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <label>Latitude</label>
                      <Controller
                        control={control}
                        name="latitude"
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            maxLength={20}
                            autoComplete="off"
                            fluid
                          />
                        )}
                      />
                      {errors.latitude && (
                        <InputError>{errors?.latitude?.message}</InputError>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label>Longitude</label>
                      <Controller
                        control={control}
                        name="longitude"
                        render={({ onChange, onBlur, value }) => (
                          <Input
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            maxLength={20}
                            autoComplete="off"
                            fluid
                          />
                        )}
                      />
                      {errors.longitude && (
                        <InputError>{errors?.longitude?.message}</InputError>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label>Altitude</label>
                      <Controller
                        control={control}
                        name="altitude"
                        render={({ onChange, onBlur, value }) => (
                          <NumberFormat
                            onValueChange={(data) => {
                              onChange(data.floatValue);
                            }}
                            onBlur={onBlur}
                            value={value}
                            placeholder="ex: 200"
                            decimalSeparator=","
                            thousandSeparator="."
                            suffix=" m"
                            isNumericString
                            decimalScale={0}
                            disabled={isSubmitting}
                            autoComplete="off"
                          />
                        )}
                      />
                      {errors.altitude && (
                        <InputError>{errors?.altitude?.message}</InputError>
                      )}
                    </Form.Field>
                  </Form.Group>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as="h3">Propriedade</Header>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Group>
                    <Form.Field width={12}>
                      <label>Propriedade</label>
                      <Controller
                        control={control}
                        name="property"
                        render={({ onChange, onBlur, value }) => (
                          <PropertySearch
                            name="property"
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                            disabled={isSubmitting}
                          />
                        )}
                      />
                      {errors.property && (
                        <InputError>{errors?.property?.message}</InputError>
                      )}
                    </Form.Field>
                    <Form.Field width={4}>
                      <HiddenLabel />
                      <Link to="/propriedades/novo" target="_blank">
                        <Button type="button" basic primary fluid>
                          <Icon name="add" />
                          Nova
                        </Button>
                      </Link>
                    </Form.Field>
                  </Form.Group>
                  <Form.Field width={12}>
                    <label>Proprietário</label>
                    <Input
                      value={watchProperty?.owner}
                      loading={isSubmitting}
                      autoComplete="off"
                      disabled
                      fluid
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column width={6}>
                  <Header as="h3">Croquis</Header>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Field>
                    <label>Croquí de acesso a área do ensaio</label>
                    <UploadInput
                      name="accessSketch"
                      uploadedFile={accessSketchFile?.file}
                      onUpload={(e: FileEvent) =>
                        handleAccessSketchFileChange(
                          e,
                          documentTypeAccessSketch,
                        )
                      }
                      onDelete={accessSketchFile.handleDelete}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>Croquí da área do ensaio</label>
                    <UploadInput
                      name="locationSketch"
                      uploadedFile={locationSketchFile?.file}
                      onUpload={(e: FileEvent) =>
                        handleLocationFileChange(e, documentTypeLocationSketch)
                      }
                      onDelete={locationSketchFile.handleDelete}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Form>
        )}
        {createError ||
          (updateError && (
            <Message
              content={handleError(createError || updateError)}
              negative
            />
          ))}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isSubmitting}
          disabled={isSubmitting || disabledButton}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
