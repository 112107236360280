import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
} from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/useModal';
import { useExportSamples } from 'mutations/sample';
import { useInfiniteSamples } from 'queries/sample';
import { downloadFile } from 'util/FileManager';

import SamplesFilter from './SampleFilter';
import SamplesDiscardModal from './SamplesDiscardModal';
import SamplesReturnModal from './SamplesReturnModal';
import SampleTable from './SampleTable';
import { useSampleFilter } from './use-sample-filter';

const MESSAGES = {
  loading: 'Exportando amostras...',
  success: 'Amostras exportadas!',
  error: 'Não foi possível exportar as amostras. Tente novamente.',
} as const;

export default function SamplesPage() {
  const history = useHistory();

  const discardModal = useModal();
  const returnModal = useModal();

  const { user } = useAuth();
  const { getMappedFilter } = useSampleFilter();

  const {
    pagination,
    samples,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteSamples(getMappedFilter());

  const exportSamples = useExportSamples({
    onSuccess: ({ file, filename }) => {
      onSuccess({ file, filename });
    },
  });

  function onSuccess({ file, filename }: { file: Blob; filename: string }) {
    downloadFile(file, filename);
  }

  function handleAddClick() {
    history.push('/amostras/novo');
  }

  function handleExportSamplesClick() {
    const promise = exportSamples.mutateAsync();
    toast.promise(promise, MESSAGES);
  }

  function handleDiscardClick() {
    discardModal.open();
  }

  function handleReturnClick() {
    returnModal.open();
  }

  const options = [
    {
      key: 'discard-samples',
      text: 'Descartar amostras',
      value: 'discard-samples',
      icon: 'recycle',
      onClick: handleDiscardClick,
    },
    {
      key: 'return-samples',
      text: 'Devolver amostras',
      value: 'return-samples',
      icon: 'refresh',
      onClick: handleReturnClick,
    },
  ];

  const reportOptions = [
    {
      key: 'export',
      text: 'Todas as amostras',
      value: 'export',
      icon: 'tasks',
      onClick: handleExportSamplesClick,
    },
  ];

  return (
    <>
      <Base>
        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign="left" verticalAlign="middle">
                <Header as="h1">Amostras</Header>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right" verticalAlign="middle">
                <Dropdown
                  text="Relatórios"
                  direction="left"
                  disabled={exportSamples.isLoading}
                  floating
                  basic
                  button
                >
                  <Dropdown.Menu>
                    <Dropdown.Menu scrolling>
                      {reportOptions.map((option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Dropdown.Item {...option} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown.Menu>
                </Dropdown>
                {user.isAdmin ? (
                  <Dropdown
                    text="Opções"
                    direction="left"
                    floating
                    basic
                    button
                  >
                    <Dropdown.Menu>
                      <Dropdown.Menu scrolling>
                        {options.map((option) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <Dropdown.Item {...option} />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                {user.isAdmin ? (
                  <Button type="button" onClick={handleAddClick} primary>
                    <Icon name="add" />
                    Nova amostra
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <SamplesFilter />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={1}>
              <Grid.Column>
                <SampleTable
                  fetchNextPage={fetchNextPage}
                  hasNextPage={hasNextPage}
                  isFetchingNextPage={isFetchingNextPage}
                  isLoading={isLoading}
                  samples={samples}
                  pagination={pagination}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Base>

      {discardModal.isOpen ? (
        <SamplesDiscardModal
          isOpen={discardModal.isOpen}
          onClose={discardModal.close}
        />
      ) : null}

      {returnModal.isOpen ? (
        <SamplesReturnModal
          isOpen={returnModal.isOpen}
          onClose={returnModal.close}
        />
      ) : null}
    </>
  );
}
