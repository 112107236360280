import React from 'react';

import toast from 'react-hot-toast';
import { Button, Divider, Grid, Header, Icon } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import { useExportCollections, useInfiniteCollections } from 'data/collection';
import { useModalWithData } from 'hooks/useModal';

import CollectionAssociationModal from './CollectionAssociationModal';
import CollectionSortingModal from './CollectionSortingModal';
import CollectionTable from './CollectionTable';

const defaultFilter = {
  page: 1,
  pageSize: 10,
  orderBy: 'startDate',
  sort: 'desc',
};

function Collections() {
  const sortingModal = useModalWithData<number>();
  const associateModal = useModalWithData<number>();

  const {
    collections,
    pagination,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteCollections(defaultFilter);
  const { mutate, isLoading: isExporting } = useExportCollections({
    onSuccess: () => {
      toast.success('Coletas exportadas com sucesso!');
    },
  });

  function handleExportClick() {
    mutate();
  }

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row columns={2} verticalAlign="middle">
            <Grid.Column>
              <Header as="h1">Coletas</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                type="button"
                onClick={handleExportClick}
                loading={isExporting}
                basic
              >
                <Icon name="download" />
                Exportar
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <CollectionTable
                fetchNextPage={fetchNextPage}
                hasNextPage={hasNextPage}
                isFetchingNextPage={isFetchingNextPage}
                isLoading={isLoading}
                items={collections || []}
                onSortingClick={(collectionId) =>
                  sortingModal.open(collectionId)
                }
                onAssociateClick={(collectionId) =>
                  associateModal.open(collectionId)
                }
                pagination={pagination}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>

      {sortingModal.isOpen && sortingModal?.data ? (
        <CollectionSortingModal
          collectionId={sortingModal?.data}
          isOpen={sortingModal.isOpen}
          onClose={sortingModal.close}
        />
      ) : null}

      {associateModal.isOpen && associateModal?.data ? (
        <CollectionAssociationModal
          collectionId={associateModal?.data}
          isOpen={associateModal.isOpen}
          onClose={associateModal.close}
        />
      ) : null}
    </Base>
  );
}

export default Collections;
