import React, { useState } from 'react';

import { Grid, Divider, Button, Icon, Header } from 'semantic-ui-react';

import { useAuth } from 'hooks/auth';

import InstallmentForm from './InstallmentForm';
import ProjectInstallmentsTable from './ProjectInstallmentsTable';

export default function ProjectInstallmentsPage({ project, contract }) {
  const [installment, setInstallment] = useState(null);
  const [installmentModal, setInstallmentModal] = useState(false);

  const { user } = useAuth();

  function handleAddClick() {
    setInstallmentModal(true);
  }

  const handleEditClick = async (payment) => {
    setInstallment(payment);
    setInstallmentModal(true);
  };

  const handleClose = () => {
    setInstallment(null);
    setInstallmentModal(false);
  };

  return (
    <>
      <InstallmentForm
        open={installmentModal}
        projectId={project?.id}
        currency={contract?.currency}
        installment={installment}
        onClose={handleClose}
      />

      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h2" floated="left">
              Parcelas
            </Header>
            {user.isAdmin ? (
              <Button floated="right" onClick={handleAddClick} basic>
                <Icon name="add" />
                Nova parcela
              </Button>
            ) : null}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column>
            <ProjectInstallmentsTable
              contract={contract}
              project={project}
              loading={!project || !contract}
              onEditClick={handleEditClick}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
    </>
  );
}
