import * as React from 'react';

import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import { OptionProps } from 'react-select/src/types';
import { Form, Input } from 'semantic-ui-react';

import ActivityTypeSelect from 'components/inputs/ActivityTypeSelect';
import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import DivisionSelect from 'components/inputs/DivisionSelect';
import { useAuth } from 'hooks/auth';
import debounce from 'util/debounce';

import {
  activityStatusOptions,
  useActivityFilter,
} from './use-activity-filter';

export default function ActivityFilter() {
  const { filter, updateFilter } = useActivityFilter();
  const { control } = useForm({ defaultValues: filter });

  const { user } = useAuth();

  const debouncedFilter = debounce(updateFilter, 600);

  return (
    <Form>
      <Form.Group>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="status"
            render={({ onChange, onBlur, value }) => (
              <Select
                instanceId="status"
                name="status"
                onBlur={onBlur}
                onChange={(data) => {
                  debouncedFilter({ status: data });
                  onChange(data);
                }}
                options={activityStatusOptions}
                placeholder="Status"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="type"
            render={({ onChange, onBlur, value }) => (
              <ActivityTypeSelect
                isClearable
                name="type"
                onBlur={onBlur}
                onChange={(data) => {
                  debouncedFilter({ type: data });
                  onChange(data);
                }}
                placeholder="Tipo"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="startDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onBlur={onBlur}
                onChange={(date: Date) => {
                  debouncedFilter({ startDate: date });
                  onChange(date);
                }}
                placeholderText="Data inicial"
                selected={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Controller
            control={control}
            name="endDate"
            render={({ onChange, onBlur, value }) => (
              <DatePicker
                onBlur={onBlur}
                onChange={(date: Date) => {
                  debouncedFilter({ endDate: date });
                  onChange(date);
                }}
                placeholderText="Data final"
                selected={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="insideCode"
            render={({ onChange, onBlur, value }) => (
              <Input
                autoComplete="off"
                fluid
                onBlur={onBlur}
                onChange={(e, data) => {
                  debouncedFilter({ insideCode: data.value });
                  onChange(e, data);
                }}
                placeholder="Código do estudo"
                value={value}
              />
            )}
          />
        </Form.Field>
        <Form.Field width={3}>
          <Controller
            control={control}
            name="customer"
            defaultValue=""
            render={({ onChange, onBlur, value }) => (
              <CustomerSearch
                isClearable
                name="customer"
                onBlur={onBlur}
                onChange={(data: OptionProps) => {
                  updateFilter({ customer: data || '' });
                  onChange(data);
                }}
                placeholder="Cliente"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={3}>
          <Controller
            control={control}
            defaultValue=""
            name="division"
            render={({ onChange, onBlur, value }) => (
              <DivisionSelect
                isClearable
                divisions={!user.isAdmin ? user.divisions : undefined}
                name="division"
                onBlur={onBlur}
                onChange={(data: OptionProps) => {
                  debouncedFilter({ division: data || '' });
                  onChange(data);
                }}
                placeholder="Setor"
                value={value}
              />
            )}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
}
