import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import api from 'util/api';

type CreatePropertyInput = {
  name: string;
  owner: string;
  ownerDocument: string;
  phone: string;
  address: string;
  neighborhood: string;
  city: string;
  state: string;
  cep: string;
};

type UseCreatePropertyOptions = UseMutationOptions<
  number,
  AxiosError,
  CreatePropertyInput
>;

export function useCreateProperty(options?: UseCreatePropertyOptions) {
  const queryClient = useQueryClient();
  return useMutation(createProperty, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('properties');
      queryClient.invalidateQueries('property');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createProperty(input: CreatePropertyInput) {
  const response = await api.post(`/properties`, input);
  const { location } = response.headers;
  const propertyId = parseInt(location.substring(15), 10);
  return propertyId;
}

type UpdatePropertyInput = CreatePropertyInput & {
  propertyId: number;
};

type UseUpdatePropertyOptions = UseMutationOptions<
  void,
  AxiosError,
  UpdatePropertyInput
>;

export function useUpdateProperty(options: UseUpdatePropertyOptions) {
  const queryClient = useQueryClient();
  return useMutation(updateProperty, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('properties');
      queryClient.invalidateQueries('property');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export async function updateProperty(params: UpdatePropertyInput) {
  await api.patch(`/properties/${params.propertyId}`, params);
}
