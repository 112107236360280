import { useQuery } from 'react-query';

import { getDocumentInput } from 'hooks/document';
import api from 'util/api';
import { Document } from 'util/Document';

export type UseEvaluationsParams = {
  projectId: number;
  page: number;
  pageSize: number;
  orderBy: string;
  sort: string;
};

export function useEvaluations(params: UseEvaluationsParams) {
  const { projectId, ...filter } = params;

  return useQuery(['evaluations', params], () => {
    return getEvaluations(projectId, filter);
  });
}

async function getEvaluations(projectId: number, filter: any) {
  const response = await api.get(`/projects/${projectId}/evaluations`, {
    params: filter,
  });
  const { data: evaluations, pagination } = response.data;
  return { evaluations, pagination };
}

type EvaluationWithDocumentParams = {
  projectId: number;
  evaluationId: number;
};

export function useEvaluationWithDocument({
  projectId,
  evaluationId,
}: EvaluationWithDocumentParams) {
  return useQuery({
    queryKey: ['evaluationWithDocument', evaluationId],
    queryFn: () => getEvaluationWithDocument(projectId, evaluationId),
    enabled: !!projectId && !!evaluationId,
  });
}

type Evaluation = {
  id: number;
  date: Date;
  description: string;
  documents: Document[];
};

async function getEvaluationWithDocument(
  projectId: number,
  evaluationId: number,
) {
  const { data: evaluation } = await api.get<Evaluation>(
    `/projects/${projectId}/evaluations/${evaluationId}`,
  );

  const documentFiles = await Promise.all(
    evaluation.documents.map(async ({ id }) => getDocumentInput(id)),
  );

  return { evaluation, documentFiles };
}
