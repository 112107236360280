import React, { useContext, createContext, useState } from 'react';

import { WeatherFilter } from 'queries/weather';

type WeatherFilterProps = {
  startDate?: Date;
  endDate?: Date;
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
};

type WeatherFilterContextData = {
  filter: WeatherFilterProps;
  updateFilter: (newData: WeatherFilterProps) => void;
  getMappedFilter: () => WeatherFilter;
  resetFilter: () => void;
};

const WeatherFilterContextDefaultValues = {
  filter: {
    startDate: undefined,
    endDate: undefined,
    orderBy: 'date',
    page: 1,
    pageSize: 10,
    sort: 'asc',
  },
  updateFilter: () => null,
  getMappedFilter: () => null,
  resetFilter: () => null,
};

const WeatherFilterContext = createContext<WeatherFilterContextData>(
  WeatherFilterContextDefaultValues,
);

type WeatherFilterProviderProps = {
  children: React.ReactNode;
};

const WeatherFilterProvider = ({ children }: WeatherFilterProviderProps) => {
  const [activityFilter, setWeatherFilter] = useState<WeatherFilterProps>({
    page: 1,
    pageSize: 10,
    orderBy: 'date',
    sort: 'asc',
  });

  const updateFilter = (data: WeatherFilterProps) => {
    const newFilter = { ...activityFilter, ...data };
    setWeatherFilter(newFilter);
  };

  const getMappedFilter = () => {
    return {
      ...activityFilter,
    };
  };

  function resetFilter() {
    setWeatherFilter({
      startDate: new Date(),
      endDate: undefined,
      orderBy: 'date',
      page: 1,
      pageSize: 10,
      sort: 'asc',
    });
  }

  return (
    <WeatherFilterContext.Provider
      value={{
        filter: activityFilter,
        updateFilter,
        getMappedFilter,
        resetFilter,
      }}
    >
      {children}
    </WeatherFilterContext.Provider>
  );
};

const useWeatherFilter = () => useContext(WeatherFilterContext);

export { WeatherFilterProvider, useWeatherFilter };
