import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export default yup.object().shape({
  batchNumber: yup.string().required(`Obrigatório`),
  productName: yup.string().required(`Obrigatório`),
  productType: yup
    .object()
    .required(`Obrigatório`)
    .transform(emptyStringToUndefined),
  productCategory: yup
    .object()
    .required(`Obrigatório`)
    .transform(emptyStringToUndefined),
  formulation: yup
    .object()
    .required(`Obrigatório`)
    .transform(emptyStringToUndefined),
  arrivalDate: yup
    .date()
    .required(`Obrigatório`)
    .transform(emptyStringToUndefined),
  measurementUnit: yup
    .string()
    .required(`Obrigatório`)
    .transform(emptyStringToUndefined),
  packagings: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          volume: yup
            .number()
            .required(`Obrigatório`)
            .transform(emptyStringToUndefined),
        })
        .required(`Obrigatório`),
    )
    .required(`É necessário selecionar pelo menos um estudo`)
    .transform(emptyStringToUndefined),
  associatedProjects: yup
    .array()
    .of(
      yup
        .object()
        .shape({
          project: yup
            .object()
            .required('Selecione um estudo')
            .transform(emptyStringToUndefined),
          sampleGoal: yup
            .object()
            .required('Selecione a finalidade')
            .transform(emptyStringToUndefined),
        })
        .required(`Obrigatório`),
    )
    .optional()
    .transform(emptyStringToUndefined),
});
