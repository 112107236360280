import * as React from 'react';

import { Button, Divider, Dropdown, Icon, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { Users } from 'data/user';
import { ROLE_DESCRIPTION } from 'hooks/use-auth';
import { Pagination } from 'util/api';

import PaginationInfo from '../PaginationInfo';
import Tag from '../Tag';

const ROWS = 10;
const COLUMNS = 6;

type NextPageProps = {
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
};

export type UserTableProps = NextPageProps & {
  isLoading?: boolean;
  onChangeDivisionsClick: (userId: number) => void;
  onChangePasswordClick: (userId: number) => void;
  onChangeRoleClick: (userId: number) => void;
  onEditClick: (userId: number) => void;
  pagination: Pagination;
  users: Users[];
};

function UserTable({
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  isLoading = false,
  onChangeDivisionsClick,
  onChangePasswordClick,
  onChangeRoleClick,
  onEditClick,
  pagination,
  users = [],
}: UserTableProps) {
  if (!users.length) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Nenhum usuário</EmptyState.Header>
            <EmptyState.Description>
              Clique no botão abaixo para cadastrar um novo usuário
            </EmptyState.Description>
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  const options = [
    {
      key: 'enable-disable',
      text: 'Habilitar/Desabilitar',
      value: 'enable-disable',
      icon: 'toggle on',
      onClick: onEditClick,
    },
    {
      key: 'change-role',
      text: 'Alterar perfil',
      value: 'change-role',
      icon: 'address card',
      onClick: onChangeRoleClick,
    },
    {
      key: 'change-divisions',
      text: 'Alterar setores',
      value: 'change-divisions',
      icon: 'tags',
      onClick: onChangeDivisionsClick,
    },
    {
      key: 'change-password',
      text: 'Alterar senha',
      value: 'change-password',
      icon: 'lock',
      onClick: onChangePasswordClick,
    },
  ];

  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Usuários" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Nome</Table.HeaderCell>
              <Table.HeaderCell width={3}>E-mail</Table.HeaderCell>
              <Table.HeaderCell width={2}>Crea-SP</Table.HeaderCell>
              <Table.HeaderCell width={2}>Status</Table.HeaderCell>
              <Table.HeaderCell>Setores (acesso)</Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={COLUMNS} rows={ROWS} />}
            {!isLoading &&
              users?.map((user) => {
                const { id, employee, email, active, role } = user;

                return (
                  <Table.Row key={id.toString()}>
                    <Table.Cell>
                      <Text>{employee.name}</Text>
                      <Text variant="secondary">{ROLE_DESCRIPTION[role]}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{email}</Text>
                    </Table.Cell>
                    <Table.Cell>{employee.creaSp || ''}</Table.Cell>
                    <Table.Cell>
                      {active ? (
                        <Tag color="green">Ativo</Tag>
                      ) : (
                        <Tag color="transparent">Inativo</Tag>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <Text>
                        {role === 'admin' ? (
                          <Tag color="green">Todos</Tag>
                        ) : (
                          employee.divisions.map(({ description }) => (
                            <span
                              style={{ marginRight: 4 }}
                              key={`${id}${description}`}
                            >
                              <Tag color="grey">{description}</Tag>
                            </span>
                          ))
                        )}
                      </Text>
                    </Table.Cell>
                    <Table.Cell
                      width={2}
                      textAlign="center"
                      style={{ overflow: 'visible' }}
                    >
                      <Dropdown
                        direction="left"
                        floating
                        labeled
                        icon={null}
                        button
                        trigger={
                          <Button basic icon>
                            <Icon name="ellipsis horizontal" />
                          </Button>
                        }
                      >
                        <Dropdown.Menu>
                          <Dropdown.Menu scrolling style={{ zIndex: 999 }}>
                            {options.map((option) => (
                              <Dropdown.Item
                                key={option.key}
                                text={option.text}
                                value={option.value}
                                icon={option.icon}
                                onClick={() => option.onClick(id)}
                              />
                            ))}
                          </Dropdown.Menu>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            <NextPageRow
              fetchNextPage={fetchNextPage}
              isFetchingNextPage={isFetchingNextPage}
              hasNextPage={hasNextPage}
            />
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

function NextPageRow({
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}: NextPageProps) {
  return (
    <Table.Row>
      <Table.Cell textAlign="center" colSpan={COLUMNS}>
        <Divider hidden />
        {hasNextPage ? (
          <Button
            type="button"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
          >
            {isFetchingNextPage ? 'Carregando...' : ''}
            {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
          </Button>
        ) : (
          <Text variant="secondary">Nada mais para mostrar</Text>
        )}
      </Table.Cell>
    </Table.Row>
  );
}

export default UserTable;
