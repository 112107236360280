import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Modal, Button, Form, Input } from 'semantic-ui-react';
import { object, date, string } from 'yup';

import DatePicker from 'components/inputs/date-picker';
import InputError from 'components/inputs/InputError';
import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useGenerateContract } from 'mutations/quotation';
import { DOCUMENT_CONTRACT } from 'util/Document';
import { emptyStringToUndefined } from 'util/validation';

const required = 'obrigatório';
const schema = object().shape({
  number: string().required(required),
  signatureDate: date()
    .required(required)
    .transform(emptyStringToUndefined)
    .typeError(required),
  notes: string()
    .optional()
    .nullable(),
});

const MESSAGE_FILE_ERROR = 'Anexo é obrigatório';

export default function ContractGenerationModal({ quotation, open, onClose }) {
  const history = useHistory();

  const formRef = React.useRef(null);
  const [fileError, setFileError] = React.useState(null);

  const documentFile = useUpload();

  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { mutate: generateContract, isLoading } = useGenerateContract({
    onSuccess: (contractId) => onSuccess('Contrato gerado!', contractId),
  });

  function onSuccess(message, contractId) {
    toast.success(message);
    history.push(`/contratos/${contractId}`);
    onClose();
  }

  function handleFileChange(e, documentType) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
    setFileError('');
  }

  function onSubmit(values) {
    if (!documentFile.file?.id) {
      setFileError(MESSAGE_FILE_ERROR);
      return;
    }
    const input = {
      quotationId: quotation.id,
      number: values.number,
      signatureDate: values.signatureDate,
      notes: values.notes || null,
      documentId: documentFile.file?.id || null,
    };
    setFileError('');
    generateContract(input);
  }

  return (
    <Modal size="tiny" open={open} onClose={onClose}>
      <Modal.Header>Gerar contrato</Modal.Header>
      <Modal.Content>
        <Form id="form" ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Número do contrato</label>
              <Controller
                control={control}
                name="number"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
              {errors?.number && (
                <InputError>{errors?.number?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Data de assinatura</label>
              <Controller
                control={control}
                name="signatureDate"
                render={({ onChange, onBlur, value }) => (
                  <DatePicker
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    disabled={isLoading}
                  />
                )}
              />
              {errors.signatureDate && (
                <InputError>{errors?.signatureDate?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Observações (opcional)</label>
            <Controller
              control={control}
              name="notes"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors?.notes && <InputError>{errors?.notes?.message}</InputError>}
          </Form.Field>
          <Form.Field>
            <label>Anexo do contrato</label>
            <UploadInput
              name="documentId"
              uploadedFile={documentFile?.file}
              onUpload={(e) => handleFileChange(e, DOCUMENT_CONTRACT)}
              onDelete={documentFile.handleDelete}
            />
          </Form.Field>
          {fileError && <InputError>{fileError}</InputError>}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button form="form" loading={isLoading} primary>
          Gerar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
