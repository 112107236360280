import React from 'react';

import { Table } from 'semantic-ui-react';

import Loader from '../loader';

type LoadingRowProps = {
  columns: number;
  rows: number;
};

const LoadingRow = ({ columns, rows }: LoadingRowProps) => (
  <>
    {Array.from({ length: rows }, (rowNumber, rowIndex) => (
      <Table.Row key={rowIndex}>
        {Array.from({ length: columns }, (columnNumber, columnIndex) => (
          <Table.Cell key={columnIndex}>
            <Loader />
          </Table.Cell>
        ))}
      </Table.Row>
    ))}
  </>
);

export default LoadingRow;
