import styled from 'styled-components';

const gutter = '3.2rem';

const Container = styled.div`
  max-width: 130rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(${gutter} / 2);
  padding-right: calc(${gutter} / 2);
`;

export default Container;
