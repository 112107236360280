import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Button, Form, Grid, Header, Modal } from 'semantic-ui-react';

import Tag from 'components/data/Tag';
import TextInfo from 'components/data/TextInfo';
import InputError from 'components/inputs/InputError';
import { useChangeRole, Users } from 'data/user';
import { ROLES, ROLE_DESCRIPTION } from 'hooks/use-auth';

import { ChangeRoleFormInput, ChangeRoleFormValues } from './validation';

export type ChangeRoleModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: Users;
};

const FORM_ID = 'form_change_role';
const MESSAGE = {
  success: 'Senha alterada!',
} as const;

const roleOptions = Object.values(ROLES).map((value) => ({
  label: ROLE_DESCRIPTION[value],
  value,
}));

export default function ChangeRoleModal({
  isOpen,
  onClose,
  user,
}: ChangeRoleModalProps) {
  const defaultValues = {
    role: roleOptions.find((opt) => opt.value === user.role),
  };
  const resolver = yupResolver(ChangeRoleFormInput);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const changeRole = useChangeRole({
    onSuccess: () => handleSuccess(MESSAGE.success),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ChangeRoleFormValues) {
    const input = {
      userId: user.id,
      role: values.role.value,
    };
    changeRole.mutate(input);
  }

  const isLoading = changeRole.isLoading || changeRole.isLoading;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alteração de perfil</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Header as="h3">Usuário</Header>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo label="Nome" text={user.employee.name} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo
                  label="Setores"
                  text={
                    user.role === 'admin' ? (
                      <Tag color="green">Todos</Tag>
                    ) : (
                      <>
                        {user.employee.divisions.map((division) => (
                          <>
                            <Tag key={division.id} color="grey">
                              {division.description}
                            </Tag>
                            <span style={{ marginRight: 4 }} />
                          </>
                        ))}
                      </>
                    )
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Perfil</label>
                    <Controller
                      control={control}
                      name="role"
                      defaultValue=""
                      render={({ onChange, onBlur, value }) => (
                        <Select
                          instanceId="role"
                          isClearable
                          isDisabled={isLoading}
                          isLoading={isLoading}
                          name="role"
                          onBlur={onBlur}
                          onChange={onChange}
                          options={roleOptions}
                          placeholder="Selecione"
                          value={value}
                        />
                      )}
                    />
                    {errors.role && (
                      <InputError>{errors?.role?.message}</InputError>
                    )}
                  </Form.Field>
                  <Form.Field />
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
