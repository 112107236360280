import React from 'react';

import Select, { Styles, ValueType } from 'react-select';

import { useQueryCrops } from 'data/crop';

type CropSearchProps = {
  autoComplete?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  name?: string;
  onBlur?: () => void;
  onChange?: (value: ValueType<CropOption>) => void;
  placeholder?: string;
  styles?: Partial<Styles>;
  value: CropOption | null;
};

type CropOption = {
  label: string;
  value: number;
};

export default function CropSearch({
  autoComplete = undefined,
  disabled = false,
  isClearable = false,
  isMulti = true,
  name = undefined,
  onBlur = undefined,
  onChange,
  placeholder = 'Busque pelo nome...',
  styles = undefined,
  value,
}: CropSearchProps) {
  const [cropSearchValue, setCropSearchValue] = React.useState('');

  const { data, isLoading, error } = useQueryCrops({ name: cropSearchValue });

  const crops = error || !data || !data.crops ? [] : data.crops;

  const options = crops?.map((crop) => ({
    value: crop.id,
    label: crop.name,
  }));

  const handleCropInputChange = (inputValue: string) => {
    setCropSearchValue(inputValue);
  };

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      inputValue={cropSearchValue}
      instanceId={name}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading}
      isMulti={isMulti}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onInputChange={handleCropInputChange}
      options={options}
      placeholder={placeholder}
      styles={styles}
      value={value}
    />
  );
}
