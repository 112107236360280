import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import api from 'util/api';

type Maintenance = {
  id: number;
  date: string;
  windSpeed: number | null;
  temperature: number | null;
  humidity: number | null;
  precipitation: number | null;
  sample: MaintenanceSample;
  projectId: number;
};

type MaintenanceSample = {
  id: number;
  code: string;
  product: {
    id: number;
    name: string;
  };
};

export type MaintenanceFilter = {
  projectId: number;
};

export function useMaintenances({ projectId }: MaintenanceFilter) {
  return useQuery<Maintenance[], AxiosError>({
    queryKey: ['maintenances', { projectId }],
    queryFn: () => getMaintenances(projectId),
  });
}

async function getMaintenances(projectId: number) {
  const params = { projectId };
  const { data } = await api.get<Maintenance[]>(`/maintenances`, { params });
  return data;
}

export function useMaintenance(maintenanceId: number) {
  return useQuery<Maintenance, AxiosError>({
    queryKey: ['maintenance', { maintenanceId }],
    queryFn: () => getMaintenance(maintenanceId),
    enabled: !!maintenanceId,
  });
}

async function getMaintenance(maintenanceId: number) {
  const { data } = await api.get<Maintenance>(`/maintenances/${maintenanceId}`);
  return data;
}
