import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

const required = 'é um campo obrigatório';

export const AddEditRepresentative = yup.object().shape({
  name: yup.string().required(`Nome ${required}`),
  email: yup.string().required(`E-mail ${required}`),
  phone: yup
    .string()
    .optional()
    .nullable(),
  zipcode: yup
    .string()
    .length(8, `CEP deve conter 8 caracteres`)
    .optional()
    .nullable()
    .transform(emptyStringToUndefined),
});
