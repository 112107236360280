import * as yup from 'yup';

import { emptyStringToUndefined } from 'util/validation';

export type ChangeDivisionsFormValues = yup.InferType<
  typeof ChangeDivisionsFormInput
>;

export const ChangeDivisionsFormInput = yup.object({
  divisions: yup
    .array()
    .of(
      yup
        .object({
          label: yup.string().required(),
          value: yup.number().required(),
        })
        .required('Obrigatório')
        .typeError('Obrigatório')
        .transform(emptyStringToUndefined),
    )
    .required('Obrigatório')
    .typeError('Obrigatório')
    .transform(emptyStringToUndefined),
});
