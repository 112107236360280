import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import { queryClient } from 'App';
import api from 'util/api';

export type MeasurementUnitInput = {
  description: string;
  abbreviation: string;
};

const createMeasurementUnit = async (input: MeasurementUnitInput) => {
  const { headers } = await api.post(`/measurement-units`, input);
  const { location } = headers;
  const employeeId = parseInt(location.substring(14), 10);

  return employeeId;
};

export function useCreateMeasurementUnit(
  options?: UseMutationOptions<
    number,
    AxiosError,
    MeasurementUnitInput,
    () => void
  >,
) {
  return useMutation(createMeasurementUnit, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('measurementUnits');
      queryClient.invalidateQueries('measurementUnit');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}
