import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Button, Message, Form, Input, Grid } from 'semantic-ui-react';
import * as Yup from 'yup';

import InputError from 'components/inputs/InputError';
import { useErrorHandler } from 'hooks/use-error-handler';
import { useCreateIngredient } from 'mutations/ingredient';

export type IngredientFormValues = {
  name: string;
};

export type IngredientModalFormProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
};

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Obrigatório')
    .min(3, 'Deve ter no mínimo 3 caracteres')
    .max(255, 'Deve ter no máximo 255 caracteres'),
});

const formId = 'form_ingredient';

export default function IngredientModalForm({
  isOpen,
  onClose,
  onSuccess,
}: IngredientModalFormProps) {
  const { handleError } = useErrorHandler();

  const resolver = yupResolver(schema);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const { mutate: createIngredient, isLoading, error } = useCreateIngredient({
    onSuccess: () => onSuccess('Ingredient adicionada com sucesso!'),
  });

  function onSubmit(values: IngredientFormValues) {
    createIngredient(values);
  }

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>Novo ingrediente ativo</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Nome</label>
                    <Controller
                      control={control}
                      name="name"
                      render={({ onChange, onBlur, value }) => (
                        <Input
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          disabled={isLoading}
                          loading={isLoading}
                          autoComplete="off"
                          fluid
                        />
                      )}
                    />
                    {errors.name && (
                      <InputError>{errors?.name?.message}</InputError>
                    )}
                  </Form.Field>
                </Form.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        {error ? <Message content={handleError(error)} negative /> : null}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
