import React from 'react';

import toast from 'react-hot-toast';
import { Grid, Header, Button, Icon, Divider } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import TreatmentForm from 'components/forms/TreatmentForm';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import { useModalWithData } from 'hooks/useModal';
import { useDeleteTreatment } from 'mutations/treatment';
import { isUntreated } from 'queries/treatment';

import TreatmentTable from './TreatmentTable';

export default function TabTreatments({ project, treatments = [] }) {
  const addEditModal = useModalWithData();
  const deleteModal = useModalWithData();

  const deleteTreatment = useDeleteTreatment({
    onSuccess: () => {
      toast.success('Tratament excluído!');
      deleteModal.close();
    },
  });

  function handleAddClick() {
    addEditModal.open();
  }

  function handleEditClick(treatment) {
    addEditModal.open(treatment);
  }

  function handleDeleteClick(treatment) {
    deleteModal.open(treatment);
  }

  return (
    <>
      <Grid>
        {treatments.length === 0 ? (
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Header as="h3">Tratamentos</Header>
                <EmptyState>
                  <EmptyState.Header>
                    Nenhum tratamento adicionado
                  </EmptyState.Header>
                  <EmptyState.Description>
                    Clique no botão abaixo para adicionar um tratamento
                  </EmptyState.Description>
                  <EmptyState.Actions>
                    <Button
                      type="button"
                      onClick={handleAddClick}
                      primary
                      basic
                    >
                      <Icon name="add" />
                      Adicionar
                    </Button>
                  </EmptyState.Actions>
                </EmptyState>
              </Section>
            </Grid.Column>
          </Grid.Row>
        ) : (
          <>
            <Grid.Row>
              <Grid.Column width={16}>
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Tratamentos
                    </Header>
                    {treatments.length ? (
                      <Button
                        basic
                        onClick={handleAddClick}
                        primary
                        size="small"
                      >
                        <Icon name="add" />
                        Novo tratamento
                      </Button>
                    ) : null}
                  </Section.Header>
                  <Section.Content>
                    <TreatmentTable
                      onAddClick={handleAddClick}
                      onEditClick={handleEditClick}
                      onDeleteClick={handleDeleteClick}
                      treatments={treatments}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Divider hidden />
          </>
        )}
      </Grid>

      {addEditModal.isOpen ? (
        <TreatmentForm
          isOpen={addEditModal.isOpen}
          onClose={addEditModal.close}
          number={treatments.length + 1}
          projectId={project.id}
          formValues={
            addEditModal.data
              ? mapToTreatmentForm(addEditModal.data)
              : undefined
          }
        />
      ) : null}

      {deleteModal.isOpen && deleteModal.data ? (
        <AlertModal
          header="Excluir tratamento"
          content={`Tem certeza que deseja excluir o tratamento N°${deleteModal?.data?.number}?`}
          submitText="Excluir"
          negative
          isOpen={deleteModal.isOpen}
          isLoading={deleteTreatment.isLoading}
          onClose={deleteModal.close}
          onSubmit={() => deleteTreatment.mutate(deleteModal?.data?.id)}
        />
      ) : null}
    </>
  );
}

function mapToTreatmentForm(treatment) {
  return {
    treatmentId: treatment.id,
    number: treatment.number,
    treatmentType: treatment.treatmentType.toString(),
    sampleDoses: isUntreated(treatment.treatmentType)
      ? [{ sample: null, quantity: null }]
      : treatment.sampleDoses.map((sampleDose) => {
          return {
            sample: sampleDose.sample,
            quantity: sampleDose.quantity,
            measurementUnit: sampleDose.measurementUnit,
            ingredientDoses: sampleDose?.ingredientDoses?.map(
              (ingredientDose) => {
                return {
                  ingredient: ingredientDose.ingredient || undefined,
                  quantity: ingredientDose.quantity || undefined,
                  measurementUnit: ingredientDose?.measurementUnit || undefined,
                };
              },
            ),
          };
        }),
    notes: treatment.notes,
  };
}
