import React from 'react';

import { Link } from 'react-router-dom';
import { Button, Divider, Table } from 'semantic-ui-react';

import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import { formatCpfOrCnpj, formatPhone } from 'util/mask';

export default function CustomersTable({
  customers = [],
  loading,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
}) {
  const columns = 6;
  const loadingRows = 5;

  return (
    <Table size="large" basic="very">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Nome</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">CNPJ/CPF</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Telefone</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">
            Inscrição estadual
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Código</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {loading && <LoadingRow columns={columns} rows={loadingRows} />}
        {!loading &&
          customers.map(
            ({
              id,
              tradingName,
              companyName,
              phone,
              cnpj,
              stateRegistration,
              insideCode = '',
            }) => (
              <Table.Row key={id}>
                <Table.Cell textAlign="left">
                  <Text variant="primary">
                    <Link to={`/patrocinadores/${id}`} name="customer-details">
                      {tradingName}
                    </Link>
                  </Text>
                  <Text variant="secondary">{companyName}</Text>
                </Table.Cell>
                <Table.Cell textAlign="left">
                  {formatCpfOrCnpj(cnpj)}
                </Table.Cell>
                <Table.Cell textAlign="left">
                  {phone ? formatPhone(phone, 1) : ''}
                </Table.Cell>
                <Table.Cell textAlign="left">{stateRegistration}</Table.Cell>
                <Table.Cell textAlign="left">
                  {insideCode ? insideCode.toUpperCase() : 'Sem código'}
                </Table.Cell>
              </Table.Row>
            ),
          )}
        <Table.Row>
          <Table.Cell textAlign="center" colSpan={columns}>
            <Divider hidden />
            {hasNextPage ? (
              <Button
                type="button"
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
              >
                {isFetchingNextPage ? 'Carregando...' : ''}
                {!isFetchingNextPage && hasNextPage ? 'Ver mais' : ''}
              </Button>
            ) : (
              <Text variant="secondary">Nada mais para mostrar</Text>
            )}
          </Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
