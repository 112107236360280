import React from 'react';

import { Grid, Header } from 'semantic-ui-react';

import ContractForm from 'components/forms/ContractForm';
import Base from 'components/layout/Base';

export default function AddContract() {
  return (
    <Base text>
      <Grid>
        <Grid.Column>
          <Header as="h1">Novo contrato</Header>
          <ContractForm />
        </Grid.Column>
      </Grid>
    </Base>
  );
}
