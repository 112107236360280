import * as React from 'react';

import { Button, Icon, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import { downloadDocument } from 'hooks/document';

export type ProcedureItem = {
  id: number;
  code: string;
  revision: number;
  title: string;
  objective: string;
  documentId: number;
};

export type ProcedureTable = {
  items: ProcedureItem[];
  onAddClick?: () => void;
  isLoading: boolean;
};

const DESCRIPTION_ADMIN = 'Clique no botão abaixo para adicionar um novo POP';
const DESCRIPTION_USER = 'Solicite o cadastro de um POP a um administrador';

function ProcedureTable({ items = [], onAddClick, isLoading }: ProcedureTable) {
  const emptyStateDescription = onAddClick
    ? DESCRIPTION_ADMIN
    : DESCRIPTION_USER;

  if (!items.length) {
    return (
      <EmptyState>
        <EmptyState.Header>Nenhum POP encontrado</EmptyState.Header>
        <EmptyState.Description>{emptyStateDescription}</EmptyState.Description>
        {onAddClick ? (
          <EmptyState.Actions>
            <Button type="button" onClick={onAddClick} primary>
              <Icon name="add" />
              Adicionar
            </Button>
          </EmptyState.Actions>
        ) : null}
      </EmptyState>
    );
  }

  return (
    <Table size="small" basic="very" fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width={2}>Código</Table.HeaderCell>
          <Table.HeaderCell width={9}>Descrição</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width={2}>
            Revisão
          </Table.HeaderCell>
          <Table.HeaderCell width={2}>Documento</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width={1} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {isLoading && <LoadingRow columns={5} rows={5} />}
        {!isLoading &&
          items?.map(({ id, code, title, objective, revision, documentId }) => {
            return (
              <Table.Row key={id.toString()}>
                <Table.Cell>{code}</Table.Cell>
                <Table.Cell>
                  <Text variant="primary">{title}</Text>
                  <Text variant="secondary">{objective}</Text>
                </Table.Cell>
                <Table.Cell textAlign="center">{revision}</Table.Cell>
                <Table.Cell>
                  <LinkButton onClick={() => downloadDocument(documentId)}>
                    Anexo
                  </LinkButton>
                </Table.Cell>
                <Table.Cell textAlign="center">
                  <Button type="button" size="mini" basic icon>
                    <Icon name="edit" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
}

export default ProcedureTable;
