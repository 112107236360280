import React from 'react';

import { Table, Button, Icon, Header } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import LinkButton from 'components/inputs/LinkButton';
import { formatToBr } from 'util/DateFormatter';

export default function MaintenanceTable({
  maintenances = [],
  loading = false,
  onAddClick,
  onEditClick,
  onRemoveClick,
}) {
  const isEmpty = !loading && maintenances.length === 0;

  if (isEmpty) {
    return (
      <>
        <Header as="h3">Manutenções</Header>
        <EmptyState>
          <EmptyState.Header>Nenhuma manutenção adicionada</EmptyState.Header>
          <EmptyState.Description>
            Clique no botão abaixo para adicionar uma manutenção
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button type="button" onClick={onAddClick} primary basic>
              <Icon name="add" />
              Adicionar
            </Button>
          </EmptyState.Actions>
        </EmptyState>
      </>
    );
  }

  return (
    <>
      <Table basic="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Data</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Produto</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Ingredient</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Dose i/ha</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Finalidade</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Vento</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">UR%</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Temperatura</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Precipitação</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && <LoadingRow columns={9} rows={5} />}
          {!loading &&
            maintenances.map((maintenance) => {
              const {
                id,
                date,
                product,
                ingredient,
                dose,
                goal,
                windSpeed,
                temperature,
                humidity,
                precipitation,
              } = maintenance;

              return (
                <Table.Row key={id}>
                  <Table.Cell textAlign="center">{formatToBr(date)}</Table.Cell>
                  <Table.Cell textAlign="center">{product}</Table.Cell>
                  <Table.Cell textAlign="center">{ingredient}</Table.Cell>
                  <Table.Cell textAlign="center">{dose}</Table.Cell>
                  <Table.Cell textAlign="center">{goal}</Table.Cell>
                  <Table.Cell textAlign="center">
                    {windSpeed?.toFixed(1) || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {humidity?.toFixed(1) || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {temperature?.toFixed(1) || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    {precipitation || '-'}
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <LinkButton onClick={() => onEditClick(maintenance)}>
                      Editar
                    </LinkButton>
                    <LinkButton onClick={() => onRemoveClick(maintenance)}>
                      Remover
                    </LinkButton>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    </>
  );
}
