import * as React from 'react';

import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  PaginationProps,
} from 'semantic-ui-react';

import MeasurementUnitTable from 'components/data/MeasurementUnitTable';
import Pagination from 'components/data/Pagination';
import MeasurementUnitModalForm from 'components/forms/MeasurementUnitForm';
import Section from 'components/layout/Section';
import { useModal } from 'hooks/useModal';
import { useQueryMeasurementUnits } from 'queries/measurementUnit';

type MouseEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;

export default function UnitsTab() {
  const modal = useModal();

  const [measurementUnitsPage, setMeasurementUnitsPage] = React.useState(1);

  const pageSize = 10;
  const measurementUnitsQuery = useQueryMeasurementUnits({
    page: measurementUnitsPage,
    pageSize,
  });

  function handleMeasurementUnitsPageChange(
    _: MouseEvent,
    { activePage }: PaginationProps,
  ) {
    setMeasurementUnitsPage(activePage as number);
  }

  function handleAddUnitClick() {
    modal.open();
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h1">Unidades</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Button type="button" onClick={handleAddUnitClick} primary>
              <Icon name="add" />
              Nova unidade
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row columns="equal">
          <Grid.Column>
            <Section>
              <Section.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <MeasurementUnitTable
                        items={measurementUnitsQuery?.data?.units}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1} textAlign="right">
                    <Grid.Column>
                      <Pagination
                        pagination={measurementUnitsQuery?.data?.pagination}
                        onPageChange={handleMeasurementUnitsPageChange}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <MeasurementUnitModalForm isOpen={modal.isOpen} onClose={modal.close} />
    </>
  );
}
