import React from 'react';

import { useForm } from 'react-hook-form';
import {
  Modal,
  Button,
  Form,
  Message,
  Loader,
  Dimmer,
  Icon,
  Grid,
} from 'semantic-ui-react';

import UploadInput from 'components/inputs/UploadInput';
import { useUploads } from 'hooks/use-upload';
import { useAddProjectDocuments } from 'mutations/project';
import { useDocumentInputs } from 'queries/document';

const documentTypes = {
  application: 15,
  maintenance: 16,
}; /*  as const; */

// type DocumentType = keyof typeof documentTypes;

const titles = {
  application: 'Anexos de aplicação',
  maintenance: 'Anexos de manutenção',
}; /*  as const; */

const FORM_ID = 'form_application_documents';

export default function ProjectDocumentsModal({
  projectId,
  documentIds,
  documentType,
  error,
  onClose,
  onSuccess,
  isOpen,
}) {
  const formRef = React.useRef(null);

  const {
    append,
    files,
    handleUpload,
    handleDelete,
    remove,
    setFiles,
  } = useUploads();

  const { handleSubmit } = useForm({
    mode: 'onBlur',
  });

  const {
    data: documentInputs,
    isLoading: isLoadingDocuments,
  } = useDocumentInputs(documentIds);

  const {
    mutate: addProjectDocuments,
    isLoading: isMutating,
  } = useAddProjectDocuments({
    onSuccess,
  });

  React.useEffect(() => {
    if (documentInputs) {
      setFiles(documentInputs);
    }
  }, [documentInputs, setFiles]);

  function handleClose() {
    onClose();
  }

  function handleAddDocumentClick() {
    append();
  }

  function handleRemoveDocumentItemClick(index) {
    remove(index);
  }

  function isDocumentNotValid(document) {
    return !document || document.error || !document.url;
  }

  function onSubmit(newDocumentIds) {
    addProjectDocuments({
      projectId,
      documentIds: newDocumentIds,
      documentType: documentTypes[documentType],
    });
  }

  function handleFileChange(e, index) {
    const file = e.target.files ? e.target.files[0] : null;
    handleUpload(file, documentTypes[documentType], index);
  }

  const disabledButton = files.some(isDocumentNotValid);

  return (
    <Modal size="tiny" open={isOpen} onClose={handleClose}>
      <Modal.Header>{titles[documentType]}</Modal.Header>
      <Modal.Content>
        {documentIds && isLoadingDocuments ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form
            id={FORM_ID}
            ref={formRef}
            onSubmit={handleSubmit(() => onSubmit(files.map(({ id }) => id)))}
          >
            <Form.Field>
              <label>Anexos</label>
            </Form.Field>
            <Grid>
              {files.map((_, index) => (
                <React.Fragment key={index.toString()}>
                  <Grid.Column width={14}>
                    <Form.Field>
                      <UploadInput
                        name={`document${index}`}
                        uploadedFile={files[index]}
                        onUpload={(e) => handleFileChange(e, index)}
                        onDelete={() => handleDelete(index)}
                      />
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column width={2} verticalAlign="middle">
                    <Button
                      type="button"
                      onClick={() => handleRemoveDocumentItemClick(index)}
                      basic
                      icon
                    >
                      <Icon name="trash" />
                    </Button>
                  </Grid.Column>
                </React.Fragment>
              ))}
              <Grid.Column width={16}>
                {files.length < 5 ? (
                  <Button
                    type="button"
                    onClick={handleAddDocumentClick}
                    fluid
                    basic
                  >
                    <Icon name="add" />
                    Novo anexo
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid>
          </Form>
        )}
        {error && <Message content={error} negative />}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          loading={isMutating}
          disabled={disabledButton}
          primary
        >
          Salvar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
