import React from 'react';

import { useQuery } from 'react-query';
import {
  Modal,
  Button,
  Form,
  Message,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import UploadInput from 'components/inputs/UploadInput';
import { DOCUMENT_TYPE } from 'data/document';
import {
  getProjectDocumentFiles,
  makeDetailedFile,
  uploadDocument,
} from 'hooks/document';
import { useErrorHandler } from 'hooks/useErrorHandler';
import { useUpdateProject } from 'mutations/project';

const INITIAL_STATE = {
  protocol: null,
  areaAssignment: null,
  ret: null,
  secondRet: null,
  thirdRet: null,
  fourthRet: null,
  reportReview: null,
  report: null,
};

export default function EditProjectDocumentsModal({
  project,
  open,
  onClose,
  onSuccess,
}) {
  const [isMounted, setMounted] = React.useState(false);
  const { message: error, handleError } = useErrorHandler();

  const { data, isLoading, isFetched } = useQuery(
    ['projectDocuments', project],
    () => getProjectDocumentFiles(project.id),
    { onError: handleError },
  );

  const [documentFiles, setDocumentFiles] = React.useState(
    data || INITIAL_STATE,
  );

  React.useEffect(() => {
    if (data && !isMounted) {
      setDocument('protocol', data.protocolFile);
      setDocument('areaAssignment', data.areaAssignmentFile);
      setDocument('ret', data.retFile);
      setDocument('secondRet', data.secondRetFile);
      setDocument('thirdRet', data.thirdRetFile);
      setDocument('fourthRet', data.fourthRetFile);
      setDocument('reportReview', data.reportReviewFile);
      setDocument('report', data.reportFile);

      setMounted(true);
    }
  }, [isMounted, data]);

  /* const [
    { data: updated, loading: updating, error: updateError },
    updateProjectDocuments,
  ] = useAsync(updateProjectDocumentsUseCase); */

  const {
    mutate: updateProject,
    isLoading: isUpdating,
    error: updateError,
  } = useUpdateProject({
    onSuccess: () => {
      setDocumentFiles(INITIAL_STATE);
      onSuccess();
    },
  });

  const setDocument = (name, document) => {
    setDocumentFiles((oldValues) => ({
      ...oldValues,
      [name]: document,
    }));
  };

  const handleSubmit = async () => {
    updateProject({
      projectId: project.id,
      documentation: {
        protocolId: documentFiles.protocol?.id || null,
        areaAssignmentId: documentFiles.areaAssignment?.id || null,
        retId: documentFiles.ret?.id || null,
        secondRetId: documentFiles.secondRet?.id || null,
        thirdRetId: documentFiles.thirdRet?.id || null,
        fourthRetId: documentFiles.fourthRet?.id || null,
        reportReviewId: documentFiles.reportReview?.id || null,
        reportId: documentFiles.report?.id || null,
      },
    });
  };

  function handleFileChange(e, name, documentType) {
    const file = e.target.files[0];

    const updatedFile = makeDetailedFile(file);

    setDocumentFiles((oldValues) => ({
      ...oldValues,
      [name]: updatedFile,
    }));

    handleUpload(updatedFile, name, documentType);
  }

  function handleUpload(updatedFile, name, documentType) {
    uploadDocument({
      file: updatedFile,
      documentType,
      onUploadProgress: (progress) => {
        setDocumentFiles((oldValues) => ({
          ...oldValues,
          [name]: { ...oldValues[name], progress },
        }));
      },
    })
      .then((response) => {
        setDocumentFiles((oldValues) => ({
          ...oldValues,
          [name]: {
            ...oldValues[name],
            uploaded: true,
            id: response.id,
            url: response.url,
          },
        }));
      })
      .catch(() => {
        setDocumentFiles((oldValues) => ({
          ...oldValues,
          [name]: { ...oldValues[name], error: true },
        }));
      });
  }

  async function handleDeleteButton(name) {
    setDocumentFiles((oldValues) => ({
      ...oldValues,
      [name]: null,
    }));
  }

  const handleClose = () => {
    setDocumentFiles(INITIAL_STATE);
    onClose();
  };

  const isUploading = Object.values(documentFiles).some(
    (item) => item !== null && !item.url,
  );
  const isSubmitDisabled = !!error || isUpdating || isUploading;

  return (
    <Modal size="tiny" open={open} onClose={handleClose}>
      <Modal.Header>Atualizar documentos</Modal.Header>
      <Modal.Content>
        {isLoading && !error ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : null}

        {error ? <Message content={error} negative /> : null}

        {data && isFetched ? (
          <DocumentsForm
            documentFiles={documentFiles}
            error={updateError}
            onSubmit={handleSubmit}
            onDeleteButton={handleDeleteButton}
            onFileChange={handleFileChange}
          />
        ) : null}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose} basic>
          Cancelar
        </Button>
        <Button
          onClick={() => handleSubmit()}
          loading={isUpdating}
          disabled={isSubmitDisabled}
          primary
        >
          Atualizar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

function DocumentsForm({
  documentFiles,
  error,
  onSubmit,
  onDeleteButton,
  onFileChange,
}) {
  return (
    <Form onSubmit={onSubmit}>
      <Form.Field>
        <label>Protocolo</label>
        <UploadInput
          name="protocol"
          uploadedFile={documentFiles?.protocol}
          onUpload={(e) => onFileChange(e, 'protocol', DOCUMENT_TYPE.protocol)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>Termo de cessão de área</label>
        <UploadInput
          name="areaAssignment"
          uploadedFile={documentFiles?.areaAssignment}
          onUpload={(e) =>
            onFileChange(e, 'areaAssignment', DOCUMENT_TYPE.areaAssignment)
          }
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>1º RET</label>
        <UploadInput
          name="ret"
          uploadedFile={documentFiles?.ret}
          onUpload={(e) => onFileChange(e, 'ret', DOCUMENT_TYPE.ret)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>2º RET</label>
        <UploadInput
          name="secondRet"
          uploadedFile={documentFiles?.secondRet}
          onUpload={(e) => onFileChange(e, 'secondRet', DOCUMENT_TYPE.ret)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>3º RET</label>
        <UploadInput
          name="thirdRet"
          uploadedFile={documentFiles?.thirdRet}
          onUpload={(e) => onFileChange(e, 'thirdRet', DOCUMENT_TYPE.ret)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>4º RET</label>
        <UploadInput
          name="fourthRet"
          uploadedFile={documentFiles?.fourthRet}
          onUpload={(e) => onFileChange(e, 'fourthRet', DOCUMENT_TYPE.ret)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>Laudo de revisão</label>
        <UploadInput
          name="reportReview"
          uploadedFile={documentFiles?.reportReview}
          onUpload={(e) =>
            onFileChange(e, 'reportReview', DOCUMENT_TYPE.reportReview)
          }
          onDelete={onDeleteButton}
        />
      </Form.Field>
      <Form.Field>
        <label>Laudo final</label>
        <UploadInput
          name="report"
          uploadedFile={documentFiles?.report}
          onUpload={(e) => onFileChange(e, 'report', DOCUMENT_TYPE.report)}
          onDelete={onDeleteButton}
        />
      </Form.Field>
      {error && <Message content={error} negative />}
    </Form>
  );
}
