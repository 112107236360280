import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import api from 'util/api';

export const CONTRACT_STATUS = {
  enabling: { id: 3, description: 'Habilitação' },
  inProgress: { id: 1, description: 'Em andamento' },
  finished: { id: 2, description: 'Concluído' },
  canceled: { id: 4, description: 'Cancelado' },
};

type UpdateContractInput = {
  contractId: number;
  contractStatus?: number;
};

export function useUpdateContract(
  options?: UseMutationOptions<
    void,
    AxiosError,
    UpdateContractInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(updateContract, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('contract');
      queryClient.invalidateQueries('contracts');
      queryClient.invalidateQueries('contractWithDocument');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateContract({ contractId, ...input }: UpdateContractInput) {
  await api.patch(`/contracts/${contractId}`, input);
}

export function useCancelContract(
  options?: UseMutationOptions<void, AxiosError, number>,
) {
  const queryClient = useQueryClient();
  return useMutation(cancelContract, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('contract');
      queryClient.invalidateQueries('contracts');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function cancelContract(contractId: number) {
  await api.post(`/contracts/${contractId}/cancel`);
}
