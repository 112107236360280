import * as React from 'react';

import api from 'util/api';

import { isAxiosError } from './use-error-handler';

type Admin = 1;

type User = 2;

type Warehouse = 3;

type Breeding = 4;

type Role = Admin | User | Warehouse | Breeding;

export type UserResponse = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
  employeeId: number;
  role: Role;
  roleType?: 'admin' | 'research' | 'warehouse' | 'breeding';
  divisions: number[];
  photo: string | null;
  isAdmin: boolean;
  isUser: boolean;
  isWarehouse: boolean;
  isBreeding: boolean;
};

type Response = {
  status?: number;
  message?: string;
};

type AuthResponse = UserResponse & {
  token: string;
};

type AuthContextData = {
  logged: boolean;
  user: UserResponse | null;
  isLoading: boolean;
};

type AuthProviderProps = {
  children: React.ReactNode;
};

const TOKEN_KEY = 'token_key';
const USER_KEY = 'user_key';
const CHECK_TOKEN_ENDPOINT = `${process.env.REACT_APP_API_URL}/auth/verification`;

const AuthContextDefaultValues = {
  user: null,
  logged: false,
  isLoading: true,
};

export const AuthContext = React.createContext<AuthContextData>(
  AuthContextDefaultValues,
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = React.useState<UserResponse | null>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [validToken, setValidToken] = React.useState(false);

  React.useEffect(() => {
    async function checkToken() {
      try {
        const token = getStoredToken();

        await api.post(CHECK_TOKEN_ENDPOINT, { token });

        api.defaults.headers.common.authorization = token;

        setLoading(false);
        setValidToken(true);
      } catch (e) {
        if (isAxiosError<Response>(e)) {
          if (e?.response?.status && e?.response?.status === 500) {
            setLoading(false);
            return;
          }
        }

        setLoading(false);
        deleteUserAndToken();
      }
    }

    checkToken();
  }, []);

  function getStoredToken() {
    return localStorage.getItem(TOKEN_KEY);
  }

  function deleteUserAndToken() {
    localStorage.removeItem(USER_KEY);
    localStorage.removeItem(TOKEN_KEY);
  }

  React.useEffect(() => {
    const currentUser = getCurrentUser();
    setUser(currentUser);
  }, []);

  function getCurrentUser() {
    const storagedUser = localStorage.getItem(USER_KEY);
    const userResponse: UserResponse = storagedUser
      ? JSON.parse(storagedUser)
      : null;

    const updatedUser = userResponse
      ? {
          ...userResponse,
          roleType: userResponse?.roleType || getRoleType(userResponse),
        }
      : null;

    return updatedUser;
  }

  return (
    <AuthContext.Provider value={{ logged: !!validToken, user, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const ROLES = {
  1: 'admin',
  2: 'research',
  3: 'warehouse',
  4: 'breeding',
} as const;

export const ROLE_DESCRIPTION = {
  admin: 'Admin',
  research: 'Pesquisa',
  warehouse: 'Produtos',
  breeding: 'Criações',
} as const;

export type RoleType = typeof ROLES[keyof typeof ROLES];

function getRoleType(user: UserResponse) {
  if (!ROLES[user.role]) {
    throw new Error('Papel de usuário não encontrado!');
  }

  return ROLES[user.role];
}

export function saveUserAndToken({ token, ...user }: AuthResponse) {
  localStorage.setItem(USER_KEY, JSON.stringify(user));
  localStorage.setItem(TOKEN_KEY, token);
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };
