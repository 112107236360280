import React from 'react';

import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import useAsync from 'hooks/useAsync';
import updateProjectUseCase from 'mutations/updateProject';

import { projectRepository } from '../../container';
import ProjectProtocolForm from './ProjectProtocolForm';

export default function EditProtocol() {
  const history = useHistory();
  const { id: projectId } = useParams();

  const [{ data: project, loading: isProjectLoading }, getProject] = useAsync(
    projectRepository.getProject,
  );

  const [
    { data: updated, loading, error: updateError },
    updateProject,
  ] = useAsync(updateProjectUseCase);

  React.useEffect(() => {
    getProject(projectId);
  }, [getProject, projectId]);

  React.useEffect(() => {
    function handleCreateResult() {
      if (updated) {
        toast.success('Protocolo atualizado com sucesso!');
        history.goBack();
      }

      if (updateError) {
        toast.error(updateError, 'error');
      }
    }

    handleCreateResult();
  }, [updated, updateError, history]);

  function handleSubmit(values) {
    const updatedValues = mapFromForm(values);

    updateProject(projectId, updatedValues);
  }

  return (
    <Base loading={isProjectLoading} text>
      <Container>
        {project && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as="h1">Edição de materiais e métodos</Header>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row>
              <Grid.Column>
                <Section>
                  <Section.Content>
                    <ProjectProtocolForm
                      projectFormData={mapToForm(project)}
                      isSubmiting={loading}
                      onSubmit={handleSubmit}
                    />
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Container>
    </Base>
  );
}

function mapToForm(project) {
  return {
    title: project.title || '',
    intro: project.intro || '',
    objective: project.objective || '',
    applicationMethodology: project.applicationMethodology || '',
    equipment: project.equipment || '',
    equipmentBar: project.equipmentBar || '',
    equipmentVolume: project.equipmentVolume || '',
    nozzlesModel: project.nozzlesModel || '',
    references: project.references.map(({ number, description }) => ({
      number,
      description,
    })) || [{}],
  };
}

function mapFromForm(values) {
  return {
    title: values.title
      ? values.title
          .replace(/\n/g, '')
          .replace(/\s+/g, ' ')
          .trim()
      : null,
    intro: values.intro
      ? values.intro
          .replace(/\n/g, '')
          .replace(/\s+/g, ' ')
          .trim()
      : null,
    objective: values.objective
      ? values.objective
          .replace(/\n/g, '')
          .replace(/\s+/g, ' ')
          .trim()
      : null,
    methodology: {
      application: values.applicationMethodology
        ? values.applicationMethodology
            .replace(/\n/g, '')
            .replace(/\s+/g, ' ')
            .trim()
        : null,
    },
    equipment: {
      description: values.equipment || null,
      bar: values.equipmentBar || null,
      volume: values.equipmentVolume || null,
      nozzlesModel: values.nozzlesModel || null,
    },
    references:
      values?.references?.map(({ description }, index) => ({
        number: index + 1,
        description,
      })) || [],
  };
}
