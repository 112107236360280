import * as yup from 'yup';

export type ChangePasswordFormValues = yup.InferType<
  typeof ChangePasswordFormInput
>;

export const ChangePasswordFormInput = yup.object({
  newPassword: yup
    .string()
    .required('Obrigatório')
    .min(8, 'Mínimo de 8 caracteres')
    .max(32, 'Máximo de 32 caracteres')
    .typeError('Obrigatório'),
  newPasswordConfirmation: yup
    .string()
    .required('Obrigatório')
    .min(8, 'Mínimo de 8 caracteres')
    .max(32, 'Máximo de 32 caracteres')
    .typeError('Obrigatório'),
});
