import { productRepository } from '../container';

export default async function getAllFormulations(filter) {
  const {
    data: formulations,
    pagination,
  } = await productRepository.getAllFormulations(filter);

  return {
    formulations,
    pagination,
  };
}
