import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import api, { Response } from 'util/api';

type CropFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  name?: string;
};

export type CropResponse = {
  id: number;
  name: string;
  nameInt: string;
  insideCode: string;
};

const getCrops = async (filter: CropFilter) => {
  const url = '/crops';
  const config = { params: filter } as const;
  const { data } = await api.get<Response<CropResponse>>(url, config);

  return {
    crops: data.data,
    pagination: data.pagination,
  };
};

export function useQueryCrops(filter: CropFilter) {
  return useQuery(['crops', filter], () =>
    getCrops({ orderBy: 'name', sort: 'asc', ...filter }),
  );
}

type CreateCropInput = {
  name: string;
  nameInt: string;
  insideCode: string;
};

export function useCreateCrop(
  options?: UseMutationOptions<number, AxiosError, CreateCropInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(createCrop, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('crops');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createCrop(input: CreateCropInput) {
  const url = '/crops';
  const data = { ...input };
  const { headers } = await api.post(url, data);
  const { location } = headers;
  const cropId = parseInt(location.split('/').pop(), 10);

  return cropId;
}

type UpdateCropInput = {
  cropId: number;
  name: string;
  nameInt: string;
  insideCode: string;
};

export function useUpdateCrop(
  options?: UseMutationOptions<void, AxiosError, UpdateCropInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateCrop, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('crops');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateCrop({ cropId, ...input }: UpdateCropInput) {
  const url = `/crops/${cropId}`;

  await api.patch(url, input);
}
