import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Divider, Form, Grid, Header, Modal } from 'semantic-ui-react';

import TextInfo from 'components/data/TextInfo';
import DivisionSelect from 'components/inputs/DivisionSelect';
import InputError from 'components/inputs/InputError';
import { useChangeDivisions, Users } from 'data/user';
import { ROLE_DESCRIPTION } from 'hooks/use-auth';

import {
  ChangeDivisionsFormInput,
  ChangeDivisionsFormValues,
} from './validation';

export type ChangeDivisionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: Users;
};

const formId = 'form_crop' as const;

const MESSAGE = {
  successDeactivate: 'Usuário desativado!',
  successActivate: 'Usuário ativado!',
} as const;

export default function ChangeDivisionsModal({
  isOpen,
  onClose,
  user,
}: ChangeDivisionsModalProps) {
  const divisions = user.employee.divisions.map(({ id, description }) => ({
    key: id,
    value: id,
    label: description,
  }));
  const defaultValues = { divisions };
  const resolver = yupResolver(ChangeDivisionsFormInput);
  const { control, errors, handleSubmit } = useForm({
    defaultValues,
    mode: 'onBlur',
    resolver,
  });

  const changeDivisions = useChangeDivisions({
    onSuccess: () => handleSuccess(MESSAGE.successActivate),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ChangeDivisionsFormValues) {
    changeDivisions.mutate({
      userId: user.id,
      divisionIds: values.divisions.map((division) => division.value),
    });
  }

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alterar setores</Modal.Header>
      <Modal.Content>
        <Header as="h3">Usuário</Header>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <TextInfo label="Nome" text={user.employee.name} />
              </Grid.Column>
              <Grid.Column>
                <TextInfo label="Perfil" text={ROLE_DESCRIPTION[user.role]} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns="equal">
              <Grid.Column>
                <Form.Field>
                  <label>Setor</label>
                  <Controller
                    control={control}
                    name="divisions"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DivisionSelect
                        disabled={changeDivisions.isLoading}
                        isMulti
                        name="divisions"
                        onBlur={onBlur}
                        onChange={onChange}
                        value={value}
                      />
                    )}
                  />
                  {errors.divisions && (
                    <InputError>{errors?.divisions?.message}</InputError>
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={changeDivisions.isLoading}
          disabled={changeDivisions.isLoading}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
