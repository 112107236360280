import { projectRepository } from '../container';

export default async function updateProject(projectId, project) {
  try {
    await projectRepository.updateProject(projectId, project);

    return {};
  } catch (e) {
    throw e.message || 'Ops! Alguma coisa não funcionou direito';
  }
}
