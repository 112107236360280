import React from 'react';

import toast from 'react-hot-toast';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  Button,
  Icon,
  Header,
  Divider,
  Loader,
  Table,
} from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import PaginationInfo from 'components/data/PaginationInfo';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import LinkButton from 'components/inputs/LinkButton';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import { useAuth } from 'hooks/auth';
import { useModal, useModalWithData } from 'hooks/useModal';
import { useAssociateSample, useDissociateSample } from 'mutations/sample';
import {
  useInfiniteSamples,
  sampleMapper,
  SAMPLE_STATUS_COLOR,
  SAMPLE_STATUS_DESCRIPTION,
} from 'queries/sample';
import theme from 'styles/theme';

import { AssociateSampleModal } from './AssociateSampleModal';

const SuccessMessage = {
  AssociateSample: 'Amostra adicionada!',
  DissociateSample: 'Amostra desassociada!',
};

export default function TabSamples({ projectId }) {
  const history = useHistory();

  const { user } = useAuth();

  const associateModal = useModal();
  const dissociateModal = useModalWithData();

  const { pagination, samples, isLoading } = useInfiniteSamples({
    pageSize: 100,
    orderBy: 'arrivalDate',
    sort: 'asc',
    projectId,
  });

  const associateSampleMutation = useAssociateSample({
    onSuccess: onAssociateSuccess,
  });

  function onAssociateSuccess() {
    associateModal.close();
    toast.success(SuccessMessage.AssociateSample);
  }

  const dissociateSampleMutation = useDissociateSample({
    onSuccess: onDissociateSuccess,
  });

  function onDissociateSuccess() {
    dissociateModal.close();
    toast.success(SuccessMessage.DissociateSample);
  }

  function handleRegisterClick() {
    history.push(`/amostras/novo`);
  }

  function handleAssociateClick() {
    associateModal.open();
  }

  function handleDissociateClick(sample) {
    dissociateModal.open(sample.id);
  }

  function handleAssociateSampleSubmit(values) {
    const input = {
      sampleId: values.sample.id,
      projectId,
      sampleGoal: values.sampleGoal.value,
    };
    associateSampleMutation.mutate(input);
  }

  function handleDissociateSubmit() {
    const input = {
      sampleId: dissociateModal.data,
      projectId,
    };
    dissociateSampleMutation.mutate(input);
  }

  if (isLoading) {
    return (
      <Loader active size="large" style={{ marginTop: '200px' }}>
        Carregando...
      </Loader>
    );
  }
  const isEmpty = samples.length === 0;

  return (
    <>
      <Grid>
        {!isEmpty && (
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" floated="left">
                Amostras
              </Header>
              {user.isAdmin && !isEmpty && (
                <>
                  <Button
                    basic
                    floated="right"
                    onClick={handleAssociateClick}
                    primary
                  >
                    <Icon name="check" />
                    Associar amostra cadastrada
                  </Button>
                  <Button floated="right" onClick={handleRegisterClick} basic>
                    <Icon name="add" />
                    Cadastrar amostra
                  </Button>
                </>
              )}
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={1}>
          <Grid.Column>
            <ProjectSamplesTable
              onAddClick={handleRegisterClick}
              onAssociateClick={handleAssociateClick}
              onDissociateClick={handleDissociateClick}
              pagination={pagination}
              projectId={projectId}
              samples={samples}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <AssociateSampleModal
        isLoading={associateSampleMutation.isLoading}
        isOpen={associateModal.isOpen}
        onClose={associateModal.close}
        onSubmit={handleAssociateSampleSubmit}
      />

      <AlertModal
        content="Você tem certeza que deseja desassociar esta amostra?"
        header="Desassociar amostra"
        isLoading={dissociateSampleMutation.isLoading}
        isOpen={dissociateModal.isOpen}
        onClose={dissociateModal.close}
        onSubmit={handleDissociateSubmit}
        submitText="Desassociar"
      />

      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
    </>
  );
}

function ProjectSamplesTable({
  projectId,
  samples = [],
  onAddClick,
  onAssociateClick,
  onDissociateClick,
  loading = false,
  pagination,
}) {
  const { user } = useAuth();

  const isEmpty = samples.length === 0;

  if (isEmpty && !user.isAdmin) {
    return (
      <Section>
        <Header as="h2">Amostras</Header>
        <EmptyState>
          <EmptyState.Header>Nenhuma amostra adicionada</EmptyState.Header>
        </EmptyState>
      </Section>
    );
  }

  if (isEmpty) {
    return (
      <Section>
        <Header as="h2">Amostras</Header>
        <EmptyState>
          <EmptyState.Header>Nenhuma amostra adicionada</EmptyState.Header>
          <EmptyState.Description>
            Associe o estudo a uma amostra já cadastrada ou cadastre um nova
            amostra.
          </EmptyState.Description>
          <EmptyState.Actions>
            <Button type="button" onClick={onAssociateClick} primary basic>
              <Icon name="check" />
              Associar amostra cadastrada
            </Button>
            <Button type="button" onClick={onAddClick} basic>
              <Icon name="add" />
              Cadastrar amostra
            </Button>
          </EmptyState.Actions>
        </EmptyState>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Header>
        <PaginationInfo description="Amostras" pagination={pagination} />
      </Section.Header>
      <Section.Content>
        <Table basic="very" size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Código</Table.HeaderCell>
              <Table.HeaderCell>Nome</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Finalidade</Table.HeaderCell>
              <Table.HeaderCell>Entrada</Table.HeaderCell>
              <Table.HeaderCell>Validade</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Qtd. total</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading && <LoadingRow columns={8} rows={1} />}
            {!loading &&
              samples?.map((sample) => {
                const {
                  id,
                  code,
                  type,
                  volume,
                  associatedProjects,
                  arrivalDateFormatted,
                  product,
                  expirationDate,
                  expirationDateFormatted,
                  packagingQuantity,
                  status,
                } = sampleMapper(sample);

                const sampleGoal =
                  associatedProjects.find((project) => project.id === projectId)
                    ?.sampleGoal || '';

                return (
                  <Table.Row key={id}>
                    <Table.Cell>
                      <Text variant="primary">
                        <Link to={`/amostras/${id}`} name="sample">
                          {code}
                        </Link>
                      </Text>
                      <Text variant="secondary">{type}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{product?.name}</Text>
                      <Text variant="secondary">{product?.category}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">
                        <Tag color={SAMPLE_STATUS_COLOR[status]}>
                          {SAMPLE_STATUS_DESCRIPTION[status]}
                        </Tag>
                      </Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Tag color="grey">{sampleGoal}</Tag>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{arrivalDateFormatted}</Text>
                    </Table.Cell>
                    <Table.Cell>
                      <Text variant="primary">{expirationDateFormatted}</Text>
                      <Text variant="secondary">
                        {// eslint-disable-next-line no-nested-ternary
                        expirationDate ? (
                          expirationDate > new Date().toISOString() ? (
                            'Na validade'
                          ) : (
                            <span style={{ color: theme.colors.red }}>
                              Vencida
                            </span>
                          )
                        ) : (
                          'n/d'
                        )}
                      </Text>
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <Text variant="primary">{volume}</Text>
                      <Text variant="secondary">
                        {packagingQuantity === 1
                          ? `${packagingQuantity} embalagem`
                          : `${packagingQuantity} embalagens`}
                      </Text>
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      <LinkButton
                        onClick={() => onDissociateClick(sample)}
                        type="button"
                      >
                        Desassociar
                      </LinkButton>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}
