import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Button, Form, Modal } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import ProjectStageSelect from 'components/inputs/ProjectStageSelect';
import { useChangeProjectStage } from 'mutations/project';

import {
  ChangeProjectStageFormInput,
  ChangeProjectStageFormValues,
} from './validation';

export type ChangeProjectStageModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
};

const FORM_ID = 'form_change_project_stage';
const MESSAGE = {
  success: 'Fase alterada!',
} as const;

export default function ChangeProjectStageModal({
  isOpen,
  onClose,
  projectId,
}: ChangeProjectStageModalProps) {
  const resolver = yupResolver(ChangeProjectStageFormInput);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
  });

  const changeProjectStage = useChangeProjectStage({
    onSuccess: () => handleSuccess(MESSAGE.success),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ChangeProjectStageFormValues) {
    const input = {
      projectId,
      projectStageId: values.projectStage.value,
    };
    changeProjectStage.mutate(input);
  }

  const isLoading =
    changeProjectStage.isLoading || changeProjectStage.isLoading;

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>Alterar fase do estudo</Modal.Header>
      <Modal.Content>
        <Form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Fase do estudo</label>
              <Controller
                control={control}
                name="projectStage"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <ProjectStageSelect
                    disabled={isLoading}
                    isClearable
                    name="projectStage"
                    onBlur={onBlur}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
              {errors.projectStage && (
                <InputError>{errors?.projectStage?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field />
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={FORM_ID}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          Alterar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
