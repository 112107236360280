import { AxiosError } from 'axios';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';

import api from 'util/api';

type MaintenanceInput = {
  sampleId: number;
  projectId: number;
  date: Date;
  product: string;
  ingredient: string;
  dose: number;
  goal: string;
  windSpeed: number | null;
  humidity: number | null;
  temperature: number | null;
  precipitation: number | null;
};

export type MaintenanceUpdateInput = MaintenanceInput & {
  maintenanceId: number;
};

export function useCreateMaintenance(
  options?: UseMutationOptions<void, AxiosError, MaintenanceInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(createMaintenance, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('maintenances');
      queryClient.invalidateQueries('maintenance');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createMaintenance(input: MaintenanceInput) {
  await api.post(`/maintenances`, input);
}

export function useUpdateMaintenance(
  options?: UseMutationOptions<void, AxiosError, MaintenanceInput, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateMaintenance, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('maintenances');
      queryClient.invalidateQueries('maintenance');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateMaintenance(input: MaintenanceUpdateInput) {
  await api.patch(`/maintenances/${input.maintenanceId}`, input);
}

export function useRemoveMaintenance(
  options?: UseMutationOptions<void, AxiosError, number, () => void>,
) {
  const queryClient = useQueryClient();

  return useMutation(deleteMaintenance, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('maintenances');
      queryClient.invalidateQueries('maintenance');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function deleteMaintenance(maintenanceId: number) {
  await api.delete(`/maintenances/${maintenanceId}`);
}
