import React from 'react';

import Select from 'react-select';

import {
  UNIT_KILOGRAM,
  UNIT_GRAM,
  UNIT_MILLILITER,
  UNIT_LITER,
  UNIT_CENTIMETER,
  UNIT_METER,
  UNIT_PLANTS_PER_HECTARE,
  UNIT_PLANTS_PER_POT,
  UNIT_GRAMS_PER_LITERS,
  UNIT_GRAMS_PER_KILOGRAMS,
  UNIT_METERS_PER_SECONDS,
  UNIT_MILLILITERS_PER_HECATRE,
  UNIT_MILLILITERS_PER_POT,
  UNIT_LITERS_PER_HECTARE,
  UNIT_LITERS_PER_POT,
  UNIT_FRUITS_PER_PLOT,
  UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM,
  UNIT_PERCENTAGE,
  UNIT_PLANTS_PER_PLOT,
  UNIT_CULMS_PER_HECTARE,
  UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER,
  UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER,
  UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER,
  UNIT_GRAMS_PER_HECTARE,
} from 'util/unitFormatter';

export default function MeasurementUnitSelect({
  name = undefined,
  value = undefined,
  onChange,
  onBlur,
  onlyUnits = [],
  autoComplete,
  disabled = false,
  isClearable = false,
}) {
  const measurementUnits = [
    { value: UNIT_CENTIMETER, label: 'Centímetro (cm)' },
    { value: UNIT_LITER, label: 'Litro (L)' },
    { value: UNIT_GRAM, label: 'Grama (g)' },
    { value: UNIT_GRAMS_PER_KILOGRAMS, label: 'Gramas por Kilogramas (g/kg)' },
    { value: UNIT_GRAMS_PER_LITERS, label: 'Gramas por Litro (g/L)' },
    { value: UNIT_METER, label: 'Metro (m)' },
    { value: UNIT_MILLILITER, label: 'Mililitro (mL)' },
    { value: UNIT_PLANTS_PER_HECTARE, label: 'Plantas/ha' },
    { value: UNIT_PLANTS_PER_POT, label: 'Plantas/vaso' },
    { value: UNIT_KILOGRAM, label: 'Quilograma (kg)' },
    { value: UNIT_METERS_PER_SECONDS, label: 'Quilograma (kg)' },
    { value: UNIT_MILLILITERS_PER_HECATRE, label: 'Mililitros/ha (ml/ha)' },
    { value: UNIT_MILLILITERS_PER_POT, label: 'Mililitros/vaso (ml/vaso)' },
    { value: UNIT_LITERS_PER_HECTARE, label: 'Litros/ha (L/ha)' },
    { value: UNIT_LITERS_PER_POT, label: 'Litros/vaso (L/vaso)' },
    { value: UNIT_FRUITS_PER_PLOT, label: 'Frutos/parcela' },
    {
      value: UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_GRAM,
      label: 'x 10(9)UFC/g',
    },
    { value: UNIT_PERCENTAGE, label: 'Porcentagem (%)' },
    { value: UNIT_PLANTS_PER_PLOT, label: 'Plantas/parcela' },
    { value: UNIT_CULMS_PER_HECTARE, label: 'Colmos/ha' },
    {
      value: UNIT_10_POWER_10_COLONY_FORMING_UNIT_PER_MILLILITER,
      label: 'x 10(10)UFC/ml',
    },
    {
      value: UNIT_10_POWER_9_COLONY_FORMING_UNIT_PER_MILLILITER,
      label: 'x 10(9)UFC/ml',
    },
    {
      value: UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER,
      label: 'x 10(7)UFC/ml',
    },
    {
      value: UNIT_10_POWER_7_COLONY_FORMING_UNIT_PER_MILLILITER,
      label: 'x 10(7)UFC/ml',
    },
    {
      value: UNIT_GRAMS_PER_HECTARE,
      label: 'Gramas/hectare (g/L)',
    },
  ];

  const hasFilter = onlyUnits.length !== 0;

  const options = !hasFilter
    ? measurementUnits.sort(sortByLabel)
    : measurementUnits.filter(filter).sort(sortByLabel);

  function filter(unit) {
    return onlyUnits.includes(unit.value);
  }

  function sortByLabel(a, b) {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  }

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder="Selecione..."
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete={autoComplete}
    />
  );
}
