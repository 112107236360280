import React, { forwardRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { Form, Header, Input, Divider } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import PhoneInput from 'components/inputs/PhoneInput';
import { formatCpfOrCnpj } from 'util/mask';

import { CustomerBasicInfo } from './validations';

export type CustomerBasicInfoFormValues = {
  companyName: string;
  tradingName: string;
  cnpj: string;
  phone: string;
  insideCode?: string;
  stateRegistration?: string;
};

export type CustomerBasicInfoProps = {
  defaultValues: Partial<CustomerBasicInfoFormValues>;
  onSubmit: (values: CustomerBasicInfoFormValues) => void;
};

const CustomerBasicInfoForm = forwardRef<
  HTMLFormElement,
  CustomerBasicInfoProps
>(({ defaultValues, onSubmit }, ref) => {
  const resolver = yupResolver(CustomerBasicInfo);
  const { control, errors, handleSubmit } = useForm({
    mode: 'onBlur',
    resolver,
    defaultValues,
  });

  return (
    <Form ref={ref} onSubmit={handleSubmit(onSubmit)}>
      <Header as="h3">Informações da empresa</Header>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Razão social</label>
          <Controller
            control={control}
            name="companyName"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.companyName && (
            <InputError>{errors?.companyName?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field>
          <label>Nome fantasia</label>
          <Controller
            control={control}
            name="tradingName"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.tradingName && (
            <InputError>{errors?.tradingName?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field>
          <label>CNPJ/CPF</label>
          <Controller
            control={control}
            name="cnpj"
            render={({ onChange, onBlur, value }) => (
              <NumberFormat
                allowEmptyFormatting
                autoComplete="off"
                format={formatCpfOrCnpj}
                isNumericString
                mask="_"
                maxLength={18}
                onBlur={onBlur}
                onValueChange={(data) => {
                  onChange(data.value);
                }}
                value={value}
              />
            )}
          />
          {errors.cnpj && <InputError>{errors?.cnpj?.message}</InputError>}
        </Form.Field>
        <Form.Field>
          <label>Telefone</label>
          <Controller
            control={control}
            name="phone"
            render={({ onChange, onBlur, value }) => (
              <PhoneInput
                onValueChange={(data) => onChange(data.value)}
                onBlur={onBlur}
                value={value}
                autoComplete="off"
              />
            )}
          />
          {errors.phone && <InputError>{errors?.phone?.message}</InputError>}
        </Form.Field>
      </Form.Group>
      <Form.Group>
        <Form.Field width={8}>
          <label>Inscrição estadual (opcional)</label>
          <Controller
            control={control}
            name="stateRegistration"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                autoComplete="new-password"
                fluid
              />
            )}
          />
          {errors.stateRegistration && (
            <InputError>{errors?.stateRegistration?.message}</InputError>
          )}
        </Form.Field>
        <Form.Field width={4}>
          <label>Código (opcional)</label>
          <Controller
            control={control}
            name="insideCode"
            render={({ onChange, onBlur, value }) => (
              <Input
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                placeholder="01A"
                maxLength={3}
                autoComplete="off"
                fluid
              />
            )}
          />
          {errors.insideCode && (
            <InputError>{errors?.insideCode?.message}</InputError>
          )}
        </Form.Field>
      </Form.Group>
      <Divider hidden />
    </Form>
  );
});

export default CustomerBasicInfoForm;
