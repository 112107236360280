import React, { useEffect } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Form, Input, Button, Modal, Dimmer, Loader } from 'semantic-ui-react';

import InputError from 'components/inputs/InputError';
import PhoneInput from 'components/inputs/PhoneInput';

import { AddEditRepresentative } from './validations';

type FormValues = {
  name: string;
  email?: string;
  phone?: string;
};

export type RepresentativeFormModalProps = {
  formValues?: FormValues;
  isOpen: boolean;
  isSubmiting?: boolean;
  isLoading?: boolean;
  onSubmit: (values: FormValues) => void;
  onClose: () => void;
};

const formId = 'form_representative';

const INITIAL_VALUES = {};

export default function RepresentativeFormModal({
  formValues,
  onClose,
  onSubmit,
  isOpen,
  isSubmiting,
  isLoading,
}: RepresentativeFormModalProps) {
  const defaultValues = formValues || INITIAL_VALUES;
  const resolver = yupResolver(AddEditRepresentative);

  const { control, errors, setValue, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues,
    resolver,
  });

  useEffect(() => {
    setValue('name', formValues?.name || '');
    setValue('email', formValues?.email || '');
    setValue('phone', formValues?.phone || '');
  }, [setValue, formValues]);

  const isEditing = !!formValues;
  const submitButtonText = isEditing ? 'Atualizar' : 'Adicionar';
  const header = isEditing ? 'Editar representante' : 'Novo representante';

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader active inline="centered" size="large">
              Carregando...
            </Loader>
          </Dimmer>
        ) : (
          <Form id={formId}>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Nome</label>
                <Controller
                  control={control}
                  name="name"
                  render={({ onChange, onBlur, value }) => (
                    <Input
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      disabled={isSubmiting}
                      loading={isSubmiting}
                      autoComplete="new-password"
                      fluid
                    />
                  )}
                />
                {errors.name && (
                  <InputError>{errors?.name?.message}</InputError>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>E-mail</label>
              <Controller
                control={control}
                name="email"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isSubmiting}
                    loading={isSubmiting}
                    autoComplete="new-password"
                    fluid
                  />
                )}
              />
              {errors.email && (
                <InputError>{errors?.email?.message}</InputError>
              )}
            </Form.Field>
            <Form.Field>
              <label>Telefone (opcional)</label>
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <PhoneInput
                    onValueChange={(data) => onChange(data.value)}
                    onBlur={onBlur}
                    value={value}
                    autoComplete="new-password"
                  />
                )}
              />
              {errors.phone && (
                <InputError>{errors?.phone?.message}</InputError>
              )}
            </Form.Field>
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          type="button"
          form={formId}
          style={{ marginTop: 20 }}
          onClick={handleSubmit(onSubmit)}
          loading={isSubmiting}
          primary
        >
          {submitButtonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
