import React from 'react';

import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import Select from 'react-select';
import { Divider, Dropdown, Form, Grid, Header } from 'semantic-ui-react';

import { allDivisionValues } from 'components/inputs/DivisionSelect';
import {
  allStagesValues,
  inProgressStagesValues,
} from 'components/inputs/ProjectStageSelect';
import { useAuth } from 'hooks/auth';
import {
  useExportCompletedProjectsWithoutReport,
  useExportFinalStageProjects,
  useExportProjects,
  useExportProjectsInProgress,
} from 'mutations/project';
import { useInfiniteProjects } from 'queries/project';
import { downloadFile } from 'util/FileManager';

import ProjectFilter from './ProjectFilter';
import ProjectTable from './ProjectTable';
import { IN_PROGRESS, useProjectFilter } from './use-project-filter';

const messageInProgress = 'Estudos em andamento exportados com sucesso!';
const messageAllProjects = 'Estudos exportados com sucesso!';
const messageCompletedProjectsWithoutReport = 'Estudos exportados com sucesso!';

export default function ProjectsPage() {
  const { user } = useAuth();
  const { getMappedFilter, updateFilter, filter } = useProjectFilter();
  const { control, setValue } = useForm({ defaultValues: filter });

  const {
    pagination,
    projects,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingProjects,
  } = useInfiniteProjects(getMappedFilter());

  const {
    mutate: exportProjectsInProgress,
    isLoading: isExportingInProgress,
  } = useExportProjectsInProgress({
    onSuccess: ({ file, filename }) =>
      onSuccess({
        file,
        filename,
        message: messageInProgress,
      }),
  });

  const {
    mutate: exportProjects,
    isLoading: isExportingProjects,
  } = useExportProjects({
    onSuccess: ({ file, filename }) =>
      onSuccess({ file, filename, message: messageAllProjects }),
  });

  const {
    mutate: exportCompletedProjectsWithoutReport,
    isLoading: isExportingCompletedProjectsWithoutReport,
  } = useExportCompletedProjectsWithoutReport({
    onSuccess: ({ file, filename }) =>
      onSuccess({
        file,
        filename,
        message: messageCompletedProjectsWithoutReport,
      }),
  });

  const {
    mutate: exportFinalStageProjects,
    isLoading: isExportingFinalStageProjects,
  } = useExportFinalStageProjects({
    onSuccess: ({ file, filename }) =>
      onSuccess({
        file,
        filename,
        message: messageCompletedProjectsWithoutReport,
      }),
  });

  function onSuccess({
    file,
    filename,
    message,
  }: {
    file: Blob;
    filename: string;
    message: string;
  }) {
    downloadFile(file, filename);
    toast.success(message);
  }

  async function handleExportProjectsInProgressClick() {
    exportProjectsInProgress();
  }

  async function handleExportProjectsClick() {
    exportProjects();
  }

  async function handleExportProjectsWithoutReportClick() {
    exportCompletedProjectsWithoutReport();
  }

  async function handleExportFinalStageProjectsClick() {
    exportFinalStageProjects();
  }

  const options = [
    {
      key: 'projects-in-progress',
      text: 'Estudos em andamento',
      value: 'projects-in-progress',
      onClick: handleExportProjectsInProgressClick,
    },
    {
      key: 'all-projects',
      text: 'Todos os estudos',
      value: 'all-projects',
      onClick: handleExportProjectsClick,
    },
    {
      key: 'completed-projects-without-report',
      text: 'Estudos concluídos sem relatório',
      value: 'completed-projects-without-report',
      onClick: handleExportProjectsWithoutReportClick,
    },
    {
      key: 'final-stage-projects',
      text: 'Estudos data/status',
      value: 'final-stage-projects',
      onClick: handleExportFinalStageProjectsClick,
    },
  ];

  const isExporting =
    isExportingInProgress ||
    isExportingProjects ||
    isExportingCompletedProjectsWithoutReport ||
    isExportingFinalStageProjects;

  const divisionFilters = user?.isAdmin ? allDivisionValues : user?.divisions;
  const stageFilters =
    filter.status.value === IN_PROGRESS
      ? inProgressStagesValues
      : allStagesValues;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as="h1">Estudos</Header>
          </Grid.Column>
          <Grid.Column width={3} verticalAlign="middle" />
          <Grid.Column width={3} verticalAlign="middle">
            <Form>
              <Form.Field>
                <Select
                  name="inprogress"
                  instanceId="inprogress"
                  value={filter.status}
                  onChange={(value) => {
                    updateFilter({ status: value, stages: [] });
                    setValue('stages', []);
                  }}
                  options={[
                    { value: 1, label: 'Em andamento' },
                    { value: 2, label: 'Todos' },
                  ]}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right" verticalAlign="middle">
            <Dropdown
              text="Relatórios"
              direction="left"
              loading={isExporting}
              floating
              basic
              button
              fluid
            >
              <Dropdown.Menu>
                <Dropdown.Menu scrolling>
                  {options.map((option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Dropdown.Item {...option} />
                  ))}
                </Dropdown.Menu>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <ProjectFilter
              control={control}
              divisionFilters={divisionFilters}
              stageFilters={stageFilters}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <ProjectTable
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              isFetchingNextPage={isFetchingNextPage}
              isLoading={isLoadingProjects}
              pagination={pagination}
              projects={projects}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
