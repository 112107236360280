import React from 'react';

import styled, { css } from 'styled-components';

type InlineSize = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl';

export type InlineProps = {
  size?: InlineSize;
};

type WrapperProps = {
  size: InlineSize;
};

const inlineModifiers = {
  xxs: () => css`
    margin: 0 0 0 2px;
  `,
  xs: () => css`
    margin: 0 0 0 4px;
  `,
  s: () => css`
    margin: 0 0 0 8px;
  `,
  m: () => css`
    margin: 0 0 0 16px;
  `,
  l: () => css`
    margin: 0 0 0 32px;
  `,
  xl: () => css`
    margin: 0 0 0 64px;
  `,
};

const Wrapper = styled.div<WrapperProps>`
  ${({ size }) => css`
    ${inlineModifiers[size]}
  `}
`;

function Inline({ size = 'm' }: InlineProps) {
  return <Wrapper size={size} />;
}

export default Inline;
