import React from 'react';

import Select from 'react-select';

import { CONTRACT_STATUS } from 'data/contract';

const OPTIONS = Object.values(CONTRACT_STATUS).map(({ id, description }) => ({
  value: id,
  label: description,
}));

export default function ContractStatusSelect({
  name,
  value,
  onChange,
  isClearable = false,
  isMulti = false,
  disabled,
  placeholder = 'Selecione...',
}) {
  return (
    <Select
      name={name}
      instanceId={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      options={OPTIONS}
      isDisabled={disabled}
      isClearable={isClearable}
      isMulti={isMulti}
    />
  );
}
