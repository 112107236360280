import { AxiosError } from 'axios';
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
} from 'react-query';

import api from 'util/api';

export type CompanyInfo = {
  id: number;
  name: string;
  cnpj: string;
  stateRegistration: string;
  address: string;
  mailbox: string;
  city: string;
  state: string;
  cep: string;
  phone: string;
  websiteUrl: string;
  version: number;
};

export const userKeys = {
  all: ['users'] as const,
};

export function useCompanyInfo() {
  return useQuery({
    queryKey: 'company-info',
    queryFn: () => getCompanyInfo(),
  });
}

async function getCompanyInfo() {
  const url = '/admin/company-info';
  const { data } = await api.get<CompanyInfo>(url);

  return data;
}

type UpdateCompanyInfoInput = {
  name: string;
  cnpj: string;
  stateRegistration: string;
  address: string;
  mailbox: string;
  city: string;
  state: string;
  cep: string;
  phone: string;
  websiteUrl: string;
};

export function useUpdateCompanyInfo(
  options?: UseMutationOptions<void, AxiosError, UpdateCompanyInfoInput>,
) {
  const queryClient = useQueryClient();

  return useMutation(updateCompanyInfo, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('company-info');

      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

const updateCompanyInfo = async (input: UpdateCompanyInfoInput) => {
  const url = '/admin/company-info';
  await api.post(url, input);
};
