import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ align }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: ${align || 'center'};
    align-items: ${align || 'center'};
    padding: 32px 64px;
  `}
`;

export const EmptyStateHeader = styled.div`
  text-align: center;
`;

export const EmptyStateDescription = styled.p`
  text-align: center;
`;

export const EmptyStateActions = styled.div``;
