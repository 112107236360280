import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Button,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
} from 'semantic-ui-react';
import * as yup from 'yup';

import CustomerSearch from 'components/inputs/CustomerSearch';
import DatePicker from 'components/inputs/date-picker';
import EmployeeSearch from 'components/inputs/EmployeeSearch';
import HiddenLabel from 'components/inputs/HiddenLabel';
import InputError from 'components/inputs/InputError';
import RepresentativeSearch from 'components/inputs/RepresentativeSearchV2';
import SampleSearch from 'components/inputs/SampleSearch';
import UploadInput from 'components/inputs/UploadInput';
import { useUpload } from 'hooks/use-upload';
import { useReturnSamples } from 'mutations/sample';
import { DOCUMENT_SAMPLES_RETURN_TERM } from 'util/Document';
import { emptyStringToUndefined } from 'util/validation';

const SamplesReturnFormInput = yup.object().shape({
  date: yup
    .date()
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
  receivingResponsible: yup
    .object()
    .required('Obrigatório')
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  returnResponsible: yup
    .object()
    .required('Obrigatório')
    .transform(emptyStringToUndefined)
    .typeError('Obrigatório'),
  notes: yup
    .string()
    .nullable()
    .optional()
    .transform(emptyStringToUndefined),
  samples: yup
    .array()
    .of(
      yup.object({
        sample: yup
          .object()
          .required('Obrigatório')
          .typeError('Obrigatório'),
      }),
    )
    .required('Obrigatório')
    .transform(emptyStringToUndefined),
});

type FileEvent = React.ChangeEvent<HTMLInputElement>;

type SamplesReturnFormValues = {
  date: Date;
  receivingResponsible: {
    id: number;
    name: string;
  };
  returnResponsible: {
    value: number;
    label: string;
  };
  notes?: string | null;
  samples: {
    sample: {
      id: number;
      code: string;
      product: {
        id: number;
        name: string;
      };
    };
  }[];
};

export type SamplesReturnModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const formId = 'form_product_item';

const MESSAGE = {
  successReturn: 'Amostras devolvidas!',
} as const;

export default function SamplesReturnModal({
  isOpen,
  onClose,
}: SamplesReturnModalProps) {
  const defaultValues = { samples: [{}] };
  const resolver = yupResolver(SamplesReturnFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit, setValue, watch } = useForm(
    formOptions,
  );
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'samples',
  });
  const watchProjects = watch('samples');
  const watchCustomer = watch('customer');

  const documentFile = useUpload();

  const returnSamples = useReturnSamples({
    onSuccess: () => {
      handleSuccess(MESSAGE.successReturn);
    },
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function handleCustomerChange() {
    setValue('receivingResponsible', null);
    setValue('samples', [{}]);
  }

  function handleDocumentFileChange(e: FileEvent, documentType: number) {
    const file = e.target.files ? e.target.files[0] : null;
    documentFile.handleUpload(file, documentType);
  }

  function onSubmit(values: SamplesReturnFormValues) {
    if (!documentFile.file?.url || typeof documentFile.file?.id === 'string') {
      return;
    }

    const input = {
      date: values.date,
      receivingResponsibleId: values.receivingResponsible.id,
      returnResponsibleId: values.returnResponsible.value,
      documentId: documentFile.file?.id,
      notes: values.notes || null,
      sampleIds: values.samples.map(({ sample }) => sample.id),
    };

    returnSamples.mutate(input);
  }

  return (
    <Modal size="small" open={isOpen} onClose={onClose}>
      <Modal.Header>Devolução de amotras</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Devolução</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field width={6}>
                  <label>Data da devolução</label>
                  <Controller
                    control={control}
                    name="date"
                    defaultValue=""
                    render={({ onChange, onBlur, value }) => (
                      <DatePicker
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                  {errors.date && (
                    <InputError>{errors?.date?.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Responsável pela devolução</label>
                  <Controller
                    control={control}
                    defaultValue=""
                    name="returnResponsible"
                    render={({ onChange, onBlur, value }) => (
                      <EmployeeSearch
                        onBlur={onBlur}
                        onChange={onChange}
                        placeholder="Busque pelo nome"
                        value={value}
                      />
                    )}
                  />
                  {errors.returnResponsible && (
                    <InputError>
                      {errors?.returnResponsible?.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Empresa</label>
                  <Controller
                    control={control}
                    name="customer"
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <CustomerSearch
                          onChange={(customerData) => {
                            handleCustomerChange();
                            onChange(customerData);
                          }}
                          onBlur={onBlur}
                          value={value}
                          disabled={returnSamples.isLoading}
                          autoComplete="off"
                        />
                      </>
                    )}
                  />
                  {errors.customer && (
                    <InputError>{errors.customer.message}</InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Responsável pelo recebimento</label>
                  <Controller
                    control={control}
                    name="receivingResponsible"
                    render={({ onChange, onBlur, value }) => (
                      <>
                        <RepresentativeSearch
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                          customerId={watchCustomer?.id}
                          disabled={returnSamples.isLoading || !watchCustomer}
                          autoComplete="off"
                        />
                      </>
                    )}
                  />
                  {errors.receivingResponsible && (
                    <InputError>
                      {errors.receivingResponsible.message}
                    </InputError>
                  )}
                </Form.Field>
                <Form.Field width={12}>
                  <label>Observações (opcional)</label>
                  <Controller
                    control={control}
                    name="notes"
                    render={({ onChange, onBlur, value }) => (
                      <Input
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        disabled={returnSamples.isLoading}
                        autoComplete="off"
                        fluid
                      />
                    )}
                  />
                  {errors.notes && (
                    <InputError>{errors?.notes?.message}</InputError>
                  )}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Anexo</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Form.Field>
                  <label>Termo de devolução de amostras</label>
                  <UploadInput
                    name="document"
                    uploadedFile={documentFile?.file}
                    onUpload={(e: FileEvent) =>
                      handleDocumentFileChange(e, DOCUMENT_SAMPLES_RETURN_TERM)
                    }
                    onDelete={documentFile.handleDelete}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
              <Grid.Column width={6}>
                <Header as="h3">Amostras</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                {fields.map((item, index) => {
                  const isFirstItem = index === 0;
                  const disabledRemoveButton =
                    isFirstItem && watchProjects.length === 1;

                  return (
                    <Form.Group key={item.id}>
                      <Form.Field width={12}>
                        {isFirstItem ? <label>Amostra</label> : null}
                        <Controller
                          control={control}
                          name={`samples[${index}].sample`}
                          defaultValue={item.sample}
                          render={({ onChange, onBlur, value }) => (
                            <SampleSearch
                              autoComplete="off"
                              isClearable
                              onBlur={onBlur}
                              onChange={onChange}
                              placeholder="Busque pelo código..."
                              value={value}
                            />
                          )}
                        />
                        {errors.samples && (
                          <InputError>
                            {errors?.samples[index]?.sample?.message}
                          </InputError>
                        )}
                      </Form.Field>
                      <Form.Field width={1}>
                        {isFirstItem ? <HiddenLabel /> : null}
                        <Button
                          type="button"
                          size="small"
                          onClick={() => remove(index)}
                          disabled={disabledRemoveButton}
                          icon
                          basic
                        >
                          <Icon name="trash" />
                        </Button>
                      </Form.Field>
                    </Form.Group>
                  );
                })}
                {errors.samples && (
                  <InputError>{errors?.samples?.message}</InputError>
                )}
                <Form.Field width={11}>
                  <Button basic fluid onClick={() => append({})} type="button">
                    <Icon name="add" />
                    Adicionar uma amostra
                  </Button>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={returnSamples.isLoading}
          form={formId}
          loading={returnSamples.isLoading}
          primary
          type="submit"
        >
          Devolver amostras
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
