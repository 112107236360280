import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Container, Divider, Grid, Header } from 'semantic-ui-react';

import Base from 'components/layout/Base';
import Section from 'components/layout/Section';
import { useCreateSample } from 'mutations/sample';

import SampleForm from './SampleForm';

export default function AddSamplePage() {
  const history = useHistory();

  const { mutate: createSample, isLoading } = useCreateSample({
    onSuccess: (productId) => {
      toast.success('Amostra cadastrada com sucesso!');
      navigateToSampleDetails(productId);
    },
  });

  const navigateToSampleDetails = React.useCallback(
    (sampleId) => {
      history.replace(`/amostras/${sampleId}`);
    },
    [history],
  );

  const handleCancelClick = () => history.goBack();

  const handleSubmit = async (values: any) => {
    const input = {
      volume: values.volume,
      batchNumber: values.batchNumber,
      arrivalDate: values.arrivalDate,
      measurementUnit: values.measurementUnit,
      sampleType: values.sampleType,
      checkInDocumentId: values.checkInDocumentId,
      custodyChainDocumentId: values.custodyChainDocumentId,
      invoiceDocumentId: values.invoiceDocumentId,
      manufacturingDate: values?.manufacturingDate,
      hasManufacturingDay: values?.hasManufacturingDay,
      expirationDate: values?.expirationDate,
      hasExpirationDay: values?.hasExpirationDay,
      product: {
        name: values.productName,
        type: values.productType.value,
        productCategoryId: values.productCategory.value,
        formulationId: values.formulation.value,
        ingredients: values.ingredients.map((ingredient) => {
          return {
            ingredientId: ingredient.ingredient.id,
            concentration: ingredient.concentration || undefined,
            measurementUnit: ingredient.measurementUnit || undefined,
          };
        }),
        mapaRegister: values.mapaRegister || null,
        ret:
          values.ret && values.retStage && values.retExpirationDate
            ? {
                number: values.ret || undefined,
                stage: values.retStage?.value || undefined,
                expirationDate: values.retExpirationDate || undefined,
              }
            : undefined,
      },
      packagings: values.packagings.map(({ volume }, index) => ({
        number: index + 1,
        volume,
      })),
      associatedProjects:
        values?.associatedProjects?.map((item) => ({
          projectId: item.project.id,
          sampleGoal: item.sampleGoal.value,
        })) || [],
    };

    createSample(input);
  };

  return (
    <Base>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Nova amostra</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <SampleForm
                    onSubmit={handleSubmit}
                    onCancelClick={handleCancelClick}
                    isLoading={isLoading}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}
