import * as React from 'react';

import { DateTime } from 'luxon';
import { Button, Header, Icon, Popup, Table } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import Section from 'components/layout/Section';
import { formatDivision } from 'queries/division';
import * as Currency from 'util/Currency';

type ProductItem = {
  id: number;
  amount: number;
  description: string;
  division: number;
  ncm: {
    id: number;
    code: string;
  };
  number: number;
  quantity: number;
  quotationId: number;
  target: { id: number; name: string };
  totalAmount: number;
  unit: { id: number; abbreviation: string };
  unitPrice: number;
  shippingForecast: string | null;
};

export type ProductItemTable = {
  currency: number;
  isLoading: boolean;
  items: ProductItem[];
  onAddClick?: () => void;
  onDeleteClick: (productItemId: number) => void;
  onDuplicateClick: (productItemId: number) => void;
  onEditClick: (productItemId: number) => void;
  showActions?: boolean;
  showAddButton?: boolean;
};

const DESCRIPTION_ADMIN = 'Clique no botão abaixo para adicionar um item';
const DESCRIPTION_USER = 'Clique no botão abaixo para adicionar um item';

function ProductItemTable({
  isLoading,
  items = [],
  onAddClick,
  onDeleteClick,
  onDuplicateClick,
  onEditClick,
  showActions = false,
  showAddButton = true,
}: ProductItemTable) {
  const emptyStateDescription = onAddClick
    ? DESCRIPTION_ADMIN
    : DESCRIPTION_USER;

  if (!items.length) {
    return (
      <Section>
        <Section.Content>
          <EmptyState>
            <EmptyState.Header>Nenhum item adicionado</EmptyState.Header>
            <EmptyState.Description>
              {emptyStateDescription}
            </EmptyState.Description>
            {onAddClick ? (
              <EmptyState.Actions>
                <Button basic type="button" onClick={onAddClick} primary>
                  <Icon name="add" />
                  Adicionar
                </Button>
              </EmptyState.Actions>
            ) : null}
          </EmptyState>
        </Section.Content>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Header>
        <Header as="h3">Itens</Header>
        {showAddButton ? (
          <Button basic onClick={onAddClick} primary>
            <Icon name="add" /> Novo item
          </Button>
        ) : null}
      </Section.Header>
      <Section.Content>
        <Table size="small" basic="very" fixed singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>#</Table.HeaderCell>
              <Table.HeaderCell width={3}>Descrição</Table.HeaderCell>
              <Table.HeaderCell>NCM</Table.HeaderCell>
              <Table.HeaderCell>Setor</Table.HeaderCell>
              <Table.HeaderCell>Prev. Expedição</Table.HeaderCell>
              <Table.HeaderCell>Qtd.</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor unit.</Table.HeaderCell>
              <Table.HeaderCell textAlign="right">Valor total</Table.HeaderCell>
              {showActions ? <Table.HeaderCell textAlign="center" /> : null}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {isLoading && <LoadingRow columns={5} rows={5} />}
            {!isLoading &&
              items?.map(
                ({
                  description,
                  division,
                  id,
                  ncm,
                  number,
                  quantity,
                  unit,
                  unitPrice,
                  totalAmount,
                  shippingForecast,
                }) => {
                  return (
                    <Table.Row key={id.toString()}>
                      <Table.Cell width={1}>{number}</Table.Cell>
                      <Table.Cell width={3}>
                        <Text variant="primary">{description}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">{ncm.code}</Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {formatDivision({ division })}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {shippingForecast
                            ? DateTime.fromISO(shippingForecast).toFormat(
                                'dd/MM/yyyy',
                              )
                            : 'A definir'}
                        </Text>
                      </Table.Cell>
                      <Table.Cell>
                        <Text variant="primary">
                          {quantity} {unit.abbreviation}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        <Text variant="primary">
                          {Currency.formatWithSymbol({
                            amount: unitPrice,
                            currency: Currency.Currencies.REAL,
                          })}
                        </Text>
                      </Table.Cell>
                      <Table.Cell textAlign="right">
                        {Currency.formatWithSymbol({
                          amount: totalAmount,
                          currency: Currency.Currencies.REAL,
                        })}
                      </Table.Cell>
                      {showActions ? (
                        <Table.Cell textAlign="center">
                          <Popup
                            trigger={
                              <Button
                                size="mini"
                                onClick={() => onDuplicateClick(id)}
                                icon
                                circular
                                basic
                              >
                                <Icon name="copy outline" />
                              </Button>
                            }
                            content="Duplicar"
                            mouseEnterDelay={500}
                            inverted
                          />
                          <Popup
                            trigger={
                              <Button
                                size="mini"
                                onClick={() => onEditClick(id)}
                                icon
                                circular
                                basic
                              >
                                <Icon name="edit" />
                              </Button>
                            }
                            content="Editar"
                            mouseEnterDelay={500}
                            inverted
                          />
                          <Popup
                            trigger={
                              <Button
                                size="mini"
                                onClick={() => onDeleteClick(id)}
                                icon
                                circular
                                basic
                              >
                                <Icon name="trash" />
                              </Button>
                            }
                            content="Excluir"
                            mouseEnterDelay={500}
                            inverted
                          />
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  );
                },
              )}
          </Table.Body>
        </Table>
      </Section.Content>
    </Section>
  );
}

export default ProductItemTable;
