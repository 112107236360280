const makeCustomerRepository = ({ api }) => ({
  async getAllCustomers({
    page = 1,
    pageSize = 10,
    orderBy = 'tradingName',
    sort = 'asc',
    tradingName = '',
  }) {
    const { data } = await api.get(
      `/customers?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sort=${sort}&tradingName=${tradingName}`,
    );

    const customers = customerMapperList({ data: data.data, origin: 'API' });

    return { data: customers, pagination: data.pagination };
  },

  async getCustomer(customerId) {
    const { data } = await api.get(`/customers/${customerId}`);

    return customerMapper({
      data,
      origin: 'API',
    });
  },

  async getAllCustomerRepresentatives(
    customerId,
    { page = 1, pageSize = 10, orderBy = 'name', sort = 'asc', name = '' },
  ) {
    const { data } = await api.get(
      `/customers/${customerId}/representatives?page=${page}&pageSize=${pageSize}&orderBy=${orderBy}&sort=${sort}&name=${name}`,
    );

    const representatives = representativeMapperList({
      data: data.data,
      origin: 'API',
    });

    return { data: representatives, pagination: data.pagination };
  },

  async getRepresentative({ customerId, representativeId }) {
    const { data } = await api.get(
      `/customers/${customerId}/representatives/${representativeId}`,
    );

    return data;
  },

  async createCustomer(customer) {
    try {
      const createCustomerInput = customerMapper({
        data: customer,
        origin: 'API_CREATE',
      });

      const { headers } = await api.post(`/customers`, createCustomerInput);

      const { location } = headers;
      const customerId = parseInt(location.substring(14), 10);

      return customerId;
    } catch (e) {
      throw e.response.data;
    }
  },

  async createRepresentative(data) {
    const { customerId } = data;

    const representative = await representativeMapper({
      data,
      origin: 'FORM',
    });

    await api.post(`/customers/${customerId}/representatives`, representative);

    return true;
  },

  async updateCustomer(input) {
    const { customerId } = input;

    await api.patch(`/customers/${customerId}`, input);
  },

  async updateRepresentative(input) {
    const { representativeId, customerId } = input;

    await api.patch(
      `/customers/${customerId}/representatives/${representativeId}`,
      input,
    );
  },
});

function customerMapperList({ data, origin }) {
  return data.map((item) => customerMapper({ data: item, origin }));
}

function customerMapper({ data, origin }) {
  const mappers = {
    API: apiCustomerResponseMapper,
    API_CREATE: apiCreateMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

function apiCustomerResponseMapper(customerApi) {
  return { ...customerApi };
}

function apiCreateMapper(customer) {
  return {
    companyName: customer.companyName,
    tradingName: customer.tradingName,
    insideCode: customer.insideCode || null,
    cnpj: customer.cnpj,
    phone: customer.phone,
    stateRegistration: customer.stateRegistration,
    representatives: representativeMapperList({
      data: customer.representatives,
      origin: 'FORM',
    }),
  };
}

export function representativeMapperList({ data, origin }) {
  return data.map((item) => representativeMapper({ data: item, origin }));
}

export function representativeMapper({ data, origin }) {
  const mappers = {
    API: apiRepresentativeResponseMapper,
    FORM: formInputsMapper,
  };

  return mappers[origin] ? mappers[origin](data) : data;
}

function apiRepresentativeResponseMapper(representativeApi) {
  return {
    ...representativeApi,
  };
}

function formInputsMapper(data) {
  const { name, email, phone = '' } = data;

  const apiValues = {
    name,
    email,
    phone,
  };

  return apiValues;
}

export default makeCustomerRepository;
