import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import api from 'util/api';

type WeatherInput = {
  weatherId: number;
  date: string;
  temperatureMin: number;
  temperatureMax: number;
  humidityMin: number;
  humidityMax: number;
  rain: number;
  stationId: number;
};

type ImportWeatherInput = {
  file: any;
  onUploadProgress: (percent: number) => void;
};

export function useCreateWeather(
  options?: UseMutationOptions<void, AxiosError, WeatherInput>,
) {
  const queryClient = useQueryClient();
  return useMutation((input) => api.post(`/weathers`, input), {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('weathers');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

export function useUpdateWeather() {
  return useMutation((input: WeatherInput) =>
    api.patch(`/weathers/${input.weatherId}`, input),
  );
}

export function useRemoveWeather() {
  return useMutation((weatherId: number) =>
    api.delete(`/weathers/${weatherId}`),
  );
}

export function useImportWeathers(
  options?: UseMutationOptions<
    void,
    AxiosError,
    ImportWeatherInput,
    () => void
  >,
) {
  const queryClient = useQueryClient();
  return useMutation(importWeather, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('weathers');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function importWeather({
  file,
  onUploadProgress,
}: {
  file: any;
  onUploadProgress?: (progress: number) => void;
}) {
  const data = new FormData();
  data.append('file', file.file, file.name);

  return api
    .post('/weathers/import', data, {
      onUploadProgress: (e) => {
        const progress = calculateProgress(e);
        if (onUploadProgress) {
          onUploadProgress(progress);
        }
      },
    })
    .then((response) => response.data)
    .catch((error) => {
      throw error;
    });
}

function calculateProgress(e: ProgressEventInit) {
  const { loaded = 0, total = 0 } = e;
  return Math.round((loaded * 100) / total);
}
