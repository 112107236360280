import React from 'react';

import toast from 'react-hot-toast';
import { Button, Modal } from 'semantic-ui-react';

import { useAddActivity } from 'mutations/project';

import ActivityAddForm, {
  ActivityAddFormValues,
  FORM_ID,
} from './ActivityAddForm';

type ActivityAddModalProps = {
  isOpen: boolean;
  onClose: () => void;
  projectId: number;
};

export default function ActivityAddModal({
  isOpen,
  onClose,
  projectId,
}: ActivityAddModalProps) {
  const { mutate: addActivity, isLoading: isSubmitting } = useAddActivity({
    onSuccess: () => {
      handleSuccess('Atividade atualizada!');
      onClose();
    },
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: ActivityAddFormValues) {
    const input = {
      date: values.date,
      type: values.type.value,
      description: values.description || null,
      projectId,
    };
    addActivity(input);
  }

  const header = 'Nova atividade';

  return (
    <Modal size="mini" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <ActivityAddForm isLoading={isSubmitting} onSubmit={onSubmit} />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          disabled={isSubmitting}
          form={FORM_ID}
          loading={isSubmitting}
          primary
        >
          Adicionar
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
