import * as React from 'react';

import { Divider, Grid, Header } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import { allDivisionValues } from 'components/inputs/DivisionSelect';
import Section from 'components/layout/Section';
import ActivityPerformModal from 'components/modal/ActivityPerformModal';
import { useAuth } from 'hooks/auth';
import { useModalWithData } from 'hooks/useModal';
import { useInfiniteActivities, ACTIVITY_STATUS } from 'queries/activity';

import RecentActivityList from './RecentActivityList';

const activitiesFilter = {
  pageSize: 5,
  inProgress: true,
  orderBy: 'date',
  sort: 'asc',
  status: ACTIVITY_STATUS.notPerformed,
};

export default function Dashboard() {
  const { user } = useAuth();

  const activityModal = useModalWithData();

  const divisions = user.isAdmin ? allDivisionValues : user.divisions;
  const { activities } = useInfiniteActivities({
    ...activitiesFilter,
    divisions,
  });

  function handleProjectClick(activityId: number) {
    activityModal.open(activityId);
  }

  const isActivitiesEmpty = activities.length === 0;

  return (
    <>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header as="h1">Dashboard</Header>
          </Grid.Column>
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column width={8}>
            <Section>
              <Section.Header>
                <Header as="h3" style={{ margin: 0 }}>
                  Atividades recentes
                </Header>
              </Section.Header>
              <Section.Content>
                {!isActivitiesEmpty ? (
                  <RecentActivityList
                    items={activities}
                    onClick={handleProjectClick}
                  />
                ) : (
                  <EmptyState>
                    <EmptyState.Header>Nenhuma atividade</EmptyState.Header>
                    <EmptyState.Description>
                      Adicione atividades aos estudos para visualizar seu
                      cronograma
                    </EmptyState.Description>
                  </EmptyState>
                )}
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <ActivityPerformModal
        activityId={activityModal.data}
        isOpen={activityModal.isOpen}
        onClose={activityModal.close}
      />
    </>
  );
}
