/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import AuthPage from 'Auth';
import { DocumentFilterProvider } from 'components/data/FilteredDocumentTable/use-document-filter';
import { SaleServiceFilterProvider } from 'components/data/FilteredSaleServiceTable/use-sale-service-filter';
import { useAuth } from 'hooks/auth';
import { AuthContext } from 'hooks/use-auth';
import AdministrationPage from 'pages/administracao';
import EditTargetPage from 'pages/alvos/[targetId]/editar';
import AddTargetPage from 'pages/alvos/novo';
import SamplesPage from 'pages/amostras';
import SampleDetailsPage from 'pages/amostras/[sampleId]';
import AddSamplePage from 'pages/amostras/novo';
import { SampleFilterProvider } from 'pages/amostras/use-sample-filter';
import EditEmployeePage from 'pages/colaboradores/[employeeId]/editar';
import AddEmployeePage from 'pages/colaboradores/novo';
import CollectionsPage from 'pages/coletas';
import SettingsPage from 'pages/configuracoes';
import ContractPage from 'pages/contratos/[contractId]';
import EditContractPage from 'pages/contratos/[contractId]/editar';
import AddContractPage from 'pages/contratos/novo';
import BreedingPage from 'pages/criacoes';
import { ShippingFilterProvider } from 'pages/criacoes/use-shipping-filter';
import QuotationPage from 'pages/orcamentos/[quotationId]';
import EditQuotationProjectPage from 'pages/orcamentos/[quotationId]/estudos/[quotationProjectId]/editar';
import AddQuotationProjectPage from 'pages/orcamentos/novo';
import CustomerPage from 'pages/patrocinadores/[customerId]';
import ResearchPage from 'pages/pesquisa';
import { ActivityFilterProvider } from 'pages/pesquisa/atividades/use-activity-filter';
import ProjectPage from 'pages/pesquisa/estudos/[projectId]';
import { ProjectFilterProvider } from 'pages/pesquisa/estudos/use-project-filter';
import ProceduresPage from 'pages/pops';
import EditProductPage from 'pages/produtos/[productId]/editar';
import EditProjectPage from 'pages/projetos/[projectId]/editar';
import AddProjectPage from 'pages/projetos/[projectId]/novo';
import EditProtocolDetailsPage from 'pages/projetos/[projectId]/protocolo/detalhes/editar';
import EditProjectProtocolPage from 'pages/projetos/[projectId]/protocolo/editar';
import EditPropertyPage from 'pages/propriedades/[propertyId]';
import AddPropertyPage from 'pages/propriedades/novo';
import { ContractFilterProvider } from 'templates/Contracts/use-contract-filter';
import { ProfitSharingFilterProvider } from 'templates/ProfitSharing/use-profit-sharing-filter';
import { QuotationFilterProvider } from 'templates/Quotations/use-quotation-filter';
import { WeatherFilterProvider } from 'templates/Weathers/use-weather-filter';

const routes = [
  /* {
    name: 'Dashboard',
    path: '/dashboard',
    component: DashboardPage,
    exact: true,
    private: true,
  }, */
  {
    name: 'Administração',
    path: '/administracao/:active_tab?',
    component: AdministrationPage,
    exact: true,
  },
  {
    name: 'Amostras',
    path: '/amostras',
    component: SamplesPage,
    exact: true,
  },
  {
    name: 'Nova amostras',
    path: '/amostras/novo',
    component: AddSamplePage,
    exact: true,
  },
  {
    name: 'Amostra',
    path: '/amostras/:id/:active_tab?',
    component: SampleDetailsPage,
    exact: true,
  },
  {
    name: 'POPs',
    path: '/pops',
    component: ProceduresPage,
    exact: true,
  },
  {
    name: 'Configurações',
    path: '/configuracoes/:active_tab?',
    component: SettingsPage,
    exact: true,
    private: true,
  },
  {
    name: 'Criações',
    path: '/criacoes',
    component: BreedingPage,
    exact: true,
  },
  {
    name: 'Pesquisa',
    path: '/pesquisa/:active_tab?',
    component: ResearchPage,
    exact: true,
  },
  {
    name: 'Orçamento',
    path: '/orcamentos/:quotationId',
    component: QuotationPage,
    exact: true,
  },
  {
    name: 'Novo item',
    path: '/orcamentos/:quotationId/estudos/novo',
    component: AddQuotationProjectPage,
    exact: true,
  },
  {
    name: 'Edição de item',
    path: '/orcamentos/:quotationId/estudos/:quotationProjectId/editar',
    component: EditQuotationProjectPage,
    exact: true,
  },
  {
    name: 'Novo contrato',
    path: '/contratos/novo',
    component: AddContractPage,
    exact: true,
    private: true,
  },
  {
    name: 'Contrato',
    path: '/contratos/:id',
    component: ContractPage,
    exact: true,
    private: true,
  },
  {
    name: 'Edição de contrato',
    path: '/contratos/:id/editar',
    component: EditContractPage,
    exact: true,
    private: true,
  },
  {
    name: 'Novo estudo',
    path: '/estudos/novo',
    component: AddProjectPage,
    exact: true,
  },
  {
    name: 'Edição de estudo',
    path: '/estudos/:id/editar',
    component: EditProjectPage,
    exact: true,
  },
  {
    name: 'Estudo',
    path: '/estudos/:id/:active_tab?',
    component: ProjectPage,
    exact: true,
  },
  {
    name: 'Edição de protocolo',
    path: '/estudos/:id/protocolo/editar',
    component: EditProjectProtocolPage,
    exact: true,
  },
  {
    name: 'Edição de ???',
    path: '/estudos/:id/protocolo/detalhes/editar',
    component: EditProtocolDetailsPage,
    exact: true,
  },
  {
    name: 'Edição de produto',
    path: '/produtos/:productId/editar',
    component: EditProductPage,
    exact: true,
  },
  {
    name: 'Cliente',
    path: '/patrocinadores/:customerId',
    component: CustomerPage,
    exact: true,
    private: true,
  },
  {
    name: 'Novo colaborador',
    path: '/colaboradores/novo',
    component: AddEmployeePage,
    exact: true,
    private: true,
  },
  {
    name: 'Edição de colaborador',
    path: '/colaboradores/:employeeId/editar',
    component: EditEmployeePage,
    exact: true,
    private: true,
  },
  {
    name: 'Novo alvo',
    path: '/alvos/novo',
    component: AddTargetPage,
    exact: true,
    private: true,
  },
  {
    name: 'Edição de alvo',
    path: '/alvos/:targetId/editar',
    component: EditTargetPage,
    exact: true,
    private: true,
  },
  {
    name: 'Nova propriedade',
    path: '/propriedades/novo',
    component: AddPropertyPage,
    exact: true,
  },
  {
    name: 'Edição de propriedade',
    path: '/propriedades/:propertyId/editar',
    component: EditPropertyPage,
    exact: true,
  },
  {
    name: 'Coletas',
    path: '/coletas',
    component: CollectionsPage,
    exact: true,
  },
];

export default () => {
  const location = useLocation();
  const { user } = useAuth();

  const auth = React.useContext(AuthContext);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (auth.isLoading) {
    return (
      <Loader active size="large" style={{ marginTop: '100px' }}>
        Carregando...
      </Loader>
    );
  }

  if (!auth.isLoading && !auth.logged && !auth.user) {
    return (
      <main>
        <Switch>
          <Route exact path="/login" component={AuthPage} />
          <Redirect to="/login" />
        </Switch>
      </main>
    );
  }

  return (
    <QuotationFilterProvider>
      <ContractFilterProvider>
        <ProjectFilterProvider>
          <ProfitSharingFilterProvider>
            <SampleFilterProvider>
              <ActivityFilterProvider>
                <WeatherFilterProvider>
                  <ShippingFilterProvider>
                    <DocumentFilterProvider>
                      <SaleServiceFilterProvider>
                        <main>
                          <Switch>
                            {routes.map((route) => {
                              return route.private ? (
                                <PrivateRoute
                                  key={route.path}
                                  exact={route.exact}
                                  path={route.path}
                                  component={route.component}
                                />
                              ) : (
                                <Route
                                  key={route.path}
                                  exact={route.exact}
                                  path={route.path}
                                  component={route.component}
                                />
                              );
                            })}

                            <Redirect to={getDefaultRoute(user)} />
                          </Switch>
                        </main>
                      </SaleServiceFilterProvider>
                    </DocumentFilterProvider>
                  </ShippingFilterProvider>
                </WeatherFilterProvider>
              </ActivityFilterProvider>
            </SampleFilterProvider>
          </ProfitSharingFilterProvider>
        </ProjectFilterProvider>
      </ContractFilterProvider>
    </QuotationFilterProvider>
  );
};

function PrivateRoute({ component: Component, ...rest }) {
  const { user } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        user.isAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  );
}

const DEFAULT_ROUTE_BY_ROLE = {
  admin: '/administracao',
  research: '/pesquisa',
  warehouse: '/amostras',
  breeding: '/criacoes',
};

function getDefaultRoute(user) {
  if (!DEFAULT_ROUTE_BY_ROLE[user.roleType]) {
    return '/pesquisa';
  }

  return DEFAULT_ROUTE_BY_ROLE[user.roleType];
}
