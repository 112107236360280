import React from 'react';

import { useHistory } from 'react-router-dom';
import {
  Button,
  Divider,
  Grid,
  Header,
  Icon,
  PaginationProps,
} from 'semantic-ui-react';

import Section from 'components/layout/Section';
import { PropertyQuery, useProperties } from 'queries/property';

import PropertyTable from './PropertyTable';

type PageChangeEvent = React.MouseEvent<HTMLAnchorElement, MouseEvent>;

export default function Properties() {
  const history = useHistory();
  const [page, setPage] = React.useState(1);
  const { properties, pagination, isLoading } = useProperties({
    page,
    orderBy: 'name',
  });

  function handleAddClick() {
    history.push('/propriedades/novo');
  }

  function handleEditClick(property: PropertyQuery) {
    history.push(`/propriedades/${property.id}/editar`);
  }

  function handlePageChange(
    _: PageChangeEvent,
    { activePage }: PaginationProps,
  ) {
    setPage(activePage as number);
  }

  return (
    <>
      <Grid>
        <Grid.Row columns="equal" verticalAlign="middle">
          <Grid.Column>
            <Header as="h1">Propriedades</Header>
          </Grid.Column>
          {properties?.length ? (
            <Grid.Column verticalAlign="middle" textAlign="right">
              <Button type="button" onClick={handleAddClick} primary>
                <Icon name="add" />
                Nova propriedade
              </Button>
            </Grid.Column>
          ) : null}
        </Grid.Row>
        <Divider hidden />
        <Grid.Row>
          <Grid.Column>
            <Section>
              <Section.Content>
                <PropertyTable
                  properties={properties}
                  pagination={pagination}
                  onPageChange={handlePageChange}
                  onEditClick={handleEditClick}
                  loading={isLoading}
                />
              </Section.Content>
            </Section>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
}
