import React from 'react';

import { Table, Button, Popup, Icon } from 'semantic-ui-react';
import shortid from 'shortid';
import styled from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import LoadingRow from 'components/data/LoadingRow';
import Text from 'components/foundation/Text';
import { formatProjectGoal, formatTestLocation } from 'data/project';
import { formatDivision } from 'queries/division';
import * as Currency from 'util/Currency';

export const Container = styled.div`
  margin-top: 16px;
`;

export default function QuotationProjectAddedTable({
  quotation,
  quotationProjects = [],
  isLoading = false,
  isFetching = false,
  onAddClick,
  onDuplicateClick,
  onEditClick,
  onRemoveClick,
}) {
  const isEmpty = quotationProjects.length === 0;
  const showActions = quotation.isInNegotiation;

  if (isEmpty) {
    return (
      <EmptyState>
        <EmptyState.Header>Nenhum item adicionado</EmptyState.Header>
        <EmptyState.Description>
          Clique no botão abaixo para adicionar um item
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button type="button" onClick={onAddClick} primary basic>
            <Icon name="add" />
            Adicionar
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    );
  }

  return (
    <Container>
      <Table basic="very" size="small" fixed singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="left" width={1}>
              Nº
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Descrição</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Protocolo</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Cultura/Alvo</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Período</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Localidade</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Custo/Trat.</Table.HeaderCell>
            <Table.HeaderCell textAlign="right" width={2}>
              Total
            </Table.HeaderCell>
            {showActions && <Table.HeaderCell textAlign="center" />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isLoading && <LoadingRow columns={10} rows={1} />}
          {!isLoading &&
            quotationProjects.map((project) => {
              const description = project.description || 'Sem descrição';
              const protocol = project?.protocol || 'n/d';
              const crops = project?.cropsNames.join(', ') || 'n/d';
              const targets = project?.targetsNames.join(', ') || 'n/d';

              const amountInput = {
                amount: project.amount,
                currency: quotation.currency,
              };
              const amountFormatted =
                quotation.currency === Currency.Currencies.REAL
                  ? Currency.formatWithSymbol(amountInput)
                  : Currency.formatWithCode(amountInput);

              const totalAmountInput = {
                amount: project.totalAmount,
                currency: quotation.currency,
              };
              const totalAmountFormatted =
                quotation.currency === Currency.Currencies.REAL
                  ? Currency.formatWithSymbol(totalAmountInput)
                  : Currency.formatWithCode(totalAmountInput);

              return (
                <Table.Row key={shortid.generate()}>
                  <Table.Cell textAlign="left" width={1}>
                    {project.number}
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={description}>
                    <Text variant="primary">{description}</Text>
                    <Text variant="secondary">
                      {formatDivision({ division: project.division })}
                    </Text>
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={protocol}>
                    <Text variant="primary">{protocol}</Text>
                    <Text variant="secondary">
                      {formatProjectGoal(project.projectGoal)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={`${crops} / ${targets}`}>
                    <Text variant="primary">{crops}</Text>
                    <Text variant="secondary">{targets}</Text>
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <Text variant="primary">
                      {project.durationForecast
                        ? `${project.durationForecast} ${
                            project.durationForecast === 1 ? 'mês' : 'meses'
                          }`
                        : 'Não definido'}
                    </Text>
                  </Table.Cell>
                  <Table.Cell textAlign="left">
                    <Text variant="primary">
                      {formatTestLocation(project.testLocation)}
                    </Text>
                  </Table.Cell>
                  <Table.Cell textAlign="left" title={amountFormatted}>
                    <Text variant="primary">{amountFormatted}</Text>
                  </Table.Cell>
                  <Table.Cell textAlign="right" title={totalAmountFormatted}>
                    <Text variant="primary">{totalAmountFormatted}</Text>
                    <Text variant="secondary">
                      {project.treatmentQuantity} tratamentos
                    </Text>
                  </Table.Cell>
                  {showActions && (
                    <Table.Cell textAlign="center">
                      <Popup
                        trigger={
                          <Button
                            size="mini"
                            onClick={() => onDuplicateClick(project)}
                            icon
                            circular
                            basic
                          >
                            <Icon name="copy outline" />
                          </Button>
                        }
                        content="Duplicar"
                        mouseEnterDelay={500}
                        inverted
                      />
                      <Popup
                        trigger={
                          <Button
                            size="mini"
                            onClick={() => onEditClick(project)}
                            icon
                            circular
                            basic
                          >
                            <Icon name="edit" />
                          </Button>
                        }
                        content="Editar"
                        mouseEnterDelay={500}
                        inverted
                      />
                      <Popup
                        trigger={
                          <Button
                            size="mini"
                            onClick={() => onRemoveClick(project)}
                            icon
                            circular
                            basic
                          >
                            <Icon name="trash" />
                          </Button>
                        }
                        content="Remover"
                        mouseEnterDelay={500}
                        inverted
                      />
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          {isFetching ? <LoadingRow columns={10} rows={1} /> : null}
        </Table.Body>
      </Table>
    </Container>
  );
}
