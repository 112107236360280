import { AxiosError } from 'axios';
import { useInfiniteQuery, useMutation, UseMutationOptions } from 'react-query';

import api, { emptyPagination, Response } from 'util/api';
import { downloadFile } from 'util/FileManager';

export type WeatherFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  startDate?: string;
  endDate?: string;
};

export type WeatherQuery = {
  id: number;
  date: string;
  temperatureAverage: number;
  temperatureMin: number;
  temperatureMax: number;
  humidityAverage: number;
  humidityMin: number;
  humidityMax: number;
  rain: number;
  weatherStationId: number;
};

type WeathersResult = Response<WeatherQuery>;

export function useInfiniteWeathers(filter: WeatherFilter) {
  const result = useInfiniteQuery(
    ['weathers', filter],
    (queryParams) => {
      const { pageParam: page } = queryParams;
      return getWeathers({ ...filter, page });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.pagination.page >= lastPage.pagination.totalPages) {
          return undefined;
        }
        return lastPage.pagination.page + 1;
      },
    },
  );

  const pagination =
    result?.data?.pages[result?.data?.pages.length - 1]?.pagination;
  const weathers =
    result?.data?.pages
      ?.map((group) => group?.weathers?.map((weather) => weather))
      .flat() || [];

  return {
    ...result,
    pagination: pagination || emptyPagination,
    weathers,
  };
}

async function getWeathers(params: WeatherFilter) {
  const { data } = await api.get<WeathersResult>('/weathers', { params });
  return { weathers: data.data, pagination: data.pagination };
}

export function useExportWeathers(
  options?: UseMutationOptions<void, AxiosError, WeatherFilter, () => void>,
) {
  return useMutation(exportWeathers, {
    ...options,
  });
}

const CONFIG = { responseType: 'blob' } as const;

async function exportWeathers(params: WeatherFilter) {
  const url = `/weathers/export`;
  const { data: file, headers } = await api.get(url, { ...CONFIG, params });
  const { filename } = headers;
  downloadFile(file, filename);
}
