import { customerRepository } from '../container';

export default async function getAllCustomerRepresentatives(
  customerId,
  { page = 1, pageSize = 10, orderBy = 'name', sort = 'asc' },
) {
  const {
    data,
    pagination,
  } = await customerRepository.getAllCustomerRepresentatives(customerId, {
    page,
    pageSize,
    orderBy,
    sort,
  });

  return { data, pagination };
}
