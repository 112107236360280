import React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Icon } from 'semantic-ui-react';

import EmptyState from 'components/data/EmptyState';
import AlertModal from 'components/layout/AlertModal';
import Section from 'components/layout/Section';
import { useAuth } from 'hooks/auth';
import { useModal } from 'hooks/useModal';
import { useDeleteTrails } from 'mutations/treatment';

import CropsModalForm from './CropsModalForm';
import ProjectCropDetails from './ProjectCropDetails';
import ProjectProtocolDetails from './ProjectProtocolDetails';
import RandomizationModalForm from './RandomizationModalForm';
import RandomizationTable from './RandomizationTable';
import TabTreatments from './TabTreatments';

export default function ProjectProtocolPage({
  project,
  installation,
  treatments,
  refreshProject,
}) {
  const { user } = useAuth();
  const history = useHistory();
  const modal = useModal();
  const editCropsModal = useModal();
  const deleteTrialsModal = useModal();

  const deleteTrials = useDeleteTrails({
    onSuccess: () => {
      toast.success('Randomização excluída!');
      deleteTrialsModal.close();
    },
  });

  function handleEditProjectProtocolClick() {
    history.push(`/estudos/${project.id}/protocolo/detalhes/editar`);
  }

  function handleEditCropDetailsClick(crops) {
    editCropsModal.open(crops);
  }

  function handleAddRandomizationClick() {
    modal.open();
  }

  function handleSuccess(message) {
    modal.close();
    toast.success(message);
    refreshProject();
  }

  const projectCropDetails = project.projectCrops?.[0]
    ? projectCropDetailsMapper(project.projectCrops?.[0])
    : null;
  const hasTrials = treatments.map(({ trials }) => trials).flat(1).length !== 0;

  return (
    <>
      <Grid>
        <>
          <Grid.Row columns="equal">
            <Grid.Column>
              <ProjectProtocolDetails
                project={project}
                treatmentsNumber={treatments.length}
                onEditClick={handleEditProjectProtocolClick}
              />
            </Grid.Column>
            <Grid.Column>
              {installation && projectCropDetails ? (
                <ProjectCropDetails
                  plantingDate={projectCropDetails?.plantingDate}
                  seedlingEmergenceDate={
                    projectCropDetails?.seedlingEmergenceDate
                  }
                  plantsNumber={projectCropDetails?.plantsNumber}
                  cropStage={projectCropDetails?.cropStage}
                  bbchScale={projectCropDetails?.bbchScale}
                  plantSpacing={projectCropDetails?.plantSpacing}
                  onEditClick={handleEditCropDetailsClick}
                />
              ) : (
                <Section>
                  <Section.Content>
                    <EmptyState>
                      <EmptyState.Header>
                        Estudo sem cultura definida
                      </EmptyState.Header>
                    </EmptyState>
                  </Section.Content>
                </Section>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <TabTreatments project={project} treatments={treatments} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Header>
                  <Header as="h3" style={{ margin: 0 }}>
                    Randomização
                  </Header>
                  {user.isAdmin && hasTrials ? (
                    <Button
                      basic
                      onClick={() => deleteTrialsModal.open()}
                      size="small"
                    >
                      <Icon name="trash" />
                      Excluir
                    </Button>
                  ) : null}
                </Section.Header>
                <Section.Content>
                  <RandomizationTable
                    project={project}
                    treatments={treatments}
                    onAddClick={handleAddRandomizationClick}
                  />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </>
      </Grid>

      {modal.isOpen ? (
        <RandomizationModalForm
          isOpen={modal.isOpen}
          onClose={modal.close}
          onSuccess={handleSuccess}
          project={project}
          treatments={treatments}
        />
      ) : null}

      {editCropsModal.isOpen ? (
        <CropsModalForm
          projectCropId={project.projectCrops?.[0]?.id}
          projectId={project.id}
          formValues={cropsFormMapper(project.projectCrops?.[0])}
          isOpen={editCropsModal.isOpen}
          onClose={editCropsModal.close}
          onSuccess={handleSuccess}
        />
      ) : null}

      {deleteTrialsModal.isOpen ? (
        <AlertModal
          content="Tem certeza que deseja excluir a randomização?"
          header="Excluir"
          isLoading={deleteTrials.isLoading}
          isOpen={deleteTrialsModal.isOpen}
          negative
          onClose={deleteTrialsModal.close}
          onSubmit={() => deleteTrials.mutate(project.id)}
          submitText="Excluir"
        />
      ) : null}
    </>
  );
}

function cropsFormMapper(projectCrop) {
  return {
    ...projectCrop,
    plantingDate: projectCrop.plantingDate
      ? DateTime.fromISO(projectCrop.plantingDate).toJSDate()
      : null,
    seedlingEmergenceDate: projectCrop.seedlingEmergenceDate
      ? DateTime.fromISO(projectCrop.seedlingEmergenceDate).toJSDate()
      : null,
    plantsNumberUnit: projectCrop.plantsNumberUnit || null,
    plantSpacingUnit: projectCrop.plantSpacingUnit || null,
  };
}

function projectCropDetailsMapper(projectCrop) {
  const formattedPlantingDate = projectCrop?.plantingDate
    ? DateTime.fromISO(projectCrop.plantingDate).toFormat('dd/MM/yyyy')
    : '';
  const seedlingEmergenceDate = projectCrop?.seedlingEmergenceDate
    ? DateTime.fromISO(projectCrop.seedlingEmergenceDate).toFormat('dd/MM/yyyy')
    : 'n/a';
  const plantsNumber =
    projectCrop.plantsNumber && projectCrop.plantsNumberUnit
      ? `${projectCrop.plantsNumber.toLocaleString('pt-BR')} ${
          projectCrop.plantsNumberUnit.abbreviation
        }`
      : 'n/d';

  const plantingDate = formattedPlantingDate || 'n/d';

  const plantSpacingInitials = projectCrop.plantSpacingUnit
    ? projectCrop.plantSpacingUnit.abbreviation
    : 'n/a';
  const plantSpacing =
    projectCrop.plantSpacingWidth && projectCrop.plantSpacingLength
      ? `${projectCrop.plantSpacingWidth} ${plantSpacingInitials} x ${projectCrop.plantSpacingLength} ${plantSpacingInitials}`
      : 'n/a';

  return {
    plantingDate,
    seedlingEmergenceDate,
    plantsNumber,
    cropStage: projectCrop.cropStage,
    bbchScale: projectCrop.bbchScale,
    plantSpacing,
  };
}
