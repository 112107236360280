import React from 'react';

import toast from 'react-hot-toast';
import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { Grid, Header, Divider, Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import EmptyState from 'components/data/EmptyState';
import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import RepresentativeFormModal from 'components/modal/RepresentativeFormModal';
import { customerRepository } from 'container';
import { useModalWithData } from 'hooks/useModal';
import IconItem from 'pages/pesquisa/estudos/[projectId]/IconItem';
import { useCustomer, useRepresentative } from 'queries/customer';
import { formatCpfOrCnpj, formatPhone } from 'util/mask';

import CustomerAddressModal from './CustomerAddressModal';
import EditCustomerModal from './EditCustomerModal';
import RepresentativesTable from './RepresentativesTable';

export default function CustomerPage() {
  const params = useParams<{ customerId: string }>();
  const customerId = parseInt(params.customerId, 10);

  const customerModal = useModalWithData();
  const customerAddressModal = useModalWithData();
  const representativeModal = useModalWithData();

  const queryClient = useQueryClient();
  const { data: customer, isLoading, isFetching, error } = useCustomer(
    customerId,
  );
  const {
    data: representative,
    isLoading: isLoadingRepresentative,
  } = useRepresentative(
    { customerId, representativeId: representativeModal?.data?.id },
    { enabled: customerId && representativeModal?.data?.id !== undefined },
  );

  const onSuccess = (message) => {
    queryClient.invalidateQueries('customers');
    queryClient.invalidateQueries('customer');
    toast.success(message);
    representativeModal.close();
  };

  const createRepresentativeMutation = useMutation(
    customerRepository.createRepresentative,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customer');
        queryClient.invalidateQueries('representative');
        onSuccess('Representante adicionado com sucesso!');
      },
    },
  );
  const updateRepresentativeMutation = useMutation(
    customerRepository.updateRepresentative,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('customers');
        queryClient.invalidateQueries('customer');
        queryClient.invalidateQueries('representative');
        onSuccess('Representante atualizado com sucesso!');
      },
    },
  );

  function handleAddRepresentativeClick() {
    representativeModal.open();
  }

  function handleRepresentativeSubmit(values: any) {
    if (!representativeModal.data) {
      createRepresentativeMutation.mutate({
        ...values,
        customerId,
      });
    } else {
      updateRepresentativeMutation.mutate({
        ...values,
        customerId,
        representativeId: representativeModal?.data?.id,
      });
    }
  }

  function handleEditCustomerSuccess() {
    queryClient.invalidateQueries('customerDetailed');
    toast.success('Cliente atualizado com sucesso!');
    customerModal.close();
  }

  function handleEditCustomerClick() {
    customerModal.open(customer);
  }

  function handleEditCustomerAddressClick() {
    customerAddressModal.open(customer?.address);
  }

  function handleEditRepresentativeClick(selectedRepresentative: any) {
    representativeModal.open(selectedRepresentative);
  }

  function handleClose() {
    customerModal.close();
  }

  function handleRepresentativeModalClose() {
    representativeModal.close();
  }

  const isEmpty = customer?.representatives?.length === 0;

  const iconItems = [
    {
      icon: 'registered',
      label: customer?.cnpj ? formatCpfOrCnpj(customer?.cnpj) : 'Sem CNPJ/CPF',
    },
    {
      icon: 'clipboard list',
      label: customer?.stateRegistration || 'Sem inscrição',
    },
    {
      icon: 'phone',
      label: customer?.phone ? formatPhone(customer?.phone, 1) : 'Sem telefone',
    },
  ];

  if (!customer) {
    return (
      <Base loading={isLoading}>
        <Container />
      </Base>
    );
  }

  const hasAddress = Object.values(customer.address).some((prop) => !!prop);

  return (
    <>
      {customerModal.isOpen ? (
        <EditCustomerModal
          customer={customerModal.data || {}}
          onClose={handleClose}
          onSuccess={handleEditCustomerSuccess}
          open={customerModal.isOpen}
        />
      ) : null}

      <Base loading={isLoading}>
        <Container>
          <Grid>
            <Grid.Row columns="equal" verticalAlign="middle">
              <Grid.Column>
                <Header as="h1" floated="left" style={{ marginBottom: 0 }}>
                  {customer?.tradingName}
                </Header>
                <Tag color="grey">{customer?.insideCode}</Tag>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Button type="button" onClick={handleEditCustomerClick} basic>
                  <Icon name="edit" />
                  Editar
                </Button>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
                <Text variant="secondary">{customer?.companyName}</Text>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Icons>
                  {iconItems.map(({ icon, label, onClick }) => (
                    <IconItem
                      key={label}
                      icon={icon}
                      label={label}
                      onClick={onClick}
                    />
                  ))}
                </Icons>
              </Grid.Column>
            </Grid.Row>
            <Divider hidden />
            <Grid.Row columns="equal">
              <Grid.Column>
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Representantes
                    </Header>
                    <Button
                      floated="right"
                      onClick={handleAddRepresentativeClick}
                      primary
                      basic
                    >
                      <Icon name="add" />
                      Novo representante
                    </Button>
                  </Section.Header>
                  <Section.Content>
                    {!isEmpty ? (
                      <RepresentativesTable
                        representatives={customer?.representatives}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        onEditClick={handleEditRepresentativeClick}
                      />
                    ) : (
                      <EmptyState>
                        <EmptyState.Header>
                          Nenhum representante adicionado
                        </EmptyState.Header>
                        <EmptyState.Description>
                          {`Clique no botão "Novo representante" para
                        adicionar um representante`}
                        </EmptyState.Description>
                      </EmptyState>
                    )}
                  </Section.Content>
                </Section>
              </Grid.Column>
              <Grid.Column>
                <Section>
                  <Section.Header>
                    <Header as="h3" style={{ margin: 0 }}>
                      Endereço
                    </Header>
                    <Button
                      type="button"
                      onClick={handleEditCustomerAddressClick}
                      basic
                    >
                      <Icon name="edit" />
                      Editar
                    </Button>
                  </Section.Header>
                  <Section.Content>
                    {hasAddress ? (
                      <>
                        {customer.address?.street ? (
                          <Text variant="primary">
                            {formatAddress(customer?.address)}
                          </Text>
                        ) : null}
                        {customer.address?.neighborhood ? (
                          <Text variant="primary">
                            {customer.address?.neighborhood}
                          </Text>
                        ) : null}
                        {customer.address?.zipcode ? (
                          <Text variant="primary">
                            CEP: {customer.address?.zipcode}
                          </Text>
                        ) : null}
                        {customer.address?.city ? (
                          <Text variant="primary">
                            {formatLocation(customer?.address)}
                          </Text>
                        ) : null}
                        {customer.address?.country ? (
                          <Text variant="primary">
                            {customer?.address.country}
                          </Text>
                        ) : null}
                        {customer.address?.complementary ? (
                          <Text variant="primary">
                            {customer?.address?.complementary}
                          </Text>
                        ) : null}
                      </>
                    ) : (
                      <EmptyState>
                        <EmptyState.Header>Sem endereço</EmptyState.Header>
                        <EmptyState.Description>
                          Clique em &quot;Editar&quot; para atualizar o endereço
                        </EmptyState.Description>
                      </EmptyState>
                    )}
                  </Section.Content>
                </Section>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>

        <RepresentativeFormModal
          formValues={representative}
          onClose={handleRepresentativeModalClose}
          onSubmit={handleRepresentativeSubmit}
          isOpen={representativeModal.isOpen}
          error={createRepresentativeMutation.error}
          isSubmiting={
            createRepresentativeMutation.isLoading ||
            updateRepresentativeMutation.isLoading
          }
          isLoading={isLoadingRepresentative}
        />

        {customerAddressModal.isOpen ? (
          <CustomerAddressModal
            formValues={customerAddressModal?.data}
            isOpen={customerAddressModal.isOpen}
            onClose={customerAddressModal.close}
            customerId={customerId}
          />
        ) : null}
      </Base>
    </>
  );
}

function formatAddress({ street, streetNumber }: any) {
  return `${street}, ${streetNumber || 's/n'}`;
}

function formatLocation({ city, state }: any) {
  return `${city}-${state}`;
}

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin-bottom: 3.2rem;
`;
