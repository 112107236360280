import * as React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import * as yup from 'yup';

import InputError from 'components/inputs/InputError';
import MeasurementUnitSelect from 'components/inputs/MeasurementUnitSelectV2';
import { useCreateSaleProduct, useUpdateSaleProduct } from 'data/sale-product';

const SaleProductFormInput = yup.object().shape({
  description: yup
    .string()
    .required('Obrigatório')
    .max(255, 'Máximo de 255 caracteres'),
  ncm: yup.number().required('Obrigatório'),
  measurementUnit: yup
    .object({
      id: yup.number().required(),
      abbreviation: yup.string().required(),
    })
    .required('Obrigatório')
    .typeError('Obrigatório'),
  unitPrice: yup.number().required('Obrigatório'),
});

export type SaleProductFormValues = yup.InferType<typeof SaleProductFormInput>;

export type SaleProductModalProps = {
  formValues?: SaleProductFormValues;
  isOpen: boolean;
  onClose: () => void;
  saleProductId?: number;
};

const CENTS_MULTIPLIER = 100;

const defaultNCM = { id: 1, code: '05119190' } as const;
const initialValues = { ncm: defaultNCM.code } as const;
const formId = 'form_sale_product' as const;

const MESSAGE = {
  successCreate: 'Praga cadastrada!',
  successUpdate: 'Praga salva!',
} as const;

export default function SaleProductModal({
  formValues,
  isOpen,
  onClose,
  saleProductId,
}: SaleProductModalProps) {
  const defaultValues = formValues ? { ...formValues } : initialValues;
  const resolver = yupResolver(SaleProductFormInput);
  const formOptions = {
    defaultValues,
    mode: 'onBlur',
    resolver,
  } as const;
  const { control, errors, handleSubmit } = useForm(formOptions);

  const createSaleProduct = useCreateSaleProduct({
    onSuccess: () => handleSuccess(MESSAGE.successCreate),
  });

  const updateSaleProduct = useUpdateSaleProduct({
    onSuccess: () => handleSuccess(MESSAGE.successUpdate),
  });

  function handleSuccess(message: string) {
    toast.success(message);
    onClose();
  }

  function onSubmit(values: SaleProductFormValues) {
    const input = {
      description: values.description,
      ncmId: defaultNCM.id,
      measurementUnitId: values.measurementUnit.id,
      unitPrice: values.unitPrice * CENTS_MULTIPLIER,
    };

    if (saleProductId) {
      updateSaleProduct.mutate({ ...input, saleProductId });
      return;
    }

    createSaleProduct.mutate(input);
  }

  const isLoading = createSaleProduct.isLoading || updateSaleProduct.isLoading;
  const header = saleProductId ? 'Edição de praga' : 'Nova praga';
  const buttonText = saleProductId ? 'Salvar' : 'Cadastrar';

  return (
    <Modal size="tiny" open={isOpen} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <Form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <label>Descrição</label>
            <Controller
              control={control}
              name="description"
              render={({ onChange, onBlur, value }) => (
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  disabled={isLoading}
                  maxLength={255}
                  autoComplete="off"
                  fluid
                />
              )}
            />
            {errors.description && (
              <InputError>{errors?.description?.message}</InputError>
            )}
          </Form.Field>
          <Form.Group widths="equal">
            <Form.Field>
              <label>NCM</label>
              <Controller
                control={control}
                name="ncm"
                render={({ onChange, onBlur, value }) => (
                  <Input
                    disabled
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    maxLength={20}
                    autoComplete="off"
                    fluid
                  />
                )}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Unidade</label>
              <Controller
                control={control}
                name="measurementUnit"
                defaultValue=""
                render={({ onChange, onBlur, value }) => (
                  <MeasurementUnitSelect
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isLoading}
                    autoComplete="off"
                    isClearable
                  />
                )}
              />
              {errors?.measurementUnit ? (
                <InputError>{errors?.measurementUnit?.message}</InputError>
              ) : null}
            </Form.Field>
            <Form.Field>
              <label>Preço unitário</label>
              <Controller
                control={control}
                name="unitPrice"
                render={({ onChange, onBlur, value }) => (
                  <NumberFormat
                    decimalScale={2}
                    decimalSeparator=","
                    disabled={isLoading}
                    onBlur={onBlur}
                    onValueChange={(data) => {
                      onChange(data.floatValue);
                    }}
                    thousandSeparator="."
                    value={value}
                  />
                )}
              />
              {errors.unitPrice && (
                <InputError>{errors?.unitPrice?.message}</InputError>
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button type="button" onClick={onClose} basic>
          Cancelar
        </Button>
        <Button
          form={formId}
          type="submit"
          loading={isLoading}
          disabled={isLoading}
          primary
        >
          {buttonText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
