import React from 'react';

import Select from 'react-select';

export const ProjectStage = {
  Enabling: 1,
  Preparatory: 2,
  Deployment: 3,
  Evaluation: 4,
  Harvest: 5,
  ReportWriting: 6,
  CustomerReview: 7,
  Complete: 8,
  Canceled: 9,
};

const inProgressStages = [
  { value: ProjectStage.Preparatory, label: 'Preparatória' },
  { value: ProjectStage.Deployment, label: 'Implantação' },
  { value: ProjectStage.Evaluation, label: 'Avaliação' },
  { value: ProjectStage.Harvest, label: 'Análise de dados' },
  { value: ProjectStage.ReportWriting, label: 'Redação de Laudo' },
  { value: ProjectStage.CustomerReview, label: 'Revisão do cliente' },
];

export const inProgressStagesValues = inProgressStages.map(
  ({ value }) => value,
);

const allStages = [
  { value: ProjectStage.Enabling, label: 'Habilitação' },
  { value: ProjectStage.Preparatory, label: 'Preparatória' },
  { value: ProjectStage.Deployment, label: 'Implantação' },
  { value: ProjectStage.Evaluation, label: 'Avaliação' },
  { value: ProjectStage.Harvest, label: 'Análise de dados' },
  { value: ProjectStage.ReportWriting, label: 'Redação de Laudo' },
  { value: ProjectStage.CustomerReview, label: 'Revisão do cliente' },
  { value: ProjectStage.Complete, label: 'Concluído' },
  { value: ProjectStage.Canceled, label: 'Cancelado' },
];

export const allStagesValues = allStages.map(({ value }) => value);

export default function ProjectStageSelect({
  name,
  placeholder = 'Selecione...',
  styles = undefined,
  value,
  onChange,
  onBlur,
  disabled = false,
  isClearable = false,
  isMulti = false,
  filters = [],
}) {
  const options = filters.length
    ? allStages.filter((stage) => filters.includes(stage.value))
    : allStages;

  const defaultValue = !isMulti ? undefined : [];

  return (
    <Select
      name={name}
      placeholder={placeholder}
      instanceId={name}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      isDisabled={disabled}
      isClearable={isClearable}
      isMulti={isMulti}
      styles={styles}
    />
  );
}
