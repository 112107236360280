import React from 'react';

import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Divider, Grid, Header } from 'semantic-ui-react';

import TargetForm from 'components/forms/TargetForm';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Section from 'components/layout/Section';
import { useCreateTarget } from 'hooks/target';

const successMessage = 'Alvo biológico adicionado com sucesso!';

export default function AddTarget() {
  const history = useHistory();

  const { mutate: createTarget, isLoading } = useCreateTarget({
    onSuccess,
  });

  function onSuccess() {
    toast.success(successMessage);
    history.replace('/configuracoes/alvos');
  }

  function handleSubmit(values) {
    createTarget(values);
  }

  return (
    <Base text>
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1">Novo alvo</Header>
            </Grid.Column>
          </Grid.Row>
          <Divider hidden />
          <Grid.Row>
            <Grid.Column>
              <Section>
                <Section.Content>
                  <TargetForm isSubmiting={isLoading} onSubmit={handleSubmit} />
                </Section.Content>
              </Section>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Base>
  );
}
