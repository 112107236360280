import * as React from 'react';

import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Dropdown, Grid, Header, Icon, Tab } from 'semantic-ui-react';
import styled from 'styled-components';

import Tag from 'components/data/Tag';
import Text from 'components/foundation/Text';
import AlertDialog from 'components/layout/AlertDialog';
import AlertModal from 'components/layout/AlertModal';
import Base from 'components/layout/Base';
import Container from 'components/layout/Container';
import Inline from 'components/layout/Inline';
import ChangeProjectStageModal from 'components/modal/ChangeProjectStageModal';
import {
  isInProgress,
  canBeUpdatedByUsers,
  formatProjectGoal,
  formatManagementType,
  formatTestLocation,
  PROJECT_STAGE,
} from 'data/project';
import { useAuth } from 'hooks/auth';
import { useModal, useModalWithData } from 'hooks/useModal';
import {
  useCancelAndDuplicateProject,
  useExportShortResearchProject,
  useExportResearchProject,
  useExportApplicationForm,
  useDeployProject,
} from 'mutations/project';
import { formatDivision } from 'queries/division';
import { useProject } from 'queries/project';
import * as Currency from 'util/Currency';

import ProjectSamplesPage from './amostras';
import ProjectApplicationsPage from './aplicacoes';
import ProjectActivitiesPage from './atividades';
import ProjectEvaluationsPage from './avaliacoes';
import CancelProjectModal from './CancelProjectModal';
import ChangeResponsibleModal from './ChangeResponsibleModal';
import ProjectCollectionsPage from './coletas';
import ProjectWeathersPage from './dados-meteorologicos';
import ProjectHistoryPage from './historico';
import IconItem from './IconItem';
import ProjectMaintenancesPage from './manutencoes';
import ProjectInstallmentsPage from './parcelas';
import ProjectFinishModal from './ProjectFinishModal';
import ProjectReassignmentModal from './ProjectReassignmentModal';
import ProjectStageProgress from './ProjectStageProgress';
import ProjectProtocolPage from './protocolo';
import SendToCustomerReviewModal from './SendToCustomerReviewModal';
import UpdateProjectStageButton from './UpdateProjectStageButton';
import UpdateStageModal from './UpdateStageModal';
import { useColectionDivisionTabs } from './use-collection-division-tabs';
import { useProjectTabs } from './use-project-tabs';
import ProjectOverviewPage from './visao-geral';

const activeStageMap = {
  [PROJECT_STAGE.preparatory]: 0,
  [PROJECT_STAGE.deployment]: 1,
  [PROJECT_STAGE.evaluation]: 2,
  [PROJECT_STAGE.harvest]: 3,
  [PROJECT_STAGE.reportWriting]: 4,
  [PROJECT_STAGE.customerReview]: 5,
  [PROJECT_STAGE.completed]: 6,
};

const MESSAGES = {
  applicationForm: {
    loading: 'Exportando...',
    success: 'Formulário de aplicação exportado!',
    error: 'Não foi possível exportar. Tente novamente.',
  },
  fieldResearchProject: {
    loading: 'Exportando...',
    success: 'Projeto de pesquisa para campo exportado!',
    error: 'Não foi possível exportar. Tente novamente.',
  },
  researchProject: {
    loading: 'Exportando...',
    success: 'Projeto de pesquisa exportado!',
    error: 'Não foi possível exportar. Tente novamente.',
  },
};

export default function ProjectPage() {
  const history = useHistory();
  const { id } = useParams();
  const projectId = parseInt(id, 10);

  const { user } = useAuth();
  const { toggle, activeIndex } = useProjectTabs(`/estudos/${projectId}`);
  const collectionDisivionTabs = useColectionDivisionTabs(
    `/estudos/${projectId}`,
  );

  const [updateStageModal, setUpdateStageModal] = React.useState(false);
  const [cancelProjectModal, setCancelProjectModal] = React.useState(false);
  const cancelAndDuplicateModal = useModalWithData();
  const changeStageModal = useModalWithData();
  const projectDeploymentModal = useModal();
  const sendToCustomerReviewModal = useModal();
  const projectFinishModal = useModal();

  const changeResponsibleModal = useModalWithData();
  const projectReassignmentModal = useModal();

  const {
    data: completeProject,
    isLoading: loadingCompleteProject,
    refetch: refetchProject,
  } = useProject(projectId);

  const exportApplicationForm = useExportApplicationForm();
  const exportResearchProject = useExportResearchProject();
  const exportShortResearchProject = useExportShortResearchProject();

  const cancelAndDuplicateProject = useCancelAndDuplicateProject({
    onSuccess: (duplicatedProjectId) => {
      toast.success('Estudo cancelado e duplicado!');
      history.push(`/estudos/${duplicatedProjectId}`);
    },
  });

  const deployProject = useDeployProject({
    onSuccess: () => {
      toast.success('Estudo implantado!');
      projectDeploymentModal.close();
    },
  });

  function handleChangeResponsibleClick() {
    changeResponsibleModal.open(
      completeProject?.project?.responsibleTechnicianId,
    );
  }

  function handleContractClick() {
    history.push(`/contratos/${completeProject?.contract?.id}`);
  }

  function handleQuotationClick() {
    history.push(`/orcamentos/${completeProject?.contract?.quotation?.id}`);
  }

  function handleReassignProjectClick() {
    projectReassignmentModal.open();
  }

  function handleEditClick() {
    history.push(`/estudos/${completeProject?.project?.id}/editar`);
  }

  function handleCancelClick() {
    setCancelProjectModal(true);
  }

  function handleCancelAndDuplicateClick() {
    cancelAndDuplicateModal.open(completeProject?.project?.id);
  }

  function handleChangeStageClick() {
    changeStageModal.open(completeProject?.project?.id);
  }

  async function handleExportApplicationFormClick() {
    const promise = exportApplicationForm.mutateAsync(
      completeProject?.project?.id,
    );
    toast.promise(promise, MESSAGES.applicationForm);
  }

  async function handleExportResearchProjectClick() {
    const promise = exportResearchProject.mutateAsync(
      completeProject?.project?.id,
    );
    toast.promise(promise, MESSAGES.researchProject);
  }

  async function handleExportShortResearchProjectClick() {
    const promise = exportShortResearchProject.mutateAsync(
      completeProject?.project?.id,
    );
    toast.promise(promise, MESSAGES.fieldResearchProject);
  }

  function refreshProject() {
    refetchProject();
  }

  const handleSuccess = React.useCallback(() => {
    toast.success('Operação realizada com sucesso');
    setCancelProjectModal(false);
    refetchProject(projectId);
  }, [refetchProject, projectId]);

  const handleFailure = React.useCallback(() => {
    toast.success('Ops! Alguma coisa não funcionou direito');
    setCancelProjectModal(false);
  }, []);

  function handleUpdateStageClick() {
    setUpdateStageModal(true);
  }

  function handleUpdateStageClose() {
    setUpdateStageModal(false);
  }

  const showMessage = React.useCallback((message) => {
    toast.success(message);
  }, []);

  function handleProjectUpdateSuccess(message) {
    handleUpdateStageClose();
    refetchProject(projectId);
    showMessage(message);
  }

  function handleProjectUpdateFailure(message) {
    handleUpdateStageClose();
    showMessage(message);
  }

  const exportOptions = [
    {
      key: 'export-application-form',
      text: 'Formulário de aplicação',
      icon: 'download',
      onClick: handleExportApplicationFormClick,
    },
    {
      key: 'export-research-project',
      text: 'Projeto de pesquisa',
      icon: 'download',
      onClick: handleExportResearchProjectClick,
    },
    {
      key: 'export-short-research-project',
      text: 'Projeto de pesquisa resumido',
      icon: 'download',
      onClick: handleExportShortResearchProjectClick,
    },
  ];

  const options = [
    {
      key: 'change-responsible',
      text: 'Alterar responsável',
      value: 'Change responsible',
      icon: 'user',
      onClick: handleChangeResponsibleClick,
    },
    {
      key: 'reassign-date',
      text: 'Remanejar data requerida',
      value: 'Reassign date',
      icon: 'calendar outline',
      onClick: handleReassignProjectClick,
    },
    {
      key: 'edit',
      text: 'Editar',
      value: 'Edit project',
      icon: 'edit',
      onClick: handleEditClick,
    },
    {
      key: 'change-stage',
      text: 'Alterar fase do estudo',
      value: 'change-stage',
      icon: 'edit',
      onClick: handleChangeStageClick,
    },
    {
      key: 'cancel',
      text: 'Cancelar estudo',
      value: 'Cancel project',
      icon: 'delete',
      onClick: handleCancelClick,
    },
    {
      key: 'cancel-and-duplicate',
      text: 'Cancelar e duplicar estudo',
      value: 'Cancel and duplicate project',
      icon: 'delete',
      onClick: handleCancelAndDuplicateClick,
    },
  ];

  if (!completeProject) {
    return <Base loading={loadingCompleteProject} />;
  }

  const {
    contract,
    documents,
    history: projectHistory,
    installation,
    project,
    treatments,
  } = completeProject;

  const isExporting =
    exportShortResearchProject.isLoading ||
    exportResearchProject.isLoading ||
    exportApplicationForm.isLoading;

  const showUpdateButtonForAdmin =
    isInProgress(project) &&
    project.projectStage !== PROJECT_STAGE.preparatory &&
    project.projectStage !== PROJECT_STAGE.customerReview &&
    project.projectStage !== PROJECT_STAGE.reportWriting;
  const showUpdateButtonForUsers = canBeUpdatedByUsers(project);
  const showUpdateButton = user.isAdmin
    ? showUpdateButtonForAdmin
    : showUpdateButtonForUsers;
  const showFinishButton =
    user.isAdmin && project.projectStage === PROJECT_STAGE.customerReview;
  const showDeployProjectButton =
    project.projectStage === PROJECT_STAGE.preparatory;
  const showSendReportToCustomerReviewButton =
    project.projectStage === PROJECT_STAGE.reportWriting;

  const division = formatDivision({
    division: project.division,
  });
  const responsible = project.responsible
    ? project.responsible.firstName
    : 'n/d';
  const projectGoal = formatProjectGoal(project.projectGoal);
  const managementType = formatManagementType(project.managementType);
  const testLocation = formatTestLocation(project.testLocation);

  const commonIconItems = [
    {
      icon: 'calendar alternate',
      label: project.harvest.toString(),
    },
    { icon: 'users', label: project.customer.tradingName },
    { icon: 'user', label: project.representative.name },
    {
      icon: 'pencil alternate',
      label: contract.quotation?.code || 'Sem orçamento',
      onClick: contract.quotation?.id ? handleQuotationClick : undefined,
    },
    {
      icon: 'handshake',
      label: contract?.number.toString(),
      onClick: contract && user.isAdmin ? handleContractClick : undefined,
    },
    {
      icon: 'clipboard check',
      label: project.protocol || 'Sem protocolo',
    },
  ];
  const adminIconItems = [
    ...commonIconItems,
    {
      icon: 'dollar',
      label: Currency.formatWithSymbol({
        amount: project?.totalValue,
        currency: Currency.Currencies.REAL,
      }),
    },
    { icon: 'clipboard list', label: contract?.workOrder || 'Sem OS' },
  ];
  const iconItems = user.isAdmin ? adminIconItems : commonIconItems;

  const tags = [
    projectGoal,
    division,
    responsible,
    managementType,
    testLocation,
  ];

  const stageItems = [
    { description: 'Preparatória', done: project.projectStage >= 2 },
    { description: 'Implantação', done: project.projectStage >= 3 },
    { description: 'Avaliação', done: project.projectStage >= 4 },
    { description: 'Análise de dados', done: project.projectStage >= 5 },
    { description: 'Redação de laudo', done: project.projectStage >= 6 },
    {
      description: 'Revisão do cliente',
      done: project.projectStage === 7 || project.projectStage === 8,
    },
    { description: 'Concluído', done: project.projectStage === 8 },
  ];

  return (
    <>
      <Base loading={loadingCompleteProject}>
        <Container>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column>
                <Header as="h1">{project.insideCode}</Header>
                {project.reinstalled ? (
                  <Tag color="yellow">Reinstalado</Tag>
                ) : null}
                {project.projectStage === 9 ? (
                  <Tag color="red">Cancelado</Tag>
                ) : null}
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Dropdown
                  basic
                  button
                  direction="left"
                  floating
                  disabled={isExporting}
                  text="Exportar"
                >
                  <Dropdown.Menu>
                    <Dropdown.Menu scrolling>
                      {exportOptions.map((option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <Dropdown.Item {...option} />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown.Menu>
                </Dropdown>
                {user.isAdmin ? (
                  <Dropdown
                    basic
                    button
                    direction="left"
                    floating
                    text="Opções"
                  >
                    <Dropdown.Menu>
                      <Dropdown.Menu scrolling>
                        {options.map((option) => (
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          <Dropdown.Item {...option} />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                {showUpdateButton && (
                  <UpdateProjectStageButton
                    stage={project.projectStage}
                    onClick={handleUpdateStageClick}
                  />
                )}
                {showDeployProjectButton && (
                  <Button primary onClick={() => projectDeploymentModal.open()}>
                    <Icon name="check" />
                    Implantar estudo
                  </Button>
                )}
                {showSendReportToCustomerReviewButton && (
                  <Button
                    primary
                    onClick={() => sendToCustomerReviewModal.open()}
                  >
                    <Icon name="check" />
                    Enviar para revisão do cliente
                  </Button>
                )}
                {showFinishButton ? (
                  <Button onClick={() => projectFinishModal.open()} primary>
                    <Icon name="check" />
                    Concluir estudo
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Icons>
                  {iconItems.map(({ icon, label, onClick }) => (
                    <IconItem
                      key={label}
                      icon={icon}
                      label={label}
                      onClick={onClick}
                    />
                  ))}
                </Icons>
                <Tags>
                  {tags.map((tag) => (
                    <React.Fragment key={tag}>
                      <Tag color="grey">{tag}</Tag>
                      <Inline size="s" />
                    </React.Fragment>
                  ))}
                </Tags>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                {project.notes ? (
                  <Text variant="secondary">Observações: {project.notes}</Text>
                ) : null}
              </Grid.Column>
            </Grid.Row>
            <div style={{ marginBottom: 64 }} />
            <Grid.Row>
              <Grid.Column>
                <ProjectStageProgress
                  activeIndex={activeStageMap[project.projectStage]}
                  items={stageItems}
                />
              </Grid.Column>
            </Grid.Row>
            <div style={{ marginBottom: 64 }} />
          </Grid>
        </Container>

        <Container>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                {project.division !== 3 ? (
                  <Tab
                    renderActiveOnly
                    menu={{ color: 'blue', secondary: true, pointing: true }}
                    activeIndex={activeIndex}
                    onTabChange={(_, data) => {
                      toggle(data.activeIndex);
                    }}
                    panes={getDefaultPanes({
                      contract,
                      documents,
                      handleFailure,
                      handleSuccess,
                      installation,
                      project,
                      projectHistory,
                      projectId,
                      refreshProject,
                      treatments,
                    })}
                  />
                ) : (
                  <Tab
                    renderActiveOnly
                    menu={{ color: 'blue', secondary: true, pointing: true }}
                    activeIndex={collectionDisivionTabs.activeIndex}
                    onTabChange={(_, data) => {
                      collectionDisivionTabs.toggle(data.activeIndex);
                    }}
                    panes={getCollectionPanes({
                      contract,
                      documents,
                      handleFailure,
                      handleSuccess,
                      installation,
                      project,
                      projectHistory,
                      refreshProject,
                      treatments,
                    })}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </Base>

      <CancelProjectModal
        open={cancelProjectModal}
        projectId={project.id}
        onClose={() => setCancelProjectModal(false)}
        onSuccess={handleSuccess}
        onFailure={handleFailure}
      />
      <AlertDialog
        open={cancelAndDuplicateModal.isOpen}
        onClose={cancelAndDuplicateModal.close}
        content="Cancelar e duplicar estudo como reinstalado?"
        buttonText="Cancelar e duplicar"
        onClick={() =>
          cancelAndDuplicateProject.mutate(cancelAndDuplicateModal.data)
        }
        isLoading={cancelAndDuplicateProject.isLoading}
        negative
      />
      {changeStageModal.isOpen ? (
        <ChangeProjectStageModal
          isOpen={changeStageModal.isOpen}
          onClose={changeStageModal.close}
          projectId={project.id}
        />
      ) : null}
      <UpdateStageModal
        open={updateStageModal}
        projectId={project.id}
        currentStage={project.projectStage}
        onClose={handleUpdateStageClose}
        onSuccess={() =>
          handleProjectUpdateSuccess('Fase concluída com sucesso!')
        }
        onFailure={() =>
          handleProjectUpdateFailure(
            'Não foi possível concluir a fase. Tente novamente.',
          )
        }
      />
      {changeResponsibleModal.isOpen ? (
        <ChangeResponsibleModal
          isOpen={changeResponsibleModal.isOpen}
          onClose={changeResponsibleModal.close}
          onSuccess={(message) => {
            changeResponsibleModal.close();
            toast.success(message);
          }}
          projectId={project.id}
        />
      ) : null}
      {projectReassignmentModal.isOpen ? (
        <ProjectReassignmentModal
          isOpen={projectReassignmentModal.isOpen}
          onClose={projectReassignmentModal.close}
          onSuccess={() => {
            projectReassignmentModal.close();
            toast.success('Estudo remanejado!');
          }}
          projectId={project.id}
          reassignedDate={
            project?.reassignedDate
              ? DateTime.fromISO(project?.reassignedDate).toJSDate()
              : null
          }
        />
      ) : null}

      {projectDeploymentModal.isOpen ? (
        <AlertModal
          content="Tem certeza que deseja implantar o estudo?"
          header="Implantação de estudo"
          isLoading={deployProject.isLoading}
          isOpen={projectDeploymentModal.isOpen}
          onClose={projectDeploymentModal.close}
          onSubmit={() => deployProject.mutate(projectId)}
          submitText="Implantar estudo"
        />
      ) : null}

      {sendToCustomerReviewModal.isOpen ? (
        <SendToCustomerReviewModal
          isOpen={sendToCustomerReviewModal.isOpen}
          onClose={sendToCustomerReviewModal.close}
          projectId={project.id}
          reportReviewId={project.reportReviewDocumentId}
        />
      ) : null}

      {projectFinishModal.isOpen ? (
        <ProjectFinishModal
          isOpen={projectFinishModal.isOpen}
          onClose={projectFinishModal.close}
          projectId={project.id}
        />
      ) : null}
    </>
  );
}

const paneStyle = { paddingTop: '20px' };

function getDefaultPanes({
  project,
  documents,
  treatments,
  installation,
  contract,
  handleFailure,
  handleSuccess,
  projectHistory,
  projectId,
  refreshProject,
}) {
  return [
    {
      menuItem: 'Visão geral',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectOverviewPage
            project={project}
            treatments={treatments}
            installation={installation}
            documents={documents}
            refreshProject={refreshProject}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Atividades',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectActivitiesPage
            project={project}
            refreshProject={refreshProject}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Protocolo',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectProtocolPage
            project={project}
            treatments={treatments}
            installation={installation}
            refreshProject={refreshProject}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Manutenções',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectMaintenancesPage
            projectId={project.id}
            installation={installation}
            documents={project?.projectDocuments}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Aplicações',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectApplicationsPage
            projectId={project.id}
            installation={installation}
            documents={project?.projectDocuments}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Avaliações',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectEvaluationsPage projectId={project.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Amostras',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectSamplesPage projectId={projectId} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Dados meteorológicos',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectWeathersPage activities={project.activities} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Parcelas',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectInstallmentsPage
            project={project}
            contract={contract}
            refreshProject={refreshProject}
            onUpdateSuccess={handleSuccess}
            onUpdateError={handleFailure}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Histórico',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectHistoryPage history={projectHistory} />
        </Tab.Pane>
      ),
    },
  ];
}

function getCollectionPanes({
  contract,
  documents,
  handleFailure,
  handleSuccess,
  installation,
  project,
  projectHistory,
  refreshProject,
  treatments,
}) {
  return [
    {
      menuItem: 'Visão geral',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectOverviewPage
            project={project}
            treatments={treatments}
            installation={installation}
            documents={documents}
            refreshProject={refreshProject}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Coletas',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectCollectionsPage projectId={project.id} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Parcelas',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectInstallmentsPage
            project={project}
            contract={contract}
            refreshProject={refreshProject}
            onUpdateSuccess={handleSuccess}
            onUpdateError={handleFailure}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: 'Histórico',
      render: () => (
        <Tab.Pane as="div" style={paneStyle} attached="false">
          <ProjectHistoryPage history={projectHistory} />
        </Tab.Pane>
      ),
    },
  ];
}

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  margin-bottom: 3.2rem;
`;

const Tags = styled.div`
  display: flex;
  flex-direction: row;
`;
