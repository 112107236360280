import { AxiosError } from 'axios';
import {
  UseMutationOptions,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';

import api from 'util/api';

export type EmployeeFilter = {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  sort?: string;
  fullName?: string;
  isFormer?: boolean;
};

export function useQueryEmployees(params: EmployeeFilter) {
  return useQuery(['employees', params], () =>
    getAllEmployees({ orderBy: 'firstName', sort: 'asc', ...params }),
  );
}

async function getAllEmployees(filter: EmployeeFilter) {
  const response = await api.get(`/employees`, { params: filter });
  const { data: employees, pagination } = response.data;

  return { employees, pagination };
}

export function useQueryEmployee(employeeId: number) {
  return useQuery(['employee', employeeId], () => getEmployee(employeeId));
}

async function getEmployee(employeeId: number) {
  const { data } = await api.get(`/employees/${employeeId}`);
  return data;
}

export type EmployeeInput = {
  firstName: string;
  lastName: string;
  jobPosition: number;
  photo?: string;
  creaSp: string;
};

export function useCreateEmployee(
  options?: UseMutationOptions<number, AxiosError, EmployeeInput, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(createEmployee, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('employees');
      queryClient.invalidateQueries('employee');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function createEmployee(input: EmployeeInput) {
  const { headers } = await api.post(`/employees`, input);

  const { location } = headers;
  const employeeId = parseInt(location.substring(14), 10);

  return employeeId;
}

export type EmployeeUpdate = {
  id: number;
  firstName: string;
  lastName: string;
  jobPosition: number;
  photo?: string;
  creaSp: string;
};

export function useUpdateEmployee(
  options?: UseMutationOptions<void, AxiosError, EmployeeUpdate, () => void>,
) {
  const queryClient = useQueryClient();
  return useMutation(updateEmployee, {
    ...options,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries('employees');
      queryClient.invalidateQueries('employee');
      if (options?.onSuccess) {
        options?.onSuccess(data, variables, context);
      }
    },
  });
}

async function updateEmployee(input: EmployeeUpdate) {
  await api.patch(`/employees/${input.id}`, input);
}
