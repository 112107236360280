import React from 'react';

import Select from 'react-select';

import { useQueryMeasurementUnits } from 'queries/measurementUnit';

export default function MeasurementUnitSelect({
  name = undefined,
  value = undefined,
  onChange,
  onBlur,
  autoComplete,
  disabled = false,
  isClearable = false,
}) {
  const { data, isLoading } = useQueryMeasurementUnits({
    pageSize: 100,
    orderBy: 'abbreviation',
  });

  const defaultValue = {};
  const options = data?.units ? data.units : [];

  return (
    <Select
      name={name}
      instanceId={name}
      placeholder="Selecione..."
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={(option) => option.abbreviation}
      defaultValue={defaultValue}
      isLoading={isLoading}
      isDisabled={disabled}
      isClearable={isClearable}
      autoComplete={autoComplete}
    />
  );
}
